import PropTypes from 'prop-types'
import React from 'react'

import Checkbox from './checkbox'

function CheckboxField(props) {
  return (
    <Checkbox
      label={props.label}
      value={Boolean(props.value)}
      onChange={props.onChange}
    />
  )
}

export default CheckboxField

CheckboxField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
}

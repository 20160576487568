import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { h1, h2, h3, h4, h5, h6 } from '../mixins'

import Text from './text'

const LEVELS = [h1(), h2(), h3(), h4(), h5(), h6()]

function Heading(props) {
  const H = `h${props.level}`

  return (
    <Text align={props.align} className={props.className} color={props.color}>
      <H>{props.children}</H>
    </Text>
  )
}

export default styled(Heading)`
  font-family: ${props => props.theme.fonts.headings};
  margin: 0;
  ${props => LEVELS[Math.max(0, props.level - 1)]};
`

Heading.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

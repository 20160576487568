import React from 'react'
import { Route } from 'react-router'

export default function developmentOptions(root) {
  return (
    <Route
      getComponents={(nextState, cb) => {
        require.ensure(
          [],
          () => {
            cb(null, require('./development-options').default)
          },
          'development-options'
        )
      }}
      path={root}
    />
  )
}

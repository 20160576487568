import * as recompose from 'recompose'
import { connect } from 'react-redux'

import {
  CAD,
  EUR,
  GBP,
  SEK,
  USD,
  createAmountComponent,
} from '@rushplay/common'

import * as App from '../store/app'
import getPlayerCurrency from '../util/get-player-currency'

export const RUBIES = {
  code: 'RUBIES',
  prepend: true,
  ratio: 1,
  symbol: null,
  customCurrency: true,
}

export const currencies = { EUR, GBP, RUBIES, SEK, USD, CAD }

function mapStateToProps(state) {
  return {
    currency: getPlayerCurrency(state),
    locale: App.getLanguage(state.app),
  }
}

const Amount = createAmountComponent({
  currencies: [CAD, EUR, GBP, SEK, USD, RUBIES],
})

export default recompose.branch(
  props => props.currency == null,
  connect(mapStateToProps)
)(Amount)

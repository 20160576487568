import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import Button from '../../../common/button'
import HtmlContent from '../../../common/html-content'
import LockPage from '../lock-page'

import { Row } from './row'

export function UnsetLoginTimeLimits(props) {
  const i18n = I18n.useI18n()
  return (
    <LockPage backgroundColor="nightDark">
      <Common.Box
        display="flex"
        p="5%"
        flexDirection="column"
        color="white"
        alignItems="center"
        textAlign="center"
      >
        <Common.Box fontSize={5} fontWeight="700" pb={15}>
          {i18n.translate('locks.unset-login-time-limits.title')}
        </Common.Box>
        <Common.Box fontSize="12px" pb={8}>
          <HtmlContent
            html={{
              __html: i18n.translate('locks.unset-login-time-limits.content'),
            }}
          />
        </Common.Box>
        <forms.Form
          name="unset-login-time-limits"
          autocomplete="off"
          onSubmit={props.onSubmit}
          mountPoint="formsv2"
        >
          {form => (
            <div>
              <Common.Box
                display="grid"
                gridTemplateColumns="40% 60%"
                gridTemplateRows="50% 50%"
                backgroundColor="rgba(70,192,244, 0.07)"
                fontSize={['16px', null, 3, null]}
                pt={4}
                px={[2, null, '14%', null]}
                justifyContent="center"
                alignItems="center"
              >
                <Common.Box textAlign="left" fontSize={3}>
                  {i18n.translate(
                    'locks.unset-login-time-limits.period.default-values'
                  )}
                </Common.Box>
                <Common.Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {R.map(
                    value => (
                      <Common.Box
                        onClick={() =>
                          props.onUpdateLimits(value, {
                            day: 1,
                            week: 7,
                            month: 30,
                          })}
                        backgroundColor={
                          props.formValues['day'] === value ? (
                            'oceanLight'
                          ) : (
                            'white'
                          )
                        }
                        color={
                          props.formValues['day'] === value ? 'white' : 'black'
                        }
                        cursor="pointer"
                        key={value}
                        fontWeight="bold"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexShrink="0"
                        border="solid 2px rgba(32,154,206)"
                        borderRadius="2px"
                        p={['5px', null, '10px', null]}
                        my={0}
                        minWidth={['16px', null, '19px', null]}
                      >
                        {value}
                      </Common.Box>
                    ),
                    props.defaultValues
                  )}
                </Common.Box>
                <Common.Box
                  gridColumn="2/2"
                  display="flex"
                  pb={1}
                  alignItems="center"
                >
                  <Row
                    id="day"
                    maxAmount={24}
                    minAmount={1}
                    borderStyle="dashed"
                    onChange={e =>
                      props.onUpdateLimits(e.target.value, {
                        week: 7,
                        month: 30,
                      })}
                  />
                </Common.Box>
              </Common.Box>
              <Common.Box
                display="grid"
                flexDirection="column"
                gridTemplateColumns="100%"
                gridTemplateRows="50% 50%"
                fontSize={2}
              >
                <Row
                  id="week"
                  maxAmount={168}
                  minAmount={props.formValues['day']}
                />
                <Row
                  id="month"
                  backgroundColor="rgba(70,192,244, 0.07)"
                  maxAmount={720}
                  minAmount={props.formValues['week']}
                />
              </Common.Box>
              <Common.Box fontSize={0} py={8} textAlign="center">
                {i18n.translate(
                  'locks.unset-login-time-limits.additional-info'
                )}
              </Common.Box>
              <Button type="submit" variant="primary" disabled={!form.valid}>
                {i18n.translate('locks.unset-deposit-limits.cta')}
              </Button>
            </div>
          )}
        </forms.Form>
      </Common.Box>
    </LockPage>
  )
}

UnsetLoginTimeLimits.propTypes = {
  defaultValues: PropTypes.array,
  formValues: PropTypes.object,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUpdateLimits: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import { fetchPlayerRegion, fetchWorldRegions } from '@rushplay/api-client'

import regions from '../../store/adventure/regions'
import treasures from '../../store/adventure/treasures'
import worlds from '../../store/adventure/worlds'
import { PageSpinner } from '../../common/spinner'
import { isWaiting, startWaiting, stopWaiting } from '../../store/app'

const FETCH_REGIONS_INFORMATION = 'fetch-regions-information'

class RegionPreloader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ready: false }
  }

  componentWillMount() {
    function success({ value }) {
      const worldKey = value.worldKey
      const treasuresEntities = R.path(
        ['unfoundTreasures', 'entities', 'treasures'],
        value
      )
      const treasuresIds = R.path(['unfoundTreasures', 'result'], value)
      const worldsImages = {
        [worldKey]: {
          prizeImageUrl: value.prizeImageUrl,
          emptinessImageUrl: value.emptinessImageUrl,
          treasureImageUrl: value.treasureImageUrl,
        },
      }

      const fetchWorldRegionsAction = fetchWorldRegions(worldKey, {
        success: ({ value }) => {
          const regionsEntities = R.path(
            ['result', 'entities', 'regions'],
            value
          )
          const regionsKeys = R.path(['result', 'result'], value)

          return [
            regions.actions.update(regionsEntities),
            worlds.actions.storeRegions(worldKey, regionsKeys),
            stopWaiting(FETCH_REGIONS_INFORMATION),
          ]
        },
        failure: res => [
          Notifications.add({ message: res.value, level: 'error' }),
          stopWaiting(FETCH_REGIONS_INFORMATION),
        ],
        version: 1,
        normalize: true,
        cacheFor: Infinity,
      })

      return [
        worlds.actions.selectWorld(worldKey),
        worlds.actions.selectRegion(worldKey, value.key),
        worlds.actions.storeTreasures(worldKey, treasuresIds),
        worlds.actions.updateImages(worldsImages),
        worlds.actions.updateRegionProgress(worldKey, value.progressPercent),
        worlds.actions.updateLastSeenProgress(
          worldKey,
          value.lastSeenProgressPercent
        ),
        worlds.actions.updateDetails({
          [worldKey]: { isLastRegion: value.lastRegion, pp: value.pp },
        }),
        treasures.actions.update(treasuresEntities),
        fetchWorldRegionsAction,
      ]
    }

    this.props.startWaiting(FETCH_REGIONS_INFORMATION),
      this.props.fetchPlayerRegion({
        success,
        failure: error => [
          Notifications.add({ message: error.value, level: 'error' }),
          stopWaiting(FETCH_REGIONS_INFORMATION),
        ],
        version: 1,
        normalize: true,
      })
  }

  componentWillReceiveProps(nextProps) {
    // This component should render RegionContainer component only when all data is fetched.
    // Having waitingRegionsInformation in redux-store is not enough because this prop
    // is is being updated after render method of this component has been called causing to
    // render RegionContainer once before data was fetched.
    // This is a hack to have one more prop on local component's state, so that RegionContainer
    // is only rendered when data has been fetched.
    if (!this.state.ready) {
      this.setState({ ready: !nextProps.waitingRegionsInformation })
    }
  }

  render() {
    if (!this.state.ready) {
      return <PageSpinner />
    }

    return this.props.children
  }
}

function mapStateToProps(state) {
  return {
    waitingRegionsInformation: isWaiting(state.app, FETCH_REGIONS_INFORMATION),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPlayerRegion,
      fetchWorldRegions,
      startWaiting,
      updateTreasures: treasures.actions.update,
      updateRegions: regions.actions.update,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionPreloader)

RegionPreloader.propTypes = {
  children: PropTypes.node,
  fetchPlayerRegion: PropTypes.func.isRequired,
  startWaiting: PropTypes.func.isRequired,
  waitingRegionsInformation: PropTypes.bool.isRequired,
}

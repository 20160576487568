import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { componentFromProp } from 'recompose'

import { Space } from '@rushplay/common'
import { connectForm } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import Select from '../common/select'
import Text from '../common/text'

import * as Limit from './limit-styles.js'

function RealityCheckForm(props) {
  return (
    <Limit.Wrapper>
      <Limit.Panel>
        <Limit.Header>
          <Limit.Title>{props.translate('reality-check.heading')}</Limit.Title>
        </Limit.Header>
        <Space x="26px" bottom={6}>
          <Text>{props.translate('reality-check.info')}</Text>
        </Space>
        <Space x="26px" bottom={6}>
          {props.initialValue
            ? props.translate('reality-check.current-limit', {
                value: props.initialValue / 60000,
              })
            : props.translate('reality-check.current-limit.no-limits')}
        </Space>
        <Space x="10px">
          <props.Field
            component={Select}
            name="remind-me-in"
            options={props.options}
            initialValue={props.initialValue}
            validator={props.validator}
          />
          <Space x="26px" y={5}>
            <Button
              stretch
              onClick={props.onSubmit}
              disabled={props.isDisabled}
            >
              {props.translate('reality-check.confirm')}
            </Button>
          </Space>
        </Space>
        <Limit.StyledLine color="#6f7d95" />
      </Limit.Panel>
    </Limit.Wrapper>
  )
}

RealityCheckForm.defaultProps = {
  Field: componentFromProp('component'),
  translate: R.identity,
}

RealityCheckForm.propTypes = {
  Field: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  validator: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

export default connectForm(withTranslate(RealityCheckForm))

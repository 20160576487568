import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import { Header, Headline, NavigateBack } from '../components/static/static'
import { h3 } from '../mixins'
import { textFont } from '../constants/typography'

const Wrapper = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 50px;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
`

const Description = styled(HtmlContent)`
  ${h3};
  font-weight: 400;
  font-family: ${textFont};

  max-width: 60%;
  padding-left: 20px;

  @media screen and (min-width: 768px) {
    max-width: 70%;
  }
`

function PlaySmartContainer(props) {
  return (
    <Wrapper>
      <Header>
        <Headline>{props.translate(`static.play-smart.headline`)}</Headline>

        <NavigateBack onClick={props.onNavigateBack}>
          <Icon name="chevron-left" size="big" />
        </NavigateBack>

        <Description
          html={{ __html: props.translate(`static.play-smart.description`) }}
        />
      </Header>

      <HtmlContent
        html={{ __html: props.translate(`static.play-smart.content`) }}
      />
    </Wrapper>
  )
}

export default connect(null, { onNavigateBack: goBack })(
  withTranslate(PlaySmartContainer)
)

PlaySmartContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
}

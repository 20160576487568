import styled from '@emotion/styled'

import { baseFontSizeLarge } from '../constants/typography'

export const Headline = styled.h4`
  font-size: ${baseFontSizeLarge};
  color: #fff;
  text-align: center;

  @media screen and (min-width: 690px) {
    font-size: 22px;
  }
`

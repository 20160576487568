import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import AggregateProgress from '../../../components/region/aggregate-progress'
import BigSpinsProgress from '../../../components/region/big-spins-progress'
import CampaignModal from '../../../components/region/campaign-modal'

function isAgainstMonster(campaignType) {
  return campaignType === 'aggregating_against_monster'
}

function isAgainstOthers(campaignType) {
  return campaignType === 'aggregating_against_others'
}

function getAssetImgByType(campaignAsset, type) {
  const asset = R.find(R.propEq('type', type), campaignAsset)
  return asset ? asset.image : ''
}

export function CampaignOverlay(props) {
  const i18n = useI18n()

  return (
    <CampaignModal
      color="rgba(0, 13, 61, 0.7)"
      isOpen={props.isOpen}
      title={i18n.translate(`campaigns.${props.campaign.key}.title`)}
      onRequestClose={props.onRequestClose}
    >
      <Flex justifyContent="center">
        {!isAgainstOthers(props.campaign.type) && (
          <AggregateProgress
            raceBarImg={getAssetImgByType(props.campaign.assets, 'raceMeter')}
            rewards={R.reverse(
              R.sortBy(R.prop('ppCheckpoint'), props.campaign.rewards)
            )}
            isAgainstMonster={isAgainstMonster(props.campaign.type)}
            monsterImg={props.campaign.monsterAvatarUrl}
            monsterProgress={props.campaign.monsterProgress}
            playerImg={props.playerImage}
            playerProgress={props.campaign.playerProgress}
          />
        )}
        {isAgainstOthers(props.campaign.type) && (
          <BigSpinsProgress
            maxPlayers={3}
            rewards={props.campaign.rewards}
            rewardImg={getAssetImgByType(props.campaign.assets, 'PVPReward')}
            avatarImg={props.playerImage}
            playerRank={props.campaign.playerRank}
            topWinners={R.slice(0, 3, props.campaign.topWinners)}
            winnerCount={props.campaign.winnersCount}
            playerCount={props.campaign.playersCount}
          />
        )}
      </Flex>
    </CampaignModal>
  )
}

CampaignOverlay.propTypes = {
  campaign: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  playerImage: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
}

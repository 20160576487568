import PropTypes from 'prop-types'
import React from 'react'

import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import PopupContainer from '../common/popup/popup-container'
import { PopupScrollable } from '../common/popup/popup-styles.js'

function TermsConditions(props) {
  return (
    <PopupContainer
      id="terms-conditions"
      isOpen={props.visible}
      title={props.translate('register.form.terms-conditions')}
      onRequestClose={props.onClose}
    >
      <PopupScrollable>
        <HtmlContent
          html={{
            __html: props.translate('terms.and.conditions.general', {
              logoImageSrc: props.translate(
                'terms-and-conditions.general.license-logo'
              ),
            }),
          }}
        />
      </PopupScrollable>
    </PopupContainer>
  )
}

export default withTranslate(TermsConditions)

TermsConditions.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as onNavigateTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as supportChat from '@rushplay/support-chat'
import {
  fetchAllNotifications,
  verifyPhoneNumber as verifyPhoneNumberApi,
} from '@rushplay/api-client'
import {
  actions as formActions,
  connectForm,
  selectors,
} from '@rushplay/legacy-forms'
import {
  getCountryCode,
  getPhoneNumber,
  updateRequirePhoneVerification,
} from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import {
  fetchCountries,
  getCountries,
  getWaitingRegister,
  requestSmsVerification,
  updatePhoneNumber,
  waitRegister,
} from '../store/sign-up'
import {
  getBrand,
  restoreSession,
  startWaiting,
  stopWaiting,
} from '../store/app'

import Step5 from './steps/step5'
import Success from './steps/success'
import { CasiTabiCampaignTracker } from './steps/casitabi-campaign-tracker'

function stepIndex(step) {
  return R.findIndex(R.equals(step), STEPS)
}

const STEPS = ['validation-code', 'success']

const isStepValid = R.curry((formsState, group) => {
  return selectors.isAllFieldsHaveStatus(formsState, {
    form: 'phone-verification',
    group,
    status: 'valid',
  })
})

class PhoneVerification extends Component {
  constructor(props) {
    super(props)

    this.handleNextStep = this.handleNextStep.bind(this)
    this.handleSmsRequest = this.handleSmsRequest.bind(this)
    this.handleVerification = this.handleVerification.bind(this)
    this.handlePhoneNumberUpdate = this.handlePhoneNumberUpdate.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  componentWillMount() {
    this.props.fetchCountries()
    const [firstStep] = STEPS
    this.props.onNavigateTo(`/phone-verification/${firstStep}`)
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.params.step) {
      const currentStepIndex = stepIndex(this.props.phoneVerificationPage)
      const currentStep = STEPS[currentStepIndex]
      this.props.onNavigateTo(`/phone-verification/${currentStep}`)
    }
  }

  handleNextStep() {
    const nextStepIndex = stepIndex(this.props.phoneVerificationPage) + 1
    const nextStep = STEPS[nextStepIndex]

    this.props.onNavigateTo(`/phone-verification/${nextStep}`)
  }

  handleVerification() {
    this.props.verifyPhoneNumber(
      this.props.fields.code.value,
      this.props.translate
    )
  }

  handleSmsRequest() {
    this.props.requestSmsVerification()
    this.props.clearFields(this.props.form, ['code'])
  }

  handlePhoneNumberUpdate() {
    const address = {
      countryCallingCode: this.props.fields.countryCallingCode.value,
      mobile: this.props.fields.mobile.value,
    }

    this.props.updatePhoneNumber({ address })

    this.handleNextStep()
  }

  handleSuccess() {
    this.props.restoreSession(this.props.isCasitabi ? '/onboarding' : true)
  }

  render() {
    const steps = [
      <Step5
        key="1"
        loading={this.props.waitingRegister}
        isValid={this.props.isStepValid('step5')}
        fields={R.pick(['code'], this.props.fields)}
        onLiveChatClick={this.props.onLiveChatClick}
        onResendCode={this.handleSmsRequest}
        onNextStep={this.handleVerification}
      />,
      <Success key="2" onSuccess={this.handleSuccess}>
        {this.props.isCasitabi ? <CasiTabiCampaignTracker /> : null}
      </Success>,
    ]

    return <div>{steps[stepIndex(this.props.phoneVerificationPage)]}</div>
  }
}

function mapStateToProps(state, ownProps) {
  return {
    countries: getCountries(state.signUp),
    countryCode: getCountryCode(state.session),
    isCasitabi: getBrand(state.app) === 'casitabi',
    isStepValid: isStepValid(state.forms),
    mobile: getPhoneNumber(state.session),
    phoneVerificationPage: ownProps.params.step,
    waitingRegister: getWaitingRegister(state.signUp),
  }
}

function verifyPhoneNumber(code, translate) {
  const verifyPhoneNumberAction = verifyPhoneNumberApi(code, {
    success: () => [
      waitRegister(false),
      updateRequirePhoneVerification(false),
      onNavigateTo(`/phone-verification/${R.last(STEPS)}`),
    ],
    failure: () => [
      waitRegister(false),
      Notifications.add({
        message: translate('sign-up.verification.failed'),
        level: 'error',
      }),
    ],
    version: 1,
  })

  return [waitRegister(true), verifyPhoneNumberAction]
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearFields: formActions.clearFields,
      fetchAllNotifications,
      fetchCountries,
      requestSmsVerification,
      restoreSession,
      updatePhoneNumber,
      verifyPhoneNumber,
      startWaiting,
      stopWaiting,
      onNavigateTo,
      onLiveChatClick: () => supportChat.setTabVisibility(true),
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslate(connectForm(PhoneVerification, { name: 'phone-verification' }))
)

PhoneVerification.propTypes = {
  clearFields: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
  fetchAllNotifications: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fields: PropTypes.object,
  form: PropTypes.string,
  formData: PropTypes.object,
  isCasitabi: PropTypes.bool,
  isStepValid: PropTypes.func.isRequired,
  mobile: PropTypes.string.isRequired,
  params: PropTypes.object,
  phoneVerificationPage: PropTypes.string,
  requestSmsVerification: PropTypes.func.isRequired,
  restoreSession: PropTypes.func.isRequired,
  startWaiting: PropTypes.func.isRequired,
  stopWaiting: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  updatePhoneNumber: PropTypes.func.isRequired,
  verifyPhoneNumber: PropTypes.func.isRequired,
  waitingRegister: PropTypes.bool.isRequired,
  onLiveChatClick: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
}

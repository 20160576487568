/* global __API_URL__ */

import * as R from 'ramda'

// https://regexr.com/3gpo0
const getSecondLevelDomain = R.pipe(
  R.match(/[a-z-]+\.\w+$/i),
  R.head
)

export function getApiUrl(hostname) {
  /* istanbul ignore next: dev-only code */
  if (typeof __API_URL__ === 'string') {
    // eslint-disable-next-line no-console
    console.warn(
      '[Deprecation] __API_URL__ global usage is discouraged; prefer process.env.API_URL'
    )
    return __API_URL__
  }

  /* istanbul ignore next: dev-only code */
  if (typeof process.env.API_URL === 'string') {
    return process.env.API_URL
  }

  const secondLevelDomain = getSecondLevelDomain(hostname)

  if (secondLevelDomain) {
    return `//clients.${secondLevelDomain}`
  } else {
    /* istanbul ignore next: dev-only code */
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(
        'API URL cannot be detected locally.',
        'Set API_URL environment variable.',
        'You can also use .env to persist configuration.',
        'More information can be found in project’s README.md'
      )
    }
    return null
  }
}

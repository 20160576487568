import * as R from 'ramda'
import React from 'react'
import { PropTypes } from 'prop-types'

const trimParams = R.omit(['limit', 'offset'])

const paramsDidChange = (prevParams, newParams) =>
  R.not(R.equals(trimParams(prevParams), trimParams(newParams)))

export class GameQuery extends React.Component {
  componentDidMount() {
    this.props.onFetch()
  }

  componentWillUnmount() {
    this.props.onClear()
  }

  componentDidUpdate(prevProps) {
    if (paramsDidChange(prevProps.params, this.props.params)) {
      this.props.onFetch()
    }
  }

  render() {
    return this.props.children({
      games: this.props.games,
      ids: this.props.ids,
      isLoading: this.props.isLoading,
      onLoadMore: this.props.onLoadMore,
    })
  }
}

GameQuery.propTypes = {
  children: PropTypes.func,
  games: PropTypes.array.isRequired,
  mountPoint: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  ids: PropTypes.array.isRequired,
  params: PropTypes.shape({
    mobile: PropTypes.bool,
    limit: PropTypes.number,
    offset: PropTypes.number,
    order: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    recent: PropTypes.bool,
    jackpot: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  onClear: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
}

import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import { getUserAvatar } from '@rushplay/session'

import { getActiveRaceCampaign } from '../../../store/campaigns'

import { CampaignOverlay as Component } from './campaign-overlay'

function mapStateToProps(state) {
  return {
    campaign: getActiveRaceCampaign(state.campaigns),
    playerImage: getUserAvatar(state.session),
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  recompose.branch(props => R.not(props.campaign), recompose.renderNothing)
)

export const CampaignOverlay = connector(Component)

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getItem, setItem } from 'redux-effects-localstorage'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import { updateCookieConsent } from '../store/player'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  background-color: rgba(250, 250, 250, 0.9);
  color: black;
  font-size: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 768px) {
    width: 60%;
    margin: auto;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
`

class CookieBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cookieConsent: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    this.props
      .getItem('COOKIE_CONSENT')
      .then((data) => this.setState({ cookieConsent: data }))
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
  }

  handleSubmit() {
    this.props.setItem('COOKIE_CONSENT', true)
    this.setState({ cookieConsent: true })
    this.props.updateCookieConsent(true)
  }

  render() {
    if (!this.state.cookieConsent) {
      return (
        <Wrapper>
          <HtmlContent
            html={{ __html: this.props.translate('cookie-disclaimer') }}
          />
          <Button small onClick={this.handleSubmit}>
            {this.props.translate('ok')}
          </Button>
        </Wrapper>
      )
    }

    return null
  }
}

export default connect(null, { setItem, getItem, updateCookieConsent })(
  withTranslate(CookieBanner)
)

CookieBanner.propTypes = {
  translate: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  updateCookieConsent: PropTypes.func.isRequired,
}

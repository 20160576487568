import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import * as locks from '@rushplay/compliance/locks'

import NotFound from '../not-found'

import BankIdVerification from './bank-id-verification'
import TermsAndConditionsConsent from './terms-and-conditions-consent'
import UnsetDailyDepositLimit from './unset-daily-deposit-limit'
import UnsetDepositLimits from './unset-deposit-limits'
import { EmailVerification } from './email-verification'
import { FreeText } from './free-text/'
import { HighDepositLimitPrompt } from './high-deposit-limit-prompt'
import { RGDepositThresholdPrompt } from './rg-deposit-threshold-prompt'
import { SourceOfFunds } from './source-of-funds/source-of-funds'
import { SourceOfWealth } from './source-of-wealth'
import { UnsetLoginTimeLimits } from './unset-login-time-limits'
import { UpdatedLimitsConfirmation } from './updated-limits-confirmation'

const LOCK_PAGES = {
  'email-verification': EmailVerification,
  'high-deposit-limit-prompt': HighDepositLimitPrompt,
  'responsible-gaming-deposit-threshold-prompt': RGDepositThresholdPrompt,
  'bank-id-verification': BankIdVerification,
  'source-of-funds-verification': SourceOfFunds,
  'source-of-wealth-verification': SourceOfWealth,
  'terms-and-conditions-consent': TermsAndConditionsConsent,
  'unread-messages': FreeText,
  'unset-daily-deposit-limit': UnsetDailyDepositLimit,
  'unset-deposit-limits': UnsetDepositLimits,
  'unset-login-time-limits': UnsetLoginTimeLimits,
  'updated-limits-confirmation': UpdatedLimitsConfirmation,
}

class LockPage extends React.Component {
  componentDidUpdate(prevProps) {
    if (!R.equals(prevProps.locks, this.props.locks)) {
      if (!R.includes(this.props.params.lock, this.props.locks)) {
        this.props.redirect()
      }
    }
  }

  render() {
    const Component = LOCK_PAGES[this.props.params.lock]

    if (!Component) {
      return <NotFound />
    }

    return <Component {...this.props} />
  }
}

function mapStateToProps(state) {
  return {
    locks: locks.getLocks(state.locks),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    redirect: () => dispatch(push('/locks')),
  }
}

const connector = R.compose(connect(mapStateToProps, mapDispatchToProps))

export default connector(LockPage)

LockPage.propTypes = {
  locks: PropTypes.object,
  redirect: PropTypes.func.isRequired,
  params: PropTypes.object,
}

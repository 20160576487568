import * as R from 'ramda'
import React, { useEffect } from 'react'
import { space } from 'styled-system'

import propTypes from '@styled-system/prop-types'
import styled from '@emotion/styled'
import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop'

import {
  ResponsiveValueSizePropType,
  logLegacyPropsWarnings,
} from './internal-helpers/index'

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'all',
  'as',
  'bottom',
  'left',
  'right',
  'top',
  'x',
  'y',
])

/**
 * Render children with whitespace around (can be done using padding or margin)
 * All values can be supplied theme => space values or regular units (px, em, etc.)
 * Supply responsive values (based on theme => breakpoints) in array, eg. <Space p={[1, 2, null, 3]} />
 * For more info see https://styled-system.com/api#space
 * @param {Object} props
 * @param {CssNumberLikeValues} [props.all] Add padding at all sides
 * @param {CssNumberLikeValues} [props.x] Add padding on left and right sides
 * @param {CssNumberLikeValues} [props.y] Add padding at top and bottom
 * @param {CssNumberLikeValues} [props.left] Add padding on left side
 * @param {CssNumberLikeValues} [props.right] Add padding on right side
 * @param {CssNumberLikeValues} [props.top] Add padding at the top
 * @param {CssNumberLikeValues} [props.bottom] Add padding at the bottom
 * @param {CssNumberLikeValues} [props.m] Add margin at all sides
 * @param {CssNumberLikeValues} [props.margin] Add margin at all sides
 * @param {CssNumberLikeValues} [props.mt] Add margin at the top
 * @param {CssNumberLikeValues} [props.marginTop] Add margin at the top
 * @param {CssNumberLikeValues} [props.mr] Add margin on right side
 * @param {CssNumberLikeValues} [props.marginRight] Add margin on right side
 * @param {CssNumberLikeValues} [props.mb] Add margin at the bottom
 * @param {CssNumberLikeValues} [props.marginBottom] Add margin at the bottom
 * @param {CssNumberLikeValues} [props.ml] Add margin on left side
 * @param {CssNumberLikeValues} [props.marginLeft] Add margin on left side
 * @param {CssNumberLikeValues} [props.mx] Add margin on left and right sides
 * @param {CssNumberLikeValues} [props.my] Add margin at top and bottom
 * @param {CssNumberLikeValues} [props.p] Add padding at all sides
 * @param {CssNumberLikeValues} [props.padding] Add padding at all sides
 * @param {CssNumberLikeValues} [props.pt] Add padding at the top
 * @param {CssNumberLikeValues} [props.paddingTop] Add padding at the top
 * @param {CssNumberLikeValues} [props.pr] Add padding on right side
 * @param {CssNumberLikeValues} [props.paddingRight] Add padding on right side
 * @param {CssNumberLikeValues} [props.pb] Add padding at the bottom
 * @param {CssNumberLikeValues} [props.paddingBottom] Add padding at the bottom
 * @param {CssNumberLikeValues} [props.pl] Add padding on left side
 * @param {CssNumberLikeValues} [props.paddingLeft] Add padding on left side
 * @param {CssNumberLikeValues} [props.px] Add padding on left and right sides
 * @param {CssNumberLikeValues} [props.py] Add padding at top and bottom
 * @returns {ReactComponent}
 */
const NextSpace = styled('div', { shouldForwardProp })(space)

/**
 * Ugly backwards-compatibility hack
 *
 * We must remove properties that has undefined as value to avoid wiping out the actual value on merge
 *
 * @param {*} props
 */
function getLegacyProps(props) {
  return R.reject(R.isNil, {
    pt: props.pt || props.top || props.y || props.all,
    pb: props.pb || props.bottom || props.y || props.all,
    pl: props.pl || props.left || props.x || props.all,
    pr: props.pr || props.right || props.x || props.all,
  })
}

export function Space(props) {
  useEffect(() => logLegacyPropsWarnings(props), [])

  return <NextSpace {...getLegacyProps(props)} {...props} />
}

Space.propTypes = {
  ...propTypes.space,
  all: ResponsiveValueSizePropType,
  bottom: ResponsiveValueSizePropType,
  left: ResponsiveValueSizePropType,
  right: ResponsiveValueSizePropType,
  top: ResponsiveValueSizePropType,
  x: ResponsiveValueSizePropType,
  y: ResponsiveValueSizePropType,
}

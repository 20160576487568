import * as R from 'ramda'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { locationShape } from 'react-router/lib/PropTypes'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { css } from '@emotion/core'

import * as Colors from '../../../constants/colors'
import Icon from '../../../common/icon/icon'
import theme from '../../../theme'
import withoutLanguage from '../../../util/path-without-language'

export function MainMenu(props) {
  const i18n = I18n.useI18n()

  const pathnameWithoutLanguage = withoutLanguage(props.location.pathname)

  const mainMenuItems = [
    {
      icon: 'ch-adventure',
      title: i18n.translate('menu-links.my-adventure'),
      to: props.authenticated ? 'adventure' : 'adventure-promo',
    },

    {
      icon: 'ch-casino',
      title: i18n.translate('menu-links.casino-games'),
      to: 'casino',
    },

    props.isSportsbookEnabled && {
      icon: 'ch-sports',
      title: i18n.translate('menu-links.sportsbook'),
      to: 'sports',
    },

    {
      icon: 'ch-live-casino',
      title: i18n.translate('menu-links.live-casino-games'),
      to: 'live-casino',
    },

    {
      count: props.authenticated ? props.treasureCount : 0,
      icon: 'ch-treasure-chest',
      title: i18n.translate('menu-links.treasure-chest'),
      disabled: !props.authenticated,
      to: 'treasure-chest',
    },

    {
      count: props.authenticated ? props.promotionsCount : 0,
      icon: 'ch-offers',
      title: i18n.translate('menu-links.offers'),
      to: 'promotions',
    },
  ]

  return (
    <Common.Box
      display={['none', null, null, 'flex']}
      paddingTop={[null, null, null, '6px']}
      paddingBottom={[null, null, null, '6px']}
    >
      {R.map((item) => {
        return (
          <Common.Box
            as={Common.Link}
            key={item.icon}
            to={`/${item.to}`}
            display="block"
            paddingTop="4px"
            paddingBottom="4px"
            borderRadius="4px"
            textAlign="center"
            backgroundColor={
              R.includes(item.to, R.split('/', pathnameWithoutLanguage)[1]) &&
              Colors.nightDark
            }
            cursor={item.disabled && 'not-allowed'}
            opacity={item.disabled && '.5'}
            style={css`
              &:hover {
                opacity: 0.9;
              }

              ${item.disabled &&
              css`
                &:hover {
                  opacity: 0.5;
                }
              `};
            `}
          >
            <Common.Box
              fontSize="44px"
              marginLeft="25px"
              marginRight="25px"
              position="relative"
            >
              <Icon name={item.icon} />
              {item.count > 0 && (
                <Common.Box
                  position="absolute"
                  right="0"
                  top="0"
                  transform="translate(50%, 25%)"
                  fontSize="12px"
                  fontWeight="900"
                  lineHeight="18px"
                  textAlign="center"
                  width="18px"
                  height="18px"
                  borderRadius="50%"
                  backgroundColor={theme.variants.notification}
                >
                  {item.count}
                </Common.Box>
              )}
            </Common.Box>
            {item.title && (
              <Common.Box
                marginTop="0.75em"
                fontSize="0.75em"
                whiteSpace="nowrap"
              >
                {item.title}
              </Common.Box>
            )}
          </Common.Box>
        )
      }, R.filter(Boolean, mainMenuItems))}
    </Common.Box>
  )
}

MainMenu.propTypes = {
  authenticated: PropTypes.bool,
  isSportsbookEnabled: PropTypes.bool,
  location: locationShape,
  license: PropTypes.string,
  treasureCount: PropTypes.number,
  pathname: PropTypes.string,
  promotionsCount: PropTypes.number,
}

import * as R from 'ramda'
import { createSelector } from 'reselect'

import { getValueIndexInRange } from '../../util/get-value-index-in-range'

function _getOffers(state, props) {
  return R.pipe(
    R.sort(R.ascend(R.prop('maxDepositNumber'))),
    R.find((offer) => props.depositNumber < offer.maxDepositNumber),
    R.defaultTo({ packages: [] }),
    R.prop('packages')
  )(state)
}

function _getAmountCents(_, props) {
  return props.amountCents
}

function _getChoice(_, props) {
  return props.choice
}

function _getCalculatedOffers(offers, amountCents) {
  function getCurrentReward(field, offer) {
    return R.path(
      [field, getValueIndexInRange(amountCents, offer.rangeLimits)],
      offer
    )
  }

  return R.map((offer) => {
    const validMoneyCents =
      amountCents >= offer.rangeLimits[0] ? amountCents : 0
    const bonusMoneyCents = validMoneyCents * (offer.bonusMoneyPercent / 100)

    const inLimits = R.gte(amountCents, offer.rangeLimits[0])

    return {
      id: offer.id,
      rangeLimits: offer.rangeLimits,
      inLimits: inLimits,
      gameTitle: offer.gameTitle,
      bonusMoneyPercent: offer.bonusMoneyPercent,
      bonusMoneyPercentMaxCents: offer.bonusMoneyPercentMaxCents,
      bonusMoneyPercentCalculated: Math.min(
        bonusMoneyCents,
        offer.bonusMoneyPercentMaxCents
      ),
      fixedBonusMoney: getCurrentReward('fixedBonusMoney', offer),
      fixedFreebet: getCurrentReward('fixedFreebet', offer),
      fixedFreespins: getCurrentReward('fixedFreespins', offer),
      bonusRubies: getCurrentReward('bonusRubies', offer),
      fixedFeatureTriggers: getCurrentReward('fixedFeatureTriggers', offer),
      fixedFreeGamesVoucher: getCurrentReward('fixedFreeGamesVoucher', offer),
      maxFixedFreeGamesVoucher:
        offer.fixedFreeGamesVoucher && Math.max(...offer.fixedFreeGamesVoucher),
      maxFixedFeatureTriggers:
        offer.fixedFeatureTriggers && Math.max(...offer.fixedFeatureTriggers),
      maxFixedFreebet: offer.fixedFreebet && Math.max(...offer.fixedFreebet),
      maxFixedFreespins:
        offer.fixedFreespins && Math.max(...offer.fixedFreespins),
      maxFixedRubies: offer.bonusRubies && Math.max(...offer.bonusRubies),
      maxFixedBonusMoney:
        offer.fixedBonusMoney && Math.max(...offer.fixedBonusMoney),
    }
  }, offers)
}

export const getCalculatedOffers = createSelector(
  [_getOffers, _getAmountCents],
  _getCalculatedOffers
)

export const getWelcomeOfferReward = createSelector(
  [getCalculatedOffers, _getChoice],
  (offers, choice) => R.find(R.propEq('id', choice), offers)
)

import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import GamesSection from '../casino/games-section'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;

  section {
    > div {
      background: transparent;
      box-shadow: none;
    }
  }
`

export function LandingPageGamesArea() {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      width="100%"
      backgroundColor="#06193c"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Wrapper>
        <GamesSection
          id="new"
          params={{ tags: ['ct_new'], limit: 6, order: 'sort_order' }}
          heading={i18n.translate('casino.categories.new-games')}
          path="/casino/new"
          placeholders
          renderEmptyResults
        />
        <GamesSection
          id="jackpot"
          params={{ jackpot: true, limit: 6, order: 'sort_order' }}
          heading={i18n.translate('casino.categories.jackpot')}
          path="/casino/jackpot"
          placeholders
          renderEmptyResults
        />
        <GamesSection
          id="populargames"
          params={{ tags: ['ct_lp_popular'], limit: 6, order: 'sort_order' }}
          heading={i18n.translate('casino.categories.popular-games')}
          path="/casino"
          placeholders
          renderEmptyResults
        />
      </Wrapper>
    </Common.Box>
  )
}

LandingPageGamesArea.propTypes = {}

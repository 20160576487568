import PropTypes from 'prop-types'
import React from 'react'
import StyledProxy from 'styled-proxy'

import styled from '@emotion/styled'
import { Flex } from '@rushplay/common'
import { css } from '@emotion/core'

function marginX(value) {
  return css`
    margin-left: ${value / 2}px;
    margin-right: ${value / 2}px;
  `
}

const RowWrapper = styled(Flex)`
  ${props => props.gutter && marginX(props.gutter * -1)};
`

const RowItem = styled(StyledProxy)`
  ${props => props.gutter && marginX(props.gutter)};
`

/**
 * Align children horizontally with optional gutter.
 */
function Row(props) {
  if (React.Children.count(props.children) === 0) {
    return null
  }

  return (
    <RowWrapper
      className={props.className}
      inline={props.inline}
      justifyContent={props.justify}
      gutter={props.gutter}
      wrap={props.wrap}
    >
      {React.Children.map(props.children, child => (
        <RowItem gutter={props.gutter}>{child}</RowItem>
      ))}
    </RowWrapper>
  )
}

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool,
  justify: PropTypes.string,
  wrap: PropTypes.bool,
}

export default Row

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withTranslate } from '@rushplay/i18n'

import PageNav from '../common/page-nav'

function Limits(props) {
  const items = R.map(
    item => R.assoc('label', props.translate(item.label), item),
    props.items
  )

  return (
    <div>
      <PageNav items={items} />
      {props.children}
    </div>
  )
}

Limits.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  translate: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const isRealityCheckConfigurable = jurisdiction.getRealityCheckConfigurability(
    state.jurisdiction
  )

  const isSelfExclusionConfigurable = R.not(
    R.isEmpty(jurisdiction.getSelfExclusionValues(state.jurisdiction))
  )

  const isTimeOutConfigurable = R.not(
    R.isEmpty(jurisdiction.getTimeOutValues(state.jurisdiction))
  )

  const isSessionLimitConfigurable = R.not(
    R.isEmpty(
      jurisdiction.getSessionLimitPeriods(state.jurisdiction, {
        type: 'loginTime',
      })
    )
  )

  const isMoneyLimitsConfigurable = R.not(
    R.isEmpty(jurisdiction.getMoneyLimitTypes(state.jurisdiction))
  )

  return {
    items: R.filter(Boolean, [
      isMoneyLimitsConfigurable && {
        label: 'limits.nav.money-limits',
        to: 'dashboard/limits/money-limits',
      },
      isSessionLimitConfigurable && {
        label: 'limits.nav.login-time-limits',
        to: 'dashboard/limits/session-limits',
      },
      isRealityCheckConfigurable && {
        label: 'limits.nav.reality-check',
        to: 'dashboard/limits/reality-check',
      },
      isTimeOutConfigurable && {
        label: 'limits.nav.time-out',
        to: 'dashboard/limits/time-out',
      },
      isSelfExclusionConfigurable && {
        label: 'limits.nav.self-exclusion',
        to: 'dashboard/limits/self-exclusion',
      },
    ]),
  }
}

const connector = R.compose(connect(mapStateToProps), withTranslate)

export default connector(Limits)

import * as types from './types'

export function init(playerLimits) {
  return {
    type: types.INIT,
    payload: playerLimits,
  }
}

export function clear(target, period) {
  return {
    type: types.CLEAR,
    payload: {
      target,
      period,
    },
  }
}

export function edit(target, period) {
  return {
    type: types.EDIT,
    payload: {
      target,
      period,
    },
  }
}

export function set(target, period, amount, activeFrom) {
  return {
    type: types.SET,
    payload: {
      amount,
      activeFrom,
      target,
      period,
    },
  }
}

export function apply(target, period) {
  return {
    type: types.APPLY,
    payload: {
      target,
      period,
    },
  }
}

export function cancel(target, period) {
  return {
    type: types.CANCEL,
    payload: {
      target,
      period,
    },
  }
}

export function remove(target, period, activeFrom) {
  return set(target, period, Infinity, activeFrom)
}

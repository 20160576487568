import fieldError from '../field-error'

export default function getFormTranslationKey(formName) {
  return (currentField, fieldName, tooltipKey) => {
    if (!currentField) {
      return
    }

    if (fieldError(currentField)) {
      return fieldError(currentField)
    }

    return currentField.value
      ? `${formName}.${fieldName}.success`
      : `${formName}.${tooltipKey}`
  }
}

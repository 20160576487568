import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Link, ScrollableRow } from '@rushplay/common'
import { css } from '@emotion/core'

import { dark60, disabledGray, gradientBlue } from '../constants/colors'
import { headingFont } from '../constants/typography'

const mapIndexed = R.addIndex(R.map)

const Wrapper = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: ${gradientBlue};
`

const Nav = styled.nav`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 1245px;
`

const NavChildrenWrapper = styled.div`
  margin-right: -1px;
  display: flex;
  align-content: center;
  border-left: 1px solid #3765af;
  border-right: 1px solid #3765af;
`

const NavItemsContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border-left: 1px solid #3765af;
`

const NavItems = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  text-align: center;
  border-left: 1px solid #e7e7e7;
  font-size: 16px;
  font-family: ${headingFont};
  letter-spacing: 0;
  overflow-y: hidden;
`

const ListItem = styled.li`
  border-right: 1px solid #3765af;
  flex-shrink: 0;
  ${props => props.growItems && css`flex-grow: 1;`};
`

const LinkItem = styled(Link)`
  font-size: 14px;
  height: 100%;
  letter-spacing: 0;
  padding: 1.07143em 1em;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-family: ${headingFont};
  &.${props => props.activeClassName} {
    color: #fff;
    background-color: rgba(18, 51, 99, 0.91);
  }
  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.5;
        color: ${disabledGray};
      `
    }

    return css`
      color: ${dark60};

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    `
  }};
`

function PageNav(props) {
  return (
    <Wrapper>
      <Nav>
        {props.children && (
          <NavChildrenWrapper>{props.children}</NavChildrenWrapper>
        )}
        <NavItemsContentWrapper>
          <ScrollableRow>
            <NavItems>
              {mapIndexed(
                (item, index) => (
                  <ListItem growItems={props.growItems} key={index}>
                    <LinkItem
                      activeClassName="active"
                      disabled={item.disabled}
                      onlyActiveOnIndex={item.onlyActiveOnIndex}
                      to={item.to}
                    >
                      {item.label}
                    </LinkItem>
                  </ListItem>
                ),
                props.items
              )}
            </NavItems>
          </ScrollableRow>
        </NavItemsContentWrapper>
      </Nav>
    </Wrapper>
  )
}

export default PageNav

// --

const NAV_ITEM_SHAPE = PropTypes.shape({
  disabled: PropTypes.bool,
  onlyActiveOnIndex: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
})

PageNav.propTypes = {
  children: PropTypes.element,
  growItems: PropTypes.bool,
  items: PropTypes.arrayOf(NAV_ITEM_SHAPE),
}

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import styled from '@emotion/styled'
import { getDisplayName } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../../common/html-content'
import RegionContainer from '../region/region-container'
import RegionPreloader from '../region/region-preloader'
import triggers from '../../store/triggers/'
import { darkSun } from '../../constants/colors'
import { dequeuePopup, queuePopup } from '../../common/popup/popups'
import {
  finishOnboarding,
  incrementOnboardingStep,
} from '../../store/onboarding'
import { getAssetPath, isUk } from '../../store/app'

import OnboardingPopper from './onboarding-popper'
import OnboardingSemiEclipse from './onboarding-semi-eclipse'
import {
  AvatarsCenterWrapper,
  AvatarsWrapper,
  HighlightBoss,
  HighlightGames,
  HighlightGamesFake,
  HighlightPlayer,
  KingAvatar,
  OnboardingWrapper,
  RegionWrapper,
  SpeechBubble,
  StepOneTitleWrapper,
  StepOneWrapper,
  StepTwoBetser,
  StepTwoKing,
  StepTwoTitle,
  StepTwoWrapper,
  Wrapper,
} from './onboarding-steps'
import {
  SegregationH1,
  SegregationH2,
  SegregationMobileHeadlineContainer,
  SegregationMobileWarning,
  SegregationWarning,
  SegregationWarningText,
} from './segregation-of-funds'

export const Title = styled.span`
  font-size: 2em;
  color: ${darkSun};
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 0.5em;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);

  @media screen and (max-height: 750px) {
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }
`

export const SubTitle = styled.span`
  padding-left: 1em;
  padding-right: 1em;
`

class OnboardingContainer extends Component {
  componentDidMount() {
    if (!this.props.onboardingNotification) {
      this.props.redirectTo('/adventure')
    }

    this.props.queuePopup('popup-blocker')
  }

  componentWillUnmount() {
    this.props.dequeuePopup('popup-blocker')
  }

  render() {
    const onboardingSteps = [
      <StepOneWrapper
        key="step-one"
        background={`${this.props
          .assetPath}/onboarding/old-world-bg-extended.jpg`}
      >
        {!this.props.isUk && (
          <StepOneTitleWrapper>
            <Title>{this.props.translate('onboarding.step1.title')}</Title>
            <SubTitle>
              {this.props.translate('onboarding.step1.subtitle')}
            </SubTitle>
          </StepOneTitleWrapper>
        )}

        {this.props.isUk && (
          <SegregationMobileHeadlineContainer>
            <SegregationH1>
              {this.props.translate('segregation-of-funds.welcome')}
            </SegregationH1>
            <SegregationH2>
              {this.props.translate('segregation-of-funds.brand-new-adventure')}
            </SegregationH2>
          </SegregationMobileHeadlineContainer>
        )}
      </StepOneWrapper>,

      <StepTwoWrapper
        key="step-two"
        background={`${this.props.assetPath}/onboarding/throne-bg.jpg`}
      >
        <StepTwoTitle>
          {this.props.translate('onboarding.step2.title')}
        </StepTwoTitle>
        <AvatarsWrapper>
          <AvatarsCenterWrapper>
            <StepTwoKing
              active
              src={`${this.props.assetPath}/onboarding/king-sad.png`}
            />
            <StepTwoBetser
              src={`${this.props.assetPath}/onboarding/betser-full-body.png`}
            />
            <SpeechBubble
              king
              speechBubble={`${this.props
                .assetPath}/onboarding/speech-bubble.svg`}
            >
              <HtmlContent
                html={{
                  __html: this.props.translate('onboarding.step2.speech', {
                    username: this.props.displayName,
                  }),
                }}
              />
            </SpeechBubble>
          </AvatarsCenterWrapper>
        </AvatarsWrapper>
      </StepTwoWrapper>,

      <StepTwoWrapper
        key="step-three"
        background={`${this.props.assetPath}/onboarding/throne-bg.jpg`}
      >
        <StepTwoTitle>
          {this.props.translate('onboarding.step2.title')}
        </StepTwoTitle>
        <AvatarsWrapper>
          <AvatarsCenterWrapper>
            <StepTwoKing
              src={`${this.props.assetPath}/onboarding/king-sad.png`}
            />
            <StepTwoBetser
              active
              src={`${this.props.assetPath}/onboarding/betser-full-body.png`}
            />
            <SpeechBubble
              speechBubble={`${this.props
                .assetPath}/onboarding/speech-bubble-betser.svg`}
            >
              <HtmlContent
                html={{
                  __html: this.props.translate('onboarding.step3.speech'),
                }}
              />
            </SpeechBubble>
          </AvatarsCenterWrapper>
        </AvatarsWrapper>
      </StepTwoWrapper>,

      <RegionWrapper key="step-four">
        <OnboardingPopper
          content={this.props.translate('onboarding.step4.player', {
            username: this.props.displayName,
          })}
          key="onboarding.step4.player"
          placement="top"
        >
          <HighlightPlayer />
        </OnboardingPopper>
      </RegionWrapper>,

      <RegionWrapper boss key="step-five">
        <OnboardingPopper
          content={this.props.translate('onboarding.step5.boss')}
          key="onboarding.step5.boss"
          placement="bottom"
        >
          <HighlightBoss />
        </OnboardingPopper>
      </RegionWrapper>,

      <RegionWrapper boss games key="step-six">
        <OnboardingPopper
          content={this.props.translate('onboarding.step6.quickgames')}
          key="onboarding.step6.quickgames"
          placement="left"
        >
          <HighlightGamesFake />
          <HighlightGames />
        </OnboardingPopper>
      </RegionWrapper>,
    ]

    return (
      <Wrapper>
        <RegionWrapper>
          <RegionPreloader>
            <RegionContainer />
          </RegionPreloader>
        </RegionWrapper>
        <OnboardingWrapper>
          {onboardingSteps[this.props.currentStep]}
          <OnboardingSemiEclipse
            buttonText={this.props.translate(
              onboardingSteps.length - 1 === this.props.currentStep
                ? 'onboarding.button.last'
                : 'onboarding.button'
            )}
            linkText={this.props.translate('onboarding.skip')}
            onSkipHandler={() =>
              this.props.finishOnboarding(this.props.onboardingNotification.id)}
            onNextHandler={() =>
              handleNextStep(onboardingSteps.length, this.props)}
          >
            {this.props.currentStep === 0 &&
            !this.props.isUk && (
              <KingAvatar
                src={`${this.props
                  .assetPath}/onboarding/king-avatar-shadow.png`}
              />
            )}

            {this.props.currentStep === 0 &&
            this.props.isUk && (
              <div>
                <SegregationMobileWarning>
                  <SegregationWarningText />
                </SegregationMobileWarning>
                <SegregationWarning>
                  <SegregationH1>
                    {this.props.translate('segregation-of-funds.welcome')}
                  </SegregationH1>
                  <SegregationH2>
                    {this.props.translate(
                      'segregation-of-funds.brand-new-adventure'
                    )}
                  </SegregationH2>
                  <SegregationWarningText />
                </SegregationWarning>
              </div>
            )}
          </OnboardingSemiEclipse>
        </OnboardingWrapper>
      </Wrapper>
    )
  }
}

function handleNextStep(onboardingSteps, props) {
  if (onboardingSteps - 1 === props.currentStep) {
    if (props.onboardingNotification) {
      return props.finishOnboarding(props.onboardingNotification.id)
    }
    // onboardingNotification id will be undefined when user access onbording through url
    return props.redirectTo('/adventure')
  }

  return props.incrementOnboardingStep()
}

function mapStateToProps(state) {
  return {
    isUk: isUk(state),
    assetPath: getAssetPath(state.app),
    currentStep: state.onboarding.currentStep,
    onboardingNotification: triggers.selectors.getByEvent(
      'onboarding',
      state.triggers
    ),
    displayName: getDisplayName(state.session),
  }
}

OnboardingContainer.propTypes = {
  isUk: PropTypes.bool.isRequired,
  assetPath: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  dequeuePopup: PropTypes.func,
  onboardingNotification: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  finishOnboarding: PropTypes.func.isRequired,
  queuePopup: PropTypes.func,
  redirectTo: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  dequeuePopup,
  finishOnboarding,
  incrementOnboardingStep,
  queuePopup,
  redirectTo,
})(withTranslate(OnboardingContainer))

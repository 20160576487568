import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'
import { setItem } from 'redux-effects-localstorage'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as supportChat from '@rushplay/support-chat'
import * as I18n from '@rushplay/i18n'
import inventory from '@rushplay/inventory'
import {
  getDisplayName,
  getUserAvatar,
  isSessionActive,
} from '@rushplay/session'
import { saveLanguagePreference } from '@rushplay/api-client'

import {
  changeLanguage,
  getFooterLinks,
  getLanguage,
  getLicenses,
  getSocialLinks,
  getSupportedLanguage,
  getSupportedLanguages,
  isSportsbookEnabled,
} from '../../../store/app'
import { clearPopups } from '../../../common/popup/popups'
import { getUnseenPromotionsCount } from '../../../combined-selectors'

import { MobileMenu as Component } from './mobile-menu'

function mapStateToProps(state) {
  const authenticated = isSessionActive(state.session)
  const promotionsCount = getUnseenPromotionsCount(state)

  return {
    authenticated,
    footerLinks: getFooterLinks(state),
    isSportsbookEnabled: isSportsbookEnabled(state.app),
    getSupportedLanguage: (language) => getSupportedLanguage(state, language),
    language: getLanguage(state.app),
    license: jurisdiction.getLicense(state.jurisdiction),
    licenses: getLicenses(state, {
      jurisdiction: jurisdiction.getLicense(state.jurisdiction),
    }),
    promotionsCount,
    socialLinks: getSocialLinks(state.app),
    supportedLanguages: getSupportedLanguages(state),
    treasureCount: inventory.newItems.selectors.get(state.inventory),
    unreadChatNotifications: supportChat.getUnreadCount(state.supportChat),
    userAvatar: getUserAvatar(state.session),
    displayName: getDisplayName(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearPopups,
      redirectTo,
      setItem,
      onChangeLanguage: changeLanguage,
      onUpdateLanguage: (language) =>
        saveLanguagePreference(language, { version: 1 }),
      onLiveChatClick: () => supportChat.setTabVisibility(true),
    },
    dispatch
  )
}

export const MobileMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(I18n.withTranslate(Component))

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { actions } from '@rushplay/legacy-forms'

import { getBrand, getClientType, login } from '../../store/app'

function mapStateToProps(state) {
  return {
    returningUsername: state.app.username,
    returningUserAvatar: state.app.avatarUrl,
    isLoginInProgress: state.app.isLoginInProgress,
    clientType: getClientType(state.app),
    isCasitabi: getBrand(state.app) === 'casitabi',
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onUsernameClear: () => [
        actions.clearFields('login-page', 'username'),
        actions.focus('login-page', 'username'),
      ],
      login,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@rushplay/common'
import { css } from '@emotion/core'

import { dark60, disabledGray, nightDark } from '../constants/colors'
import { headingFont } from '../constants/typography'

const mapIndexed = R.addIndex(R.map)

const Nav = styled.nav`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 1200px;
  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: center;
  }
`

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 1121px) {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: space-between;
  }
`

const NavChildrenWrapper = styled.div`
  display: flex;
  align-content: center;
  padding-bottom: 3em;

  @media (max-width: 1120px) {
    padding-top: 1em;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const NavItemsContentWrapper = styled.div`
  width: 100%;
  padding-top: 1.2em;
  padding-bottom: 1.2em;

  @media (max-width: 1120px) {
    background: ${nightDark};
  }
`

const LinkItem = styled(Link)`
  padding: 10px 20px;
  border-radius: 1.5em;
  background: #21498e;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  &.active {
    color: #fff;
    background-color: #03c7e9;
  }
  ${props => {
    if (props.disabled) {
      return css`
        opacity: 0.5;
        color: ${disabledGray};
      `
    }

    return css`
      color: ${dark60};

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    `
  }};
`

const ListItem = styled.li`
  margin-right: 10px;
  flex-shrink: 0;
  ${props => props.growItems && css`flex-grow: 1;`};
  &:first-child {
    border-right: 1px rgb(255, 255, 255, 0.2) solid;
    padding-right: 10px;
  }
`

const NavItems = styled.ul`
  margin-left: -1px;
  margin-right: 10px;
  display: flex;
  font-size: 14px;
  font-family: ${headingFont};
  letter-spacing: 0;
  @media (max-width: 1120px) {
    padding-left: 16px;
    margin-bottom: -20px;
    padding-bottom: 20px;
    overflow-x: auto;
  }
`

function PageNav(props) {
  return (
    <Nav>
      <Wrapper>
        <NavItemsContentWrapper>
          <NavItems>
            {mapIndexed(
              (item, index) => (
                <ListItem growItems={props.growItems} key={index}>
                  <LinkItem
                    activeClassName="active"
                    disabled={item.disabled}
                    onlyActiveOnIndex={item.onlyActiveOnIndex}
                    to={item.to}
                  >
                    {item.label}
                  </LinkItem>
                </ListItem>
              ),
              props.items
            )}
          </NavItems>
        </NavItemsContentWrapper>
        {props.children && (
          <NavChildrenWrapper>{props.children}</NavChildrenWrapper>
        )}
      </Wrapper>
    </Nav>
  )
}

export default PageNav

// --

const NAV_ITEM_SHAPE = PropTypes.shape({
  disabled: PropTypes.bool,
  onlyActiveOnIndex: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
})

PageNav.propTypes = {
  children: PropTypes.element,
  growItems: PropTypes.bool,
  items: PropTypes.arrayOf(NAV_ITEM_SHAPE),
}

import styled from '@emotion/styled'
import { rem } from 'polished'

import InventoryTile from '../../common/inventory-tile'

export default styled(InventoryTile)`
  width: ${rem('200px')};
  height: ${rem('200px')};
  margin: 0 auto 1rem;
`

import * as R from 'ramda'

export const RESPONSE_CACHED = 'api-client/http/RESPONSE_CACHED'
export const START_TRACKING_REQUEST = 'api-client/http/START_TRACKING_REQUEST'
export const STOP_TRACKING_REQUEST = 'api-client/http/STOP_TRACKING_REQUEST'

export function cacheResponse(key, expiresAt, response) {
  return {
    type: RESPONSE_CACHED,
    payload: {
      key,
      expiresAt,
      headers: response.headers.raw
        ? response.headers.raw()
        : { ...response.headers },
      status: response.status,
      value: response.value,
    },
  }
}

export function startTrackingRequest(request) {
  return {
    type: START_TRACKING_REQUEST,
    payload: request,
  }
}

export function stopTrackingRequest(request) {
  return {
    type: STOP_TRACKING_REQUEST,
    payload: request,
  }
}

const initialState = {
  requests: [],
  responses: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESPONSE_CACHED: {
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.key]: {
            data: {
              headers: action.payload.headers,
              status: action.payload.status,
              value: action.payload.value,
            },
            expiresAt: action.payload.expiresAt,
          },
        },
      }
    }

    case START_TRACKING_REQUEST: {
      return R.assoc(
        'requests',
        R.uniq(R.append(action.payload, state.requests)),
        state
      )
    }
    case STOP_TRACKING_REQUEST: {
      return R.assoc(
        'requests',
        R.reject(R.equals(action.payload), state.requests),
        state
      )
    }
    default:
      return state
  }
}

export function getPendingRequests(state) {
  return R.prop('requests', state)
}

export function getCachedResponse(state, props) {
  const cache = R.pathOr({ expiresAt: 0 }, ['responses', props.key], state)

  if (cache.expiresAt >= props.now) {
    return cache.data
  }

  return null
}

import * as R from 'ramda'

/**
 * Combine several middlewares into one.
 * @param {Array<Function>} middlewares
 * @return {Function}
 */
export function combineMiddleware(middlewares) {
  return store => next =>
    R.reduceRight(
      (middleware, next) => middleware(store)(next),
      next,
      middlewares
    )
}

/**
 * Notification severity level
 *
 * @readonly
 * @enum {string}
 */
export const NotificationLevel = {
  /**
   * Unexpected failure requiring further action to prevent data loss or
   * undesired behaviour (e.g. form submission failure due to server error,
   * deposit failure due to authentication error)
   */
  ERROR: 'error',

  /**
   * Neutral messages with any kind of information, e.g. new inventory items,
   * new games available.
   */
  INFO: 'info',

  /**
   * Positive reaction on player’s action, e.g. form submission.
   */
  SUCCESS: 'success',

  /**
   * Negative message to inform player about issues that do not require
   * immediate action, e.g. log out due to session limits or message about game
   * provider unavailability.
   */
  WARNING: 'warning',
}

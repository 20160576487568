import * as R from 'ramda'
import React from 'react'

import { toFixed } from './to-fixed'

function updateCount(factor, targetCount) {
  return state => {
    const roundingType = state.count < targetCount ? 'ceil' : 'floor'
    const delta = toFixed(2, (targetCount - state.count) / factor)

    return {
      count: Math[roundingType](state.count + delta) || 0,
      run: delta > 1 / factor,
    }
  }
}

/**
 * Create component with number easing applied to specified prop
 * @param {ReactComponent} Component
 * @param {object} options
 * @param {number} [options.factor=96] - Easing factor; smaller is faster
 * @param {string} options.propName - Prop to ease
 */
export function withCountUp(Component, options = {}) {
  if (!options.propName) {
    throw new Error('options.propName is required')
  }

  // eslint-disable-next-line react/no-deprecated
  return class CountUp extends React.Component {
    constructor(props) {
      super(props)
      this.handleUpdate = this.handleUpdate.bind(this)

      this.state = { count: props[options.propName] || 0 }
    }

    componentWillUnmount() {
      window.cancelAnimationFrame(this.requestID)
    }

    componentDidUpdate(prevProps) {
      if (!Number.isNaN(this.props[options.propName])) {
        if (prevProps[options.propName] !== this.props[options.propName]) {
          this.handleUpdate(true)
        }
        if (this.state.count !== this.props[options.propName]) {
          window.cancelAnimationFrame(this.requestID)
          this.requestID = window.requestAnimationFrame(this.handleUpdate)
        }
      }
    }

    handleUpdate(force) {
      if (force || this.state.run) {
        this.setState(
          updateCount(options.factor || 96, this.props[options.propName])
        )
      }
    }

    render() {
      return React.createElement(
        Component,
        R.assoc(options.propName, Math.round(this.state.count), this.props)
      )
    }
  }
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as locks from '@rushplay/compliance/locks'
import styled from '@emotion/styled'
import { Flex, Space, capitalize } from '@rushplay/common'
import { requestPlayerLimit } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Heading from '../../common/heading'
import HtmlContent from '../../common/html-content'
import Text from '../../common/text'
import { MoneyLimits } from '../../limits/money-limits'

import LockPage from './lock-page'

const LIMIT_TYPE = 'deposit'
const LIMIT_PERIOD = 'day'

const TextLink = styled(Text)`cursor: pointer;`

class UnsetDailyDepositLimit extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showLimits: false }

    this.handleToggleVisibility = this.handleToggleVisibility.bind(this)
  }

  handleToggleVisibility() {
    this.setState({ showLimits: !this.state.showLimits })
  }

  render() {
    if (this.state.showLimits) {
      return <MoneyLimits />
    }

    return (
      <LockPage>
        <Heading level="2" align="center">
          {this.props.translate('locks.unset-daily-deposit-limit.title')}
        </Heading>
        <Space bottom={5}>
          <Text align="center" lineHeight="22">
            <HtmlContent
              html={{
                __html: this.props.translate(
                  'locks.unset-daily-deposit-limit.content',
                  { amount: this.props.initialLimitAmount }
                ),
              }}
            />
          </Text>
          <Space top="18px">
            <Text align="center" size="12" color="gray">
              {this.props.translate('player-limits.crossbrand-prompt')}
            </Text>
          </Space>
        </Space>
        <Space top={7}>
          <Flex column justifyContent="center">
            <Button
              variant="primary"
              onClick={() => this.props.onSubmit(this.props.initialLimitAmount)}
            >
              {this.props.translate('locks.unset-daily-deposit-limit.cta')}
            </Button>
            <Space top={7}>
              <TextLink
                underlined
                align="center"
                color="gray"
                size="14"
                onClick={this.handleToggleVisibility}
              >
                {this.props.translate(
                  'locks.unset-daily-deposit-limit.handle-limits'
                )}
              </TextLink>
            </Space>
          </Flex>
        </Space>
      </LockPage>
    )
  }
}

UnsetDailyDepositLimit.propTypes = {
  initialLimitAmount: PropTypes.number,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const lock = locks.getLock(state.locks, { type: 'unset-daily-deposit-limit' })

  return {
    initialLimitAmount: R.pathOr(0, ['options', 'defaultValue'], lock),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: value =>
      dispatch(
        requestPlayerLimit(LIMIT_TYPE, LIMIT_PERIOD, value, {
          success: () => [
            locks.fetch(),
            locks.remove('unset-daily-deposit-limit'),
          ],
          failure: () => [
            Notifications.add({
              message: 'player-limits.errors.set-limit',
              variables: {
                target: capitalize(LIMIT_TYPE),
                period: LIMIT_PERIOD,
              },
              level: 'error',
            }),
          ],
          version: 2,
        })
      ),
  }
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate
)

export default connector(UnsetDailyDepositLimit)

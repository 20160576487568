import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'
import PropTypes from 'prop-types'
import querystring from 'querystring'

import * as Analytics from '@rushplay/analytics'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Notifications from '@rushplay/notifications'

import * as Constants from '../constants'
import * as CombinedSelectors from '../combined-selectors'
import * as Player from '../store/player'
import * as Session from '../store/session'
import Button from '../common/button'
import { PageSpinner } from '../common/spinner'
import { Payer } from '../payer'

import DepositOfferContainer from './deposit-offers/deposit-offer-container'
import { Disclaimer } from './disclaimer'
import { Headline } from './headline'
import { TransactionAmountInput } from './transaction-amount-input'
import { TransactionStep } from './transaction-step'
import { useTransactionAmountLimits } from './use-transaction-amount-limits'

const pnpLimits = {
  EUR: {
    min: 10,
    max: 10000,
  },
  SEK: {
    min: 100,
    max: 100000,
  },
  USD: {
    min: 15,
    max: 10000,
  },
}

const initialAmounts = {
  EUR: 50,
  SEK: 500,
  USD: 50,
}

function getConfig(state) {
  const preferredWelcomeOfferType =
    Forms.value(state.formsv2, {
      form: 'transaction',
      field: 'preferredWelcomeOfferType',
    }) || null
  const amount =
    Forms.value(state.formsv2, {
      form: 'transaction',
      field: 'amount',
    }) || null

  const localCurrency = CombinedSelectors.getLocalCurrency(state)

  return {
    amount,
    initialAmount: initialAmounts[localCurrency] || initialAmounts['EUR'],
    pnpLimits: pnpLimits[localCurrency] || pnpLimits['EUR'],
    preferredWelcomeOfferType,
    selectedType: preferredWelcomeOfferType,
  }
}

function PayNPlayTransaction(props) {
  const dispatch = ReactRedux.useDispatch()
  const i18n = I18n.useI18n()
  const [step, setStep] = React.useState(TransactionStep.Amount)
  const [payerLoading, setPayerLoading] = React.useState(true)
  const [depositMethod, setDepositMethod] = React.useState('')
  const config = ReactRedux.useSelector(getConfig)
  const { limits, loading } = useTransactionAmountLimits(
    Constants.TransactionType.DEPOSIT
  )
  const query = querystring.parse(R.tail(props.location.search))

  const queryAmount = Number.parseInt(query.amount)
  const queryOfferId = Number.parseInt(query.offerId)

  const loginWithoutDeposit = query?.type === 'no-deposit'
  const amountLimits = React.useMemo(
    () =>
      !loading && {
        max: (limits && limits.max / 100) || config.pnpLimits.max || null,
        min: (limits && limits.min / 100) || config.pnpLimits.min || null,
      },
    [limits, loading]
  )

  React.useEffect(() => {
    return function cleanup() {
      dispatch(Forms.destroyForm('transaction'))
    }
  }, [])

  React.useEffect(() => {
    if (loginWithoutDeposit) {
      setStep(TransactionStep.Payer)
    }
  }, [loginWithoutDeposit])

  const handleFailure = React.useCallback(
    (status, payload = {}) => {
      dispatch(
        Player.fetch({
          success: () => Analytics.completeTransaction(status, depositMethod),
        })
      )
      if (payload.errors) {
        R.forEach((error) => {
          dispatch(
            Notifications.add({ message: error.message, level: 'error' })
          )
        }, payload.errors)
      } else if (payload.error) {
        dispatch(
          Notifications.add({
            message: payload.error.message,
            variables: payload.error.variables,
            level: 'error',
          })
        )
      }
    },
    [dispatch, depositMethod]
  )

  const handleCancel = React.useCallback(() => {
    handleFailure('cancel')
    setStep(TransactionStep.Amount)
  }, [])

  const handleSuccess = React.useCallback(
    ({ sessionToken, error = {} }) => {
      const [, sessionRequest] = dispatch(Session.refresh(sessionToken))
      sessionRequest
        .then(() => {
          return dispatch([
            !error.message &&
              Player.fetch({
                token: sessionToken,
                success: () =>
                  Analytics.completeTransaction('successful', depositMethod),
              }),
            ReactRouterRedux.replace('/'),
          ])
        })
        .catch((err) =>
          // eslint-disable-next-line  no-console
          console.error(err)
        )

      if (error.message) {
        dispatch(Notifications.add({ message: error.message, level: 'error' }))
      }
    },
    [dispatch, depositMethod]
  )

  const onLoad = React.useCallback(() => setPayerLoading(false), [])

  if (loading) {
    return <PageSpinner />
  }

  if (step === TransactionStep.Amount) {
    return (
      <Common.Box margin="30px auto" maxWidth="706px" px={3} width="100%">
        <Common.Space bottom={9}>
          <Headline>{i18n.translate('wallet.deposit.headline')}</Headline>
        </Common.Space>

        <Forms.Form name="transaction" mountPoint="formsv2">
          {(form) => {
            const isSubmitDisabled =
              config.selectedType === 'none' ||
              R.not(form.valid) ||
              amountLimits.max < config.amount ||
              amountLimits.min > config.amount

            return (
              <React.Fragment>
                <Common.Space bottom={9}>
                  <TransactionAmountInput
                    initialAmount={config.initialAmount}
                    maxAmount={amountLimits.max}
                    minAmount={amountLimits.min}
                    transactionType={Constants.TransactionType.DEPOSIT}
                  />
                </Common.Space>

                <DepositOfferContainer />

                <Common.Box display="flex" justifyContent="center">
                  <Common.Link
                    to={{
                      pathname: '/register/deposit',
                      query: {
                        amount: config.amount,
                        offerId: config.preferredWelcomeOfferType,
                      },
                    }}
                    disabled={isSubmitDisabled}
                    onClick={() => setStep(TransactionStep.Payer)}
                  >
                    <Button variant="primary" disabled={isSubmitDisabled}>
                      {i18n.translate('bank-id.deposit-play')}
                    </Button>
                  </Common.Link>
                </Common.Box>
              </React.Fragment>
            )
          }}
        </Forms.Form>

        <Common.Space top={6}>
          <Disclaimer />
        </Common.Space>
      </Common.Box>
    )
  }

  if (step === TransactionStep.Payer) {
    return (
      <Common.Box margin="30px auto" maxWidth="706px" px={3} width="100%">
        <Common.Box height={payerLoading ? '0px' : '520px'}>
          <Payer
            amountCents={queryAmount ? queryAmount * 100 : null}
            transactionType={
              loginWithoutDeposit ? 'auth' : Constants.TransactionType.DEPOSIT
            }
            offerId={queryOfferId || null}
            onCancel={handleCancel}
            onFailure={handleFailure}
            onLoad={onLoad}
            onSelectMethod={setDepositMethod}
            onSuccess={handleSuccess}
          />
        </Common.Box>
      </Common.Box>
    )
  }

  return null
}

PayNPlayTransaction.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PayNPlayTransaction

import { createSelector } from 'reselect'

import * as Analytics from '@rushplay/analytics'
import * as supportChat from '@rushplay/support-chat'
import inventory from '@rushplay/inventory'
import {
  getCountryCode as getRegistrationCountryCode,
  getCurrency as getCurrencyFromSession,
  getVip,
  isSessionActive,
} from '@rushplay/session'

import * as Player from './store/player'
import * as lookup from './store/lookup'
import { unseenPromotionCount } from './store/promotions'

/**
 * Get local currency.
 * @param {Object} state
 * @returns {string} local currency
 */
export const getLocalCurrency = createSelector(
  [
    (state) => isSessionActive(state.session),
    (state) => getCurrencyFromSession(state.session),
    (state) => lookup.getCurrency(state.lookup),
  ],
  (authenticated, sessionCurrency, lookupCurrency) => {
    return authenticated ? sessionCurrency : lookupCurrency
  }
)

/**
 * Get player market.
 * @param {Object} state
 * @returns {string} player market
 */
export const getMarket = createSelector(
  [
    (state) => getRegistrationCountryCode(state.session),
    (state) => lookup.getCountryCode(state.lookup),
  ],
  (registrationCountryCode, lookupCountryCode) => {
    return registrationCountryCode || lookupCountryCode
  }
)

export function getAffiliateSubId(state) {
  return (
    Player.getPlayerSubId(state.session) || Analytics.getSubId(state.analytics)
  )
}

/**
 * Get promotions count.
 * @param {Object} state
 * @returns {number} promotions count
 */
export const getUnseenPromotionsCount = createSelector(
  [
    (state) => state.promotions,
    (state) => isSessionActive(state.session),
    (state) => getVip(state.session),
    (state) => state.player.depositNumber,
  ],
  (promotionsState, authenticated, vip, depositNumber) =>
    unseenPromotionCount(promotionsState, {
      authenticated,
      vip,
      depositNumber,
    })
)

/**
 * Get notifications state.
 * @param {Object} state
 * @returns {boolean} has notifications state
 */
export const getNotificationsState = createSelector(
  [(state) => state],
  (state) => {
    const unreadChatNotifications = supportChat.getUnreadCount(
      state.supportChat
    )
    const treasureCount = inventory.newItems.selectors.get(state.inventory)
    const promotionsCount = getUnseenPromotionsCount(state)
    return unreadChatNotifications + treasureCount + promotionsCount > 0
  }
)

import PropTypes from 'prop-types'
import React from 'react'

import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import {
  PopupDescription,
  PopupDescriptionSmall,
  PopupImage,
  PopupTitle,
} from '../common/popup/popup-styles.js'

const EMPTY_OBJECT = Object.freeze({})

function InventorySecretItemDetails(props) {
  const lockedState = props.item.found ? 'unlocked' : 'locked'
  const isClaimable = props.item.claimable && props.item.found
  const claimableState = isClaimable ? 'claimable' : 'locked'
  const descriptionState = isClaimable ? 'claimable' : lockedState

  return (
    <div>
      <PopupImage src={props.item.imageUrl} />

      <PopupTitle>
        {props.translate('inventory.secret-item.popup.title')}
      </PopupTitle>

      <PopupDescription
        html={{
          __html: props.translate(
            `inventory.secret-item.popup.description.${descriptionState}`
          ),
        }}
      />

      <PopupDescriptionSmall>
        {props.translate(
          `inventory.secret-item.popup.descriptionsmall.${claimableState}`
        )}
      </PopupDescriptionSmall>

      <Button
        stretch
        minimalPadding
        loading={props.claimSecretItemInProgress}
        onClick={() => props.onClaim(props.item.id, isClaimable)}
      >
        {props.translate(
          `inventory.secret-item.popup.button.${claimableState}`
        )}
      </Button>
    </div>
  )
}
InventorySecretItemDetails.defaultProps = {
  item: EMPTY_OBJECT,
}

InventorySecretItemDetails.propTypes = {
  claimSecretItemInProgress: PropTypes.bool,
  item: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  onClaim: PropTypes.func.isRequired,
}

export default withTranslate(InventorySecretItemDetails)

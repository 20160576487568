import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as Common from '@rushplay/common'
import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import Spinner from '../common/spinner'
import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
} from '../constants/typography'
import { getLanguage } from '../store/app'
import { whitestBlue } from '../constants/colors'

import GamblingHistoryTable from './gambling-history-table'
import { useFetchPaginatedHistory } from './use-fetch-paginated-history'

const Wrapper = styled.div`
  max-width: 514px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
`

const Header = styled.div`
  font-family: ${headingFont};
  font-size: ${baseFontSizeLarge};
  padding: 16px;
  background-color: #091a36;
  text-align: center;
`

const ActionButton = styled.button`
  width: 100%;
  border: none;
  background-color: #2b67d2;
  padding: 16px;
  color: white;
  font-size: ${baseFontSizeSmall};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FilterWrapper = styled.div`
  border: none;
  background-color: #2f4774;
  padding: 16px;
  color: white;
  font-size: ${baseFontSizeSmall};
  transition: 0.3s;
`

const NotFound = styled.div`
  background-color: ${whitestBlue};
  font-family: ${headingFont};
  font-size: ${baseFontSizeSmall};
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  width: 100%;
`

const DateInput = styled.input`
  margin: initial;
  box-sizing: border-box;
  font-size: 16px;
  text-align: left;
  border: none;
  padding: 5px;
  background: #091a369e;
  color: white;
  font-family: inherit;
  font-size: 12px;

  &:focus {
    outline: none;
  }
  &:-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0px 9999px white;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  &::placeholder {
    color: #c3c3c3;
  }
`
function getMinStartDate() {
  const date = new Date()

  date.setMonth(date.getMonth() - 6)

  return date.toISOString().split('T')[0]
}

function getFormattedToday() {
  const date = new Date()

  return date.toISOString().split('T')[0]
}

function getPastDate() {
  const date = new Date()

  date.setDate(date.getDate() - 30)

  return date.toISOString().split('T')[0]
}

function getMaxEndDate(fromDate) {
  const date = new Date(fromDate)
  date.setDate(date.getDate() + 31)

  const today = new Date()

  if (date > today.getTime()) {
    return today.toISOString().split('T')[0]
  }

  return date.toISOString().split('T')[0]
}

const initialFilter = {
  startDate: getPastDate(),
  endDate: getFormattedToday(),
}

function GamblingHistoryContainer(props) {
  const [date, setDate] = React.useState(initialFilter)

  const [showTotals, setShowTotals] = React.useState(false)

  const paginatedHistory = useFetchPaginatedHistory({ cursor: props.cursor })

  function handleChangedate(value, name) {
    if (value) {
      setDate({
        ...date,
        [name]: value,
      })
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    paginatedHistory.handleFilter({
      startDate: date.startDate,
      endDate: date.endDate,
    })
  }

  function handleClearFilter() {
    setDate(initialFilter)
    paginatedHistory.handleClearFilter()
  }

  return (
    <Wrapper>
      <Header>{props.translate('gambling-history.title')}</Header>

      <FilterWrapper>
        <Common.Box pb={5} pt={0}>
          {props.translate('gambling-history.description')}
        </Common.Box>
        <form onSubmit={handleSubmit}>
          <Common.Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Common.Box display="flex" flexDirection="column">
              <Common.Text style={{ paddingBottom: '5px' }}>
                {props.translate('history-date-range-start-description')}
              </Common.Text>
              <DateInput
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                onChange={(e) => handleChangedate(e.target.value, 'startDate')}
                value={date.startDate}
                min={getMinStartDate()}
                max={getFormattedToday()}
                required
              />
            </Common.Box>
            <Common.Box display="flex" flexDirection="column">
              <Common.Text style={{ paddingBottom: '5px' }}>
                {props.translate('history-date-range-end-description')}
              </Common.Text>
              <DateInput
                type="date"
                name="till"
                pattern="\d{4}-\d{2}-\d{2}"
                onChange={(e) => handleChangedate(e.target.value, 'endDate')}
                value={date.endDate}
                min={date.startDate}
                max={getMaxEndDate(date.startDate)}
                required
              />
            </Common.Box>
          </Common.Box>

          <Common.Box display="flex" width="100%" pt={3}>
            <Button small type="submit">
              {props.translate('gambling-history.trigger-date-filter')}
            </Button>
            {paginatedHistory.isFiltered && (
              <Common.Box pl={1}>
                <Button small onClick={handleClearFilter}>
                  {props.translate('gambling-history.disable-date-filter')}
                </Button>
              </Common.Box>
            )}
          </Common.Box>
        </form>
      </FilterWrapper>

      <ActionButton
        type="button"
        onClick={() => setShowTotals((prev) => !prev)}
      >
        {showTotals
          ? props.translate('gambling-history.hide-totals')
          : props.translate('gambling-history.show-totals')}
      </ActionButton>

      {!props.cursor &&
        paginatedHistory.noEdges &&
        !paginatedHistory.fetching && (
          <NotFound>{props.translate('gambling-history.not-found')}</NotFound>
        )}

      {paginatedHistory.fetching &&
      ((paginatedHistory.isFiltered && paginatedHistory.firstTimeFilter) ||
        paginatedHistory.noEdges) ? (
        <Common.Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Common.Box>
      ) : (
        <GamblingHistoryTable
          items={paginatedHistory.transactions}
          language={props.language}
          totals={paginatedHistory.totals}
          showTotals={showTotals}
          loading={paginatedHistory.fetching}
        />
      )}

      {!(paginatedHistory.isFiltered && paginatedHistory.fetching) &&
        paginatedHistory?.pageInfo?.hasNextPage && (
          <ActionButton
            type="button"
            onClick={() => paginatedHistory.loadMore()}
          >
            {paginatedHistory.fetching ? (
              <Spinner />
            ) : (
              props.translate('gambling-history.load-more')
            )}
          </ActionButton>
        )}
    </Wrapper>
  )
}

GamblingHistoryContainer.propTypes = {
  language: PropTypes.string,
  translate: PropTypes.func.isRequired,
  cursor: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    language: getLanguage(state.app),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onError: () =>
      dispatch(
        Notifications.add({ message: 'errors.fetch-failed', level: 'error' })
      ),
  }
}

export default withTranslate(
  connect(mapStateToProps, mapDispatchToProps)(GamblingHistoryContainer)
)

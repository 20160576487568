import React from 'react'

export function IconInfo() {
  return (
    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.333 40.625c0 .586-.455 1.042-1.041 1.042H17.708a1.028 1.028 0 0 1-1.041-1.042v-5.208c0-.586.455-1.042 1.041-1.042h3.125V23.958h-3.125a1.028 1.028 0 0 1-1.041-1.041v-5.209c0-.586.455-1.041 1.041-1.041h10.417c.586 0 1.042.455 1.042 1.041v16.667h3.125c.586 0 1.041.456 1.041 1.042v5.208zm-4.166-29.167c0 .586-.456 1.042-1.042 1.042h-6.25a1.028 1.028 0 0 1-1.042-1.042V6.25c0-.586.456-1.042 1.042-1.042h6.25c.586 0 1.042.456 1.042 1.042v5.208zM50 25C50 11.198 38.802 0 25 0S0 11.198 0 25s11.198 25 25 25 25-11.198 25-25z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { STORAGE_TYPE, getItem } from 'redux-effects-localstorage'
import { bind } from 'redux-effects'

import * as Notifications from '@rushplay/notifications'

import * as lookup from '../../store/lookup'
import pathWithoutLanguage from '../../util/path-without-language'
import { PageSpinner } from '../../common/spinner'
import { changeLanguage, updateConfig, updateCountries } from '../../store/app'
import {
  utmCampaignSessionStorageKey,
  utmMediumSessionStorageKey,
  utmSourceSessionStorageKey,
} from '../../constants/config'

export class AppPreloader extends Component {
  constructor(props) {
    super(props)
    this.getTranslations = this.getTranslations.bind(this)
    this.handleKeepAlive = this.handleKeepAlive.bind(this)
  }

  componentWillMount() {
    this.props.fetchCountryCode({
      success: (res) => [
        bind(getItem('LANGUAGE'), (language) => [
          changeLanguage(language),
          lookup.init({
            countryCode: R.toUpper(res.value.countryCode),
            currency: res.value.country.currency,
          }),
        ]),
      ],
      failure: (error) =>
        Notifications.add({ message: error.value, level: 'error' }),
      version: 1,
    })

    this.props.fetchCountries({
      success: (res) => updateCountries(res.value),
      failure: (error) =>
        Notifications.add({ message: error.value, level: 'error' }),
      version: 1,
    })

    this.props.fetchIpInfo({
      success: (res) => updateConfig(res.value),
      failure: (error) =>
        Notifications.add({ message: error.value, level: 'error' }),
      version: 1,
    })

    this.props.onFetchMetadata()

    this.props
      .getItem('LIVE_CASINO_ONBOARDING')
      .then(this.props.completeOnboarding)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )

    this.props
      .getItem('USERNAME')
      .then(this.props.storeUsername)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props
      .getItem('AVATAR_URL')
      .then(this.props.storeAvatarUrl)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props
      .getItem('referrals')
      .then(this.props.storeReferrals)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props
      .getItem('LOGGED_IN_ONCE')
      .then(this.props.storeLoggedInOnce)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )

    this.props
      .getItem('timeToRealityCheck', STORAGE_TYPE.session)
      .then(this.props.updateTimeToRealityCheck)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props.onRealityCheckRestore()
  }

  componentDidMount() {
    this.props.onMount()
    this.props.onBrandCurrencyUpdate()

    // netentSessionId for anonymous user to play in fun mode
    const netentSessionId = `DEMO-${Math.random()
      .toString(19)
      .slice(2, 34)}-EUR`

    this.props.updateNetEntSessionId(netentSessionId)

    this.props.onSessionRestore()

    this.props.onJurisdictionFetch()

    this.props
      .getItem(utmCampaignSessionStorageKey, STORAGE_TYPE.session)
      .then(this.props.storeUtmCampaign)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props
      .getItem(utmMediumSessionStorageKey, STORAGE_TYPE.session)
      .then(this.props.storeUtmMedium)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
    this.props
      .getItem(utmSourceSessionStorageKey, STORAGE_TYPE.session)
      .then(this.props.storeUtmSource)
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )

    if (this.props.sessionToken) {
      document.addEventListener('visibilitychange', this.handleKeepAlive)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brandCurrency !== this.props.brandCurrency) {
      this.props.onBrandCurrencyUpdate()
    }

    if (
      prevProps.isCountryCodeDetected !== this.props.isCountryCodeDetected ||
      prevProps.language !== this.props.language
    ) {
      this.getTranslations(this.props.language)
    }

    if (prevProps.sessionToken !== this.props.sessionToken) {
      this.getTranslations()
      this.props.onJurisdictionFetch()
      document.removeEventListener('visibilitychange', this.handleKeepAlive)

      if (this.props.sessionToken) {
        document.addEventListener('visibilitychange', this.handleKeepAlive)
      }
    }
  }

  handleKeepAlive() {
    if (!document.hidden) {
      this.props.onSessionCheck(this.props.sessionToken)
    }
  }

  getTranslations(storedLanguage) {
    if (this.props.isCountryCodeDetected) {
      const currentPath = pathWithoutLanguage(window.location.pathname)
      const languageFromURL = window.location.pathname.split('/')[1]
      const supportedLanguage = this.props.getSupportedLanguage([
        languageFromURL,
        storedLanguage,
        this.props.locationLanguage,
      ])

      if (languageFromURL) {
        this.props.setItem('LANGUAGE', supportedLanguage)
      }

      this.props.requestTranslations(
        `${this.props.cdnHost}/${this.props.cdnPrefix}`,
        supportedLanguage
      )

      this.props.changeLanguage(supportedLanguage)
      this.props.redirectTo({
        pathname: `/${supportedLanguage + currentPath}`,
        search: window.location.search,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleKeepAlive)
  }

  render() {
    if (
      this.props.isConfigFetched &&
      this.props.isCountryCodeDetected &&
      this.props.isCurrentTranslationPresent &&
      this.props.isMetadataLoaded &&
      this.props.isSessionUpdated
    ) {
      return <div>{this.props.children}</div>
    }

    return <PageSpinner />
  }
}

AppPreloader.propTypes = {
  authenticated: PropTypes.bool,
  brandCurrency: PropTypes.string,
  cdnHost: PropTypes.string,
  cdnPrefix: PropTypes.string,
  changeLanguage: PropTypes.func,
  children: PropTypes.element.isRequired,
  completeOnboarding: PropTypes.func.isRequired,
  fetchCountryCode: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchIpInfo: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  getSupportedLanguage: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isConfigFetched: PropTypes.bool,
  isCountryCodeDetected: PropTypes.bool,
  isCurrentTranslationPresent: PropTypes.bool,
  isMetadataLoaded: PropTypes.bool,
  isSessionUpdated: PropTypes.bool,
  language: PropTypes.string,
  locationLanguage: PropTypes.string,
  onBrandCurrencyUpdate: PropTypes.func.isRequired,
  onJurisdictionFetch: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onRealityCheckRestore: PropTypes.func.isRequired,
  onSessionCheck: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
  requestTranslations: PropTypes.func,
  onFetchMetadata: PropTypes.func,
  onSessionRestore: PropTypes.func.isRequired,
  sessionToken: PropTypes.string,
  setItem: PropTypes.func.isRequired,
  storeAvatarUrl: PropTypes.func.isRequired,
  storeUtmCampaign: PropTypes.func.isRequired,
  storeUtmMedium: PropTypes.func.isRequired,
  storeUtmSource: PropTypes.func.isRequired,
  storeReferrals: PropTypes.func.isRequired,
  storeUsername: PropTypes.func.isRequired,
  storeLoggedInOnce: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  updateNetEntSessionId: PropTypes.func.isRequired,
  updateTimeToRealityCheck: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Field } from '@rushplay/legacy-forms'
import { isExistingTranslation, withTranslate } from '@rushplay/i18n'

import CheckboxField from '../../common/checkbox-field'
import HtmlContent from '../../common/html-content'
import InputField from '../../common/input-field'
import Select from '../../common/select'
import fieldError from '../../field-error'
import formatCountryCodeOptions from '../lib/format-country-code-options'
import formatCountryOptions from '../lib/format-country-options'
import getCountryCode from '../lib/get-country-code'
import validators from '../../validators'
import { baseFontSizeSmall } from '../../constants/typography'
import { berry } from '../../constants/colors'

import StepTemplate from './step-template'
import { Description, InlineRow, InlineRowItem, Row } from './styles'

const BIRTH_YEAR_MAX = new Date().getFullYear() - 18
const BIRTH_YEAR_MIN = new Date().getFullYear() - 100

function getBirthYearOptions(minYear, maxYear) {
  const birthYearOptions = new Array(maxYear - minYear)
  for (let i = maxYear; i >= minYear; i--) {
    birthYearOptions.push({ label: i, value: i })
  }

  return birthYearOptions
}

function getNumbersRange(min, max) {
  const array = new Array(max - min)
  for (let i = min; i <= max; i++) {
    array.push({ label: i, value: i })
  }

  return array
}

const CheckboxRow = styled(Row)`
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.5;
`
const CountryCode = styled(InlineRowItem)`
  flex-grow: 0.5;

  @media screen and (min-width: 375px) {
    flex-grow: 0.4;
  }
`
const ZipCode = styled(InlineRowItem)`
  flex: 0.6;
`
const TermsCondition = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${berry};
  }
`
const PrivacyPolicy = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${berry};
  }
`
const FieldWrapper = styled.div`
  display: inline-block;
`
const DateFieldWrapper = styled.div`
  max-width: 130px;
`

const LegalInformation = styled(HtmlContent)`
  text-align: left;
  margin-left: 36px;
  line-height: 1.5;
  font-size: ${baseFontSizeSmall};
`

/* eslint-disable react/prop-types */
function CountryCallingCodeSelect(props) {
  return (
    <Select
      name="countryCallingCode"
      label={props.label}
      options={formatCountryCodeOptions(props.countries)}
      value={props.value}
      onChange={props.onChange}
      hasExternalLabel={props.hasExternalLabel}
    />
  )
}
/* eslint-enable react/prop-types */

function Step2(props) {
  if (!props.countryCode) {
    return null
  }

  const initialCountryCallingCode = getCountryCode(
    props.countryCode,
    props.countries
  )

  const tacLabelLink = (
    <span>
      {`${props.translate('register.form.agree-terms')} `}
      <TermsCondition onClick={props.onTermsConditionsClick}>
        {props.translate('sign-up.terms-conditions')}
      </TermsCondition>
      {props.displayGdprContent && (
        <span>
          {` ${props.translate('sign-up.and-our')} `}
          <PrivacyPolicy onClick={props.onPrivacyPolicyClick}>
            {props.translate('sign-up.privacy-policy')}
          </PrivacyPolicy>
        </span>
      )}
    </span>
  )

  const hasExternalLabel = props.isCasitabi

  if (props.isCasitabi) {
    return (
      <StepTemplate
        {...props.stepTemplate}
        nextStepDisabled={!props.isValid}
        nextStepLabel={props.translate('register.continue')}
        title={props.translate('register.form.information')}
        onBack={props.onBack}
        onNextStep={props.onNextStep}
      >
        <div>
          <Row>
            <Field
              autoFocus
              component={InputField}
              group="step2"
              label={
                hasExternalLabel
                  ? props.translate('sign-up.last-name.external-label')
                  : props.translate('sign-up.last-name.label')
              }
              placeholder={props.translate('sign-up.last-name.placeholder')}
              tooltip={
                fieldError(props.fields.lastName) &&
                props.translate(fieldError(props.fields.lastName))
              }
              name="lastName"
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
              hasExternalLabel={hasExternalLabel}
            />
          </Row>
          <Row>
            <Field
              component={InputField}
              group="step2"
              label={
                hasExternalLabel
                  ? props.translate('sign-up.first-name.external-label')
                  : props.translate('sign-up.first-name.label')
              }
              placeholder={props.translate('sign-up.first-name.placeholder')}
              tooltip={
                fieldError(props.fields.firstName) &&
                props.translate(fieldError(props.fields.firstName))
              }
              name="firstName"
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
              hasExternalLabel={hasExternalLabel}
            />
          </Row>
          <InlineRow>
            <InlineRowItem>
              <DateFieldWrapper>
                <Field
                  component={Select}
                  group="step2"
                  label={props.translate('sign-up.birth-year.label')}
                  firstOption={props.translate(
                    'sign-up.birth-year.first-option'
                  )}
                  name="year"
                  options={getBirthYearOptions(BIRTH_YEAR_MIN, BIRTH_YEAR_MAX)}
                  tooltip={
                    fieldError(props.fields.year) &&
                    props.translate(fieldError(props.fields.year), {
                      min: BIRTH_YEAR_MIN,
                      max: BIRTH_YEAR_MAX,
                    })
                  }
                  validator={[
                    validators.required,
                    validators.min(BIRTH_YEAR_MIN),
                    validators.max(BIRTH_YEAR_MAX),
                  ]}
                  hasExternalLabel={hasExternalLabel}
                />
              </DateFieldWrapper>
            </InlineRowItem>
            <InlineRowItem>
              <DateFieldWrapper>
                <Field
                  component={Select}
                  group="step2"
                  label={props.translate('sign-up.birth-month.label')}
                  firstOption={props.translate(
                    'sign-up.birth-month.first-option'
                  )}
                  options={getNumbersRange(1, 12)}
                  name="month"
                  tooltip={
                    fieldError(props.fields.month) &&
                    props.translate(fieldError(props.fields.month), {
                      min: 1,
                      max: 12,
                    })
                  }
                  validator={[
                    validators.required,
                    validators.min(1),
                    validators.max(12),
                  ]}
                  hasExternalLabel={hasExternalLabel}
                />
              </DateFieldWrapper>
            </InlineRowItem>
            <InlineRowItem>
              <DateFieldWrapper>
                <Field
                  component={Select}
                  group="step2"
                  label={props.translate('sign-up.birth-day.label')}
                  firstOption={props.translate(
                    'sign-up.birth-day.first-option'
                  )}
                  options={getNumbersRange(1, 31)}
                  name="day"
                  tooltip={
                    fieldError(props.fields.day) &&
                    props.translate(fieldError(props.fields.day), {
                      min: 1,
                      max: 31,
                    })
                  }
                  validator={[
                    validators.required,
                    validators.min(1),
                    validators.max(31),
                  ]}
                  hasExternalLabel={hasExternalLabel}
                />
              </DateFieldWrapper>
            </InlineRowItem>
          </InlineRow>
          <Row>
            <Field
              component={Select}
              group="step2"
              countries={props.countries}
              initialValue={props.countryCode}
              name="countryCode"
              label={props.translate('sign-up.country.label')}
              validator={validators.required}
              options={formatCountryOptions(props.countries)}
              hasExternalLabel={hasExternalLabel}
            />
          </Row>
          <InlineRow>
            <ZipCode>
              <Field
                component={InputField}
                group="step2"
                label={props.translate('sign-up.zip.label')}
                placeholder={props.translate('sign-up.zip.placeholder')}
                name="zip"
                tooltip={
                  fieldError(props.fields.zip) &&
                  props.translate(fieldError(props.fields.zip))
                }
                type="text"
                validator={[
                  validators.required,
                  validators.noJapaneeseCharacters,
                ]}
                hasExternalLabel={hasExternalLabel}
              />
            </ZipCode>
            <InlineRowItem>
              <Field
                component={InputField}
                group="step2"
                label={props.translate('sign-up.city.label')}
                placeholder={props.translate('sign-up.city.placeholder')}
                name="city"
                tooltip={
                  fieldError(props.fields.city) &&
                  props.translate(fieldError(props.fields.city))
                }
                type="text"
                validator={[
                  validators.required,
                  validators.noJapaneeseCharacters,
                ]}
                hasExternalLabel={hasExternalLabel}
              />
            </InlineRowItem>
          </InlineRow>
          <Row>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.street.label')}
              placeholder={props.translate('sign-up.street.placeholder')}
              name="street"
              tooltip={
                fieldError(props.fields.street) &&
                props.translate(fieldError(props.fields.street))
              }
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
              hasExternalLabel={hasExternalLabel}
            />
          </Row>
          <Row>
            <Description>
              {props.translate(
                props.phoneVerificationRequired
                  ? 'registration.phone-validation-true'
                  : 'registration.phone-validation-false'
              )}
            </Description>
          </Row>
          <InlineRow>
            <CountryCode>
              <Field
                component={CountryCallingCodeSelect}
                group="step2"
                label={props.translate('sign-up.country-code.label')}
                countries={props.countries}
                countryCode={props.countryCode}
                initialValue={initialCountryCallingCode}
                name="countryCallingCode"
                hasExternalLabel={hasExternalLabel}
              />
            </CountryCode>
            <InlineRowItem>
              <Field
                component={InputField}
                group="step2"
                label={
                  hasExternalLabel
                    ? props.translate('sign-up.phone-number.external-label')
                    : props.translate('sign-up.phone-number.label')
                }
                placeholder={props.translate(
                  'sign-up.phone-number.placeholder'
                )}
                initialValue={props.mobile}
                name="mobile"
                tooltip={
                  fieldError(props.fields.mobile) &&
                  props.translate(fieldError(props.fields.mobile))
                }
                type="text"
                validator={[validators.required, validators.digitsOnly]}
                hasExternalLabel={hasExternalLabel}
              />
            </InlineRowItem>
          </InlineRow>
          <CheckboxRow>
            <FieldWrapper>
              <Field
                component={CheckboxField}
                group="step2"
                name="termsConditions"
                label={tacLabelLink}
                validator={validators.required}
              />
            </FieldWrapper>
          </CheckboxRow>

          {isExistingTranslation('sign-up.legal-information') && (
            <LegalInformation
              html={{
                __html: props.translate('sign-up.legal-information'),
              }}
            />
          )}

          {props.displayGdprContent && (
            <CheckboxRow>
              <Field
                component={CheckboxField}
                group="step2"
                name="allowMarketing"
                label={props.translate('register.allow-marketing')}
              />
            </CheckboxRow>
          )}
        </div>
      </StepTemplate>
    )
  }
  return (
    <StepTemplate
      {...props.stepTemplate}
      loading={props.loading}
      nextStepDisabled={!props.isValid}
      nextStepLabel={props.translate('register.continue')}
      title={props.translate('register.form.information')}
      onBack={props.onBack}
      onNextStep={props.onNextStep}
    >
      <div>
        <Row>
          <Field
            autoFocus
            component={InputField}
            group="step2"
            label={props.translate('sign-up.first-name.label')}
            tooltip={
              fieldError(props.fields.firstName) &&
              props.translate(fieldError(props.fields.firstName))
            }
            name="firstName"
            type="text"
            validator={[validators.required, validators.noJapaneeseCharacters]}
          />
        </Row>
        <Row>
          <Field
            component={InputField}
            group="step2"
            label={props.translate('sign-up.last-name.label')}
            tooltip={
              fieldError(props.fields.lastName) &&
              props.translate(fieldError(props.fields.lastName))
            }
            name="lastName"
            type="text"
            validator={[validators.required, validators.noJapaneeseCharacters]}
          />
        </Row>
        <InlineRow>
          <InlineRowItem>
            <DateFieldWrapper>
              <Field
                component={InputField}
                group="step2"
                label={props.translate('sign-up.birth-day.label')}
                max={31}
                name="day"
                tooltip={
                  fieldError(props.fields.day) &&
                  props.translate(fieldError(props.fields.day), {
                    min: 1,
                    max: 31,
                  })
                }
                type="text"
                validator={[
                  validators.required,
                  validators.min(1),
                  validators.max(31),
                  validators.digitsOnly,
                ]}
              />
            </DateFieldWrapper>
          </InlineRowItem>
          <InlineRowItem>
            <DateFieldWrapper>
              <Field
                component={InputField}
                group="step2"
                label={props.translate('sign-up.birth-month.label')}
                max={12}
                name="month"
                tooltip={
                  fieldError(props.fields.month) &&
                  props.translate(fieldError(props.fields.month), {
                    min: 1,
                    max: 12,
                  })
                }
                type="text"
                validator={[
                  validators.required,
                  validators.min(1),
                  validators.max(12),
                  validators.digitsOnly,
                ]}
              />
            </DateFieldWrapper>
          </InlineRowItem>
          <InlineRowItem>
            <DateFieldWrapper>
              <Field
                component={InputField}
                group="step2"
                label={props.translate('sign-up.birth-year.label')}
                name="year"
                tooltip={
                  fieldError(props.fields.year) &&
                  props.translate(fieldError(props.fields.year), {
                    min: BIRTH_YEAR_MIN,
                    max: BIRTH_YEAR_MAX,
                  })
                }
                type="text"
                validator={[
                  validators.required,
                  validators.min(BIRTH_YEAR_MIN),
                  validators.max(BIRTH_YEAR_MAX),
                  validators.digitsOnly,
                ]}
              />
            </DateFieldWrapper>
          </InlineRowItem>
        </InlineRow>
        <Row>
          <Field
            component={InputField}
            group="step2"
            label={props.translate('sign-up.street.label')}
            name="street"
            tooltip={
              fieldError(props.fields.street) &&
              props.translate(fieldError(props.fields.street))
            }
            type="text"
            validator={[validators.required, validators.noJapaneeseCharacters]}
          />
        </Row>
        <InlineRow>
          <ZipCode>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.zip.label')}
              name="zip"
              tooltip={
                fieldError(props.fields.zip) &&
                props.translate(fieldError(props.fields.zip))
              }
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
            />
          </ZipCode>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.city.label')}
              name="city"
              tooltip={
                fieldError(props.fields.city) &&
                props.translate(fieldError(props.fields.city))
              }
              type="text"
              validator={[
                validators.required,
                validators.noJapaneeseCharacters,
              ]}
            />
          </InlineRowItem>
        </InlineRow>
        <Row>
          <Field
            component={Select}
            group="step2"
            countries={props.countries}
            initialValue={props.countryCode}
            name="countryCode"
            validator={validators.required}
            options={formatCountryOptions(props.countries)}
          />
        </Row>
        <Row>
          <Description>
            {props.translate(
              props.phoneVerificationRequired
                ? 'registration.phone-validation-true'
                : 'registration.phone-validation-false'
            )}
          </Description>
        </Row>
        <InlineRow>
          <CountryCode>
            <Field
              component={CountryCallingCodeSelect}
              group="step2"
              countries={props.countries}
              countryCode={props.countryCode}
              initialValue={initialCountryCallingCode}
              name="countryCallingCode"
            />
          </CountryCode>
          <InlineRowItem>
            <Field
              component={InputField}
              group="step2"
              label={props.translate('sign-up.phone-number.label')}
              initialValue={props.mobile}
              name="mobile"
              tooltip={
                fieldError(props.fields.mobile) &&
                props.translate(fieldError(props.fields.mobile))
              }
              type="text"
              validator={[validators.required, validators.digitsOnly]}
            />
          </InlineRowItem>
        </InlineRow>
        <CheckboxRow>
          <FieldWrapper>
            <Field
              component={CheckboxField}
              group="step2"
              name="termsConditions"
              label={tacLabelLink}
              validator={validators.required}
            />
          </FieldWrapper>
        </CheckboxRow>

        {isExistingTranslation('sign-up.legal-information') && (
          <LegalInformation
            html={{
              __html: props.translate('sign-up.legal-information'),
            }}
          />
        )}

        {props.displayGdprContent && (
          <CheckboxRow>
            <Field
              component={CheckboxField}
              group="step2"
              name="allowMarketing"
              label={props.translate('register.allow-marketing')}
            />
          </CheckboxRow>
        )}
      </div>
    </StepTemplate>
  )
}

export default withTranslate(Step2)

Step2.propTypes = {
  countries: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  displayGdprContent: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  mobile: PropTypes.string,
  phoneVerificationRequired: PropTypes.bool,
  stepTemplate: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundOverlay: PropTypes.string,
    titleBarBackgroundImage: PropTypes.string,
    titleBarText: PropTypes.string,
  }),
  translate: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrivacyPolicyClick: PropTypes.func.isRequired,
  onTermsConditionsClick: PropTypes.func.isRequired,
  isCasitabi: PropTypes.bool.isRequired,
}

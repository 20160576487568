import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'

import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import { Header, Headline, NavigateBack } from '../components/static/static'

const Wrapper = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`

function FaqContainer(props) {
  return (
    <Wrapper>
      <Header>
        <Headline>{props.translate(`static.faq.headline`)}</Headline>

        <NavigateBack onClick={props.onNavigateBack}>
          <Icon name="chevron-left" size="big" />
        </NavigateBack>
      </Header>

      <HtmlContent html={{ __html: props.translate(`static.faq.content`) }} />
    </Wrapper>
  )
}

export default connect(null, { onNavigateBack: goBack })(
  withTranslate(FaqContainer)
)

FaqContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
}

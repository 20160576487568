import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Link } from '@rushplay/common'

const Image = styled.img`
  ${props => (props.isCasitabi ? 'max-height: 40px' : 'max-height: 100px')};
`

export function Logo(props) {
  return (
    <Box display={['none', null, null, null, 'block']} paddingBottom={9}>
      <Link to="/">
        <Image
          src={
            props.isCasitabi ? (
              '/images/logo-casitabi.svg'
            ) : (
              '/images/logo-casinoheroes.svg'
            )
          }
          isCasitabi={props.isCasitabi}
        />
      </Link>
    </Box>
  )
}

Logo.propTypes = {
  isCasitabi: PropTypes.bool,
}

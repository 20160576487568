import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { resetPassword } from '@rushplay/api-client'
import { selectors } from '@rushplay/legacy-forms'

import ResetPassword from './reset-password'

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(email) {
    this.props.onResetPassword(email)
    this.props.push('/reset-password-confirmation')
  }

  render() {
    return (
      <ResetPassword
        name="resetPassword"
        isFormValid={this.props.isFormValid}
        onSubmit={this.onSubmit}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    isFormValid: selectors.isAllFieldsHaveStatus(state.forms, {
      form: 'resetPassword',
      status: 'valid',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onResetPassword: password => resetPassword(password, { version: 1 }),
      push,
    },
    dispatch
  )
}

ResetPasswordContainer.contextTypes = { locale: PropTypes.string }

ResetPasswordContainer.propTypes = {
  isFormValid: PropTypes.bool.isRequired,
  push: PropTypes.func,
  onResetPassword: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ResetPasswordContainer
)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import PageNav from '../common/page-nav'
import ProgressionContainer from '../common/progression/progression-container'

export function PromotionsMenu(props) {
  const i18n = I18n.useI18n()

  const items = R.map(
    item => ({
      onlyActiveOnIndex: item === 'all',
      label: i18n.translate(`promotions.menu.${item}`),
      to: item === 'all' ? '/promotions' : `/promotions/${item}`,
    }),
    ['all', ...props.items]
  )

  return (
    <Common.Box overflow="hidden" bg="#082049">
      <ProgressionContainer />
      <PageNav items={items} />
    </Common.Box>
  )
}

PromotionsMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
}

import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Colors from '../constants/colors'
import Spinner from '../common/spinner'

export function BigWins() {
  const dispatch = ReactRedux.useDispatch()
  const i18n = I18n.useI18n()

  const [bigWins, setBigWins] = React.useState(null)

  React.useEffect(
    () => {
      dispatch(
        Api.fetchLiveStatistics(
          { limit: 1 },
          {
            success: res =>
              setBigWins(R.head(R.path(['biggestSettlement'], res.value))),
            version: 3,
          }
        )
      )
    },
    [Api.fetchLiveStatistics]
  )

  if (!bigWins) {
    return (
      <Common.Flex justifyContent="center">
        <Spinner />
      </Common.Flex>
    )
  }

  return (
    <Common.Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={Colors.powderBlue}
    >
      <Common.Box
        maxWidth="1440px"
        p={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Common.Box
          display={['none', null, null, 'flex']}
          mx={4}
          fontFamily="mplus-1p-black"
          fontSize="26px"
          color={Colors.white}
          fontWeight="900"
        >
          {i18n.translate('big-wins.title')}
        </Common.Box>
        <Common.Box
          borderRadius="50%"
          minWidth={['31px', null, null, '48px']}
          height={['31px', null, null, '48px']}
          mx={4}
          backgroundColor={Colors.white}
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundImage={`url(${bigWins.playerAvatarUrl})`}
        />
        <Common.Box
          fontSize={['14px', null, null, '20px']}
          fontFamily="mplus-1p"
          fontWeight="900"
          lineHeight="1.29"
        >
          {i18n.translate('big-wins.winner-info', {
            username: bigWins.displayName,
            gameTitle: bigWins.gameName,
            amount: bigWins.amountCents,
          })}
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

import PropTypes from 'prop-types'
import React from 'react'

import { withTranslate } from '@rushplay/i18n'

import ProgressionContainer from '../common/progression/progression-container'

import PageNav from './page-nav'

function HeaderContainer(props) {
  const items = [
    {
      label: props.translate('live-casino.all'),
      to: '/live-casino',
    },
    {
      label: props.translate('live-casino.category.roulette'),
      to: '/live-casino/roulette',
    },
    {
      label: props.translate('live-casino.category.blackjack'),
      to: 'live-casino/blackjack',
    },
    {
      label: props.translate('live-casino.category.moneywheel'),
      to: 'live-casino/moneywheel',
    },
    {
      label: props.translate('live-casino.category.baccarat'),
      to: 'live-casino/baccarat',
    },
  ]
  return (
    <React.Fragment>
      <ProgressionContainer />
      <PageNav items={items}>{props.children}</PageNav>
    </React.Fragment>
  )
}

HeaderContainer.propTypes = {
  translate: PropTypes.func,
  children: PropTypes.object,
}

export default withTranslate(HeaderContainer)

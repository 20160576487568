import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 12px;
  pointer-events: none;
`

class LegalNote extends Component {
  constructor() {
    super()
    this.state = { show: true }
  }

  componentDidMount() {
    const { autoHideAfter } = this.props

    if (autoHideAfter != null) {
      this.timeoutId = setTimeout(() => {
        this.hide()
      }, autoHideAfter)
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  show() {
    // eslint-disable-next-line react/no-set-state
    this.setState({ show: true })
  }

  hide() {
    // eslint-disable-next-line react/no-set-state
    this.setState({ show: false })
  }

  render() {
    return (
      this.state.show && <Wrapper>{this.props.translate('legal-note')}</Wrapper>
    )
  }
}

LegalNote.propTypes = {
  autoHideAfter: PropTypes.number,
  translate: PropTypes.func,
}

export default withTranslate(LegalNote)

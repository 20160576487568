import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'

import { Flex } from '@rushplay/common'

import { headingFont } from '../constants/typography'

const BorderFlex = styled(Flex)`
  border-radius: 5px;
  border: 1px solid rgba(240, 243, 247, 0.2);
  padding: 17px 15px;
`

const ScoreboardNumber = styled.span`
  font-family: scoreboard;
  font-size: ${rem('26px')};
`

const Label = styled.span`
  font-family: ${headingFont};
  font-size: ${rem('10px')};
  margin-top: 5px;
  text-transform: uppercase;
`

function CountdownSection({ children, label }) {
  return (
    <Flex center column>
      <BorderFlex inline>
        <ScoreboardNumber>
          {children < 10 && '0'}
          {children}
        </ScoreboardNumber>
      </BorderFlex>
      <Label>{label}</Label>
    </Flex>
  )
}

CountdownSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.number.isRequired,
}

export default CountdownSection

import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import { SESSION_EXPIRED } from '@rushplay/websockets'
import { logout } from '@rushplay/api-client'
import { session } from '@rushplay/session'

class LogoutContainer extends Component {
  componentDidMount() {
    this.props.onDidMount()
    window.FasttrackCrm && window.FasttrackCrm.logout()
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDidMount: () =>
      dispatch(
        logout({
          success: () => [
            session.clear(),
            dispatch({ type: SESSION_EXPIRED }),
            redirectTo('/'),
          ],
          // This is technically not correct since the logout-action failed.
          // But if you ended up on the /logout route you're to be "logged out" one way or another
          // This "hack", will clear the store and make the app look like your session was killed.
          // You'll even have to login again to get a new one. (Basically dead)
          failure: () => [
            session.clear(),
            dispatch({ type: SESSION_EXPIRED }),
            redirectTo('/'),
          ],
          version: 1,
        })
      ),
  }
}

export default connect(null, mapDispatchToProps)(LogoutContainer)

LogoutContainer.propTypes = {
  expired: PropTypes.bool,
  onDidMount: PropTypes.func.isRequired,
}

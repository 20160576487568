import * as R from 'ramda'

export default function formatCountryCodeOptions(countries) {
  const result = R.map(country => {
    return {
      label: `+${country.countryCode}`,
      value: `+${country.countryCode}`,
    }
  }, countries)

  return R.sort(R.ascend(R.prop('label')), result)
}

import * as R from 'ramda'
import { FETCH } from 'redux-effects-fetch'
import {
  cacheResponse,
  startTrackingRequest,
  stopTrackingRequest,
  getCachedResponse,
} from './api'

/**
 * Action type for API actions.
 * @constant
 * @type {string}
 */
export const EFFECT_FETCH_API = 'EFFECT_FETCH_API'

function noop() {}

function withCompletion(uuid, action) {
  const injectUuid = R.assocPath(
    ['meta', '@rushplay/api-client', 'completed'],
    uuid
  )

  if (Array.isArray(action)) {
    // Append NOOP action to the end of array
    // Altering last action might fail if it happens to be a falsy value when
    // conditional dispatching is used
    return R.append(injectUuid({ type: 'NOOP' }), action)
  }

  return injectUuid(R.defaultTo({ type: 'NOOP' }, action))
}

function withOnBeforeFailure(onBeforeFailure, action) {
  return R.over(
    R.lensPath(['meta', 'steps', 0, 1]),
    (failure = noop) => response =>
      onBeforeFailure(response, () => failure(response)),
    action
  )
}

function toCacheKey(action) {
  const method = R.path(['payload', 'params', 'method'], action)
  const url = R.path(['payload', 'url'], action)
  const version = R.path(['payload', 'params', 'headers', 'Accept'], action)

  if (method === 'GET') {
    return R.join(':', [method, url, version])
  }

  if (method === 'POST' && url === '/games/search') {
    const body = R.path(['payload', 'params', 'body'], action)
    return R.join(':', [method, url, version, body])
  }
}

function toFetch(
  { cacheFor, cacheKey, countryCode, dispatch, host, token, uuid },
  action
) {
  const subaction = action.payload

  const nextAction = R.over(
    R.lensPath(['meta', 'steps']),
    // Steps is array of arrays with callbacks, therefore we need to
    // R.map inside another R.map
    steps =>
      R.map(
        R.map((actionCreator = noop) => (...args) => {
          const action = actionCreator(...args)

          const cacheable = cacheKey != null && cacheFor > 0

          if (cacheable) {
            const expiresAt = Date.now() + cacheFor
            const [response] = args

            if (response.status >= 200 && response.status <= 299) {
              dispatch(cacheResponse(cacheKey, expiresAt, response))
            }
          }

          /* istanbul ignore next: defensive code */
          if (uuid == null) {
            return action
          }

          return withCompletion(uuid, action)
        }),
        R.defaultTo([], steps)
      ),
    action
  )

  const nextSubaction = R.mergeDeepLeft(
    {
      type: FETCH,
      payload: {
        params: {
          headers: R.reject(R.either(R.isNil, R.isEmpty), {
            Authorization: R.pathOr(
              token,
              ['payload', 'params', 'headers', 'Authorization'],
              subaction
            ),
            'Frontend-Country-Code': countryCode,
          }),
        },
        url: `${host}${subaction.payload.url}`,
      },
    },
    subaction
  )

  return R.assoc('payload', nextSubaction, nextAction)
}

/**
 * Redux Effects middleware.
 * @param {object} options
 * @param {string|Function} options.host
 *   Host URL e.g. `https://api.domain.com`
 * @param {Function} [options.countryCodeSelector]
 *   Function that gets Redux state and country code
 * @param {Function} [options.tokenSelector]
 *   Function that gets Redux state and returns a session token
 * @param {Function} [options.onBeforeFailure]
 *   Function called before action’s `failure` callback; receives response and
 *   `next` callback to execute action’s `failure` callback if necessary.
 */
export function middleware(options) {
  const defaultCacheFor =
    typeof options.defaultCacheFor === 'number' ? options.defaultCacheFor : 0

  const getCountryCode =
    typeof options.countryCodeSelector === 'function'
      ? options.countryCodeSelector
      : () => {}

  const getHost =
    typeof options.host === 'function' ? options.host : () => options.host

  const getToken =
    typeof options.tokenSelector === 'function'
      ? options.tokenSelector
      : () => {}

  const onBeforeFailure =
    typeof options.onBeforeFailure === 'function'
      ? options.onBeforeFailure
      : (response, next) => next()

  return store => next => action => {
    if (
      action.type === 'EFFECT_COMPOSE' &&
      action.payload.type === EFFECT_FETCH_API
    ) {
      const state = store.getState()
      const subaction = action.payload

      const cacheFor = R.pathOr(
        defaultCacheFor,
        ['meta', '@rushplay/api-client', 'cacheFor'],
        subaction
      )

      const cacheKey = toCacheKey(subaction)

      const uuid = R.path(['meta', 'uuid'], subaction)

      const nextAction = toFetch(
        {
          cacheFor,
          cacheKey,
          countryCode: getCountryCode(state),
          dispatch: store.dispatch,
          host: getHost(state),
          token: getToken(state),
          uuid,
        },
        withOnBeforeFailure(onBeforeFailure, action)
      )

      const cacheable = cacheKey != null && cacheFor > 0

      if (cacheable) {
        const substate = options.substateSelector(state)
        const now = Date.now()
        const cachedResponse = getCachedResponse(substate, {
          key: cacheKey,
          now,
        })

        if (cachedResponse) {
          const success = R.pathOr(noop, ['meta', 'steps', 0, 0], nextAction)

          return store.dispatch(success(cachedResponse))
        }
      }

      /* istanbul ignore next: defensive code */
      if (uuid == null) {
        return next(nextAction)
      }

      store.dispatch(startTrackingRequest(uuid))
      return next(nextAction)
    }

    const dispatched = next(action)

    const uuid = R.path(['meta', '@rushplay/api-client', 'completed'], action)

    /* istanbul ignore next: defensive code */
    if (uuid == null) {
      return dispatched
    }

    store.dispatch(stopTrackingRequest(uuid))
    return dispatched
  }
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { css } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import blitzIcon from '../casino/blitz/blitz-icon.svg'
import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
  textFont,
} from '../constants/typography'
import { deepBerry, mulberry, successDark } from '../constants/colors'

import Amount from './amount'
import Icon from './icon/icon'

const Wrapper = styled.div`
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center;
      background-size: cover;
    `};
  ${props =>
    props.gradientBackground &&
    css`
      background-image: linear-gradient(
        -215deg,
        rgb(35, 56, 167) 30%,
        rgb(204, 120, 209) 100%
      );
    `};
  ${props => props.discount && css`box-shadow: 0 0 0 3px ${deepBerry};`};
  ${props => props.onClick && css`cursor: pointer;`};
  background-color: rgba(0, 0, 0, 0.57);
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
`

const RibbonBase = styled.div`
  transform: rotate(45deg);
  position: relative;
  width: 150%;
  height: 25%;
  top: 5px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: white;
  background-color: ${props => props.color};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
`

const Ribbon = styled(RibbonBase)`
  font-size: 1.1em;
  background-image: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(3, 4, 5, 0.3) 2%,
    rgba(125, 185, 232, 0) 100%
  );
`

const BonusRibbon = styled(RibbonBase)`
  background-image: linear-gradient(
    120deg,
    #b27f2c,
    #b27f2c,
    #e2b63e,
    #956021,
    #e2b63e,
    #956021
  );
  background-position: -30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

const PPBoosterRibbon = styled(RibbonBase)`
  position: absolute;
  left: 5px;
  background-image: linear-gradient(
    rgb(75 197 248) 100%,
    rgb(0 127 181) 100%,
    rgb(0 127 181) 100%
  );
`

const PPBoosterRibbonLabel = styled.div`
  padding: 115px;
  right: 2px;
  left: 2px;
  font-family: ${headingFont};
  font-weight: bold;
  font-size: ${props => props.fontSize};
`

const SaleBadge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 10px 8px 4px;
  background-color: ${deepBerry};
  border-radius: 0 0 30px;
  font-weight: 600;
  font-size: 0.9em;
  color: white;
`

const AmountBadge = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
  padding: 5px 6px;
  background-color: white;
  border-radius: 50px;
  font-weight: 600;
  color: black;
`

const MultiplierBadge = styled.div`
  position: relative;
  top: ${props => (props.enlarged ? '40px' : '20px')};
  font-family: ${headingFont};
  font-weight: bold;
  font-size: ${props => (props.enlarged ? '3.7em' : '2.7em')};
  color: white;
`

const LabelBadge = styled.div`
  position: absolute;
  bottom: 0px;
  height: 25%;
  left: 0px;
  right: 0px;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.51);
  color: white;
  font-size: ${props =>
    props.enlarged ? baseFontSizeLarge : baseFontSizeSmall};
  font-family: ${textFont};
  font-weight: 600;
  padding-top: 2.2px;
  padding-bottom: 2.2px;
  padding-right: 13.3px;
  padding-left: 9.3px;
`
const SubLabelBadge = styled.div`
  width: 100%;
  height: 25%;
  opacity: 0.8;
  border-radius: 5px;
  color: white;
  font-size: ${props =>
    props.enlarged ? baseFontSizeLarge : baseFontSizeSmall};
  font-family: ${textFont};
  font-weight: 300;
`

const TagBadge = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 5px 6px;
  background-color: ${successDark};
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-size: ${baseFontSizeSmall};
  font-family: ${headingFont};
  font-weight: 600;
`

const BlitzIcon = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-top-right-radius: 22px;
  width: 36px;
  height: 36px;
  background-image: url('${blitzIcon}');
  background-size: 26px 28px;
  background-position: 4px 5px;
  background-repeat: no-repeat;
`

const ribbonValues = {
  'low-value': {
    color: 'peru',
    value: '★',
  },
  'medium-value': {
    color: 'silver',
    value: '★★',
  },
  'high-value': {
    color: 'gold',
    value: '★★★',
  },
  'low-value-king-spins': {
    color: mulberry,
    value: (
      <span>
        <Icon name="crown" />
      </span>
    ),
  },
  'medium-value-king-spins': {
    color: mulberry,
    value: (
      <Flex center>
        <Space x="1px">
          <Icon name="crown" />
        </Space>
        <Space x="1px">
          <Icon name="crown" />
        </Space>
      </Flex>
    ),
  },
  'king-spins': {
    color: mulberry,
    value: (
      <Flex center>
        <Space x="1px">
          <Icon name="crown" />
        </Space>
        <Space x="1px">
          <Icon name="crown" />
        </Space>
        <Space x="1px">
          <Icon name="crown" />
        </Space>
      </Flex>
    ),
  },
}

function getTileAmount(itemType, amount) {
  switch (itemType) {
    case 'PPBooster':
      return null
    default:
      return amount
  }
}

function getBoosterRibbonFontSize(enlarged) {
  if (enlarged) {
    return baseFontSizeLarge
  } else {
    return baseFontSizeSmall
  }
}

function InventoryTile(props) {
  const gradientBackground = props.itemType === 'PPBooster'
  const tileAmount = getTileAmount(props.itemType, props.amount)

  return (
    <Wrapper
      className={props.className}
      image={props.image}
      onClick={props.onClick}
      discount={props.discount}
      gradientBackground={gradientBackground}
    >
      {props.value &&
      props.itemType === 'Freespins' && (
        <Ribbon color={R.path([props.value, 'color'], ribbonValues)}>
          {R.pathOr('', [props.value, 'value'], ribbonValues)}
        </Ribbon>
      )}

      {R.equals('FeatureTriggers', props.itemType) && (
        <BonusRibbon>{props.translate('inventory.bonus-game')}</BonusRibbon>
      )}

      {props.discount && <SaleBadge>{props.discount}%</SaleBadge>}

      {tileAmount ? (
        <AmountBadge>×{tileAmount}</AmountBadge>
      ) : (
        props.betAmountCents && (
          <AmountBadge>
            <Amount>{props.betAmountCents}</Amount>
          </AmountBadge>
        )
      )}

      {props.duration && (
        <MultiplierBadge enlarged={props.enlarged}>
          {props.multiplier}X
        </MultiplierBadge>
      )}

      {props.duration && (
        <PPBoosterRibbon enlarged={props.enlarged || false}>
          <PPBoosterRibbonLabel
            fontSize={getBoosterRibbonFontSize(props.enlarged)}
          >
            {props.duration}
          </PPBoosterRibbonLabel>
        </PPBoosterRibbon>
      )}

      {props.itemLabel && (
        <LabelBadge enlarged={props.enlarged}>
          {props.itemLabel}
          <SubLabelBadge enlarged={props.enlarged}>
            {props.itemSubLabel}
          </SubLabelBadge>
        </LabelBadge>
      )}

      {props.blitzMode && <BlitzIcon />}

      {props.tag && <TagBadge>{props.tag}</TagBadge>}
    </Wrapper>
  )
}

InventoryTile.propTypes = {
  amount: PropTypes.number,
  betAmountCents: PropTypes.number,
  blitzMode: PropTypes.bool,
  duration: PropTypes.string,
  multiplier: PropTypes.number,
  className: PropTypes.string,
  discount: PropTypes.number,
  image: PropTypes.string,
  enlarged: PropTypes.bool,
  tag: PropTypes.string,
  value: PropTypes.oneOf([
    'high-value',
    'king-spins',
    'low-value',
    'low-value-king-spins',
    'medium-value',
    'medium-value-king-spins',
    'pp-booster',
  ]),
  itemType: PropTypes.string,
  itemTitle: PropTypes.string,
  itemLabel: PropTypes.string,
  itemSubLabel: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default withTranslate(InventoryTile)

export default function nextTimeToRealityCheck(
  currentTime,
  lastSessionTimestamp,
  remainder,
  period
) {
  const timePlayed = currentTime - lastSessionTimestamp

  if (timePlayed < remainder) {
    return remainder - timePlayed
  }

  return period - (timePlayed - remainder) % period
}

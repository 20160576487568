import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { searchGames } from '@rushplay/api-client'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

const indexById = R.indexBy(R.prop('id'))

function getGamesFromResponse(name, response) {
  return R.path(['value', 'result', name], response)
}

function fetchGames(name, params) {
  return [
    actions.storeQueryOffset({
      name,
      offset: params.offset,
    }),
    searchGames(
      { [name]: params },
      {
        success: response => {
          const games = getGamesFromResponse(name, response)
          const storeQueryResults = params.offset
            ? actions.appendQueryResults
            : actions.replaceQueryResults
          return [
            actions.storeGames(indexById(games)),
            storeQueryResults({
              name,
              ids: R.pluck('id', games),
              totalCount: R.path([name, 'totalCount'], response.value.meta),
            }),
          ]
        },
        failure: () => {
          // TODO: errors when fetching should be handled by the fetching mechanism.
          // The thing here, the `failure` callback, is actually more akin to a
          // `catch`, i.e. "There was an error, what do you want to do about it?".
          return actions.storeGames(new Error('errors.http.fetch-failure'))
        },
        version: 2,
      }
    ),
  ]
}

function loadMore(name, params, currentCount) {
  return fetchGames(name, R.assoc('offset', currentCount, params))
}

function mapStateToProps(state, ownProps) {
  const localState = R.prop(ownProps.mountPoint, state)
  const queryName = { queryName: ownProps.name }
  const currentCount = selectors.getCurrentCount(localState, queryName)

  return {
    games: selectors.getGames(localState, queryName),
    ids: selectors.getGameIds(localState, {
      queryName: ownProps.name,
      limit: ownProps.params.limit,
    }),
    localState,
    hasMore:
      selectors.getTotalCount(localState, queryName) >
      selectors.getCurrentCount(localState, queryName),
    currentCount,
    isLoading: selectors.areGamesLoading(localState, {
      queryName: ownProps.name,
      limit: ownProps.params.limit,
    }),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      onClear: () => actions.clearQueryResults(ownProps.name),
      onFetch: () => fetchGames(ownProps.name, ownProps.params),
      onLoadMore: loadMore,
    },
    dispatch
  )
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return R.merge(ownProps, {
    ids: stateProps.ids,
    isLoading: stateProps.isLoading,
    onClear: dispatchProps.onClear,
    games: stateProps.games,
    onFetch: dispatchProps.onFetch,
    onLoadMore: stateProps.hasMore
      ? R.partial(dispatchProps.onLoadMore, [
          ownProps.name,
          ownProps.params,
          stateProps.currentCount,
        ])
      : undefined,
  })
}

export const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Box } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import Icon from '../icon/icon'

const Label = styled.label`
  color: ${t.color('dark60')};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  position: absolute;
  top: 17.5%;
  left: 22px;
  right: 0;
  pointer-events: none;
  transform-origin: left top;
  font-size: ${t.fontSize(0)};
`
const Option = styled.option`
  color: black;
`

const StyledSelect = styled.select`
  font-size: ${t.fontSize(2)};
  border: 2px solid
    ${(props) => (props.visited ? t.color('success') : t.color('dark60'))};
  border-radius: 8px;
  background-image: none;
  background-color: inherit;
  color: inherit;
  box-shadow: none;
  appearance: none;
  width: 100%;
  padding: ${(props) => (props.hasLabel ? '28px 20px 12px' : '20px')};
  &:focus {
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
`
const Arrow = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -0.5em;
  pointer-events: none;
  font-size: 20px;
  color: ${t.color('dark40')};

  @media screen and (min-width: 375px) {
    right: 20px;
  }
`
export function Select(props) {
  const i18n = useI18n()
  const mapIndexed = R.addIndex(R.map)

  if (props.options == null || R.isEmpty(props.options)) {
    return null
  }

  return (
    <Box position="relative">
      {props.label && <Label>{props.label}</Label>}
      <StyledSelect
        hasLabel={Boolean(props.label)}
        name={props.name}
        value={props.value}
        valid={props.value && props.visited}
        onChange={(event) => props.onChange(event.target.value)}
      >
        {mapIndexed(
          (option, i) => (
            <Option key={i} value={option.value}>
              {i18n.translate(option.label)}
            </Option>
          ),
          props.options
        )}
      </StyledSelect>
      <Arrow name="caret-down" />
    </Box>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  visited: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
}

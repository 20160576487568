import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as locks from '@rushplay/compliance/locks'
import {
  cancelPlayerLimit,
  fetchPlayerLimits,
  requestPlayerLimit,
} from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import limits from '../../../store/limits'

import { UpdatedLimitsConfirmation as Component } from './updated-limits-confirmation'

function mapStateToProps(state) {
  const lock = locks.getLock(state.locks, {
    type: 'updated-limits-confirmation',
  })

  const target = R.path(['target'], lock)
  const period = R.path(['period'], lock)

  return {
    period,
    target,
    previousAmountCents: R.pathOr(
      0,
      ['valueCents'],
      limits.selectors.getLimit(state.player.limits, { target, period })
    ),
    newAmountCents: R.path(['newAmountCents'], lock),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLimit: props =>
      dispatch(
        requestPlayerLimit(props.target, props.period, props.amount, {
          success: () => [
            locks.fetch(),
            locks.remove('updated-limits-confirmation'),
          ],
          failure: () =>
            Notifications.add({
              message: 'player-limits.errors.set-limit',
              variables: {
                target: props.target,
                period: props.period,
              },
              level: 'error',
            }),
          version: 2,
        })
      ),
    cancelLimit: props =>
      dispatch(
        cancelPlayerLimit(props.target, props.period, {
          success: () => [
            locks.fetch(),
            locks.remove('updated-limits-confirmation'),
          ],
          failure: () =>
            Notifications.add({
              message: 'player-limits.errors.cancel-limit',
              variables: {
                target: props.target,
                period: props.period,
              },
              level: 'error',
            }),
          version: 2,
        })
      ),
    fetchPlayerLimits: () =>
      dispatch(
        fetchPlayerLimits({
          success: res => limits.actions.init(res.value.result),
          failure: () =>
            Notifications.add({
              message: 'player-limits.errors.failed-loading',
              level: 'error',
            }),
          version: 2,
        })
      ),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    onApprove: () =>
      dispatchProps.setLimit({
        target: stateProps.target,
        period: stateProps.period,
        amount: stateProps.newAmountCents,
      }),
    onDecline: () =>
      dispatchProps.cancelLimit({
        target: stateProps.target,
        period: stateProps.period,
      }),
    onFetch: () => dispatchProps.fetchPlayerLimits(),
    period: stateProps.period,
    target: stateProps.target,
    previousAmountCents: stateProps.previousAmountCents,
    newAmountCents: stateProps.newAmountCents,
  }
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslate
)

export const UpdatedLimitsConfirmation = connector(Component)

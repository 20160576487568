import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

import Row from '../common/row'
import { Constraint } from '../common/grid'

import GameItem from './game-item'

function LiveCasino(props) {
  return (
    <Constraint centered width="1200">
      <Common.Box margin="0px 16px">
        <Row justify="flex-start" wrap gutter="16">
          {props.games.map((game) => (
            <Common.Box
              key={game.id}
              flexGrow="1"
              flexShrink="0"
              flexBasis={[
                [
                  'calc(100% - 16px)',
                  null,
                  'calc(50% - 16px)',
                  'calc(33.33% - 16px)',
                ],
              ]}
              maxWidth={[
                'calc(100% - 16px)',
                null,
                'calc(50% - 16px)',
                'calc(33.33% - 16px)',
              ]}
            >
              <Common.Space bottom={5}>
                <GameItem
                  isAuthenticated={props.isAuthenticated}
                  betBehind={game.betBehind}
                  dealer={game.dealerName}
                  gameId={game.id}
                  gameName={game.title}
                  gameProvider={game.provider}
                  gameSeats={game.seatsAvailable}
                  image={game.imageUrl}
                  betRange={`${game.minBetCents / 100} - ${
                    game.maxBetCents / 100
                  }`}
                  seats={game.seatsAvailable}
                  color={game.backgroundColor || '#a3a3a3'}
                  loggedInOnce={props.loggedInOnce}
                  liveCasinoChannel={props.liveCasinoChannel}
                  redirectTo={props.redirectTo}
                />
              </Common.Space>
            </Common.Box>
          ))}
        </Row>
      </Common.Box>
    </Constraint>
  )
}

LiveCasino.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  currentCategory: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  loggedInOnce: PropTypes.bool,
  liveCasinoChannel: PropTypes.string,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      dealerName: PropTypes.string,
      gameType: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string,
      maxBetCents: PropTypes.number.isRequired,
      minBetCents: PropTypes.number.isRequired,
      seatsAvailable: PropTypes.number,
      tags: PropTypes.array,
      title: PropTypes.string.isRequired,
    })
  ),
  redirectTo: PropTypes.func,
}

export default LiveCasino

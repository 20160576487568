import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import styled from '@emotion/styled'
import { Field, FormPropTypes } from '@rushplay/legacy-forms'
import { Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import CheckboxField from '../../common/checkbox-field'
import InputField from '../../common/input-field'
import StepTemplate from '../steps/step-template'
import getFormTranslationKey from '../../util/get-form-translation-key'
import validators from '../../validators'
import { Row } from '../steps/styles'
import { baseFontSizeSmall } from '../../constants/typography'
import { berry } from '../../constants/colors'

const FieldWrapper = styled.div`display: inline-block;`

const CheckboxRow = styled(Row)`
  font-size: ${baseFontSizeSmall};
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.5;
`

const TermsConditionLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${berry};
  }
`

function LogInDetails(props) {
  const tacLabelLink = (
    <span>
      {`${props.translate('register.form.agree-terms')} `}
      <TermsConditionLink
        to={{
          pathname: props.location.pathname,
          query: { TermsCondition: true },
        }}
      >
        {props.translate('sign-up.terms-conditions')}
      </TermsConditionLink>
      <span>
        {` ${props.translate('sign-up.and-our')} `}
        <TermsConditionLink
          to={{
            pathname: props.location.pathname,
            query: { PrivacyPolicy: true },
          }}
        >
          {props.translate('sign-up.privacy-policy')}
        </TermsConditionLink>
      </span>
    </span>
  )

  return (
    <StepTemplate
      nextStepDisabled={!props.isValid}
      nextStepLabel={props.translate('sign-up.ok')}
      title={props.translate('bank-id.log-in-details')}
      onNextStep={props.onNextStep}
    >
      <div>
        <Row>
          <Field
            component={InputField}
            group="log-in-details"
            label={props.translate('sign-up.email.label')}
            name="email"
            tooltip={props.translate(
              getFormTranslationKey('sign-up')(
                props.fields.email,
                'email',
                'choose-valid-email'
              )
            )}
            type="email"
            validator={[
              validators.required,
              validators.email,
              validators.emailAsync,
            ]}
          />
        </Row>
        <Row>
          <Field
            component={InputField}
            group="log-in-details"
            label={props.translate('sign-up.password.label')}
            name="password"
            tooltip={props.translate(
              getFormTranslationKey('sign-up')(
                props.fields.password,
                'password',
                'password-help'
              ),
              { min: 8 }
            )}
            type="password"
            validator={[validators.required, validators.password]}
          />
        </Row>
        <CheckboxRow>
          <FieldWrapper>
            <Field
              component={CheckboxField}
              group="log-in-details"
              name="termsConditions"
              label={tacLabelLink}
              validator={validators.required}
            />
          </FieldWrapper>
        </CheckboxRow>
        <CheckboxRow>
          <Field
            component={CheckboxField}
            group="log-in-details"
            name="allowMarketing"
            label={props.translate('register.allow-marketing')}
          />
        </CheckboxRow>
      </div>
    </StepTemplate>
  )
}

export default R.compose(withTranslate, withRouter)(LogInDetails)

LogInDetails.propTypes = {
  fields: PropTypes.shape({
    email: FormPropTypes.field,
    password: FormPropTypes.field,
  }).isRequired,
  location: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
}

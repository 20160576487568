import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as InternalLink } from 'react-router-dom'

// --

function isLocal(url) {
  // if url is an Object, it’s definitely for react-router
  if (R.is(Object, url)) {
    return true
  }

  // if url is a Function, it’s also for react-router
  if (typeof url === 'function') {
    return true
  }

  // if begins with protocol
  if (/^[a-z]+:\/\//.test(url)) {
    return false
  }

  // if begins with double slash
  if (/^\/\//.test(url)) {
    return false
  }

  return true
}

// --

function prependLocaleToPathname(locale, pathname) {
  if (locale && !/^\/?[a-z]{2}\W/i.test(pathname)) {
    return `/${locale}/${pathname}`.replace('//', '/')
  }

  return pathname
}

/**
 * Wrap location transformer function to prepend pathname with location when called
 * @param {Function} locationTransformer Function passed as `to` prop
 * @returns {Object}
 */
function enhanceLocationTransformer(locale, locationTransformer) {
  return R.pipe(
    R.defaultTo({}),
    locationTransformer,
    location =>
      R.assoc(
        'pathname',
        prependLocaleToPathname(locale, location.pathname),
        location
      )
  )
}

function prependLocale(locale, props) {
  const pathname = R.path(['to', 'pathname'], props) || R.prop('to', props)

  if (typeof props.to === 'function') {
    return R.assoc('to', enhanceLocationTransformer(locale, props.to), props)
  }

  const pathnameWithLocale = prependLocaleToPathname(locale, pathname)

  return R.is(String, props.to)
    ? R.assoc('to', pathnameWithLocale, props)
    : R.assocPath(['to', 'pathname'], pathnameWithLocale, props)
}

// --

/**
 * Create react-router compatible Link capable to handle external URLs
 * @param {Object} props
 * @param {boolean} props.disabled Prevent browser click handling
 * @param {(Object|string)} props.to A location descriptor. See:
 * https://github.com/ReactTraining/react-router/blob/v3/docs/API.md#link
 * @return {ReactComponent}
 */
export class Link extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    if (this.props.disabled) {
      event.preventDefault()
    }

    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event)
    }
  }

  render() {
    // if URL is not local, render regular <a>
    if (!isLocal(this.props.to)) {
      return React.createElement(
        'a',
        R.merge(
          {
            href: this.props.to,
            rel: 'noopener noreferrer',
            target: '_blank',
          },
          R.omit(['to'], this.props)
        )
      )
    }

    // stick in our click handler for disabled links to use preventDefault
    const props = R.assoc('onClick', this.handleClick, this.props)

    // fallback to react-router’s <Link> render
    return React.createElement(
      InternalLink,
      prependLocale(this.context.locale, props)
    )
  }
}

// --

Link.contextTypes = { locale: PropTypes.string }

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rel: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_parent', '_self', '_top']),
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
}

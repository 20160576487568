import * as R from 'ramda'

import * as Notifications from '@rushplay/notifications'
import * as api from '@rushplay/api-client'
import * as websockets from '@rushplay/websockets'
import * as processes from '@rushplay/processes'

import * as processIds from '../constants/processes'
import {
  isEligibleDepositNumber,
  isEligibleVip,
} from '../unseen-promotion-filters'

const CLEAR_PROMOTIONS = 'casino-heroes/promotions/CLEAR_PROMOTIONS'
const CLEAR_UNSEEN_PROMOTIONS =
  'casino-heroes/promotions/CLEAR_UNSEEN_PROMOTIONS'
const OPT_IN_CAMPAIGN_IN_PROMOTION =
  'casino-heroes/promotions/OPT_IN_CAMPAIGN_IN_PROMOTION'
const OPT_OUT_CAMPAIGN_IN_PROMOTION =
  'casino-heroes/promotions/OPT_OUT_CAMPAIGN_IN_PROMOTION'
const SET_PROMOTIONS_READY = 'casino-heroes/promotions/SET_PROMOTIONS_READY'
const UPDATE_PROMOTIONS = 'casino-heroes/promotions/UPDATE_PROMOTIONS'
const VIEWED_PROMOTION = 'casino-heroes/promotions/VIEWED_PROMOTION'

const initialState = {
  items: [],
  ready: false,
  unseenPromotions: [],
  viewedPromotion: '',
}

export function clearPromotions() {
  return { type: CLEAR_PROMOTIONS }
}

export function clearUnseenPromotions() {
  return { type: CLEAR_UNSEEN_PROMOTIONS }
}

export function setPromotionsReady(payload) {
  return { type: SET_PROMOTIONS_READY, payload }
}

export function viewPromotion(payload) {
  return {
    type: VIEWED_PROMOTION,
    payload,
  }
}

export function updatePromotions(payload) {
  return {
    type: UPDATE_PROMOTIONS,
    payload,
  }
}

/**
 * Set opt in status of campain in promotion to in
 * @param {number} payload - index of the promotion in promotions array
 */
export function optInCampaignInPromotion(payload) {
  return {
    type: OPT_IN_CAMPAIGN_IN_PROMOTION,
    payload,
  }
}

/**
 * Set opt in status of campain in promotion to out
 * @param {number} payload - index of the promotion in promotions array
 */
export function optOutCampaignInPromotion(payload) {
  return {
    type: OPT_OUT_CAMPAIGN_IN_PROMOTION,
    payload,
  }
}

export function fetch() {
  return [
    processes.start(processIds.FETCH_PROMOTIONS),
    setPromotionsReady(false),
    api.fetchPromotions(null, {
      success: res => [
        processes.stop(processIds.FETCH_PROMOTIONS),
        setPromotionsReady(true),
        updatePromotions(res.value),
      ],
      failure: error => [
        processes.stop(processIds.FETCH_PROMOTIONS),
        setPromotionsReady(true),
        Notifications.add({
          message: error.value.message || 'error.generic',
          level: 'error',
        }),
      ],
      version: 2,
    }),
  ]
}

export default function reducer(state = initialState, action) {
  const mergeWithState = R.mergeRight(state)

  switch (action.type) {
    case websockets.PROMOTION_ADDED: {
      return mergeWithState({
        unseenPromotions: R.append(action.payload.key, state.unseenPromotions),
        items: R.append(action.payload, state.items),
      })
    }
    case websockets.SESSION_EXPIRED:
    case CLEAR_PROMOTIONS: {
      return initialState
    }

    case CLEAR_UNSEEN_PROMOTIONS: {
      return mergeWithState({ unseenPromotions: [] })
    }

    case OPT_IN_CAMPAIGN_IN_PROMOTION: {
      return mergeWithState({
        items: R.assocPath(
          [action.payload, 'campaign', 'optInState'],
          'in',
          state.items
        ),
      })
    }

    case OPT_OUT_CAMPAIGN_IN_PROMOTION: {
      return mergeWithState({
        items: R.assocPath(
          [action.payload, 'campaign', 'optInState'],
          'out',
          state.items
        ),
      })
    }

    case SET_PROMOTIONS_READY: {
      return mergeWithState({ ready: action.payload })
    }

    case VIEWED_PROMOTION: {
      return mergeWithState({ viewedPromotion: action.payload })
    }

    case UPDATE_PROMOTIONS: {
      return mergeWithState({ items: action.payload })
    }

    default:
      return state
  }
}

function getUnseenPromotions(state) {
  return R.map(
    key => R.find(R.propEq('key', key), state.items),
    state.unseenPromotions
  )
}

function filterUnseenPromotions(filters, promotions) {
  return R.filter(R.allPass(filters), promotions)
}

export function unseenPromotionCount(state, props) {
  return props.authenticated
    ? filterUnseenPromotions(
        [
          isEligibleVip(props.vip),
          isEligibleDepositNumber(props.depositNumber),
        ],
        getUnseenPromotions(state)
      ).length
    : 0
}

export function viewedPromotion(state) {
  return R.find(R.propEq('key', state.viewedPromotion), state.items)
}

export function getClaimedCampaigns(state) {
  return R.pipe(
    R.pathOr([], ['items']),
    R.pluck('campaign'),
    R.filter(R.pathEq(['claimed'], true)),
    R.pluck('id')
  )(state)
}

export function getPromotion(key, state) {
  return R.find(R.pathEq(['key'], key), state.items)
}

import * as R from 'ramda'

export default function isAtCasinoGame(locationPathname) {
  const isAtCasino = R.includes('/casino', locationPathname)
  // TODO: find less hacky way
  const isAtGame =
    R.pipe(R.split('/'), R.filter(path => path), R.length)(locationPathname) > 3

  return isAtCasino && isAtGame
}

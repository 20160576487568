import PropTypes from 'prop-types'
import React from 'react'

import { Constraint, Flex, Space } from '@rushplay/common'

import Button from '../../../common/button'
import Card from '../../../common/card'
import Heading from '../../../common/heading'
import HtmlContent from '../../../common/html-content'
import Text from '../../../common/text'

export function FreeText(props) {
  return (
    <Constraint width="600" centered>
      <Space top={8}>
        <Card>
          <Space top="10px">
            <Heading level="1" align="center">
              {props.title}
            </Heading>
          </Space>
          <Space bottom="10px" x="10px">
            <Text align="center">
              <HtmlContent html={{ __html: props.message }} />
            </Text>
          </Space>
          <Flex justifyContent="center">
            <Space bottom="10px">
              <Button variant="primary" onClick={props.onAccept}>
                {props.translate('ok')}
              </Button>
            </Space>
          </Flex>
        </Card>
      </Space>
    </Constraint>
  )
}

FreeText.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import React from 'react'

import { GameQuery as Component } from '@rushplay/casino'

const getFinalProps = props =>
  R.mergeDeepLeft(props, {
    params: {
      mobile: typeof window.orientation !== 'undefined',
    },
  })

export function GameQuery(props) {
  return <Component mountPoint="casino" {...getFinalProps(props)} />
}

import * as R from 'ramda'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import HtmlContent from '../common/html-content'

const Image = styled.div`
  background-image: url('${props => props.backgroundImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #02112a;
  border-radius: 4px;
  width: 100%;
  height: 190px;
`

export function UspsArea() {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      width="100%"
      backgroundColor="#1c3e7b"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Common.Box
        maxWidth="1200px"
        width="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        my={[7, null, null, 9]}
      >
        <Common.Box
          pb={6}
          fontSize="32px"
          fontWeight="900"
          alignSelf="center"
          px="16px"
        >
          {i18n.translate('usps-area.title')}
        </Common.Box>
        <Common.Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
          gridGap="24px"
          padding="16px"
        >
          {R.times(
            index => (
              <Common.Box color="white" key={`usp-item-${index}`}>
                <Image
                  backgroundImage={i18n.translate(
                    `usps-area.item-${index}.image`
                  )}
                />
                <Common.Box pt="24px" fontWeight="900" fontSize="22px">
                  {i18n.translate(`usps-area.item-${index}.title`)}
                </Common.Box>
                <Common.Box pt="8px" fontSize="16px" lineHeight="1.75">
                  <HtmlContent
                    html={{
                      __html: i18n.translate(
                        `usps-area.item-${index}.description`
                      ),
                    }}
                  />
                </Common.Box>
              </Common.Box>
            ),
            4
          )}
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@rushplay/common'

import Icon from './icon/icon'

const Wrapper = styled.div`
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #888888;
  background: linear-gradient(-39deg, #183363 0%, #2653a1 47%);
  @media screen and (min-width: 500px) {
    padding: 15px;
  }
  @media screen and (min-width: 750px) {
    padding: 20px;
  }
  @media screen and (min-width: 1000px) {
    padding: 25px;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  @media screen and (min-width: 750px) : {
    margin-bottom: 10px;
  }
`

const Title = styled.h2`
  margin: 0;
  padding-bottom: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-transform: capitalize;
`

const TitleIcon = styled(Icon)`
  margin-right: 0.3em;
  font-size: 1.4em;
`

const HeaderLink = styled(Link)`
  margin: 0;
  flex-shrink: 1;
  font-size: 14px;
  color: #ffffff;
`

function Panel(props) {
  return (
    <Wrapper className={props.className}>
      <Header>
        <Title>
          {props.icon && <TitleIcon name={props.icon} />}
          {props.title}
        </Title>
        {props.linkLabel &&
        props.linkUrl && (
          <HeaderLink to={props.linkUrl}>{props.linkLabel}</HeaderLink>
        )}
      </Header>
      {props.children}
    </Wrapper>
  )
}

export default Panel

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  icon: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  title: PropTypes.string,
}

import PropTypes from 'prop-types'
import React from 'react'

import { Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import HtmlContent from './html-content'
import ImageSelect from './image-select/image-select'
import Text from './text'

function AvatarSelect(props) {
  return (
    <div>
      <Space bottom={6}>
        <ImageSelect
          current={props.value}
          images={props.avatars}
          onChange={props.onChange}
        />
      </Space>
      <Text align="center" lineHeight="20">
        <HtmlContent
          html={{ __html: props.translate(`${props.value}-description`) }}
        />
      </Text>
    </div>
  )
}

export default withTranslate(AvatarSelect)

AvatarSelect.propTypes = {
  avatars: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

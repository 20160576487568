import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import { h6 } from '../mixins'

const Wrapper = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
`

const Price = styled.h6`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  ${h6};
  color: white;
`

class StoreItem extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.onItemSelect(this.props.id)
  }

  render() {
    return (
      <Wrapper onClick={this.handleClick}>
        {this.props.children}
        <Price>
          <Button stretch small icon="ruby">
            &nbsp;×&nbsp;{this.props.price}
          </Button>
        </Price>
      </Wrapper>
    )
  }
}

StoreItem.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
}

export default withTranslate(StoreItem)

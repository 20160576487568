import * as R from 'ramda'
// TODO: Deposit offers and local sign-up needs
// refactoring to work with new forms and payments.
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { locationShape } from 'react-router/lib/PropTypes'
import { push as redirectTo } from 'react-router-redux'
import { withRouter } from 'react-router'

import * as Notifications from '@rushplay/notifications'
import { Space } from '@rushplay/common'
import { fetchDepositOffers } from '@rushplay/api-client'
import { getAffiliateId, getSessionToken } from '@rushplay/session'
import { selectors } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import * as processIds from '../../constants/processes'
import HtmlContent from '../../common/html-content'
import PopupContainer from '../../common/popup/popup-container'
import Scrollable from '../../common/scrollable'
import getPlayerCurrency from '../../util/get-player-currency'
import { getActiveDepositCampaign } from '../../store/selectors'
import {
  getCalculatedOffers,
  storeDepositOffers,
} from '../../store/deposit-offers'
import { isWaiting } from '../../store/app'
import { selectOfferType } from '../../store/welcome-offers'

import DepositOffer from './deposit-offer'

class DepositOfferContainer extends Component {
  componentDidMount() {
    if (
      this.props.depositOffersAvailable ||
      this.props.hasActiveDepositCampaign
    ) {
      this.fetchDepositOffers(
        this.props.depositOffersAvailable,
        this.props.hasActiveDepositCampaign,
        this.props.token
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedType &&
      nextProps.selectedType !== this.props.selectedType
    ) {
      this.props.selectOfferType(nextProps.selectedType)
    }
  }

  componentWillUnmount() {
    this.props.storeDepositOffers([])
  }

  fetchDepositOffers(depositOffersAvailable, hasActiveDepositCampaign, token) {
    if (!hasActiveDepositCampaign && depositOffersAvailable) {
      this.props.fetchDepositOffers(
        {},
        {
          success: (payload) => storeDepositOffers(payload.value.result),
          failure: (res) =>
            Notifications.add({
              message: res.value.message || 'error.generic',
              level: 'error',
            }),
          version: 2,
          token,
        }
      )
    }
  }

  render() {
    if (this.props.depositOffers.length <= 0) {
      return null
    }

    const headlineLabel = this.props.hasActiveDepositCampaign
      ? 'wallet.deposit.choose.offer'
      : 'wallet.welcome.choose.offer'

    return (
      <Space bottom="10px">
        <DepositOffer
          currency={this.props.currency}
          depositNumber={this.props.depositNumber}
          depositOffers={this.props.depositOffers}
          headlineLabel={headlineLabel}
          selectedType={this.props.selectedType}
        />

        <PopupContainer
          id="welcome-offers"
          isOpen={Boolean(this.props.location.query.popup)}
          onRequestClose={() =>
            this.props.redirectTo(this.props.location.pathname)
          }
        >
          <Scrollable maxHeight={350}>
            <HtmlContent
              html={{
                __html: this.props.translate(
                  `wallet.deposit.readmore.${this.props.location.query.popup}`
                ),
              }}
            />
          </Scrollable>
        </PopupContainer>
      </Space>
    )
  }
}

function getCampaignCalculatedOffers(state, props) {
  const activeDepositCampaign = getActiveDepositCampaign(state) || {}
  const depositOffers = activeDepositCampaign.rewards || []

  // TODO: Decouple from deposit offers state shape?
  return getCalculatedOffers(depositOffers, props)
}

function mapStateToProps(state) {
  const depositAmount = selectors.value(state.formsv2, {
    form: 'transaction',
    field: 'amount',
  })
  const preferredWelcomeOfferType = selectors.value(state.formsv2, {
    form: 'transaction',
    field: 'preferredWelcomeOfferType',
  })
  const amountCents = (depositAmount || 0) * 100

  const depositCampaign = getCampaignCalculatedOffers(state, {
    depositNumber: state.player.depositNumber,
    amountCents,
  })

  const depositOffers = getCalculatedOffers(state.depositOffers, {
    depositNumber: state.player.depositNumber,
    amountCents,
  })

  return {
    affiliateId: getAffiliateId(state.session),
    currency: getPlayerCurrency(state),
    depositNumber: state.player.depositNumber,
    depositOffers: depositCampaign.length ? depositCampaign : depositOffers,
    depositOffersAvailable: state.player.depositOffersAvailable,
    hasActiveDepositCampaign: !R.isNil(getActiveDepositCampaign(state)),
    loading: isWaiting(state.app, [
      processIds.FETCH_CAMPAIGNS,
      processIds.FETCH_PROMOTIONS,
    ]),
    selectedType: preferredWelcomeOfferType,
    token: getSessionToken(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDepositOffers,
      redirectTo,
      selectOfferType,
      storeDepositOffers,
    },
    dispatch
  )
}

DepositOfferContainer.propTypes = {
  affiliateId: PropTypes.number,
  currency: PropTypes.string.isRequired,
  depositNumber: PropTypes.number,
  depositOffers: PropTypes.array,
  depositOffersAvailable: PropTypes.bool,
  fetchDepositOffers: PropTypes.func.isRequired,
  hasActiveDepositCampaign: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  location: locationShape,
  popupId: PropTypes.string,
  redirectTo: PropTypes.func.isRequired,
  selectOfferType: PropTypes.func,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeDepositOffers: PropTypes.func,
  token: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslate(DepositOfferContainer)))

import React from 'react'

/**
 * Calculate remaining time respecting server time offset
 * @deprecated
 * @param {ReactComponent} component
 * @param {object} [options]
 * @param {number|Function} [options.interval=1000] - Minimum interval to wait before updating
 * @param {number} [options.propName="currentTime"] - Injected prop name
 */
export function withCurrentTime(Component, options = {}) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      'DEPRECATED',
      'withCurrentTime is deprecated and will be removed.',
      'Use <Timestamp> component instead.'
    )
  }
  // eslint-disable-next-line react/no-deprecated
  return class CurrentTime extends React.Component {
    constructor(props) {
      super(props)

      this.handleTick = this.handleTick.bind(this)
      this.state = { timestamp: Date.now() }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      this.timerId = this.handleTick()
    }

    componentWillUnmount() {
      clearInterval(this.timerId)
    }

    handleTick() {
      const interval =
        typeof options.interval === 'function'
          ? options.interval()
          : options.interval || 1000

      return setTimeout(() => {
        this.setState({ timestamp: Date.now() })
        this.timerId = this.handleTick()
      }, interval)
    }

    render() {
      const hocProps = {
        [options.propName || 'currentTime']: this.state.timestamp,
      }
      return <Component {...this.props} {...hocProps} />
    }
  }
}

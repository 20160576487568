import styled from '@emotion/styled'

export default styled.div`
  background: #209ace;
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  width: 45px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    border-left: 6px solid #209ace;
    border-right: 6px solid #209ace;
    border-top: 6px solid #209ace;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 17px;
    width: 21px;
    margin-top: -23px;
    margin-left: 7px;
  }
`

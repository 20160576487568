import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import {
  Field,
  FormPropTypes,
  actions as formActions,
  connectForm,
} from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import InputField from '../../common/input-field'
import fieldError from '../../field-error'
import validators from '../../validators'

import { Content } from './content'
import { Title } from './title'

const Form = styled.form`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Input = styled.div`
  margin: 10px 0;
  width: 400px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

function CreateNewPassword(props) {
  return (
    <Content>
      <Title>
        {props.translate('create-new-password.create-new-password')}
      </Title>
      <Form
        onSubmit={event => {
          event.preventDefault()
          props.onSubmit(
            props.token,
            props.fields.newPassword.value,
            props.fields.newPasswordConfirmation.value
          )
        }}
      >
        <Input>
          <Field
            autoFocus
            component={InputField}
            label={props.translate('create-new-password.new-password.label')}
            name="newPassword"
            tooltip={
              fieldError(props.fields.newPassword) &&
              props.translate(fieldError(props.fields.newPassword))
            }
            type="password"
            validator={validators.password}
          />
        </Input>
        <Input>
          <Field
            component={InputField}
            label={props.translate(
              'create-new-password.password-confirmation.label'
            )}
            name="newPasswordConfirmation"
            tooltip={
              fieldError(props.fields.newPasswordConfirmation) &&
              props.translate(fieldError(props.fields.newPasswordConfirmation))
            }
            type="password"
          />
        </Input>
        <Button disabled={!props.isFormValid} type="submit">
          {props.translate('create-new-password.update-password')}
        </Button>
      </Form>
    </Content>
  )
}

CreateNewPassword.propTypes = {
  isFormValid: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    newPassword: FormPropTypes.field,
    newPasswordConfirmation: FormPropTypes.field,
  }).isRequired,
  translate: PropTypes.func,
  token: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default connectForm(withTranslate(CreateNewPassword), {
  validator: (form, values) => {
    if (values.newPassword !== values.newPasswordConfirmation) {
      return formActions.validate(form, 'newPasswordConfirmation', {
        status: false,
        errors: ['createNewPassword.newPasswordConfirmation.errors.not-same'],
      })
    } else if (values.newPassword === values.newPasswordConfirmation) {
      return formActions.validate(form, 'newPasswordConfirmation', {
        status: true,
        errors: [],
      })
    }
  },
})

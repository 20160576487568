import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Locks from '@rushplay/compliance/locks'
import * as Notifications from '@rushplay/notifications'

import { HighDepositLimitPrompt as Component } from './high-deposit-limit-prompt'

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: () =>
      dispatch(
        Api.dismissHighDepositLimitPrompt({
          success: () => [
            Locks.fetch(),
            Locks.remove('high-deposit-limit-prompt'),
          ],
          failure: () =>
            Notifications.add({
              message: 'error.generic',
              level: Notifications.NotificationLevel.ERROR,
            }),
          version: 1,
        })
      ),
  }
}

const connector = R.compose(connect(null, mapDispatchToProps))

export const HighDepositLimitPrompt = connector(Component)

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import styled from '@emotion/styled'
import { Link } from '@rushplay/common'
import { keyframes } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import { berry, gradientBlue } from '../constants/colors'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${gradientBlue} fixed;
  text-align: center;
`

const wobble = keyframes`
  {
    0%, 24%, 49%, 74%, 100% {
       transform: scaleX(1.0);
    }
    25%, 50%, 75% {
       transform: scaleX(1.3) scaleY(0.8) translateY(.5em);
    }
    30%, 55%, 80% {
       transform: scaleX(0.8) scaleY(1.2);
    }
  }
`

const FourOhFourWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
`

const Four = styled.div`
  font-size: 10vw;
  animation-name: ${wobble};
  animation-duration: 6s;
  animation-iteration-count: infinite;
`

const FourEnd = styled(Four)`animation-duration: 7s;`

const Oh = styled.div`
  font-size: 9vw;
  color: ${berry};
`

const Content = styled.div`
  margin-top: 5vw;
  margin-bottom: 2em;
`

function NotFound(props) {
  return (
    <Wrapper>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <FourOhFourWrapper>
        <Four>4</Four>
        <Oh>oh</Oh>
        <FourEnd>4</FourEnd>
      </FourOhFourWrapper>
      <Content>{props.translate('errors.404.message')}</Content>
      <Link to="/casino">{props.translate('errors.404.goback')}</Link>
    </Wrapper>
  )
}

NotFound.propTypes = { translate: PropTypes.func }

export default withTranslate(NotFound)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { FormBuilder } from '@rushplay/forms'

import { MultipleChoice } from './multiple-choice'
import { NumberInput } from './number-input'
import { SelectField } from './select-field'
import { TextInput } from './text-input'

function Text(props) {
  const i18n = I18n.useI18n()
  return <Common.Text {...props}>{i18n.translate(props.children)}</Common.Text>
}

Text.propTypes = {
  children: PropTypes.node,
}

export function Form(props) {
  return (
    <FormBuilder
      components={R.mergeRight(
        {
          text: Text,
          textField: TextInput,
          numberField: NumberInput,
          singleSelectField: SelectField,
          multipleSelectField: MultipleChoice,
          ...props.components,
        },
        props.components
      )}
      disabled={props.disabled}
      dataSchema={props.dataSchema}
      getCustomField={props.getCustomField}
      getComponent={props.getComponent}
      initialValues={props.initialValues}
      name={props.name}
      mountPoint="formsv2"
      uiSchema={props.uiSchema}
      onSubmit={props.onSubmit}
    />
  )
}

Form.propTypes = {
  components: PropTypes.object,
  disabled: PropTypes.bool,
  dataSchema: PropTypes.object.isRequired,
  getCustomField: PropTypes.func,
  getComponent: PropTypes.func,
  initialValues: PropTypes.object,
  name: PropTypes.string,
  uiSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Image } from '@rushplay/common'
import { keyframes } from '@emotion/core'

import Avatar from '../../common/avatar'
import Icon from '../../common/icon/icon'
import Line from '../../common/line'
import { headingFont } from '../../constants/typography'

/* This is for iphone 5 - 7 */
const mobileLandscape =
  'only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape)'

const rotateIn = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const AggregateBar = styled.div`
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  @media screen and (max-width: 320px) {
    width: 112px;
  }
  .spin__animation {
    animation: ${rotateIn};
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`

const AggregateRewardWrapper = styled.div`
  width: 60px;
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  top: 13%;
  left: 0;
  right: 0;
  position: absolute;
  @media screen and (max-width: 320px) {
    width: 45px;
  }
`

const AggregateReward = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: ${props => props.heightPercent}%;
`
const AggregateLine = styled(Line)`
  width: 100%;
  margin: 0;
  box-shadow: 0 1px 1px 0 #7f7f7f;
  @media ${mobileLandscape} {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
`

const AggregateRewardLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  @media ${mobileLandscape} {
    justify-content: center;
    width: 90%;
  }
`

const AggregateLevelAmount = styled.span`
  font-family: ${headingFont};
  font-weight: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 17px;
  color: ${props => (props.hasPassed ? '#121D45' : '#FFFFFF')};
  line-height: 22px;
  text-shadow: 1px 1px 1px
    ${props =>
      props.hasPassed ? 'rgba(127, 127, 127, .1)' : 'rgba(0, 0, 0, .1)'};
  @media ${mobileLandscape} {
    font-size: 12px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
    line-height: 12px;
  }
`

const AggregateLevelIcon = styled.span`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.hasPassed ? '#121D45' : 'white')};
  @media screen and (max-width: 320px) {
    width: 11px;
    height: 11px;
  }
`

const ImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 73px;
  @media screen and (min-width: 321px) {
    width: 101px;
  }
  @media screen and (min-width: 414px) {
    width: 125px;
  }
  @media screen and (min-width: 574px) {
    width: 134px;
  }
  @media ${mobileLandscape} {
    width: 70px;
  }
`
const AvatarWrapper = styled.div`
  width: 44px;
  height: 44px;
  position: absolute;
  box-shadow: 0 0 20px #000000;
  border-radius: 50%;
  margin-bottom: -20px;
  @media screen and (max-width: 320px) {
    width: 30px;
    height: 30px;
  }
`

const BossAvatarWrapper = styled(AvatarWrapper)`
  left: -35px;
  bottom: ${props => props.monsterProgress}%;
  @media screen and (max-width: 320px) {
    left: -22px;
  }
`

const PlayerAvatarWrapper = styled(AvatarWrapper)`
  right: -32px;
  bottom: ${props => props.playerProgress}%;
  @media screen and (max-width: 320px) {
    right: -20px;
  }
`

function AggregateProgress(props) {
  const heightPercent = 100 / (props.rewards.length + 1)
  const mapIndexed = R.addIndex(R.map)
  return (
    <AggregateBar>
      <ImageWrapper>
        <Image src={props.raceBarImg} width={134} alt="Race Progress Bar" />
      </ImageWrapper>
      <AggregateRewardWrapper>
        {mapIndexed((reward, index) => {
          const passed =
            heightPercent * (props.rewards.length - index + 1) <=
            Math.max(props.playerProgress, props.monsterProgress)
          return (
            <AggregateReward key={index} heightPercent={heightPercent}>
              <AggregateRewardLevelWrapper>
                <AggregateLevelIcon
                  className={!passed && 'spin__animation'}
                  hasPassed={passed}
                >
                  <Icon
                    name="refresh"
                    style={{ width: '100%', height: '100%' }}
                  />
                </AggregateLevelIcon>
                <AggregateLevelAmount hasPassed={passed}>
                  {reward.amount}
                </AggregateLevelAmount>
              </AggregateRewardLevelWrapper>
              <AggregateLine color="#000000" />
            </AggregateReward>
          )
        }, props.rewards)}
        {props.isAgainstMonster && (
          <BossAvatarWrapper monsterProgress={props.monsterProgress}>
            <Avatar src={props.monsterImg} />
          </BossAvatarWrapper>
        )}
        <PlayerAvatarWrapper playerProgress={props.playerProgress}>
          <Avatar src={props.playerImg} />
        </PlayerAvatarWrapper>
      </AggregateRewardWrapper>
    </AggregateBar>
  )
}

AggregateProgress.propTypes = {
  rewards: PropTypes.arrayOf(PropTypes.object).isRequired,
  monsterProgress: PropTypes.number,
  monsterImg: PropTypes.string,
  playerProgress: PropTypes.number.isRequired,
  playerImg: PropTypes.string.isRequired,
  raceBarImg: PropTypes.string.isRequired,
  isAgainstMonster: PropTypes.bool.isRequired,
}

AggregateProgress.defaultProps = {
  playerProgress: 0,
}

export default AggregateProgress

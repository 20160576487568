import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import HtmlContent from '../common/html-content'

export function SeoTextArea(props) {
  const i18n = I18n.useI18n()

  if (!i18n.isExistingTranslation(props.translationKey)) {
    return null
  }
  return (
    <Common.Box
      width="100%"
      backgroundColor="#1c3e7b"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Common.Box
        maxWidth="1200px"
        width="100%"
        my={[7, null, null, 9]}
        px="16px"
      >
        <HtmlContent html={{ __html: i18n.translate(props.translationKey) }} />
      </Common.Box>
    </Common.Box>
  )
}

SeoTextArea.propTypes = {
  translationKey: PropTypes.string,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const CounterWrapper = styled.span`
  left: 50%;
  font-size: 0.8em;
  line-height: 1.5em;
  position: absolute;
  text-align: center;
  top: -.5em;
  width: 100%;
  z-index: 2;
  pointer-events: none;
`

const Counter = styled.span`
  background: ${props => props.theme.variants.notification};
  font-weight: 600;
  border-radius: 2em;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  transform: ${props => props.children <= 0 && 'scale(0, 0)'};
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

function Badge(props) {
  return (
    <Wrapper>
      <CounterWrapper>
        <Counter>{props.count}</Counter>
      </CounterWrapper>
      {props.children}
    </Wrapper>
  )
}

Badge.defaultProps = { count: 0 }

Badge.propTypes = {
  children: PropTypes.element,
  count: PropTypes.number.isRequired,
}

export default Badge

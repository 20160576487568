import styled from '@emotion/styled'

export const Content = styled.div`
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }
`

export default Content

import PropTypes from 'prop-types'

export function Notifications(props) {
  return props.children(props.notifications)
}

Notifications.propTypes = {
  children: PropTypes.func,
  notifications: PropTypes.array,
}

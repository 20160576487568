import * as R from 'ramda'
import { createAction } from 'redux-actions'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as websockets from '@rushplay/websockets'
import { deleteNotification as deleteNotificationApi } from '@rushplay/api-client'

import triggers from './triggers/'

const initialState = {
  currentStep: 0,
}

export const clearOnboarding = createAction(
  'casino-heroes/onboarding/CLEAR_ONBOARDING'
)

export const incrementOnboardingStep = createAction(
  'casino-heroes/onboarding/INCREMENT_ONBOARDING_STEP'
)

export function onboardingReducer(state = initialState, action) {
  const mergeWithState = R.mergeRight(state)

  switch (action.type) {
    case 'casino-heroes/onboarding/INCREMENT_ONBOARDING_STEP': {
      return mergeWithState({ currentStep: state.currentStep + 1 })
    }
    case websockets.SESSION_EXPIRED:
    case 'casino-heroes/onboarding/CLEAR_ONBOARDING': {
      return initialState
    }
    default:
      return state
  }
}

export function finishOnboarding(notificationId) {
  return deleteNotificationApi(notificationId, {
    success: () => [
      triggers.actions.remove(notificationId),
      redirectTo('/adventure'),
    ],
    failure: error => [
      Notifications.add({
        message: error.value.message || 'error.generic',
        level: 'error',
      }),
      triggers.actions.remove(notificationId),
      redirectTo('/adventure'),
    ],
    version: 2,
  })
}

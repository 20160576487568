import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import InputField from '../../common/input-field'

import ReturningUsernameField from './returning-login-form'

function getReturningUserStyles(props) {
  if (
    !props.focused &&
    props.value &&
    props.pristine &&
    props.returningUserAvatar
  ) {
    return css`
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);
    `
  }

  return css`
    opacity: 0;
    pointer-events: none;
    transform: scale(1.05, 1.05);
  `
}

const Wrapper = styled.div`position: relative;`

const ReturningUserWrapper = styled.div`
  ${props => getReturningUserStyles(props)};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const LoginFieldWrapper = styled.div`
  opacity: ${props =>
    !props.focused && props.value && props.pristine && props.returningUserAvatar
      ? 0
      : 1};
  margin-bottom: ${props =>
    !props.focused && props.value && props.pristine && props.returningUserAvatar
      ? 34
      : 0}px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`

function UsernameField(props) {
  return (
    <Wrapper {...props}>
      <LoginFieldWrapper {...props}>
        <InputField
          autoCapitalize="none"
          autoCorrect="off"
          autoComplete="off"
          {...props}
        />
      </LoginFieldWrapper>

      {props.returningUserAvatar && (
        <ReturningUserWrapper {...props}>
          <ReturningUsernameField {...props} />
        </ReturningUserWrapper>
      )}
    </Wrapper>
  )
}

UsernameField.propTypes = {
  focused: PropTypes.bool,
  pristine: PropTypes.bool,
  returningUserAvatar: PropTypes.string,
  value: PropTypes.string,
}

export default UsernameField

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Icon from '../common/icon/icon'
import { textFont } from '../constants/typography'

const LinkWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
`

const Text = styled.p`
  flex: 1;
  font-size: 20px;
  font-family: ${textFont};
  font-weight: 600;
  margin-left: 10px;
`

function LogOutLink(props) {
  return (
    <Link to="logout">
      <LinkWrapper>
        <Icon name="logout" />
        <Text>{props.translate('more-page.logout')}</Text>
      </LinkWrapper>
    </Link>
  )
}

export default withTranslate(LogOutLink)

LogOutLink.propTypes = { translate: PropTypes.func }

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { STORAGE_TYPE, setItem } from 'redux-effects-localstorage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withCurrentTime } from '@rushplay/common'

import {
  getRealityCheckPeriod,
  resetRealityCheckTimer,
  updateTimeToRealityCheck,
} from '../store/player'

class RealityCheckTimer extends Component {
  constructor() {
    super()
    this.setRealityCheckTime = this.setRealityCheckTime.bind(this)
    this.setRealityCheckTimestamps = this.setRealityCheckTimestamps.bind(this)
  }

  setRealityCheckTime(timeToRealityCheck) {
    if (timeToRealityCheck) {
      const realityCheckAt = this.props.currentTime + timeToRealityCheck

      this.props.updateTimeToRealityCheck(timeToRealityCheck)
      this.props.setItem(
        'timeToRealityCheck',
        timeToRealityCheck,
        STORAGE_TYPE.session
      )
      this.realityCheckAt = realityCheckAt
    }
  }

  setRealityCheckTimestamps() {
    const timeToRealityCheck = this.realityCheckAt - this.props.currentTime
    this.props.setItem(
      'timeToRealityCheck',
      timeToRealityCheck,
      STORAGE_TYPE.session
    )
    this.props.setItem(
      'realityCheckPeriod',
      this.props.realityCheckPeriod,
      STORAGE_TYPE.session
    )
    this.props.setItem(
      'lastLoggedRealityCheckTimestamp',
      this.props.currentTime,
      STORAGE_TYPE.session
    )
  }

  componentWillMount() {
    this.setRealityCheckTime(
      this.props.timeToRealityCheck || this.props.realityCheckPeriod
    )
    this.setRealityCheckTimestamps()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentTime > this.realityCheckAt) {
      this.props.setItem(
        'timeToRealityCheck',
        this.props.realityCheckPeriod,
        STORAGE_TYPE.session
      )
      this.setRealityCheckTime(nextProps.realityCheckPeriod)
      this.props.resetRealityCheckTimer()
    }
  }

  componentWillUnmount() {
    const timeToRealityCheck = this.realityCheckAt - this.props.currentTime
    this.setRealityCheckTimestamps()
    this.props.updateTimeToRealityCheck(timeToRealityCheck)
  }

  render() {
    return React.cloneElement(this.props.children, {
      realityCheckTimeRemaining: this.realityCheckAt - this.props.currentTime,
    })
  }
}

RealityCheckTimer.propTypes = {
  children: PropTypes.node,
  currentTime: PropTypes.number.isRequired,
  realityCheckPeriod: PropTypes.number,
  resetRealityCheckTimer: PropTypes.func,
  setItem: PropTypes.func.isRequired,
  timeToRealityCheck: PropTypes.number,
  updateTimeToRealityCheck: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    realityCheckPeriod: getRealityCheckPeriod(state.player),
    timeToRealityCheck: R.path(['player', 'timeToRealityCheck'], state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetRealityCheckTimer,
      setItem,
      updateTimeToRealityCheck,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCurrentTime(RealityCheckTimer)
)

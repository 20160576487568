import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import { deprecated } from 'react-prop-types'

import styled from '@emotion/styled'

import { CloseButton } from '../../common/close-button'

import { h3 } from '../../mixins'

const Header = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 10px;
  min-width: 320px;
`

const Title = styled.h3`
  ${h3};
  color: white;
  text-align: center;
  flex-grow: 3;
  width: 208px;
  margin-left: 42px;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  @media screen and (min-width: 574px) {
    width: auto;
    margin-left: 52px;
  }
`

const CloseButtonWrapper = styled.div`
  flex-grow: 0;
  margin-right: 10px;
  @media screen and (min-width: 574px) {
    margin-left: 10px;
  }
`

function ReactModalWrapper(props) {
  return (
    <ReactModal
      ariaHideApp={false}
      className="modal__contents"
      contentLabel="contentLabel"
      isOpen={props.isOpen}
      overlayClassName="modal__overlay"
      portalClassName={props.className}
    >
      <Header>
        <Title>{props.title}</Title>
        <CloseButtonWrapper>
          <CloseButton onClick={props.onRequestClose} />
        </CloseButtonWrapper>
      </Header>
      {props.children}
    </ReactModal>
  )
}

ReactModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentLabel: deprecated(
    PropTypes.node,
    'contentLabel is not needed anymore'
  ),
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
  translate: PropTypes.func,
}

const CampaignModal = styled(ReactModalWrapper)`
  .modal__contents {
    outline: none;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 574px) {
      position: relative;
      width: auto;
      max-width: 90vw;
      height: auto;
    }
  }

  .modal__overlay {
    padding-top: 16px;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: rgba(0, 13, 61, 0.7);

    @media screen and (min-width: 574px) {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`

export default CampaignModal

import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'
import PropTypes from 'prop-types'
import { locationShape } from 'react-router/lib/PropTypes'

import * as Analytics from '@rushplay/analytics'
import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Session from '@rushplay/session'

import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Lookup from './store/lookup'
import * as Player from './store/player'
import * as App from './store/app'

export function getPayerConfig(state) {
  return {
    clientType: App.getClientType(state.app),
    countryCode:
      Player.getCountryCode(state) || Lookup.getCountryCode(state.lookup),
    currency: CombinedSelectors.getLocalCurrency(state),
    language: App.getLanguage(state.app),
    license: Jurisdiction.getLicense(state.jurisdiction),
    host: App.getPayerUrl(state.app),
    token: Session.getSessionToken(state.session),
    userId: Session.getUsername(state.session),
  }
}

function getInitialData(state) {
  return {
    affiliateClickId: Analytics.getClickId(state.analytics),
    affiliateSubId: CombinedSelectors.getAffiliateSubId(state),
    city: Player.getCity(state),
    email: Player.getEmail(state),
    firstName: Player.getFirstName(state),
    lastName: Player.getLastName(state),
    netrefererBtag: Analytics.getBtag(state.analytics),
    phoneNumber: Player.getPhoneNumber(state),
    postalCode: Player.getZip(state),
    province: Player.getState(state),
    ssn: Session.getSsn(state.session),
    state: Player.getState(state) || Player.getCity(state),
    street: Player.getStreet(state),
    utmCampaign: Analytics.getUtmCampaign(state.analytics),
    utmMedium: Analytics.getUtmMedium(state.analytics),
    utmSource: Analytics.getUtmSource(state.analytics),
  }
}

export function Payer(props) {
  const ref = React.useRef(null)
  const payerConfig = ReactRedux.useSelector(getPayerConfig)
  const initialData = ReactRedux.useSelector(getInitialData)
  const payerUrl = Url.format({
    pathname: props.transactionType,
    query: {
      amount_cents: props.amountCents,
      client_type: payerConfig.clientType,
      country_code: payerConfig.countryCode,
      currency: payerConfig.currency,
      language: payerConfig.language,
      license: payerConfig.license,
      offer_id: props.offerId,
      token: R.path(['initialData', 'verificationToken'], props)
        ? null
        : payerConfig.token,
      user_id: payerConfig.userId,
    },
  })

  React.useEffect(() => {
    function payerEventListener(event) {
      const action = event.data || {}
      const ALLOWED_ORIGIN = payerConfig.host

      if (event.origin === ALLOWED_ORIGIN) {
        switch (action.type) {
          case 'payer/INITIATED': {
            const postData = R.reject(
              (item) => R.isEmpty(item) || R.isNil(item),
              R.mergeDeepRight(props.initialData, initialData)
            )

            if (!R.isEmpty(postData)) {
              ref.current.contentWindow.postMessage(
                {
                  type: 'payer/UPDATE_INFORMATION',
                  payload: postData,
                },
                ALLOWED_ORIGIN
              )
            }
            props.onLoad && props.onLoad()
            break
          }

          case 'payer/STEP_CHANGED': {
            props.onStepChange && props.onStepChange(action.payload)
            break
          }

          case 'payer/PAYMENT_METHOD_SELECTED': {
            props.onSelectMethod && props.onSelectMethod(action.payload)
            break
          }

          case 'payer/TRANSACTION_CANCELED': {
            props.onCancel && props.onCancel(action.payload)
            break
          }

          case 'payer/TRANSACTION_FAILED': {
            props.onFailure && props.onFailure('failed', action.payload)
            break
          }

          case 'payer/TRANSACTION_SUCCEEDED': {
            props.onSuccess && props.onSuccess(action.payload)
            break
          }

          default: {
            return null
          }
        }
      }
    }
    window.addEventListener('message', payerEventListener)
    return () => window.removeEventListener('message', payerEventListener)
  }, [
    props.onSuccess,
    props.onCancel,
    props.onFailure,
    props.onStepChange,
    props.onSelectMethod,
    props.onLoad,
  ])

  return (
    <Common.Box
      as="iframe"
      borderRadius={1}
      height="100%"
      ref={ref}
      sandbox={R.join(' ', [
        'allow-forms',
        'allow-modals',
        'allow-orientation-lock',
        'allow-popups',
        'allow-popups-to-escape-sandbox',
        'allow-presentation',
        'allow-same-origin',
        'allow-scripts',
        'allow-top-navigation',
        'allow-top-navigation-by-user-activation',
      ])}
      src={`${payerConfig.host}/${payerUrl}`}
      title="Wallet"
      width="100%"
    />
  )
}

Payer.propTypes = {
  amountCents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialData: PropTypes.shape({
    beneficiaryName: PropTypes.string,
    beneficiaryStreet: PropTypes.string,
    beneficiaryZip: PropTypes.string,
    beneficiaryCity: PropTypes.string,
    beneficiaryState: PropTypes.string,
    beneficiaryCountry: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.number,
    ssn: PropTypes.number,
    verificationToken: PropTypes.string,
  }),
  offerId: PropTypes.number,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
  onCancel: PropTypes.func,
  onFailure: PropTypes.func,
  onLoad: PropTypes.func,
  onSelectMethod: PropTypes.func,
  onStepChange: PropTypes.func,
  onSuccess: PropTypes.func,
  location: locationShape.isRequired,
}

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Popup from './popup'
import {
  dequeuePopup,
  isCurrent,
  isRemoved,
  queuePopup,
  removePopup,
} from './popups'

class PopupContainer extends Component {
  componentDidMount() {
    if (this.props.isOpen) {
      this.props.queuePopup(this.props.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.props.queuePopup(this.props.id)
    }

    if (this.props.isOpen && !nextProps.isOpen) {
      this.props.dequeuePopup(this.props.id)
      if (this.props.openOnce) {
        this.props.removePopup(this.props.id)
      }
    }
  }

  componentWillUnmount() {
    this.props.dequeuePopup(this.props.id)
  }

  render() {
    if (typeof this.props.id === 'undefined') {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn('PopupContainer must have id')
      }
      return null
    }
    return (
      <Popup
        className={this.props.className}
        headerPadding={this.props.headerPadding}
        isOpen={this.props.isOpen && this.props.isOpenable}
        title={this.props.title}
        onRequestClose={this.props.onRequestClose}
        parentNode={this.props.parentNode}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        themeDark={this.props.themeDark}
      >
        {this.props.children}
      </Popup>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isOpenable:
      isCurrent(state.popups, ownProps) && !isRemoved(state.popups, ownProps),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dequeuePopup,
      queuePopup,
      removePopup,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer)

PopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentPopup: PropTypes.string,
  dequeuePopup: PropTypes.func.isRequired,
  headerPadding: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenable: PropTypes.bool.isRequired,
  openOnce: PropTypes.bool,
  parentNode: PropTypes.object,
  queuePopup: PropTypes.func.isRequired,
  removed: PropTypes.array,
  removePopup: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  themeDark: PropTypes.bool,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
}

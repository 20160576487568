import PropTypes from 'prop-types'
import React from 'react'

import { Constraint, Flex, Link, Space } from '@rushplay/common'

import Button from '../../../common/button'
import Card from '../../../common/card'
import Heading from '../../../common/heading'
import HtmlContent from '../../../common/html-content'
import Text from '../../../common/text'

export function RGDepositThresholdPrompt(props) {
  return (
    <Constraint width="600" centered>
      <Space y={6}>
        <Card>
          <Space all={6}>
            <Heading level="2" align="center">
              {props.translate(
                'responsible-gaming-deposit-threshold-prompt.title'
              )}
            </Heading>
            <Space top={5}>
              <Text align="center" lineHeight="20" size="16">
                <HtmlContent
                  html={{
                    __html: props.translate(
                      'responsible-gaming-deposit-threshold-prompt.content'
                    ),
                  }}
                />
              </Text>
            </Space>
            <Flex column justifyContent="center" alignItems="center">
              <Space y={5}>
                <Button small onClick={props.onAccept}>
                  {props.translate(
                    'responsible-gaming-deposit-threshold-prompt.button'
                  )}
                </Button>
              </Space>
              <Text align="center" color="gray" size="14">
                <Link
                  to="/dashboard/limits/money-limits"
                  onClick={props.onAccept}
                >
                  {props.translate(
                    'responsible-gaming-deposit-threshold-prompt.limits'
                  )}
                </Link>
              </Text>
            </Flex>
          </Space>
        </Card>
      </Space>
    </Constraint>
  )
}

RGDepositThresholdPrompt.propTypes = {
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as api from '@rushplay/api-client'
import * as forms from '@rushplay/forms'
import * as locks from '@rushplay/compliance/locks'

import { UnsetLoginTimeLimits as Component } from './unset-login-time-limits'

function mapStateToProps(state) {
  const lock = locks.getLock(state.locks, { type: 'unset-login-time-limits' })

  return {
    defaultValues: R.pathOr(0, ['defaultValues'], lock),
    formValues: {
      day: Number(
        forms.value(state.formsv2, {
          form: 'unset-login-time-limits',
          field: 'day',
        })
      ),
      week: Number(
        forms.value(state.formsv2, {
          form: 'unset-login-time-limits',
          field: 'week',
        })
      ),
      month: Number(
        forms.value(state.formsv2, {
          form: 'unset-login-time-limits',
          field: 'month',
        })
      ),
    },
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onSubmit: () =>
      dispatch(
        api.requestPlayerLimits(
          [
            {
              type: 'loginTime',
              period: 'day',
              amountCents: props.formValues['day'] * 6000,
            },
            {
              type: 'loginTime',
              period: 'week',
              amountCents: props.formValues['week'] * 6000,
            },
            {
              type: 'loginTime',
              period: 'month',
              amountCents: props.formValues['month'] * 6000,
            },
          ],
          {
            success: () => [
              locks.fetch(),
              locks.remove('unset-login-time-limits'),
            ],
            failure: () =>
              Notifications.add({
                message: 'player-limits.errors.set-limit',
                variables: {
                  target: 'loginTime',
                },
                level: 'error',
              }),
            version: 2,
          }
        )
      ),
    onUpdateLimits: (value, periods) => {
      R.map((period) => {
        return dispatch([
          forms.changeValue(
            'unset-login-time-limits',
            period,
            value * periods[period]
          ),
        ])
      }, R.keys(periods))
    },
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  connect(null, mapDispatchToProps)
)

export const UnsetLoginTimeLimits = connector(Component)

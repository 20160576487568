import * as R from 'ramda'

/**
 * Convert object to query string
 * @private
 * @name toQueryString
 * @param {object}
 * @return {string} query string
 */
export default R.pipe(
  // remove null-ish values
  R.reject(R.isNil),
  R.ifElse(
    R.isEmpty,

    // return empty string if empty
    R.always(''),

    // do the actual conversion
    R.pipe(
      // convert to array of pairs ({a: 1, b: 2} -> [['a', 1], ['b', 2]])
      R.toPairs,

      // join keys and values
      R.map(R.join('=')),

      // join pairs
      R.join('&'),

      // prepend with question mark
      queryString => `?${queryString}`
    )
  )
)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { Flex, Image } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import RadialProgress from '../radial-progress/radial-progress'
import { baseFontSizeSmall, headingFont } from '../../constants/typography'
import { h5 } from '../../mixins'

const Row = styled.div`
  padding: 10px;
  margin-top: 2px;
  background-color: rgba(0, 0, 0, 0.2);
`

const Title = styled.h5`
  ${h5};
  color: #fff;
  width: 160px;
  margin-left: 10px;

  @media screen and (min-width: 400px) {
    width: 230px;
  }
`

const RadialProgressWrapper = styled.div`
  width: 48px;
  height: 48px;
  font-family: ${headingFont};
  font-size: ${baseFontSizeSmall};

  @media screen and (min-width: 1280px) {
    font-size: 14px;
  }
`

function StatsRow(props) {
  return (
    <Row>
      <Flex center>
        <Image
          alt={props.translate(props.worldKey)}
          src={props.translate(`worlds.${props.worldKey}.world-icon`)}
          width={80}
        />

        <Title>{props.translate(props.worldKey)}</Title>

        <RadialProgressWrapper>
          <RadialProgress percentage={props.progressPercentage} />
        </RadialProgressWrapper>
      </Flex>
    </Row>
  )
}

export default withTranslate(StatsRow)

StatsRow.propTypes = {
  progressPercentage: PropTypes.number,
  worldKey: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

import * as recompose from 'recompose'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { borderRadius, color, font } from '@rushplay/theme'
import { css } from '@emotion/core'

import { withThemeForLightBG } from '../theme/withThemeForLightBG'

import { CloseButton } from './close-button'

const CardElement = styled.div`
  border-radius: ${(props) => borderRadius(props.borderRadius)};
  color: ${(props) => (props.themeDark ? color('white') : color(props.color))};
  font-family: ${font('body')};
  line-height: 1.2;
  background-color: ${(props) =>
    props.themeDark ? color('darkBlue2') : color(props.backgroundColor)};
  ${(props) =>
    props.onRequestClose &&
    css`
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
    `};
`

const PositionTopRight = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`
CardElement.defaultProps = {
  color: 'blackish',
  backgroundColor: 'white',
  borderRadius: 0,
}

Card.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRequestClose: PropTypes.func,
  themeDark: PropTypes.bool,
}

function Card(props) {
  return (
    <CardElement {...props}>
      {props.onRequestClose && (
        <PositionTopRight>
          <CloseButton onClick={props.onRequestClose} />
        </PositionTopRight>
      )}
      {props.children}
    </CardElement>
  )
}

const addTheme = recompose.branch(
  (props) =>
    R.isNil(props.themeDark) || R.equals(props.backgroundColor, 'white'),
  recompose.renderComponent(withThemeForLightBG(Card))
)

export default addTheme(Card)

import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as Casino from '@rushplay/casino'

import * as app from '../store/app'

import { Metadata as Component } from './metadata'

function mapStateToProps(state, props) {
  const gameId = R.path(['params', 'gameId'], props)

  return {
    metaTitle: app.getMetadataTitle(state.app),
    metaDescription: app.getMetadataDescription(state.app),
    game: Casino.selectors.getGame(state.casino, { id: gameId }),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: url => dispatch(app.fetchMetadata(url)),
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export const Metadata = connector(Component)

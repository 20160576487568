import PropTypes from 'prop-types'
import React from 'react'
import parseMs from 'parse-ms'

import * as limits from '@rushplay/compliance/limits'
import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'

import Heading from '../../../../common/heading'
import Line from '../../../../common/line'
import PlayerLimit from '../../../player-limit'
import PlayerLimitForm from '../../../player-limit-form'
import toRemainingTime from '../../../../to-remaining-time'
import { PageSpinner } from '../../../../common/spinner'

const LimitWrapper = styled.div`
  width: 300px;
`

export function Limit(props) {
  if (!props.period) {
    return <PageSpinner />
  }

  return (
    <limits.Limit
      mountPoint="limits"
      period={props.period}
      target={props.target}
    >
      {(limit) => (
        <React.Fragment>
          {!limit.isLoaded ? (
            <PageSpinner />
          ) : (
            <Space all={7}>
              <Space bottom={6}>
                <Line />
              </Space>

              <LimitWrapper key={limit.period}>
                <Flex column>
                  <Heading align="center" level="4">
                    {props.translate(`player-limits.type.${limit.period}`)}
                  </Heading>
                  {limit.isUnset || limit.isEditing ? (
                    <PlayerLimitForm
                      key={`${limit.target}-${limit.period}`}
                      cooldown={parseMs(props.cooldown).days}
                      name={`${limit.target}-${limit.period}`}
                      initialLimitAmount={
                        (limit.nextValueCents || limit.valueCents) / 6000
                      }
                      onSubmit={(fields) =>
                        limit.onSet(fields.amount.value * 6000)
                      }
                      maxAmount={props.max}
                      minAmount={props.min}
                      target={limit.target}
                    />
                  ) : (
                    <PlayerLimit
                      cooldown={props.cooldown}
                      isFetchingActiveFrom={
                        limit.activeFrom === Number.POSITIVE_INFINITY
                      }
                      activeFrom={props.translate(
                        'player-limits.active-from',
                        toRemainingTime(0, limit.activeFrom, props.currentTime)
                      )}
                      editableFrom={props.translate(
                        'player-limits.active-from',
                        toRemainingTime(
                          0,
                          limit.editableFrom,
                          props.currentTime
                        )
                      )}
                      canEdit={toRemainingTime(
                        0,
                        limit.editableFrom,
                        props.currentTime
                      )}
                      amount={limit.valueCents / 6000}
                      nextAmount={limit.nextValueCents / 6000}
                      isIncreased={limit.isIncreased}
                      isRemoved={limit.isRemoved}
                      onEdit={limit.onEditToggle}
                      onCancel={limit.onRevert}
                      onRemove={props.required ? null : limit.onUnset}
                      target={limit.target}
                    />
                  )}
                </Flex>
              </LimitWrapper>
            </Space>
          )}
        </React.Fragment>
      )}
    </limits.Limit>
  )
}

Limit.propTypes = {
  cooldown: PropTypes.number,
  required: PropTypes.bool,
  currentTime: PropTypes.number,
  id: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  period: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box } from '@rushplay/common'

const Image = styled.img`
  height: 22px;
  padding-top: 17px;
  padding-right: 32px;

  @media screen and (max-width: 1279px) {
    padding-right: 24px;
  }
`

export function Providers(props) {
  return (
    <Box display="flex" flexWrap="wrap">
      {R.map(
        (provider) => (
          <Image key={provider.name} src={provider.image.url} />
        ),
        props.providers
      )}
    </Box>
  )
}

Providers.propTypes = {
  providers: PropTypes.array,
}

import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'
import * as Locks from '@rushplay/compliance/locks'
import styled from '@emotion/styled'

import * as Forms from '../../../common/forms'
import Button from '../../../common/button'
import Card from '../../../common/card'
import Heading from '../../../common/heading'
import LogOutLink from '../../log-out-link'
import { Constraint } from '../../../common/grid'
import { berry } from '../../../constants/colors'

import MulipleSelect from './multiple-select'
import UiSchema from './ui-schema.js'
import { dataSchema } from './data-schema'

const SubmitButton = styled(Button)`
  max-width: 422px;
`

export function SourceOfFunds() {
  const i18n = I18n.useI18n()
  const lock = ReactRedux.useSelector((state) =>
    Locks.getLock(state.locks, {
      type: 'source-of-funds-verification',
    })
  )

  const dispatch = ReactRedux.useDispatch()

  function handleSubmit(data) {
    dispatch([
      Api.submitSourceOfFunds(data, {
        failure: (res) =>
          Notifications.add({
            message: res?.value,
            level: 'error',
          }),
        version: 1,
      }),
      Api.acceptPrivacyPolicy({
        success: () => [
          Locks.fetch(),
          Locks.remove('source-of-funds-verification'),
        ],
        version: 1,
      }),
    ])
  }

  function handleDismiss() {
    dispatch(
      Api.dismissSourceOfFunds({
        success: () => [
          Locks.fetch(),
          Locks.remove('source-of-funds-verification'),
        ],
        failure: (res) =>
          Notifications.add({
            message: res?.value,
            level: 'error',
          }),
        version: 1,
      })
    )
  }

  if (!lock) {
    return null
  }

  if (lock?.rejected) {
    return (
      <Constraint centered width="500">
        <Common.Space y="32px" x={5}>
          <Card>
            <Common.Space all={6}>
              <Heading align="center" color={berry} level="2">
                {i18n.translate('source-of-funds.title')}
              </Heading>
              <Common.Space y="10px">
                <Common.Text bold align="center" size="18">
                  {i18n.translate('source-of-funds.rejected')}
                </Common.Text>
              </Common.Space>
            </Common.Space>
          </Card>
        </Common.Space>
        <Common.Box textAlign="center">
          <LogOutLink />
        </Common.Box>
      </Constraint>
    )
  }

  return (
    <Common.Box my="32px" mx={[null, null, 5, 'auto']} maxWidth="1040px">
      <Common.Box textAlign="center" fontSize="39px" mb="4">
        {i18n.translate('source-of-funds.title')}
      </Common.Box>
      <Card themeDark>
        <Common.Space x={7}>
          {!lock?.dismissable && (
            <Common.Space top={8}>
              <Common.Text fontWeight="700" fontSize="14px">
                {i18n.translate('source-of-funds.last-time-alert')}
              </Common.Text>
            </Common.Space>
          )}
          <Common.Space y={6}>
            <Common.Text lineHeight="2" fontSize="14px">
              {i18n.translate('source-of-funds.content.why')}
            </Common.Text>
          </Common.Space>
          <Common.Space y={4}>
            <Forms.Form
              components={{ submit: SubmitButton }}
              dataSchema={dataSchema()}
              getCustomField={(_, scope) => {
                if (scope === 'fundsCountryOrigin') {
                  return MulipleSelect
                }
              }}
              name="source-of-funds"
              uiSchema={UiSchema()}
              onSubmit={(data) =>
                handleSubmit({
                  employment: data.employment?.value,
                  occupation: data.occupation?.value || 'none',
                  salary: data.salary.value,
                  sourcesOfIncome: data.otherSourcesField?.value
                    ? [...data.otherSources.value, data.otherSourcesField.value]
                    : data.otherSources?.value,
                  nationality: data.nationality?.value,
                  fundsCountryOrigin: data.fundsCountryOrigin?.value,
                  placeOfBirth: data.placeOfBirth?.value,
                  idNumber: data.idNumber?.value,
                  anticipatedYearlyDeposits:
                    data.anticipatedYearlyDeposits?.value,
                  sourcesOfFunds: data.sourcesOfFundsOtherField?.value
                    ? [
                        ...data.sourcesOfFunds.value,
                        data.sourcesOfFundsOtherField.value,
                      ]
                    : data.sourcesOfFunds?.value,
                })
              }
            />
            <Forms.FieldResponse form="source-of-funds" for="idNumber" />
          </Common.Space>
          <Common.Space y="15px">
            <Common.Flex column>
              {lock?.dismissable && (
                <Common.Space bottom={3}>
                  <Button onClick={() => handleDismiss()}>
                    {i18n.translate('source-of-funds.dismiss')}
                  </Button>
                </Common.Space>
              )}
              <Common.Box
                textAlign={['center', null, 'left']}
                my={1}
                ml={['0px', null, '150px']}
              >
                <LogOutLink />
              </Common.Box>
            </Common.Flex>
          </Common.Space>
        </Common.Space>
      </Card>
    </Common.Box>
  )
}

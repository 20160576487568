import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as onNavigateTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import inventory from '@rushplay/inventory'
import styled from '@emotion/styled'
import { Flex } from '@rushplay/common'
import { css } from '@emotion/core'
import {
  deleteSecretPrize,
  fetchInventory,
  fetchNotificationsByKind,
  fetchSecretPrizes,
  readInventoryNotifications,
  useItem,
} from '@rushplay/api-client'
import { getCurrency } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as processIds from '../constants/processes'
import Icon from '../common/icon/icon'
import InventoryLockPopup from '../components/inventory-lock-popup'
import InventoryTile from '../common/inventory-tile'
import PopupContainer from '../common/popup/popup-container'
import ProgressionContainer from '../common/progression'
import getDurationString from '../util/get-duration-string'
import toRemainingTime from '../to-remaining-time'
import triggers from '../store/triggers'
import {
  getAssetPath,
  getClientType,
  getLanguage,
  isWaiting,
  startWaiting,
  stopWaiting,
} from '../store/app'
import { getInventoryLockedStatus } from '../store/selectors'
import { headingFont } from '../constants/typography'

import InventoryGrid from './inventory-grid'
import InventoryItemDetails from './inventory-item-details'
import InventorySecretItemDetails from './inventory-secret-item-details'
import Lock from './lock'

const TileWrapper = styled.div`
  margin: 6px auto;
  width: 100px;
  height: 100px;
  position: relative;

  @media screen and (min-width: 360px) {
    margin: 10px;
  }
`

const LockWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  background-color: rgba(0, 0, 0, 0.6);
  padding-bottom: 7px;
  padding-left: 7px;
  box-sizing: border-box;
  border-radius: 10px;

  font-family: ${headingFont};
  font-size: 13px;
  color: #a1e3ff;
`

const SecretItems = styled(InventoryGrid)`
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(158, 167, 183, 0.2);

  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 720px) {
    background-position-x: 50%;
  }
`

const SecretItem = styled.div`
  position: relative;
  display: flex;
  width: ${props => (props.large ? 100 : 80)}px;
  height: ${props => (props.large ? 100 : 80)}px;
  margin: 7.5px 15px;
  border-radius: 10px;

  background-color: #13223f;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-size: 70% 70%;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    `};

  @media screen and (min-width: 620px) {
    width: ${props => (props.large ? 170 : 130)}px;
    height: ${props => (props.large ? 170 : 130)}px;
    margin: 12.5px 25px;
  }
`

const SecretItemCheck = styled(Icon)`
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 15px;

  @media screen and (min-width: 620px) {
    font-size: 25px;
  }
`

const mapIndexed = R.addIndex(R.map)

const isBooster = R.propEq('type', 'PPBooster')

function getTitle(translate, item) {
  switch (item && item.type.toLowerCase()) {
    case 'freespins':
      return item.gameTitle
    case 'rubies':
      return translate('inventory.rubies')
    case 'ppbooster':
      return translate('inventory.ppbooster.title')
    case 'moneyitem':
      return translate('inventory.real-money')
    case 'featuretriggers':
      return translate('inventory.bonus-game.title')
    case 'freebet':
      return translate('inventory.freebet')
    case 'freegamesvoucher':
      return translate('inventory.freegamesvoucher.title')
    default:
      return translate('inventory.bonus')
  }
}

class InventoryContainer extends Component {
  constructor(props) {
    super(props)
    this.imageUrl = this.imageUrl.bind(this)
    this.handleUse = this.handleUse.bind(this)
    this.handleClaim = this.handleClaim.bind(this)
  }

  componentWillMount() {
    this.props.onFetchNotifications()

    if (R.isEmpty(this.props.items) || this.props.newItems > 0) {
      this.props.fetchInventory({
        success: res => inventory.items.actions.update(res.value),
        failure: () =>
          Notifications.add({ message: 'error.generic', level: 'error' }),
        version: 1,
      })
    }

    if (this.props.newItems > 0) {
      this.props.readInventoryNotifications({
        success: () => this.props.updateNewItems(0),
        failure: () =>
          Notifications.add({ message: 'error.generic', level: 'error' }),
        version: 1,
      })
    }

    this.props.fetchSecretPrizes({
      success: response => this.props.updateSecretItems(response.value.result),
      failure: () =>
        Notifications.add({ message: 'error.generic', level: 'error' }),
      version: 1,
    })
  }

  imageUrl(type = '') {
    const tileUrls = {
      freebet: `${this.props.assetPath}/inventory/freebet.jpg`,
      rubies: `${this.props.assetPath}/bossfight/ruby.jpg`,
      ppbooster: null,
      moneyitem: `${this.props.assetPath}/inventory/real_money_crown.png`,
      freegamesvoucher: `${this.props
        .assetPath}/inventory/free_games_voucher.png`,
    }

    return R.defaultTo(
      `${this.props.assetPath}/inventory/bonus_money_crown.png`,
      tileUrls[type.toLowerCase()]
    )
  }

  handleUse() {
    this.props.startWaiting(processIds.USE_ITEM)
    this.props.useItem(this.props.selectedItem.id, {
      success: res => {
        const usedItem = res.value
        this.props.stopWaiting(processIds.USE_ITEM)
        this.props.removeItem(usedItem.id)
        if (
          usedItem.type.toLowerCase() === 'freespins' ||
          usedItem.type.toLowerCase() === 'featuretriggers'
        ) {
          return this.props.onNavigateTo({
            pathname: `/casino/${usedItem.blitz
              ? 'blitz'
              : 'slots'}/${usedItem.gameProvider.toLowerCase()}/${usedItem.gameId}/${usedItem.gameKey}`,
            search: `?referrer=treasure-chest&mode=${usedItem.blitz
              ? 'blitz'
              : 'normal'}`,
          })
        } else if (usedItem.type.toLowerCase() === 'freegamesvoucher') {
          return this.props.onNavigateTo({
            pathname: `/casino/other/${usedItem.gameProvider.toLowerCase()}/${usedItem.gameId}/${usedItem.gameKey}`,
            search: `?referrer=treasure-chest&free_games=${usedItem.freeGames}`,
          })
        } else if (usedItem.type.toLowerCase() === 'freebet') {
          return this.props.onNavigateTo({
            pathname: R.defaultTo(`/sports`, usedItem.redirectUrl),
          })
        } else if (usedItem.type.toLowerCase() === 'ppbooster') {
          this.props.fetchInventory({
            success: res => inventory.items.actions.update(res.value),
            failure: () =>
              Notifications.add({ message: 'error.generic', level: 'error' }),
          })
          return this.props.onNavigateTo({
            pathname: `/casino`,
          })
        }

        this.props.onNavigateTo('/treasure-chest')
      },
      failure: res => [
        Notifications.add({
          message: res.value.message || 'error.generic',
          level: 'error',
        }),
        stopWaiting(processIds.USE_ITEM),
      ],
      version: 1,
    })
  }

  handleClaim(id, isClaimable) {
    if (!isClaimable) {
      this.props.onNavigateTo('/treasure-chest')
      return
    }

    this.props.startWaiting(processIds.CLAIM_SECRET_ITEM)

    this.props.deleteSecretPrize(id, {
      success: res => {
        this.props.claimSecretItem(id)
        // tag items to add new flag within inventory list
        const taggedItems = R.map(
          item => R.assoc('tag', true, item),
          res.value.result
        )
        this.props.updateItems(taggedItems)

        this.props.stopWaiting(processIds.CLAIM_SECRET_ITEM)
        this.props.onNavigateTo('/treasure-chest')
      },
      failure: res => [
        stopWaiting(processIds.CLAIM_SECRET_ITEM),
        Notifications.add({ message: res.value.error, level: 'error' }),
      ],
      version: 1,
    })
  }

  renderLock(serverTimeOffset, itemActiveFrom) {
    if (serverTimeOffset + itemActiveFrom - Date.now() > 0) {
      const { days, hours } = toRemainingTime(
        serverTimeOffset,
        itemActiveFrom,
        Date.now()
      )
      return (
        <LockWrapper>
          <Lock>
            {days >= 2 ? (
              this.props.translate('inventory.lock.remaining-days', {
                days: days + 1,
              })
            ) : (
              this.props.translate('inventory.lock.remaining-hours', {
                hours: hours + days * 24 + 1,
              })
            )}
          </Lock>
        </LockWrapper>
      )
    }
  }

  render() {
    return (
      <div>
        <ProgressionContainer />
        {this.props.lockedStatus && (
          <InventoryLockPopup locked={this.props.lockedStatus === 'locked'} />
        )}
        {this.props.secretItems.length > 0 && (
          <SecretItems
            background={this.props.translate(
              'inventory.secret-item.background'
            )}
          >
            {mapIndexed(
              (secretItems, i) => (
                <Flex center key={i}>
                  {mapIndexed((secretItem, j) => {
                    if (secretItem.claimed) {
                      return <SecretItem key={`${i}-${j}`} large={i === 0} />
                    }
                    return (
                      <SecretItem
                        key={`${i}-${j}`}
                        large={i === 0}
                        background={secretItem.imageUrl}
                        onClick={() =>
                          this.props.onNavigateTo(
                            `/treasure-chest/secret/${secretItem.id}`
                          )}
                      >
                        <SecretItemCheck
                          name={
                            secretItem.found ? 'tick-active' : 'tick-inactive'
                          }
                        />
                      </SecretItem>
                    )
                  }, secretItems)}
                </Flex>
              ),
              this.props.secretItems
            )}
          </SecretItems>
        )}
        {this.props.items && (
          <InventoryGrid>
            {R.map(
              item => (
                <TileWrapper key={item.id}>
                  <InventoryTile
                    amount={item.amount || item.amountCents / 100 || null}
                    betAmountCents={item.betAmountCents}
                    blitzMode={item.blitz}
                    multiplier={item.multiplier}
                    duration={
                      item.duration &&
                      getDurationString(item.duration, this.props.translate)
                    }
                    image={item.gameImage || this.imageUrl(item.type)}
                    value={item.heroSpinType}
                    tag={item.tag && this.props.translate('game-tile.new')}
                    itemLabel={
                      isBooster(item) ? (
                        this.props.translate('inventory.ppbooster.label')
                      ) : null
                    }
                    itemSubLabel={
                      isBooster(item) ? (
                        this.props.translate('inventory.ppbooster.sublabel')
                      ) : null
                    }
                    itemType={item.type}
                    itemTitle={getTitle(this.props.translate, item)}
                    onClick={() =>
                      this.props.onNavigateTo(`/treasure-chest/${item.id}`)}
                  />
                  {this.renderLock(
                    this.props.serverTimeOffset,
                    Date.parse(item.activeFrom)
                  )}
                </TileWrapper>
              ),
              this.props.items
            )}

            {R.times(
              index => (
                <TileWrapper key={index}>
                  <InventoryTile />
                </TileWrapper>
              ),
              6 - this.props.items.length % 6
            )}
          </InventoryGrid>
        )}
        <PopupContainer
          id="inventory-details"
          isOpen={Boolean(this.props.selectedItem)}
          onRequestClose={() => this.props.onNavigateTo('/treasure-chest')}
        >
          <InventoryItemDetails
            clientType={this.props.clientType}
            language={this.props.language}
            imageUrl={this.imageUrl(R.path(['type'], this.props.selectedItem))}
            duration={
              R.path(['selectedItem', 'duration'], this.props) &&
              getDurationString(
                this.props.selectedItem.duration,
                this.props.translate
              )
            }
            item={this.props.selectedItem}
            onUse={this.handleUse}
            useItemInProgress={this.props.useItemInProgress}
            itemTitle={
              this.props.selectedItem &&
              getTitle(this.props.translate, this.props.selectedItem)
            }
            itemLabel={
              this.props.selectedItem && isBooster(this.props.selectedItem) ? (
                this.props.translate('inventory.ppbooster.label')
              ) : null
            }
            itemSubLabel={
              this.props.selectedItem && isBooster(this.props.selectedItem) ? (
                this.props.translate('inventory.ppbooster.sublabel')
              ) : null
            }
          />
        </PopupContainer>
        <PopupContainer
          id="inventory-secret-item"
          isOpen={Boolean(this.props.selectedSecretItem)}
          onRequestClose={() => this.props.onNavigateTo('/treasure-chest')}
        >
          <InventorySecretItemDetails
            item={this.props.selectedSecretItem}
            onClaim={this.handleClaim}
            claimSecretItemInProgress={this.props.claimSecretItemInProgress}
          />
        </PopupContainer>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const items =
    inventory.items.selectors
      .get(state.inventory)
      .filter(item => item.usedAt === null) || []
  const secretItems = inventory.secretItems.selectors.get(state.inventory) || []

  return {
    assetPath: getAssetPath(state.app),
    claimSecretItemInProgress: isWaiting(
      state.app,
      processIds.CLAIM_SECRET_ITEM
    ),
    clientType: getClientType(state.app),
    currency: getCurrency(state.session),
    items,
    language: getLanguage(state.app),
    lockedStatus: getInventoryLockedStatus(state),
    newItems: inventory.newItems.selectors.get(state.inventory),
    secretItems,
    selectedItem: R.find(R.propEq('id', parseInt(props.params.id)), items),
    selectedSecretItem: R.find(
      R.propEq('id', parseInt(props.params.secretId)),
      R.flatten(secretItems)
    ),
    serverTimeOffset: state.app.serverTimeOffset,
    useItemInProgress: isWaiting(state.app, processIds.USE_ITEM),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      claimSecretItem: inventory.secretItems.actions.claim,
      deleteSecretPrize,
      fetchInventory,
      fetchSecretPrizes,
      readInventoryNotifications,
      removeItem: inventory.items.actions.remove,
      startWaiting,
      stopWaiting,
      updateItems: inventory.items.actions.update,
      updateNewItems: inventory.newItems.actions.update,
      updateSecretItems: inventory.secretItems.actions.update,
      useItem,
      onNavigateTo,
      onFetchNotifications: () =>
        fetchNotificationsByKind('popup', {
          success: res => triggers.actions.update(res.value),
          failure: res =>
            Notifications.add({ message: res.value, level: 'error' }),
          version: 1,
        }),
    },
    dispatch
  )
}

InventoryContainer.propTypes = {
  assetPath: PropTypes.string.isRequired,
  claimSecretItem: PropTypes.func.isRequired,
  claimSecretItemInProgress: PropTypes.bool.isRequired,
  clientType: PropTypes.string,
  currency: PropTypes.string.isRequired,
  deleteSecretPrize: PropTypes.func,
  fetchInventory: PropTypes.func.isRequired,
  fetchSecretPrizes: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  language: PropTypes.string,
  lockedStatus: PropTypes.string,
  newItems: PropTypes.number.isRequired,
  params: PropTypes.object,
  readInventoryNotifications: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  secretItems: PropTypes.arrayOf(PropTypes.array),
  selectedItem: PropTypes.object,
  selectedSecretItem: PropTypes.object,
  serverTimeOffset: PropTypes.number,
  startWaiting: PropTypes.func.isRequired,
  stopWaiting: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  updateItems: PropTypes.func.isRequired,
  updateNewItems: PropTypes.func.isRequired,
  updateSecretItems: PropTypes.func.isRequired,
  useItem: PropTypes.func.isRequired,
  useItemInProgress: PropTypes.bool.isRequired,
  onFetchNotifications: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslate(InventoryContainer)
)

export const STORE_GAMES = 'casino/games/STORE_GAMES'
export const STORE_GAME_PROPS = 'casino/games/STORE_GAME_PROPS'
export const STORE_GAMES_ORDER = 'casino/games/STORE_GAMES_ORDER'
export const CLEAR_GAME_OPTIONS = 'casino/games/CLEAR_GAME_OPTIONS'
export const CLEAR_QUERY_RESULTS = 'casino/games/CLEAR_QUERY_RESULTS'
export const APPEND_QUERY_RESULTS = 'casino/games/APPEND_QUERY_RESULTS'
export const REPLACE_QUERY_RESULTS = 'casino/games/REPLACE_QUERY_RESULTS'
export const STORE_QUERY_OFFSET = 'casino/games/STORE_QUERY_OFFSET'
export const UPDATE_FILTER_GAME_TYPE = 'casino/games/UPDATE_FILTER_GAME_TYPE'
export const UPDATE_FILTER_BET_RANGE = 'casino/games/UPDATE_FILTER_BET_RANGE'

import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { PageSpinner } from '../common/spinner'
import { SeoTextArea } from '../components/seo-text-area'

import HeaderContainer from './header-container'
import LiveCasino from './live-casino'
import RangeSlider from './range-slider'

class LiveCasinoContainer extends React.Component {
  componentDidMount() {
    this.props.onDidMount({
      clientType: this.props.clientType,
      liveCasinoChannel: this.props.liveCasinoChannel,
    })
  }

  componentWillUnmount() {
    this.props.onWillUnmount({
      liveCasinoChannel: this.props.liveCasinoChannel,
    })
  }

  render() {
    if (this.props.loading) {
      return <PageSpinner />
    }
    return (
      <React.Fragment>
        <Common.Box flex="1" height="100%" width="100%">
          <HeaderContainer>
            <RangeSlider
              currency={this.props.currency}
              locale={this.props.locale}
              stepValues={[0, 1, 2, 5, 10, 20, 50, 100, 200, 500]}
              values={this.props.filters.betRange}
              onChange={this.props.onBetRangeChange}
            />
          </HeaderContainer>
          <LiveCasino
            currentCategory={'this.props.filters.category'}
            games={this.props.games}
            currency={this.props.currency}
            isAuthenticated={this.props.isAuthenticated}
            loggedInOnce={this.props.loggedInOnce}
            redirectTo={this.props.redirectTo}
          />
        </Common.Box>
        {this.props.filters.category ? (
          <SeoTextArea
            translationKey={`live-casino-${this.props.filters.category}-page.seo-content`}
          />
        ) : (
          <SeoTextArea translationKey="live-casino-page.seo-content" />
        )}
      </React.Fragment>
    )
  }
}

export default withTranslate(LiveCasinoContainer)

LiveCasinoContainer.propTypes = {
  clientType: PropTypes.string,
  liveCasinoChannel: PropTypes.string,
  currency: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  filters: PropTypes.shape({
    betRange: PropTypes.arrayOf(PropTypes.number),
    category: PropTypes.string,
  }),
  games: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      dealerName: PropTypes.string,
      gameType: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string,
      maxBetCents: PropTypes.number.isRequired,
      minBetCents: PropTypes.number.isRequired,
      seatsAvailable: PropTypes.number,
      tags: PropTypes.array,
      title: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  locale: PropTypes.string,
  loggedInOnce: PropTypes.bool,
  onBetRangeChange: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onWillUnmount: PropTypes.func,
  params: PropTypes.object,
  translate: PropTypes.func,
  redirectTo: PropTypes.func,
}

/**
 * Calculate element’s offsetTop relative to document
 * @param {Element} el
 * @returns {number} offset
 */
export function documentOffsetTop(el, _offset) {
  const offset = _offset || 0

  if (!el || !el.offsetParent || isNaN(el.offsetTop)) {
    return offset
  }

  return documentOffsetTop(el.offsetParent, offset + el.offsetTop)
}

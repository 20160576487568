import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'

import * as Locks from '@rushplay/compliance/locks'
import * as Notifications from '@rushplay/notifications'

export function LockCheck() {
  const locks = ReactRedux.useSelector((state) => Locks.getLocks(state.locks))
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    if (!R.isEmpty(locks)) {
      dispatch([
        ReactRouterRedux.push(`/locks/${R.head(locks)}`),
        Notifications.dismissAll(),
      ])
    }
  }, [locks])

  return null
}

import * as types from './types'

function clear() {
  return {
    type: types.CLEARED,
  }
}

function remove(payload) {
  return {
    type: types.REMOVED,
    payload,
  }
}

function update(payload) {
  return {
    type: types.UPDATED,
    payload,
  }
}

export default {
  clear,
  remove,
  update,
}

import * as R from 'ramda'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as websockets from '@rushplay/websockets'
import * as casino from '@rushplay/casino'
import { fetchLiveCasinoTables } from '@rushplay/api-client'
import { isSessionActive } from '@rushplay/session'

import * as App from '../store/app'
import getPlayerCurrency from '../util/get-player-currency'
import {
  getFilteredGames,
  getFilters,
  getLiveCasinoGames,
  setBetRange,
  setLiveCasinoGames,
} from '../store/live-casino'

function mapStateToProps(state, props) {
  return {
    isAuthenticated: isSessionActive(state),
    clientType: App.getClientType(state.app),
    locale: App.getLanguage(state.app),
    loggedInOnce: App.getLoggedInOnce(state.app),
    filters: getFilters(state, props.params),
    currency: getPlayerCurrency(state),
    games: getFilteredGames(state, { category: props.params.category }),
    loading: R.isEmpty(getLiveCasinoGames(state)),
    liveCasinoChannel: App.getLiveCasinoChannelKey(state.app),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onBetRangeChange: values => {
      dispatch(setBetRange(values))
    },
    onWillUnmount: props =>
      dispatch(websockets.unsubscribe(props.liveCasinoChannel)),
    onDidMount: props => {
      const options = {
        type: props.clientType,
        config: {
          success: res => [
            casino.actions.storeGames(R.indexBy(R.prop('id'), res.value)),
            setLiveCasinoGames(R.pluck('id', res.value)),
            websockets.subscribe(props.liveCasinoChannel),
          ],
          failure: () =>
            Notifications.add({ message: 'error.generic', level: 'error' }),
          version: 1,
        },
      }
      dispatch(fetchLiveCasinoTables(options))
    },
    redirectTo: url => dispatch(redirectTo(url)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import kebabCase from 'lodash.kebabcase'

import { Box, Flex } from '@rushplay/common'

import { FooterSection } from '../footer-section'
import { SocialMedia } from '../social-media'

export function Sitemap(props) {
  const mapIndexed = R.addIndex(R.map)
  return (
    <Flex flexDirection={['column', null, null, null, 'row']}>
      <Flex flexWrap="wrap">
        {props.casinoLinks && (
          <Box display={['none', null, null, null, 'block']}>
            <FooterSection
              heading="footer.sections.casino"
              sectionLinks={props.casinoLinks}
            />
          </Box>
        )}

        {mapIndexed(
          (section, index) => (
            <FooterSection
              heading={`footer.sections.${kebabCase(
                R.keys(props.footerLinks)[index]
              )}`}
              sectionLinks={section}
              key={R.keys(props.footerLinks)[index]}
            />
          ),
          R.values(props.footerLinks)
        )}
      </Flex>

      <SocialMedia />
    </Flex>
  )
}

Sitemap.propTypes = {
  casinoLinks: PropTypes.array,
  footerLinks: PropTypes.object.isRequired,
}

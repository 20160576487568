export const displayAvatars = {
  casinoheroes: [
    'indy',
    'knight',
    'witch',
    'robin',
    'yoda',
    'gandalf',
    'khaleesi',
  ],
  casitabi: [
    'hanasaka',
    'kaguyahime',
    'manekineko',
    'momotaro',
    'samurai',
    'shishimai',
    'maiko',
  ],
}

export function worldsData(translate) {
  return ['treasure-island', 'rock-of-riches', 'mythical-mountain'].map(key => {
    return {
      image: translate(`home-page.${key}.icon`),
      title: translate(`homepage.featured-islands.${key}.title`),
      content: translate(`worlds.${key}.description`),
    }
  })
}

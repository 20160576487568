import { connect } from 'react-redux'

import { isCasitabi } from '../../../store/app'

import { Logo as Component } from './logo'

function mapStateToProps(state) {
  return {
    isCasitabi: isCasitabi(state),
  }
}

export const Logo = connect(mapStateToProps)(Component)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { Flex } from '@rushplay/common'

import InputField from './input-field'

const Wrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const mapIndexed = R.addIndex(R.map)

class DigitsField extends Component {
  constructor(props) {
    super(props)

    this.handleChangeValue = this.handleChangeValue.bind(this)
    // focus first InputField by default
    this.state = { focused: 0 }
  }

  /*
   * TODO: should be handled by initialValue of Field component.
   * @rushplay/forms has to trigger validation on init even when value is equal to initialValue
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.value.length !== nextProps.count) {
      this.props.onChange(R.join('', R.repeat(' ', nextProps.count)))
    }
  }

  handleChangeValue(index, value) {
    const code = R.pipe(R.split(''), R.update(index, value.slice(0, 1) || ' '))(
      this.props.value
    )

    this.props.onChange(R.join('', code))
    // select next empty digit input
    this.setState({ focused: R.indexOf(' ', code) })
  }

  render() {
    return (
      <Flex center>
        {mapIndexed(
          (digit, index) => (
            <Wrapper key={index}>
              <InputField
                centered
                focused={R.equals(this.state.focused, index)}
                name={`digit${index}`}
                small
                status={R.trim(digit) ? 'valid' : undefined}
                type="text"
                value={R.trim(digit)}
                onChange={e =>
                  this.handleChangeValue(index, e.currentTarget.value)}
                onFocus={() => this.setState({ focused: index })}
                onBlur={() => this.setState({ focused: -1 })}
              />
            </Wrapper>
          ),
          R.split('', this.props.value)
        )}
      </Flex>
    )
  }
}

export default DigitsField

DigitsField.propTypes = {
  count: PropTypes.number,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

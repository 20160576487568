import * as R from 'ramda'
import url from 'url'
import {
  STORAGE_TYPE,
  getItem,
  removeItem,
  setItem,
} from 'redux-effects-localstorage'
import { bind } from 'redux-effects'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getUrl } from 'redux-effects-location'
import { push as redirectTo } from 'react-router-redux'

import * as Analytics from '@rushplay/analytics'
import * as Notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import {
  fetchCountries,
  fetchCountryCode,
  fetchIpInfo,
  fetchSession,
} from '@rushplay/api-client'
import { getBtag } from '@rushplay/common'
import {
  getSessionToken,
  isSessionActive,
  isSessionUpdated,
  session,
  updateAffiliateClickId,
  updateNetEntSessionId,
} from '@rushplay/session'

import * as lookup from '../../store/lookup'
import nextTimeToRealityCheck from '../../util/next-time-to-reality-check'
import pathWithoutLanguage from '../../util/path-without-language'
import { btagLocalStorageKey } from '../../constants/config'
import {
  changeLanguage,
  fetchMetadata,
  getBrand,
  getBrandCurrency,
  getConfig,
  getEnvironment,
  getIsIpInternal,
  getLanguage,
  getLanguageByLocation,
  getMetadataTitle,
  getSupportedLanguage,
  restoreSession,
  storeAvatarUrl,
  storeLoggedInOnce,
  storeReferrals,
  storeUsername,
  storeUtmCampaign,
  storeUtmMedium,
  storeUtmSource,
} from '../../store/app'
import {
  isCurrentTranslationPresent,
  requestTranslations,
} from '../../store/translations'
import { timestamp } from '../../redux-effects-timestamp'
import {
  updateCookieConsent,
  updateTimeToRealityCheck,
} from '../../store/player'

import { AppPreloader as Component } from './app-preloader'

function restoreRealityCheck() {
  return bind(
    getItem('realityCheckPeriod', STORAGE_TYPE.session),
    (realityCheckPeriod) =>
      bind(
        getItem('timeToRealityCheck', STORAGE_TYPE.session),
        (realityCheckRemainder) =>
          bind(
            getItem('lastLoggedRealityCheckTimestamp', STORAGE_TYPE.session),
            (realityCheckTimestamp) =>
              bind(timestamp(), (currentTime) => {
                if (realityCheckPeriod && realityCheckTimestamp) {
                  const timeToRealityCheck = nextTimeToRealityCheck(
                    currentTime,
                    realityCheckTimestamp,
                    realityCheckRemainder,
                    realityCheckPeriod
                  )
                  return [
                    updateTimeToRealityCheck(timeToRealityCheck),
                    setItem(
                      'timeToRealityCheck',
                      timeToRealityCheck,
                      STORAGE_TYPE.session
                    ),
                    removeItem(
                      'lastLoggedRealityCheckTimestamp',
                      STORAGE_TYPE.session
                    ),
                  ]
                }
              })
          )
      )
  )
}

function mapStateToProps(state) {
  return {
    brand: getBrand(state.app),
    brandCurrency: getBrandCurrency(state),
    cdnHost: getConfig(state.app).cdnHost,
    cdnPrefix: getConfig(state.app).cdnPrefix,
    environment: getEnvironment(state.app),
    getSupportedLanguage: (language) => getSupportedLanguage(state, language),
    isAuthenticated: isSessionActive(state.session),
    isConfigFetched: !R.isEmpty(getConfig(state.app)),
    isCountryCodeDetected: Boolean(lookup.getCountryCode(state.lookup)),
    isIpInternal: getIsIpInternal(state.app),
    isMetadataLoaded: !R.isNil(getMetadataTitle(state.app)),
    isSessionUpdated: isSessionUpdated(state.session),
    language: getLanguage(state.app),
    locationLanguage: getLanguageByLocation(state.app),
    isCurrentTranslationPresent: isCurrentTranslationPresent(
      state.translations,
      getLanguage(state.app)
    ),
    metadataTitle: getMetadataTitle(state.app),
    sessionToken: getSessionToken(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeLanguage,
      fetchCountryCode,
      fetchCountries,
      fetchIpInfo,
      getItem,
      redirectTo: R.pipe(
        R.over(R.lensProp('pathname'), R.replace(/\/+/g, '/')),
        redirectTo
      ),
      requestTranslations,
      onSessionRestore: () => [
        restoreSession(),
        bind(getItem('COOKIE_CONSENT'), (cookieConsent) =>
          updateCookieConsent(cookieConsent)
        ),
      ],
      setItem,
      storeAvatarUrl,
      storeUtmCampaign,
      storeUtmMedium,
      storeUtmSource,
      storeReferrals,
      updateSession: session.update,
      storeUsername,
      storeLoggedInOnce,
      updateNetEntSessionId,
      updateTimeToRealityCheck,
      onBrandCurrencyUpdate: (authenticated, currency) => [
        !authenticated && session.update({ player: { currency } }),
      ],
      onJurisdictionFetch: jurisdiction.fetch,
      onFetchMetadata: () =>
        fetchMetadata(pathWithoutLanguage(window.location.pathname)),
      onMount: () => {
        const nextBtag = getBtag(document.referrer, window.location.href)
        const queryParams = url.parse(window.location.href, true, true).query
        const regexClickId = /^click(id|-id|_id)/i
        const clickId = Object.keys(queryParams).find((prop) =>
          regexClickId.test(prop)
        )

        const regexSubId = /^sub(id|-id|_id)/i
        const subId = Object.keys(queryParams).find((prop) =>
          regexSubId.test(prop)
        )

        return [
          queryParams.utm_source && storeUtmSource(queryParams.utm_source),
          queryParams.utm_medium && storeUtmMedium(queryParams.utm_medium),
          queryParams.utm_campaign &&
            storeUtmCampaign(queryParams.utm_campaign),
          nextBtag && bind(setItem(btagLocalStorageKey, nextBtag)),
          bind(getUrl(), (location) => {
            const { query } = url.parse(location, true, true)
            return updateAffiliateClickId(query[clickId])
          }),
          bind(getUrl(), (location) => {
            const { query } = url.parse(location, true, true)
            return Analytics.updateSubId(query[subId])
          }),
        ]
      },
      onRealityCheckRestore: restoreRealityCheck,
      onSessionCheck: (token) =>
        fetchSession({
          token,
          failure: () => [
            redirectTo('/logout'),
            Notifications.add({ message: 'session-expired', level: 'warning' }),
          ],
          version: 2,
        }),
    },
    dispatch
  )
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return R.mergeAll([
    ownProps,
    stateProps,
    dispatchProps,
    {
      onBrandCurrencyUpdate: () =>
        dispatchProps.onBrandCurrencyUpdate(
          stateProps.isAuthenticated,
          stateProps.brandCurrency
        ),
    },
  ])
}

export const AppPreloader = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component)

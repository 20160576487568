import PropTypes from 'prop-types'
import React from 'react'

import { Space } from '@rushplay/common'

import PopupContainer from '../common/popup/popup-container'

import { SetLimitsConfirmation } from './set-limits-confirmation'

export function HighLimitPopup(props) {
  return (
    <PopupContainer id="high-limit-info" isOpen={props.isOpen}>
      <Space all="1px" align="center">
        <SetLimitsConfirmation
          info={props.info}
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
          limitValue={props.limitValue}
        />
      </Space>
    </PopupContainer>
  )
}

HighLimitPopup.propTypes = {
  info: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  limitValue: PropTypes.string,
  parentSelector: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

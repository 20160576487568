import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as locks from '@rushplay/compliance/locks'
import { withTranslate } from '@rushplay/i18n'

import { FreeText as Component } from './free-text'

function mapStateToProps(state) {
  const message = R.head(R.defaultTo([], locks.getMessages(state.locks)))

  return {
    message: R.pathOr('', ['message'], message),
    title: R.pathOr('', ['name'], message),
    id: R.pathOr('', ['id'], message),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onAccept: id =>
      dispatch([
        locks.dissmissMessage(id),
        api.dismissFreeTextPopup(id, { version: 1 }),
      ]),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    onAccept: () => dispatchProps.onAccept(stateProps.id),
    message: stateProps.message,
    title: stateProps.title,
  }
}
const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslate
)

export const FreeText = connector(Component)

import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { Timeout } from '@rushplay/compliance/exclusion'
import { session } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import NotFound from '../components/not-found'

import PlayerLockForm from './player-lock-form'

function TimeOutContainer(props) {
  return (
    <Timeout
      values={props.values}
      onSuccess={props.onSuccess}
      onFailure={props.onFailure}
    >
      {renderProps => (
        <PlayerLockForm
          crossbrand={props.crossbrand}
          initialValue={R.path(['options', 0, 'value'], renderProps)}
          name="time-out-period"
          options={R.map(
            option => ({
              label: props.translate(option.labelKey),
              value: option.value,
            }),
            renderProps.options
          )}
          popupVisible={props.popupVisible}
          onPopupClose={props.onPopupClose}
          onSubmit={(period, password) =>
            renderProps.onSubmit(period, password)}
        />
      )}
    </Timeout>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    crossbrand: jurisdiction.isTimeOutCrossbrand(state.jurisdiction),
    values: jurisdiction.getTimeOutValues(state.jurisdiction),
    popupVisible: ownProps.location.query.confirm === 'true',
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onFailure: () =>
        Notifications.add({ message: 'error.generic', level: 'error' }),
      onPopupClose: () => push(props.location.pathname),
      onSuccess: () => [session.clear(), push('/')],
    },
    dispatch
  )
}

TimeOutContainer.propTypes = {
  crossbrand: PropTypes.bool,
  location: PropTypes.object.isRequired,
  values: PropTypes.array,
  popupVisible: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

const connector = R.compose(
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps),
  recompose.branch(
    props => R.isEmpty(props.values),
    recompose.renderComponent(NotFound)
  )
)

export default connector(TimeOutContainer)

/**
 * Round number to specified precision
 * @param {number} precision The number of decimal digits to round to.
 * @param {number} float The value to round
 * @returns {number} The rounded value
 * @example
 * toFixed(2, Math.PI) // => 3.14
 */
export function toFixed(precision, float) {
  const divisor = Math.pow(10, precision)
  return Math.round(float * divisor) / divisor
}

import { combineReducers } from 'redux'

import * as selectors from './selectors'
import regions from './regions'
import treasures from './treasures'
import worlds from './worlds'

const reducer = combineReducers({
  worlds: worlds.reducer,
  treasures: treasures.reducer,
  regions: regions.reducer,
})

export default { selectors, reducer }

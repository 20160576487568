import React from 'react'

export function IconCheck() {
  return (
    <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.797 19.727a2.04 2.04 0 0 1-.586 1.464L23.535 38.867c-.39.39-.944.619-1.497.619a2.13 2.13 0 0 1-1.465-.619L8.789 27.083a2.04 2.04 0 0 1-.586-1.465c0-.553.195-1.106.586-1.497l2.962-2.93a2.07 2.07 0 0 1 1.465-.618 2.07 2.07 0 0 1 1.465.618l7.357 7.357L35.319 15.3a2.07 2.07 0 0 1 1.465-.618 2.07 2.07 0 0 1 1.465.618l2.962 2.93c.39.39.586.944.586 1.498zM50 25C50 11.198 38.802 0 25 0S0 11.198 0 25s11.198 25 25 25 25-11.198 25-25z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { withProps } from 'recompose'

import styled from '@emotion/styled'
import { Constraint, Flex, Link, Space } from '@rushplay/common'

import Button from '../../common/button'
import PopupContainer from '../../common/popup/popup-container'
import Text from '../../common/text'
import { stone } from '../../constants/colors'

const ContentWrapper = styled.div`
  padding-bottom: ${props => (props.locked ? `68%` : `80%`)};
  background-repeat: no-repeat;
  background-image: url(${props =>
    props.locked
      ? `/images/inventory/inventory-locked.png`
      : `/images/inventory/inventory-unlocked.png`});
  background-position: bottom;
  background-size: 100%;
  border-bottom-left-radius: 1.3em;
  border-bottom-right-radius: 1.3em;
  margin-top: -1.7em;
`

const TextContainer = styled.div`
  display: inline-block;
  width: 70%;
  vertical-align: middle;
`

const TreasureChest = styled.div`
  background-image: url(${props =>
    props.locked
      ? `/images/inventory/chest-closed.png`
      : `/images/inventory/chest-open.png`});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 64px;
  height: 61px;
`

const Bubble = styled.div`
  border: 19px dotted #c905c6;
  border-image-source: url(/images/inventory/speech-bubble.png);
  border-image-slice: 19 19 61 19 fill;
  border-image-width: 29px;
  padding-bottom: 50px;
  max-width: 360px;
  min-width: 320px;
  box-sizing: border-box;
`

const BubbleContent = styled.div`
  padding-top: 30px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const LockPopup = styled(withProps({ headerPadding: true })(PopupContainer))`
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
`

export function InventoryLockPopup(props) {
  return (
    <LockPopup
      id="inventory-lock-popup"
      isOpen
      onRequestClose={!props.locked && props.onDismiss}
      title={props.translate(
        props.locked ? 'inventory.locked-title' : 'inventory.unlocked-title'
      )}
    >
      <ContentWrapper locked={props.locked}>
        <Flex justifyContent="center">
          <Bubble>
            <BubbleContent>
              <Flex>
                <Space right="15px">
                  <TreasureChest locked={props.locked} />
                </Space>
                <TextContainer>
                  <Text size="18" color={stone} align="left">
                    {props.translate(
                      props.locked ? 'inventory.locked' : 'inventory.unlocked'
                    )}
                  </Text>
                </TextContainer>
              </Flex>
              {props.locked && (
                <Space top="10px">
                  <Constraint width="200" centered>
                    <Link to="/wallet/deposit">
                      <Button stretch>
                        {props.translate('inventory.deposit-now')}
                      </Button>
                    </Link>
                  </Constraint>
                </Space>
              )}
            </BubbleContent>
          </Bubble>
        </Flex>
      </ContentWrapper>
    </LockPopup>
  )
}

InventoryLockPopup.propTypes = {
  translate: PropTypes.func,
  locked: PropTypes.bool,
  onDismiss: PropTypes.func,
}

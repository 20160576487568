import * as actions from './actions'
import * as types from './types'

const initialState = {
  registeredPlayers: 0,
  biggestSettlement: {},
  games: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_STATISTICS:
      return Object.assign({}, state, action.payload)

    case types.UPDATE_GAMES:
      return Object.assign({}, state, { games: action.payload })

    default:
      return state
  }
}

export default {
  actions,
  reducer,
  types,
}

import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as navigateTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import { connectForm, selectors } from '@rushplay/legacy-forms'
import { updateAvatarUrl, updateEmail } from '@rushplay/session'
import { updateMe as updateMeApi } from '@rushplay/api-client'

import {
  fetchAvatars,
  getAvatars,
  getPrivacyPolicyVisible,
  getTermsConditionsVisible,
  updateLogInData,
} from '../../store/sign-up'

function mapStateToProps(state, ownProps) {
  return {
    avatars: getAvatars(state.signUp),
    isStepValid: selectors.isAllFieldsHaveStatus(state.forms, {
      form: 'log-in-details',
      status: 'valid',
    }),
    userData: R.pluck(
      'value',
      selectors.fields(state.forms, { form: ownProps.form })
    ),
    steps: ['success', 'login-details', 'select-avatar'],
    termsConditionsVisible: getTermsConditionsVisible(state.signUp),
    privacyPolicyVisible: getPrivacyPolicyVisible(state.signUp),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDidMount: props => {
      dispatch(fetchAvatars())
      const firstStep = R.head(props.steps)
      if (props.params.step !== firstStep) {
        dispatch(navigateTo(`/register/log-in-info/${firstStep}`))
      }
    },
    actions: bindActionCreators(
      {
        onPlayerUpdate: R.pipe(
          payload =>
            R.mergeRight(payload, {
              passwordConfirmation: payload.password,
              allowEmail: !payload.allowMarketing,
              allowSms: !payload.allowMarketing,
            }),
          payload =>
            updateMeApi(payload, {
              success: res => [
                updateEmail(res.value.email),
                updateAvatarUrl(res.value.avatarImageUrl),
                navigateTo('/casino'),
              ],
              failure: () =>
                Notifications.add({ message: 'error.generic', level: 'error' }),
              version: 1,
            })
        ),
        updateLogInData,
        navigateTo,
      },
      dispatch
    ),
  }
}

export default R.compose(
  component => connectForm(component, { name: 'log-in-details' }),
  connect(mapStateToProps, mapDispatchToProps)
)

import * as ReactRedux from 'react-redux'

import * as App from '../../../store/app'

import { VideoArea as Component } from './video-area'

function mapStateToProps(state) {
  return {
    isReturningUser: Boolean(App.getUsername(state.app)),
    assetPath: App.getAssetPath(state.app),
  }
}

export const VideoArea = ReactRedux.connect(mapStateToProps)(Component)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { Constraint, Flex, Space } from '@rushplay/common'
import {
  Field,
  FormPropTypes,
  actions as formActions,
  connectForm,
  selectors,
} from '@rushplay/legacy-forms'
import { changePassword } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Card from '../../common/card'
import Heading from '../../common/heading'
import InputField from '../../common/input-field'
import fieldError from '../../field-error'
import validators from '../../validators'
import { waitDashboard } from '../../store/app'

const Row = styled.div`
  margin-bottom: 10px;
`

const Subtitle = styled.p`
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 29px;
`

class EditPassword extends Component {
  constructor() {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillUnmount() {
    this.props.clearForm(this.props.form)
  }

  handleSubmit() {
    if (
      this.props.email
        .toLowerCase()
        .includes(this.props.fields.newPassword.value.toLowerCase())
    ) {
      return this.props.onPasswordInvalid('error.password-matching-email')
    }

    this.props.waitDashboard(true)

    const actions = [
      waitDashboard(false),
      this.props.clearForm(this.props.form),
      redirectTo('/dashboard'),
    ]

    this.props.changePassword(
      this.props.fields.oldPassword.value,
      this.props.fields.newPassword.value,
      this.props.fields.newPasswordConfirmation.value,
      {
        success: () => actions,
        failure: (error) =>
          R.append(
            Notifications.add({ message: error.value, level: 'error' }),
            actions
          ),
        version: 2,
      }
    )
  }

  render() {
    return (
      <Constraint width="500" centered>
        <Space top={8} x="10px">
          <Card onRequestClose={() => this.props.redirectTo('/dashboard')}>
            <Space all="20px">
              <Flex flexDirection="column" alignItems="center">
                <Heading level="2" align="center">
                  {this.props.translate('user-dashboard.password')}
                </Heading>

                <Subtitle>
                  {this.props.translate('edit-password.subtitle')}
                </Subtitle>
              </Flex>
              <Row>
                <Field
                  autoFocus
                  component={InputField}
                  label={this.props.translate(
                    'edit-password.old-password.label'
                  )}
                  name="oldPassword"
                  tooltip={
                    fieldError(this.props.fields.oldPassword) &&
                    this.props.translate(
                      fieldError(this.props.fields.oldPassword)
                    )
                  }
                  type="password"
                  validator={[validators.required]}
                />
              </Row>
              <Row>
                <Field
                  component={InputField}
                  label={this.props.translate(
                    'edit-password.new-password.label'
                  )}
                  name="newPassword"
                  tooltip={
                    fieldError(this.props.fields.newPassword) &&
                    this.props.translate(
                      fieldError(this.props.fields.newPassword)
                    )
                  }
                  type="password"
                  validator={[validators.required, validators.password]}
                />
              </Row>
              <Row>
                <Field
                  component={InputField}
                  label={this.props.translate(
                    'edit-password.new-password-confirmation.label'
                  )}
                  name="newPasswordConfirmation"
                  tooltip={
                    fieldError(this.props.fields.newPasswordConfirmation) &&
                    this.props.translate(
                      fieldError(this.props.fields.newPasswordConfirmation)
                    )
                  }
                  type="password"
                />
              </Row>
              <Space y="10px">
                <Flex justifyContent="center">
                  <Button
                    minimalPadding
                    disabled={!this.props.isFormValid}
                    loading={this.props.isLoading}
                    onClick={this.handleSubmit}
                  >
                    {this.props.translate('edit-password.button')}
                  </Button>
                </Flex>
              </Space>
            </Space>
          </Card>
        </Space>
      </Constraint>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    email: state.player.player.email,
    isLoading: state.app.waitDashboard,
    isFormValid: selectors.isAllFieldsHaveStatus(state.forms, {
      form: ownProps.form,
      status: 'valid',
    }),
  }
}

export default withTranslate(
  connectForm(
    connect(mapStateToProps, {
      onPasswordInvalid: (error) => {
        return Notifications.add({ message: error, level: 'error' })
      },
      changePassword,
      clearForm: formActions.clearForm,
      redirectTo,
      waitDashboard,
    })(EditPassword),
    {
      name: 'edit-password',
      validator: (form, values) => {
        if (values.newPassword !== values.newPasswordConfirmation) {
          return formActions.validate(form, 'newPasswordConfirmation', {
            status: false,
            errors: ['edit-password.newPasswordConfirmation.errors.not-same'],
          })
        } else if (values.newPassword === values.newPasswordConfirmation) {
          return formActions.validate(form, 'newPasswordConfirmation', {
            status: true,
            errors: [],
          })
        }
      },
    }
  )
)

EditPassword.propTypes = {
  email: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    oldPassword: FormPropTypes.field,
    newPassword: FormPropTypes.field,
    newPasswordConfirmation: FormPropTypes.field,
  }).isRequired,
  form: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isFormValid: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func,
  translate: PropTypes.func.isRequired,
  waitDashboard: PropTypes.func,
  onPasswordInvalid: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import { withTranslate } from '@rushplay/i18n'

import Icon from '../../common/icon/icon'

import { Content } from './content'
import { Message } from './message'
import { Title } from './title'

function CreateNewPasswordConfirmation(props) {
  return (
    <Content>
      <Icon name="check-green" style={{ fontSize: '80px' }} />
      <Title>
        {props.translate('create-new-password-confirmation.password-updated')}
      </Title>
      <Message>
        <p>
          {props.translate(
            'create-new-password-confirmation.new-password-activated'
          )}
        </p>
      </Message>
    </Content>
  )
}

CreateNewPasswordConfirmation.propTypes = { translate: PropTypes.func }

export default withTranslate(CreateNewPasswordConfirmation)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'emotion-theming'

import * as t from './utils'

const BREAKPOINTS = ['base', 'sm', 'md', 'lg']

function mergeThemes(props) {
  const base = props.theme || {}
  const sm = R.mergeDeepRight(base, props.overrides.sm || {})
  const md = R.mergeDeepRight(sm, props.overrides.md || {})
  const lg = R.mergeDeepRight(md, props.overrides.lg || {})
  return { base, sm, md, lg }
}

function getDerivedStateFromProps(props) {
  return mergeThemes(props)
}

function getMediaQuery(name, props) {
  return `screen and (min-width: ${t.breakpoint(name)(props)})`
}

function getMediaQueries(props) {
  return R.map(name => getMediaQuery(name, props), ['sm', 'md', 'lg'])
}

/**
 * Provides relevant theme through React Context API based on media query.
 * @param {Object} props
 * @param {Object} props.children
 * @param {Object} props.overrides
 * @param {Object} props.theme
 * @return {ReactComponent}
 * @example
 * import { ResponsiveThemeProvider } from '@rushplay/common'
 *
 * const theme = {
 *   paddings: ['8px', '16px'],
 *   fonts: {
 *     body: 'Open Sans',
 *     sizes: ['12px', '18px', '30px']
 *   },
 * }
 *
 * const overrides = {
 *   lg: {
 *     fonts: {
 *       sizes: ['14px', '22px', '38px'],
 *     },
 *   },
 * }
 *
 * function Root({ store }) {
 *   return (
 *     <Provider store={store}>
 *       </React.Fragment>
 *         <ResponsiveThemeProvider
 *           theme={theme}
 *           overrides={overrides}>
 *           <Router>
 *             {routes(store)}
 *           </Router>
 *         </ResponsiveThemeProvider>
 *       </React.Fragment>
 *     </Provider>
 *   )
 * }
 */
export class ResponsiveThemeProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = getDerivedStateFromProps(props)

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const mediaQueries = getMediaQueries(this.props)

    if (window.matchMedia) {
      this.mediaQueries = R.map(query => window.matchMedia(query), mediaQueries)

      R.forEach(
        query => query.addListener(this.handleChange),
        this.mediaQueries
      )

      this.handleChange()
    }
  }

  componentWillUnmount() {
    R.forEach(
      query => query.removeListener(this.handleChange),
      this.mediaQueries
    )
  }

  handleChange() {
    const index = R.findLastIndex(query => query.matches, this.mediaQueries)

    this.setState({ breakpoint: BREAKPOINTS[index] })
  }

  render() {
    const theme = this.state[this.state.breakpoint || 'base']
    return <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
  }
}

ResponsiveThemeProvider.propTypes = {
  children: PropTypes.node,
  overrides: PropTypes.shape({
    sm: PropTypes.object,
    md: PropTypes.object,
    lg: PropTypes.object,
  }),
  theme: PropTypes.object.isRequired,
}

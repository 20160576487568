import * as R from 'ramda'

import * as Analytics from '@rushplay/analytics'
import * as Session from '@rushplay/session'

import * as App from '../store/app'
import * as Player from '../store/player'
import * as CombinedSelectors from '../combined-selectors'

const BRANDS = {
  casinoheroes: 1,
  casitabi: 2,
  test: 3,
}

function getNetreferBrand(state) {
  if (App.isProduction(state.app)) {
    return R.prop(App.getBrand(state.app), BRANDS)
  } else {
    return R.prop('test', BRANDS)
  }
}

function isPresent(values) {
  return R.none(R.isNil, values)
}

const NO_SEARCH_RESULTS = 'analytics/NO_SEARCH_RESULTS'

export function noSearchResults(payload) {
  return Analytics.queueEvent({
    type: NO_SEARCH_RESULTS,
    payload,
  })
}

export const netreferEvents = {
  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    if (Player.getDepositCount(nextState.player) === 1) {
      return {
        brand: getNetreferBrand(nextState),
        actionName: 'FirstTimeDeposit',
        btag: Session.getBtag(nextState.session),
        customFields: {
          clickID: Session.getAffiliateClickId(nextState.session),
          subID: CombinedSelectors.getAffiliateSubId(nextState),
          customerID: Session.getPlayerId(nextState.session),
        },
        method: 'both',
      }
    }

    return {}
  },

  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    return {
      brand: getNetreferBrand(nextState),
      actionName: 'SignUp',
      btag: Session.getBtag(nextState.session),
      customFields: {
        clickID: Session.getAffiliateClickId(nextState.session),
        subID: CombinedSelectors.getAffiliateSubId(nextState),
        customerID: Session.getPlayerId(nextState.session),
      },
      method: 'both',
    }
  },
}

export const gtmEvents = {
  [Analytics.AUTHENTICATED]: (action, prevState, nextState) => {
    const uid = Session.getUsername(nextState.session)
    const affiliateid = Session.getAffiliateId(nextState.session)

    if (!isPresent([uid])) {
      return null
    }

    return {
      event: 'login',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [Analytics.DEPOSIT_AMOUNT_SELECTED]: (action, prevState, nextState) => {
    const uid = Session.getUsername(nextState.session)
    const affiliateid = Session.getAffiliateId(nextState.session)
    const value = action.payload

    if (R.not(isPresent([uid, value]))) {
      return null
    }

    return {
      event: 'amountSelected',
      deposit: {
        value,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.DEPOSIT_PAYMENT_METHOD_SELECTED]: (
    action,
    prevState,
    nextState
  ) => {
    const uid = Session.getUsername(nextState.session)
    const affiliateid = Session.getAffiliateId(nextState.session)
    const method = action.payload

    if (R.not(isPresent([uid, method]))) {
      return null
    }

    return {
      event: 'paymentMethodSelected',
      deposit: {
        method,
      },
      user: {
        affiliateid,
        uid,
      },
    }
  },

  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const uid = Session.getUsername(nextState.session)
    const affiliateid = Session.getAffiliateId(nextState.session)
    const depositAttempts = Player.getDepositAttempts(nextState.player)
    const valueCents = Player.getLastDepositCents(nextState.player)
    const method = action.payload.depositMethod
    const status = action.payload.transactionStatus

    if (R.not(isPresent([uid, depositAttempts, valueCents, method, status]))) {
      return null
    }

    return {
      event: 'transactionCompleted',
      deposit: {
        count: depositAttempts,
        value: status === 'successful' ? valueCents / 100 : null,
        method,
        status,
      },
      user: {
        affiliateid,
      },
    }
  },

  [Analytics.PAGE_VIEWED]: (action) => ({
    event: 'pageview',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const uid = Session.getUsername(nextState.session)
    const affiliateid = Session.getAffiliateId(nextState.session)

    if (!isPresent([uid])) {
      return null
    }

    return {
      event: 'nrc',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [Analytics.BONUS_CLAIMED]: (action, prevState, nextState) => {
    const currency = Session.getCurrency(nextState.session)

    if (!isPresent([currency])) {
      return null
    }

    return {
      event: 'bonus',
      bonus: {
        value: R.divide(action.payload.value, 100),
        currency: Session.getCurrency(nextState.session),
        claimed: action.payload.claimed ? 'yes' : 'no',
      },
    }
  },
  [NO_SEARCH_RESULTS]: (action) => ({
    event: 'no-search-results',
    searchedGame: action.payload.searchedGame,
  }),
}

export const mapsEvents = {
  [Analytics.DEPOSIT_TRANSACTION_COMPLETED]: (action, prevState, nextState) => {
    const btag = Session.getBtag(nextState.player)
    const valueCents = Player.getLastDepositAmount(nextState.player)
    const playerId = Player.getMapsPlayerId(nextState.player)
    const transactionStatus = action?.payload?.transactionStatus

    if (transactionStatus !== 'successful') {
      return {}
    }

    if (!isPresent([valueCents, playerId])) {
      return null
    }

    return {
      actionName: 'deposit',
      btag,
      playerId,
      deposit: {
        value: valueCents / 100,
      },
    }
  },
  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const btag = Session.getBtag(nextState.player)
    const playerId = Player.getMapsPlayerId(nextState.player)
    if (!isPresent([playerId])) {
      return null
    }

    return {
      actionName: 'registration',
      btag,
      playerId,
    }
  },
}

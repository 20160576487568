import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import * as I18n from '@rushplay/i18n'

import pathWithoutLanguage from '../util/path-without-language'

export function Metadata(props) {
  const i18n = I18n.useI18n()

  React.useEffect(
    () => {
      props.onFetch(pathWithoutLanguage(props.location.pathname))
    },
    [props.location.pathname, props.onFetch, pathWithoutLanguage]
  )

  // If a key has been set for the url
  if (props.metaTitle) {
    return (
      <Helmet>
        <title>{i18n.translate(props.metaTitle)}</title>
        <meta content={i18n.translate(props.metaTitle)} name="title" />
        {props.metaDescription && (
          <meta
            content={i18n.translate(props.metaDescription)}
            name="description"
          />
        )}
      </Helmet>
    )
    // If no key was found but it's a gameplay page
  } else if (!R.isEmpty(props.game) && !R.isNil(props.game)) {
    return (
      <Helmet>
        <title>{`${props.game.displayProvider || props.game.provider} - ${props
          .game.title}`}</title>
        <meta
          content={`${props.game.displayProvider ||
            props.game.provider} - ${props.game.title}`}
          name="title"
        />
      </Helmet>
    )
    // If none of the above, fallback
  } else {
    return (
      <Helmet>
        <title>{i18n.translate('meta.title')}</title>
        <meta content={i18n.translate('meta.title')} name="title" />
      </Helmet>
    )
  }
}

Metadata.propTypes = {
  game: PropTypes.object,
  location: PropTypes.object.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  onFetch: PropTypes.func.isRequired,
}

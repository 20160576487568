import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import memoize from 'fast-memoize'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import inventory from '@rushplay/inventory'
import styled from '@emotion/styled'
import { Link, Space, toLocaleDate } from '@rushplay/common'
import {
  claimGift,
  deleteGift,
  deleteNotification as deleteNotificationApi,
  deleteTreasure,
  fetchInventory,
  finishWorld,
  updateRegionProgress,
} from '@rushplay/api-client'
import { getUserAvatar } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as processIds from '../../constants/processes'
import Button from '../../common/button'
import OnboardingPopper from '../onboarding/onboarding-popper'
import PopupContainer from '../../common/popup/popup-container'
import SizeObserver from '../../size-observer'
import adventure from '../../store/adventure'
import triggers from '../../store/triggers/'
import worlds from '../../store/adventure/worlds'
import {
  PopupDescription,
  PopupImage,
  PopupImageShadow,
  PopupScrollable,
  PopupTitle,
  PopupWelcomeImage,
  PopupWelcomeRegionName,
  PopupWelcomeTo,
} from '../../common/popup/popup-styles.js'
import {
  clearGift,
  getActiveBoosterItem,
  getGiftAmount,
  getGiftCasinoWagerFactor,
  getGiftSportWagerFactor,
} from '../../store/player'
import {
  getActiveBoosterCampaign,
  getActiveRaceCampaign,
  isBeatBossesCampaignActive,
} from '../../store/campaigns'
import {
  getAssetPath,
  getLanguage,
  isSportsbookEnabled,
  isWaiting,
  startWaiting,
  stopWaiting,
} from '../../store/app'
import { removePopup } from '../../common/popup/popups'

import CampaignButton from './campaign-button'
import CollectTreasureImage from './collect-treasure-image'
import MapRegions from './map-regions'
import PreBossfightImage from './pre-bossfight-image'
import QuickGames from './quick-games'
import { getRandomNumberFromOneTo } from './helpers'

export const WELCOME_GIFT_CLAIMED = 'casino-heroes/welcomeOffers/GIFT_CLAIMED'

export function giftClaimed(payload) {
  return {
    type: WELCOME_GIFT_CLAIMED,
    payload,
  }
}

/* This is for mobile landscapes */
const mobileLandscape =
  'only screen and (min-width: 320px) and (max-width: 823px) and (orientation: landscape)'

export const MapWrapper = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Image = styled.img`width: 16vmin;`

export const CasinoImage = styled(Image)`
  min-width: 63px;
  max-width: 130px;
`

export const WorldsImage = styled(Image)`
  min-width: 63px;
  max-width: 130px;
`

export const SportsImage = styled(Image)`
  min-width: 63px;
  max-width: 130px;
`

export const HowToPlayImage = styled(Image)`
  min-width: 57px;
  max-width: 109px;
`

export const GamesWrapper = styled.aside`
  position: absolute;
  top: 5em;
  right: 0.875em;
  @media ${mobileLandscape} {
    top: 0;
  }
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 0;
    right: 2.5em;
    height: 100%;
  }
`

export const WorldsWrapper = styled.aside`
  position: absolute;
  bottom: 12em;
  left: 0.875em;
  @media screen and (min-width: 400px) {
    bottom: 13em;
  }
  @media screen and (min-width: 500px) {
    bottom: 15em;
  }
  @media screen and (min-width: 650px) {
    bottom: 16em;
  }
  @media screen and (min-width: 769px) {
    bottom: 14em;
  }
  @media ${mobileLandscape} {
    bottom: 5em;
  }
  @media screen and (min-width: 1280px) {
    left: 2.5em;
  }
`
export const HowToPlayWrapper = styled.aside`
  display: block;
  position: absolute;
  bottom: 6em;
  left: 0.875em;
  @media ${mobileLandscape} {
    bottom: 0em;
  }
  @media screen and (min-width: 1280px) {
    left: 2.5em;
  }
`

export const SportsWrapper = styled.aside`
  display: block;
  position: absolute;
  top: 5em;
  left: 0.875em;
  @media ${mobileLandscape} {
    top: 0;
  }
  @media screen and (min-width: 1280px) {
    left: 2.5em;
  }
`

const CampaignButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Wrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
`

function isFutureDate(availableFrom) {
  return availableFrom - Date.now() > 0
}

function heroSpinType(type) {
  // TODO: should be undefined in API response
  return type || undefined
}

class RegionContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pushedBackRandomTranslationNumber: 0,
      hiddenTreasureRandomTranslationNumber: 0,
      preBossFightRandomTranslationNumber: 0,
    }

    this.handleTreasureClick = this.handleTreasureClick.bind(this)
    this.handleTreasurePopupClose = this.handleTreasurePopupClose.bind(this)
    this.handleTreasureCollectPopupClose = this.handleTreasureCollectPopupClose.bind(
      this
    )
    this.handlePushedBackPopupClose = this.handlePushedBackPopupClose.bind(this)

    this.handleWorldEnd = this.handleWorldEnd.bind(this)
    this.handleDismissGift = this.handleDismissGift.bind(this)
    this.handleClaimGift = this.handleClaimGift.bind(this)
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize)
    this.props.updatePosition(
      this.props.currentWorldKey,
      this.props.startPosition
    )
    // forceUpdate is needed to trigger other lyfecycle methods when component mounts
    this.forceUpdate()
    this.getInventory()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.props.updateRegionProgress()
  }

  componentDidMount() {
    if (this.props.progress < this.props.startPosition) {
      this.setState({
        pushedBackRandomTranslationNumber: getRandomNumberFromOneTo(9),
      })
    }

    /*
      This (alongside with <SizeObserver/>) is a poor hack to resolve Safari
      issue, when it doesn’t compute height for `inline-block` element, which
      contains map with AspectRatioSvg.

      The code below forces layout to be recomputed to trigger ResizeObserver
      update and finally set map height.

      Unfortunately, there’s no easy way to fix it properly, so it’s not a TODO,
      but an explanation of what is happening here.
    */
    window.setTimeout(() => {
      const resizeEvent = new Event('resize')
      window.dispatchEvent(resizeEvent)
    }, 1)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.position !== this.props.progress &&
      typeof this.props.currentPopup === 'undefined'
    ) {
      const delta = (this.props.progress - this.props.position) / 16
      const nextPosition =
        Math.abs(delta) > 0.01
          ? Math.ceil((delta + this.props.position) * 1000) / 1000
          : this.props.progress

      requestAnimationFrame(() =>
        this.props.updatePosition(this.props.currentWorldKey, nextPosition)
      )
    }

    if (
      prevProps.position !== this.props.position &&
      this.props.position === this.props.progress
    ) {
      this.props.updateRegionProgress()
    }
  }

  handleTreasurePopupClose() {
    this.setState({ hiddenTreasureRandomTranslationNumber: 0 })
  }

  handlePushedBackPopupClose() {
    this.setState({ pushedBackRandomTranslationNumber: 0 })
  }

  handleTreasureCollectPopupClose() {
    this.props.startWaiting(processIds.COLLECT_TREASURE)
    this.props.deleteTreasure(this.props.closestTreasure.id, {
      success: () => [
        worlds.actions.removeTreasure(
          this.props.currentWorldKey,
          this.props.closestTreasure.id
        ),
        stopWaiting(processIds.COLLECT_TREASURE),
      ],
      failure: ({ value }) => [
        Notifications.add({ message: value, level: 'error' }),
        stopWaiting(processIds.COLLECT_TREASURE),
      ],
      version: 1,
    })
  }

  handleTreasureClick() {
    this.setState({
      hiddenTreasureRandomTranslationNumber: getRandomNumberFromOneTo(10),
    })
  }

  handleWorldEnd() {
    this.props.finishWorld({
      success: () => this.props.redirectTo('/worlds'),
      failure: res => Notifications.add({ message: res.value, level: 'error' }),
      version: 2,
    })
  }

  handleDismissGift() {
    this.props.startWaiting(processIds.CLAIM_GIFT)
    this.props.giftClaimed(false)
    this.props.deleteGift({
      success: () => this.props.stopWaiting(processIds.CLAIM_GIFT),
      failure: res => [
        Notifications.add({ message: res.value, level: 'error' }),
        this.props.stopWaiting(processIds.CLAIM_GIFT),
      ],
      version: 2,
    })
    this.props.clearGift()
    this.props.redirectTo('/wallet/deposit')
  }

  handleClaimGift() {
    this.props.giftClaimed(true)
    this.props.claimGift({
      success: () => this.props.stopWaiting(processIds.CLAIM_GIFT),
      failure: res => [
        Notifications.add({ message: res.value, level: 'error' }),
        this.props.stopWaiting(processIds.CLAIM_GIFT),
      ],
      version: 2,
    })
    this.props.clearGift()
  }

  getInventory() {
    if (R.isEmpty(this.props.items)) {
      fetchInventory({
        success: res => inventory.items.actions.update(res.value),
        failure: res =>
          Notifications.add({ message: res.value, level: 'error' }),
        version: 1,
      })
    }
  }

  render() {
    return (
      <Wrapper innerRef={node => (this.node = node)}>
        <SizeObserver
          getNextState={rect => ({ height: rect.height })}
          render={({ innerRef, state }) => (
            <MapWrapper innerRef={innerRef}>
              <MapRegions
                currentWorldKey={this.props.currentWorldKey}
                height={state.height}
                regions={this.props.regions}
                progress={this.props.position}
                boosterMultiplier={this.props.boosterMultiplier}
                beatBossesCampaign={this.props.beatBossesCampaign}
                playerAvatarSrc={this.props.playerAvatar}
                treasures={this.props.treasures}
                treasureImage={this.props.treasureImage}
                onTreasureClick={this.handleTreasureClick}
                onBossAvatarClick={
                  this.props.isLastRegion ? (
                    () => {}
                  ) : (
                    () => this.props.redirectTo('/adventure/region-target')
                  )
                }
                onPlayerAvatarClick={() => this.props.redirectTo('/dashboard')}
              />
              <WorldsWrapper>
                <Link to="/worlds">
                  <WorldsImage
                    src={this.props.translate('region.icon.worlds')}
                  />
                </Link>
              </WorldsWrapper>
              <HowToPlayWrapper>
                <Link to="/how-to-play">
                  <HowToPlayImage
                    src={this.props.translate('region.icon.howtoplay')}
                  />
                </Link>
              </HowToPlayWrapper>
              {this.props.isSportsbookEnabled && (
                <SportsWrapper>
                  <Link to="/sports">
                    <SportsImage
                      src={this.props.translate('region.icon.sports')}
                    />
                  </Link>
                </SportsWrapper>
              )}
              <GamesWrapper>
                <Link to="/casino">
                  <CasinoImage
                    src={this.props.translate('region.icon.casino')}
                  />
                </Link>
                <OnboardingPopper
                  hidden={this.props.onboardingHidden}
                  placement="left"
                >
                  <QuickGames />
                </OnboardingPopper>
              </GamesWrapper>
              {this.props.campaignKey && (
                <CampaignButtonWrapper>
                  <CampaignButton
                    onClick={() =>
                      this.props.redirectTo(
                        `/adventure/campaigns/${this.props.campaignKey}`
                      )}
                  >
                    {this.props.translate('region.campaign.button-label')}
                  </CampaignButton>
                </CampaignButtonWrapper>
              )}
            </MapWrapper>
          )}
        />
        <PopupContainer
          id="current-region-locked"
          isOpen={this.props.isCurrentRegionLocked}
        >
          <PopupImageShadow
            src={`${this.props.assetPath}/boss_avatars/avatar-king.png`}
          />
          <PopupTitle>
            {this.props.translate(
              `popup.${this.props
                .currentWorldKey}.current-region-locked.heading`
            )}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.${this.props
                  .currentWorldKey}.current-region-locked.description`,
                {
                  time: toLocaleDate(
                    this.props.availableDate,
                    this.props.language
                  ).time,
                }
              ),
            }}
          />
          <Button stretch onClick={() => this.props.redirectTo('/worlds')}>
            {this.props.translate(
              `popup.${this.props.currentWorldKey}.current-region-locked.button`
            )}
          </Button>
        </PopupContainer>
        <PopupContainer
          id="new-journey"
          isOpen={Boolean(this.props.newJourneyNotification)}
        >
          <PopupTitle>
            {this.props.translate('popup.new-journey.heading')}
          </PopupTitle>
          <PopupImageShadow src={this.props.prizeImageUrl} />
          <PopupDescription
            html={{
              __html: this.props.translate('popup.new-journey.description'),
            }}
          />
          <Button
            stretch
            onClick={() => {
              this.props.onNotificationRemove(
                this.props.newJourneyNotification.id
              )
            }}
          >
            {this.props.translate('continue')}
          </Button>
          <Space top="10px">
            <Link to="/how-to-play" className="popup-new-journey__link">
              {this.props.translate('popup.new-journey.learn-more')}
            </Link>
          </Space>
        </PopupContainer>

        {R.map(
          notification => (
            <PopupContainer
              key={notification.event}
              id={notification.event}
              isOpen
              title={this.props.translate(`${notification.event}.headline`)}
              onRequestClose={() =>
                this.props.onNotificationRemove(notification.id)}
            >
              <PopupImage
                src={this.props.translate(`${notification.event}.image`)}
              />
              <PopupDescription
                html={{
                  __html: this.props.translate(
                    `${notification.event}.description`
                  ),
                }}
              />
              <Button
                stretch
                onClick={() => {
                  this.props.onNotificationRemove(notification.id)
                  this.props.redirectTo('/worlds')
                }}
              >
                {this.props.translate(`${notification.event}.button`)}
              </Button>
            </PopupContainer>
          ),
          this.props.notificationsList
        )}
        <PopupContainer id="gift" isOpen={Boolean(this.props.giftAmount)}>
          <PopupImage src={this.props.translate('free-gift.bonus-coin')} />
          <PopupTitle>
            {this.props.translate('gift.title', {
              amount: this.props.giftAmount,
            })}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate('gift.description', {
                casinoWagerFactor: this.props.giftCasinoWagerFactor,
                sportWagerFactor: this.props.giftSportWagerFactor,
              }),
            }}
          />
          <Button
            stretch
            variant="primary"
            onClick={this.handleClaimGift}
            loading={this.props.claimGiftInProgress}
          >
            {this.props.translate('popup.collect-treasure.claim')}
          </Button>
          <Space top="10px">
            <Button
              stretch
              variant="secondary"
              onClick={this.handleDismissGift}
            >
              {this.props.translate('gift.dismiss')}
            </Button>
          </Space>
        </PopupContainer>
        <PopupContainer
          id={`welcome-to-region.${this.props.currentRegion.key}`}
          isOpen={
            this.props.startPosition / 100 === this.props.currentRegionIndex &&
            this.props.currentRegionIndex !== this.props.regions.length - 2
          }
          openOnce
        >
          <PopupScrollable>
            <PopupWelcomeImage
              imagePath={`${this.props.assetPath}/regions/region_sign.png`}
            >
              <PopupWelcomeTo>
                {this.props.translate('popup.welcome-to-region.sign')}
              </PopupWelcomeTo>
              <PopupWelcomeRegionName>
                {this.props.translate(this.props.currentRegion.key)}
                <br />
                {`${this.props.currentRegionIndex + 1} / ${this.props.regions
                  .length - 2}`}
              </PopupWelcomeRegionName>
            </PopupWelcomeImage>
            <PopupDescription
              html={{
                __html: this.props.translate(
                  `popup.welcome-to-region.${this.props.currentRegion.key}`
                ),
              }}
            />
          </PopupScrollable>
          <Button
            stretch
            onClick={() =>
              this.props.removePopup(
                `welcome-to-region.${this.props.currentRegion.key}`
              )}
          >
            {this.props.translate('popup.welcome-to-region.continue')}
          </Button>
        </PopupContainer>

        <PopupContainer
          id="hidden-treasure"
          isOpen={this.state.hiddenTreasureRandomTranslationNumber > 0}
          onRequestClose={this.handleTreasurePopupClose}
        >
          <PopupImage
            src={this.props.translate(
              this.props.isExistingTranslation(
                `popup.${this.props.currentWorldKey}.hidden-treasure.image`
              )
                ? `popup.${this.props.currentWorldKey}.hidden-treasure.image`
                : 'popup.hidden-treasure.image'
            )}
          />
          <PopupTitle>
            {this.props.translate(
              this.props.isExistingTranslation(
                `popup.${this.props.currentWorldKey}.hidden-treasure.heading`
              )
                ? `popup.${this.props.currentWorldKey}.hidden-treasure.heading`
                : 'popup.hidden-treasure.heading'
            )}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.hidden-treasure.description-${this.state
                  .hiddenTreasureRandomTranslationNumber}`
              ),
            }}
          />
        </PopupContainer>
        <PopupContainer
          id="pre-boss-fight"
          isOpen={
            this.props.progress / 100 === this.props.currentRegionIndex + 1 &&
            this.props.progress === this.props.position &&
            this.props.position !== (this.props.regions.length - 1) * 100
          }
        >
          <PreBossfightImage
            boss={this.props.currentRegion.bossAvatar}
            player={this.props.playerAvatar}
          />
          <PopupTitle>
            {this.props.translate('popup.pre-bossfight.heading')}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.pre-bossfight.description-${getRandomNumberFromOneTo(7)}`
              ),
            }}
          />
          <Link to="/bossfight">
            <Button stretch>
              {this.props.translate('popup.pre-bossfight.fight')}
            </Button>
          </Link>
        </PopupContainer>
        <PopupContainer
          id="pushed-back"
          isOpen={
            this.state.pushedBackRandomTranslationNumber > 0 &&
            this.props.position === this.props.progress
          }
          onRequestClose={this.handlePushedBackPopupClose}
        >
          <PopupImageShadow
            src={`${this.props.assetPath}/boss_avatars/avatar-king.png`}
          />
          <PopupTitle>
            {this.props.translate(
              `popup.pushed-back.heading-${this.state
                .pushedBackRandomTranslationNumber}`
            )}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.pushed-back.description-${this.state
                  .pushedBackRandomTranslationNumber}`
              ),
            }}
          />
          <Button stretch onClick={this.handlePushedBackPopupClose}>
            {this.props.translate('continue')}
          </Button>
        </PopupContainer>
        <PopupContainer
          id="closest-treasure-progress"
          isOpen={
            (this.props.closestTreasure &&
              this.props.position <= this.props.progress &&
              this.props.position >= this.props.closestTreasureProgress) ||
            this.props.collectingTreasure
          }
        >
          <CollectTreasureImage
            amount={this.props.closestTreasure.amount}
            image={
              this.props.closestTreasure.gameImage ||
              `${this.props.assetPath}/bossfight/ruby.jpg`
            }
            value={heroSpinType(this.props.closestTreasure.heroSpinType)}
          />
          <PopupTitle>
            {this.props.translate(
              `popup.collect-treasure.${this.props.closestTreasure
                .type}.heading`,
              { amount: this.props.closestTreasure.amount }
            )}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.collect-treasure.${this.props.closestTreasure
                  .type}.description`
              ),
            }}
          />
          <Button
            stretch
            loading={this.props.collectingTreasure}
            onClick={this.handleTreasureCollectPopupClose}
          >
            {this.props.translate('popup.collect-treasure.claim')}
          </Button>
        </PopupContainer>
        <PopupContainer
          id="boss-not-fightable"
          isOpen={this.props.position === (this.props.regions.length - 1) * 100}
        >
          <PopupImageShadow src={this.props.prizeImageUrl} />
          <PopupTitle>
            {this.props.translate(
              `popup.completed.${this.props.currentWorldKey}.title`
            )}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.isExistingTranslation(
                `popup.completed.${this.props.currentWorldKey}.description`
              )
                ? this.props.translate(
                    `popup.completed.${this.props.currentWorldKey}.description`
                  )
                : this.props.translate('popup.completed.description'),
            }}
          />
          <Button stretch onClick={this.handleWorldEnd}>
            {this.props.translate('popup.completed.button')}
          </Button>
        </PopupContainer>
        {this.props.children}
      </Wrapper>
    )
  }
}

// TODO: use reselect
const _getActiveRaceCampaign = memoize(getActiveRaceCampaign)
const _getAssetPath = memoize(getAssetPath)
const _getActiveBoosterCampaign = memoize(getActiveBoosterCampaign)
const _getActiveBoosterItem = memoize(getActiveBoosterItem)
const _isBeatBossesCampaignActive = memoize(isBeatBossesCampaignActive)
const _isWaiting = memoize(isWaiting)
const _getGiftAmount = memoize(getGiftAmount)
const _getUserAvatar = memoize(getUserAvatar)

function mapStateToProps(state) {
  // TODO: https://github.com/RushPlay/casino-heroes/issues/413
  const treasures = adventure.selectors.getTreasures(state.adventure)
  const items = inventory.items.selectors.get(state.inventory)

  const campaign = _getActiveRaceCampaign(state.campaigns)
  const onboarding = triggers.selectors.getByEvent('onboarding', state.triggers)
  const currentWorld = worlds.selectors.getCurrentWorld(state.adventure.worlds)
  const currentRegion = adventure.selectors.getCurrentRegion(state.adventure)
  const availableFrom = R.pathOr(0, ['availableFrom'], currentRegion)

  return {
    assetPath: _getAssetPath(state.app),
    availableDate: new Date(availableFrom),
    boosterMultiplier: Math.max(
      _getActiveBoosterCampaign(state.campaigns),
      _getActiveBoosterItem(items)
    ),
    beatBossesCampaign: _isBeatBossesCampaignActive(state.campaigns),
    language: getLanguage(state.app),
    currentWorldKey: currentWorld.key,
    isCampaignActive: !R.isNil(campaign && campaign.key),
    isLastRegion: currentWorld.isLastRegion,
    isSportsbookEnabled: isSportsbookEnabled(state.app),
    campaignKey: campaign && campaign.key,
    notificationsList: triggers.selectors.getByPrefix(
      'notification',
      state.triggers
    ),
    claimGiftInProgress: _isWaiting(state.app, processIds.CLAIM_GIFT),
    collectingTreasure: _isWaiting(state.app, processIds.COLLECT_TREASURE),
    currentPopup: R.head(state.popups.queue),
    currentRegionIndex: adventure.selectors.getCurrentRegionIndex(
      state.adventure
    ),
    currentRegion,
    closestTreasure: R.head(treasures),
    closestTreasureProgress: adventure.selectors.getClosestTreasureProgress(
      state.adventure
    ),
    giftAmount: _getGiftAmount(state.player),
    giftCasinoWagerFactor: getGiftCasinoWagerFactor(state.player),
    giftSportWagerFactor: getGiftSportWagerFactor(state.player),
    onboardingHidden:
      !onboarding || (onboarding && !R.hasIn('deleted', onboarding)),
    playerAvatar: _getUserAvatar(state.session),
    prizeImageUrl: currentWorld.prizeImageUrl,
    progressPercent: currentWorld.regionProgress,
    position: adventure.selectors.getMapPosition(state.adventure),
    progress: adventure.selectors.getProgress(state.adventure),
    startPosition: adventure.selectors.getMapStartPosition(state.adventure),
    regions: adventure.selectors.getRegions(state.adventure),
    treasureImage: currentWorld.treasureImageUrl,
    treasures,
    newJourneyNotification: triggers.selectors.getByEvent(
      'adventure-updated',
      state.triggers
    ),
    isCurrentRegionLocked: isFutureDate(availableFrom),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      claimGift,
      clearGift,
      deleteGift,
      giftClaimed,
      deleteTreasure,
      finishWorld,
      redirectTo,
      removePopup,
      updatePosition: worlds.actions.updateMapPosition,
      startWaiting,
      stopWaiting,
      updateRegionProgress: () =>
        updateRegionProgress({
          failure: res => {
            if (res.status !== 401) {
              return Notifications.add({ message: res.value, level: 'error' })
            }
          },
          version: 1,
        }),
      onNotificationRemove: id => [
        triggers.actions.remove(id),
        deleteNotificationApi(id, { version: 1 }),
      ],
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslate(RegionContainer)
)

RegionContainer.defaultProps = {
  closestTreasure: {},
  params: {},
}

const TreasurePropType = PropTypes.shape({
  amount: PropTypes.number,
  gameImage: PropTypes.string,
  heroSpinType: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
})

RegionContainer.propTypes = {
  assetPath: PropTypes.string.isRequired,
  availableDate: PropTypes.instanceOf(Date),
  beatBossesCampaign: PropTypes.bool,
  boosterMultiplier: PropTypes.number,
  campaignKey: PropTypes.string,
  children: PropTypes.node,
  claimGift: PropTypes.func.isRequired,
  claimGiftInProgress: PropTypes.bool.isRequired,
  clearGift: PropTypes.func.isRequired,
  closestTreasure: TreasurePropType,
  closestTreasureProgress: PropTypes.number,
  collectingTreasure: PropTypes.bool,
  currentPopup: PropTypes.string,
  currentRegion: PropTypes.object,
  currentRegionIndex: PropTypes.number.isRequired,
  currentWorldKey: PropTypes.string.isRequired,
  deleteGift: PropTypes.func.isRequired,
  deleteTreasure: PropTypes.func.isRequired,
  fetchInventory: PropTypes.func,
  finishWorld: PropTypes.func.isRequired,
  giftAmount: PropTypes.number,
  giftCasinoWagerFactor: PropTypes.number,
  giftSportWagerFactor: PropTypes.number,
  giftClaimed: PropTypes.func.isRequired,
  isCurrentRegionLocked: PropTypes.bool,
  isExistingTranslation: PropTypes.func,
  isLastRegion: PropTypes.bool,
  isSportsbookEnabled: PropTypes.bool,
  items: PropTypes.array,
  language: PropTypes.string,
  newJourneyNotification: PropTypes.object,
  notificationsList: PropTypes.array,
  params: PropTypes.object,
  playerAvatar: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  prizeImageUrl: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  progressPercent: PropTypes.number,
  redirectTo: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  removePopup: PropTypes.func.isRequired,
  startPosition: PropTypes.number.isRequired,
  startWaiting: PropTypes.func.isRequired,
  stopWaiting: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  treasureImage: PropTypes.string,
  treasures: PropTypes.arrayOf(TreasurePropType),
  updatePosition: PropTypes.func.isRequired,
  updateRegionProgress: PropTypes.func.isRequired,
  onboardingHidden: PropTypes.bool,
  onNotificationRemove: PropTypes.func,
}

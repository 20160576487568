import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as forms from '@rushplay/forms'
import { withTranslate } from '@rushplay/i18n'

import { MultipleChoice as Component } from './multiple-choice'

function mapDispatchToProps(dispatch, props) {
  return {
    onFieldChange: (value) =>
      dispatch(forms.changeValue(props.form, props.name, value)),
  }
}

const connector = R.compose(
  recompose.fromRenderProps(forms.FormContext.Consumer, (context) => ({
    form: context.name,
  })),
  connect(null, mapDispatchToProps),
  withTranslate
)

export const MultipleChoice = connector(Component)

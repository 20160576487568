import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import { paragraph } from '../mixins'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`

const Description = styled.p`
  ${paragraph};
  text-align: center;
`

const FlexItemSpace = styled(Space)`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
`

function StoreItemDetails(props) {
  return (
    <Wrapper>
      {props.children}
      <Title>{props.title}</Title>
      <Space bottom={8}>
        <Description>{props.description}</Description>
      </Space>
      <Flex wrap justifyContent="center">
        {props.blitzMode && (
          <FlexItemSpace x={5}>
            <Flex column>
              <Button
                disabled={props.rubiesAmount < props.price}
                loading={props.purchaseInProgress}
                icon="ruby"
                variant="primary"
                onClick={() => props.onBuy(true)}
              >
                {`x ${props.price}`}
              </Button>
              <Space top={3}>
                <Description>
                  {props.translate('rubystore.details.buy.as-blitz')}
                </Description>
              </Space>
            </Flex>
          </FlexItemSpace>
        )}
        <FlexItemSpace x={5}>
          <Flex column>
            <Button
              disabled={props.rubiesAmount < props.price}
              loading={props.purchaseInProgress}
              icon="ruby"
              variant="primary"
              onClick={() => props.onBuy(false)}
            >
              {`x ${props.price}`}
            </Button>
            {props.blitzMode && (
              <Space top={3}>
                <Description>
                  {props.translate('rubystore.details.buy.as-normal')}
                </Description>
              </Space>
            )}
          </Flex>
        </FlexItemSpace>
      </Flex>
    </Wrapper>
  )
}

StoreItemDetails.propTypes = {
  blitzMode: PropTypes.bool,
  children: PropTypes.node,
  description: PropTypes.string,
  price: PropTypes.number.isRequired,
  purchaseInProgress: PropTypes.bool.isRequired,
  rubiesAmount: PropTypes.number,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired,
}

export default withTranslate(StoreItemDetails)

import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import inventory from '@rushplay/inventory'
import { getVip, isSessionActive } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import { isSportsbookEnabled } from '../../../store/app'
import { unseenPromotionCount } from '../../../store/promotions'

import { MainMenu as Component } from './main-menu'

function mapStateToProps(state) {
  const authenticated = isSessionActive(state.session)
  const promotionsCount = unseenPromotionCount(state.promotions, {
    authenticated,
    vip: getVip(state.session),
    depositNumber: state.player.depositNumber,
  })
  return {
    authenticated,
    isSportsbookEnabled: isSportsbookEnabled(state.app),
    license: jurisdiction.getLicense(state.jurisdiction),
    treasureCount: inventory.newItems.selectors.get(state.inventory),
    promotionsCount,
  }
}

const connector = R.compose(withRouter, withTranslate, connect(mapStateToProps))

export const MainMenu = connector(Component)

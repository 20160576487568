import PropTypes from 'prop-types'
import React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

/**
 * @typedef {Object} NodeRect
 * @prop {number} height
 * @prop {number} width
 * @prop {number} top
 * @prop {number} left
 */

/** Utility component for deriving data from element size */
export default class SizeObserver extends React.PureComponent {
  /**
   * @param {Object} props
   * @param {function(NodeRect): ?Object} props.getNextState
   *  State creator. Gets target element size on every update and produces
   *  this components internal state. If returns null, no state update happens.
   *  Try narrowing your use case to as few updates as posssible.
   * @param {function({innerRef: function, state: Object}): ?ReactElement} props.render
   *  Renderer. Receives `state` produced by `getNextState` and `innerRef`
   *  which must be attached to observation target.
   */
  constructor(props) {
    super(props)

    this.handleRef = this.handleRef.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.observer = new ResizeObserver(this.handleResize)

    this.state = {}
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  handleRef(node) {
    if (this.node) {
      this.observer.unobserve(this.node)
    }

    this.node = node

    if (this.node) {
      this.observer.observe(this.node)
    }
  }

  handleResize([entry]) {
    const nextState = this.props.getNextState(entry.contentRect)
    if (nextState) {
      this.setState(nextState)
    }
  }

  render() {
    const renderProps = {
      innerRef: this.handleRef,
      state: this.state,
    }
    return this.props.render(renderProps)
  }
}

SizeObserver.defaultProps = {
  getNextState: () => null,
  render: () => null,
}

SizeObserver.propTypes = {
  getNextState: PropTypes.func,
  render: PropTypes.func,
}

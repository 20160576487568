import getIndexByKey from './get-index-by-key'
/**
 * Get the next/previous key relative to given key
 * If no next key exists, it returns the first key
 * If no previous key exists, it return the last key
 * @private
 * @param {string} key - The key property of each object in images
 * @param {number} delta
 * @param {array} images - Image collection
 * @returns {string} returns the next or previous key
 */
export default function handleChange(key, delta, images) {
  const nextIndex = getIndexByKey(key, images) + delta

  if (nextIndex < 0) {
    return images[images.length - 1].key
  } else if (nextIndex >= images.length) {
    return images[0].key
  }

  return images[nextIndex].key
}

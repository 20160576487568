import PropTypes from 'prop-types'
import React from 'react'

import Text from '../../common/text'

export function CurrentSessionTime(props) {
  if (props.currentSessionTime) {
    if (props.withTranslation) {
      return (
        <Text align="center" size="12">
          {`${props.translate(
            'current-session-time.label'
          )} ${props.currentSessionTime} min`}
        </Text>
      )
    }

    return `${props.currentSessionTime} min`
  }

  return null
}

CurrentSessionTime.propTypes = {
  currentSessionTime: PropTypes.string,
  translate: PropTypes.func.isRequired,
  withTranslation: PropTypes.bool,
}

import styled from '@emotion/styled'
import { rem } from 'polished'

import HtmlContent from '../html-content'
import { headingFont, textFont } from '../../constants/typography'

export const PopupImage = styled.img`max-height: ${rem('200px')};`

export const PopupImageShadow = styled.img`
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.37));
  max-height: ${rem('200px')};
  margin-bottom: 1rem;
`

export const PopupTitle = styled.h1`
  font-family: ${headingFont};
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const PopupDescription = styled(HtmlContent)`
  font-family: ${textFont};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: ${rem('20px')};
`

export const PopupDescriptionSmall = styled.p`
  font-family: ${textFont};
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: ${rem('20px')};
  font-weight: 600;
`

export const PopupScrollable = styled.div`
  max-height: 450px;
  overflow-y: auto;
`
export const PopupWelcomeImage = styled.div`
  width: 100%;
  height: ${rem('200px')};
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  background-image: url(${props => props.imagePath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 0.5rem;
`
export const PopupWelcomeTo = styled.div`
  font-size: ${rem('20px')};
  font-family: ${headingFont};
  line-height: 1.2;
`
export const PopupWelcomeRegionName = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: ${headingFont};
  font-weight: 600;
  margin: 0 auto;
  white-space: nowrap;
  margin-top: ${rem('10px')};
`

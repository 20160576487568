import PropTypes from 'prop-types'
import React from 'react'

import GameIframeContainer from './game-iframe-container'

// Got the URL from Malta Operator Guidelines doc
export const mgFlashGames = 'https://quickfireessl.gamesassists.eu/'

class MicrogamingGameContainer extends React.Component {
  constructor() {
    super()
    this.iframeRef = React.createRef()
    this.handleRealityCheck = this.handleRealityCheck.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isRealityCheck !== prevProps.isRealityCheck) {
      return this.handleRealityCheck('StopGamePlay')
    } else {
      return this.handleRealityCheck('ReStartGamePlay')
    }
  }

  handleRealityCheck(action) {
    // This bit probably doesn't work, but leaving it here just in case it will in Production
    if (this.iframeRef.current) {
      this.iframeRef.current.contentWindow.postMessage(action, mgFlashGames)
    }
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GameIframeContainer {...this.props} innerRef={this.iframeRef} />
      </div>
    )
  }
}

MicrogamingGameContainer.PropTypes = {
  innerRef: PropTypes.func,
}

export default MicrogamingGameContainer

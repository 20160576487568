import * as R from 'ramda'
import * as recompose from 'recompose'
import Cookies from 'universal-cookie'
import { connect } from 'react-redux'
import { cookie } from 'redux-effects-universal-cookie'
import { withRouter } from 'react-router'

import * as session from '@rushplay/session'

import * as app from '../store/app'

import { Chat } from './live-chat'

function mapStateToProps(state, props) {
  const cookies = new Cookies()
  const language = app.getLanguage(state.app)
  const department = app.getLiveChatDepartment(state.app)

  const name = session.getDisplayName(state.session)
  const username = session.getUsername(state.session)
  const displayName = name && `${name} (${username})`

  const birthdate = R.pathOr('', ['player', 'birthdate'], state.session)
  const birthdateTag = birthdate ? `DOB: ${birthdate}` : null

  const valueSegment = session.getValueSegment(state.session)
  const valueSegmentTag = valueSegment ? `Value segment: ${valueSegment}` : null

  return {
    addScriptInHead: true,
    appKey: app.getLiveChatKey(state.app),
    departmentId: department.primary,
    email: session.getEmail(state.session),
    fallbackDepartmentId: department.fallback,
    language,
    mountPoint: 'supportChat',
    name: displayName,
    tags: R.filter(item => !R.isNil(item), [birthdateTag, valueSegmentTag]),
    zendeskCached: cookies.set('zendesk_cached'),
    visibleAt: R.includes('/sports', props.location.pathname) ? 9999 : 800,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    onZendeskCached: () =>
      dispatch([
        cookie('zendesk_cached', 1, {
          path: '/',
          maxAge: 36000,
        }),
      ]),
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  recompose.branch(props => !props.appKey, recompose.renderNothing)
)

export const SupportChat = connector(Chat)

import * as R from 'ramda'
import { createSelector } from 'reselect'

import isInRange from '../is-in-range'

import { bindSelector } from './selectors'

const SET_LIVE_CASINO_GAMES = 'casino-heros/live-casino/SET_LIVE_CASINO_GAMES'
const SET_BET_RANGE = 'casino-heros/live-casino/SET_BET_RANGE'

export function setLiveCasinoGames(games) {
  return {
    type: SET_LIVE_CASINO_GAMES,
    payload: games,
  }
}

export function setBetRange(value) {
  return {
    type: SET_BET_RANGE,
    payload: value,
  }
}

const initialState = {
  games: [],
  showHelpModal: true,
  filters: {
    betRange: [0, Infinity],
  },
}

function filtersReducer(state = {}, action) {
  switch (action.type) {
    case SET_BET_RANGE:
      return R.assoc('betRange', action.payload, state)
  }
}

export default function liveCasino(state = initialState, action) {
  switch (action.type) {
    case SET_BET_RANGE:
      return R.assoc('filters', filtersReducer(state.filters, action), state)
    case SET_LIVE_CASINO_GAMES:
      return R.assoc('games', action.payload, state)
    default:
      return state
  }
}

export const getLiveCasinoGames = createSelector(
  R.pathOr([], ['casino', 'games']),
  R.pathOr([], ['liveCasino', 'games']),
  (games, ids) => R.map((id) => R.prop(id, games), ids)
)

function isOfCategory(category = 'all') {
  return (game) =>
    R.equals(category, 'all') || R.equals(category, game.gameType)
}

const isTableOpen = (game) => game.tableOpen === true

function getFilterParams(state, props) {
  const filters = R.propOr({}, 'filters', state)
  return {
    betRange: filters.betRange,
    category: props.category,
  }
}

export const getFilters = bindSelector('liveCasino', getFilterParams)

export const getFilteredGames = createSelector(
  getFilters,
  getLiveCasinoGames,
  (params, games) =>
    R.filter(
      R.allPass([
        (game) =>
          isInRange(
            params.betRange[0],
            params.betRange[1],
            game.minBetCents / 100
          ),
        isOfCategory(params.category),
        isTableOpen,
      ]),
      games
    )
)

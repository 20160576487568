import * as R from 'ramda'
import URL from 'url'

/**
 * Process given URL with a provided function.
 * Parses the URL, applies the function and formats the URL back into string.
 * @param {Function} fn modifying function.
 * @param {string} url URL to be processed.
 * @returns {string} resulting URL.
 */
export function mapUrl(fn, url) {
  const urlObj = R.dissoc('search', URL.parse(url, true))
  return URL.format(fn(urlObj))
}

import React from 'react'

import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #183363;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
`

const Link = styled.a`
  display: block;
  padding-top: 24px;
  text-decoration: underline;
`

export function GetModernBrowser() {
  return (
    <Wrapper>
      <p>
        {
          "It seems you're using an unsupported browser. To be able to have the best experience with us please download a modern one."
        }
      </p>
      <Link href="http://outdatedbrowser.com">Download a modern browser</Link>
    </Wrapper>
  )
}

/**
 * An object containing date and time related to the specific language code specified
 * @typedef LocaleDate
 * @property {string} dateTime locale specific date and time
 * @property {string} date locale specific date
 * @property {string} time locale specific time
 */

/**
 * @param {Number} timestamp (ex: 1535469831848)
 * @param {String} languageCode (ex: FI)
 * @returns {LocaleDate}
 * @example
 * toLocaleDate(Date.now(),'FI') // Returns: {dateTime: "30.8.2018 klo 14.07.14", date: "30.8.2018", time: "14.07.14"}
 */

export function toLocaleDate(timestamp, languageCode) {
  const date = new Date(timestamp)
  const locale = {
    en: 'en-GB',
    de: 'de-DE',
    sv: 'sv-SE',
    fi: 'fi-FI',
    ja: 'ja-JP',
    nl: 'nl-NL',
    no: 'nn-NO',
  }[languageCode.toLowerCase()]

  return {
    dateTime: date.toLocaleString(locale),
    date: date.toLocaleDateString(locale),
    time: date.toLocaleTimeString(locale),
  }
}

import React from 'react'

class ApgSeal extends React.Component {
  componentDidMount() {
    window.APG && window.APG.init()
  }

  render() {
    return (
      <div
        id="apg-seal-container"
        data-apg-seal-id="7bef53e6-e12b-4a5b-bfc2-3a88c6739b47"
        data-apg-image-size="128"
        data-apg-image-type="basic-small"
      />
    )
  }
}
export default ApgSeal

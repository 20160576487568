import * as R from 'ramda'
import memoize from 'fast-memoize'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as locks from '@rushplay/compliance/locks'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Api from '@rushplay/api-client'
import { getCurrency } from '@rushplay/session'

import * as App from '../../store/app'
import limits from '../../store/limits'

import { MoneyLimits as Component } from './money-limits'

const LOAD_LIMITS = 'load-limits'

const getLimits = memoize(state => {
  return R.map(
    target => ({
      target,
      periods: R.map(
        period =>
          R.mergeRight(
            jurisdiction.getMoneyLimit(state.jurisdiction, {
              type: target,
              period,
            }),
            limits.selectors.getLimit(state.player.limits, {
              target,
              period,
            })
          ),
        jurisdiction.getMoneyLimitPeriods(state.jurisdiction, { type: target })
      ),
    }),
    jurisdiction.getMoneyLimitTypes(state.jurisdiction)
  )
})

function mapStateToProps(state, props) {
  const loadingError = R.find(
    R.propEq('message', 'player-limits.errors.failed-loading'),
    state.notifications
  )

  return {
    currency: getCurrency(state.session),
    depositLimitLock: locks.getLock(state.locks, {
      type: 'unset-deposit-limits',
    }),
    isWaiting: App.isWaiting(state.app, LOAD_LIMITS),
    license: jurisdiction.getLicense(state.jurisdiction),
    limits: loadingError ? null : getLimits(state, props),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: () =>
      dispatch([
        App.startWaiting(LOAD_LIMITS),
        Api.fetchPlayerLimits({
          success: res => [
            App.stopWaiting(LOAD_LIMITS),
            limits.actions.init(res.value.result),
          ],
          failure: () => [
            App.stopWaiting(LOAD_LIMITS),
            Notifications.add({
              message: 'player-limits.errors.failed-loading',
              level: 'error',
            }),
          ],
          version: 2,
        }),
      ]),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const MoneyLimits = connector(Component)

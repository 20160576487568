import PropTypes from 'prop-types'
import React from 'react'
/**
 * @param {Object} variables Variables for Google Tag Manager
 */
function updateDataLayer(variables) {
  if (window.dataLayer) {
    window.dataLayer.push(variables)
  } else {
    // eslint-disable-next-line no-console
    console.error(`"window.dataLayer" is not defined`)
  }
}

/**
 * A component that pushes variables to an existing Google data layer.
 *
 * A requirment for using this hook is that 'window.dataLayer' must be defined.
 */
export function GoogleDataLayerManager({ affiliateId, authenticated, userId }) {
  const prevAuthenticated = React.useRef(authenticated)

  // Hook that updates 'user.uid' for an authenticated player
  React.useEffect(() => {
    if (authenticated) {
      if (userId) {
        updateDataLayer({ 'user.uid': userId })
      }
    }
  }, [authenticated, userId])

  // Hook that updates 'user.affiliateid' for an authenticated player
  React.useEffect(() => {
    if (authenticated) {
      if (affiliateId) {
        updateDataLayer({ 'user.affiliateid': affiliateId })
      }
    }
  }, [affiliateId, authenticated])

  React.useEffect(() => {
    if (authenticated) {
      prevAuthenticated.current = true
    }
  }, [authenticated, prevAuthenticated])

  // Hook that updates 'user.affiliateid' and 'user.uid' for an terminated session
  React.useEffect(() => {
    if (!authenticated && prevAuthenticated.current) {
      prevAuthenticated.current = false
      updateDataLayer({
        'user.affiliateid': undefined,
        'user.uid': undefined,
      })
    }
  }, [authenticated, prevAuthenticated])

  return null
}

GoogleDataLayerManager.propTypes = {
  affiliateId: PropTypes.string,
  authenticated: PropTypes.bool,
  userId: PropTypes.string,
}

import { withProps } from 'recompose'

import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import Icon from './icon/icon'

const rotate = keyframes`
  from { transform: rotateZ(0); }
  to { transform: rotateZ(360deg); }
`

const Spinner = styled(withProps({ name: 'loading' })(Icon))`
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  animation-timing-function: linear;
`

export default Spinner

export const PageSpinner = styled(Spinner)`
  margin-left: auto;
  margin-right: auto;
  /* Forcing <Icon/> display value override */
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  font-size: 10em;
`

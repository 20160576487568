import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as helpers from './internal-helpers/index'

/**
 * Primitive to build a date select
 * @param {number} value Timestamp
 * @param {number} min Minimum date
 * @param {number} max Maximum date
 * @returns {ReactComponent}
 * @example
 * <DateSelect
 *  min={new Date('January 19, 2016')}
 *  max={new Date()}
 * >
 *  {data => (
 *    <Select
 *      label="Day"
 *      name="day"
 *      options={data.options.days}
 *      value={data.values.day}
 *      onChange={value => data.onChange('day', value)}
 *    />
 *    <Select
 *      label="Month"
 *      name="month"
 *      options={data.options.months}
 *      value={data.values.month}
 *      onChange={value => data.onChange('month', value)}
 *      />
 *    <Select
 *      label="Year"
 *      month="year"
 *      options={data.options.years}
 *      value={data.values.year}
 *      onChange={value => data.onChange('year', value)}
 *    />
 *  )}
 * </DateSelect>
 */
export class DateSelect extends React.PureComponent {
  constructor() {
    super()

    this.getProps = this.getProps.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  getProps() {
    const date = new Date(this.props.value)
    const min = new Date(this.props.min)
    const max = new Date(this.props.max)

    return {
      values: {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      },
      options: {
        days: R.map(
          value => ({ label: value, value }),
          helpers.getDateRange(min, max, date)
        ),
        months: R.map(
          value => ({ label: value + 1, value }),
          helpers.getMonthRange(min, max, date)
        ),
        years: R.map(
          value => ({ label: value, value }),
          helpers.getYearRange(min, max)
        ),
      },
      onChange: this.handleChange,
    }
  }

  handleChange(unit, value) {
    const nextDate = helpers.getNextDate(unit, this.props.value, value)
    this.props.onChange(nextDate)
  }

  render() {
    return this.props.children(this.getProps())
  }
}

DateSelect.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  values: PropTypes.objectOf({
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  max: PropTypes.instanceOf(Date).isRequired,
  min: PropTypes.instanceOf(Date).isRequired,
  options: PropTypes.objectOf({
    days: PropTypes.arrayOf({
      label: PropTypes.number,
      value: PropTypes.number,
    }),
    months: PropTypes.arrayOf({
      label: PropTypes.number,
      value: PropTypes.number,
    }),
    years: PropTypes.arrayOf({
      label: PropTypes.number,
      value: PropTypes.number,
    }),
  }),
  onChange: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { Box, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

export function SocialMedia(props) {
  const i18n = useI18n()

  return (
    <Box
      paddingBottom={['15px', null, null, null, '48px']}
      width={[1, null, null, null, 'auto']}
      display="flex"
      flexDirection={['row', null, null, null, 'column']}
    >
      <Box
        display={['none', null, null, null, 'block']}
        paddingBottom={2}
        fontSize={[1, null, null, null, 2]}
      >
        {i18n.translate('footer.sections.social-media')}
      </Box>

      {R.map(
        link => (
          <Space paddingTop={[null, null, null, null, 4]} key={link.name}>
            {(link.links[props.language] || link.links.en) && (
              <a
                href={link.links[props.language] || link.links.en}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box display="flex" alignItems="center" height="24px">
                  <Space paddingRight={[6, null, null, null, 2]}>
                    <img src={link.image.url} />
                  </Space>
                  <Box
                    display={['none', null, null, null, 'block']}
                    fontSize={1}
                    textAlign="center"
                    opacity="0.5"
                  >
                    {link.name}
                  </Box>
                </Box>
              </a>
            )}
          </Space>
        ),
        props.links
      )}
    </Box>
  )
}

SocialMedia.propTypes = {
  language: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
}

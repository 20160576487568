import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { path } from 'ramda'

import { actions as formActions, selectors } from '@rushplay/legacy-forms'
import { isSessionActive } from '@rushplay/session'

import { login, toggleNativeAppMode } from '../../store/app'

import NativeApp from './native-app'

function NativeAppContainer(props) {
  return (
    <NativeApp
      activeSession={props.activeSession}
      isNativeApp={props.isNativeApp}
      password={props.password}
      username={props.username}
      onChangePassword={props.onChangePassword}
      onChangeUsername={props.onChangeUsername}
      onLogin={props.onLogin}
      onToggleNativeAppMode={props.onToggleNativeAppMode}
    />
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onToggleNativeAppMode: event => toggleNativeAppMode(event.detail),
      onLogin: value => login(value.username, value.password, 'ios'),
      onChangePassword: value =>
        formActions.changeValue('login', 'password', value),
      onChangeUsername: value =>
        formActions.changeValue('login', 'username', value),
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    activeSession: isSessionActive(state.session),
    isNativeApp: path(['app', 'nativeApp', 'isNativeApp'], state),
    password: selectors.value(state.forms, {
      form: 'login',
      field: 'password',
    }),
    username: selectors.value(state.forms, {
      form: 'login',
      field: 'username',
    }),
  }
}

NativeAppContainer.propTypes = {
  activeSession: PropTypes.bool.isRequired,
  isNativeApp: PropTypes.bool,
  password: PropTypes.string,
  username: PropTypes.string,
  onToggleNativeAppMode: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangeUsername: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(NativeAppContainer)

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import isAtCasinoGame from '../../util/at-casino-game'
import { getCasinoHeaderLinks } from '../../store/selectors'
import { isCasitabi, isEmbeddingEnabled } from '../../store/app'
import { tagPrefix } from '../tag-prefix'

import { Header as Component } from './header'

function mapStateToProps(state, ownProps) {
  return {
    embedding: isEmbeddingEnabled(state.app),
    showNav: !isAtCasinoGame(ownProps.location.pathname),
    license: jurisdiction.getLicense(state.jurisdiction),
    links: getCasinoHeaderLinks(state),
    tagPrefix: tagPrefix(isCasitabi(state)),
  }
}

export const Header = connect(mapStateToProps)(withRouter(Component))

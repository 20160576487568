import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { Field } from '@rushplay/legacy-forms'

import Button from '../../../common/button'
import DigitsField from '../../../common/digits-field'
import Heading from '../../../common/heading'
import HtmlContent from '../../../common/html-content'
import LockPage from '../lock-page'
import Text from '../../../common/text'
import validators from '../../../validators'

const ResendLink = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
`

export function EmailVerification(props) {
  const [showSent, setShowSent] = React.useState(false)

  React.useEffect(() => {
    props.onEnter()
  }, [])

  React.useEffect(() => {
    if (showSent) {
      const id = setTimeout(() => {
        setShowSent(false)
      }, 10000)

      return () => {
        clearTimeout(id)
      }
    }
  }, [showSent])

  function handleResendCode() {
    props.onResendCode(() => setShowSent(true))
  }

  return (
    <LockPage>
      <Heading level="2" align="center">
        {props.translate('locks.email-verification.title')}
      </Heading>
      <Common.Space y={5}>
        <Text align="center" lineHeight="1.4em">
          <HtmlContent
            html={{
              __html: props.translate('locks.email-verification.description', {
                email: props.playerEmail,
              }),
            }}
          />
        </Text>
      </Common.Space>
      <Field
        component={DigitsField}
        name="code"
        validator={[
          validators.required,
          validators.specialCharacters,
          validators.length(4),
        ]}
        normalize={R.replace(/([^0-9])/g, ' ')}
        count={4}
      />
      <Common.Space y="32px">
        <Button
          stretch
          disabled={!R.isEmpty(props.formErrors)}
          onClick={props.onSubmit}
          variant="primary"
        >
          {props.translate('locks.email-verification.submit')}
        </Button>
      </Common.Space>
      <Common.Flex justifyContent="center">
        {showSent ? (
          props.translate('locks.email-verification.code-resent')
        ) : (
          <ResendLink onClick={handleResendCode}>
            {props.translate('locks.email-verification.resend-code')}
          </ResendLink>
        )}
      </Common.Flex>
    </LockPage>
  )
}

EmailVerification.propTypes = {
  playerEmail: PropTypes.string,
  formErrors: PropTypes.array,
  translate: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

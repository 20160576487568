export default function pathWithoutLanguage(pathname) {
  // minimum pathname length equals 3, e.g. /en
  if (pathname.length <= 3) {
    return '/'
  }

  const regex = /^\/\w\w\//
  if (pathname.search(regex) > -1) {
    return pathname.replace(regex, '/')
  }

  return pathname
}

import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as Form from '../../../common/forms'

const LimitInput = styled.input`
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  font-weight: 500;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  font-size: 18px;
  color: white;
  &:focus {
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export function Row(props) {
  const i18n = I18n.useI18n()
  return (
    <Common.Box backgroundColor={props.backgroundColor} width="100%">
      <Common.Box
        display="grid"
        gridTemplateColumns="40% 60%"
        gridTemplateRows="auto"
        pt={5}
        px={props.id !== 'day' && [2, null, '14%', null]}
      >
        <Common.Box textAlign="left" fontSize={3}>
          {i18n.translate(`locks.unset-login-time-limits.period.${props.id}`)}
        </Common.Box>
        <Common.Box
          borderBottom={props.borderStyle}
          borderWidth="3px"
          borderColor="ocean"
        >
          <forms.Field
            id={props.id}
            name={props.id}
            validator={[
              forms.min(props.minAmount),
              forms.max(props.maxAmount),
              forms.required,
            ]}
            mountPoint="formsv2"
          >
            {field => (
              <LimitInput
                {...field.getProps(props)}
                autocomplete="off"
                type="number"
              />
            )}
          </forms.Field>
        </Common.Box>
        <Common.Box
          display="flex"
          gridColumn={
            props.id === 'day' ? 'span 2' : ['span 2', null, '2/2', null]
          }
          justifyContent="flex-end"
          pb={4}
        >
          <Form.FieldResponse for={props.id} />
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Row.defaultProps = {
  borderStyle: 'solid',
}
Row.propTypes = {
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderStyle: PropTypes.string,
  maxAmount: PropTypes.number,
  minAmount: PropTypes.number,
}

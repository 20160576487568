import * as R from 'ramda'

/**
 * Removes trailing slash if present from a given string
 * @param {string} path
 */
export default function removeTrailingSlash(path) {
  if (path && R.endsWith('/', path)) {
    return path.substring(0, path.length - 1)
  }

  return path
}

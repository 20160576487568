import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@rushplay/common'

import Badge from '../badge'
import Icon from '../icon/icon'

const Wrapper = styled(Link)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  ${props => props.disabled && 'cursor: not-allowed; opacity: 0.5;'};

  &:focus {
    outline: 0;
    transform: scale(1.1);
  }
`
const IconWrapper = styled.div`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: ${props => (props.disabled ? 'none' : 'scale(1.1)')};
  }
`
const Label = styled.span`
  margin-top: 0.75em;
  font-size: 0.75em;
  white-space: nowrap;
`

const BadgeIconWrapper = styled.span`font-size: ${props => props.size};`

function NavIcon(props) {
  return (
    <Wrapper disabled={props.disabled} to={props.to}>
      <IconWrapper disabled={props.disabled}>
        <Badge count={props.count ? props.count : 0}>
          <BadgeIconWrapper size={props.size}>
            <Icon name={props.icon} />
          </BadgeIconWrapper>
        </Badge>
      </IconWrapper>
      {props.label && <Label>{props.label}</Label>}
    </Wrapper>
  )
}

NavIcon.defaultProps = { size: '30px' }

NavIcon.propTypes = {
  count: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default NavIcon

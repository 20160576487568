import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import { LoginTimeLimitsContainer as Component } from './login-time-limits-container'

function mapStateToProps(state) {
  return {
    targets: jurisdiction.getSessionLimitTypes(state.jurisdiction),
  }
}

export const connector = connect(mapStateToProps)

export const LoginTimeLimitsContainer = connector(Component)

import PropTypes from 'prop-types'
import React from 'react'
import { timingFunctions } from 'polished'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Icon from '../common/icon/icon'
import Spinner from '../common/spinner'

function hoverFill(color) {
  return css`
    &:hover {
      background-color: ${color};
    }
  `
}

function activeFill(color) {
  return css`
    &:active {
      color: rgba(255, 255, 255, 0.5);
      background-color: ${color};
    }
  `
}

function hoverStroke(color) {
  return css`
    &:hover,
    &:active {
      color: ${color};
      border-color: ${color};
    }
  `
}

function buttonTheme({ variant, disabled, loading, theme }) {
  if (disabled && !loading) {
    return css`
      color: ${theme.variants.disabledText};
      background-color: ${theme.variants.disabled};
    `
  }

  switch (variant) {
    case 'primary':
      return css`
        background-color: ${theme.variants.primary};
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        ${hoverFill(theme.variants.primaryHover)};
        ${activeFill(theme.variants.primaryHover)};
      `

    case 'secondary':
      return css`
        background-color: transparent;
        border: 2px solid ${theme.variants.secondary};
        color: ${theme.variants.secondary};
        ${hoverStroke(theme.variants.secondaryHover)};
      `

    default:
      return css`
        background-color: ${theme.variants.default};
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        ${hoverFill(theme.variants.defaultHover)};
        ${activeFill(theme.variants.defaultHover)};
      `
  }
}

function getPaddings(small, icon, round, minimalPadding) {
  if (small) {
    return getSmallPaddings(icon, round, minimalPadding)
  } else {
    return getDefaultPaddings(icon, round, minimalPadding)
  }
}

function getSmallPaddings(icon, round, minimalPadding) {
  if (round) {
    return css`
      padding: 8px;
    `
  }

  if (icon) {
    return css`
      padding: 5px 24px 5px 38px;
    `
  }

  if (minimalPadding) {
    return css`
      padding: 5px 10px;
    `
  } else {
    return css`
      padding: 5px 24px;
    `
  }
}

function getDefaultPaddings(icon, round, minimalPadding) {
  if (round) {
    return css`
      padding: 9px;
    `
  }

  if (icon) {
    return css`
      padding: 12px 64px;
    `
  }

  if (minimalPadding) {
    return css`
      padding: 9px 19px;
    `
  } else {
    return css`
      padding: 9px 64px;
    `
  }
}

// TODO: Unify outline/override-outline styling for :focus state of all input, buttons and anchor tags across site?

const Wrapper = styled.button`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  color: rgb(255, 255, 255);

  border: none;
  outline: none;
  overflow: hidden;

  ${buttonTheme};
  white-space: nowrap;
  font-family: Montserrat;
  font-weight: 600;
  font-size: ${props => (props.small ? 14 : 18)}px;

  width: ${props => (props.stretch ? '100%' : 'auto')};
  min-width: ${props => (props.small ? 34 : 46)}px;
  min-height: ${props => (props.small ? 34 : 46)}px;

  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  justify-content: center;

  ${props =>
    getPaddings(
      props.small,
      props.icon,
      !props.childrenExist,
      props.minimalPadding
    )};
  border-radius: 100px;
`

const IconWrapper = styled.span`
  position: ${props => (props.childrenExist ? 'absolute' : 'relative')};
  left: ${props => (props.childrenExist ? '1em' : 'auto')};
  font-size: ${props => (props.small ? 18 : 22)}px;
  line-height: 1;
`

const ButtonContent = styled.div`
  opacity: ${props => (props.loading ? '0' : '1')};
  transition: opacity 0.3s ${timingFunctions('easeInSine')};
  display: inline-flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5em;
  opacity: ${props => (props.loading ? '1' : '0')};
  transition: opacity 0.3s ${timingFunctions('easeInSine')};
`

function Button(props) {
  let WrapperComponent = Wrapper

  // TODO: Hack to get ButtonLink component
  if (props.as) {
    WrapperComponent = Wrapper.withComponent(props.as)
  }

  return (
    <WrapperComponent
      childrenExist={Boolean(props.children)}
      disabled={props.disabled || props.loading}
      loading={props.loading}
      icon={props.icon}
      small={props.small}
      minimalPadding={props.minimalPadding}
      stretch={props.stretch}
      variant={props.variant}
      type={props.type}
      onClick={props.onClick}
      {...props}
    >
      <ButtonContent loading={props.loading}>
        {props.icon && (
          <IconWrapper
            childrenExist={Boolean(props.children)}
            small={props.small}
          >
            <Icon name={props.icon} />
          </IconWrapper>
        )}
        {props.children}
      </ButtonContent>
      {props.loading && <ButtonSpinner loading={props.loading} />}
    </WrapperComponent>
  )
}

Button.propTypes = {
  as: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  minimalPadding: PropTypes.bool,
  small: PropTypes.bool,
  stretch: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func,
}

export default Button

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withCountUp, withCurrentTime } from '@rushplay/common'

import Amount from '../common/amount'
import getRandomInt from '../util/get-random-int'
import { berry } from '../constants/colors'

const Wrapper = styled.div`
  margin-top: 0.3em;
  padding: 0.5em;
  float: left;
  color: white;
  background: ${berry};
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  box-shadow: 0.1em 0.1em 0.3em rgba(0, 0, 0, 0.8);
`

function Jackpot(props) {
  const deltaTime = props.currentTime - props.jackpotUpdatedAt

  return (
    <Wrapper>
      <Amount fixed>
        {Math.round(deltaTime / 1000 * props.velocity + props.value)}
      </Amount>
    </Wrapper>
  )
}

export default withCurrentTime(
  withCountUp(Jackpot, { propName: 'currentTime', factor: 10 }),
  { interval: () => getRandomInt(500, 3000) }
)

Jackpot.propTypes = {
  currentTime: PropTypes.number.isRequired,
  jackpotUpdatedAt: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  velocity: PropTypes.number.isRequired,
}

import * as R from 'ramda'
import url from 'url'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchGame, fetchLaunchParameters } from '@rushplay/api-client'

import { clearGameOptions, storeGameProps } from '../../actions'
import { getGame } from '../../selectors'

function toEmptyHtmlRedirect(urlObj) {
  return R.merge(urlObj, {
    pathname: 'empty.html',
    query: { redirect: `/${urlObj.pathname}` },
  })
}

function mapStateToProps(state, props) {
  return {
    game: getGame(state[props.mountPoint], { id: props.gameId }),
    clientType: props.clientType !== 'unknown' ? props.clientType : undefined,
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onOptionsClear: clearGameOptions,
      onFetch: () => {
        const isGameId = parseInt(props.gameId, 10)
        const config = {
          success: res => storeGameProps(props.gameId, res.value),
          failure: res =>
            props.onError(
              res.value.status
                ? `${res.value.status} ${res.value.error}`
                : res.value
            ),
          version: isGameId ? 2 : 1,
        }

        /*
        * Workaround for providers redirecting to our app (e.g. on game quit or
        * gambling history view), which causes app rendered inside the app
        * recursively.
        */
        const needsRedirect =
          props.clientType === 'browser' &&
          R.contains(R.path(['provider'], props.game), ['Pushgaming'])

        const referrer = R.concat(props.language, props.lobbyPath)

        const baseUrlObj = url.parse(window.location.href, false, true)

        const lobbyUrl = {
          protocol: baseUrlObj.protocol,
          host: baseUrlObj.host,
          pathname: referrer,
        }

        const options = {
          id: props.gameId,
          type: props.clientType,
          elementId: 'game-container',
          language: props.language,
          lobbyUrl: url.format(
            needsRedirect ? toEmptyHtmlRedirect(lobbyUrl) : lobbyUrl
          ),
          config,
          betHistoryUrl: props.betHistoryUrl,
          realityCheckTimeRemaining: props.realityCheckTimeRemaining,
        }

        return isGameId
          ? fetchGame(options)
          : fetchLaunchParameters(
              { id: props.gameId, provider: props.provider },
              options.config
            )
      },
      storeGameProps: storeGameProps,
    },
    dispatch
  )
}

export const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)

import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

import { Notification as Component } from './notification'

function mapStateToProps(state, props) {
  const notificationsState = R.prop(props.mountPoint, state)

  return {
    notification: selectors.getOne(notificationsState, { id: props.id }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onDismiss: actions.dismiss,
    },
    dispatch
  )
}

export const Notification = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)

import * as R from 'ramda'

/**
 * Function for getting most recent field error
 * @param {object=} field
 * @param {string[]} field.errors
 * @param {bool} field.visited
 */
const fieldError = field =>
  field && field.visited && field.errors && R.head(field.errors)

export default fieldError

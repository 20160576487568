import * as I18n from '@rushplay/i18n'

export default function UiSchema() {
  const i18n = I18n.useI18n()

  return {
    type: 'Column',
    children: [
      {
        id: 'sofq-employment-info-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.employment.title',
      },
      {
        id: 'sofq-employment-info',
        type: 'Control',
        scope: '#/properties/employment',
      },
      {
        id: 'sofq-occupation-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.occupation.title',
        effects: [
          {
            effect: 'hide',
          },
          {
            effect: 'show',
            condition: {
              properties: {
                employment: {
                  contains: {
                    oneOf: [{ const: 'Full time' }, { const: 'Part time' }],
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: 'sofq-occupation',
        type: 'Control',
        scope: '#/properties/occupation',
        label: 'source-of-funds.occupation',
        effects: [
          {
            effect: 'hide',
          },
          {
            effect: 'show',
            condition: {
              properties: {
                employment: {
                  contains: {
                    oneOf: [{ const: 'Full time' }, { const: 'Part time' }],
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: 'sofq-sources-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.other-sources.title',
      },
      {
        id: 'sofq-other-sources-title',
        type: 'Text',
        size: 0,
        translationKey: 'source-of-funds.sources-of-income.additional-info',
      },
      {
        id: 'sofq-sources',
        type: 'Control',
        scope: '#/properties/otherSources',
      },
      {
        id: 'sofq-other-sources',
        type: 'Control',
        scope: '#/properties/otherSourcesField',
        label: 'source-of-funds.occupation.other',
        effects: [
          {
            effect: 'hide',
          },
          {
            effect: 'show',
            condition: {
              properties: {
                otherSources: {
                  contains: {
                    const: 'Other',
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: 'sofq-salary-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.salary.title',
      },
      {
        id: 'sofq-salary',
        type: 'Control',
        scope: '#/properties/salary',
      },
      {
        id: 'sofq-deposits-level-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.deposits-level.title',
      },
      {
        id: 'sofq-deposits-level-additional-info',
        type: 'Text',
        size: 0,
        color: 'white',
        translationKey: 'source-of-funds.deposits-level.additional-info',
      },
      {
        id: 'sofq-deposits-level',
        type: 'Control',
        scope: '#/properties/anticipatedYearlyDeposits',
      },
      {
        id: 'sofq-sources-of-founds-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.sources-of-founds.title',
      },
      {
        id: 'sofq-sources-of-founds-additional-info',
        type: 'Text',
        size: 0,
        translationKey: 'source-of-funds.sources-of-founds.additional-info',
      },
      {
        id: 'sofq-income',
        type: 'Control',
        scope: '#/properties/sourcesOfFunds',
      },
      {
        id: 'sofq-other-founds-other',
        type: 'Control',
        scope: '#/properties/sourcesOfFundsOtherField',
        label: 'source-of-funds.sources-of-founds.other',
        effects: [
          {
            effect: 'hide',
          },
          {
            effect: 'show',
            condition: {
              properties: {
                sourcesOfFunds: {
                  contains: {
                    const: 'Other',
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: 'sofq-funds-country-origin-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.funds-country-origin.title',
      },
      {
        id: 'sofq-funds-country-origin-additional-info',
        type: 'Text',
        size: 0,
        color: 'white',
        translationKey: 'source-of-funds.funds-country-origin.additional-info',
      },
      {
        id: 'sofq-funds-country-origin',
        type: 'Control',
        scope: '#/properties/fundsCountryOrigin',
      },
      {
        id: 'sofq-place-of-bith-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.nationality.title',
      },
      {
        id: 'sofq-nationality',
        type: 'Control',
        scope: '#/properties/nationality',
      },
      {
        id: 'sofq-place-of-bith-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.place-of-birth.title',
      },
      {
        id: 'sofq-place-of-birth',
        type: 'Control',
        scope: '#/properties/placeOfBirth',
      },
      {
        id: 'sofq-id-number-title',
        type: 'Text',
        size: 1,
        weight: 'bold',
        color: 'white',
        translationKey: 'source-of-funds.id-number.title',
      },
      {
        id: 'sofq-id-number',
        type: 'Control',
        label: 'source-of-funds.id-number',
        scope: '#/properties/idNumber',
      },
      {
        id: 'sofq-confirmation',
        type: 'Control',
        scope: '#/properties/confirmation',
      },
      {
        type: 'Submit',
        content: i18n.translate('source-of-funds.submit'),
      },
    ],
  }
}

import parseMs from 'parse-ms'

/**
 * Calculate remaining time respecting server time offset
 * @param {number} offset - Server time offset
 * @param {number} end - End of time range
 * @param {number} start - Start of time range
 */
export default function toRemainingTime(offset, end, start) {
  return parseMs(end + offset - start)
}

import * as R from 'ramda'
import * as ReactRouterRedux from 'react-router-redux'
import { connect } from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'
import * as Locks from '@rushplay/compliance/locks'
import * as Session from '@rushplay/session'
import { connectForm, selectors } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import { EmailVerification as Component } from './email-verification'

function mapStateToProps(state) {
  const verificationCode = selectors.value(state.forms, {
    form: 'email-verification',
    field: 'code',
  })

  return {
    playerEmail: Session.getEmail(state.session),
    verificationCode,
    formErrors: selectors.errors(state.forms, {
      form: 'email-verification',
      field: 'code',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onEnter: () => dispatch(Notifications.dismissAll()),
    onResendCode: (onSuccess) =>
      dispatch(
        Api.requestEmailVerificationCode({
          success: () => {
            onSuccess()
          },
          failure: () =>
            Notifications.add({
              message: 'errors.email-verification-code.resend.failed',
              level: 'error',
            }),
          version: 1,
        })
      ),
    onSubmit: (token) =>
      dispatch(
        Api.verifyEmail(token, {
          success: () => [
            Locks.fetch(),
            Locks.remove('email-verification'),
            ReactRouterRedux.push('/wallet/deposit'),
          ],
          failure: () =>
            Notifications.add({
              message: 'errors.email-verifications-code.invalid',
              level: 'error',
            }),
          version: 1,
        })
      ),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    playerEmail: stateProps.playerEmail,
    formErrors: stateProps.formErrors,
    onEnter: dispatchProps.onEnter,
    onSubmit: () => dispatchProps.onSubmit(stateProps.verificationCode),
    onResendCode: dispatchProps.onResendCode,
  }
}

const connector = R.compose(
  (c) => connectForm(c, { name: 'email-verification' }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslate
)

export const EmailVerification = connector(Component)

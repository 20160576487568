import * as R from 'ramda'

const timers = {}

/**
 * Middleware for debouncing redux actions
 * @param {Array} debounceCheckers - Each function in the array checks if action needs to be debounced
 * @returns {Function} Redux middleware
 * @example
 * applyMiddleware(
 *   debounceMiddleware([
 *     (action) => {key: 'unique-key', time:500}, // Will debounce
 *     (action) => undefined // Will not debounce
 *   ])
 * )
 */
export function debounceMiddleware(debounceCheckers = []) {
  return store => next => action => {
    const state = store.getState()
    const debounce = R.reduceWhile(
      R.isNil,
      (shouldDebounce, checker) => checker(action, state),
      undefined,
      debounceCheckers
    )

    if (!debounce) {
      return next(action)
    }

    if (typeof debounce.key !== 'string') {
      // eslint-disable-next-line no-console
      console.error(
        'Expected `key` to be a string. Action will be dispatched immediately.'
      )
      return next(action)
    }

    if (typeof debounce.time !== 'number') {
      // eslint-disable-next-line no-console
      console.error(
        'Expected `time` to be a number. Action will be dispatched immediately.'
      )
      return next(action)
    }

    if (timers[debounce.key]) {
      clearTimeout(timers[debounce.key])
    }

    timers[debounce.key] = setTimeout(() => next(action), debounce.time)
  }
}

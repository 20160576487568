/**
 * Truncate string to given length and append ellipsis
 * @param {number} limit Maximum allowed string length
 * @param {string} string The string to truncate
 * @returns {string} Truncated string if longer than limit; input otherwise.
 */
export function truncate(limit, string) {
  if (limit > string.length) {
    return string
  }

  return `${string.substring(0, limit)}...`
}

import * as R from 'ramda'
import { connect } from 'react-redux'

import * as casino from '@rushplay/casino'

import { isEmbeddingEnabled } from '../store/app'
import { mapUrl } from '../util/map-url'

import { GameIframeContainer } from './game-iframe-container'

function mapStateToProps(state, props) {
  const gameId = parseInt(R.path(['params', 'gameId'], props))
  const game = casino.selectors.getGame(state.casino, { id: gameId })

  const classicModeUrl = encodeURIComponent(
    `${window.location.origin}${R.replace(
      '/blitz',
      '/slots',
      window.location.pathname
    )}?referrer=blitz&mode=normal`
  )

  const src =
    game.provider.toLowerCase() === 'shock'
      ? mapUrl(
          R.assocPath(['query', 'blitz'], true),
          R.path(['options', 'iframeSrc'], game)
        )
      : `${R.path(
          ['launchUrl'],
          game.blitzProperties
        )}&classicModeUrl=${classicModeUrl}`

  return {
    embedding: isEmbeddingEnabled(state.app),
    launchMethod: 'iframe',
    referrer: R.pathOr('casino', ['location', 'query', 'referrer'], props),
    src,
  }
}

export default connect(mapStateToProps)(GameIframeContainer)

import * as R from 'ramda'
import React from 'react'
import { Route } from 'react-router'

import removeTrailingSlash from '../util/remove-trailing-slash'
import { injectAsyncReducers } from '../store'

export default function bossFight(root, requireAuth, store) {
  return (
    <Route
      getComponents={(nextState, cb) => {
        require.ensure(
          [],
          () => {
            const reducers = {
              bossfight: require('../store/bossfight').bossFightReducer,
            }

            injectAsyncReducers(store, reducers)

            cb(null, require('./boss-fight-container').default)
          },
          'boss-fight'
        )
      }}
      getChildRoutes={(location, cb) => {
        require.ensure(
          [],
          require => {
            const routes = [
              {
                path: 'menu',
                component: require('./menu/menu-container').default,
                childRoutes: [
                  {
                    path: 'paytable',
                    component: require('./paytable/paytable-container').default,
                  },
                ],
              },
              {
                path: 'game',
                component: require('./boss-fight-game-container').default,
                childRoutes: [
                  {
                    path: 'tutorial',
                    component: require('./tutorial/tutorial-container').default,
                  },
                  {
                    path: 'paytable',
                    component: require('./paytable/paytable-container').default,
                  },
                  {
                    path: 'treasures',
                    component: require('./loot-table/treasure-loot-table-container')
                      .default,
                  },
                  {
                    path: 'weapons',
                    component: require('./loot-table/weapon-loot-table-container')
                      .default,
                  },
                ],
              },
            ]

            cb(null, routes)
          },
          'boss-fight'
        )
      }}
      getIndexRoute={(location, cb) => {
        const pathname = R.path(['location', 'pathname'], location)
        const redirectTo = `${removeTrailingSlash(pathname)}/menu`

        const route = { onEnter: (nextState, replace) => replace(redirectTo) }

        cb(null, route)
      }}
      path={root}
      onEnter={requireAuth}
    />
  )
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space, toLocaleDate } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import InventoryTile from '../common/inventory-tile'
import Text from '../common/text'
import { dark, error } from '../constants/colors'
import { paragraph } from '../mixins'

const Title = styled.h1`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`

const Description = styled.p`
  ${paragraph};
  color: ${dark};
  margin-bottom: ${props => (props.expiresAt ? '15px' : '30px')};
`

const Expires = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 16px;
  align-items: center;
  color: #252525;
`

const ClockIconWrapper = styled.div`
  font-size: 22px;
  margin-right: 3px;
`

const Tile = styled(InventoryTile)`
  width: 160px;
  height: 160px;
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
`

/* wasItemBought :: Item -> Bool
 *
 * Items bought in the ruby store get "Bought XXX in the store" added
 * as a reason to them. This is currently the only way to tell if an
 * item was bought in the ruby store or not.
 */
function wasItemBought(item) {
  return /^Bought .+ in store$/.test(item.reason)
}

function getDescription(translate, item) {
  switch (item.type.toLowerCase()) {
    case 'freespins': {
      const key = `rubystore.item.description.${item.gameKey}`
      if (wasItemBought(item) && translate(key) !== key) {
        return translate(key)
      }
      return translate('rubystore.item.generic-description')
    }
    case 'rubies':
      return translate('inventory.rubies.description')
    case 'ppbooster':
      return translate('inventory.ppbooster.description')
    case 'moneyitem':
      return translate('inventory.real-money.description')
    case 'featuretriggers':
      if (item.zeroWagering) {
        return translate('inventory.bonus-game.description.no-wagering', {
          value: item.betAmountCents,
        })
      } else {
        return translate('inventory.bonus-game.description', {
          value: item.betAmountCents,
        })
      }
    case 'freebet':
      return translate('inventory.freebet.description')
    case 'freegamesvoucher':
      return translate('inventory.freegamesvoucher.description')
    default:
      return translate('inventory.bonus.description')
  }
}

function InventoryItemDetails(props) {
  if (!props.item) {
    return null
  }

  const tileAmount = props.item.amount || props.item.amountCents / 100 || null
  const tileImage = props.item.gameImage || props.imageUrl

  return (
    <Flex center column>
      <Tile
        amount={tileAmount}
        betAmountCents={props.item.betAmountCents}
        blitzMode={props.item.blitz}
        image={tileImage}
        value={props.item.heroSpinType}
        duration={props.duration}
        multiplier={props.item.multiplier}
        itemType={props.item.type}
        itemLabel={props.itemLabel}
        itemSubLabel={props.itemSubLabel}
        enlarged
      />

      <Title>
        {props.itemTitle} {props.item.blitz && ' (Blitz)'}
      </Title>

      <Description expiresAt={props.item.expiresAt}>
        {getDescription(props.translate, props.item)}
      </Description>

      {props.item.expiresAt && (
        <Expires>
          <ClockIconWrapper>
            <Icon name="clock-o" />
          </ClockIconWrapper>
          <HtmlContent
            html={{
              __html: props.translate(
                'rubystore.item.expires',
                toLocaleDate(props.item.expiresAt, props.language)
              ),
            }}
          />
        </Expires>
      )}

      {props.item.gameDisabledForClientType && (
        <Space bottom="20">
          <Text bold color={error}>
            {props.translate(
              `inventory.useSpins.invalid-client-type.${props.clientType}`
            )}
          </Text>
        </Space>
      )}

      <Button
        stretch
        onClick={props.onUse}
        loading={props.useItemInProgress}
        disabled={props.item.gameDisabledForClientType}
      >
        {props.translate('inventory.useSpins')}
      </Button>
    </Flex>
  )
}

InventoryItemDetails.propTypes = {
  clientType: PropTypes.string,
  duration: PropTypes.string,
  imageUrl: PropTypes.string,
  item: PropTypes.object,
  itemLabel: PropTypes.string,
  itemSubLabel: PropTypes.string,
  itemTitle: PropTypes.string,
  language: PropTypes.string,
  translate: PropTypes.func.isRequired,
  useItemInProgress: PropTypes.bool.isRequired,
  onUse: PropTypes.func.isRequired,
}

export default withTranslate(InventoryItemDetails)

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import uuid4 from 'uuid'
import { timingFunctions } from 'polished'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Icon from '../common/icon/icon'
import { disabledGray } from '../constants/colors'
import { textFont } from '../constants/typography'

const colors = {
  dark40: '#909090',
  dark60: '#c3c3c3',
  error: '#d0011b',
  errorLight: '#ff4f65',
  night: '#183363',
  oceanLight: '#46c0f4',
  success: '#84b41f',
  black: '#000000',
}

function boxShadow(props) {
  if (!props.active) {
    return 'none'
  }

  return props.dark ? '0 0 8px 2px #209ace' : '0 2px 4px 0 rgba(0, 0, 0, .20)'
}

function inputBorderColor(props) {
  if (!props.empty) {
    if (props.valid) {
      return colors.success
    }
  }

  if (props.processing) {
    return colors.night
  }

  if (props.visited && !props.valid) {
    return props.dark ? colors.errorLight : colors.error
  }

  if (props.isLoginPage) {
    return colors.dark60
  }

  if (props.dark) {
    return 'white'
  }

  if (props.active) {
    return colors.night
  }

  return colors.dark60
}

function iconName(valid, empty) {
  if (valid) {
    return empty ? 'info' : 'check'
  }

  return 'alert'
}

function inputPadding(props) {
  if (!props.small && !props.externalLabel) {
    return '11px 19px'
  }

  if (props.currency) {
    return '18px 12px'
  }

  return '12px'
}

function toolTipColor(props) {
  if (props.valid) {
    if (props.empty) {
      return props.dark ? colors.oceanLight : colors.night
    }

    return colors.success
  }

  return props.dark ? colors.errorLight : colors.error
}

const Wrapper = styled.div`text-align: left;`

const InputFieldWrapper = styled.div`
  padding: ${inputPadding};
  text-align: left;
  display: flex;
  box-sizing: border-box;
  background-color: ${props => (props.disabled ? disabledGray : 'white')};
  transition: border 0.3s ${timingFunctions('easeOutQuart')};
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid ${inputBorderColor};
  box-shadow: ${boxShadow};
  overflow: hidden;
`

const BaseLabel = styled.label`
  color: ${colors.dark40};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: ${textFont};
`

const FloatingLabel = styled(BaseLabel)`
  font-size: 16px;
  ${props => props.active && 'transform: translateY(-100%) scale(0.8, 0.8);'};
  transform-origin: left top;
  transition: transform 0.3s ${timingFunctions('easeOutCubic')};
`

export const ExternalLabel = styled.label`
  color: ${colors.black};
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  pointer-events: none;
  font-family: ${textFont};
  padding-top: 10px;
  padding-bottom: 10px;
`

const Input = styled.input`
  margin: initial;
  padding: initial;
  ${props =>
    props.externalLabel
      ? 'padding: initial;'
      : props.small ? 'padding-left: 4px;' : 'padding-top: 18px;'};
  ${props =>
    props.disabled &&
    css`
      background-color: ${disabledGray};
      color: ${colors.dark40};
    `};
  box-sizing: border-box;
  width: 100%;
  font-size: ${props => (props.currency ? 19 : 16)}px;
  font-family: ${textFont};
  text-align: ${props => (props.centered ? 'center' : 'left')};
  border: none;
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0px 9999px white;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${colors.dark40};
  }
`

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
`

const Tooltip = styled.p`
  text-align: left;
  max-height: ${props => (props.displayTooltip ? '100px' : '0')};
  min-height: ${props => (props.displayTooltip ? '15px' : 'auto')};
  opacity: ${props => (props.displayTooltip ? 1 : 0)};
  overflow: hidden;
  transition: max-height 0.8s ${timingFunctions('easeInOutQuart')},
    opacity 0.5s ${timingFunctions('easeInOutQuart')};
  font-size: 13px;
  font-family: ${textFont};
  margin-top: 0.438em;
  margin-bottom: -.438em;
  color: ${toolTipColor};
  word-break: break-word;
`

const TooltipIcon = styled(Icon)`
  font-size: 13px;
  margin-right: 0.3em;
  color: ${props =>
    props.valid && !props.empty ? colors.success : 'currentColor'};
`

const PeekIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -.5em;
  font-size: 20px;
  color: ${colors.dark40};
  cursor: pointer;
`

const CurrencyIcon = styled(Icon)`
  font-size: 19px;
  color: ${colors.dark40};
`

class InputField extends Component {
  constructor() {
    super()
    this.uuid = uuid4()
    this.state = { type: 'password' }
    this.handlePasswordToggle = this.handlePasswordToggle.bind(this)
  }

  componentWillMount() {
    // eslint-disable-next-line react/no-set-state
    this.setState({ type: this.props.type })
  }

  componentDidMount() {
    if (this.props.focused && this.node) {
      // focus input on next tick to make browser switch focus from
      // element which triggered the actual event
      this.timer = setTimeout(() => this.node && this.node.focus(), 1)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focused && this.props.focused && this.node) {
      this.node.focus()
    }
  }

  handlePasswordToggle() {
    // eslint-disable-next-line react/no-set-state
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    })
  }

  render() {
    const isSmall = this.props.currency || this.props.small
    const hasExternalLabel = this.props.hasExternalLabel
    const empty = this.props.value === ''
    const active = !empty || this.props.focused
    const processing = this.props.status == 'processing'
    const valid = this.props.status === 'valid'
    const visuallyValid = !this.props.visited || valid
    const displayTooltip =
      (!visuallyValid || this.props.focused) && this.props.tooltip
    const internalLabel = isSmall ? this.props.label : ''
    const placeholder = hasExternalLabel
      ? this.props.placeholder
      : internalLabel

    return (
      <Wrapper>
        {hasExternalLabel ? (
          <ExternalLabel>{this.props.label}</ExternalLabel>
        ) : null}
        <InputFieldWrapper
          className={this.props.className}
          active={this.props.focused}
          currency={this.props.currency}
          dark={this.props.dark}
          empty={empty}
          processing={processing}
          small={isSmall}
          externalLabel={hasExternalLabel}
          valid={valid}
          visited={this.props.visited}
          disabled={this.props.disabled}
          isLoginPage={this.props.isLoginPage}
        >
          {this.props.currency && <CurrencyIcon name={this.props.currency} />}
          <InputWrapper>
            {hasExternalLabel ? null : (
              !isSmall && (
                <FloatingLabel
                  active={!empty || active}
                  currency={this.props.currency}
                  empty={empty}
                  htmlFor={this.uuid}
                >
                  {this.props.label}
                </FloatingLabel>
              )
            )}
            <Input
              autoFocus={this.props.autoFocus}
              autoCapitalize={this.props.autoCapitalize}
              autoCorrect={this.props.autoCorrect}
              autoComplete={this.props.autoComplete}
              centered={this.props.centered}
              currency={this.props.currency}
              disabled={this.props.disabled}
              id={this.uuid}
              ref={node => (this.node = node)}
              max={this.props.max}
              min={this.props.min}
              name={this.props.name}
              small={isSmall}
              externalLabel={hasExternalLabel}
              type={this.state.type}
              value={this.props.value}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              placeholder={placeholder}
            />
            {this.props.type === 'password' && (
              <PeekIcon
                name={this.state.type === 'text' ? 'eye-slash' : 'eye'}
                onClick={this.handlePasswordToggle}
              />
            )}
          </InputWrapper>
        </InputFieldWrapper>
        {!processing &&
        (this.props.tooltip || !visuallyValid) && (
          <Tooltip
            dark={this.props.dark}
            displayTooltip={displayTooltip}
            empty={empty}
            valid={visuallyValid}
          >
            <TooltipIcon
              empty={empty}
              name={iconName(visuallyValid, empty)}
              valid={visuallyValid}
            />
            {this.props.tooltip}
          </Tooltip>
        )}
      </Wrapper>
    )
  }
}

export default InputField

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoCapitalize: PropTypes.oneOf(['none', 'characters', 'words', 'sentences']),
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoComplete: PropTypes.oneOf(['on', 'off']),
  centered: PropTypes.bool,
  currency: PropTypes.oneOf(['eur', 'sek', 'usd', 'gbp']),
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  ref: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  status: PropTypes.oneOf(['invalid', 'valid', 'processing']),
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']).isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  isLoginPage: PropTypes.bool,
  hasExternalLabel: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
}

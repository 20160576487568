/**
 * Currency definition with hints for proper rendering.
 * @typedef {Object} CurrencyDefinition
 * @property {string} code
 *   Currency code according to ISO 4217.
 * @property {number} ratio
 *   Amount of cents in a single unit.
 * @property {boolean} [customCurrency]
 *   Whether currency is regular or our custom created
 */

/**
 * Great British Pound currency definition
 * @name GBP
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const GBP = {
  code: 'GBP',
  customCurrency: false,
  ratio: 100,
  symbol: '£',
}

/**
 * Euro currency definition
 * @name EUR
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const EUR = {
  code: 'EUR',
  customCurrency: false,
  ratio: 100,
  symbol: '€',
}

/**
 * Norwegian krone currency definition
 * @name NOK
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const NOK = {
  code: 'NOK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

/**
 * Swedish krona currency definition
 * @name SEK
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const SEK = {
  code: 'SEK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

/**
 * United States dollar currency definition
 * @name USD
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const USD = {
  code: 'USD',
  customCurrency: false,
  ratio: 100,
  symbol: '$',
}

/**
 * Danish krona currency definition
 * @name DKK
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const DKK = {
  code: 'DKK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

/**
 * Indian rupee currency definition
 * @name INR
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const INR = {
  code: 'INR',
  customCurrency: false,
  ratio: 100,
  symbol: '₹',
}

/**
 * Canadian currency definition
 * @name CAD
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const CAD = {
  code: 'CAD',
  customCurrency: false,
  ratio: 100,
  symbol: 'C$',
}

/**
 * Millibitcoin currency definition
 * @name mBTC
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const mBTC = {
  code: 'mBTC',
  customCurrency: true,
  ratio: 100,
  symbol: 'm₿',
}

/**
 * Microbitcoin currency definition
 * We're getting uBTC (microbitcoin) cents from BE and converting it
 * to mBTC (millibitcoin)
 * @name uBTC
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const uBTC = {
  code: 'uBTC',
  customCurrency: true,
  ratio: 100000,
  symbol: 'm₿',
}

/**
 * Japaneese Yen currency definition
 * @name JPY
 * @kind constant
 * @type {CurrencyDefinition}
 */
export const JPY = {
  code: 'JPY',
  customCurrency: false,
  ratio: 100,
  symbol: '¥',
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Icon from '../../common/icon/icon'

const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;

  display: flex;
  align-items: center;
  height: 50px;
  background-color: #06193c;
`

const Username = styled.span`
  position: relative;
  color: white;
  font-size: 16px;
  text-align: center;
  width: calc(100% - 50px);
  text-overflow: ellipsis;
  overflow-x: hidden;
  line-height: initial;

  @media screen and (max-width: 1279px) {
    width: calc(100% - 74px);
  }
`

const AvatarWrapper = styled.div`
  width: 44px;
  height: 44px;
  padding-left: 3px;
  padding-right: 6px;
`

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50%;
`

const NotYouLink = styled.span`
  display: block;
  color: ${props => (props.isLoginPage ? '#366ac7' : 'white')};
  font-size: 0.75em;
  position: absolute;
  right: 0;
  bottom: ${props => (props.isLoginPage ? '-26' : '-14')}px;
  cursor: pointer;

  @media screen and (max-width: 1279px) {
    display: none;
  }
`

const IconWrapper = styled.span`
  display: none;

  @media screen and (max-width: 1279px) {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 28px;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`

function ReturningUsernameField(props) {
  return (
    <Wrapper isLoginPage={props.isLoginPage}>
      <AvatarWrapper isLoginPage={props.isLoginPage}>
        <Avatar src={props.returningUserAvatar} />
      </AvatarWrapper>
      <Username>{props.value}</Username>
      <IconWrapper onClick={props.onClear}>
        <Icon name="edit" />
      </IconWrapper>
      <NotYouLink onClick={props.onClear} isLoginPage={props.isLoginPage}>
        {props.translate('login-form.not-you')}
      </NotYouLink>
    </Wrapper>
  )
}

ReturningUsernameField.propTypes = {
  returningUserAvatar: PropTypes.string,
  translate: PropTypes.func,
  value: PropTypes.string,
  onClear: PropTypes.func,
  isLoginPage: PropTypes.bool,
}

export default withTranslate(ReturningUsernameField)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import PageNav from '../../common/page-nav'
import ProgressionContainer from '../../common/progression/progression-container'
import SearchGamesInput from '../search-games/search-games-input'
import SearchGamesResult from '../search-games/search-games-result'

const Wrapper = styled.div`
  overflow: hidden;
`

const Children = styled.div`
  ${(props) => props.hide && 'display:none;'};
`

function translateLinks(translate, links) {
  return R.map(
    R.pipe(
      R.assoc('onlyActiveOnIndex', true),
      R.over(R.lensProp('label'), translate)
    ),
    links
  )
}

function scratchCategory(links, license) {
  return R.reject(
    (link) => link.label === 'casino.nav.scratch' && license === 'sga',
    links
  )
}

export function Header(props) {
  const i18n = useI18n()

  if (props.embedding) {
    return props.children
  }

  const searchParams = new URLSearchParams(props.location.search)
  const searchQuery = searchParams.get('search') || ''
  const isSearchValid = searchQuery.length > 1

  function handleClear() {
    props.router.replace(props.location.pathname)
  }

  function handleChange(value) {
    if (!value) {
      return props.router.replace(props.location.pathname)
    }
    const searchParams = new URLSearchParams({ search: value }).toString()
    props.router.replace(`${props.location.pathname}?${searchParams}`)
  }

  return (
    <Wrapper>
      <ProgressionContainer />
      {props.showNav && (
        <React.Fragment>
          <PageNav
            items={translateLinks(
              i18n.translate,
              scratchCategory(props.links, props.license),
              props.links
            )}
          >
            <SearchGamesInput
              value={searchQuery}
              validSearch={isSearchValid}
              onClear={handleClear}
              onChange={handleChange}
            />
          </PageNav>
          {isSearchValid && (
            <SearchGamesResult
              searchQuery={searchQuery}
              tagPrefix={props.tagPrefix}
            />
          )}
        </React.Fragment>
      )}
      <Children hide={isSearchValid && props.showNav}>
        {props.children}
      </Children>
    </Wrapper>
  )
}

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  children: PropTypes.node,
  embedding: PropTypes.bool,
  license: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  searchQuery: PropTypes.string,
  showSearchResult: PropTypes.bool,
  showNav: PropTypes.bool,
  tagPrefix: PropTypes.func,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import URL from 'url'
import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import format from 'date-fns/format'
import screenfull from 'screenfull'
import throttle from 'lodash.throttle'
import { connect } from 'react-redux'
import { locationShape } from 'react-router/lib/PropTypes'
import { push } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as casino from '@rushplay/casino'
import styled from '@emotion/styled'
import { Link, documentOffsetTop, withCurrentTime } from '@rushplay/common'
import { fetchGame } from '@rushplay/api-client'
import { isSessionActive } from '@rushplay/session'

import Icon from '../common/icon/icon'
import { PageSpinner } from '../common/spinner'
import { SeoTextArea } from '../components/seo-text-area'
import {
  getClientType,
  getLanguage,
  isEmbeddingEnabled,
  toggleEmbedding,
  toggleFullscreen,
} from '../store/app'
import { night, nightDark } from '../constants/colors'

import BalanceTypeChangePopupContainer from './balance-type-change-popup'
import LegalNote from './legal-note'
import ModeSelector from './blitz/mode-selector'
import RealityCheckPopupContainer from './reality-check-popup-container'
import blitzLogo from './blitz/blitz-logo-contained.svg'

const EmbedWrapper = styled.div`
  all: initial;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: black;

  @media (orientation: landscape) {
    flex-direction: row;
    align-items: stretch;
  }

  /* We assume game container to be last child */
  & > :last-child {
    width: auto !important;
    height: auto !important;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
`

const Time = styled.div`
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: ${nightDark};
`

const FullScreenButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  background-color: ${night};
  border: none;
`

const BlitzButton = styled.div`
  width: 95px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  background-image: url('${blitzLogo}');
  background-size: cover;
  background-color: transparent;
`

const FullscreenWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  display: flex;
`

const GameWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-bottom: calc((9 / 16) * 100%);

  padding-bottom: ${(props) =>
    calculatePaddingBottom(props.offsetTop, props.game)};
  max-width: ${(props) => calculateMaxWidth(props.offsetTop, props.game)};

  ${(props) => props.isFullscreen && 'margin: 70px auto 0;'};
`

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  /* We assume game container to be last child */
  & > :last-child {
    width: auto !important;
    height: auto !important;
    flex-grow: 1;
    overflow: hidden;
  }
`

function calculateRawMaxWidth(offsetTop, game) {
  const { innerHeight: height, innerWidth: width } = window
  return (height - offsetTop) / game.height / (width / game.width)
}

function calculateMaxWidth(offsetTop, game) {
  return `${calculateRawMaxWidth(offsetTop, game) * 100}%`
}

function calculatePaddingBottom(offsetTop, game) {
  const maxWidth = calculateRawMaxWidth(offsetTop, game)
  const paddingBottom = game.height / game.width

  if (maxWidth < 1) {
    return `${paddingBottom * maxWidth * 100}%`
  }

  return `${paddingBottom * 100}%`
}

function toGameRedirectPage(urlObj) {
  return R.mergeRight(urlObj, {
    pathname: 'casino/game-callback.html',
    query: {
      redirect: `/${urlObj.pathname}`,
    },
  })
}

function shouldEmbed(state, props) {
  const gameId = parseInt(R.path(['params', 'gameId'], props))
  const game = casino.selectors.getGame(state.casino, { id: gameId }) || {}
  const blitzEnabled = game.blitzEnabled || R.not(R.isNil(game.blitzProperties))
  const clientType = getClientType(state.app)

  const isDesktopBrowser = clientType === 'browser'

  const isMissingMode =
    blitzEnabled && R.isNil(R.path(['query', 'mode'], props.location))

  // TODO: move to Redux state?
  const isStandalone = window.top === window

  return isStandalone && !isDesktopBrowser && !isMissingMode
}

class GameContainer extends Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
    this.handleResize = throttle(this.handleResize.bind(this), 100)
    this.handleScroll = debounce(this.handleScroll.bind(this), 100)
    this.toggleFullScreen = this.toggleFullScreen.bind(this)
    this.state = { offsetTop: 0 }

    this.gameWrapperRef = React.createRef()
  }

  componentWillMount() {
    const config = {
      success: (res) =>
        casino.actions.storeGameProps(this.props.gameId, res.value),
      failure: (res) =>
        Notifications.add({ message: res.value, level: 'error' }),
      version: 2,
    }

    const baseUrlObj = URL.parse(window.location.href, false, true)

    const referrer = R.path(['query', 'referrer'], this.props.location)
    const freeGames = R.path(['query', 'free_games'], this.props.location)

    const lobbyUrl = toGameRedirectPage({
      protocol: baseUrlObj.protocol,
      host: baseUrlObj.host,
      pathname: `${this.props.language}/${referrer}`,
    })

    const betHistoryUrl = toGameRedirectPage({
      protocol: baseUrlObj.protocol,
      host: baseUrlObj.host,
      pathname: `${this.props.language}/dashboard/history`,
    })

    const limitsUrl = toGameRedirectPage({
      protocol: baseUrlObj.protocol,
      host: baseUrlObj.host,
      pathname: `${this.props.language}/dashboard/limits`,
    })

    const origin = URL.format({
      protocol: baseUrlObj.protocol,
      host: baseUrlObj.host,
    })

    const options = {
      id: this.props.gameId,
      type: this.props.clientType,
      elementId: 'pnggame',
      language: this.props.language,
      lobbyUrl: URL.format(lobbyUrl),
      origin,
      config,
      betHistoryUrl: URL.format(betHistoryUrl),
      limitsUrl: URL.format(limitsUrl),
      realityCheckTimeRemaining: this.props.realityCheckTimeRemaining,
      freeGames,
    }

    this.props.onFetchGame(options)
  }

  componentDidMount() {
    if (this.props.embeddable) {
      this.props.onEmbeddingStart()
    }
    window.addEventListener('message', this.handleMessage)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    if (this.props.embeddable) {
      window.removeEventListener('scroll', this.handleScroll)
      this.props.onEmbeddingStop()
    }
    window.removeEventListener('message', this.handleMessage)
    window.removeEventListener('resize', this.handleResize)
    this.props.onClearGameOptions(this.props.game.id)
  }

  componentDidUpdate(prevProps) {
    if (R.isNil(prevProps.game.options) && this.props.game.options) {
      this.handleResize()
    }
    if (
      R.path(['query', 'mode'], prevProps.location) !==
      R.path(['query', 'mode'], this.props.location)
    ) {
      this.handleResize()
    }
    if (prevProps.embeddable !== this.props.embeddable) {
      if (this.props.embeddable) {
        this.props.onEmbeddingStart()
        window.addEventListener('scroll', this.handleScroll, { passive: true })
      } else {
        window.removeEventListener('scroll', this.handleScroll)
        this.props.onEmbeddingStop()
      }
    }
  }

  handleMessage(event) {
    const referrer = R.pathOr(
      'casino',
      ['query', 'referrer'],
      this.props.location
    )

    if (event.data.type === '@rushplay/game-callback/REDIRECT') {
      if (event.data.payload) {
        this.props.onRouterPush(decodeURIComponent(event.data.payload))
      } else {
        this.props.onRouterPush(`/${referrer}`)
      }
    }
  }

  handleScroll() {
    window.scroll(1, 0)
  }

  toggleFullScreen() {
    if (screenfull.enabled && typeof this.node !== 'undefined') {
      screenfull.toggle(this.node)
      this.handleResize()
      this.props.onToggleFullScreen()
    }
  }

  handleResize() {
    this.setState({ offsetTop: documentOffsetTop(this.node) })
  }

  render() {
    if (this.props.disallowFunMode) {
      return null
    }

    if (R.isNil(this.props.game.options)) {
      return <PageSpinner />
    }

    const blitzLink = `/casino/blitz/${R.pathOr(
      '',
      ['provider'],
      this.props.game
    ).toLowerCase()}/${this.props.gameId}/${this.props.game.key}`

    const blitzEnabled =
      this.props.game.blitzEnabled ||
      R.not(R.isNil(this.props.game.blitzProperties))

    if (
      blitzEnabled &&
      R.isNil(R.path(['query', 'mode'], this.props.location))
    ) {
      const getQuery = (mode) =>
        R.isEmpty(this.props.location.search)
          ? `?mode=${mode}`
          : `${this.props.location.search}&mode=${mode}`

      return (
        <ModeSelector
          authenticated={this.props.authenticated}
          blitzLink={`${blitzLink}${getQuery('blitz')}`}
          normalLink={`${this.props.location.pathname}${getQuery('normal')}`}
          gameKey={this.props.game.key}
          gameLogo={R.path(
            ['blitzProperties', 'logotypeImageUrl'],
            this.props.game
          )}
          gameColors={R.path(
            ['blitzProperties', 'elementsColor'],
            this.props.game
          )}
        />
      )
    }

    if (this.props.game.launchMethod === 'redirect') {
      const { iframeSrc, scriptSrc } = R.pathOr(
        {},
        ['options'],
        this.props.game
      )
      window.location = iframeSrc || scriptSrc
      return null
    }

    if (this.props.embedded) {
      return (
        <React.Fragment>
          <EmbedWrapper>
            <RealityCheckPopupContainer />
            {this.props.children}
          </EmbedWrapper>
          {this.props.licensed && <LegalNote autoHideAfter={10000} />}
          <BalanceTypeChangePopupContainer
            openOnChange={
              !R.includes(this.props.game.provider, [
                'Evolution',
                'Pushgaming',
                'Quickspin',
                'Yggdrasil',
              ])
            }
            openOnLaunch={!R.includes(this.props.game.provider, ['Playngo'])}
          />
        </React.Fragment>
      )
    }

    /* Hardcoding offsetTop to match fullscreen margin-top
     * This is needed because offsetTop originally is calculated for
     * non-fullscreen view using all offset parents up to <html>
     * See util/document-offset-top
     */
    const offsetTop = this.props.isFullscreen ? 70 : this.state.offsetTop

    const isClassicMode = /casino\/slots/.test(
      R.pathOr('', ['pathname'], this.props.location)
    )

    return (
      <React.Fragment>
        <Wrapper
          ref={(node) => {
            this.node = node
          }}
        >
          {this.props.time && <Time>{this.props.time}</Time>}
          <FullscreenWrapper>
            <div className="popupRef" ref={this.gameWrapperRef} />
            {blitzEnabled && isClassicMode && this.props.authenticated && (
              <Link to={`${blitzLink}?mode=blitz`}>
                <BlitzButton />
              </Link>
            )}
            <FullScreenButton onClick={this.toggleFullScreen}>
              <Icon name={this.props.isFullscreen ? 'times' : 'arrows-alt'} />
            </FullScreenButton>
          </FullscreenWrapper>
          <GameWrapper
            game={this.props.game}
            isFullscreen={this.props.isFullscreen}
            offsetTop={offsetTop}
          >
            <Content id="gameContainer">
              {this.props.children}
              {this.props.licensed && <LegalNote autoHideAfter={10000} />}
              <RealityCheckPopupContainer
                parentNode={this.gameWrapperRef.current}
              />
            </Content>
          </GameWrapper>
          <BalanceTypeChangePopupContainer
            openOnChange={
              !R.includes(this.props.game.provider, [
                'Evolution',
                'Pushgaming',
                'Quickspin',
                'Yggdrasil',
              ])
            }
            openOnLaunch={!R.includes(this.props.game.provider, ['Playngo'])}
          />
        </Wrapper>
        <SeoTextArea
          translationKey={`casino-slots-${this.props.game.provider.toLowerCase()}-${this.props.game.key.toLowerCase()}-page.seo-content`}
        />
      </React.Fragment>
    )
  }
}

GameContainer.defaultProps = {
  game: {
    provider: '',
    key: '',
  },
}

function mapStateToProps(state, ownProps) {
  const gameId = parseInt(R.path(['params', 'gameId'], ownProps))
  const game = casino.selectors.getGame(state.casino, { id: gameId })
  const authenticated = isSessionActive(state.session)
  const embedded = isEmbeddingEnabled(state.app)
  const isFullscreen = state.app.isFullscreen
  const isMGA = R.equals('mga', jurisdiction.getLicense(state.jurisdiction))
  const isUKGC = R.equals('ukgc', jurisdiction.getLicense(state.jurisdiction))

  return {
    authenticated,
    clientType: getClientType(state.app),
    disallowFunMode: isUKGC && R.not(authenticated),
    embeddable: shouldEmbed(state, ownProps),
    embedded,
    game,
    gameId,
    isFullscreen,
    language: getLanguage(state.app),
    licensed: isMGA && /microgaming|betsoft/i.test(game && game.provider),
    time:
      authenticated && isFullscreen
        ? format(ownProps.currentTime, 'HH:mm')
        : '',
  }
}

GameContainer.propTypes = {
  authenticated: PropTypes.bool,
  children: PropTypes.node,
  clientType: PropTypes.string,
  currentTime: PropTypes.number,
  disallowFunMode: PropTypes.bool,
  embeddable: PropTypes.bool,
  embedded: PropTypes.bool,
  game: PropTypes.object,
  gameId: PropTypes.number.isRequired,
  isFullscreen: PropTypes.bool,
  language: PropTypes.string,
  licensed: PropTypes.bool,
  location: locationShape,
  realityCheckTimeRemaining: PropTypes.number,
  time: PropTypes.string,
  onClearGameOptions: PropTypes.func.isRequired,
  onEmbeddingStart: PropTypes.func.isRequired,
  onEmbeddingStop: PropTypes.func.isRequired,
  onFetchGame: PropTypes.func.isRequired,
  onRouterPush: PropTypes.func.isRequired,
  onToggleFullScreen: PropTypes.func.isRequired,
}

export { GameContainer }

export default withCurrentTime(
  connect(mapStateToProps, {
    onClearGameOptions: casino.actions.clearGameOptions,
    onEmbeddingStart: () => toggleEmbedding(true),
    onEmbeddingStop: () => toggleEmbedding(false),
    onFetchGame: fetchGame,
    onRouterPush: push,
    onToggleFullScreen: toggleFullscreen,
  })(GameContainer)
)

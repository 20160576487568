/**
* Concatenates a duration object into a single string, with translation.
*
* duration: {
*   days: 10,
*   hours: 11,
*   minutes: 55
* }
*
* -> '10d 11h 55m'
*/
export default function getDurationString(duration, translate) {
  return `${duration.days != 0
    ? `${duration.days}${translate('inventory.pp-booster.duration.d')} `
    : ''}${duration.hours != 0
    ? `${duration.hours}${translate('inventory.pp-booster.duration.h')} `
    : ''}${duration.minutes != 0
    ? `${duration.minutes}${translate('inventory.pp-booster.duration.m')}`
    : ''}`
}

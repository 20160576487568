import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { color, compose, system, typography } from 'styled-system'
import { useTheme } from 'emotion-theming'

import * as t from '@rushplay/theme'
import propTypes from '@styled-system/prop-types'
import styled from '@emotion/styled'
import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop'

import { logLegacyPropsWarnings } from './internal-helpers/index'

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'align',
  'as',
  'cursor',
  'decoration',
  'font',
  'size',
  'weight',
])

/**
 * Render a Text component
 * @param {Object} props
 * @param {CssStringValues} props.align Control CSS `text-align` value
 * @param {CssStringValues} props.backgroundColor Control CSS `background-color` value
 * @param {CssStringValues} props.bg Control CSS `background-color` value
 * @param {CssStringValues} props.color Control CSS `color` value
 * @param {CssStringValues} props.decoration Control CSS `text-decoration` value
 * @param {CssStringValues} props.font Control CSS `font-family` value
 * @param {CssStringValues} props.fontStyle Control CSS `font-style` value
 * @param {CssNumberLikeValues} props.lineHeight Control CSS `line-height` value
 * @param {CssNumberLikeValues} props.opacity Control CSS `opacity` value
 * @param {CssNumberLikeValues} props.size Control CSS `font-size` value
 * @param {CssStringValues} props.textDecoration Control CSS `text-decoration` value
 * @param {CssStringValues} props.textTransform Control CSS `text-transform` value
 * @param {CssNumberLikeValues} props.weight Control CSS `font-weight` value
 * @return {ReactComponent}
 * @example
 * <Text weight={600} color="white" align="center">
 *    Register
 * </Text>
 */

const NextText = styled('div', { shouldForwardProp })(
  compose(
    color,
    typography,
    system({
      cursor: true,
      textDecoration: true,
      textTransform: true,
    })
  )
)

/**
 * Ugly backwards-compatibility hack
 *
 * We must remove properties that has undefined as value to avoid wiping out the actual value on merge
 *
 * @param {*} props
 */
function getLegacyProps(props) {
  return R.reject(R.isNil, {
    color: props.color && t.color(props.color)(props),
    fontFamily: props.font && t.font(props.font)(props),
    fontSize: props.size && t.fontSize(props.size)(props),
    fontWeight: props.weight,
    textAlign: props.align,
    textDecoration: props.decoration,
  })
}

export function Text(props) {
  useEffect(() => logLegacyPropsWarnings(props), [])

  const theme = useTheme()
  props = R.assoc('theme', theme, props)

  return <NextText {...getLegacyProps(props)} {...props} />
}

Text.propTypes = {
  ...propTypes.color,
  ...propTypes.typography,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  decoration: PropTypes.oneOf([
    'none',
    'underline',
    'overline',
    'line-through',
  ]),
  font: PropTypes.string,
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

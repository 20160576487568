import * as R from 'ramda'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

const MAX_DIFFICULTY = 3
const getDifficultyLevel = R.pipe(R.prop('level'), R.min(MAX_DIFFICULTY))
const repeatStars = R.pipe(R.repeat('★'), R.join(' '))

const Difficulty = styled.div`
  margin-left: -.15em;
  margin-right: -.15em;
  color: rgba(255, 255, 255, .5);

  &:before,
  &:after {
    margin-left: .15em;
    margin-right: .15em;
  }

  &:before {
    content: '${R.pipe(getDifficultyLevel, repeatStars)}';
    color: white;
  }

  &:after {
    content: '${R.pipe(
      getDifficultyLevel,
      R.subtract(MAX_DIFFICULTY),
      repeatStars
    )}';
  }
`

Difficulty.propTypes = {
  difficulty: PropTypes.number,
}

Difficulty.defaultProps = {
  difficulty: 0,
}

export default Difficulty

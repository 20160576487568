import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import { css } from '@emotion/core'

import Card from './card'
import HtmlContent from './html-content'
import Icon from './icon/icon'
import Text from './text'

const variants = {
  info: {
    primary: 'oceanDark',
    secondary: 'lightBlue',
    icon: 'info',
  },
  success: {
    primary: 'successPrimary',
    secondary: 'successSecondary',
    icon: 'check',
  },
  warning: {
    primary: 'warningPrimary',
    secondary: 'warningSecondary',
    icon: 'alert',
  },
  error: {
    primary: 'error',
    secondary: 'errorLight',
    icon: 'alert',
  },
}

function Toast(props) {
  const variant = variants[props.variant]
  const [text, setText] = React.useState(null)

  React.useEffect(() => {
    if (typeof props.text === 'object') {
      setText(props.text.error || props.text.message)
    } else if (typeof props.text === 'string') {
      setText(props.text)
    }
  }, [props.text])

  return (
    <Card
      color={variant.primary}
      backgroundColor={variant.secondary}
      borderRadius="0"
    >
      <Common.Space x={6} y="10px">
        <Common.Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Common.Box
            marginRight="8px"
            style={css`
              & > svg {
                height: 2em;
                width: 2em;
              }
            `}
          >
            <Icon name={variant.icon} />
          </Common.Box>
          <Text align="center">
            {text ? <HtmlContent html={{ __html: text }} /> : props.children}
          </Text>
          {props.onDismiss && (
            <Common.Box marginLeft="8px">
              <Icon name="times" onClick={props.onDismiss} />
            </Common.Box>
          )}
        </Common.Box>
      </Common.Space>
    </Card>
  )
}

Toast.defaultProps = {
  variant: 'info',
}

Toast.propTypes = {
  children: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  onDismiss: PropTypes.func,
}

export default Toast

import * as R from 'ramda'

import * as types from './types'

/**
* Get the reward-object from state.welcomeOffers.rewards which matches amount passed
*
* @param {Object} state
* @param {Object} props
* @param {number} props.amountCents - An amount in cents. Ex: 10€ === 1000
*/

export function getWelcomeOfferRewardByAmountCents(state, props) {
  return R.find(
    reward =>
      props.amountCents >= reward.minimumAmountCents &&
      props.amountCents <= reward.maximumAmountCents,
    state.welcomeOffers.rewards
  )
}

/**
* Get bonus money amount from welcomeOffers reward
*
* @param {Object} state
* @param {Object} props
* @param {number} props.amountCents - An amount in cents. Ex: 10€ === 1000
*/
export function getWelcomeOfferBonusMoneyAmount(state, props) {
  if (
    R.not(props.amountCents) ||
    R.not(state.selectedReward) ||
    R.not(state.selectedReward.maximumAmountCents)
  ) {
    return 0
  }

  if (state.selectedReward.bonusPercent) {
    const amount =
      Math.ceil(props.amountCents) / 100 * state.selectedReward.bonusPercent

    if (amount > state.maxBonusCents) {
      return state.maxBonusCents
    }

    return amount
  }

  return state.selectedReward.bonusCents
}

export function selectOfferType(payload) {
  return {
    type: types.SELECT_OFFER_TYPE,
    payload,
  }
}

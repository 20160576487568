import * as R from 'ramda'
import { createAction } from 'redux-actions'

const initialState = {
  activeTile: [],
  shuffling: false,
  tiles: 9,
}

export const tileActivated = createAction(
  'casino-heroes/board/TILE_ACTIVATED',
  undefined,
  obj => obj.sound && { sound: 'tile' }
)
export const tileReset = createAction('casino-heroes/board/TILE_RESET')

export const boardShuffleStart = createAction(
  'casino-heroes/board/BOARD_SHUFFLE_START',
  undefined,
  sound => sound && { sound: 'shuffle' }
)
export const boardShuffleEnd = createAction(
  'casino-heroes/board/BOARD_SHUFFLE_END'
)
export const clearBoard = createAction('casino-heroes/board/CLEAR')

export function boardReducer(state = initialState, action) {
  const mergeWithState = R.mergeRight(state)

  switch (action.type) {
    case 'casino-heroes/board/TILE_ACTIVATED': {
      return mergeWithState({
        activeTile: R.uniq(R.append(action.payload.key, state.activeTile)),
      })
    }

    case 'casino-heroes/board/TILE_RESET': {
      return mergeWithState({ activeTile: initialState.activeTile })
    }

    case 'casino-heroes/board/BOARD_SHUFFLE_START': {
      return mergeWithState({ shuffling: true })
    }

    case 'casino-heroes/board/BOARD_SHUFFLE_END': {
      return mergeWithState({ shuffling: false })
    }

    case 'casino-heroes/board/CLEAR': {
      return initialState
    }

    default:
      return state
  }
}

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { berry, gradientOrangeDark } from '../../constants/colors'
import { headingFont } from '../../constants/typography'

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 1.4em;
  background-color: #132e65;
  border: 1px solid #071228;
`

const Progress = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: ${props => props.completion}%;
  background: ${props => (props.boosted ? gradientOrangeDark : berry)};
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  transition: width 0.5s ease;
`

const ProgressNumber = styled.span`
  margin-left: 1.5em;
  color: #ffffff;
  font-family: ${headingFont};
  font-size: 0.8em;
  font-weight: 600;
`

const numberVisibilityThreshold = 15

function ProgressBar(props) {
  return (
    <Wrapper>
      <Progress completion={props.completion} boosted={props.boosted}>
        {props.completion >= numberVisibilityThreshold && (
          <ProgressNumber>{props.completion}%</ProgressNumber>
        )}
      </Progress>
    </Wrapper>
  )
}

ProgressBar.defaultProps = {
  completion: 0,
}

ProgressBar.propTypes = {
  boosted: PropTypes.bool,
  completion: PropTypes.number,
}

export default ProgressBar

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import styled from '@emotion/styled'
import {
  Field,
  FormPropTypes,
  actions,
  connectForm,
} from '@rushplay/legacy-forms'
import { Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import FingerprintContainer from '../native-app/fingerprint-container'
import InputField from '../../common/input-field'
import { getClientType, getIsLoginInProgress, login } from '../../store/app'

import UsernameField from './username-field'

const Wrapper = styled.form`
  width: 100%;
  display: inline-block;

  @media screen and (min-width: 1280px) {
    display: flex;
    align-items: center;
  }
`

const Controls = styled.div`
  padding-top: 0;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1280px) {
    padding-top: 14px;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    height: 62px;
  }
`

const Input = styled.div`
  width: 100%;

  @media screen and (max-width: 1279px) {
    padding-bottom: 12px;
  }

  @media screen and (min-width: 1280px) {
    width: 185px;
    padding-left: 5px;
    position: relative;
  }
`

const ButtonWrapper = styled.div`
  display: inline-block;
  float: right;

  @media screen and (min-width: 1280px) {
    padding-left: 10px;
  }
`

const ForgotPasswordLarge = styled.span`
  display: none;

  @media screen and (min-width: 1280px) {
    display: block;
    color: white;
    font-size: 0.75em;
    position: absolute;
    right: 0;
    bottom: -14px;
  }
`

const ForgotPasswordSmall = styled.span`
  line-height: 51px;
  color: #cccccc;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    this.props.onLogin(
      this.props.fields.username.value,
      this.props.fields.password.value,
      this.props.clientType
    )
  }

  render() {
    return (
      <Wrapper onSubmit={this.handleSubmit}>
        {this.props.returningUsername && <Space right="17px" />}
        <Controls>
          <Input>
            <Field
              returningUserAvatar={this.props.returningUserAvatar}
              dark
              small
              component={UsernameField}
              initialValue={this.props.returningUsername}
              label={this.props.translate('login-form.username')}
              name="username"
              type="text"
              onClear={this.props.onUsernameClear}
            />
          </Input>
          {this.props.returningUsername && <Space right="10px" />}
          <Input>
            <Field
              dark
              small
              autoFocus={
                this.props.returningUsername &&
                typeof window.orientation === 'undefined'
              }
              component={InputField}
              label={this.props.translate('login-form.password')}
              name="password"
              type="password"
            />
            <Link to="/reset-password">
              <ForgotPasswordLarge>
                {this.props.translate('login-form.forgot-password')}
              </ForgotPasswordLarge>
            </Link>
          </Input>
        </Controls>
        <Link to="/reset-password">
          <ForgotPasswordSmall>
            {this.props.translate('login-form.forgot-password')}
          </ForgotPasswordSmall>
        </Link>
        <ButtonWrapper>
          <Button
            type="submit"
            loading={this.props.isLoginInProgress}
            minimalPadding
          >
            {this.props.translate('login-form.login')}
          </Button>
        </ButtonWrapper>
        <FingerprintContainer />
      </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    clientType: getClientType(state.app),
    isLoginInProgress: getIsLoginInProgress(state.app),
    returningUsername: state.app.username,
    returningUserAvatar: state.app.avatarUrl,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onUsernameClear: () => [
        actions.clearFields('login', 'username'),
        actions.focus('login', 'username'),
      ],
      onLogin: (username, password, clientType) =>
        login(username, password, clientType),
    },
    dispatch
  )
}

LoginForm.propTypes = {
  clientType: PropTypes.string,
  fields: PropTypes.shape({
    username: FormPropTypes.field,
    password: FormPropTypes.field,
  }).isRequired,
  isLoginInProgress: PropTypes.bool,
  returningUsername: PropTypes.string,
  returningUserAvatar: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onUsernameClear: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
}

LoginForm.contextTypes = { locale: PropTypes.string }

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  connectForm,
  withTranslate
)(LoginForm)

import * as React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import * as Colors from '../../constants/colors'
import Button from '../../common/button'
import HtmlContent from '../../common/html-content'
import Icon from '../../common/icon/icon'

const Wrapper = styled.div`
  text-align: center;
  color: black;
`

const Title = styled.h2`
  margin-top: 40px;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
`

const Check = styled(Icon)`
  margin-top: 40px;
  font-size: 162px;
  color: ${Colors.success};

  @media (max-width: 768px) {
    font-size: 92px;
  }
`

const Text = styled.p`
  font-size: 24px;
  line-height: 1.485;
  color: #ffffff;
  margin: 40px auto;
  width: 80%;

  @media (max-width: 768px) {
    font-size: 18px;
    margin: 30px auto;
  }
`

function Success(props) {
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView(false)
    }
  }, [])

  return (
    <Wrapper ref={buttonRef}>
      {props.children}
      <Check name="check-circle-o" />
      <Title>{props.translate('sign-up.success')}</Title>
      <Text>
        <HtmlContent
          html={{
            __html: props.translate('sign-up.access-hero-islands'),
          }}
        />
      </Text>
      <span>
        <Button
          variant="primary"
          loading={props.loading}
          onClick={props.onSuccess}
          type="button"
        >
          {props.translate('sign-up.deposit-to-start')}
        </Button>
      </span>
    </Wrapper>
  )
}

export { Success }

export default withTranslate(Success)

Success.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

import Avatar from '../../../common/avatar'
import Icon from '../../../common/icon/icon'

function UserDashBoardLink(props) {
  return (
    <Common.Box
      as={Common.Link}
      to="/dashboard"
      display="flex"
      textDecoration="none"
    >
      <Common.Box width="30px" height="30px">
        <Avatar src={props.userAvatar} />
      </Common.Box>
      <Common.Box flex="1" lineHeight="30px" marginLeft="20px">
        {props.displayName}
      </Common.Box>
      <Icon name="chevron-right" />
    </Common.Box>
  )
}

export default UserDashBoardLink

UserDashBoardLink.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
}

import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withCurrentTime } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { getCurrentSessionTime } from '../../store/session'

import { CurrentSessionTime as Component } from './current-session-time'

function mapStateToProps(state, ownProps) {
  const showCurrentSessionTime = jurisdiction.getCurrentSessionTimeVisibility(
    state.jurisdiction
  )

  return {
    currentSessionTime:
      showCurrentSessionTime && getCurrentSessionTime(state, ownProps),
  }
}

export const CurrentSessionTime = withCurrentTime(
  connect(mapStateToProps)(withTranslate(Component))
)

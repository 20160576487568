import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import Avatar from '../common/avatar'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media screen and (max-width: 1279px) {
    padding-top: 50px;
  }
`

const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin-left: 20px;
  &:nth-child(3),
  &:nth-child(4) {
    margin-right: 4px;
    margin-bottom: 90px;
  }
  &:nth-child(1) {
    width: 90px;
    height: 90px;
    margin-bottom: 120px;
  }
  &:nth-child(2) {
    width: 95px;
    height: 95px;
    margin-bottom: 105px;
  }
  @media screen and (max-width: 1279px) {
    &:nth-child(1) {
      display: none;
    }
  }
  @media screen and (max-width: 620px) {
    &:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 380px) {
    width: 80px;
    height: 80px;
    padding-top: 20px;
  }
`

const Avatars = styled.div`
  display: flex;
  align-items: flex-end;
`

const ReverseAvatars = styled(Avatars)`flex-direction: row-reverse;`

const AvatarShadow = styled.div`
  height: 10%;
  width: 80%;
  margin-top: -10%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
`

function renderAvatar(src) {
  return (
    <AvatarWrapper key={src}>
      <Avatar src={src} />
      <AvatarShadow />
    </AvatarWrapper>
  )
}

function AvatarsRow(props) {
  if (props.avatars.length === 0) {
    return null
  }
  return (
    <Wrapper>
      <Avatars>
        {R.map(id => renderAvatar(props.avatars[id]), [0, 1, 2, 3])}
      </Avatars>
      <ReverseAvatars>
        {R.map(id => renderAvatar(props.avatars[id]), [6, 5, 4])}
      </ReverseAvatars>
    </Wrapper>
  )
}

export default AvatarsRow

AvatarsRow.propTypes = { avatars: PropTypes.array.isRequired }

import { connect } from 'react-redux'

function mapStateToProps(state, props) {
  return {
    redirect: typeof window.orientation !== 'undefined',
    scriptSrc: props.game.options.scriptSrc,
  }
}

export default connect(
  mapStateToProps,
  null
)

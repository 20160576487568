import * as R from 'ramda'

import * as api from '@rushplay/api-client'

const INITIAL_STATE = {}

export const INIT = 'casino-heroes/lookup/INIT'

export function init(payload) {
  return {
    type: INIT,
    error: payload instanceof Error,
    payload,
  }
}

export function fetchCountryCode() {
  return api.fetchCountryCode({
    success: res =>
      init({
        countryCode: res.value.countryCode,
        currency: res.value.country.currency,
      }),
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return Object.assign(action.payload, state)
    }

    default: {
      return state
    }
  }
}

export function getCountryCode(state) {
  return R.pathOr('', ['countryCode'], state)
}

export function getCurrency(state) {
  return R.pathOr('', ['currency'], state)
}

import * as R from 'ramda'

import * as Websockets from '@rushplay/websockets'

export const UPDATED = 'speedy/receipts/UPDATED'
export const TOTALS_UPDATED = 'speedy/receipts/TOTALS_UPDATED'
export const TOTAL_DEPOSITS_UPDATED = 'speedy/receipts/TOTAL_DEPOSITS_UPDATED'

const RECEIPTS_FALLBACK = []

export function update(payload) {
  return {
    type: UPDATED,
    payload,
  }
}

export function updatePlayerBalanceTotals(payload) {
  return {
    type: TOTALS_UPDATED,
    payload,
  }
}

export function updateTotalDepositsCents(payload) {
  return {
    type: TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}

export function reducer(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (action.type) {
    case UPDATED: {
      return R.pipe(
        R.set(R.lensPath(['currentPage']), action.payload.meta.page),
        R.set(R.lensPath(['totalPages']), action.payload.meta.total_pages),
        R.set(R.lensPath(['items']), action.payload.receipts)
      )(state)
    }

    case TOTALS_UPDATED:
      return R.merge(state, {
        balanceTotals: action.payload,
      })

    case TOTAL_DEPOSITS_UPDATED:
      return R.merge(state, {
        totalDepositCents: action.payload,
      })

    case Websockets.SESSION_EXPIRED: {
      return {}
    }

    default: {
      return state
    }
  }
}

export function getAll(state) {
  return R.pathOr(RECEIPTS_FALLBACK, ['items'], state)
}

export function getById(state, transactionId) {
  return R.find(R.propEq('transactionId', transactionId), state.items)
}

export function getTotalPages(state) {
  return state.totalPages
}

export function getCurrentPage(state) {
  return state.currentPage
}

export function getTotalDepositsCents(state) {
  return R.path(['totalDepositCents', 'result', 'total_deposit_cents'], state)
}

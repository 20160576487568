import * as R from 'ramda'
import { createSelector } from 'reselect'

const getAllGames = R.prop('games')

const getQueryResults = (state, props) =>
  R.pathOr([], ['queryResults', R.prop('queryName', props), '__items'], state)

/**
 * Gets the current number of fetched games for a given query.
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {number} the number of games in store
 *
 * @example
 * getCurrentCount(state, { queryName: 'popular' })
 */

export const getCurrentCount = createSelector([getQueryResults], R.length)

/**
 * Gets the total number of games available on the remote for a given query.
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {number} the number of games available on remote for a given query
 *
 * @example
 * getTotalCount(state, { queryName: 'popular' })
 */

export const getTotalCount = (state, props) =>
  R.pathOr(
    Infinity,
    ['queryResults', R.prop('queryName', props), 'totalCount'],
    state
  )

/**
 * Gets the total number of games available on the remote for a given query.
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {number} the offset number (currentCount stored just before searchGames request is made)
 *
 * @example
 * getOffset(state, { queryName: 'popular' })
 */

export const getOffset = (state, props) =>
  R.pathOr(0, ['queryResults', R.prop('queryName', props), 'offset'], state)

export const getDesiredCount = createSelector(
  [getTotalCount, getOffset, (_, props) => props.limit],
  (total, offset, limit) => {
    const desired = offset + limit
    return Math.min(total, desired)
  }
)

/**
 * Gets game with specified id.
 *
 * @param {Object} state
 * @param {Object} params
 * @param {number} params.id the id of the game
 *
 * @returns {Object} a game
 *
 * @example
 * getGame(state, { id: 123 })
 */

export const getGame = createSelector(
  [(_, props) => props.id, getAllGames],
  R.prop
)

/**
 * Gets games for a given query.
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {Array.<Object>} games for a given query
 *
 * @example
 * getGames(state, { queryName: 'popular' })
 */

export const getGames = createSelector(
  [getQueryResults, getAllGames],
  (queryResults, allGames) => R.map(id => R.prop(id, allGames), queryResults)
)

export const getGameIds = createSelector(
  [getQueryResults, getDesiredCount],
  (queryResults, desiredCount) =>
    R.take(desiredCount, R.concat(queryResults, R.repeat(null, desiredCount)))
)

export const areGamesLoading = createSelector(
  [getDesiredCount, getCurrentCount],
  (desired, current) => desired > current
)

/**
 * Returns uniq gameTypes for a given query
 *
 * @param {Object} state
 * @returns {Array.<string>}
 *
 * @example
 * getGameTypes(state, { queryName: 'live' })
 */
export const getGameTypes = createSelector(getGames, games =>
  R.uniq(R.values(R.pluck('gameType', games)))
)

/**
 * Returns selected gameType
 *
 * @param {Object} state
 * @returns {string}
 */
export const getGameType = state => R.path(['filters', 'gameType'], state)

/**
 * Returns selected betRange
 *
 * @param {Object} state
 * @returns {Array.<number>}
 */
export const getBetRange = state => R.path(['filters', 'betRange'], state)

/**
 * @typedef {Object} TableGame
 * @prop {boolean} tableOpen
 * @prop {string} gameType
 */

/**
 * @param {TableGame} game live casino game
 * @returns {boolean}
 */
const isOpen = game => game.tableOpen === true

/**
 * @param {TableGame} game live casino game
 * @returns {function(TableGame): boolean}
 */
const hasSeatsAvailable = game => game.seatsAvailable !== 0

/**
 * @param {TableGame}
 * @returns {boolean}
 */
const isLiveLobby = game => R.includes('livefav', game.tags)

/**
 * @param {string} gameType
 * @returns {function(TableGame): boolean}
 */
const isOfGameType = gameType => game =>
  gameType === 'all' || game.gameType === gameType

/**
 * @param {number} min
 * @param {number} max
 * @returns {function(TableGame): boolean}
 */
const isInBetRange = (min, max) => game =>
  min <= game.minBetCents / 100 && game.minBetCents / 100 <= max

/**
 * Filters live casino games depending on filter criteria
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {Array.<Object>}
 */
export const getFilteredGames = createSelector(
  [getGames, getGameType, getBetRange],
  (games, gameType, betRange) =>
    R.filter(
      R.allPass([
        isOpen,
        hasSeatsAvailable,
        isOfGameType(gameType),
        isInBetRange(betRange[0], betRange[1]),
      ]),
      R.values(games)
    )
)

/**
 * Filters live casino games for lobby page
 *
 * @param {Object} state
 * @param {Object} params
 * @param {string} params.queryName the name of the query
 *
 * @returns {Array.<Object>}
 */
export const getFilteredLobbyLiveGames = createSelector([getGames], games =>
  R.filter(isLiveLobby, R.values(games))
)

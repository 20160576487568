import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import Text from '../common/text'

import * as Limit from './limit-styles.js'

const Wrapper = styled(Flex)`
  flex-grow: 1;
`

const HoverText = styled(Text)`
  cursor: pointer;
`

function PlayerLimit(props) {
  const increasedAmount = props.isIncreased ? props.nextAmount : ''
  const targetTitle =
    props.target === 'loginTime' ? `session-limits` : `money-limits`
  const pendingTextKey = props.isRemoved
    ? `player-limits.removed`
    : `${targetTitle}.increased`

  if (props.amount === 0) {
    return (
      <Wrapper column justifyContent="space-between">
        <Space top={7}>
          <Flex center column>
            <Limit.LargeText>
              {props.translate(`${targetTitle}.limit`, {
                value: props.amount,
              })}
            </Limit.LargeText>
          </Flex>
          <Space y={5}>
            <Limit.InfoText>
              {props.canEdit ? (
                <HtmlContent
                  html={{
                    __html: props.translate('player-limits.limit-set-to-zero', {
                      activeFrom: props.editableFrom,
                    }),
                  }}
                />
              ) : (
                props.translate(
                  'player-limits.limit-set-to-zero.contact-support'
                )
              )}
            </Limit.InfoText>
          </Space>
        </Space>
      </Wrapper>
    )
  }
  return (
    <Wrapper column justifyContent="space-between">
      <Space top={7}>
        <Flex center column>
          <Limit.LargeText>
            {props.translate(`${targetTitle}.limit`, {
              value: props.amount,
            })}
          </Limit.LargeText>
        </Flex>
        {!props.isFetchingActiveFrom &&
        (props.isIncreased || props.isRemoved) ? (
          <Space y={5}>
            <Limit.InfoText>
              <HtmlContent
                html={{
                  __html: props.translate(pendingTextKey, {
                    limit: increasedAmount,
                    activeFrom: props.activeFrom,
                  }),
                }}
              />
            </Limit.InfoText>
          </Space>
        ) : null}
      </Space>
      <Space top={5}>
        {!props.isFetchingActiveFrom &&
        (props.isIncreased || props.isRemoved) ? (
          <Flex justifyContent="center">
            <Button stretch variant="secondary" onClick={props.onCancel}>
              {props.translate('player-limits.undo')}
            </Button>
          </Flex>
        ) : (
          <Flex column>
            <Button stretch onClick={props.onEdit}>
              {props.translate('player-limits.edit')}
            </Button>
            {props.onRemove && (
              <Space top={7}>
                <HoverText align="center" onClick={props.onRemove}>
                  {props.translate('player-limits.cancel')}
                </HoverText>
              </Space>
            )}
          </Flex>
        )}
      </Space>
    </Wrapper>
  )
}

PlayerLimit.propTypes = {
  activeFrom: PropTypes.string,
  amount: PropTypes.number,
  canEdit: PropTypes.bool,
  editableFrom: PropTypes.string,
  isFetchingActiveFrom: PropTypes.bool,
  isIncreased: PropTypes.bool,
  isRemoved: PropTypes.bool,
  nextAmount: PropTypes.number,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  target: PropTypes.string,
  translate: PropTypes.func.isRequired,
}

export default withTranslate(PlayerLimit)

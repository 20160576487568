import { css } from '@emotion/core'
import { rem } from 'polished'

import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
  textFont,
} from './constants/typography'

function spacing(size) {
  return css`
    margin-top: ${size / 2}px;
    margin-bottom: ${size / 2}px;
  `
}

export function h1() {
  return css`
    font-family: ${headingFont};
    font-size: ${rem('30px', baseFontSizeSmall)};
    ${spacing(30)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('40px', baseFontSizeLarge)};
      ${spacing(40)};
    }
  `
}

export function h2() {
  return css`
    font-family: ${headingFont};
    font-size: ${rem('20px', baseFontSizeSmall)};
    ${spacing(20)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('30px', baseFontSizeLarge)};
      ${spacing(30)};
    }
  `
}

export function h3() {
  return css`
    font-family: ${headingFont};
    font-size: ${rem('16px', baseFontSizeSmall)};
    ${spacing(16)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('20px', baseFontSizeLarge)};
      ${spacing(20)};
    }
  `
}

export function h4() {
  return css`
    font-family: ${headingFont};
    font-weight: 700;
    text-transform: uppercase;
    font-size: ${rem('14px', baseFontSizeSmall)};
    ${spacing(14)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('16px', baseFontSizeLarge)};
      ${spacing(16)};
    }
  `
}

export function h5() {
  return css`
    font-family: ${textFont};
    font-size: ${rem('14px', baseFontSizeSmall)};
    ${spacing(14)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('16px', baseFontSizeLarge)};
      ${spacing(16)};
    }
  `
}

export function h6() {
  return css`
    font-family: ${textFont};
    font-size: ${rem('14px', baseFontSizeLarge)};
    font-weight: 700;
  `
}

export function preamble() {
  return css`
    font-family: ${textFont};
    font-weight: 300;
    line-height: 1.4;
    font-size: ${rem('14px', baseFontSizeSmall)};
    @media screen and (min-width: 768px) {
      font-size: ${rem('16px', baseFontSizeLarge)};
    }
  `
}

export function paragraph() {
  return css`
    font-family: ${textFont};
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 1rem;
  `
}

export function small() {
  return css`
    font-family: ${textFont};
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 1rem;
  `
}

export function list() {
  return css`
    position: relative;
    padding-left: 20px;

    font-size: ${rem('12px', baseFontSizeSmall)};
    line-height: 1.6;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
    }

    @media screen and (min-width: 768px) {
      padding-left: 30px;
      font-size: ${rem('16px', baseFontSizeLarge)};

      &:before {
        width: 15px;
        height: 15px;
      }
    }
  `
}

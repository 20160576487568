import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Locks from '@rushplay/compliance/locks'

import * as Constants from '../../constants'
import * as Session from '../../store/session'
import Button from '../../common/button'
import Heading from '../../common/heading'
import HtmlContent from '../../common/html-content'
import Icon from '../../common/icon/icon'
import Text from '../../common/text'
import { Payer } from '../../payer'

import LockPage from './lock-page'

function getPayerInitialData(state) {
  return {
    ssn: Session.getSsn(state.session),
    verificationToken: R.defaultTo('', Session.getSessionToken(state.session)),
  }
}

const BankIdVerificationStep = {
  Info: 'INFO',
  Payer: 'PAYER',
}

function BankIdVerification() {
  const dispatch = ReactRedux.useDispatch()
  const payerInitialData = ReactRedux.useSelector(getPayerInitialData)
  const i18n = I18n.useI18n()
  const [step, setStep] = React.useState(BankIdVerificationStep.Info)

  function handleSuccess(sessionToken) {
    const [, sessionRequest] = dispatch(Session.refresh(sessionToken))
    sessionRequest
      .then(() => {
        return dispatch([Locks.fetch(), Locks.remove('bank-id-verification')])
      })
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
  }

  return (
    <LockPage>
      {step === BankIdVerificationStep.Info && (
        <Text align="center">
          <Text size="72">
            <Icon name="bankid" />
          </Text>
          <Heading level="2">
            {i18n.translate('locks.bank-id-verification.title')}
          </Heading>
          <HtmlContent
            html={{
              __html: i18n.translate('locks.bank-id-verification.description'),
            }}
          />
          <Common.Space top={6}>
            <Button onClick={() => setStep(BankIdVerificationStep.Payer)}>
              {i18n.translate('locks.bank-id-verification.submit')}
            </Button>
          </Common.Space>
        </Text>
      )}
      {step === BankIdVerificationStep.Payer && (
        <Common.Box width="100%" height="500px">
          <Payer
            initialData={payerInitialData}
            transactionType={Constants.TransactionType.AUTH}
            onCancel={() => setStep(BankIdVerificationStep.Info)}
            onSuccess={({ sessionToken }) => handleSuccess(sessionToken)}
          />
        </Common.Box>
      )}
    </LockPage>
  )
}

export default BankIdVerification

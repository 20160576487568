import Modal from 'react-modal'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as Colors from '../../constants/colors'
import { CloseButton } from '../../common/close-button'

const PopupWrapper = styled(Modal)`
  padding-top: 50px;
  outline: none;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
`

const Video = styled.iframe`
  box-shadow: 0 20px 50px 0 ${Colors.darkBlue3};

  @media (orientation: portrait) and (max-width: 1280px) {
    width: 96vw;
    height: 54vw;
  }

  @media (orientation: landscape) and (max-width: 1280px) {
    width: 114vh;
    height: 64vh;
  }

  @media (min-width: 1280px) {
    border-radius: 9px;
    min-height: 560px;
    min-width: 996px;
  }
`

export function VideoModal(props) {
  return (
    <PopupWrapper
      ariaHideApp={false}
      id="landing-page-video"
      isOpen={props.isPopupOpen}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          zIndex: 99,
          backgroundColor: 'rgba(2, 16, 42, 0.5)',
        },
      }}
    >
      <Common.Box position="relative">
        <Video
          src="https://www.youtube.com/embed/ZSK1l8yhjk4?rel=0&autoplay=1"
          frameBorder="0"
          allow="autoplay; encrypted-media; picture-in-picture"
          allowFullscreen
        />
        <Common.Box
          position="absolute"
          top={['-48px', null, '-20px']}
          right={['-4px', null, '-20px']}
        >
          <CloseButton onClick={props.onRequestClose} />
        </Common.Box>
      </Common.Box>
    </PopupWrapper>
  )
}

VideoModal.propTypes = {
  isPopupOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
}

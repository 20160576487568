import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { replace as redirectTo } from 'react-router-redux'

import { Image } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import NotFound from '../components/not-found'
import {
  Content,
  Header,
  Headline,
  NavigateBack,
  Wrapper,
} from '../components/static/static'

const ImageWrapper = styled.div`
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
  }
`

function HowToPlayDetailContainer(props) {
  const howToPlayTitle = `how-to-play.${props.params.tutorial}`

  if (!props.isExistingTranslation(howToPlayTitle)) {
    return <NotFound />
  }
  return (
    <Wrapper>
      <Header>
        <Headline>
          {props.translate(`how-to-play.${props.params.tutorial}.title`)}
        </Headline>

        <NavigateBack
          onClick={() => props.redirectTo('/how-to-play')}
          visibleOnDesktop
        >
          <Icon name="chevron-left" size="big" />
        </NavigateBack>
      </Header>

      <Content>
        <ImageWrapper>
          <Image
            src={props.translate(
              `how-to-play.imagesPopup.${props.params.tutorial}`
            )}
            alt={props.translate(`how-to-play.${props.params.tutorial}.title`)}
          />
        </ImageWrapper>

        <HtmlContent
          html={{
            __html: props.translate(
              `how-to-play.${props.params.tutorial}.description`
            ),
          }}
        />
      </Content>
    </Wrapper>
  )
}

export default connect(null, { redirectTo })(
  withTranslate(HowToPlayDetailContainer)
)

HowToPlayDetailContainer.propTypes = {
  isExistingTranslation: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

import { parse as parseUrl } from 'url'

const REFERRERS = ['google', 'yahoo', 'bing']

function isSearchEngine(referrer) {
  if (typeof referrer === 'string') {
    const url = parseUrl(referrer, true, true)

    if (typeof url.hostname === 'string') {
      return url.hostname
        .split('.')
        .slice(-3)
        .reduce((result, domain) => result || REFERRERS.includes(domain), false)
    }
  }

  return false
}

/**
 * Hero Gaming SEO affiliate ID/Tag. Used to track organic search player
 * acquisition. Same for all brands.
 * @constant
 * @type {string}
 */
export const SEO_BTAG = '654421_44B727F95F044AD482A0E3A2B3398401'

/**
 * Get NetRefer BTag from page URL
 * @param {string} referrer Request origin, usually `document.referrer`
 * @param {string} location Current location, e.g. `window.location.href`
 * @returns {?string} NetRefer BTag or `undefined`
 */
export function getBtag(referrer, location) {
  if (isSearchEngine(referrer)) {
    return SEO_BTAG
  }

  const url = parseUrl(location, true, true)

  return url.query.btag
}

import React from 'react'

export function IconAlert() {
  return (
    <svg viewBox="0 0 50 47" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.572 38.837c0 .509-.39.932-.893.932H22.32c-.502 0-.893-.423-.893-.932v-5.366c0-.509.39-.933.893-.933h5.358c.502 0 .893.424.893.933v5.366zm-.056-10.564c-.028.368-.446.65-.949.65h-5.162c-.53 0-.949-.282-.949-.65l-.474-12.908c0-.17.083-.452.279-.593.167-.141.418-.31.67-.31h6.139c.25 0 .502.169.67.31.195.141.278.367.278.537l-.502 12.964zm-.39-26.38A3.545 3.545 0 0 0 25 0a3.545 3.545 0 0 0-3.125 1.892L.444 41.662c-.614 1.101-.586 2.457.055 3.559A3.564 3.564 0 0 0 3.57 47H46.43a3.564 3.564 0 0 0 3.07-1.78c.641-1.101.67-2.457.055-3.558l-21.43-39.77z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const WorldCompletionOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(24, 51, 99, 0.73);

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30%;
    left: 0;
    background-image: ${props => `url('${props.image}')`};
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }
`

WorldCompletionOverlay.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
}

export default WorldCompletionOverlay

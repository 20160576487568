import styled from '@emotion/styled'
import { Image } from '@rushplay/common'
import { css } from '@emotion/core'

import { h1, h3 } from '../../mixins'

export const Wrapper = styled.div`
  max-width: 1243px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`

export const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;

  @media screen and (min-width: 768px) {
    border-radius: 10px;
    padding: 30px;
  }
`

export const Header = styled.div`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (max-width: 767px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

export const Headline = styled.h1`
  ${h1};
  text-align: center;
  margin-bottom: 20px;
`

export const Description = styled.div`
  ${h3};
  line-height: 1.4;
  font-weight: 400;

  text-align: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    max-width: 650px;
  }
`

export const NavigateBack = styled.div`
  position: absolute;
  top: 25px;
  left: 5px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    left: 20px;
    top: 35px;
  }

  ${props => {
    if (!props.visibleOnDesktop) {
      return css`
        @media screen and (min-width: 1280px) {
          display: none;
        }
      `
    }
  }};
`

export const Logo = styled(Image)`
  width: 230px;
  height: 35px;

  @media screen and (min-width: 768px) {
    width: 460px;
    height: 70px;
  }
`

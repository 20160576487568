import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import PopupContainer from '../../common/popup/popup-container'

class BalanceTypeChangePopupContainer extends Component {
  componentDidMount() {
    if (this.props.openOnLaunch) {
      this.props.onBalanceTypeChange()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.onOpen && !this.props.isOpen && nextProps.isOpen) {
      this.props.onOpen()
    }
    if (this.props.openOnChange && this.props.type !== nextProps.type) {
      this.props.onBalanceTypeChange()
    }
  }

  render() {
    return (
      <PopupContainer id="balance-type-change" isOpen={this.props.isOpen}>
        {this.props.translate(`casino.playing-with-${this.props.type}-money`)}
        <Space top="30px">
          <Button stretch onClick={this.props.onAcknowledge}>
            {this.props.translate('ok')}
          </Button>
        </Space>
      </PopupContainer>
    )
  }
}

BalanceTypeChangePopupContainer.propTypes = {
  onAcknowledge: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  onBalanceTypeChange: PropTypes.func,
  openOnChange: PropTypes.bool,
  openOnLaunch: PropTypes.bool,
  translate: PropTypes.func,
  type: PropTypes.oneOf(['bonus', 'real']).isRequired,
}

export default withTranslate(BalanceTypeChangePopupContainer)

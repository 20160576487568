import * as R from 'ramda'

import * as types from './types'

export function updateRegionProgress(worldKey, progress) {
  return {
    type: types.UPDATE_REGION_PROGRESS,
    payload: { worldKey, progress },
  }
}

export function updateLastSeenProgress(worldKey, progress) {
  return {
    type: types.UPDATE_LAST_SEEN_PROGRESS,
    payload: { worldKey, progress },
  }
}

export function updateMapPosition(worldKey, position) {
  return {
    type: types.UPDATE_MAP_POSITION,
    payload: { worldKey, position },
  }
}

export function storeWorlds(worlds) {
  return {
    type: types.STORE_WORLDS,
    payload: worlds,
  }
}

export function selectWorld(key) {
  return {
    type: types.SELECT_WORLD,
    payload: key,
  }
}

export function storeRegions(worldKey, regions) {
  return {
    type: types.STORE_REGIONS,
    payload: { worldKey, regions },
  }
}

export function storeTreasures(worldKey, treasures) {
  return {
    type: types.STORE_TREASURES,
    payload: { worldKey, treasures },
  }
}

export function removeTreasure(worldKey, treasureId) {
  return {
    type: types.REMOVE_TREASURE,
    payload: { worldKey, treasureId },
  }
}

export function selectRegion(worldKey, regionKey) {
  return {
    type: types.SELECT_REGION,
    payload: { worldKey, regionKey },
  }
}

export function updateImages(images) {
  const payload = R.map(
    R.pick([
      'backgroundUrl',
      'prizeImageUrl',
      'iconUrl',
      'emptinessImageUrl',
      'treasureImageUrl',
    ]),
    images
  )

  return {
    type: types.UPDATE_IMAGES,
    payload,
  }
}

export function updateDetails(details) {
  const payload = R.map(
    R.pick(['difficulty', 'pp', 'isLastRegion', 'key']),
    details
  )

  return {
    type: types.UPDATE_DETAILS,
    payload,
  }
}

export function updateStats(stats) {
  const payload = R.map(
    R.pick([
      'bosses',
      'passedRegionsCount',
      'totalRegionsCount',
      'foundTreasuresCount',
      'totalTreasuresCount',
      'worldProgressPercent',
      'locked',
      'completed',
    ]),
    stats
  )

  return {
    type: types.UPDATE_STATS,
    payload,
  }
}

export function clear() {
  return { type: types.CLEAR }
}

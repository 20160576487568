import * as R from 'ramda'
import * as polished from 'polished'

/**
 * Helper function for border-radius.
 * @param {number} number
 * @return {Function} that takes props and returns the value for border-radius
 *         from the theme that corresponds to the level
 */
export function borderRadius(number) {
  return R.path(['theme', 'borderRadius', number])
}

/**
 * Helper function for breakpoints.
 * @param {string} name
 * @return {Function} that takes props and returns the value of the named
 *         breakpoint from the theme
 */
export function breakpoint(name) {
  return R.path(['theme', 'breakpoints', name])
}

/**
 * Helper function for colors.
 * @param {string} name
 * @param {number} opacity
 * @return {Function} that takes props and returns the value of the named
 *         color from the theme
 */
export function color(name, opacity = 1) {
  if (['inherit', 'currentColor'].includes(name)) {
    return R.always(name)
  }

  return props => {
    const color = R.path(['theme', 'colors', name], props)
    return color && polished.rgba(color, opacity)
  }
}

/**
 * Helper function for spacings.
 * @param {string} number spacing level (lower level -> smaller spacing)
 * @return {Function} that takes props and returns the spacing value
 *         from the theme that corresponds to the level
 */
export function spacing(number) {
  return R.path(['theme', 'paddings', number])
}

/**
 * Helper function for font sizes.
 * @param {string} number font size level (lower level -> smaller font)
 * @return {Function} that takes props and returns the fontsize
 *         from the theme that corresponds to the level
 */
export function fontSize(number) {
  return R.path(['theme', 'fonts', 'sizes', number])
}

/**
 * Helper function for fonts.
 * @param {string} name
 * @return {Function} that takes props and returns the full name of the named
 *         font from the theme
 */
export function font(name) {
  return R.path(['theme', 'fonts', name])
}

/**
 * Helper function for box shadows.
 * @param {string} number box shadow level (lower level -> smaller shadow)
 * @return {Function} that takes props and returns the box shadow
 *         from the theme that corresponds to the level
 */
export function boxShadow(number) {
  return R.path(['theme', 'boxShadow', number])
}

function render(props) {
  return (strings, interpolations) => {
    let result = ''
    for (const i in strings) {
      const string = strings[i]
      const interpolation =
        typeof interpolations[i] === 'undefined' ? '' : interpolations[i]

      const part =
        typeof interpolation === 'function'
          ? string + interpolation(props)
          : string + interpolation

      result = `${result}${part}`
    }
    return result
  }
}

/**
 * Helper function for media queries.
 * @param {string} name `sm`, `md`, or `lg`
 * @return {Function} that takes props and returns the media query from the
 *         theme that corresponds to the name
 */
export function mediaQuery(name) {
  const getBreakpoint = breakpoint(name)
  return (strings, ...interpolations) => props => {
    const breakpoint = getBreakpoint(props)
    if (breakpoint) {
      return render(props)(
        ['@media screen and (min-width:', ') {', ...strings, '}'],
        [breakpoint, '', ...interpolations, '']
      )
    }
  }
}

mediaQuery.xs = mediaQuery('xs')
mediaQuery.sm = mediaQuery('sm')
mediaQuery.md = mediaQuery('md')
mediaQuery.lg = mediaQuery('lg')

font.body = font('body')
font.head = font('head')

import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'
import { setItem } from 'redux-effects-localstorage'
import { withRouter } from 'react-router'

import { saveLanguagePreference } from '@rushplay/api-client'

import {
  changeLanguage,
  getLanguage,
  getSupportedLanguage,
  getSupportedLanguages,
} from '../store/app'

import { LanguageSelector as Component } from './language-selector'

function mapStateToProps(state) {
  return {
    getSupportedLanguage: (language) => getSupportedLanguage(state, language),
    activeLanguage: getLanguage(state.app),
    supportedLanguages: getSupportedLanguages(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      redirectTo,
      setItem,
      onChangeLanguage: changeLanguage,
      onUpdateLanguage: saveLanguagePreference,
    },
    dispatch
  )
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export const LanguageSelector = connector(Component)

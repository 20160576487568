import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { Limits } from './limits'

export function LoginTimeLimitsContainer(props) {
  return (
    <React.Fragment>
      {R.map(target => {
        return <Limits key={target} target={target} />
      }, props.targets)}
    </React.Fragment>
  )
}

LoginTimeLimitsContainer.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.string).isRequired,
}

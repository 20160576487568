import * as R from 'ramda'
import PropTypes from 'prop-types'
import { getDaysInMonth, setDate, setMonth, setYear } from 'date-fns'

/**
 * Create an array of complete or partial selection of dates of a month
 * @param {Date} min Minimum date range.
 * @param {Date} max Maximum date range.
 * @param {Date} current Current position of range.
 * @returns {array} Array of numbers in ascending order representing dates.
 */
export function getDateRange(min, max, current) {
  const maxYear = max.getFullYear()
  const maxMonth = max.getMonth()
  const maxDay = max.getDate()
  const currentYear = current.getFullYear()
  const currentMonth = current.getMonth()
  const minYear = min.getFullYear()
  const minMonth = min.getMonth()
  const minDay = min.getDate()

  if (
    (currentYear === maxYear && currentMonth >= maxMonth) ||
    currentYear > maxYear
  ) {
    return R.range(1, maxDay + 1)
  }

  if (
    (currentYear === minYear && currentMonth <= minMonth) ||
    currentYear < minYear
  ) {
    return R.range(minDay, getDaysInMonth(new Date(minYear, minMonth)) + 1)
  }

  return R.range(1, getDaysInMonth(new Date(currentYear, currentMonth)) + 1)
}

/**
 * Create an array of complete or partial selection of months
 * @param {Date} min  Minimum date range.
 * @param {Date} max  Maximum date range.
 * @param {Date} current Current position of range.
 * @returns {array} Array of numbers in ascending order representing months.
 */
export function getMonthRange(min, max, current) {
  const maxYear = max.getFullYear()
  const maxMonth = max.getMonth()
  const currentYear = current.getFullYear()
  const minYear = min.getFullYear()
  const minMonth = min.getMonth()

  if (currentYear >= maxYear) {
    return R.range(0, maxMonth + 1)
  }

  if (currentYear <= minYear) {
    return R.range(minMonth, 12)
  }

  return R.range(0, 12)
}

/**
 * Create an array of years
 * @param {Date} min Timestamp to constraint year range from.
 * @param {Date} max Timestamp to constraint year range to.
 * @returns {array} Array of numbers in ascending order representing years.
 */
export function getYearRange(min, max) {
  const maxYear = max.getFullYear()
  const minYear = min.getFullYear()

  return R.reverse(R.range(minYear, maxYear + 1))
}

/**
 * Generate a new timestamp
 * @param {string} unit="year"|"month"|"day" Target date unit to update.
 * @param {number} timestamp Timestamp to base update on.
 * @param {number} value Value of target unit.
 * @returns {number} Timestamp.
 */
export function getNextDate(unit, timestamp, value) {
  const MODIFIERS = {
    day: setDate,
    month: setMonth,
    year: setYear,
  }
  const modifier = MODIFIERS[unit] || R.identity
  const prevDate = new Date(timestamp)
  const nextDate = modifier(prevDate, value)

  return nextDate
}

export const ResponsiveValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.array,
])

export const ResponsiveValueSizePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.array,
])

export function logLegacyPropsWarnings(props) {
  if (!R.isEmpty(R.keys(props))) {
    const warnings = R.reduce(
      (warnings, prop) => {
        switch (prop) {
          case 'align':
            warnings.push(
              '`align` prop is deprecated and will be removed. Use `textAlign` instead'
            )
            break
          case 'all':
            warnings.push(
              '`all` prop is deprecated and will be removed. Use `p` or `padding` instead'
            )
            break
          case 'basis':
            warnings.push(
              '`basis` prop is deprecated and will be removed. Use `flexBasis` instead'
            )
            break
          case 'bottom':
            warnings.push(
              '`bottom` prop is deprecated and will be removed. Use `pb` or `paddingBottom` instead'
            )
            break
          case 'center':
            warnings.push(
              '`center` prop is deprecated and will be removed. Use `alignItems` and `justifyContent` instead'
            )
            break
          case 'column':
            warnings.push(
              '`column` prop is deprecated and will be removed. Use `flexDirection` instead'
            )
            break
          case 'decoration':
            warnings.push(
              '`decoration` prop is deprecated and will be removed. Use `textDecoration` instead'
            )
            break
          case 'font':
            warnings.push(
              '`font` prop is deprecated and will be removed. Use `fontFamily` instead'
            )
            break
          case 'grow':
            warnings.push(
              '`grow` prop is deprecated and will be removed. Use `flexGrow` instead'
            )
            break
          case 'left':
            warnings.push(
              '`left` prop is deprecated and will be removed. Use `pl` or `paddingLeft` instead'
            )
            break
          case 'right':
            warnings.push(
              '`right` prop is deprecated and will be removed. Use `pr` or `paddingRight` instead'
            )
            break
          case 'shrink':
            warnings.push(
              '`shrink` prop is deprecated and will be removed. Use `flexShrink` instead'
            )
            break
          case 'size':
            warnings.push(
              '`size` prop is deprecated and will be removed. Use `fontSize` instead'
            )
            break
          case 'top':
            warnings.push(
              '`top` prop is deprecated and will be removed. Use `pt` or `paddingTop` instead'
            )
            break
          case 'weight':
            warnings.push(
              '`weight` prop is deprecated and will be removed. Use `fontWeight` instead'
            )
            break
          case 'wrap':
            warnings.push(
              '`wrap` prop is deprecated and will be removed. Use `flexWrap` instead'
            )
            break
          case 'x':
            warnings.push(
              '`x` prop is deprecated and will be removed. Use `px` instead'
            )
            break
          case 'y':
            warnings.push(
              '`y` prop is deprecated and will be removed. Use `py` instead'
            )
            break
        }
        return warnings
      },
      [],
      R.keys(props)
    )

    if (
      process.env.NODE_ENV !== 'production' &&
      !R.isNil(warnings) &&
      !R.isEmpty(warnings)
    ) {
      // eslint-disable-next-line no-console
      R.map(warning => console.warn('DEPRECATED', warning), warnings)
    }
  }
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'

import Section from './section'
import { GameQuery } from './game-query'

const SectionWrapper = styled.section`
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
`

const emptySection = (limit) => R.repeat({ placeholder: true }, limit)

const gamesWithPlaceholders = (games, limit) =>
  R.take(limit, R.concat(games, emptySection(limit)))

function GamesSection(props) {
  return (
    <SectionWrapper className={props.className}>
      <GameQuery key={props.id} name={props.id} params={props.params}>
        {({ games, onLoadMore, isLoading }) =>
          (props.renderEmptyResults ||
            (!props.renderEmptyResults && !R.isEmpty(games))) && (
            <React.Fragment>
              <Section
                games={
                  props.placeholders && props.params.limit
                    ? gamesWithPlaceholders(games, props.params.limit)
                    : games
                }
                heading={
                  R.is(Function, props.heading)
                    ? props.heading(games)
                    : props.heading
                }
                icon={props.icon}
                tagPrefix={props.tagPrefix}
                path={props.path}
              />

              {onLoadMore && props.loadMore && (
                <Space top="15px">
                  <Flex justifyContent="center">
                    <Button
                      type="submit"
                      loading={isLoading}
                      onClick={onLoadMore}
                      minimalPadding
                    >
                      {props.translate('casino.category.load-more')}
                    </Button>
                  </Flex>
                </Space>
              )}
            </React.Fragment>
          )
        }
      </GameQuery>
    </SectionWrapper>
  )
}

GamesSection.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.string,
  id: PropTypes.string,
  loadMore: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string,
  placeholders: PropTypes.bool,
  renderEmptyResults: PropTypes.bool,
  tagPrefix: PropTypes.func,
  translate: PropTypes.func,
}

export default withTranslate(GamesSection)

import React from 'react'
import styled from '@emotion/styled'

import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../../common/html-content'
import { blackish, darkSun } from '../../constants/colors'

export const SegregationWarning = styled.div`
  padding-bottom: 17vh;
  padding-top: 110px;
  color: ${blackish};
  width: 60%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 899px) {
    display: none;
  }
`

export const SegregationMobileHeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    display: none;
  }
`

export const SegregationH1 = styled.span`
  color: ${darkSun};
  font-size: 52px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  line-height: 0.77;
  font-weight: 800;
  letter-spacing: -0.9px;
  margin: 5px;

  @media screen and (max-width: 899px) {
    margin: 8px;
    font-size: 32px;
  }
`

export const SegregationH2 = styled.span`
  font-size: 28px;
  line-height: 1.43;
  font-weight: 600;

  @media screen and (max-width: 899px) {
    font-size: 25px;
  }
`

const SegregationText = styled(HtmlContent)`
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${blackish};

  @media screen and (max-width: 899px) {
    max-height: 50vmin;
    overflow-y: auto;
    margin-top: 28px;
    margin-bottom: 120px;
    min-height: 150px;
    max-width: 500px;
    font-size: 15px;
    line-height: 20px;
  }

  @media screen and (max-width: 662px) {
    max-width: 270px;
  }
`

export const SegregationMobileWarning = styled.div`
  padding-bottom: 20px;
  @media screen and (min-width: 900px) {
    display: none;
  }
`

export const SegregationWarningText = withTranslate(props => (
  <SegregationText
    html={{ __html: props.translate('segregation-of-funds.warning-text') }}
  />
))

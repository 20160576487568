import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import { Field } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import InputField from '../common/input-field'
import fieldError from '../field-error'
import validators from '../validators'

function getTooltip(withdrawInformation, translate) {
  if (!withdrawInformation) {
    return ''
  }

  return translate('wallet.amountselector.notification', {
    bonusBalanceCents: withdrawInformation.bonusBalanceCents,
    moneyBalanceCents: withdrawInformation.moneyBalanceCents,
    wagerBalanceCents: withdrawInformation.wagerBalanceCents,
  })
}

class LimitAmountInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleAmountChange = this.handleAmountChange.bind(this)
    // this.handleNormalize = this.handleNormalize.bind(this)
  }

  handleAmountChange(value) {
    this.props.onAmountChange(value)
  }

  render() {
    // TODO this is for showing error message in welcome offers, error message should
    // be passed by forms module, but this does not happen because of field used in field
    // needs to be fixed in other way
    const tooltip =
      this.props.tooltip &&
      this.props.translate(this.props.tooltip, {
        limit: this.props.minAmount * 100,
        maxLimit: this.props.maxAmount * 100,
      })

    const error = fieldError(this.props.field)
      ? this.props.translate(fieldError(this.props.field), {
          limit: this.props.minAmount * 100,
          maxLimit: this.props.maxAmount * 100,
        })
      : null

    return (
      <Field
        autoFocus={this.props.autoFocus}
        small
        component={InputField}
        currency={this.props.currency && this.props.currency.toLowerCase()}
        dark
        label={this.props.translate('wallet.amountselector.typ-in-amount')}
        initialValue={this.props.initialValue}
        name={this.props.name}
        tooltip={
          tooltip ||
          error ||
          getTooltip(this.props.withdrawInformation, this.props.translate)
        }
        type="text"
        validator={R.reject(R.isNil, [
          validators.min(this.props.minAmount),
          validators.max(this.props.maxAmount),
          this.props.required && validators.required,
        ])}
        onChange={this.handleAmountChange}
        onFocus={this.props.onFocus}
        className={this.props.className}
      />
    )
  }
}

LimitAmountInput.defaultProps = {
  maxAmount: 2000,
  initialValue: null,
}

LimitAmountInput.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  field: PropTypes.object,
  initialValue: PropTypes.number,
  maxAmount: PropTypes.number.isRequired,
  minAmount: PropTypes.number,
  tooltip: PropTypes.string,
  translate: PropTypes.func,
  withdrawInformation: PropTypes.object,
  onAmountChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default withTranslate(LimitAmountInput)

import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Line = styled.hr`
  border: initial;
  border-color: transparent;
  height: ${props => `${props.height}px`};
  background-color: ${props => props.color};
  ${props => props.color === 'currentColor' && 'opacity: .5;'};
`

Line.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
}

Line.defaultProps = {
  color: 'currentColor',
  height: 1,
}

export default Line

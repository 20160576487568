import * as R from 'ramda'
import * as ReactRedux from 'react-redux'

import * as App from '../../store/app'
import * as Promotions from '../../store/promotions'

import { CampaignArea as Component } from './camapign-area'

export const CampaignArea = R.compose(
  ReactRedux.connect(state => ({
    promotions: R.slice(0, 3, state.promotions.items),
    serverTimeOffset: App.getServerTimeOffset(state.app),
  })),
  ReactRedux.connect(null, dispatch => ({
    onFetchPromotions: () => dispatch(Promotions.fetch()),
  }))
)(Component)

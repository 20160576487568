import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import Button from '../../../common/button'
import HtmlContent from '../../../common/html-content'
import { VideoModal } from '../video-modal'

const VideoLink = styled('div', {
  shouldForwardProp: Common.noneOf(['bgImage']),
})`
  position: absolute;
  right: 50px;
  bottom: 30px;
  height: 270px;
  width: 270px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('${props => props.bgImage}');
  &:hover {
    opacity: 0.9;
  }
`

export function VideoArea(props) {
  const i18n = I18n.useI18n()

  const [isPopupOpen, setIsPopupOpen] = React.useState()

  return (
    <Common.Box position="relative" display="flex">
      <Common.Box
        as="video"
        style={{ objectFit: 'cover' }}
        height={['300px', '400px', '450px', '650px']}
        width="100%"
        controls={false}
        preload="auto"
        autoPlay
        muted
        loop
        playsInline
      >
        <source
          src={`${props.assetPath}/landing-page/lp-video.mp4`}
          type="video/mp4"
        />
      </Common.Box>
      <Common.Box
        position="absolute"
        top="0"
        left="0"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(0, 0, 0, 0.2)"
      >
        <Common.Box
          fontSize={['25px', null, null, '48px']}
          fontWeight="900"
          paddingBottom={[5, null, null, 9]}
          maxWidth="80%"
          textAlign="center"
          textShadow="0 0 10px rgba(0, 0, 0, 0.2)"
        >
          <HtmlContent
            html={{
              __html: props.isReturningUser
                ? i18n.translate('landing-page.video-area.returning-user.title')
                : i18n.translate('landing-page.video-area.new-user.title'),
            }}
          />
        </Common.Box>

        {props.isReturningUser ? (
          <Button as={Common.Link} to="/login" variant="default">
            {i18n.translate('landing-page.video-area.returning-user.cta')}
          </Button>
        ) : (
          <Button as={Common.Link} to="/register" variant="primary">
            {i18n.translate('landing-page.video-area.new-user.cta')}
          </Button>
        )}

        <Common.Box
          display={['flex', null, null, 'none']}
          alignItems="center"
          onClick={() => setIsPopupOpen(true)}
          paddingTop={5}
        >
          <Common.Box
            size="24px"
            backgroundImage={`url(${i18n.translate(
              'landing-page.video-area.video-link-image-small'
            )})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            paddingRight={2}
          />
          <Common.Text fontSize={3} fontWeight="900">
            {i18n.translate('landing-page.video-area.video-link-text-small')}
          </Common.Text>
        </Common.Box>

        <Common.Box display={['none', null, null, 'block']}>
          <VideoLink
            bgImage={i18n.translate('landing-page.video-area.video-link')}
            onClick={() => setIsPopupOpen(true)}
          />
        </Common.Box>

        <VideoModal
          isPopupOpen={isPopupOpen}
          onRequestClose={() => setIsPopupOpen(false)}
        />
      </Common.Box>
    </Common.Box>
  )
}

VideoArea.propTypes = {
  assetPath: PropTypes.string.isRequired,
  isReturningUser: PropTypes.bool,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import { Flex, Image, Space } from '@rushplay/common'
import { buyItem, fetchStoreItems } from '@rushplay/api-client'
import {
  clear,
  getItems,
  getSelectedItem,
  isAnyItemSelected,
  isSelected,
  select,
  update,
} from '@rushplay/store'
import { getCurrency, getRubies } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import Text from '../common/text'
import { PageSpinner } from '../common/spinner'
import {
  getAssetPath,
  isWaiting,
  startWaiting,
  stopWaiting,
} from '../store/app'

import RubyStore from './ruby-store'

const BUY_ITEM = 'buy-item'

class RubyStoreContainer extends Component {
  componentWillMount() {
    // TODO: control refetch conditions
    this.props.fetchStoreItems({
      success: res => update(res.value),
      failure: () =>
        Notifications.add({ message: 'error.generic', level: 'error' }),
      version: 1,
    })
  }

  render() {
    if (
      R.equals('SEK', this.props.currency) &&
      this.props.isExistingTranslation('rubystore.under-maintenance')
    ) {
      return (
        <Space y={10} x={6}>
          <Flex column wide alignItems="center">
            <Text align="center">
              {this.props.translate('rubystore.under-maintenance')}
            </Text>
            <Space top="22px">
              <Image
                width="420"
                src={this.props.translate('rubystore.under-maintenance.image')}
                alt="maintenance"
              />
            </Space>
          </Flex>
        </Space>
      )
    }

    if (R.not(R.isEmpty(this.props.rows))) {
      return (
        <RubyStore
          assetPath={this.props.assetPath}
          isPopupOpen={this.props.isPopupOpen}
          isSelected={this.props.isSelected}
          rows={this.props.rows}
          rubiesAmount={this.props.rubiesAmount}
          selectedItem={this.props.selectedItem}
          onItemBuy={this.props.onItemBuy}
          onPopupClose={this.props.clear}
          onSelect={this.props.select}
          purchaseInProgress={this.props.purchaseInProgress}
        />
      )
    }

    return <PageSpinner />
  }
}

function getFormattedStoreItems(rows) {
  const sortedByNameAndPrice = R.sortWith(
    [R.ascend(R.prop('name')), R.ascend(R.prop('price'))],
    getItems(rows)
  )
  return R.groupBy(R.prop('key'), sortedByNameAndPrice)
}

function mapStateToProps(state) {
  return {
    assetPath: getAssetPath(state.app),
    currency: getCurrency(state.session),
    isPopupOpen: isAnyItemSelected(state.store),
    isSelected: id => isSelected(state.store, id),
    purchaseInProgress: isWaiting(state.app, BUY_ITEM),
    rubiesAmount: getRubies(state.session),
    //Can be simplified once the API is updated
    rows: getFormattedStoreItems(state.store),
    selectedItem: getSelectedItem(state.store),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clear,
      fetchStoreItems,
      onItemBuy: (id, blitz) => {
        const options = blitz ? { id, blitz } : id
        return [
          startWaiting(BUY_ITEM),
          buyItem(options, {
            success: () => [clear(), stopWaiting(BUY_ITEM)],
            failure: res => [
              Notifications.add({
                message: res.value.message || 'error.generic',
                level: 'error',
              }),
              stopWaiting(BUY_ITEM),
            ],
            version: 1,
          }),
        ]
      },
      select,
    },
    dispatch
  )
}

RubyStoreContainer.propTypes = {
  assetPath: PropTypes.string,
  clear: PropTypes.func,
  currency: PropTypes.string,
  fetchStoreItems: PropTypes.func,
  isExistingTranslation: PropTypes.func.isRequired,
  isPopupOpen: PropTypes.bool,
  isSelected: PropTypes.func,
  rows: PropTypes.object,
  rubiesAmount: PropTypes.number,
  select: PropTypes.func,
  selectedItem: PropTypes.object,
  translate: PropTypes.func.isRequired,
  onItemBuy: PropTypes.func.isRequired,
  purchaseInProgress: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslate(RubyStoreContainer)
)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { noneOf } from './none-of'

/**
 * Render <img> with additional control
 * @param {Object} props
 * @param {string} props.alt Image description
 * @param {string} props.fallback fallback src, to show onError
 * @param {(number|string)} [props.height] Image height in pixels
 * @param {string} props.placeholder placeholder src, to show onLoad
 * @param {string} [props.size="fluid"] Image sizing strategy.
 *   `fluid` (default) takes as much width as possible but not more than
 *   original image width.
 *   `fixed` forces image to be given size
 *   `full` takes as much width as possible regardless of original image size.
 * @param {string} props.src Image source URL
 * @param {(number|string)} [props.width] Image width in pixels
 * @return {ReactComponent}
 */
const Img = styled('img', {
  shouldForwardProp: noneOf(['as', 'fallback', 'placeholder', 'size']),
})`
  ${props =>
    ({
      fixed: css`
        width: ${props.width}px;
        height: ${props.height}px;
      `,
      full: css`
        width: 100%;
      `,
      fluid: css`
        width: 100%;
        max-width: ${props.width}px;
      `,
    }[props.size])};
  vertical-align: middle; /* Removes 7px space at bottom */
`

export class Image extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      loaded: false,
    }
    this.handleError = this.handleError.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount() {
    if (R.not(this.props.src)) {
      return this.handleError()
    }
    if (this.props.fallback && this.props.placeholder) {
      this.image = new window.Image()
      this.image.addEventListener('load', this.handleLoad)
      this.image.addEventListener('error', this.handleError)
      this.image.src = this.props.src
    }
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.removeEventListener('load', this.handleLoad)
      this.image.removeEventListener('error', this.handleError)
    }
  }

  handleError() {
    this.setState({ error: true })
  }

  handleLoad() {
    this.setState({ loaded: true })
  }

  render() {
    const src = this.state.error
      ? this.props.fallback
      : this.state.loaded
      ? this.props.src
      : this.props.placeholder
    return <Img {...this.props} src={src || this.props.src} />
  }
}

Image.defaultProps = {
  size: 'fluid',
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['fixed', 'fluid', 'full']),
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
}

import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'

import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

function GlobalModalCss(props) {
  return (
    <Global
      onClick={props.onRequestClose}
      styles={css`
        .modal__overlay {
          position: fixed;
          z-index: 102;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          overflow-y: auto;
          background-color: rgba(0, 0, 0, 0.5);
          @media screen and (min-width: 574px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      `}
    />
  )
}

GlobalModalCss.propTypes = {
  onRequestClose: PropTypes.func,
}

const ModalWrapper = styled(ReactModal)`
  outline: none;
  @media screen and (min-width: 574px) {
    position: relative;
    width: auto;
    max-width: 90vw;
    height: auto;
  }
`

function Modal(props) {
  return (
    <React.Fragment>
      <GlobalModalCss onRequestClose={props.onRequestClose} />
      <ModalWrapper
        ariaHideApp={false}
        className="modal"
        contentLabel="contentLabel"
        isOpen={props.isOpen}
        overlayClassName="modal__overlay"
        onRequestClose={props.onRequestClose}
        parentSelector={props.parentSelector}
        shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      >
        {props.children}
      </ModalWrapper>
    </React.Fragment>
  )
}

Modal.defaultProps = {
  shouldCloseOnOverlayClick: false,
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  parentSelector: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
}

export default Modal

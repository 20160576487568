import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { headingFont } from './../constants/typography'

const Wrapper = styled.svg`pointer-events: auto;`

function SvgAvatar(props) {
  const id = R.replace(/[^A-z0-9]/g, '', props.src)
  const frame = props.promo || props.multiplier > 1 ? 'promo' : 'default'
  const cursor = typeof props.onClick === 'function' ? 'pointer' : 'auto'

  return (
    <Wrapper
      width="100%"
      height="100%"
      viewBox="-50 -50 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <linearGradient id="default" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#ffffff" />
          <stop offset="84%" stopColor="#bdbdbd" />
        </linearGradient>
        <linearGradient id="promo" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#fbda61" />
          <stop offset="100%" stopColor="#f76b1c" />
        </linearGradient>
        <pattern
          id={id}
          x="-50"
          y="-50"
          patternUnits="userSpaceOnUse"
          height="100"
          width="100"
        >
          <image x="0" y="0" height="100" width="100" xlinkHref={props.src} />
        </pattern>
      </defs>
      <circle cx="0" cy="0" r="50" fill={`url(#${frame})`} />
      <circle cx="0" cy="0" r="43" fill={'#ffffff'} />
      <circle
        cursor={cursor}
        onClick={props.onClick}
        cx="0"
        cy="0"
        r="43"
        fill={id ? `url(#${id})` : 'white'}
      />
      {props.multiplier > 1 && (
        <g>
          <circle
            cursor={cursor}
            cx="25"
            cy="25"
            r="22"
            stroke="url(#promo)"
            strokeWidth="5"
            fill="#ffffff"
            onClick={props.onClick}
          />
          <text
            x="25"
            y="32"
            fontWeight="bold"
            fontSize="22"
            fontFamily={headingFont}
            fill="#1a396f"
            textAnchor="middle"
          >
            {props.multiplier}×
          </text>
        </g>
      )}
    </Wrapper>
  )
}

export default SvgAvatar

SvgAvatar.defaultProps = { src: '' }

SvgAvatar.propTypes = {
  multiplier: PropTypes.number,
  promo: PropTypes.bool,
  src: PropTypes.string,
  onClick: PropTypes.func,
}

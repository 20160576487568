import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { darkSun, night } from '../../constants/colors'
import { headingFont } from '../../constants/typography'

export const StepOneWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${night};

  display: flex;
  align-items: center;
  flex-direction: column;

  font-size: 2em;
  font-family: ${headingFont};
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  text-align: center;
`

export const StepOneTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StepTwoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${night};

  display: flex;
  align-items: center;
  flex-direction: column;

  font-size: 2em;
  font-family: ${headingFont};
  text-align: center;
`

export const StepTwoKing = styled.img`
  position: absolute;
  top: 10%;
  left: -10%;

  height: auto;
  width: 500px;

  @media screen and (max-width: 900px) {
    width: 500px;
    top: ${props => (props.active ? '18%' : '20%')};
    left: -5em;
  }

  @media screen and (max-width: 900px) and (max-height: 750px) {
    width: 400px;
    top: ${props => (props.active ? '18%' : '30%')};
    left: -5em;
  }

  @media screen and (max-width: 700px) and (max-height: 750px) {
    width: 400px;
    top: ${props => (props.active ? '18%' : '30%')};
    left: -9em;
  }

  @media screen and (max-height: 568px) {
    left: -10em;
    top: ${props => (props.active ? '20%' : '30%')};
  }
`

export const StepTwoBetser = styled.img`
  position: absolute;
  top: 0;
  right: -10%;
  height: auto;
  width: 500px;

  @media screen and (max-width: 900px) {
    width: 500px;
    top: ${props => (props.active ? '-5%' : '15%')};
    right: -4em;
  }

  @media screen and (max-width: 900px) and (max-height: 750px) {
    width: 400px;
    top: ${props => (props.active ? '10%' : '15%')};
    right: -4em;
  }

  @media screen and (max-width: 700px) and (max-height: 750px) {
    width: 400px;
    top: ${props => (props.active ? '10%' : '20%')};
    right: -7em;
  }

  @media screen and (max-height: 568px) {
    right: -7em;
    top: ${props => (props.active ? '10%' : '27%')};
  }
`

export const SpeechBubble = styled.div`
  position: absolute;
  top: -30%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  background-image: url(${props => props.speechBubble});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: black;
  font-size: 0.7em;
  padding: 3em 2em;

  @media screen and (max-height: 800px) {
    font-size: 0.5em;
    padding: 4em 2em;
    width: 150px;
    top: -15%;
  }

  @media screen and (max-width: 900px) {
    font-size: 0.6em;
    padding: 4em 2em;
    width: 150px;
    top: 0%;
    left: 50%;
  }

  @media screen and (max-width: 900px) and (min-height: 900px) and (max-height: 1024px) {
    top: -15%;
  }

  @media screen and (max-height: 568px) {
    top: 15%;
    left: ${props => (props.king ? '50%' : '45%')};
  }
`

export const AvatarsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const AvatarsCenterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  margin: auto;
`

export const StepTwoTitle = styled.span`
  font-size: 2em;
  color: ${darkSun};
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 0.5em;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);

  @media screen and (max-height: 750px) {
    font-size: 1em;
  }
`

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
`

export const KingAvatar = styled.img`
  transform: translateY(-50%);

  max-height: 350px;
  width: 300px;

  @media screen and (max-height: 750px) {
    max-height: 250px;
    width: 200px;
  }
`

export const OnboardingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const RegionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 130px);
  display: flex;
  align-items: ${props => (props.boss ? 'flex-start' : 'flex-end')};
  justify-content: ${props => (props.games ? 'flex-end' : 'center')};

  ${props =>
    props.games &&
    '@media screen and (min-width: 1280px) { align-items: center; }'};

  @media screen and (min-width: 768px) {
    height: calc(100% - 180px);
  }
`

const scaleIn = keyframes`
  from { transform: scale(10) translate(0, 0); }
  to { transform: scale(1) translate(2vh, -1.5vh); }
`

const circleAroundPlayer = keyframes`
  0% { transform: translate(2vh, -1.5vh); }
  25% { transform: translate(3vh, -1vh); }
  50% { transform: translate(2.5vh, -0.5vh); }
  75% { transform: translate(1vh, -0.5vh); }
  100% { transform: translate(2vh, -1.5vh); }
`

export const HighlightPlayer = styled.div`
  width: 20vh;
  height: 20vh;
  margin-bottom: 4vh;
  border-radius: 50%;
  box-shadow: 0 -20vmax 0 60vmax rgba(24, 51, 99, 0.6);

  animation: ${scaleIn} 1s 1 cubic-bezier(0.76, 0.01, 0.16, 1.13),
    ${circleAroundPlayer} 7s 1s ease-in-out infinite;
  animation-fill-mode: forwards;
`

const moveUp = keyframes`
  from {
    box-shadow: 0 -20vmax 0 60vmax rgba(24, 51, 99, .6);
    transform: translate(0, 190%);
  }
  to {
    box-shadow: 0 10vmax 0 60vmax rgba(24, 51, 99, .6);
    transform: translate(1vh, -2.5vh);
  }
`

const circleAroundBoss = keyframes`
  0% { transform: translate(1vh, -2.5vh); }
  25% { transform: translate(2vh, -2vh); }
  50% { transform: translate(1.5vh, -1.5vh); }
  75% { transform: translate(0.5vh, -2vh); }
  100% { transform: translate(1vh, -2.5vh); }
`

export const HighlightBoss = styled.div`
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  box-shadow: 0 10vmax 0 60vmax rgba(24, 51, 99, 0.6);

  animation: ${moveUp} 1s 1 cubic-bezier(0.76, 0.01, 0.16, 1.43),
    ${circleAroundBoss} 7s 1s ease-in-out infinite;
  animation-fill-mode: forwards;
`

const moveRight = keyframes`
  from {
    right: 50%;
    width: 20vh;
    height: 20vh;
    box-shadow: 0 0 0 60vmax rgba(24, 51, 99, .6);
    transform: translateX(50%) translateY(-50%);
  }
  50% {
    right: 0%;
    width: 20vh;
    height: 20vh;
    box-shadow: -40vmax 9vmax 0 55vmax rgba(24, 51, 99, .6);
    transform: translateX(0%) translateY(0%);
  }
  to {
    right: 0;
    top: 0%;
    width: 28vw;
    height: 85vw;
    box-shadow: -48vmax 10vmax 0 55vmax rgba(24, 51, 99, .6);
    transform: translateX(0%) translateY(0%);
  }
`

const moveRightBig = keyframes`
  from {
    right: 50%;
    width: 20vh;
    height: 20vh;
    box-shadow: 0 0 0 60vmax rgba(24, 51, 99, .6);
    transform: translateX(50%) translateY(-50%);
  }
  50% {
    right: 0%;
    height: 20vh;
    width: 20vh;
    box-shadow: -45vmax 0 0 55vmax rgba(24, 51, 99, .6);
    transform: translateX(0%) translateY(-50%);
  }
  to {
    top: 50%;
    width: 28vw;
    height: 85vw;
    box-shadow: -45vmax 0 0 55vmax rgba(24, 51, 99, .6);
    transform: translateX(0%) translateY(-50%);
  }
`

const opacityIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export const HighlightGames = styled.div`
  width: 28vw;
  max-width: 25vh;
  height: 100vw;
  max-height: 75vh;
  border-top-left-radius: 5em;
  border-bottom-left-radius: 5em;
  box-shadow: -45vw 0 0 50vmax rgba(24, 51, 99, 0.6);
  opacity: 0;
  animation: ${opacityIn} 0s 1 ease forwards;
  animation-delay: 0.8s;

  @media screen and (min-width: 1280px) {
    max-height: 580px;
    max-width: 200px;
  }
`

export const HighlightGamesFake = styled.div`
  position: absolute;
  max-width: 25vh;
  max-height: 75vh;
  top: 50%;
  border-radius: 10em;
  animation: ${moveRight} 0.8s 1 ease;

  @media screen and (min-width: 1280px) {
    max-height: 480px;
    max-width: 200px;
    animation: ${moveRightBig} 0.8s 1 ease;
  }
`

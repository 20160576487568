import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as locks from '@rushplay/compliance/locks'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { connectForm, selectors } from '@rushplay/legacy-forms'
import { requestPlayerLimit } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import { UnsetDepositLimits } from './unset-deposit-limits'

function mapStateToProps(state) {
  const lock = locks.getLock(state.locks, { type: 'unset-deposit-limits' })

  const dayValue = selectors.value(state.forms, {
    form: 'unset-deposit-limits',
    field: 'day',
  })

  const weekValue = selectors.value(state.forms, {
    form: 'unset-deposit-limits',
    field: 'week',
  })

  const monthValue = selectors.value(state.forms, {
    form: 'unset-deposit-limits',
    field: 'month',
  })

  const formValues = {
    day: dayValue || 0,
    week: weekValue || dayValue * 7,
    month: monthValue || dayValue * 30,
  }

  const displayValues = {
    day: dayValue,
    week: weekValue,
    month: monthValue,
  }

  return {
    crossbrand: R.pathOr(
      false,
      ['crossbrand'],
      jurisdiction.getMoneyLimit(state.jurisdiction, {
        type: 'deposit',
        period: 'day',
      })
    ),
    displayValues,
    formValues,
    getFormError: field =>
      selectors.errors(state.forms, {
        form: 'unset-deposit-limits',
        field,
      }),
    initialLimitAmounts: R.pathOr({}, ['options', 'defaultValues'], lock),
    excessive: jurisdiction.getMoneyLimitExcess(state.jurisdiction, {
      type: 'deposit',
      period: 'week',
      valueCents: parseInt(formValues.week) * 100,
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: values =>
      dispatch(
        R.flatten([
          R.map(
            period =>
              requestPlayerLimit('deposit', period, values[period] * 100, {
                success: () => [
                  locks.fetch(),
                  locks.remove('unset-deposit-limits'),
                ],
                failure: () =>
                  Notifications.add({
                    message: 'player-limits.errors.set-limit',
                    variables: {
                      target: 'deposit',
                      period,
                    },
                    level: 'error',
                  }),
                version: 2,
              }),
            R.keys(values)
          ),
        ])
      ),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    crossbrand: stateProps.crossbrand,
    displayValues: stateProps.displayValues,
    excessive: stateProps.excessive,
    formValues: stateProps.formValues,
    getFormError: stateProps.getFormError,
    initialLimitAmounts: stateProps.initialLimitAmounts,
    isSubmitDisabled: R.not(
      R.all(item => R.gte(item, 100), R.values(stateProps.formValues))
    ),
    onSubmit: () => dispatchProps.onSubmit(stateProps.formValues),
  }
}

const connector = R.compose(
  c => connectForm(c, { name: 'unset-deposit-limits' }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTranslate
)

export default connector(UnsetDepositLimits)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import BlitzIframeContainer from '../game-iframe-container/blitz'
import GameIframeContainer from '../game-iframe-container'
import MicrogamingGameContainer from '../microgaming-game-container'
import NetEntGameContainer from '../netent-game-container'
import PlayNGoGameContainer from '../playngo-game-container'
import { LottolandIframeContainer } from '../lottoland-iframe-container'

/**
 * Fetch and render a game in a provider specific iFrame.
 * @param {Object} props
 * @param {string} props.betHistoryUrl Path for bet history page
 * @param {string} [props.clientType="browser"] Device type of the client, `browser` or `mobile-browser`
 * @param {string} props.gameId Id of game that will be fetched and rendered
 * @param {string} props.classicModeGameUrl Used to construct a url for Blitz Bonus Rounds (Required if you want to integrate Blitz-mode)
 * @param {string} props.language Player's current site language
 * @param {string} props.lobbyPath Used to construct lobbyUrl. For instance `casino`
 * @param {string} props.mountPoint Used to find games reducer. If games is under `games: games.reducer`, pass `games`
 * @param {string} props.netentLiveCasinoLobby Path for Netent's live casino lobby
 * @param {number} [props.realityCheckTimeRemaining] Number of milliseconds before reality check should be displayed
 * @param {ReactComponent} [props.placeholder] a component to show while loading
 * @param {function} props.onError Error handling that needs to be handled in host
 * @param {function} [props.onStart] Can be used for adding fetched game to quick-games. Needs to be dispatched in host.
 * @param {function} [props.onLoad] Handles providers redirecting to our app (e.g. on game quit or gambling history view), which causes app rendered inside the app recursively.
 * @return {ReactComponent}
 */
export class GameLauncher extends React.Component {
  componentDidMount() {
    this.props.clientType && this.props.onFetch()
    this.props.onStart && this.props.onStart(this.props.gameId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientType !== this.props.clientType) {
      this.props.clientType && this.props.onFetch()
    }
  }

  componentWillUnmount() {
    this.props.onOptionsClear(this.props.gameId)
  }

  render() {
    if (R.isNil(R.path(['game', 'options'], this.props))) {
      return <this.props.placeholder />
    }

    if (this.props.mode === 'blitz') {
      return (
        <BlitzIframeContainer
          game={this.props.game}
          classicModeGameUrl={this.props.classicModeGameUrl}
          onLoad={this.props.onLoad}
        />
      )
    } else if (this.props.game.provider === 'NetEnt') {
      return (
        <NetEntGameContainer
          authenticated={this.props.authenticated}
          game={this.props.game}
          liveCasinoLobby={this.props.netentLiveCasinoLobby}
          onError={this.props.onError}
          isRealityCheck={this.props.isRealityCheck}
        />
      )
    } else if (this.props.game.provider === 'Microgaming') {
      return (
        <MicrogamingGameContainer
          game={this.props.game}
          onError={this.props.onError}
          isRealityCheck={this.props.isRealityCheck}
        />
      )
    } else if (this.props.game.provider === 'Playngo') {
      return <PlayNGoGameContainer game={this.props.game} />
    } else if (this.props.game.launchMethod === 'lottoland-launcher') {
      return (
        <LottolandIframeContainer
          game={this.props.game}
          onEvent={this.props.onEvent}
        />
      )
    } else {
      return (
        <GameIframeContainer
          game={this.props.game}
          onLoad={this.props.onLoad}
        />
      )
    }
  }
}

GameLauncher.defaultProps = {
  mode: 'classic',
  placeholder: () => null,
}

GameLauncher.propTypes = {
  authenticated: PropTypes.bool,
  betHistoryUrl: PropTypes.string.isRequired,
  clientType: PropTypes.string,
  children: PropTypes.node,
  game: PropTypes.object,
  gameId: PropTypes.string.isRequired,
  isRealityCheck: PropTypes.string.isRequired,
  classicModeGameUrl: PropTypes.string,
  language: PropTypes.string.isRequired,
  lobbyPath: PropTypes.string.isRequired,
  mountPoint: PropTypes.string.isRequired,
  mode: PropTypes.string,
  netentLiveCasinoLobby: PropTypes.string,
  placeholder: PropTypes.func,
  realityCheckTimeRemaining: PropTypes.number,
  onError: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
  onFetch: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
  onOptionsClear: PropTypes.func.isRequired,
  onStart: PropTypes.func,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

class NetEntGameContainer extends React.Component {
  constructor() {
    super()
    this.netEntExtendRef
    this.gameLaunchSuccess = this.gameLaunchSuccess.bind(this)
  }

  gameLaunchSuccess(netEntExtend) {
    this.netEntExtendRef = netEntExtend
    netEntExtend.addEventListener('gameReady', () => {
      netEntExtend.addEventListener('gameRoundStarted', () =>
        netEntExtend.get(
          'balanceInCurrency',
          this.props.onParseAndUpdateBalance
        )
      )
      netEntExtend.addEventListener('gameRoundEnded', () =>
        netEntExtend.get(
          'balanceInCurrency',
          this.props.onParseAndUpdateBalance
        )
      )
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.isRealityCheck !== prevProps.isRealityCheck) {
      this.props.onGameStop(this.netEntExtendRef)
    }
  }

  handleGameInclusion() {
    this.props.onGameLaunch(
      this.props.game.options,
      this.props.authenticated,
      this.gameLaunchSuccess
    )
    window.netent_onLeaveTableClicked = this.props.redirectToLobby
  }

  render() {
    return (
      <React.Fragment>
        <div id="neGameClient" />
        {R.path(['game', 'options'], this.props) && (
          <Helmet
            onChangeClientState={(state, { scriptTags }) => {
              if (scriptTags) {
                const scriptTag = scriptTags.filter(
                  item => item.src.indexOf('gameinclusion') >= 0
                )
                if (scriptTag.length) {
                  scriptTag[0].onload = this.handleGameInclusion.bind(this)
                }
              }
            }}
          >
            <script
              src={`${
                this.props.game.options.staticServer
              }/gameinclusion/library/gameinclusion.js`}
            />
          </Helmet>
        )}
        {this.props.children}
      </React.Fragment>
    )
  }
}

NetEntGameContainer.propTypes = {
  authenticated: PropTypes.bool,
  children: PropTypes.node,
  game: PropTypes.object.isRequired,
  isRealityCheck: PropTypes.string.isRequired,
  liveCasinoLobby: PropTypes.string.isRequired,
  redirectToLobby: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onGameLaunch: PropTypes.func,
  onGameStop: PropTypes.func,
  onParseAndUpdateBalance: PropTypes.func,
}

export default NetEntGameContainer

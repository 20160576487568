import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import memoize from 'fast-memoize'
import { v4 as generateUuid } from 'uuid'

import { withTranslate } from '@rushplay/i18n'

import Panel from '../common/panel'
import TileList from '../common/tileList'

import GameTile from './game-tile'
import getCasinoGameUrl from './get-casino-game-url'

// get array with props for GameTile placeholders
const placeholders = memoize(count =>
  R.map(
    () => ({
      key: generateUuid(),
      placeholder: true,
    }),
    Array(count)
  )
)

const Section = props => {
  const limitedGames = limitItems(props.limit, props.games)
  return (
    <Panel
      icon={props.icon}
      linkLabel={props.translate('casino.show-more')}
      linkUrl={props.path}
      title={props.heading}
      className={props.className}
    >
      <TileList type={props.layout || limitedGames.length > 6 ? 'grid' : 'row'}>
        {R.map(game => {
          return (
            <GameTile
              loginRequired={game.loginRequired}
              hasBlitzMode={game.blitzEnabled}
              imgSrc={game.imageUrl}
              title={game.title}
              provider={game.displayProvider || game.provider}
              decorations={getDecorations(game.tags, props.tagPrefix)}
              jackpotValue={game.jackpotValue}
              jackpotVelocity={game.jackpotVelocity}
              key={game.key}
              jackpotUpdatedAt={game.jackpotUpdatedAt}
              linkTo={getCasinoGameUrl(game)}
              placeholder={game.placeholder}
              preRelease={
                game.preRelease &&
                props.translate(
                  getPreReleaseTranslation(
                    props.isExistingTranslation,
                    game.key
                  )
                )
              }
            />
          )
        }, limitedGames)}
      </TileList>
    </Panel>
  )
}

export default withTranslate(Section)

// --

function limitItems(limit, items) {
  if (!limit) {
    return items
  }

  return R.take(limit, R.concat(items, placeholders(limit)))
}

function getDecorations(tags, tagPrefix) {
  const tag = tag => R.includes(tagPrefix(tag), tags || [])

  if (tag('exclusive')) {
    return {
      label: 'game-tile.exclusive',
    }
  }

  if (R.not(tag('exclusive')) && tag('hot')) {
    return {
      ribbon: 'game-tile.hot',
    }
  }

  if (R.not(tag('exclusive')) && R.not(tag('hot')) && tag('new')) {
    return {
      ribbon: 'game-tile.new',
    }
  }

  return {}
}

// --

function getPreReleaseTranslation(isExistingTranslation, gameKey) {
  const customPreReleaseTranslation = `games.coming-soon.${gameKey}`

  return isExistingTranslation(customPreReleaseTranslation)
    ? customPreReleaseTranslation
    : 'games.coming-soon'
}

Section.defaultProps = {
  tagPrefix: () => {},
}

Section.propTypes = {
  className: PropTypes.string,
  games: PropTypes.array,
  heading: PropTypes.string,
  icon: PropTypes.string,
  isExistingTranslation: PropTypes.func.isRequired,
  layout: PropTypes.string,
  limit: PropTypes.number,
  path: PropTypes.string,
  tagPrefix: PropTypes.func,
  translate: PropTypes.func,
}

import * as R from 'ramda'
import { createSelector } from 'reselect'

import regions from './regions'
import treasures from './treasures'
import worlds from './worlds'

const _getRegions = R.compose(regions.selectors.getRegions, R.prop('regions'))

const _getTreasures = R.compose(
  treasures.selectors.getTreasures,
  R.prop('treasures')
)
const _getCurrentWorld = R.compose(
  worlds.selectors.getCurrentWorld,
  R.prop('worlds')
)

const _getCurrentRegionKey = createSelector(
  _getCurrentWorld,
  R.propOr('', 'currentRegionKey')
)

const _getCurrentWorldRegions = createSelector(
  _getCurrentWorld,
  R.propOr([], 'regions')
)

const _getCurrentWorldTreasures = createSelector(
  _getCurrentWorld,
  R.propOr([], 'treasures')
)

const _getLastSeenProgress = createSelector(
  _getCurrentWorld,
  R.propOr(0, 'lastSeenProgress')
)

const _getRegionProgress = createSelector(
  _getCurrentWorld,
  R.propOr(0, 'regionProgress')
)

export const getMapPosition = createSelector(
  _getCurrentWorld,
  R.propOr(0, 'mapPosition')
)

const CURRENT_REGION_FALLBACK = {}
export const getCurrentRegion = createSelector(
  [_getCurrentRegionKey, _getRegions],
  R.propOr(CURRENT_REGION_FALLBACK)
)

export const getCurrentRegionIndex = createSelector(
  [_getCurrentRegionKey, _getCurrentWorldRegions],
  R.indexOf
)

const REGION_FALLBACK = {}
export const getRegions = createSelector(
  [
    _getRegions,
    _getCurrentWorldRegions,
    _getCurrentWorld,
    state => worlds.selectors.getCurrentWorld(state.worlds).key,
  ],
  (regions, keys, currentWorld, currentWorldKey) => {
    const sortedRegions = R.map(R.propOr(REGION_FALLBACK, R.__, regions), keys)
    const lastRegionLens = R.lensIndex(sortedRegions.length - 1)
    // since we fight boss of current region, but not guardian of next boss
    // and be returns the same boss image for last two regions, we want
    // to show a prize image instead of the very last boss image.
    // might change that after a discussion with be
    const _regions = R.over(
      lastRegionLens,
      R.assoc('bossAvatar', currentWorld.prizeImageUrl)
    )(sortedRegions)

    // construct "fake" region in order to display emptiness image
    // at the end of world
    return R.append(
      {
        image: currentWorld.emptinessImageUrl,
        key: `${currentWorldKey}-last-region`,
      },
      _regions
    )
  }
)

const _calculateMapPosition = (index, progress) => index * 100 + progress

export const getProgress = createSelector(
  [getCurrentRegionIndex, _getRegionProgress],
  _calculateMapPosition
)

export const getMapStartPosition = createSelector(
  [getCurrentRegionIndex, _getLastSeenProgress],
  _calculateMapPosition
)

const TREASURE_FALLBACK = {}
export const getTreasures = createSelector(
  [_getTreasures, _getCurrentWorldTreasures],
  (treasures, keys) => R.map(R.propOr(TREASURE_FALLBACK, R.__, treasures), keys)
)

export const getClosestTreasureProgress = createSelector(
  [getTreasures, getCurrentRegionIndex],
  (treasures, currentRegionIndex) => {
    const closestTreasure = R.head(treasures)

    if (closestTreasure) {
      return currentRegionIndex * 100 + closestTreasure.progressPercent
    }
  }
)

export const getBossImage = createSelector(
  [_getCurrentWorld, getCurrentRegion],
  (currentWorld, currentRegion) => {
    return R.propOr(false, 'isLastRegion', currentWorld)
      ? currentWorld.prizeImageUrl
      : currentRegion.bossAvatar
  }
)

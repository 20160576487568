export const CLAIM_GIFT = 'claim-gift'
export const COLLECT_TREASURE = 'collect-treasure'
export const FETCH_PLAYER_INFO = 'fetch-player-info'
export const FETCH_CAMPAIGNS = 'fetch-campaigns'
export const FETCH_PROMOTIONS = 'fetch-promotions'
export const FETCH_WORLDS = 'fetch-worlds'
export const SELECT_WORLD = 'select-world'
export const USE_ITEM = 'use-item'
export const CLAIM_SECRET_ITEM = 'claim-secret-item'
export const LOAD_TRANSACTION_ITEMS = 'load-transaction-items'
export const UPDATE_DEPOSIT_INFORMATION = 'update-deposit-information'
export const UPDATE_WITHDRAW_INFORMATION = 'update-withdraw-information'

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export class LottolandIframeContainer extends React.Component {
  constructor() {
    super()
    this.handleScriptLoad = this.handleScriptLoad.bind(this)
  }

  componentDidMount() {
    this.handleScriptLoad()

    window.lottolandEventRouter = eventData => {
      switch (eventData.type) {
        case 'betPlaced':
          this.props.onEvent('lottoland/bet-placed')
          break
        case 'userLogin':
          this.props.onEvent('lottoland/login')
          break
        case 'topUp':
          this.props.onEvent('lottoland/deposit')
          break
        case 'fundingSettings':
          this.props.onEvent('lottoland/money-limit')
          break
      }
    }
  }

  componentWillUnmount() {
    if (window.llsdk) {
      window.llsdk.destroy()
    }
    delete window.lottolandEventRouter
    window.clearTimeout(this.timeout)
  }

  handleScriptLoad() {
    if (window.llsdk) {
      window.llsdk.init()
    } else {
      this.timeout = window.setTimeout(this.handleScriptLoad, 200)
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <script src="https://jscdn.lttlapp.com/sdk/sdk.v1.js" />
        </Helmet>
        <lottoland-app
          data-version="1.0"
          data-operator-id={this.props.game.options.operatorId}
          data-host={this.props.game.options.launchUrl}
          data-path={this.props.game.options.gamePath}
          data-event-router="lottolandEventRouter"
          data-query-currency={this.props.game.options.currency}
          data-query-country={this.props.game.options.country}
          data-query-user-token={this.props.game.options.userToken || null}
          data-query-locale={this.props.game.options.locale}
        />
      </div>
    )
  }
}

LottolandIframeContainer.propTypes = {
  countryCode: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  game: PropTypes.object,
  gamePath: PropTypes.string,
  launchUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  operatorId: PropTypes.string.isRequired,
  sessionToken: PropTypes.string.isRequired,
  onEvent: PropTypes.func,
}

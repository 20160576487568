import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withCurrentTime } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { Limits as Component } from './limits'

function mapStateToProps(state, props) {
  const limit = jurisdiction.getSessionLimit(state.jurisdiction, {
    id: 0,
  })
  return {
    ids: jurisdiction.getSessionLimits(state.jurisdiction, {
      type: props.target,
    }),
    crossbrand: R.path(['crossbrand'], limit),
    cooldown: R.pathOr(0, ['cooldown'], limit),
    license: jurisdiction.getLicense(state.jurisdiction),
  }
}

export const connector = connect(mapStateToProps)

export const Limits = R.compose(
  withRouter,
  connector,
  withTranslate,
  withCurrentTime
)(Component)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { Constraint, Flex, Space, Text } from '@rushplay/common'
import {
  Field,
  FormPropTypes,
  actions as formActions,
  connectForm,
  selectors,
} from '@rushplay/legacy-forms'
import { fetchSession, updateMe as updatePlayer } from '@rushplay/api-client'
import { getEmail, session } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Card from '../../common/card'
import CheckboxField from '../../common/checkbox-field'
import Heading from '../../common/heading'
import InputField from '../../common/input-field'
import fieldError from '../../field-error'
import validators from '../../validators'
import { waitDashboard } from '../../store/app'

const Row = styled.div`
  margin-bottom: 10px;
`
const InlineRow = styled(Row)`
  display: flex;
`
const ZipCodeWrapper = styled.div`
  flex: 1;
  margin-right: 10px;
`
const CityWrapper = styled.div`
  flex: 2;
`

const gatherAddress = R.converge(R.mergeRight, [
  R.omit(['city', 'street', 'zip']),
  R.pipe(
    R.pick(['city', 'street', 'zip']),
    R.unless(R.isEmpty, R.objOf('address'))
  ),
])

class EditDetails extends Component {
  constructor() {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    if (!this.props.isFormPristine) {
      const userData = R.pipe(
        R.filter((field) => field.value !== field.initialValue),
        R.pluck('value'),
        gatherAddress
      )(this.props.fields)

      this.props.onSubmit(userData)
    } else {
      this.props.redirectTo('/dashboard')
    }
  }

  render() {
    return (
      <Constraint width="500" centered>
        <Space top={8} x="10px">
          <Card onRequestClose={() => this.props.redirectTo('/dashboard')}>
            <Space all={6}>
              <Flex>
                <Heading level="2" align="center">
                  {this.props.translate('user-dashboard.edit-details')}
                </Heading>
              </Flex>
              <Flex>
                <Text align="center">
                  {this.props.translate('user-dashboard.edit-details.info')}
                </Text>
              </Flex>
              <Space all={3}></Space>
              <Row>
                <InputField
                  disabled
                  label={this.props.translate('sign-up.first-name.label')}
                  name="firstName"
                  type="text"
                  value={this.props.initialValues.firstName}
                />
              </Row>
              <Row>
                <InputField
                  disabled
                  label={this.props.translate('sign-up.last-name.label')}
                  name="lastName"
                  type="text"
                  value={this.props.initialValues.lastName}
                />
              </Row>
              <Row>
                <InputField
                  disabled
                  label={this.props.translate('sign-up.email.label')}
                  name="email"
                  type="email"
                  value={this.props.initialValues.email}
                />
              </Row>
              <Row>
                <Field
                  disabled
                  component={InputField}
                  initialValue={this.props.initialValues.street}
                  label={this.props.translate('sign-up.street.label')}
                  name="street"
                  tooltip={
                    fieldError(this.props.fields.street) &&
                    this.props.translate(fieldError(this.props.fields.street))
                  }
                  type="text"
                  validator={validators.required}
                />
              </Row>
              <InlineRow>
                <ZipCodeWrapper>
                  <Field
                    disabled
                    component={InputField}
                    initialValue={this.props.initialValues.zip}
                    label={this.props.translate('sign-up.zip.label')}
                    name="zip"
                    tooltip={
                      fieldError(this.props.fields.zip) &&
                      this.props.translate(fieldError(this.props.fields.zip))
                    }
                    type="text"
                    validator={validators.required}
                  />
                </ZipCodeWrapper>
                <CityWrapper>
                  <Field
                    disabled
                    component={InputField}
                    initialValue={this.props.initialValues.city}
                    label={this.props.translate('sign-up.city.label')}
                    name="city"
                    tooltip={
                      fieldError(this.props.fields.city) &&
                      this.props.translate(fieldError(this.props.fields.city))
                    }
                    type="text"
                    validator={validators.required}
                  />
                </CityWrapper>
              </InlineRow>
              <Row>
                <Field
                  component={CheckboxField}
                  initialValue={this.props.initialValues.allowSMS}
                  label={this.props.translate('user-dashboard.allow-sms')}
                  name="allowSMS"
                />
              </Row>
              <Row>
                <Field
                  component={CheckboxField}
                  initialValue={this.props.initialValues.allowEmail}
                  label={this.props.translate('user-dashboard.allow-email')}
                  name="allowEmail"
                />
              </Row>
              <Flex justifyContent="center">
                <Button
                  disabled={!this.props.isFormValid}
                  loading={this.props.isLoading}
                  onClick={this.handleSubmit}
                >
                  {this.props.translate('user-dashboard.save')}
                </Button>
              </Flex>
            </Space>
          </Card>
        </Space>
      </Constraint>
    )
  }
}

const isFieldPristine = R.curry((formState, form, field) => {
  return selectors.isPristine(formState, {
    form,
    field,
  })
})

function mapStateToProps(state, ownProps) {
  return {
    isFormPristine: R.all(isFieldPristine(state.forms, ownProps.form), [
      'street',
      'zip',
      'city',
      'allowSMS',
      'allowEmail',
    ]),
    loading: state.app.waitDashboard,
    initialValues: {
      allowEmail: R.path(['player', 'allowEmail'], state.session),
      allowSMS: R.path(['player', 'allowSMS'], state.session),
      lastName: R.path(['player', 'address', 'lastName'], state.session),
      email: getEmail(state.session),
      firstName: R.path(['player', 'address', 'firstName'], state.session),
      street: R.path(['player', 'address', 'street'], state.session),
      zip: R.path(['player', 'address', 'zip'], state.session),
      city: R.path(['player', 'address', 'city'], state.session),
    },
    isFormValid: selectors.isAllFieldsHaveStatus(state.forms, {
      form: ownProps.form,
      status: 'valid',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      redirectTo,

      onSubmit: (userData) => [
        waitDashboard(true),
        updatePlayer(userData, {
          success: () =>
            fetchSession({
              success: (res) => [
                redirectTo('/dashboard'),
                formActions.clearForm('edit-details'),
                waitDashboard(false),
                session.update(res.value),
              ],
              failure: (error) =>
                Notifications.add({ message: error.value, level: 'error' }),
              version: 1,
            }),
          failure: (error) => [
            waitDashboard(false),
            Notifications.add({ message: error.value, level: 'error' }),
          ],
          version: 1,
        }),
      ],
    },
    dispatch
  )
}

export default withTranslate(
  connectForm(connect(mapStateToProps, mapDispatchToProps)(EditDetails), {
    name: 'edit-details',
  })
)

EditDetails.propTypes = {
  fields: PropTypes.shape({
    city: FormPropTypes.field,
    street: FormPropTypes.field,
    zip: FormPropTypes.field,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isFormValid: PropTypes.bool.isRequired,
  isFormPristine: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import { textFont } from '../constants/typography'

const Wrapper = styled.li`
  height: 100%;
  max-width: 150px;
  text-align: center;
  font-family: ${textFont};
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4em;
  align-self: flex-start;
`

const BigIcon = styled(Icon)`
  font-size: 5em;
  padding-bottom: 10px;
`

function InfoIcon(props) {
  return (
    <Wrapper background={props.background}>
      <BigIcon name={props.icon} />
      <HtmlContent html={{ __html: props.label }} />
    </Wrapper>
  )
}

InfoIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  background: PropTypes.string,
}

export default InfoIcon

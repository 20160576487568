import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export function GoogleTagManager(props) {
  const initialDataLayer = {
    'site.environment': props.environment,
    'site.version': props.version,
    'site.websiteId': props.language,
    'site.websiteName': props.websiteName,
    'user.acceptedCookies': props.cookieConsent,
    'user.isInternal': props.isIpInternal,
  }

  return (
    <Helmet>
      <script>
        {`
          window.dataLayer.push(${JSON.stringify(initialDataLayer)});
        `}
      </script>
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${props.gtmKey}');
        `}
      </script>
    </Helmet>
  )
}

GoogleTagManager.propTypes = {
  cookieConsent: PropTypes.bool.isRequired,
  environment: PropTypes.string.isRequired,
  gtmKey: PropTypes.string.isRequired,
  isIpInternal: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  websiteName: PropTypes.string.isRequired,
}

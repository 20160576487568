import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { blueGrey, blueGreyPale } from '../constants/colors'

function createCloseIcon(background, options = {}) {
  return css`
    &::after,
    &::before {
      position: absolute;
      top: ${options.top || 'auto'};
      right: ${options.right || 'auto'};
      bottom: ${options.bottom || 'auto'};
      left: ${options.left || 'auto'};
      width: 3px;
      height: 24px;
      content: ' ';
      background-color: ${background};
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before {
      transform: rotate(45deg);
    }
  `
}

const CloseIcon = styled.button`
  position: relative;
  margin-left: auto;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover,
  &:active {
    &::after,
    &::before {
      background-color: ${props => props.theme.variants.secondaryHover};
    }
  }

  ${createCloseIcon(props => props.theme.variants.secondary)};
`

const CloseButton = styled.button`
  position: relative;
  margin-left: auto;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  border: none;
  outline: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

  &:hover,
  &:active,
  &:focus {
    &::after,
    &::before {
      background-color: ${blueGreyPale};
    }
  }

  &:focus {
    box-shadow: 0 0 4px 4px #376dce;
  }

  ${createCloseIcon(blueGrey, { top: '8px', left: '18px' })};
`

CloseIcon.propTypes = { onClick: PropTypes.func.isRequired }
CloseButton.propTypes = { onClick: PropTypes.func.isRequired }
export { CloseIcon, CloseButton }

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import * as forms from '@rushplay/forms'
import styled from '@emotion/styled'
import { Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { StyledLink } from '../styled-link'

import OfferSelector from './offer-selector'

const TermsAndConditions = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
`

const OfferHeadline = styled.h4`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`

function DepositOffer(props) {
  return (
    <React.Fragment>
      <Space bottom={1}>
        <OfferHeadline>{props.translate(props.headlineLabel)}</OfferHeadline>
      </Space>

      <forms.Field
        initialValue={R.head(props.depositOffers).id}
        name="preferredWelcomeOfferType"
        mountPoint="formsv2"
      >
        {(field) => (
          <OfferSelector
            {...field.getProps(props)}
            currency={props.currency}
            depositNumber={props.depositNumber}
            depositOffers={props.depositOffers}
            selectedType={props.selectedType}
          />
        )}
      </forms.Field>

      <TermsAndConditions>
        <StyledLink
          to={{
            pathname: props.location.pathname,
            query: { popup: `tier-${props.depositNumber}.option-0` },
          }}
        >
          {props.translate('welcome-offer.package-0')}
        </StyledLink>
        {props.depositOffers.length > 1 && (
          <StyledLink
            to={{
              pathname: props.location.pathname,
              query: { popup: `tier-${props.depositNumber}.option-1` },
            }}
          >
            {props.translate('welcome-offer.package-1')}
          </StyledLink>
        )}
        <StyledLink
          to={{
            pathname: props.location.pathname,
            query: { popup: 'nooffer' },
          }}
        >
          {props.translate('welcome-offers.refusal')}
        </StyledLink>
      </TermsAndConditions>
    </React.Fragment>
  )
}

DepositOffer.propTypes = {
  currency: PropTypes.string.isRequired,
  depositNumber: PropTypes.number.isRequired,
  depositOffers: PropTypes.array,
  headlineLabel: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isExistingTranslation: PropTypes.func.isRequired,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  translate: PropTypes.func.isRequired,
}

export default R.compose(withRouter, withTranslate)(DepositOffer)

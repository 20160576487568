import * as ReactRedux from 'react-redux'
import * as PropTypes from 'prop-types'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as App from '../../../store/app'
import Button from '../../../common/button'
import LoginForm from '../../login-form/login-form'

const Wrapper = styled.div`
  align-self: stretch;
  margin-left: auto;

  @media screen and (min-width: 600px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media screen and (min-width: 1280px) {
    min-width: 529px;
    padding-left: 5px;
    padding-right: 16px;
  }
`

const LoginSmall = styled.div`
  padding-right: 8px;
  display: inline-flex;
  @media screen and (min-width: 1280px) {
    display: none;
  }
`

const LoginLarge = styled.div`
  display: none;
  @media screen and (min-width: 1280px) {
    display: inline-flex;
    align-items: center;
  }
`

export function Authentication(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  return (
    <Common.Flex alignItems="center">
      <Wrapper>
        {!props.showLoginForm && (
          <React.Fragment>
            <LoginSmall>
              <Common.Link to="/register">
                <Button small minimalPadding variant="primary">
                  {i18n.translate('header.register')}
                </Button>
              </Common.Link>
              {props.showLoginButton && (
                <Common.Space left={3} component="div">
                  <Button
                    small
                    minimalPadding
                    onClick={() => dispatch(App.setMobileMenuVisibility(true))}
                  >
                    {i18n.translate('header.login')}
                  </Button>
                </Common.Space>
              )}
            </LoginSmall>
            <LoginLarge>
              <LoginForm name="login" />
              {props.showRegister && (
                <Common.Space left={2}>
                  <Common.Link to="/register">
                    <Button minimalPadding variant="primary">
                      {i18n.translate('header.register')}
                    </Button>
                  </Common.Link>
                </Common.Space>
              )}
            </LoginLarge>
          </React.Fragment>
        )}
      </Wrapper>
    </Common.Flex>
  )
}

Authentication.propTypes = {
  showLoginButton: PropTypes.bool.isRequired,
  showLoginForm: PropTypes.bool.isRequired,
  showRegister: PropTypes.bool,
}

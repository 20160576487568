import styled from '@emotion/styled'

import Line from '../common/line'

import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
  textFont,
} from './../constants/typography'

const maxWrapperWidth = '414px'

export const LargeText = styled.span`
  color: white;
  font-family: ${textFont};
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
`

export const InfoText = styled.span`
  color: white;
  font-family: ${textFont};
  font-size: ${baseFontSizeSmall};
  font-weight: 300;
  text-align: center;
  line-height: 1.2;
`

export const Text = styled.span`
  color: white;
  font-family: ${textFont};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
`

/*TODO: Remove when PageNav will be redesigned */
export const PageNavWrapper = styled.div`
  margin-left: -1px;
  margin-right: -1px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.33);
  width: 100%;
  max-width: ${maxWrapperWidth};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  /*TODO: Remove when PageNav will be redesigned */
  overflow: hidden;

  @media screen and (min-width: 1280px) {
    min-height: calc(100vh - 198px);
  }
`

export const Panel = styled.div`
  width: 100%;
  max-width: ${maxWrapperWidth};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

export const Title = styled.h2`
  font-family: ${headingFont};
  font-size: ${baseFontSizeLarge};
  font-weight: 600;
  text-align: left;
  color: white;
`

export const StyledLine = styled(Line)`
  opacity: 0.5;
  margin: 0px;
`

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { getItem } from 'redux-effects-localstorage'
import { locationShape } from 'react-router/lib/PropTypes'
import { push as redirectTo } from 'react-router-redux'
import { rem } from 'polished'

import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { AspectRatio, Flex, Link, Space, withCountUp } from '@rushplay/common'
import { css } from '@emotion/core'
import { fetchLiveStatistics } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import * as lookup from '../store/lookup'
import Amount from '../common/amount'
import AvatarsRow from '../components/avatars-row'
import BigWinBackground from '../images/big-win-bg.svg'
import Button from '../common/button'
import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import LoginForm from '../components/login-form/login-form'
import PopupContainer from '../common/popup/popup-container'
import Scrollable from '../common/scrollable'
import Text from '../common/text'
import getPlayerCurrency from '../util/get-player-currency'
import home from '../store/home/'
import { GameQuery } from '../casino/game-query'
import { dark80, darkSun } from '../constants/colors'
import { fetchAvatars, getAvatars } from '../store/sign-up'
import {
  getConfig,
  getLanguageByLocation,
  getUsername,
  isCasitabi,
  isUk,
} from '../store/app'
import { headingFont } from '../constants/typography'
import { updateCampaigns } from '../store/campaigns'

import Concave from './concave'
import InfoIcon from './info-icon'
import {
  DoubleIconsWrapper,
  IconsInnerWrapper,
  IconsOuterWrapper,
} from './info-icons'
import {
  Game,
  GameImage,
  GameTitle,
  Games,
  GamesHeading,
  GamesTitle,
} from './games'
import {
  NewGameplay,
  NewGameplayHeading,
  NewGameplaySubtitle,
  NewGameplayTitle,
  World,
  WorldDescription,
  WorldImage,
  WorldTitle,
  Worlds,
} from './new-gameplay'
import { displayAvatars, worldsData } from './mocks.js'

const Avatars = styled.div`
  width: 100%;
  position: absolute;
  margin: auto;
  pointer-events: none;
  top: -120px;

  @media screen and (min-width: 1280px) {
    top: -170px;
  }
`

const Login = styled.aside`
  width: 100%;
  max-width: 300px;
  margin-top: 1em;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`

const CountUp = styled.div`
  font-size: 3.5rem;
  font-weight: 200;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
    order: 1;
  }
`

const TermsWrapper = styled.div`
  margin-top: 1em;
  text-align: center;
`

const TermsLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
`

const TermsSnippet = styled(HtmlContent)`
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-size: ${rem('11px')};
  color: ${dark80};
`

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 430px;
  position: relative;

  background-color: #0b1b54;
  background-image: url(${(props) => props.background});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`

const Header = styled(Flex)`
  position: relative;
  width: 100%;
  max-width: 1180px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${(props) => (props.isUK ? 60 : 140)}px;
  background-image: url(${BigWinBackground});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-x: -20px;
  background-position-y: -20px;

  ${(props) =>
    props.returningUser &&
    css`
      @media screen and (max-width: 1280px) {
        background-image: none;
      }
    `};

  @media screen and (max-width: 768px) {
    justify-content: space-around;
    flex-direction: column;
    background-image: none;
    padding-bottom: ${(props) => (props.isUK ? 60 : 270)}px;
  }

  @media screen and (max-width: 500px) {
    background-position-x: -70px;
  }
`

const Statistics = styled(Flex)`
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    display: none;
  }

  ${(props) =>
    props.returningUser &&
    `@media screen and (max-width: 1279px) {
      display: none;
    }`};
`

const PlayerCountLabel = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 16px;

    &:last-child {
      width: 150px;
      padding-bottom: 7px;
      padding-top: 7px;
    }
  }
`

const BigWinLabel = styled(PlayerCountLabel)`
  font-size: 20px;
  @media screen and (min-width: 769px) {
    font-size: 26px;
  }
`

const Highlight = css`
  color: ${darkSun};
  text-transform: uppercase;
`

const BigWinCongratulation = styled.div`
  ${Highlight};
  font-size: 13px;
  font-weight: 700;

  @media screen and (min-width: 769px) {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
  }
`

const Flag = styled(Icon)`
  font-size: 20px;
  margin-right: 10px;

  @media screen and (min-width: 769px) {
    font-size: 30px;
  }
`

const BigWinUser = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;

  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`

const WelcomeWrapper = styled(Flex)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 16px;
  }

  ${(props) =>
    props.returningUser &&
    `@media screen and (max-width: 1279px) {
      width: 100%;
    }`};
`

const NewUser = styled(Flex)`
  max-width: 400px;
  min-width: 300px;
`

const NewUserContent = styled.div`
  font-family: ${headingFont};
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 2px 7px rgba(0, 0, 0, 0.5);
  color: white;
`

const Returning = styled.div`
  max-width: 450px;
  width: auto;
  text-align: center;

  @media screen and (max-width: 768px) {
    order: 1;
    padding-top: 24px;
  }
`

const ReturningTitle = styled(NewUserContent)`
  ${Highlight};
  font-size: 2.7rem;
`

const ReturningSubtitle = styled.p`
  color: white;
  font-size: 2rem;
`

const KingQueenWrapper = styled.div`
  position: absolute;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  width: 100%;
  height: 100%;
  min-height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  @media screen and (max-width: 1279px) {
    max-width: 500px;
  }

  @media screen and (max-width: 900px) {
    max-width: 380px;
  }
`

const VideoBackground = styled.div`
  width: 100%;
  max-width: 1040px;
  max-height: 684px;
  height: 100%;
  border-radius: 10px;
  background-color: #f4db44;
  margin: 20px;
  margin-top: 90px;
`

const VideoTitle = styled(Text)`
  text-align: center;
  font-size: 36px;
  color: #183363;
  font-weight: bold;
  @media screen and (max-width: 518px) {
    font-size: 30px;
  }
  @media screen and (max-width: 439px) {
    font-size: 25px;
  }
  @media screen and (max-width: 370px) {
    font-size: 20px;
  }
`

const PromoImage = styled.img`
  width: 100%;
  height: 100%;
`

const NewUserPromotion = styled(HtmlContent)`
  ${Highlight};
`

const ButtonText = styled.div`
  text-transform: uppercase;
`

const ButtonWrapper = styled.div`
  margin-top: 1rem;
`

const withFormattedCountUp = compose(
  R.partialRight(withCountUp, [{ propName: 'children' }]),
  mapProps((props) => R.assoc('children', props.children, props))
)

const CurrencyCounter = withFormattedCountUp(Amount)

const PlayerCounter = withFormattedCountUp('span')

const CURRENCY_ICONS = ['bonus-eur', ' bonus-gbp', 'bonus-usd']

function toTranslationString(string) {
  return R.replace(/\s/g, '-', R.toLower(string))
}

class HomeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deposited: false,
    }
  }

  componentWillMount() {
    this.props
      .getItem('DEPOSITED')
      .then((data) => this.setState({ deposited: data }))
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )

    if (R.isEmpty(this.props.avatars)) {
      this.props.fetchAvatars()
    }

    this.props.fetchLiveStatistics({
      success: (res) => home.actions.updateStatistics(res.value),
      failure: () =>
        Notifications.add({ message: 'error.generic', level: 'error' }),
      version: 2,
    })
  }

  render() {
    return (
      <div>
        <HeaderWrapper
          background={this.props.translate('landing-page.island-opacitated')}
        >
          {!this.props.isUk && (
            <KingQueenWrapper
              isCasitabi={this.props.isCasitabi}
              background={this.props.translate('landing-page.king-and-queen')}
            />
          )}
          <Header
            alignItems="center"
            justifyContent="space-between"
            isUK={this.props.isUk}
            returningUser={this.props.username}
          >
            <Statistics
              column
              alignItems="center"
              justifyContent="space-around"
              returningUser={this.props.username}
            >
              <Flex center column>
                <BigWinLabel>
                  {this.props.translate('logged-out.big-win.label')}
                </BigWinLabel>
                <BigWinCongratulation>
                  {this.props.translate('logged-out.congratulation.label')}
                </BigWinCongratulation>

                <Flex center>
                  <Flag
                    name={`flag-${getLanguageByLocation({
                      countryCode: this.props.biggestSettlement.countryCode,
                    })}`}
                  />
                  <BigWinUser>
                    {this.props.biggestSettlement.username}
                  </BigWinUser>
                </Flex>
                <CountUp>
                  <CurrencyCounter
                    currency={this.props.biggestSettlement.currency}
                  >
                    {this.props.biggestSettlement.amountCents}
                  </CurrencyCounter>
                </CountUp>
              </Flex>

              <Flex center column>
                <CountUp>
                  <PlayerCounter>{this.props.registeredPlayers}</PlayerCounter>
                </CountUp>
                <PlayerCountLabel>
                  {this.props.translate('logged-out.count-up.label')}
                </PlayerCountLabel>
              </Flex>
            </Statistics>
            <WelcomeWrapper
              justifyContent="space-between"
              returningUser={this.props.username}
            >
              {this.props.username && (
                <React.Fragment>
                  <Returning>
                    <ReturningTitle>
                      {this.props.translate('logged-out.returning-user.title')}
                    </ReturningTitle>
                    <ReturningSubtitle>
                      {this.props.translate(
                        'homepage.returning.one.headerbanner.title'
                      )}
                    </ReturningSubtitle>
                  </Returning>
                  <Login>
                    <LoginForm name="login" />
                  </Login>
                </React.Fragment>
              )}

              {!this.props.username && (
                <NewUser center column>
                  <NewUserContent>
                    <NewUserPromotion
                      html={{
                        __html: this.props.translate(
                          'try-free.giveaway-header'
                        ),
                      }}
                    />
                    <HtmlContent
                      html={{
                        __html: this.props.translate('try-free.try-on-us'),
                      }}
                    />
                  </NewUserContent>
                  <ButtonWrapper>
                    <Link to="/register">
                      <Button variant="primary" minimalPadding>
                        <ButtonText>
                          {this.props.translate('try-free.button-text')}
                        </ButtonText>
                      </Button>
                    </Link>
                  </ButtonWrapper>
                  {this.props.isUk && (
                    <TermsWrapper>
                      <TermsLink
                        to={{
                          pathname: this.props.location.pathname,
                          query: { terms: true },
                        }}
                      >
                        {this.props.translate('try-free.terms.link')}
                      </TermsLink>
                      <TermsSnippet
                        html={{
                          __html: this.props.translate(
                            'try-free.terms.snippet'
                          ),
                        }}
                      />
                    </TermsWrapper>
                  )}
                </NewUser>
              )}
            </WelcomeWrapper>
          </Header>
        </HeaderWrapper>

        <Concave>
          <Avatars>
            {this.props.avatars && <AvatarsRow avatars={this.props.avatars} />}
          </Avatars>

          {this.props.isCasitabi && (
            <Flex justifyContent="center">
              <VideoBackground>
                <Space top={6}>
                  <VideoTitle>
                    {this.props.translate('landing-page.pv-title')}
                  </VideoTitle>
                </Space>
                <Space top={8} x="10px" bottom="10px">
                  <AspectRatio ratio={9 / 16}>
                    {!this.state.deposited &&
                    this.props.isExistingTranslation(
                      'landing-page.promo-image'
                    ) ? (
                      <PromoImage
                        src={this.props.translate('landing-page.promo-image')}
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/ZSK1l8yhjk4?rel=0"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullscreen
                      />
                    )}
                  </AspectRatio>
                </Space>
              </VideoBackground>
            </Flex>
          )}

          <IconsOuterWrapper spreadIcons={this.props.avatars}>
            <IconsInnerWrapper spreadIcons={this.props.avatars}>
              <DoubleIconsWrapper spreadIcons={this.props.avatars}>
                <InfoIcon
                  icon="multi-devices"
                  label={this.props.translate('logged-out.info-icon.1')}
                />
                <InfoIcon
                  icon={
                    R.find(
                      (icon) =>
                        icon ===
                        `bonus-${R.toLower(this.props.playerCurrency)}`,
                      CURRENCY_ICONS
                    ) || `bonus-eur`
                  }
                  label={this.props.translate('logged-out.info-icon.2')}
                />
              </DoubleIconsWrapper>
              <DoubleIconsWrapper spreadIcons={this.props.avatars}>
                <InfoIcon
                  icon="sword"
                  label={this.props.translate('logged-out.info-icon.3')}
                />
                <InfoIcon
                  icon="money"
                  label={this.props.translate('logged-out.info-icon.4')}
                />
              </DoubleIconsWrapper>
            </IconsInnerWrapper>
          </IconsOuterWrapper>
        </Concave>
        <NewGameplay>
          <NewGameplayHeading>
            <NewGameplayTitle>
              {this.props.translate('logged-out.banner.title')}
            </NewGameplayTitle>
            <NewGameplaySubtitle>
              {this.props.translate('logged-out.banner.subtitle')}
            </NewGameplaySubtitle>
          </NewGameplayHeading>
          <Worlds>
            {R.map(
              (world) => (
                <World key={world.image}>
                  <Flex
                    alignItems="center"
                    column
                    justifyContent="space-between"
                  >
                    <Flex center column>
                      <WorldImage src={world.image} />
                      <WorldTitle>
                        {this.props.translate(toTranslationString(world.title))}
                      </WorldTitle>
                    </Flex>
                    <WorldDescription>{world.content}</WorldDescription>
                  </Flex>
                  <Link to="/register">
                    <Button variant="primary">
                      {this.props.translate('logged-out.island.button')}
                    </Button>
                  </Link>
                </World>
              ),
              this.props.worlds
            )}
          </Worlds>
        </NewGameplay>
        <Link to="/casino">
          <Games>
            <GamesHeading>
              <GamesTitle>
                {this.props.translate('logged-out.popular-games.title')}
              </GamesTitle>
            </GamesHeading>
            <Concave>
              <Flex center wrap component="ul">
                <GameQuery name="homePageGames" params={{ limit: 6 }}>
                  {(data) =>
                    R.map(
                      (game) => (
                        <Game key={game.image}>
                          <Flex center column>
                            <GameImage src={game.imageUrl} />
                            <GameTitle>{game.title}</GameTitle>
                          </Flex>
                        </Game>
                      ),
                      data.games
                    )
                  }
                </GameQuery>
              </Flex>
            </Concave>
          </Games>
        </Link>
        {this.props.isUk && (
          <PopupContainer
            id="try-free-terms"
            isOpen={Boolean(this.props.location.query.terms)}
            onRequestClose={() =>
              this.props.redirectTo(this.props.location.pathname)
            }
          >
            <Scrollable maxHeight={350}>
              <HtmlContent
                html={{ __html: this.props.translate('try-free.terms.text') }}
              />
            </Scrollable>
          </PopupContainer>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const isUkState = isUk(state)

  return {
    avatars:
      !isUkState &&
      R.pipe(
        getAvatars,
        R.filter((avatar) =>
          R.includes(avatar.key, displayAvatars[getConfig(state.app).brand])
        ),
        R.pluck('closeupImageUrl')
      )(state.signUp),
    countryCode: lookup.getCountryCode(state.lookup),
    games: state.home.games,
    isCasitabi: isCasitabi(state),
    isUk: isUkState,
    playerCurrency: getPlayerCurrency(state),
    registeredPlayers: state.home.registeredPlayers,
    username: getUsername(state.app),
    worlds: worldsData(props.translate),
    biggestSettlement: state.home.biggestSettlement,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAvatars,
      fetchLiveStatistics,
      getItem,
      redirectTo,
      updateCampaigns,
    },
    dispatch
  )
}

HomeContainer.propTypes = {
  avatars: PropTypes.array,
  biggestSettlement: PropTypes.object,
  countryCode: PropTypes.string,
  fetchAvatars: PropTypes.func.isRequired,
  fetchLiveStatistics: PropTypes.func.isRequired,
  games: PropTypes.array,
  getItem: PropTypes.func.isRequired,
  isCasitabi: PropTypes.bool,
  isExistingTranslation: PropTypes.func.isRequired,
  isUk: PropTypes.bool.isRequired,
  location: locationShape,
  playerCurrency: PropTypes.string,
  redirectTo: PropTypes.func.isRequired,
  registeredPlayers: PropTypes.number,
  translate: PropTypes.func.isRequired,
  username: PropTypes.string,
  worlds: PropTypes.array.isRequired,
}

export default withTranslate(
  connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
)

import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as api from '@rushplay/api-client'
import * as locks from '@rushplay/compliance/locks'
import { withTranslate } from '@rushplay/i18n'

import { RGDepositThresholdPrompt as Component } from './rg-deposit-threshold-prompt'

const connector = R.compose(
  withTranslate,
  connect(null, {
    onAccept: () =>
      api.dismissDepositThresholdPrompt({
        success: () => [
          locks.fetch(),
          locks.remove('responsible-gaming-deposit-threshold-prompt'),
        ],
        failure: () =>
          Notifications.add({
            message: 'errors.deposit-threshold-prompt.failed',
            level: 'error',
          }),
        version: 1,
      }),
  })
)

export const RGDepositThresholdPrompt = connector(Component)

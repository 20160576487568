import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as Recompose from 'recompose'
import { withRouter } from 'react-router'

import * as Api from '@rushplay/api-client'

import * as App from '../../store/app'
import { getSearchQuery } from '../../get-search-query'

import { AffiliateBar as Component } from './affiliate-bar'

export const AffiliateBar = R.compose(
  withRouter,
  ReactRedux.connect(
    (state) => {
      const query = getSearchQuery(window.location)

      return {
        affiliateInfo: App.getAffiliateInfo(state.app),
        affiliateLogo: App.getAffiliateLogo(state.app),
        btag: App.getReferrals(state.app) || query.btag,
        returningUser: !R.isNil(App.getUsername(state.app)),
      }
    },
    (dispatch) => ({
      onFetch: (btag) =>
        dispatch(
          Api.fetchAffiliateData(
            { btag },
            {
              success: (res) => App.storeAffiliateData(res.value.result),
              version: 1,
            }
          )
        ),
    })
  ),
  Recompose.branch((props) => props.returningUser, Recompose.renderNothing)
)(Component)

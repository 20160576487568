import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getItem } from 'redux-effects-localstorage'
import { push as redirectTo } from 'react-router-redux'
import { rem } from 'polished'

import * as Notifications from '@rushplay/notifications'
import * as api from '@rushplay/api-client'
import styled from '@emotion/styled'
import { Flex, FlexItem, Link, Space, withCurrentTime } from '@rushplay/common'
import { actions as formsActions } from '@rushplay/legacy-forms'
import { getVip, isSessionActive } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as player from '../store/player'
import * as promotions from '../store/promotions'
import * as campaigns from '../store/campaigns'
import * as App from '../store/app'
import Button from '../common/button'
import HtmlContent from '../common/html-content'
import Line from '../common/line'
import toRemainingTime from '../to-remaining-time'
import { PageSpinner } from '../common/spinner'
import { SeoTextArea } from '../components/seo-text-area'
import { berry, night, success } from '../constants/colors'
import { getPromotionBackgrounds } from '../store/translations'
import { headingFont, textFont } from '../constants/typography'

import CountdownSection from './countdown-section'
import Panel from './panel'
import { PromotionsMenu } from './menu'

const PromotionPage = styled.section`
  margin-right: auto;
  margin-left: auto;
  max-width: 309px;
  text-align: center;
  @media screen and (min-width: 480px) {
    max-width: 378px;
  }
  @media screen and (min-width: 836px) {
    max-width: 756px;
  }
  @media screen and (min-width: 1280px) {
    max-width: 1134px;
  }
`

const PromotionPanel = styled(Panel.withComponent('article'))`
  width: 298px;
  margin: 15px 11px;
  ${(props) => props.claimed && `background-color: ${night};`};
  @media screen and (min-width: 480px) {
    height: 565px;
    margin: 40px;
  }
`

const PromotionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

const Background = styled.div`
  ${(props) =>
    props.images.length > 0 &&
    `
    padding-bottom: 300px;
    background-color: #98d6fd;
    background-image: url(${props.images[2]}), url(${props.images[3]});
    background-repeat: no-repeat, repeat-x;
    background-position-x: center, center;
    background-position-y: bottom, bottom;
    background-size: 800px, 800px;
    min-height: 100vh;

    @media screen and (min-width: 768px) {
      padding-bottom: 0px;
      background-color: #3c79f6;
      background-image: url(${props.images[0]}), url(${props.images[1]});
      background-position-x: center, center;
      background-position-y: 100px, top;
      background-size: 1500px, 1500px;
    }
  `};
`

const PromotionBody = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  box-shadow: inset 0 1px 2px -1px white;
`

const Image = styled.div`
  width: 298px;
  height: 122px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Label = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 16px;
  background-color: ${(props) => (props.active ? success : berry)};
  border-radius: 0 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  opacity: ${(props) => (props.active || props.claimed ? 1 : 0)};
`

const Content = styled(HtmlContent)`
  font-family: ${textFont};
  font-size: 14px;
  font-weight: 300;
  max-height: 153px;
  line-height: 1.4;
  overflow: hidden;
`

const PromotionFooter = styled.div`
  margin-top: auto;
  padding: 20px;
`

const Colon = styled.div`
  margin-top: -15px;
  padding-left: 3px;
  padding-right: 3px;
`

const PromotionCountdown = styled.div`
  width: 100%;
  background-color: #0a0d2e;
  box-shadow: inset 0 1px 2px -1px white;
`

const PromotionCountdownTitle = styled.div`
  font-size: ${rem('18px')};
  margin-bottom: 15px;
`

const PromotionCountDownWrapper = styled.div`
  margin-top: auto;
  padding-top: 25px;
`

const Title = styled.h2`
  font-family: ${headingFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 5px;
`

const StyledLink = styled(Link)`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
`

const EndingTime = styled.div`
  height: 36px;
  line-height: 24px;
`

const mapIndexed = R.addIndex(R.map)

function getCtaKey(hasOptIn, activePromotion, promotion, isOptedIn) {
  if (hasOptIn && !activePromotion) {
    return 'promotions.opt-in'
  } else if (isOptedIn) {
    return 'promotions.opt-out'
  } else {
    return `promotions.${promotion.key}.button.loggedin`
  }
}

function PromotionsContainer(props) {
  const [visiblePromotions, setVisiblePromotions] = React.useState([])

  React.useEffect(() => {
    props.setPromotionsReady(false)
    props.onFetchPromotions()
    props.clearUnseenPromotions()

    return () => {
      props.onViewPromotion('')
    }
  }, [])

  React.useEffect(() => {
    if (props.promotionsCount > 0) {
      props.clearUnseenPromotions()
    }
  }, [props.promotionsCount])

  React.useEffect(() => {
    if (!R.isEmpty(props.promotions)) {
      if (!props.params.tag || props.params.tag === 'all') {
        setVisiblePromotions(props.promotions)
      } else {
        setVisiblePromotions(
          R.filter(
            (item) => R.includes(props.params.tag, item.tags),
            props.promotions
          )
        )
      }
    }
  }, [props.params.tag, props.promotions])

  function handleRegisterLoggedOut() {
    props
      .getItem('LOGGED_IN_ONCE')
      .then((data) => {
        if (!data) {
          props.onViewPromotion('')
          props.redirectTo('/register')
        } else {
          if (window.innerWidth > 1279) {
            props.onFocus('login', 'username')
          } else {
            props.setMobileMenuVisibility(true)
          }
        }
      })
      .catch((err) =>
        // eslint-disable-next-line  no-console
        console.error(err)
      )
  }

  function handleOptIn(campaign) {
    const IS_DEPOSIT = campaign.rewardTrigger === 'deposit'

    // Failsafe if the campaign's flow was aborted but not opted-out
    if (campaign.optInState !== 'out' && IS_DEPOSIT) {
      props.redirectTo('/wallet/deposit')
    }

    // TODO: Replace function with invalid actions middleware
    const filterActions = R.filter(R.has('type'))
    if (campaign.optInState === 'out') {
      props.optInCampaign(campaign.id, {
        success: (data) => {
          return filterActions([
            campaigns.startCampaign(data.value.result),
            IS_DEPOSIT && redirectTo('/wallet/deposit'),
          ])
        },
        failure: () =>
          Notifications.add({ message: 'error.generic', level: 'error' }),
        version: 2,
      })
    }
  }

  function remainingTime(timestamp) {
    return toRemainingTime(props.serverTimeOffset, timestamp, Date.now())
  }

  function handleCtaClick(
    promotion,
    promotionId,
    hasOptIn,
    isDepositCampaign,
    activePromotion,
    isOptedIn
  ) {
    if ((hasOptIn || (isDepositCampaign && !isOptedIn)) && !activePromotion) {
      return handleOptIn(promotion.campaign)
    } else if (isOptedIn) {
      return props.optOutCampaign(promotion.campaign.id, promotionId)
    } else {
      return props.redirectTo(promotion.loggedInButtonLink || '/casino')
    }
  }

  if (!props.ready) {
    return <PageSpinner />
  }

  return (
    <React.Fragment>
      <Background images={R.map(props.translate, props.images)}>
        {!R.isEmpty(props.promotions) && (
          <PromotionsMenu
            items={R.uniq(R.flatten(R.map(R.prop('tags'), props.promotions)))}
          />
        )}
        <PromotionPage authenticated={props.authenticated}>
          <Flex alignItems="stretch" justifyContent="flex-start" wrap>
            {mapIndexed((promotion, index) => {
              const image = props.translate(
                `promotions.${promotion.key}.img-src`
              )
              const title = props.translate(`promotions.${promotion.key}.title`)
              const content = props.translate(
                `promotions.${promotion.key}.content`
              )
              const activeFrom = Date.parse(promotion.activeFrom)
              const activeTo = Date.parse(promotion.activeTo)
              const remainingTimeFrom = remainingTime(activeFrom)
              const remainingMs = activeTo + props.serverTimeOffset - Date.now()
              const remainingTimeTo =
                remainingMs < 60000
                  ? // Display 1 minute if remaining time is less than 60 seconds
                    R.mergeRight(remainingTime(activeTo), { minutes: 1 })
                  : remainingTime(activeTo)

              if (remainingMs <= 0) {
                // Don't render if remaining time is less than 0
                return null
              }
              const isPromotionTimeActive =
                promotion.activeTo && props.currentTime >= activeFrom

              const campaign = promotion.campaign
              const isDepositCampaign = R.pathEq(
                ['rewardTrigger'],
                'deposit',
                campaign
              )

              const hasOptIn = R.pathEq(['optInState'], 'out', campaign)

              const isClaimed = R.propOr(false, 'claimed', campaign)

              const isOptedIn = R.pathEq(['optInState'], 'in', campaign)

              const activePromotion =
                promotion.campaign &&
                R.has(promotion.campaign.id, props.campaigns) &&
                !isDepositCampaign

              return (
                <PromotionPanel claimed={isClaimed} key={promotion.key}>
                  <PromotionWrapper>
                    <Image alt={title} src={image} />
                    <Label
                      active={activePromotion || isOptedIn}
                      claimed={isClaimed}
                    >
                      {props.translate(
                        `promotions.${
                          activePromotion || isOptedIn ? 'active' : 'claimed'
                        }`
                      )}
                    </Label>
                    <PromotionBody>
                      <Title>
                        <HtmlContent html={{ __html: title }} />
                      </Title>
                      <Content html={{ __html: content }} />
                    </PromotionBody>
                    <StyledLink to={`/promotion/${promotion.key}`}>
                      {props.translate('terms-and-conditions')}
                    </StyledLink>
                    {props.currentTime < activeFrom ? (
                      <PromotionCountDownWrapper>
                        <PromotionCountdown>
                          <Space px="17px" py="14px">
                            <PromotionCountdownTitle>
                              {props.translate('promotions.start-in')}:
                            </PromotionCountdownTitle>
                            <Flex center>
                              <FlexItem grow={1}>
                                <CountdownSection
                                  label={props.translate('promotions.days')}
                                >
                                  {remainingTimeFrom.days}
                                </CountdownSection>
                              </FlexItem>
                              <Colon x={3}>:</Colon>
                              <FlexItem grow={1}>
                                <CountdownSection
                                  label={props.translate('promotions.hours')}
                                >
                                  {remainingTimeFrom.hours}
                                </CountdownSection>
                              </FlexItem>
                              <Colon x={3}>:</Colon>
                              <FlexItem grow={1}>
                                <CountdownSection
                                  label={props.translate('promotions.minutes')}
                                >
                                  {remainingTimeFrom.minutes}
                                </CountdownSection>
                              </FlexItem>
                              <Colon x={3}>:</Colon>
                              <FlexItem grow={1}>
                                <CountdownSection
                                  label={props.translate('promotions.seconds')}
                                >
                                  {remainingTimeFrom.seconds}
                                </CountdownSection>
                              </FlexItem>
                            </Flex>
                          </Space>
                        </PromotionCountdown>
                      </PromotionCountDownWrapper>
                    ) : (
                      <PromotionFooter>
                        <Line />
                        <Space y="5px" />
                        {isPromotionTimeActive && (
                          <EndingTime>
                            {props.translate('promotions.ending-in')}:&nbsp;
                            {props.translate(
                              'promotions.ending-time',
                              remainingTimeTo
                            )}
                          </EndingTime>
                        )}
                        {props.authenticated ? (
                          <Space y="5px">
                            <Button
                              stretch
                              variant="primary"
                              disabled={isClaimed}
                              onClick={() =>
                                handleCtaClick(
                                  promotion,
                                  index,
                                  hasOptIn,
                                  isDepositCampaign,
                                  activePromotion,
                                  isOptedIn
                                )
                              }
                            >
                              {props.translate(
                                getCtaKey(
                                  hasOptIn,
                                  activePromotion,
                                  promotion,
                                  isOptedIn
                                )
                              )}
                            </Button>
                          </Space>
                        ) : (
                          <Space y="10px">
                            <Button
                              stretch
                              variant="primary"
                              onClick={() => handleRegisterLoggedOut()}
                            >
                              {props.translate(
                                `promotions.${promotion.key}.button.loggedout`
                              )}
                            </Button>
                          </Space>
                        )}
                      </PromotionFooter>
                    )}
                  </PromotionWrapper>
                </PromotionPanel>
              )
            }, visiblePromotions)}
          </Flex>
        </PromotionPage>
      </Background>
      <SeoTextArea translationKey="promotions-page.seo-content" />
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    authenticated: isSessionActive(state.session),
    campaigns: state.campaigns,
    images: getPromotionBackgrounds(state.translations.config),
    promotions: state.promotions.items,
    promotionsCount: promotions.unseenPromotionCount(state.promotions, {
      authenticated: isSessionActive(state.session),
      vip: getVip(state.session),
      depositNumber: player.getDepositCount(state.player),
    }),
    ready: state.promotions.ready,
    serverTimeOffset: state.app.serverTimeOffset,
    viewedPromotion: promotions.viewedPromotion(state.promotions),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearUnseenPromotions: promotions.clearUnseenPromotions,
      getItem,
      optInCampaign: api.optInCampaign,
      optOutCampaign: (campaignId, promotionId) => [
        // We optimistically update the campaigns and promotions state
        campaigns.optOut(campaignId),
        promotions.optOutCampaignInPromotion(promotionId),
        api.optOutCampaign(campaignId, {
          failure: (error) => [
            campaigns.optIn(campaignId),
            promotions.optInCampaignInPromotion(promotionId),
            Notifications.add({
              message: error.value.message || 'error.generic',
              level: 'error',
            }),
          ],
          version: 2,
        }),
      ],
      redirectTo,
      setMobileMenuVisibility: App.setMobileMenuVisibility,
      setPromotionsReady: promotions.setPromotionsReady,
      onFetchPromotions: () =>
        api.fetchPromotions(null, {
          success: (res) => [
            promotions.setPromotionsReady(true),
            promotions.updatePromotions(res.value),
          ],
          failure: (error) => [
            promotions.setPromotionsReady(true),
            Notifications.add({
              message: error.value.message || 'error.generic',
              level: 'error',
            }),
          ],
          version: 2,
        }),
      onFocus: formsActions.focus,
      onViewPromotion: promotions.viewPromotion,
    },
    dispatch
  )
}

PromotionsContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  campaigns: PropTypes.object.isRequired,
  clearUnseenPromotions: PropTypes.func.isRequired,
  currentTime: PropTypes.number,
  getItem: PropTypes.func.isRequired,
  images: PropTypes.object,
  optInCampaign: PropTypes.func.isRequired,
  optOutCampaign: PropTypes.func.isRequired,
  params: PropTypes.object,
  promotions: PropTypes.array.isRequired,
  promotionsCount: PropTypes.number,
  ready: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func.isRequired,
  serverTimeOffset: PropTypes.number,
  setPromotionsReady: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  viewedPromotion: PropTypes.object,
  onFetchPromotions: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  onViewPromotion: PropTypes.func,
  setMobileMenuVisibility: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(withCurrentTime(PromotionsContainer)))

import * as R from 'ramda'
import { createSelector } from 'reselect'

function getRawLimit(state, props) {
  const limit = R.pathOr({}, [props.target, props.period], state)

  return R.mergeRight(limit, {
    target: props.target,
    period: props.period,
  })
}

export const isEditing = createSelector(
  [getRawLimit],
  R.propEq('nextValueCents', -1)
)

export const isIncreased = createSelector(
  [getRawLimit],
  limit => limit.nextValueCents > limit.valueCents
)

export const isRemoved = createSelector(
  [getRawLimit],
  R.both(R.has('activeFrom'), R.propSatisfies(R.isNil, 'nextValueCents'))
)

export const getLimit = createSelector(
  [getRawLimit, isEditing, isIncreased, isRemoved],
  (limit, isEditing, isIncreased, isRemoved) =>
    R.mergeRight(limit, {
      isEditing,
      isRemoved,
      isIncreased,
    })
)

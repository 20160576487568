import PropTypes from 'prop-types'
import React from 'react'

import { Box, Flex, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import Text from '../common/text'

export function SetLimitsConfirmation(props) {
  const i18n = useI18n()
  return (
    <React.Fragment>
      <Space y={5}>
        {props.limitValue === 0 ? (
          <>
            <Box color="error" fontSize="16px">
              <Icon name="alert" />
              {i18n.translate(
                `money-limits.confirmation-info.limit-set-to-zero.title`
              )}
            </Box>
            <Text color="error" align="center" lineHeight="1.4em">
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    `money-limits.confirmation-info.limit-set-to-zero`
                  ),
                }}
              />
            </Text>
          </>
        ) : (
          <Text align="center" lineHeight="1.4em">
            <HtmlContent
              html={{
                __html: i18n.translate(
                  `money-limits.confirmation-info.${props.info}`
                ),
              }}
            />
          </Text>
        )}
      </Space>
      <Flex justifyContent="space-around">
        {props.onCancel && (
          <Button variant="secondary" onClick={props.onCancel}>
            {i18n.translate('player-limits.cancel')} ¨
          </Button>
        )}
        <Button variant="primary" onClick={props.onSubmit}>
          {i18n.translate('player-limits.confirm')}
        </Button>
      </Flex>
    </React.Fragment>
  )
}

SetLimitsConfirmation.propTypes = {
  info: PropTypes.oneOf(['high', 'excessive']),
  limitValue: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

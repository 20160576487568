import * as R from 'ramda'

import isInRange from './is-in-range'

/**
* Check if target deposit number is within the range specified in promotion
* @param {string} targetDepositNumber
* @returns {function(object): boolean}
*/
export function isEligibleDepositNumber(targetDepositNumber) {
  return promotion =>
    isInRange(
      promotion.minDepositNumber,
      promotion.maxDepositNumber || Infinity,
      targetDepositNumber
    )
}

/**
* Check if target vip is eligible for promotion
* @param {string} targetVip
* @returns {function(object): boolean}
*/
export function isEligibleVip(targetVip) {
  return promotion => {
    if (!R.isNil(targetVip) && R.includes('Non-VIP', promotion.vipLevels)) {
      // player is VIP and is not entitled for any Non-VIP promotion
      return false
    }
    return (
      R.isEmpty(promotion.vipLevels) ||
      R.includes('Non-VIP', promotion.vipLevels) ||
      R.includes(targetVip, promotion.vipLevels)
    )
  }
}

import { countryNames } from '../../../util/get-sorted-country-names'

export function dataSchema() {
  return {
    type: 'object',
    properties: {
      employment: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'Self Employed',
            'Part time',
            'Full time',
            'Retired',
            'Unemployed',
            'Student',
          ],
        },
      },
      occupation: {
        type: 'string',
        minLength: 2,
      },
      otherSources: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'Salary-Employment',
            'Pension',
            'Savings',
            'Winnings',
            'Dividends',
            'Inheritance',
            'Property Sale',
            'Company Sale',
            'Assets Sale',
            'Investments',
            'Settlement',
            'Gift',
            'Crypto',
            'Retirement Income',
            'None',
            'Other',
          ],
        },
      },
      otherSourcesField: {
        type: 'string',
        minLength: 1,
      },
      salary: {
        type: 'string',
        enum: [
          '5001-20000',
          '20001-40000',
          '40001-60000',
          '60001-80000',
          '>80000',
        ],
      },
      anticipatedYearlyDeposits: {
        type: 'string',
        enum: ['<=3000', '5001-10000', '10001-30000', '>30000'],
      },
      sourcesOfFunds: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'No difference',
            'Salary',
            'Pension',
            'Dividends',
            'Winnings',
            'Savings',
            'Loan',
            'Crypto',
            'Trading',
            'Rental income',
            'Gift',
            'Sale of company',
            'Sale of property',
            'Other',
          ],
        },
      },
      sourcesOfFundsOtherField: {
        type: 'string',
        minLength: 1,
      },
      fundsCountryOrigin: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: countryNames,
        },
      },
      nationality: {
        type: 'string',
        enum: countryNames,
      },
      placeOfBirth: {
        type: 'string',
        enum: countryNames,
      },
      idNumber: {
        type: 'string',
        minLength: 1,
      },
      confirmation: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: ['confirmation'],
        },
      },
    },
  }
}

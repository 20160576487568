import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { Constraint, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import Card from '../common/card'
import HtmlContent from '../common/html-content'
import Text from '../common/text'

function GamstopInfo(props) {
  return (
    <Constraint width="500" centered>
      <Space top={10}>
        <Card>
          <Space all={6}>
            <Text align="center">
              <HtmlContent
                html={{
                  __html: props.isExistingTranslation(
                    `self-exclusion-success.${props.license}`
                  )
                    ? props.translate(`self-exclusion-success.${props.license}`)
                    : props.translate(`self-exclusion-success.global`),
                }}
              />
            </Text>
            <Space top={8}>
              <Button stretch="stretched" onClick={props.onSubmit}>
                {props.translate('ok')}
              </Button>
            </Space>
          </Space>
        </Card>
      </Space>
    </Constraint>
  )
}

function mapStateToProps(state, props) {
  return {
    license: props.params.license,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onSubmit: () => push('/'),
    },
    dispatch
  )
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate
)

GamstopInfo.propTypes = {
  isExistingTranslation: PropTypes.func,
  license: PropTypes.string,
  params: PropTypes.object,
  translate: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default connector(GamstopInfo)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { rem } from 'polished'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Avatar from '../../common/avatar'
import Button from '../../common/button'
import Icon from '../../common/icon/icon'
import RadialProgress from '../radial-progress/radial-progress'
import { lightGrey, night, nightDark } from '../../constants/colors'

import Difficulty from './difficulty'
import WorldCompletionOverlay from './world-completion-overlay'

const gradients = {
  'treasure-island': 'linear-gradient(-180deg, #310E6A 3%, #C92676 99%)',
  'rock-of-riches': 'linear-gradient(-135deg, #183363 0%, #2653A1 100%)',
  'mythical-mountain': 'linear-gradient(-105deg, #966A55 0%, #575757 100%)',
  default: `linear-gradient(${night}, #2753A1)`,
}

const Panel = styled.section`
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Header = styled.h2`
  padding-bottom: 10px;
  background: ${props => `url(${props.worldIcon})`},
    ${props => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: 200px, cover;
  background-position: center;
  height: 120px;
  text-indent: -9999px;
  ${props => props.locked && 'filter: grayscale(0.75);'};
`

const Main = styled.div`
  display: flex;
  justify-content: center;
  background-image: ${props => props.gradient};
`

const PanelContent = styled.div`
  position: relative;
  width: 300px;
`

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 94%;
  left: 3%;
  top: -17px;
`

const Gutter = styled.div`width: 1rem;`

const StatsRow = styled.div`
  display: flex;
  padding-top: ${rem('36px')};
  padding-bottom: ${rem('22px')};
`

const StatsColumn = styled.div`
  flex-grow: 1;
  font-size: ${rem('19px')};
  text-align: center;

  &:not(:last-child) {
    border-right: 2px solid ${lightGrey};
  }
`

const Label = styled.p`
  font-size: ${rem('14px')};
  margin-top: 0.3rem;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 1rem;
  width: ${rem('45px')};
  height: ${rem('45px')};
`

const AvatarWrapper = styled(IconWrapper)`left: 1rem;`

const ProgressWrapper = styled(IconWrapper)`
  right: 1rem;
  font-size: ${rem('12px')};
`

const LockWrapper = styled.div`
  position: absolute;
  width: ${rem('39px')};
  height: ${rem('39px')};
  font-size: ${rem('20px')};
  top: 1em;
  right: 1em;
  background-color: ${night};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${nightDark};
`

function WorldPanel(props) {
  const completed =
    (props.totalRegionsCount > 0 &&
      props.passedRegionsCount === props.totalRegionsCount) ||
    props.completed
  const showProgress = !completed && props.worldProgress >= 0
  const showAvatar = !completed && props.current

  const stats = [
    {
      label: props.translate('worlds.treasures'),
      amount: props.foundTreasuresCount,
      total: props.totalTreasuresCount,
    },
    {
      label: props.translate('worlds.bosses'),
      amount: props.passedRegionsCount,
      total: props.totalRegionsCount,
    },
  ]

  return (
    <Panel>
      <Header
        background={props.background}
        worldIcon={props.worldIcon}
        locked={props.locked}
      >
        {props.translate(props.worldKey)}
      </Header>
      <Main gradient={R.or(gradients[props.worldKey], gradients['default'])}>
        <PanelContent>
          {!completed && (
            <ButtonWrapper>
              <Button
                stretch
                small
                variant="primary"
                onClick={props.onDetailsViewing}
              >
                {props.translate('worlds.details')}
              </Button>
              <Gutter />
              <Button
                stretch
                small
                disabled={props.locked}
                loading={props.loadingWorld}
                onClick={props.onStartPlaying}
              >
                {props.translate(
                  props.authenticated ? 'worlds.select' : 'worlds.register'
                )}
              </Button>
            </ButtonWrapper>
          )}

          <StatsRow>
            <StatsColumn>
              <Difficulty level={props.difficulty} />
              <Label>{props.translate('worlds.difficulty')}</Label>
            </StatsColumn>

            {R.map(
              stat => (
                <StatsColumn key={stat.label}>
                  {`${stat.amount}/${stat.total}`}
                  <Label>{stat.label}</Label>
                </StatsColumn>
              ),
              stats
            )}
          </StatsRow>
        </PanelContent>
      </Main>

      {showAvatar && (
        <AvatarWrapper onClick={props.onStartPlaying}>
          <Avatar src={props.userAvatar} />
        </AvatarWrapper>
      )}

      {showProgress && (
        <ProgressWrapper>
          <RadialProgress percentage={props.worldProgress} />
        </ProgressWrapper>
      )}

      {props.locked && (
        <LockWrapper>
          <Icon name="padlock" />
        </LockWrapper>
      )}

      {completed && (
        <WorldCompletionOverlay
          image={props.translate('worlds.completed-badge')}
        />
      )}
    </Panel>
  )
}

function mapStateToProps(state) {
  return {
    currentWorldKey: R.path(['adventure', 'worlds', 'currentWorldKey'], state),
  }
}

WorldPanel.defaultProps = {
  background: '',
  foundTreasuresCount: 0,
  passedRegionsCount: 0,
}

WorldPanel.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  background: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  current: PropTypes.bool,
  currentWorldKey: PropTypes.string,
  difficulty: PropTypes.number,
  loadingWorld: PropTypes.bool,
  locked: PropTypes.bool,
  passedRegionsCount: PropTypes.number,
  totalRegionsCount: PropTypes.number,
  foundTreasuresCount: PropTypes.number,
  totalTreasuresCount: PropTypes.number,
  translate: PropTypes.func.isRequired,
  userAvatar: PropTypes.string,
  worldIcon: PropTypes.string.isRequired,
  worldKey: PropTypes.string.isRequired,
  worldProgress: PropTypes.number,
  worldRewardLevel: PropTypes.string,
  onDetailsViewing: PropTypes.func.isRequired,
  onStartPlaying: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(withTranslate(WorldPanel))

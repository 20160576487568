import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as forms from '@rushplay/forms'
import * as Common from '@rushplay/common'

import Checkbox from '../../checkbox'

export class MultipleChoice extends React.Component {
  constructor(props) {
    super(props)

    this.state = { options: [] }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(key, value) {
    // checkbox was true
    if (value) {
      const options = R.append(key, this.state.options)
      this.setState({ options })

      return this.props.onFieldChange(options)
    }

    // checkbox was false
    const options = R.without(key, this.state.options)
    this.setState({ options })

    return this.props.onFieldChange(options)
  }

  render() {
    return (
      <forms.Field
        mountPoint="formsv2"
        name={this.props.name}
        validator={this.props.validator}
        initialValue={this.props.initialValue}
      >
        {(field) => (
          <React.Fragment>
            {R.map(
              (item) => (
                <Common.Space my="8px" key={item.value}>
                  <Checkbox
                    {...field.getProps(this.props)}
                    id={`${this.props.name}-${item.value}`}
                    label={this.props.translate(item.label)}
                    value={R.contains(item.value, this.state.options)}
                    onChange={(value) => this.handleChange(item.value, value)}
                  />
                </Common.Space>
              ),
              this.props.options
            )}
          </React.Fragment>
        )}
      </forms.Field>
    )
  }
}

MultipleChoice.propTypes = {
  initialValue: PropTypes.array,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ),
  translate: PropTypes.func.isRequired,
  validator: PropTypes.arrayOf(PropTypes.func),
  onFieldChange: PropTypes.func.isRequired,
}

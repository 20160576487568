import * as R from 'ramda'
import PropTypes from 'prop-types'

export function Notification(props) {
  return props.children(
    R.merge(props.notification, { onDismiss: props.onDismiss })
  )
}

Notification.propTypes = {
  children: PropTypes.func,
  id: PropTypes.number,
  notification: PropTypes.object,
  mountPoint: PropTypes.string.isRequired,
}

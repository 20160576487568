import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import toRemainingTime from '../../to-remaining-time'

export function CampaignArea(props) {
  const i18n = I18n.useI18n()

  React.useEffect(() => {
    props.onFetchPromotions()
  }, [])

  function getRemainingTime(timestamp) {
    const remainingMs = timestamp + props.serverTimeOffset - Date.now()

    if (remainingMs < 60000) {
      return i18n.translate('campaign-area.remaining-minutes', { minutes: 1 })
    }

    const remainingTime = toRemainingTime(
      props.serverTimeOffset,
      timestamp,
      Date.now()
    )
    const getTime = R.compose(
      time =>
        time.minutes
          ? i18n.translate('campaign-area.remaining-minutes', {
              minutes: time.minutes,
            })
          : time,
      time =>
        time.hours
          ? i18n.translate('campaign-area.remaining-hours', {
              hours: time.hours,
            })
          : time,
      time =>
        time.days
          ? i18n.translate('campaign-area.remaining-days', { days: time.days })
          : time
    )

    return getTime(remainingTime)
  }

  if (R.isEmpty(props.promotions)) {
    return null
  }

  return (
    <Common.Box
      width="100%"
      backgroundColor="#06193c"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Common.Box
        maxWidth="1440px"
        position="relative"
        display="flex"
        justifyContent="center"
        marginTop={[3, null, null, 9]}
        marginBottom={[3, null, null, 9]}
        overflow="hidden"
        px="8px"
      >
        <Common.Box
          display="flex"
          maxWidth="100%"
          justifyContent="flex-start"
          alignItems="center"
          overflow="auto"
        >
          <Common.Box
            display="flex"
            maxWidth="100%"
            justifyContent="space-between"
          >
            {R.map(promotion => {
              const image = i18n.translate(
                `promotions.${promotion.key}.img-src`
              )

              const activeFrom = Date.parse(promotion.activeFrom)
              const activeTo = Date.parse(promotion.activeTo)
              const hasPromotionStarted =
                activeFrom + props.serverTimeOffset - Date.now() <= 0
              const remainingTime = hasPromotionStarted
                ? promotion.activeTo ? getRemainingTime(activeTo) : null
                : getRemainingTime(activeFrom)
              const remainingTimeContent = hasPromotionStarted
                ? promotion.activeTo
                  ? `${i18n.translate('promotions.ending-in')} ${remainingTime}`
                  : null
                : `${i18n.translate('promotions.start-in')} ${remainingTime}`

              return (
                <Common.Box
                  key={promotion.key}
                  width={['250px', null, null, '384px']}
                  minWidth={['250px']}
                  height={['96px', null, null, '146px']}
                  position="relative"
                  margin={[1, null, null, 4]}
                  backgroundColor="rgba(0, 0, 0, 0.3)"
                  backgroundImage={`url(${image})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  overflow="hidden"
                  borderRadius="4px"
                >
                  <Common.Box
                    as={Common.Link}
                    to="/promotions"
                    width="100%"
                    height="100%"
                    position="absolute"
                    display="inline-flex"
                    flexDirection="column"
                    alignItems={['flex-start', null, null, 'flex-end']}
                    justifyContent={['flex-start', null, null, 'flex-end']}
                  >
                    {promotion.campaign &&
                    remainingTimeContent && (
                      <Common.Box
                        padding="5px 8px 4px"
                        backgroundColor={
                          hasPromotionStarted ? '#ed5646' : '#264e96'
                        }
                        borderRadius={['0 0 4px 0', null, null, '4px 0 0 0']}
                      >
                        {remainingTimeContent}
                      </Common.Box>
                    )}
                  </Common.Box>
                </Common.Box>
              )
            }, props.promotions)}
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

CampaignArea.propTypes = {
  promotions: PropTypes.array,
  serverTimeOffset: PropTypes.number,
  onFetchPromotions: PropTypes.func.isRequired,
}

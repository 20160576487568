import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as icon from '@rushplay/icons'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'

import Text from '../../text'

const Wrapper = styled('span', {
  shouldForwardProp: noneOf(['visible', 'valid', 'empty', 'tooltipColor']),
})`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  font-size: 13px;
  font-family: ${t.font('textFont')};
  color: ${t.color('errorSecondary')};
  ${(props) =>
    props.valid &&
    `color: ${t.color('success')(props)};
  `};
  ${(props) =>
    props.valid &&
    props.empty &&
    `color: ${t.color(props.tooltipColor)(props)};
  `};
  word-break: break-word;
`

const IconWrapper = styled(icon.IconWrapper)`
  margin-right: 4px;
`

function getTooltipIcon(empty, visuallyValid) {
  if (visuallyValid) {
    return empty ? <icon.IconInfo /> : <icon.IconCheck />
  }

  return <icon.IconAlert />
}

export function FieldResponse(props) {
  const empty = props.value === ''
  const processing = props.status === 'processing'
  const valid = props.status === 'valid'
  const visuallyValid = !props.visited || valid
  const hasErrors = props.errors && !R.isEmpty(props.errors)
  const visible =
    ((!visuallyValid || props.focused) && props.tooltip) ||
    (hasErrors && props.visited)

  if (processing || !visible) {
    return null
  }

  return (
    <Wrapper
      empty={empty}
      valid={valid}
      visible={visible}
      tooltipColor={props.tooltipColor}
    >
      <IconWrapper>{getTooltipIcon(empty, visuallyValid)}</IconWrapper>
      <Text>
        {hasErrors
          ? props.translate(
              props.errors?.[0]?.translationKey,
              props.errors?.[0]?.variables
            )
          : props.translate(
              `${props.form}.${props.for}.tooltip`,
              props.variables
            )}
      </Text>
    </Wrapper>
  )
}

FieldResponse.defaultProps = {
  variables: {},
  tooltipColor: 'dark40',
}

FieldResponse.propTypes = {
  errors: PropTypes.array,
  focused: PropTypes.bool,
  for: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  status: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipColor: PropTypes.string,
  translate: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variables: PropTypes.object,
  visited: PropTypes.bool,
}

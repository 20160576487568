import PropTypes from 'prop-types'
import React from 'react'

import { SupportChat as Component } from '@rushplay/support-chat'

export function Chat(props) {
  /**
   * We only timeout script load on first load not to ruin it for users
   * who need to reload while chatting
   */

  const [allowLoad, setAllowLoad] = React.useState(props.zendeskCached)

  React.useEffect(
    () => {
      if (!props.zendeskCached) {
        const id = setTimeout(() => {
          setAllowLoad(true)
          props.onZendeskCached()
        }, 5000)

        return () => {
          clearTimeout(id)
        }
      }
    },
    [props.zendeskCached]
  )

  if (allowLoad) {
    return <Component {...props} />
  }
  return null
}

Chat.propTypes = {
  zendeskCached: PropTypes.number,
  onZendeskCached: PropTypes.func,
}

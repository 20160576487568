import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import parseMs from 'parse-ms'

import * as Common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import Card from '../../common/card'
import Heading from '../../common/heading'
import HtmlContent from '../../common/html-content'
import { PageSpinner } from '../../common/spinner'

import { Limit } from './limit'

export function MoneyLimits(props) {
  const i18n = useI18n()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  if (!props.limits) {
    return null
  }

  if (props.isWaiting) {
    return <PageSpinner />
  }

  return (
    <Common.Constraint centered width="1200">
      <Common.Flex column alignItems="center">
        {R.map(
          limit => (
            <Common.Space y={5} key={limit.target}>
              <Card themeDark>
                <Common.Space top={6}>
                  <Heading align="center" level="3">
                    {i18n.translate(`player-limits.${limit.target}.title`)}
                  </Heading>
                </Common.Space>
                <Common.Space all={5}>
                  <Common.Text align="center">
                    <HtmlContent
                      html={{
                        __html: i18n.translate(
                          `player-limits.${limit.target}.info`,
                          {
                            cooldown: parseMs(
                              R.pathOr(0, ['periods', '0', 'cooldown'], limit)
                            ).days,
                          }
                        ),
                      }}
                    />
                  </Common.Text>
                </Common.Space>
                {R.path(['periods', '0', 'crossbrand'], limit) && (
                  <Common.Space bottom="32px">
                    <Common.Text align="center" size="12">
                      {i18n.translate(
                        `${props.license}.player-limits.crossbrand-prompt`
                      )}
                    </Common.Text>
                  </Common.Space>
                )}
                <Common.Flex wrap justifyContent="space-around">
                  {R.map(
                    period => (
                      <Limit
                        key={period.period}
                        target={limit.target}
                        period={period.period}
                      />
                    ),
                    limit.periods
                  )}
                </Common.Flex>
              </Card>
            </Common.Space>
          ),
          props.limits
        )}
      </Common.Flex>
    </Common.Constraint>
  )
}

MoneyLimits.propTypes = {
  currency: PropTypes.string.isRequired,
  depositLimitLock: PropTypes.object,
  isWaiting: PropTypes.bool.isRequired,
  license: PropTypes.string,
  limits: PropTypes.array,
  onFetch: PropTypes.func,
}

import React from 'react'
import { Helmet } from 'react-helmet-async'

export function CasiTabiCampaignTracker() {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  return (
    <Helmet>
      <script src="https://genieedmp.com/dmp.js?c=5999&ver=2" async />
    </Helmet>
  )
}

import { connect } from 'react-redux'

import { getCasinoLinks, getFooterLinks } from '../../../store/app'

import { Sitemap as Component } from './sitemap'

function mapStateToProps(state) {
  return {
    casinoLinks: getCasinoLinks(state.app),
    footerLinks: getFooterLinks(state),
  }
}

export const Sitemap = connect(mapStateToProps)(Component)

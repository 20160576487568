import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { Flex, Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Icon from '../../common/icon/icon'
import PrivacyPolicy from '../privacy-policy'
import TermsConditions from '../terms-conditions'
import { Background } from '../steps/styles'
import { success } from '../../constants/colors'

import LogInDetails from './log-in-details'

const Check = styled(Icon)`
  font-size: 86px;
  color: ${success};
`

class LogInDetailsContainer extends Component {
  constructor() {
    super()
    this.updateDetails = this.updateDetails.bind(this)
  }
  updateDetails() {
    this.props.actions.onPlayerUpdate(this.props.userData)
  }

  componentDidMount() {
    this.props.onDidMount({
      params: this.props.params,
      steps: this.props.steps,
    })
  }

  nextStep() {
    const nextStepIndex =
      R.findIndex(R.equals(this.props.params.step), this.props.steps) + 1
    return this.props.steps[nextStepIndex]
  }

  render() {
    const steps = {
      success: (
        <Space top={6}>
          <Flex column center>
            <Check name="check-circle-o" />
            <Space top={6} bottom={9}>
              {this.props.translate('bank-id.deposit-success')}
            </Space>
            <Link to={`/register/log-in-info/${this.nextStep()}`}>
              <Button variant="primary">
                {this.props.translate('bank-id.deposit-success-continue')}
              </Button>
            </Link>
          </Flex>
        </Space>
      ),
      'login-details': (
        <LogInDetails
          fields={R.pick(['username', 'email', 'password'], this.props.fields)}
          isValid={this.props.isStepValid}
          key="log-in-details"
          onNextStep={this.updateDetails}
          onTermsConditionsClick={this.props.actions.toggleTermsConditions}
          onPrivacyPolicyClick={this.props.actions.togglePrivacyPolicy}
        />
      ),
    }

    return (
      <Background>
        {steps[this.props.params.step]}
        {this.props.location.query.TermsCondition && (
          <TermsConditions
            visible={this.props.location.query.TermsCondition}
            onClose={() =>
              this.props.actions.navigateTo(this.props.location.pathname)}
          />
        )}
        {this.props.location.query.PrivacyPolicy && (
          <PrivacyPolicy
            visible={this.props.location.query.PrivacyPolicy}
            onClose={() =>
              this.props.actions.navigateTo(this.props.location.pathname)}
          />
        )}
      </Background>
    )
  }
}

LogInDetailsContainer.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  actions: PropTypes.object,
  fields: PropTypes.object,
  userData: PropTypes.object,
  isStepValid: PropTypes.bool,
  onDidMount: PropTypes.func.isRequired,
}

export default withTranslate(LogInDetailsContainer)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import styled from '@emotion/styled'
import { Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import { berry } from '../constants/colors'
import { h2 } from '../mixins'

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  overflow: hidden;
  background-color: rgba(255, 0, 0, 0.2);
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &::before {
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    filter: blur(5px);
    content: '';
  }
`

const Box = styled.div`
  position: relative;
  margin: auto;
  padding: 30px;
  max-width: 35em;
  background: white;
  border-radius: 10px;
  color: black;
  text-align: center;
`

const Title = styled.h2`
  ${h2};
  color: ${berry};
  font-weight: 700;
`

const Disclaimer = styled.div`
  color: lightgray;
  font-size: 0.85em;
`

function requiredKeys(id) {
  return [
    `partner-landing-page.${id}.title`,
    `partner-landing-page.${id}.content`,
    `partner-landing-page.${id}.button`,
  ]
}

class Partner extends React.Component {
  componentDidMount() {
    if (
      !R.all(
        this.props.isExistingTranslation,
        requiredKeys(this.props.params.id)
      )
    ) {
      this.props.onNotFound()
    }
  }

  render() {
    const id = this.props.params.id

    if (R.all(this.props.isExistingTranslation, requiredKeys(id))) {
      return (
        <Wrapper>
          <Backdrop
            image={this.props.translate('landing-page.island-opacitated')}
          />
          <Box>
            <Title>
              {this.props.translate(`partner-landing-page.${id}.title`)}
            </Title>
            <Space top={6} bottom={9}>
              <HtmlContent
                html={{
                  __html: this.props.translate(
                    `partner-landing-page.${id}.content`
                  ),
                }}
              />
            </Space>
            <Button onClick={this.props.onAccept}>
              {this.props.translate(`partner-landing-page.${id}.button`)}
            </Button>
            <Space top={6}>
              <Disclaimer>
                <HtmlContent
                  html={{
                    __html: this.props.translate(
                      `partner-landing-page.${id}.disclaimer`
                    ),
                  }}
                />
              </Disclaimer>
            </Space>
          </Box>
        </Wrapper>
      )
    } else {
      return null
    }
  }
}

Partner.propTypes = {
  params: PropTypes.object,
  isExistingTranslation: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onNotFound: PropTypes.func,
}

export default R.compose(
  withTranslate,
  connect(null, {
    onAccept: () => redirectTo('/register/create-account/'),
    onNotFound: () => redirectTo('/'),
  })
)(Partner)

import * as R from 'ramda'
/**
 * Get index with the matching key
 * @private
 * @param {string} key
 * @param {array} images
 * @returns {number} index or 0 if key is missing
 */
export default function getIndexByKey(key, images) {
  const index = R.findIndex(R.propEq('key', key), images)

  return index === -1 ? 0 : index
}

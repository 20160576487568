export const CLEAR = 'casino-heroes/worlds/CLEAR'
export const SELECT_WORLD = 'casino-heroes/worlds/SELECT_WORLD'
export const SELECT_REGION = 'casino-heroes/worlds/SELECT_REGION'
export const STORE_REGIONS = 'casino-heroes/worlds/STORE_REGIONS'
export const STORE_TREASURES = 'casino-heroes/worlds/STORE_TREASURES'
export const UPDATE_IMAGES = 'casino-heroes/worlds/UPDATE_IMAGES'
export const UPDATE_REGION_PROGRESS =
  'casino-heroes/worlds/UPDATE_REGION_PROGRESS'
export const UPDATE_LAST_SEEN_PROGRESS =
  'casino-heroes/worlds/UPDATE_LAST_SEEN_PROGRESS'
export const UPDATE_MAP_POSITION = 'casino-heroes/worlds/UPDATE_MAP_POSITION'
export const UPDATE_STATS = 'casino-heroes/worlds/UPDATE_STATS'
export const UPDATE_DETAILS = 'casino-heroes/worlds/UPDATE_DETAILS'
export const STORE_WORLDS = 'casino-heroes/worlds/STORE_WORLDS'
export const REMOVE_TREASURE = 'casino-heroes/worlds/REMOVE_TREASURE'

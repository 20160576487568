import PropTypes from 'prop-types'
import React from 'react'

import { withTranslate } from '@rushplay/i18n'

import HtmlContent from '../common/html-content'
import PopupContainer from '../common/popup/popup-container'
import { PopupScrollable } from '../common/popup/popup-styles.js'

function PrivacyPolicy(props) {
  return (
    <PopupContainer
      id="privacy-policy"
      isOpen={props.visible}
      title={props.translate('static.privacy-policy.headline')}
      onRequestClose={props.onClose}
    >
      <PopupScrollable>
        <HtmlContent
          html={{ __html: props.translate('static.privacy-policy.content') }}
        />
      </PopupScrollable>
    </PopupContainer>
  )
}

export default withTranslate(PrivacyPolicy)

PrivacyPolicy.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

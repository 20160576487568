import * as R from 'ramda'

const queryLens = R.lensProp('query')

/**
 * Update location query using given transformer
 * @param {Function} transformer
 * @param {Object} location
 * @returns {Object} Transformed location
 * @example
 *   <Link to={updateQuery(R.dissoc('popup'))} />
 */
export function updateQuery(transformer, location) {
  if (typeof location === 'undefined') {
    return location => updateQuery(transformer, location)
  }

  return R.over(queryLens, transformer, location)
}

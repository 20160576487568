import * as R from 'ramda'
import { createSelector } from 'reselect'

const WORLDS_FALLBACK = {}
const _getWorlds = R.propOr(WORLDS_FALLBACK, '__items')

const SORTED_WORLDS_FALLBACK = []
const _getSortedWorlds = R.propOr(SORTED_WORLDS_FALLBACK, 'sortedWorlds')

const CURRENT_WORLD_FALLBACK = {}
export const getCurrentWorld = createSelector(
  [R.propOr('', 'currentWorldKey'), _getWorlds],
  (key, worlds) =>
    R.assoc('key', key, R.propOr(CURRENT_WORLD_FALLBACK, key, worlds))
)

const WORLD_FALLBACK = {}
export const getWorlds = createSelector(
  [_getWorlds, _getSortedWorlds],
  (worlds, keys) => {
    return R.map(R.propOr(WORLD_FALLBACK, R.__, worlds), keys)
  }
)

import * as R from 'ramda'
import * as polished from 'polished'

function y(x) {
  // Amazing formula - No touching!
  return 0.03 * Math.pow(x, 2) + 0.04 * x - 0.05
}

/**
 * @name createColorSet
 * @desc Create a set of 10 color variations from light to dark based on a
 *       starting color.
 * @param {string} name Name of color used as output prefix
 * @param {string} color Color code in hex format, i.e. '#RRGGBB'
 * @returns {Object} a map of colorName: colorCode pairs
 * @example
 * import { createColorSet } from '@rushplay/common'
 *
 * const blues = createColorSet('blue', '#58B1CB')
 */
export function createColorSet(name, color) {
  const brightRatios = R.times(n => y(5 - n), 5)
  const darkRatios = R.times(n => y(n + 1), 5)

  const brights = R.map(amount => polished.tint(amount, color), brightRatios)
  const minDarkRatio = Math.min.apply(Math, darkRatios)
  const darks = R.map(amount => {
    return polished.saturate(
      (minDarkRatio - amount) / 4,
      polished.shade(amount, color)
    )
  }, darkRatios)

  return [...brights, color, ...darks].reduce(
    (set, color, i) => {
      return R.assoc([`${name}${i}`], color, set)
    },
    { [name]: color }
  )
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Space } from '@rushplay/common'

import Button from '../common/button'
import Card from '../common/card'
import Heading from '../common/heading'
import HtmlContent from '../common/html-content'
import LogOutLink from '../components/log-out-link'
import { Constraint } from '../common/grid'
import { berry } from '../constants/colors'

const Center = styled.div`text-align: center;`

const StickyWrapper = styled.div`
  position: sticky;
  top: 48px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0)
  );

  @media (min-width: 1280px) {
    top: 80px;
  }
`

export default function PrivacyPolicyUpdates(props) {
  return (
    <Constraint centered width="600">
      <Space y="32px" x={5}>
        <Card>
          <Space all={6}>
            <Heading align="center" color={berry} level="2">
              {props.translate('privacy-policy.updates.title')}
            </Heading>
            <StickyWrapper>
              <Space top={6} bottom={8}>
                <Center>
                  <Button onClick={props.onAccept}>
                    {props.translate('privacy-policy.updates.accept')}
                  </Button>
                </Center>
              </Space>
            </StickyWrapper>
            <HtmlContent
              html={{
                __html: props.translate('privacy-policy.updates.content'),
              }}
            />
          </Space>
        </Card>
      </Space>
      <Space y="32px" x={5}>
        <Center>
          <LogOutLink />
        </Center>
      </Space>
    </Constraint>
  )
}

PrivacyPolicyUpdates.propTypes = {
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
}

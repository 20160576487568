import React from 'react'

import { BigWins } from '../big-wins'
import { CampaignArea } from '../campaign-area'
import { LandingPageGamesArea } from '../landing-page-games-area'
import { LandingPageSportsArea } from '../landing-page-sports-area'
import { SeoTextArea } from '../seo-text-area'
import { UspsArea } from '../usps-area'
import { VideoArea } from '../video/video-area'

export function LandingPage() {
  return (
    <React.Fragment>
      <VideoArea />
      <BigWins />
      <CampaignArea />
      <LandingPageGamesArea />
      <LandingPageSportsArea />
      <UspsArea />
      <SeoTextArea translationKey="landing-page.seo-content" />
    </React.Fragment>
  )
}

LandingPage.propTypes = {}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PrivacyPolicy from './privacy-policy'
import SignupType from './steps/signup-type'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
// NOTE: No Step3 because we removed the select-avatar step.
// NOTE: No Step4 because we moved phone number to Step2
import Success from './steps/success'
import TermsConditions from './terms-conditions'
import formatUserData from './lib/format-user-data'
import { CasiTabiCampaignTracker } from './steps/casitabi-campaign-tracker'

function UNSAFE_getReferrals() {
  const referrals = localStorage.getItem('referrals')
  try {
    return JSON.parse(referrals)
  } catch (e) {
    return referrals || ''
  }
}

function stepIndex(steps, step) {
  return R.findIndex(R.equals(step), steps)
}

class SignupContainer extends Component {
  constructor() {
    super()
    this.handleNextStep = this.handleNextStep.bind(this)
    this.handlePreviousStep = this.handlePreviousStep.bind(this)
    this.handleRegister = this.handleRegister.bind(this)
  }

  componentDidMount() {
    this.props.onDidMount(this.props.isCasitabi)

    const firstStep = R.includes(
      this.props.countryCode,
      this.props.trustlySignUpCountries
    )
      ? R.head(this.props.steps)
      : R.nth(1, this.props.steps)

    if (this.props.params.step !== firstStep) {
      this.props.onNavigateTo(`/register/${firstStep}`)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.params.step) {
      const currentStepIndex = stepIndex(
        this.props.steps,
        this.props.params.step
      )
      const currentStep = this.props.steps[currentStepIndex]
      this.props.onNavigateTo(`/register/${currentStep}`)
    }
  }

  handleNextStep() {
    const currentStep = stepIndex(this.props.steps, this.props.params.step)

    if (currentStep === 1) {
      if (
        this.props.fields.email.value
          .toLowerCase()
          .includes(this.props.fields.password.value.toLowerCase())
      ) {
        return this.props.onPasswordValidationFailed(
          'error.password-matching-email'
        )
      }
    }

    const nextStepIndex = currentStep + 1
    const nextStep = this.props.steps[nextStepIndex]

    if (nextStep) {
      this.props.onNavigateTo(`/register/${nextStep}`)
    } else {
      // Validate Phone before registering,
      this.props.onPhoneValidation(
        this.props.countryCallingCode,
        this.props.phoneNumber,
        this.handleRegister
      )
    }
  }

  handlePreviousStep() {
    const prevStepIndex =
      stepIndex(this.props.steps, this.props.currentPage) - 1
    const prevStep = this.props.steps[prevStepIndex]

    this.props.onNavigateTo(`/register/${prevStep}`)
  }

  handleRegister() {
    // TODO: replace with redux-effects-localstorage
    const referrals = UNSAFE_getReferrals()

    this.props.onPlayerRegister(
      formatUserData(
        this.props.language,
        this.props.userData,
        referrals,
        {
          utmCampaign: this.props.utmCampaign,
          utmMedium: this.props.utmMedium,
          utmSource: this.props.utmSource,
        },
        this.props.clickId,
        this.props.subID
      ),
      this.props.clientType,
      this.props.isPhoneVerificationRequired
    )
  }

  render() {
    const stepTemplate = this.props.isCasitabi
      ? {
          backgroundImage: this.props.translate('signup.background'),
          backgroundOverlay: this.props.translate('signup.background.overlay'),
          titleBarBackgroundImage: this.props.translate('signup.title-bar'),
          titleBarText: this.props.translate('signup.bar.title'),
        }
      : {}

    const steps = {
      'signup-type': (
        <SignupType
          key="0"
          manualRegistration={this.props.manualRegistration}
        />
      ),
      'create-account': (
        <Step1
          stepTemplate={stepTemplate}
          fields={R.pick(['email', 'password'], this.props.fields)}
          isValid={this.props.isStepValid('step1')}
          isCasitabi={this.props.isCasitabi}
          key="1"
          onNextStep={this.handleNextStep}
        />
      ),
      'additional-information': (
        <Step2
          stepTemplate={stepTemplate}
          countries={this.props.countries}
          countryCallingCode={this.props.userData.countryCallingCode}
          countryCode={this.props.countryCode}
          fields={R.pick(
            [
              'firstName',
              'lastName',
              'day',
              'month',
              'year',
              'street',
              'zip',
              'city',
              'mobile',
            ],
            this.props.fields
          )}
          displayGdprContent={this.props.displayGdprContent}
          isValid={this.props.isStepValid('step2')}
          isCasitabi={this.props.isCasitabi}
          key="2"
          loading={this.props.waitingRegister}
          phoneVerificationRequired={this.props.isPhoneVerificationRequired}
          onBack={this.handlePreviousStep}
          onNextStep={this.handleNextStep}
          onTermsConditionsClick={this.props.onTermsAndConditionsToggle}
          onPrivacyPolicyClick={this.props.onPrivacyPolicyToggle}
        />
      ),
    }

    if (this.props.authenticated && !this.props.isPhoneVerificationRequired) {
      return (
        <Success onSuccess={() => this.props.onNavigateTo('/wallet/deposit')}>
          {this.props.isCasitabi ? <CasiTabiCampaignTracker /> : null}
        </Success>
      )
    }

    return (
      <React.Fragment>
        {steps[this.props.currentPage]}

        {this.props.termsConditionsVisible && (
          <TermsConditions
            visible={this.props.termsConditionsVisible}
            onClose={this.props.onTermsAndConditionsToggle}
          />
        )}
        {this.props.privacyPolicyVisible && (
          <PrivacyPolicy
            visible={this.props.privacyPolicyVisible}
            onClose={this.props.onPrivacyPolicyToggle}
          />
        )}
      </React.Fragment>
    )
  }
}

export default SignupContainer

SignupContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  clickId: PropTypes.string,
  subID: PropTypes.string,
  clientType: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  countryCallingCode: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  currentPage: PropTypes.string,
  fields: PropTypes.object,
  isCasitabi: PropTypes.bool,
  displayGdprContent: PropTypes.bool,
  isStepValid: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  manualRegistration: PropTypes.bool,
  params: PropTypes.object,
  phoneNumber: PropTypes.string,
  privacyPolicyVisible: PropTypes.bool.isRequired,
  isPhoneVerificationRequired: PropTypes.bool,
  selectedCountryCode: PropTypes.string,
  termsConditionsVisible: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  userData: PropTypes.object,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  waitingRegister: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
  trustlySignUpCountries: PropTypes.array.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  onPhoneValidation: PropTypes.func.isRequired,
  onPlayerRegister: PropTypes.func.isRequired,
  onPrivacyPolicyToggle: PropTypes.func.isRequired,
  onTermsAndConditionsToggle: PropTypes.func.isRequired,
  onPasswordValidationFailed: PropTypes.func.isRequired,
}

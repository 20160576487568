import PropTypes from 'prop-types'
import { Component } from 'react'
import { path } from 'ramda'

class NativeApp extends Component {
  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
    document.addEventListener(
      'nativeApp',
      this.props.onToggleNativeAppMode,
      false
    )
    document.addEventListener('login', this.handleLogin, false)
    this.getInitialValuesEvent()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isNativeApp) {
      if (!this.props.activeSession && nextProps.activeSession) {
        // player logged in: send login success to app
        window.webkit.messageHandlers.logged_in.postMessage(
          {
            username: this.props.username,
            password: this.props.password,
          },
          '*'
        )
      } else if (this.props.activeSession && !nextProps.activeSession) {
        // player logged out: send logout to app
        window.webkit.messageHandlers.logged_out.postMessage(null, '*')
        this.getInitialValuesEvent()
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('login', this.handleLogin)
    document.removeEventListener('nativeApp', this.props.onToggleNativeAppMode)
  }

  getInitialValuesEvent() {
    const postEvent = path(
      ['webkit', 'messageHandlers', 'is_native_app'],
      window
    )
    postEvent && postEvent.postMessage(null, '*')
  }

  handleLogin(data) {
    if (this.props.isNativeApp) {
      this.props.onChangeUsername(data.detail.username)
      this.props.onChangePassword(data.detail.password)
      this.props.onLogin(data.detail)
    }
  }

  render() {
    return null
  }
}

NativeApp.propTypes = {
  activeSession: PropTypes.bool.isRequired,
  isNativeApp: PropTypes.bool,
  password: PropTypes.string,
  username: PropTypes.string,
  onChangePassword: PropTypes.func.isRequired,
  onChangeUsername: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onToggleNativeAppMode: PropTypes.func.isRequired,
}

export default NativeApp

import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goBack, push as navigateTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Locks from '@rushplay/compliance/locks'
import { actions, connectForm, selectors } from '@rushplay/legacy-forms'
import {
  fetchLocks as fetchLocksApi,
  login as loginApi,
  registerPlayer as registerPlayerApi,
  requestSmsVerification as requestSmsVerificationApi,
  validatePhoneNumber as validatePhoneNumberApi,
} from '@rushplay/api-client'
import {
  getAffiliateClickId,
  isPhoneVerificationRequired,
  isSessionActive,
  session,
} from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as CombinedSelectors from '../combined-selectors'
import * as lookup from '../store/lookup'
import errorKey from '../error-key'
import {
  fetchCountries,
  fetchCountryCode,
  getCountries,
  getPrivacyPolicyVisible,
  getTermsConditionsVisible,
  getWaitingRegister,
  registrationFail,
  registrationSuccess,
  togglePrivacyPolicy,
  toggleTermsConditions,
  waitRegister,
} from '../store/sign-up'
import {
  getBrand,
  getClientType,
  getLanguage,
  getUtmCampaign,
  getUtmMedium,
  getUtmSource,
  handleSessionInitialization,
  isGdprJurisdiction,
} from '../store/app'

const isStepValid = R.curry((formsState, group) => {
  return selectors.isAllFieldsHaveStatus(formsState, {
    form: 'sign-up',
    group,
    status: 'valid',
  })
})

function mapStateToProps(state, ownProps) {
  const isCasitabi = getBrand(state.app) === 'casitabi'

  return {
    authenticated: isSessionActive(state.session),
    clickId: getAffiliateClickId(state.session),
    subID: CombinedSelectors.getAffiliateSubId(state),
    clientType: getClientType(state.app),
    steps: ['signup-type', 'create-account', 'additional-information'],
    countries: R.map(
      (country) =>
        R.assoc(
          'name',
          ownProps.translate(
            `country.${R.toLower(R.replace(/\s/g, '-', country.name))}`
          ),
          country
        ),
      getCountries(state.signUp)
    ),
    countryCode: isCasitabi ? 'JP' : lookup.getCountryCode(state.lookup),
    countryCallingCode: R.replace(
      '+',
      '',
      selectors.value(state.forms, {
        form: 'sign-up',
        field: 'countryCallingCode',
      })
    ),
    currentPage: ownProps.params.step,
    displayGdprContent: isGdprJurisdiction(state),
    isCasitabi,
    isStepValid: isStepValid(state.forms),
    language: getLanguage(state.app),
    manualRegistration: jurisdiction.getManualSignUpAllowed(state.jurisdiction),
    isPhoneVerificationRequired: isPhoneVerificationRequired(state.session),
    trustlySignUpCountries: ['FI'],
    selectedCountryCode: R.path(['fields', 'countryCode', 'value'], ownProps),
    translate: ownProps.translate,
    termsConditionsVisible: getTermsConditionsVisible(state.signUp),
    phoneNumber: selectors.value(state.forms, {
      form: 'sign-up',
      field: 'mobile',
    }),
    privacyPolicyVisible: getPrivacyPolicyVisible(state.signUp),
    userData: R.pluck(
      'value',
      selectors.fields(state.forms, { form: ownProps.form })
    ),
    utmCampaign: getUtmCampaign(state.app),
    utmMedium: getUtmMedium(state.app),
    utmSource: getUtmSource(state.app),
    waitingRegister: getWaitingRegister(state.signUp),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCountries,
      fetchCountryCode,
      onDidMount: (isCasitabi) => {
        const actions = [fetchCountries()]
        if (!isCasitabi) {
          actions.push(fetchCountryCode())
        }
        return actions
      },
      onPasswordValidationFailed: (error) => {
        dispatch(Notifications.add({ message: error, level: 'error' }))
      },
      onPhoneValidation: (callingCode, value, onSuccess) => {
        const config = {
          success: () => {
            onSuccess()
            return actions.validate('sign-up', 'mobile', {
              status: true,
              value,
            })
          },
          failure: (response) =>
            actions.validate('sign-up', 'mobile', {
              value,
              status: false,
              errors: errorKey('sign-up', 'mobile', response.value.message),
            }),
          version: 2,
        }

        return validatePhoneNumberApi(callingCode, value, config)
      },
      onPlayerRegister: (userData, clientType, isPhoneVerificationRequired) => {
        const data = R.merge(userData, { generate_username: true })
        const registerAction = registerPlayerApi(data, {
          success: () => {
            return loginApi(userData.email, userData.password, clientType, {
              version: 2,
              success: (payload) => {
                const actions = [
                  waitRegister(false),
                  registrationSuccess(payload.value.player),
                ]

                return fetchLocksApi({
                  success: (res) => {
                    if (
                      R.includes(
                        'email-verification',
                        R.pluck('type', res.value)
                      )
                    ) {
                      actions.push(Locks.fetch({ token: payload.value.token }))
                      actions.push(session.store(payload.value))
                    } else if (isPhoneVerificationRequired) {
                      actions.push(
                        requestSmsVerificationApi({
                          success: () => [
                            session.store(payload.value),
                            navigateTo('/phone-verification'),
                          ],
                          failure: () =>
                            Notifications.add({
                              message: 'error.generic',
                              level: 'error',
                            }),
                          version: 1,
                          token: payload.value.token,
                        })
                      )
                    } else {
                      actions.push(handleSessionInitialization(payload, false))
                    }
                    return actions
                  },
                  failure: () => {
                    actions.push(handleSessionInitialization(payload, false))
                    return actions
                  },
                  token: payload.value.token,
                  version: 1,
                })
              },
              failure: (error) => {
                const path = R.path(['value', 'errors', 'base', 0], error)

                if (R.has('reason', path)) {
                  return Notifications.add({
                    message: `errors.${path.errorCode}.${path.reason}`,
                    level: 'error',
                  })
                } else {
                  return Notifications.add({
                    message: 'error.generic',
                    level: 'error',
                  })
                }
              },
            })
          },
          failure: (response) => {
            const actions = [
              waitRegister(false),
              registrationFail(response.value.errors),
            ]
            if (response.value.errors) {
              actions.push(
                R.pipe(
                  R.map((error) =>
                    Notifications.add({
                      message: `sign-up.errors.${R.head(error)}`,
                      level: 'error',
                    })
                  ),
                  R.values
                )(response.value.errors)
              )
            }

            return actions
          },
          version: 1,
        })

        return [waitRegister(true), registerAction]
      },
      onClose: goBack,
      onPrivacyPolicyToggle: togglePrivacyPolicy,
      onTermsAndConditionsToggle: toggleTermsConditions,
      onNavigateTo: navigateTo,
    },
    dispatch
  )
}

export default R.compose(
  (component) => connectForm(component, { name: 'sign-up' }),
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps)
)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { berry, night, nightDark } from '../../constants/colors'

function backgroundImage({ percentage = 0 }) {
  const degree = 90 + percentage * 3.6
  if (percentage > 50) {
    return `linear-gradient(${degree}deg, ${berry} 50%, transparent 50%, transparent), linear-gradient(270deg, ${berry} 50%, ${nightDark} 50%, ${nightDark})`
  }

  return `linear-gradient(90deg, ${nightDark} 50%, transparent 50%, transparent), linear-gradient(${degree}deg, ${berry} 50%, ${nightDark} 50%, ${nightDark})`
}

const Circle = styled.div`
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: ${backgroundImage};
`

const Overlay = styled.div`
  position: absolute;
  width: 84%;
  height: 84%;
  background-color: ${night};
  border-radius: 50%;
  margin-left: 8%;
  margin-top: 8%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
function RadialProgress(props) {
  if (typeof props.percentage === 'number' && props.percentage >= 0) {
    return (
      <Circle percentage={props.percentage}>
        <Overlay>{`${props.percentage}%`}</Overlay>
      </Circle>
    )
  }

  return null
}

export default RadialProgress

RadialProgress.propTypes = { percentage: PropTypes.number }

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

const Wrapper = styled.svg`
  pointer-events: auto;
  width: 100%;
`

function SvgTreasure(props) {
  const id = R.replace(/[^A-z0-9]/g, '', props.src)
  return (
    <Wrapper viewBox="-50 -50 100 100" preserveAspectRatio="xMidYMid">
      <defs>
        <pattern
          id={id}
          x="-50"
          y="-50"
          patternUnits="userSpaceOnUse"
          height="100"
          width="100"
        >
          <image x="0" y="0" height="100" width="100" xlinkHref={props.src} />
        </pattern>
      </defs>
      <rect
        cursor="pointer"
        onClick={props.onClick}
        x="-50"
        y="-50"
        width="100"
        height="100"
        fill={`url(#${id})`}
      />
    </Wrapper>
  )
}

export default SvgTreasure

SvgTreasure.propTypes = {
  src: PropTypes.string,
  onClick: PropTypes.func,
}

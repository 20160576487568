import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Colors from '../../constants/colors'
import Arrow from '../../images/arrow.svg'
import Button from '../../common/button'

export function AffiliateBar(props) {
  const isSignupPage = R.path(['params', 'step'], props) === 'create-account'

  React.useEffect(() => {
    props.onFetch(props.btag)
  }, [props.btag])

  const i18n = I18n.useI18n()

  if (!i18n.isExistingTranslation(props.affiliateInfo)) {
    return null
  }

  return (
    <Common.Box width={1} backgroundColor={Colors.skyBlue}>
      <Common.Box
        maxWidth="1200px"
        margin="auto"
        display="flex"
        padding={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Common.Box display="flex" alignItems="center">
          <Common.Box
            size={['40px', null, '64px']}
            minWidth="40px"
            backgroundImage={`url(${props.affiliateLogo})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            marginRight={[3, null, 5]}
          />
          <Common.Text
            fontWeight={700}
            fontSize={[1, null, 3, 4]}
            lineHeight="normal"
          >
            {i18n.translate(props.affiliateInfo)}
          </Common.Text>
        </Common.Box>
        {!isSignupPage && (
          <React.Fragment>
            <Common.Box display={['none', null, 'block']}>
              <Button
                as={Common.Link}
                to="/register"
                variant="primary"
                minimalPadding
              >
                {i18n.translate('landing-page.affiliate-bar.cta')}
              </Button>
            </Common.Box>
            <Common.Box
              as={Common.Link}
              to="/register"
              display={['block', null, 'none']}
              size="16px"
              backgroundImage={`url(${Arrow})`}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              paddingLeft={3}
            />
          </React.Fragment>
        )}
      </Common.Box>
    </Common.Box>
  )
}

AffiliateBar.propTypes = {
  affiliateInfo: PropTypes.string,
  affiliateLogo: PropTypes.string,
  btag: PropTypes.string,
  returningUser: PropTypes.bool,
  onFetch: PropTypes.func.isRequired,
  params: PropTypes.object,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { toFixed } from './to-fixed'

function capVelocity(maxVelocity, velocity) {
  const direction = velocity < 0 ? -1 : 1
  return Math.min(Math.abs(velocity), maxVelocity) * direction
}

const INITIAL_STATE = {
  containerWidth: 0,
  contentWidth: 0,
  offset: 0,
  position: null,
  scrolling: false,
  velocity: 0,
}
const SCROLLING_CHANGED = 'SCROLLING_CHANGED'
const SCROLLING_STARTED = 'SCROLLING_STARTED'
const SCROLLING_STOPPED = 'SCROLLING_STOPPED'
const CONTAINER_RESIZED = 'CONTAINER_RESIZED'
const CONTENT_RESIZED = 'CONTENT_RESIZED'

function getThumbWidth(state) {
  if (state.contentWidth === 0) {
    return 0
  }

  return Math.min((state.containerWidth / state.contentWidth) * 100, 100)
}

function getScrollMultiplier(state) {
  const thumbWidth = getThumbWidth(state)

  if (thumbWidth === 0) {
    return 0
  }

  return 100 / thumbWidth
}

function getThumbOffset(state) {
  if (state.contentWidth === 0) {
    return 0
  }

  const scrollMultiplier = getScrollMultiplier(state)
  const maxOffset = state.contentWidth - state.containerWidth
  const offset = Math.max(0, Math.min(state.offset, maxOffset))
  return (offset / state.contentWidth) * 100 * scrollMultiplier
}

function reducer(state, action) {
  switch (action.type) {
    case CONTAINER_RESIZED: {
      return {
        ...state,
        containerWidth: action.payload.width,
      }
    }

    case CONTENT_RESIZED: {
      return {
        ...state,
        contentWidth: action.payload.width,
      }
    }

    case SCROLLING_STARTED: {
      return {
        ...state,
        offset: action.payload.offset,
        position: action.payload.position,
        scrolling: true,
        velocity: 0,
      }
    }

    case SCROLLING_CHANGED: {
      if (action.payload.nextOffset != null) {
        if (state.scrolling) {
          return state
        }

        return {
          ...state,
          offset: action.payload.nextOffset,
        }
      }

      if (action.payload.inertia) {
        // scroll offset must be integer
        const offset = Math.round(state.offset + state.velocity)
        const velocity = toFixed(2, state.velocity * action.payload.inertia)

        return {
          ...state,
          offset,
          velocity,
        }
      }

      if (!state.scrolling) {
        return state
      }

      const prevOffset = state.offset
      const prevPosition = state.position

      const multiplier = getScrollMultiplier(state)
      const position = action.payload.position
      const offset = prevOffset + (position - prevPosition) * multiplier

      return {
        ...state,
        offset,
        position,
        velocity: offset - prevOffset,
      }
    }

    case SCROLLING_STOPPED: {
      if (!state.scrolling) {
        return state
      }

      return {
        ...state,
        scrolling: false,
        velocity: capVelocity(action.payload, state.velocity),
      }
    }

    default: {
      return state
    }
  }
}

function useReducer() {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  const actions = React.useMemo(
    () => {
      function decelerate(inertia) {
        dispatch({
          type: SCROLLING_CHANGED,
          payload: { inertia },
        })
      }

      function move(position) {
        dispatch({
          type: SCROLLING_CHANGED,
          payload: { position },
        })
      }

      function resizeContainer(width) {
        dispatch({
          type: CONTAINER_RESIZED,
          payload: { width },
        })
      }

      function resizeContent(width) {
        dispatch({
          type: CONTENT_RESIZED,
          payload: { width },
        })
      }

      function scroll(nextOffset) {
        dispatch({
          type: SCROLLING_CHANGED,
          payload: { nextOffset },
        })
      }

      function start(offset, position) {
        dispatch({
          type: SCROLLING_STARTED,
          payload: { offset, position },
        })
      }

      function stop(maxVelocity) {
        dispatch({
          type: SCROLLING_STOPPED,
          payload: maxVelocity,
        })
      }

      return {
        decelerate,
        move,
        resizeContainer,
        resizeContent,
        scroll,
        start,
        stop,
      }
    },
    [dispatch]
  )

  return [state, actions]
}

const ScrollableRowWrapper = styled.div`
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overflow-y: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }

  @supports (scrollbar-width: none) {
    scrollbar-width: none;
  }
`

/**
 * Component that renders content scrollable horizontally.
 *
 * @param {Object} props
 * @param {Function} props.scrollBar
 *   Component to be rendered as a scroll bar. Must accept following props to
 *   be rendered and function correctly:
 *     - `offset` - number representing thumb shift in percents. Designed to be
 *       passed to CSS `translateX`
 *     - `width` - number representing thumb width in percents.
 *     - `onThumbMouseDown` - `mousedown` event handler to be passed as
 *       `onMouseDown` prop to thumb component inside scroll bar.
 */
export function ScrollableRow(props) {
  const ref = React.useRef(null)
  const [state, actions] = useReducer()

  /**
   * Initialize scroll capturing
   */
  const handleStart = React.useCallback(
    position => {
      const node = ref.current
      if (node) {
        actions.start(node.scrollLeft, position)
      }
    },
    [actions, ref]
  )

  /**
   * Update scroll position based on pointer movement
   */
  const handleMove = React.useCallback(
    position => {
      const node = ref.current
      if (node) {
        actions.move(position)
      }
    },
    [actions, ref]
  )

  const handleFinish = React.useCallback(
    () => {
      actions.stop(props.maxVelocity)
    },
    [actions, props.maxVelocity]
  )

  /* MOUSE HANDLING */
  const handleMouseMove = React.useCallback(
    event => {
      handleMove(event.clientX)
    },
    [handleMove]
  )

  const handleMouseUp = React.useCallback(
    () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)

      handleFinish()
    },
    [handleFinish]
  )

  const handleMouseDown = React.useCallback(
    event => {
      if ((event.button === 1 && window.event != null) || event.button === 0) {
        handleStart(event.clientX)

        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
      }
    },
    [handleStart]
  )
  /* END MOUSE HANDLING */

  // Take care of inertia when scrolling has stopped
  React.useEffect(
    () => {
      if (!state.scrolling && Math.floor(Math.abs(state.velocity)) !== 0) {
        const rafId = window.requestAnimationFrame(() => {
          actions.decelerate(props.drag)
        })
        return () => window.cancelAnimationFrame(rafId)
      }
    },
    [actions, props.drag, state.scrolling, state.velocity]
  )

  React.useEffect(
    () => {
      const node = ref.current
      if (node) {
        const isDragging = Math.floor(Math.abs(state.velocity)) !== 0
        const isScrolling = state.scrolling || isDragging
        if (isScrolling && node.scrollLeft !== state.offset) {
          node.scrollLeft = state.offset
        }
      }
    },
    [state.offset, state.scrolling, state.velocity]
  )

  React.useEffect(
    () => {
      function handleResize() {
        actions.resizeContainer(ref.current.clientWidth)
        actions.resizeContent(ref.current.scrollWidth)
      }

      if (ref.current) {
        handleResize()
        window.addEventListener('resize', handleResize, true)
        return () => window.removeEventListener('resize', handleResize, true)
      }
    },
    [ref, actions.resizeContainer, actions.resizeContent]
  )

  React.useEffect(
    () => {
      function handleScroll() {
        actions.scroll(ref.current.scrollLeft)
      }

      if (ref.current) {
        ref.current.addEventListener('scroll', handleScroll, true)
        return () => {
          ref.current.removeEventListener('scroll', handleScroll, true)
        }
      }
    },
    [ref]
  )

  const ScrollBar = props.scrollBar

  return (
    <React.Fragment>
      <ScrollableRowWrapper ref={ref}>{props.children}</ScrollableRowWrapper>
      <ScrollBar
        offset={getThumbOffset(state)}
        width={getThumbWidth(state)}
        onThumbMouseDown={handleMouseDown}
      />
    </React.Fragment>
  )
}

ScrollableRow.defaultProps = {
  drag: 0.9,
  maxVelocity: 40,
  scrollBar: () => null,
}

ScrollableRow.propTypes = {
  children: PropTypes.node,
  drag: PropTypes.number,
  maxVelocity: PropTypes.number,
  scrollBar: PropTypes.func,
}

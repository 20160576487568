import * as R from 'ramda'
import * as Reselect from 'reselect'
import { STORAGE_TYPE, removeItem } from 'redux-effects-localstorage'

import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as websockets from '@rushplay/websockets'

import * as processIds from '../constants/processes'

import limits from './limits'

const UPDATE_PLAYER_INFO = 'casino-heroes/player/UPDATE_PLAYER_INFO'
const SET_PERIOD = 'casino-heroes/player/SET_PERIOD'
const CLEAR_GAMBLING_HISTORY = 'casino-heroes/player/CLEAR_GAMBLING_HISTORY'
const CLEAR_GIFT = 'casino-heroes/player/CLEAR_GIFT'
const CLEAR_PLAYER = 'casino-heroes/player/CLEAR_PLAYER'
const ACKNOWLEDGE_REALITY_CHECK =
  'casino-heroes/player/ACKNOWLEDGE_REALITY_CHECK'
const RESET_REALITY_CHECK_TIMER =
  'casino-heroes/player/RESET_REALITY_CHECK_TIMER'
const UPDATE_REALITY_CHECK_TIMER =
  'casino-heroes/player/UPDATE_REALITY_CHECK_TIMER'
const INCREMENT_DEPOSIT_NUMBER = 'casino-heroes/player/INCREMENT_DEPOSIT_NUMBER'
const PUSH_GAMBLING_HISTORY_PAGE =
  'casino-heroes/player/PUSH_GAMBLING_HISTORY_PAGE'
const SET_PLAYER_REQUIRES_INFO = 'casino-heroes/player/SET_PLAYER_REQUIRES_INFO'
const ACKNOWLEDGE_BALANCE_TYPE_CHANGE =
  'casino-heroes/player/ACKNOWLEDGE_BALANCE_TYPE_CHANGE'
const PROMPT_BALANCE_TYPE_CHANGE =
  'casino-heroes/player/PROMPT_BALANCE_TYPE_CHANGE'
const DEPOSIT_INFORMATION_UPDATED =
  'casino-heroes/player/DEPOSIT_INFORMATION_UPDATED'
const WITHDRAW_INFORMATION_UPDATED =
  'casino-heroes/player/WITHDRAW_INFORMATION_UPDATE'
const PAYMENT_CONVERTED_AMOUNT_RESET =
  'casino-heroes/player/PAYMENT_ONVERTED_AMOUNT_RESET'
const PAYMENT_CONVERTED_AMOUNT_UPDATED =
  'casino-heroes/player/PAYMENT_CONVERTED_AMOUNT_UPDATED'
const COOKIE_CONSENT_UPDATED = 'casino-heroes/player/COOKIE_CONSENT_UPDATED'
const UPDATE_PLAYER_NET_DEPOSITS =
  'casino-heroes/player/UPDATE_PLAYER_NET_DEPOSITS'
const UPDATE_PLAYER_BALANCE_TOTALS =
  'casino-heroes/player/UPDATE_PLAYER_BALANCE_TOTALS'
const TOTAL_DEPOSITS_UPDATED = 'speedy/player/TOTAL_DEPOSITS_UPDATED'

const UPDATE_SESSION_STATS = 'speedy/player/UPDATE_SESSION_STATS'
const CLEAR_SESSION_STATS = 'speedy/player/CLEAR_SESSION_STATS'

export function fetch(config = {}) {
  return [
    Processes.start(processIds.FETCH_PLAYER_INFO),
    Api.fetchPlayerInfo({
      version: 2,
      token: config.token,
      success: (res) => [
        updatePlayerInfo(res.value.result),
        Processes.stop(processIds.FETCH_PLAYER_INFO),
        config.success && config.success(),
      ],
      failure: () => [
        Processes.stop(processIds.FETCH_PLAYER_INFO),
        config.failure && config.failure(),
      ],
    }),
  ]
}

export function getCookieConsent(state) {
  return R.path(['player', 'cookieConsent'], state)
}

export function getFirstName(state) {
  return R.path(['player', 'address', 'firstName'], state.player)
}

export function getLastName(state) {
  return R.path(['player', 'address', 'lastName'], state.player)
}

export function getStreet(state) {
  return R.path(['player', 'address', 'street'], state.player)
}

export function getZip(state) {
  return R.path(['player', 'address', 'zip'], state.player)
}

export function getCity(state) {
  return R.path(['player', 'address', 'city'], state.player)
}

export function getState(state) {
  return R.path(['player', 'address', 'state'], state.player)
}

export function getPhoneNumber(state) {
  return R.path(['player', 'address', 'mobile'], state.player)
}

export function getEmail(state) {
  return R.path(['player', 'email'], state.player)
}

export function getTotalDepositsCents(state) {
  return R.path(
    ['totalDepositCents', 'result', 'total_deposit_cents'],
    state.player
  )
}

export function getCountry(state) {
  const country = R.find(
    R.propEq('alpha2', R.path(['player', 'countryCode'], state.player))
  )(R.pathOr([], ['countries'], state.app))
  return R.path(['name'], country)
}

export function updateConvertedAmount(payload) {
  return {
    type: PAYMENT_CONVERTED_AMOUNT_UPDATED,
    payload,
  }
}

export function resetConvertedAmount() {
  return { type: PAYMENT_CONVERTED_AMOUNT_RESET }
}

export function getConvertedAmount(state) {
  return R.pathOr(0, ['convertedAmount'], state.player)
}

export function updateCookieConsent(payload) {
  return {
    type: COOKIE_CONSENT_UPDATED,
    payload,
  }
}

export function updatePlayerNetDeposits(payload) {
  return {
    type: UPDATE_PLAYER_NET_DEPOSITS,
    payload,
  }
}

export function updateDepositInformation(payload) {
  return {
    type: DEPOSIT_INFORMATION_UPDATED,
    payload,
  }
}

export function updateWithdrawInformation(payload) {
  return {
    type: WITHDRAW_INFORMATION_UPDATED,
    payload,
  }
}

export function updatePlayerBalanceTotals(payload) {
  return {
    type: UPDATE_PLAYER_BALANCE_TOTALS,
    payload,
  }
}

export function resetRealityCheckTimer() {
  return { type: RESET_REALITY_CHECK_TIMER }
}

export function updateTimeToRealityCheck(timeMs) {
  return {
    type: UPDATE_REALITY_CHECK_TIMER,
    payload: timeMs,
  }
}

export function setRealityCheckPeriod(minutes) {
  return {
    type: SET_PERIOD,
    payload: minutes,
  }
}

export function setPlayerRequiresInfo(sessionInfo) {
  return {
    type: SET_PLAYER_REQUIRES_INFO,
    payload: sessionInfo,
  }
}

export function updatePlayerInfo(playerInfo) {
  return {
    type: UPDATE_PLAYER_INFO,
    payload: playerInfo,
  }
}

export function updateTotalDepositsCents(payload) {
  return {
    type: TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}

export function clearPlayer() {
  return [
    removeItem('timeToRealityCheck', STORAGE_TYPE.session),
    removeItem('realityCheckPeriod', STORAGE_TYPE.session),
    removeItem('lastLoggedRealityCheckTimestamp', STORAGE_TYPE.session),
    { type: CLEAR_PLAYER },
  ]
}

export function clearGift() {
  return { type: CLEAR_GIFT }
}

export function acknowledgeRealityCheck() {
  return { type: ACKNOWLEDGE_REALITY_CHECK }
}

export function acknowledgeBalanceTypeChange() {
  return { type: ACKNOWLEDGE_BALANCE_TYPE_CHANGE }
}

export function promptBalanceTypeChange() {
  return { type: PROMPT_BALANCE_TYPE_CHANGE }
}

export function getCountryCode(state) {
  return R.path(['player', 'countryCode'], state.player)
}

export function getRealityCheckPeriod(player) {
  return player.realityCheckPeriod
}

export function getTimeToRealityCheck(player) {
  return player.timeToRealityCheck
}

export function getSessionStats(player) {
  return player.sessionStats
}

export function getDepositCount(player) {
  return player.depositNumber
}

export function getDepositAttempts(player) {
  return player.depositAttempts || 0
}

export function getGiftAmount(player) {
  return R.path(['gift', 'bonusCents'], player)
}

export function getGiftCasinoWagerFactor(player) {
  return R.path(['gift', 'wagerFactor'], player)
}

export function getGiftSportWagerFactor(player) {
  return R.path(['gift', 'sportWagerFactor'], player)
}

export function getPendingRealityCheck(player) {
  return player.pendingRealityCheck
}

export function getUserId(player) {
  return R.path(['player', 'username'], player)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getMapsPlayerId(state) {
  return R.path(['player', 'mapsPlayerId'], state)
}

export function getPlayerSubId(player) {
  return R.path(['player', 'affiliateSubId'], player)
}

export function incrementDepositNumber() {
  return { type: INCREMENT_DEPOSIT_NUMBER }
}

export function updateSessionStats(sessionStats) {
  return {
    type: UPDATE_SESSION_STATS,
    payload: sessionStats,
  }
}

export function clearSessionStats() {
  return {
    type: CLEAR_SESSION_STATS,
  }
}

export function getActiveBoosterItem(items) {
  const boosters = items.filter(
    (item) =>
      item.effectActiveTo &&
      item.multiplier &&
      Date.now() < Date.parse(item.effectActiveTo)
  )
  const multiplier = Math.max.apply(
    null,
    boosters.map((o) => {
      return o.multiplier
    })
  )
  return multiplier
}

export function getLastDepositAmount(state) {
  return R.pathOr(null, ['lastDepositEurCents'], state)
}

export function getLastDepositCents(state) {
  return R.pathOr(null, ['lastDepositCents'], state)
}

export function getDepositLimits(state) {
  return R.pathOr([], ['depositInformation', 'depositLimits'], state.player)
}

export function getInitialDepositCents(state) {
  return R.pathOr(0, ['depositInformation', 'initialDeposit'], state.player)
}

/**
 * @returns {number} player's deposit limit remainder in cents or Infinity
 */
export const getDepositLimitRemainder = Reselect.createSelector(
  [getDepositLimits],
  (depositLimits) =>
    R.reduce(
      R.min,
      Number.POSITIVE_INFINITY,
      R.pluck('leftToDepositCents', depositLimits)
    )
)

export function getWithdrawInformation(state) {
  return R.pathOr({}, ['withdrawInformation'], state.player)
}

export function getWithdrawalLimits(state) {
  return R.pathOr([], ['withdrawInformation', 'withdrawLimits'], state.player)
}

/**
 * @returns {number} player's withdrawal limit remainder in cents or Infinity
 */
export const getWithdrawalLimitRemainder = Reselect.createSelector(
  [getWithdrawalLimits],
  (withdrawalLimits) =>
    R.reduce(
      R.min,
      Number.POSITIVE_INFINITY,
      R.pluck('leftToWithdrawCents', withdrawalLimits)
    )
)

const initialState = {
  history: [],
  cursor: {},
  totals: null,
  depositNumber: 0,
  depositOffersAvailable: true,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case COOKIE_CONSENT_UPDATED: {
      return R.assoc('cookieConsent', action.payload, state)
    }

    case SET_PLAYER_REQUIRES_INFO: {
      return Object.assign({}, state, {
        player: action.payload.player,
        quickGames: action.payload.quickGames,
        token: action.payload.token,
      })
    }

    case PAYMENT_CONVERTED_AMOUNT_UPDATED: {
      return R.assoc('convertedAmount', action.payload)(state)
    }

    case PAYMENT_CONVERTED_AMOUNT_RESET: {
      return R.assoc('convertedAmount', null, state)
    }

    case UPDATE_PLAYER_INFO: {
      return Object.assign({}, state, action.payload)
    }

    case limits.types.INIT:
    case limits.types.EDIT:
    case limits.types.CANCEL:
    case limits.types.CLEAR:
    case limits.types.REMOVE:
    case limits.types.SET: {
      return R.assoc('limits', limits.reducer(state.limits, action), state)
    }
    case CLEAR_GIFT: {
      return R.assoc('gift', null, state)
    }
    case websockets.SESSION_EXPIRED:
    case CLEAR_PLAYER: {
      return initialState
    }

    case CLEAR_GAMBLING_HISTORY: {
      return R.mergeRight(state, initialState)
    }

    case ACKNOWLEDGE_REALITY_CHECK: {
      return R.dissoc('pendingRealityCheck', state)
    }

    case PROMPT_BALANCE_TYPE_CHANGE: {
      return R.mergeRight(state, { showBalanceTypeChangeMessage: true })
    }

    case ACKNOWLEDGE_BALANCE_TYPE_CHANGE: {
      return R.dissoc('showBalanceTypeChangeMessage', state)
    }

    case SET_PERIOD: {
      return Object.assign({}, state, {
        realityCheckPeriod: Number.parseInt(action.payload),
      })
    }
    case UPDATE_REALITY_CHECK_TIMER: {
      return Object.assign({}, state, {
        timeToRealityCheck: Number.parseInt(action.payload),
      })
    }

    case PUSH_GAMBLING_HISTORY_PAGE: {
      if (R.isEmpty(action.payload.result)) {
        return R.mergeRight(state, {
          cursor: null,
        })
      } else {
        return R.mergeRight(state, {
          history: R.append(action.payload.result, state.history),
          cursor: action.payload.cursor,
          totals: action.payload.totals,
        })
      }
    }

    case RESET_REALITY_CHECK_TIMER: {
      return R.mergeRight(state, {
        pendingRealityCheck: true,
        timeToRealityCheck: state.realityCheckPeriod,
      })
    }

    case INCREMENT_DEPOSIT_NUMBER:
      return Object.assign({}, state, {
        depositNumber: state.depositNumber + 1,
      })

    case UPDATE_PLAYER_NET_DEPOSITS:
      return R.mergeRight(state, {
        netDeposits: action.payload.netDeposits,
      })

    case UPDATE_PLAYER_BALANCE_TOTALS:
      return R.mergeRight(state, {
        balanceTotals: action.payload,
      })

    case UPDATE_SESSION_STATS:
      return R.mergeRight(state, {
        sessionStats: action.payload,
      })

    case CLEAR_SESSION_STATS:
      return R.mergeRight(state, {
        sessionStats: {},
      })

    case DEPOSIT_INFORMATION_UPDATED: {
      return R.assoc('depositInformation', action.payload, state)
    }

    case WITHDRAW_INFORMATION_UPDATED: {
      return R.assoc('withdrawInformation', action.payload, state)
    }

    case TOTAL_DEPOSITS_UPDATED:
      return R.merge(state, {
        totalDepositCents: action.payload,
      })

    default: {
      return state
    }
  }
}

export default reducer

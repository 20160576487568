import PropTypes from 'prop-types'
import React from 'react'

import { Constraint, Space } from '@rushplay/common'

import Card from '../../common/card'
import LogOutLink from '../log-out-link'
import Text from '../../common/text'

export default function LockPage(props) {
  return (
    <Constraint width="600" centered>
      <Space y={6}>
        <Card backgroundColor={props.backgroundColor}>
          <Space all={6}>{props.children}</Space>
        </Card>
      </Space>
      <Space y="32px" x={5}>
        <Text align="center">
          <LogOutLink />
        </Text>
      </Space>
    </Constraint>
  )
}

LockPage.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
}

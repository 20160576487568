import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system'

import propTypes from '@styled-system/prop-types'
import styled from '@emotion/styled'
import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop'

const shouldForwardProp = createShouldForwardProp([...props, 'as', 'cursor'])

/**
 * Universal component, can be used for almost anything
 * Can be supplied responsive values
 * For more info see https://styled-system.com/api
 * @param {Object} props Supports all these https://styled-system.com/table props
 * @returns {ReactComponent}
 */
export const Box = styled('div', { shouldForwardProp })(
  compose(
    background,
    border,
    color,
    compose,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    typography,
    system({
      cursor: true,
      transition: true,
    })
  )
)

Box.propTypes = {
  ...propTypes.background,
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.compose,
  ...propTypes.flexbox,
  ...propTypes.grid,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.shadow,
  ...propTypes.space,
  ...propTypes.typography,
}

import PropTypes from 'prop-types'
import React from 'react'

import { Field } from '@rushplay/forms'

import { Input } from './input'
import { normalizers } from './normalizers'
import { parsers } from './parsers'

export function NumberInput(props) {
  return (
    <Field
      initialValue={props.initialValue}
      name={props.name}
      normalize={props.normalize || normalizers[props.format]}
      parse={props.parse || parsers[props.format]}
      validator={props.validators}
      mountPoint="formsv2"
    >
      {field => (
        <Input
          {...field.getProps(props)}
          autoComplete="off"
          autoFocus={props.autoFocus}
          className={props.className}
          currency={props.currency}
          disabled={props.disabled}
          id={props.id}
          inputMode={props.inputMode}
          max={props.max}
          min={props.min}
          placeholder={props.placeholder}
          small
          glowingBorder={props.glowingBorder}
          step={props.step}
          type="number"
        />
      )}
    </Field>
  )
}

NumberInput.defaultProps = {
  // Prevents number from displaying in scientific format
  max: Number.MAX_SAFE_INTEGER,
}

NumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  initialValue: PropTypes.number,
  inputMode: PropTypes.string,
  format: PropTypes.string,
  glowingBorder: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
  ]),
  value: PropTypes.number,
}

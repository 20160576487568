import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Constraint, Flex, Space } from '@rushplay/common'
import { Field } from '@rushplay/legacy-forms'

import Button from '../../../common/button'
import Heading from '../../../common/heading'
import HtmlContent from '../../../common/html-content'
import InputField from '../../../common/input-field'
import LockPage from '../lock-page'
import Text from '../../../common/text'
import validators from '../../../validators'
import { SetLimitsConfirmation } from '../../../limits/set-limits-confirmation'

const LimitInput = styled(InputField)`
  width: 210px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0;
  padding: 8px;
  box-shadow: none;
  font-size: 14px;
`

export function UnsetDepositLimits(props) {
  const [isConfirming, setConfirming] = useState(false)

  return (
    <LockPage>
      {isConfirming ? (
        <SetLimitsConfirmation
          info={props.excessive ? 'excessive' : 'high'}
          onCancel={() => setConfirming(false)}
          onSubmit={props.onSubmit}
        />
      ) : (
        <React.Fragment>
          <Heading level="2" align="center">
            {props.translate('locks.unset-deposit-limits.title')}
          </Heading>
          <Space y={5}>
            <Text align="center" lineHeight="1.4em">
              <HtmlContent
                html={{
                  __html: props.translate('locks.unset-deposit-limits.content'),
                }}
              />
            </Text>
          </Space>
          {props.crossbrand && (
            <Space top={5}>
              <Text align="center" color="gray" size="12">
                {props.translate('player-limits.crossbrand-prompt')}
              </Text>
            </Space>
          )}
          {props.formValues.day > 0 && (
            <Space x={8} y={5}>
              <Text size="12" align="center" color="gray" lineHeight="1.4em">
                {props.translate(
                  'locks.unset-deposit-limits.suggested-limits-info'
                )}
              </Text>
            </Space>
          )}
          <Space top={5}>
            <Constraint width="300" centered>
              {R.map(
                (period) => (
                  <Space top="14px" key={period}>
                    <Flex
                      wide
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Space right="14px">
                        <Text bold>
                          {props.translate(`player-limits.type.${period}`)}
                        </Text>
                      </Space>
                      <Field
                        autoComplete="off"
                        currency="sek"
                        initialValue={props.displayValues[period]}
                        label={props.formValues[period]}
                        component={LimitInput}
                        name={period}
                        validator={validators.min(100)}
                        tooltip={
                          !R.isEmpty(props.getFormError(period)) &&
                          props.translate(props.getFormError(period))
                        }
                      />
                    </Flex>
                  </Space>
                ),
                R.keys(props.initialLimitAmounts)
              )}
            </Constraint>
          </Space>
          <Space top={7} bottom={5}>
            <Constraint centered width="300">
              <Flex column>
                <Button
                  stretch
                  disabled={props.isSubmitDisabled}
                  variant="primary"
                  onClick={() => setConfirming(true)}
                >
                  {props.translate('locks.unset-deposit-limits.cta')}
                </Button>
              </Flex>
            </Constraint>
          </Space>
        </React.Fragment>
      )}
    </LockPage>
  )
}

UnsetDepositLimits.propTypes = {
  crossbrand: PropTypes.bool,
  displayValues: PropTypes.object,
  initialLimitAmounts: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  formValues: PropTypes.object,
  getFormError: PropTypes.func,
  excessive: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

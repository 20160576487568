import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router' // TODO: use ../common/link
import { componentFromProp } from 'recompose'

import { FormPropTypes, connectForm } from '@rushplay/legacy-forms'
import { Space, updateQuery } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import HtmlContent from '../common/html-content'
import InputField from '../common/input-field'
import PopupContainer from '../common/popup/popup-container'
import Select from '../common/select'
import Text from '../common/text'

import * as Limit from './limit-styles.js'

function getAmount(input) {
  const result = R.match(/\d+/g, input)

  return result.length === 1 ? parseInt(result, 10) : null
}
function getPeriod(input) {
  const result = R.match(/((day|week|month|year)(s)?)/gi, input)

  return result.length === 1 ? R.toLower(result[0]) : input
}

export function PlayerLockForm(props) {
  const period = R.pathOr('', ['period', 'value'], props.fields)
  return (
    <Limit.Wrapper>
      <Limit.Panel>
        <Limit.Header>
          <Limit.Title>{props.translate(`${props.form}.headline`)}</Limit.Title>
        </Limit.Header>
        <Space x="10px">
          <Space x="26px" bottom={6}>
            <HtmlContent
              html={{
                __html: props.translate(
                  `${props.form}.${props.license}.description`
                ),
              }}
            />
          </Space>
          <props.Field
            component={Select}
            initialValue={props.initialValue}
            name="period"
            options={props.options}
          />
          <Space top={6} />
          <props.Field
            dark
            small
            component={InputField}
            label={props.translate('login-form.password')}
            name="password"
            type="password"
          />
          {props.crossbrand &&
          props.isExistingTranslation(
            `${props.license}.player-limits.crossbrand-prompt`
          ) ? (
            <Space y={6}>
              <Text align="center" size="12px">
                {props.translate(
                  `${props.license}.player-limits.crossbrand-prompt`
                )}
              </Text>
            </Space>
          ) : null}
          <Space x="26px" y={6}>
            <Link to={updateQuery(R.assoc('confirm', true))}>
              <Button stretch>
                {props.translate('player-limits.confirm')}
              </Button>
            </Link>
          </Space>
        </Space>
        <Limit.StyledLine color="#6f7d95" />
      </Limit.Panel>

      <PopupContainer
        contentLabel="player-lock-form-popup"
        id="confirm-player-limits"
        isOpen={props.popupVisible}
        onRequestClose={props.onPopupClose}
      >
        <Space all="10px" top={8}>
          <HtmlContent
            html={{
              __html: props.translate(
                `${props.form}.popup-content-${getPeriod(period)}`,
                {
                  amount: getAmount(period),
                }
              ),
            }}
          />
        </Space>
        {props.crossbrand &&
        props.isExistingTranslation(
          `${props.license}.player-limits.crossbrand-prompt`
        ) ? (
          <Space y={6}>
            <Text align="center" size="12px">
              {props.translate(
                `${props.license}.player-limits.crossbrand-prompt`
              )}
            </Text>
          </Space>
        ) : null}
        <Link to={updateQuery(R.assoc('confirm', false))}>
          <Button
            onClick={() => props.onSubmit(period, props.fields.password.value)}
          >
            {props.translate('player-limits.confirm')}
          </Button>
        </Link>
      </PopupContainer>
    </Limit.Wrapper>
  )
}

PlayerLockForm.defaultProps = {
  Field: componentFromProp('component'),
  translate: R.identity,
}

PlayerLockForm.propTypes = {
  crossbrand: PropTypes.bool,
  Field: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    password: FormPropTypes.field,
    period: FormPropTypes.field,
  }).isRequired,
  form: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  isExistingTranslation: PropTypes.func.isRequired,
  license: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  popupVisible: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
}

export default connectForm(withTranslate(PlayerLockForm))

import PropTypes from 'prop-types'
import React from 'react'
import fscreen from 'fscreen'

export class Fullscreen extends React.Component {
  constructor(props) {
    super(props)

    this.detectFullscreen = this.detectFullscreen.bind(this)
    this.enterFullscreen = this.enterFullscreen.bind(this)
    this.leaveFullscreen = this.leaveFullscreen.bind(this)
  }

  componentDidMount() {
    fscreen.addEventListener('fullscreenchange', this.detectFullscreen)
  }

  componentWillUnmount() {
    fscreen.removeEventListener('fullscreenchange', this.detectFullscreen)
  }

  componentDidUpdate() {
    this.handleProps(this.props)
  }

  handleProps(props) {
    const enabled = fscreen.fullscreenElement
    if (enabled && !props.enabled) {
      this.leaveFullscreen()
    } else if (!enabled && props.enabled) {
      this.enterFullscreen()
    }
  }

  detectFullscreen() {
    if (this.props.onChange) {
      this.props.onChange(Boolean(fscreen.fullscreenElement))
    }
  }

  enterFullscreen() {
    if (fscreen.fullscreenEnabled) {
      fscreen.requestFullscreen(this.node)
    }
  }

  leaveFullscreen() {
    if (fscreen.fullscreenEnabled) {
      fscreen.exitFullscreen()
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={node => (this.node = node)}
        style={
          this.props.enabled ? { height: '100%', width: '100%' } : undefined
        }
      >
        {this.props.children}
      </div>
    )
  }
}

Fullscreen.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

Fullscreen.defaultProps = {
  enabled: false,
}

import { EFFECT_FETCH_API } from './middleware'
import * as R from 'ramda'

/**
 * Debounces fetch action by 500ms if a search query has the name "search".
 * - To be used with debounceMiddleware from @rushplay/common
 * - Should be placed before api.middleware in applyMiddleware from Redux
 * @param {Object} action Redux action
 * @returns {?Object}
 * @example
 * import * as api from '@rushplay/api-client'
 * import { applyMiddleware } from 'redux'
 *
 * applyMiddleware(
 *   debounceMiddleware([
 *     api.shouldFetchDebounce
 *   ]),
 *   api.middleware({...})
 * )
 */
export function shouldFetchDebounce(action) {
  if (
    R.pathEq(['type'], 'EFFECT_COMPOSE', action) &&
    R.pathEq(['payload', 'type'], EFFECT_FETCH_API, action) &&
    getGameQueryByName('search', action)
  ) {
    return { key: R.path(['payload', 'payload', 'url'], action), time: 500 }
  }
}

/**
 * Checks if action payload body has the given game query name
 * @private
 * @param {Object} action
 * @param {String} name
 * @returns {Boolean}
 */
function getGameQueryByName(name, action) {
  const params = JSON.parse(
    R.pathOr('{}', ['payload', 'params', 'body'], action.payload)
  )
  return Boolean(R.path(['q', name], params))
}

import { connect } from 'react-redux'
import { push as redirectTo } from 'connected-react-router'

import { updateBalance } from '@rushplay/session'

function mapDispatchToProps(dispatch, props) {
  return {
    onParseAndUpdateBalance: balance => {
      const balanceCents = parseInt(balance.match(/\d+/g).join(''), 10)
      dispatch(updateBalance({ balanceCents }))
    },
    onGameLaunch: (options, authenticated, gameLaunchSuccess) => {
      const isMobile = typeof window.orientation !== 'undefined'
      const success = authenticated && !isMobile ? gameLaunchSuccess : null

      window.netent.launch(options, success, error => props.onError(error))
    },
    onGameStop: netEntExtendRef =>
      netEntExtendRef && netEntExtendRef.call('stopAutoplay', []),
    redirectToLobby: () => {
      dispatch(redirectTo(props.liveCasinoLobby))
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)

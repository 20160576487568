import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as limits from '@rushplay/compliance/limits'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import Heading from '../../../common/heading'
import PlayerLimit from '../../player-limit'
import PlayerLimitForm from '../../player-limit-form'
import toRemainingTime from '../../../to-remaining-time'
import { HighLimitPopup } from '../../high-limit-popup'
import { PageSpinner } from '../../../common/spinner'

const LimitWrapper = styled.div`
  width: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
`

function shouldTriggerPopup(excessive, softCap, valueCents, currentLimit) {
  const isIncreased = R.gt(valueCents, currentLimit)
  const exceedingSoftCap = R.gte(
    valueCents,
    R.defaultTo(Number.POSITIVE_INFINITY, softCap)
  )
  return excessive || isIncreased || exceedingSoftCap || valueCents === 0
}

export function Limit(props) {
  const i18n = useI18n()
  const [popupIsOpen, setPopupIsOpen] = React.useState(false)
  const [limitValue, setLimitValue] = React.useState(null)

  return (
    <limits.Limit
      mountPoint="limits"
      period={props.period}
      target={props.target}
    >
      {(limit) => (
        <React.Fragment>
          <HighLimitPopup
            info={props.excessive ? 'excessive' : 'high'}
            isOpen={popupIsOpen}
            limitValue={limitValue}
            onSubmit={() => {
              limit.onSet(props.valueCents)
              setPopupIsOpen(false)
            }}
            onCancel={() => setPopupIsOpen(false)}
          />

          {!limit.isLoaded ? (
            <PageSpinner />
          ) : (
            <LimitWrapper>
              <Heading align="center" level="4">
                {i18n.translate(`player-limits.type.${limit.period}`)}
              </Heading>
              {limit.valueCents === null || limit.isEditing ? (
                <PlayerLimitForm
                  key={`${limit.target}-${limit.period}`}
                  cooldown={props.cooldown}
                  crossbrand={limit.crossbrand}
                  currency={props.currency}
                  initialLimitAmount={
                    R.pathOr(
                      0,
                      ['options', 'defaultValues', limit.period],
                      props.depositLimitLock
                    ) / 100
                  }
                  maxAmount={props.max}
                  minAmount={props.min}
                  name={`${limit.target}-${limit.period}`}
                  label="limits.amountselector.typ-in-amount"
                  showInfoText={limit.isEditing}
                  softCap={limit.softCap}
                  onSubmit={() => {
                    if (
                      shouldTriggerPopup(
                        props.excessive,
                        props.softCap,
                        props.valueCents,
                        limit.valueCents
                      )
                    ) {
                      setLimitValue(props.valueCents)
                      setPopupIsOpen(true)
                    } else {
                      limit.onSet(props.valueCents)
                    }
                  }}
                />
              ) : (
                <PlayerLimit
                  amount={limit.valueCents}
                  isFetchingActiveFrom={
                    limit.activeFrom === Number.POSITIVE_INFINITY
                  }
                  activeFrom={i18n.translate(
                    'player-limits.active-from',
                    toRemainingTime(0, limit.activeFrom, props.currentTime)
                  )}
                  nextAmount={limit.nextValueCents}
                  isIncreased={limit.isIncreased}
                  isRemoved={limit.isRemoved}
                  onEdit={limit.onEditToggle}
                  onCancel={limit.onRevert}
                  onRemove={props.limitIsRemovable ? limit.onUnset : undefined}
                />
              )}
            </LimitWrapper>
          )}
        </React.Fragment>
      )}
    </limits.Limit>
  )
}

Limit.propTypes = {
  cooldown: PropTypes.number,
  currency: PropTypes.string.isRequired,
  currentTime: PropTypes.number,
  depositLimitLock: PropTypes.object,
  excessive: PropTypes.bool,
  limitIsRemovable: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  period: PropTypes.string.isRequired,
  softCap: PropTypes.number,
  target: PropTypes.string.isRequired,
  valueCents: PropTypes.number,
}

import * as R from 'ramda'

/**
 * Get amount of units
 * @param {number} ratio
 * @param {number} amount
 * @returns {number}
 */
function fromCents(ratio = 1, amount) {
  return amount / ratio
}

/**
 * Format number as amount with currency
 * @param {Object} options options neede for proper amount formatting
 * @param {string} options.currency Currency code according to ISO 4217
 * @param {string} options.locale BCP 47 language tag (usually shortest ISO 639 code sometimes followed by extended language subtags (such as region))
 * @param {"auto"|"show"|"hide"} [options.decimals="auto"] Decimal display type; "auto" hides decimals for round values
 * @param {number} amount Amount in cents
 * @returns {(string|any[])}
 *   String with formatted amount or tuple (array) containing amount and currency symbol if it’s non-string.
 * @example
 * function Balance(props) {
 *   return <div>{toAmount({ currency: props.currency, locale: props.locale, decimals: 'hide' }, props.amount)}</div>
 * }
 */

function minimumFractionDigits(decimals, amount) {
  switch (decimals) {
    case 'show': {
      return 2
    }

    case 'hide': {
      return 0
    }

    default: {
      // Remove decimals if it’s a integer-ish number (i.e. 7.00)
      return Math.round(amount) === amount ? 0 : 2
    }
  }
}

export function toAmount(options, amount) {
  const currencyCode = R.path(['currency', 'code'], options)
  const decimals = R.pathOr('auto', ['decimals'], options)
  const isCustomCurrency = R.path(['currency', 'customCurrency'], options)
  // TODO: Refactor to remove isNotMoney. This function is made for formatting
  // amounts. If there’s a need to format regular numbers, separate helper
  // should be created.
  const isNotMoney = !R.path(['currency'], options)
  const ratio = R.path(['currency', 'ratio'], options)
  const symbol = R.path(['currency', 'symbol'], options)

  return R.pipe(
    // divide if necessary
    amount => fromCents(ratio, amount),

    // remove decimals if requested
    amount => (decimals === 'hide' ? Math.floor(amount) : amount),

    amount => {
      if (isCustomCurrency || isNotMoney) {
        return new Intl.NumberFormat(options.locale, {
          minimumFractionDigits: minimumFractionDigits(decimals, amount),
          maximumFractionDigits: 2,
          style: 'decimal',
        }).format(amount)
      }

      return new Intl.NumberFormat(options.locale, {
        currency: currencyCode,
        minimumFractionDigits: minimumFractionDigits(decimals, amount),
        maximumFractionDigits: 2,
        style: 'currency',
      }).format(amount)
    },

    amount => {
      // Workaround for custom currencies with symbol (like mBTC)
      if (isCustomCurrency && symbol) {
        return `${amount} ${symbol}`
      }

      return amount
    }
  )(amount)
}

import { fetchDate } from '@rushplay/api-client'

import { setServerTimeOffset } from './store/app'

/**
 * @constant
 * Used to compensate overhead which appears when request/response time
 * measurement is done in JavaScript.
 * Empirically computed using Chrome DevTools.
 */
const PING_FACTOR = 0.75

function fetchAndSetServerTimeOffset(dispatch) {
  const reqStart = Date.now()
  dispatch(
    fetchDate({
      success: res => {
        const reqEnd = Date.now()
        const roundTrip = (reqEnd - reqStart) * PING_FACTOR / 2
        const serverTimestamp = Date.parse(res.value.date)
        return setServerTimeOffset(
          Math.floor(reqEnd - roundTrip - serverTimestamp)
        )
      },
      version: 1,
    })
  )
}

/**
 * Create middleware used to get server time offset. Fetches server time
 * and dispatches action with offset calculated with request time taken
 * into account
 * @returns redux middleware
 */
export function createServerTimeOffsetMiddleware() {
  return function serverTimeOffsetMiddleware(store) {
    // Perform it on the next tick
    setTimeout(() => fetchAndSetServerTimeOffset(store.dispatch), 0)

    // Pass all actions to next middleware
    return next => action => next(action)
  }
}

export default createServerTimeOffsetMiddleware()

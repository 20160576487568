import styled from '@emotion/styled'

export const Message = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  text-align: center;
  line-height: 1.8em;
`

export default Message

import * as R from 'ramda'
import { connect } from 'react-redux'

import { deleteNotification as deleteNotificationApi } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import triggers from '../../store/triggers'

import { InventoryLockPopup } from './inventory-lock-popup'

function mapStateToProps(state) {
  return {
    popupNotification: triggers.selectors.getByEvent(
      'inventory_unlocked',
      state.triggers
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDismiss: id =>
      dispatch([
        triggers.actions.remove(id),
        deleteNotificationApi(id, { version: 1 }),
      ]),
  }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return R.mergeRight(ownProps, {
    onDismiss: () => {
      dispatchProps.onDismiss(stateProps.popupNotification.id)
    },
  })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  withTranslate(InventoryLockPopup)
)

import * as R from 'ramda'

import * as actions from './actions'
import * as selectors from './selectors'
import * as types from './types'

function limitReducer(state = {}, action) {
  switch (action.type) {
    case types.APPLY: {
      return R.pipe(
        R.assoc('valueCents', state.nextValueCents),
        R.omit(['activeFrom', 'nextValueCents'])
      )(state)
    }

    case types.CANCEL: {
      return R.omit(['activeFrom', 'nextValueCents'], state)
    }

    case types.EDIT: {
      return R.assoc('nextValueCents', -1, state)
    }

    case types.SET: {
      const limit = R.omit(['activeFrom', 'nextValueCents'], state)

      if (action.payload.amount === Infinity) {
        return R.assoc('activeFrom', action.payload.activeFrom, limit)
      }

      if (action.payload.amount > state.valueCents) {
        return R.mergeRight(limit, {
          activeFrom: action.payload.activeFrom,
          nextValueCents: action.payload.amount,
        })
      }

      if (action.payload.amount > state.valueCents) {
        return R.mergeRight(limit, {
          activeFrom: action.payload.activeFrom,
          nextValueCents: action.payload.amount,
        })
      }

      return R.assoc('valueCents', action.payload.amount, limit)
    }

    /* istanbul ignore next: default statement is here just in case */
    default: {
      return state
    }
  }
}

function reducer(state, action) {
  switch (action.type) {
    case types.INIT: {
      return action.payload
    }

    case types.CLEAR: {
      return R.dissocPath([action.payload.target, action.payload.period], state)
    }
    case types.APPLY:
    case types.CANCEL:
    case types.EDIT:
    case types.REMOVE:
    case types.SET: {
      const limitLens = R.lensPath([
        action.payload.target,
        action.payload.period,
      ])

      return R.over(limitLens, limit => limitReducer(limit, action), state)
    }

    default: {
      return state
    }
  }
}

export default { actions, reducer, selectors, types }

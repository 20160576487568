import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Constraint, Flex, Space } from '@rushplay/common'

import Button from '../../../common/button'
import Card from '../../../common/card'
import Heading from '../../../common/heading'
import HtmlContent from '../../../common/html-content'
import Text from '../../../common/text'

const TextLink = styled(Text)`cursor: pointer;`

export class UpdatedLimitsConfirmation extends React.Component {
  componentDidMount() {
    this.props.onFetch()
  }

  render() {
    return (
      <Constraint centered width="600">
        <Space top="36px">
          <Card>
            <Space all={6}>
              <Text align="center">
                <Heading level="2">
                  {this.props.translate(
                    `locks.updated-limits-confirmation.title`
                  )}
                </Heading>
                <HtmlContent
                  html={{
                    __html: this.props.translate(
                      `locks.updated-limits-confirmation.${this.props
                        .target}.${this.props.period}.description`,
                      {
                        previousAmount: this.props.previousAmountCents,
                        newAmount: this.props.newAmountCents,
                      }
                    ),
                  }}
                />
                <Space top={6}>
                  <Flex column alignItems="center">
                    <Button variant="primary" onClick={this.props.onApprove}>
                      {this.props.translate(
                        'locks.updated-limits-confirmation.approve'
                      )}
                    </Button>
                    <Space top={5}>
                      <TextLink
                        underlined
                        align="center"
                        onClick={this.props.onDecline}
                      >
                        {this.props.translate(
                          'locks.updated-limits-confirmation.deny'
                        )}
                      </TextLink>
                    </Space>
                  </Flex>
                </Space>
              </Text>
            </Space>
          </Card>
        </Space>
      </Constraint>
    )
  }
}

UpdatedLimitsConfirmation.propTypes = {
  newAmountCents: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
  previousAmountCents: PropTypes.number.isRequired,
  target: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
}

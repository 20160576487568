import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { selectors } from '@rushplay/legacy-forms'

import * as App from '../../../store/app'
import withoutLanguage from '../../../util/path-without-language'

import { Authentication as Component } from './authentication'

function mapStateToProps(state, props) {
  const mobileMenuVisible = App.getMobileMenuVisibility(state)
  const isLoginPath = withoutLanguage(props.location.pathname) === '/login/'
  const usernameFieldProps = { form: 'login', field: 'username' }

  return {
    showLoginButton: !mobileMenuVisible,
    showLoginForm: isLoginPath,
    showRegister:
      selectors.value(state.forms, usernameFieldProps) === '' ||
      !selectors.isPristine(state.forms, usernameFieldProps),
  }
}

const connector = R.compose(withRouter, connect(mapStateToProps))

export const Authentication = connector(Component)

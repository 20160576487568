import * as R from 'ramda'
import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withTranslate } from '@rushplay/i18n'

import { Limit as Component } from './limit'

function mapStateToProps(state, props) {
  const limit = jurisdiction.getSessionLimit(state.jurisdiction, {
    id: props.id,
  })
  return {
    cooldown: R.pathOr(0, ['cooldown'], limit),
    required: R.path(['required'], limit),
    period: R.path(['period'], limit),
    max: R.pathOr(Number.MAX_SAFE_INTEGER, ['max'], limit),
    min: R.pathOr(0, ['min'], limit),
  }
}

const connector = R.compose(connect(mapStateToProps), withTranslate)

export const Limit = connector(Component)

import * as types from './types'

export function update(treasures) {
  return {
    type: types.UPDATE,
    payload: treasures,
  }
}

export function clear() {
  return { type: types.CLEAR }
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { rem } from 'polished'
import { withRouter } from 'react-router'

import styled from '@emotion/styled'
import { Field, FormPropTypes, connectForm } from '@rushplay/legacy-forms'
import { Flex, Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import FingerprintContainer from '../native-app/fingerprint-container'
import InputField from '../../common/input-field'
import Text from '../../common/text'
import UsernameField from '../login-form/username-field'
import { Background } from '../../sign-up/steps/styles'
import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  headingFont,
} from '../../constants/typography'

const Section = styled.div`
  margin: 0 10px;
  width: 100%;
  padding: 24px 12px;
  background: white;
  border-radius: 20px;
  @media screen and (min-width: 480px) {
    width: 400px;
    margin: 20px auto;
    padding: 48px 24px;
  }
`

const Controls = styled(Flex)`
  padding-top: 0;
  width: 100%;

  @media screen and (min-width: 1280px) {
    padding-top: 14px;
    display: inline-flex;
    align-items: center;
  }
`

const Input = styled.div`
  width: 100%;
  @media screen and (max-width: 1279px) {
    padding-bottom: 12px;
  }

  @media screen and (min-width: 1280px) {
    padding-left: 5px;
    position: relative;
    padding-bottom: 20px;
  }
`

const ForgotPassLink = styled.span`
  display: block;
  text-align: right;
  color: #366ac7;
  @media screen and (min-width: 1280px) {
    font-size: 0.75em;
  }
`
const HRule = styled.hr`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 0px;
  border-bottom: 1px solid #c3c3c3;
  @media screen and (min-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

const StyledField = styled(Field)`border: 2px solid #c3c3c3;`

const Title = styled.p`
  font-family: ${headingFont};
  font-size: ${rem('20px', baseFontSizeSmall)};
  line-height: 30px;
  margin-top: 8px;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    font-size: ${rem('30px', baseFontSizeLarge)};
    margin-top: 0px;
    margin-bottom: 26px;
  }
`

const StyledLink = styled(Link)`width: 100%;`

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    this.handleLogin(
      this.props.fields.username.value,
      this.props.fields.password.value,
      this.props.location.query.redirectTo
    )
  }

  handleLogin(username, password, redirectTo) {
    this.props.login(username, password, this.props.clientType, redirectTo)
  }

  render() {
    return (
      <Background
        image={
          this.props.isCasitabi ? (
            this.props.translate('signup.background')
          ) : (
            '/src/images/signup/signup-type-selection-bg.jpg'
          )
        }
        overlayImage={
          this.props.isCasitabi &&
          this.props.translate('signup.background.overlay')
        }
      >
        <Section>
          <form onSubmit={this.handleSubmit}>
            <Flex flexDirection="column" alignItems=" center">
              {this.props.returningUsername && <Space right="17px" />}
              <Text color="black" align="center">
                <Title>{this.props.translate('login-form.login')}</Title>
              </Text>
              <Controls flexDirection="column" alignItems="center">
                <Input>
                  <Field
                    returningUserAvatar={this.props.returningUserAvatar}
                    dark
                    small
                    component={UsernameField}
                    initialValue={this.props.returningUsername}
                    label={this.props.translate('login-form.username')}
                    name="username"
                    type="text"
                    onClear={this.props.onUsernameClear}
                    isLoginPage
                  />
                </Input>
                {this.props.returningUsername && <Space right="10px" />}
                <Input>
                  <StyledField
                    dark
                    small
                    autoFocus={
                      this.props.returningUsername &&
                      typeof window.orientation === 'undefined'
                    }
                    component={InputField}
                    label={this.props.translate('login-form.password')}
                    name="password"
                    type="password"
                  />
                  <Link to="/reset-password">
                    <Space top={4} bottom="6px">
                      <ForgotPassLink>
                        {this.props.translate('login-form.forgot-password')}
                      </ForgotPassLink>
                    </Space>
                  </Link>
                </Input>
              </Controls>
              <Button
                stretch
                type="submit"
                loading={this.props.isLoginInProgress}
                minimalPadding
              >
                {this.props.translate('login-form.login')}
              </Button>
              <FingerprintContainer />
              <HRule />
              <Space bottom={6}>
                <Text size="16" color="black" align="center">
                  {this.props.translate('login-page.registration-prompt')}
                </Text>
              </Space>
              <StyledLink to="/register">
                <Button stretch variant="primary">
                  {this.props.translate('header.register')}
                </Button>
              </StyledLink>
            </Flex>
          </form>
        </Section>
      </Background>
    )
  }
}

LoginPage.propTypes = {
  fields: PropTypes.shape({
    username: FormPropTypes.field,
    password: FormPropTypes.field,
  }).isRequired,
  isLoginInProgress: PropTypes.bool,
  location: PropTypes.object.isRequired,
  returningUsername: PropTypes.string,
  returningUserAvatar: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onUsernameClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clientType: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  isCasitabi: PropTypes.bool,
}

LoginPage.contextTypes = { locale: PropTypes.string }

export default R.compose(
  withRouter,
  component => connectForm(component, { name: 'login-page' }),
  withTranslate
)(LoginPage)

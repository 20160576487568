import styled from '@emotion/styled'

export default styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;

  @media screen and (max-width: 720px) {
    max-width: 600px;
  }
  @media screen and (max-width: 620px) {
    max-width: 480px;
  }
  @media screen and (max-width: 500px) {
    max-width: 360px;
  }
`

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as App from '../store/app'
import * as Promotions from '../store/promotions'
import HtmlContent from '../common/html-content'
import Icon from '../common/icon/icon'
import NotFound from '../components/not-found'
import {
  Content,
  Header,
  Headline,
  NavigateBack,
  Wrapper,
} from '../components/static/static'
import { PageSpinner } from '../common/spinner'

function PromotionsDetailContainer(props) {
  React.useEffect(
    () => {
      !props.promotion && props.onFetch()
    },
    [props.promotion]
  )

  const i18n = I18n.useI18n()

  const promotionTitleKey = `promotions.${props.params.terms}.title`

  if (!props.ready) {
    return <PageSpinner />
  }

  if (!i18n.isExistingTranslation(promotionTitleKey)) {
    return <NotFound />
  }

  return (
    <Wrapper>
      <Header>
        <Headline>
          <HtmlContent
            html={{
              __html: i18n.translate(`promotions.${props.params.terms}.title`),
            }}
          />
        </Headline>

        <NavigateBack
          onClick={() => props.redirectTo('/promotions')}
          visibleOnDesktop
        >
          <Icon name="chevron-left" size="big" />
        </NavigateBack>
      </Header>

      <Content>
        {R.path(['activeFrom'], props.promotion) && (
          <HtmlContent
            html={{
              __html: i18n.translate(`promotions.promotion.start-date`, {
                startDateAndTime: Common.toLocaleDate(
                  R.path(['activeFrom'], props.promotion),
                  props.language
                ).dateTime,
              }),
            }}
          />
        )}
        {R.path(['activeTo'], props.promotion) && (
          <HtmlContent
            html={{
              __html: i18n.translate(`promotions.promotion.end-date`, {
                endDateAndTime: Common.toLocaleDate(
                  R.path(['activeTo'], props.promotion),
                  props.language
                ).dateTime,
              }),
            }}
          />
        )}
        <HtmlContent
          html={{
            __html: i18n.translate(
              `promotions.${props.params.terms}.terms-conditions`
            ),
          }}
        />
      </Content>
    </Wrapper>
  )
}

export default connect(
  (state, props) => ({
    language: App.getLanguage(state.app),
    promotion: Promotions.getPromotion(props.params.terms, state.promotions),
    ready: state.promotions.ready,
  }),
  dispatch => ({
    redirectTo: path => dispatch(redirectTo(path)),
    onFetch: () =>
      dispatch(
        Api.fetchPromotions(null, {
          success: res => [
            Promotions.setPromotionsReady(true),
            Promotions.updatePromotions(res.value),
          ],
          failure: () => [
            Promotions.setPromotionsReady(true),
            Notifications.add({ message: 'error.generic', level: 'error' }),
          ],
          version: 2,
        })
      ),
  })
)(PromotionsDetailContainer)

PromotionsDetailContainer.propTypes = {
  language: PropTypes.string,
  params: PropTypes.object.isRequired,
  ready: PropTypes.bool,
  promotion: PropTypes.object,
  onFetch: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
}

import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

import * as actions from './actions'
import * as selectors from './selectors'
import * as types from './types'

const initialState = {
  __items: {},
  currentWorldKey: '',
}

function worldReducer(state, action) {
  switch (action.type) {
    case websockets.REGION_SELECTED:
    case types.SELECT_REGION: {
      return R.assoc('currentRegionKey', action.payload.regionKey, state)
    }
    case types.STORE_REGIONS: {
      return R.assoc('regions', action.payload.regions, state)
    }
    case types.STORE_TREASURES: {
      return R.assoc('treasures', action.payload.treasures, state)
    }

    case websockets.REGION_PROGRESS_CHANGED:
    case types.UPDATE_REGION_PROGRESS: {
      return R.assoc('regionProgress', action.payload.progress, state)
    }
    case types.UPDATE_LAST_SEEN_PROGRESS: {
      return R.assoc('lastSeenProgress', action.payload.progress, state)
    }
    case types.UPDATE_MAP_POSITION: {
      return R.assoc('mapPosition', action.payload.position, state)
    }
    case types.REMOVE_TREASURE: {
      const treasuresLens = R.lensProp('treasures')

      return R.over(
        treasuresLens,
        R.reject(R.equals(action.payload.treasureId)),
        state
      )
    }

    /* istanbul ignore next: default statement is here just in case */
    default:
      return state
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case websockets.SESSION_EXPIRED:
    case types.CLEAR: {
      return initialState
    }

    case websockets.WORLD_SELECTED:
    case types.SELECT_WORLD: {
      return R.assoc('currentWorldKey', action.payload, state)
    }

    case websockets.REGION_PROGRESS_CHANGED:
    case types.SELECT_REGION:
    case types.STORE_REGIONS:
    case types.STORE_TREASURES:
    case types.UPDATE_REGION_PROGRESS:
    case types.UPDATE_LAST_SEEN_PROGRESS:
    case types.UPDATE_MAP_POSITION:
    case types.REMOVE_TREASURE: {
      return R.over(
        R.lensPath(['__items', action.payload.worldKey]),
        substate => worldReducer(substate, action),
        state
      )
    }
    case types.UPDATE_STATS:
    case types.UPDATE_IMAGES:
    case types.UPDATE_DETAILS: {
      const worldLens = R.lensProp('__items')

      return R.over(worldLens, R.mergeDeepRight(R.__, action.payload), state)
    }
    case types.STORE_WORLDS: {
      return R.assoc('sortedWorlds', action.payload, state)
    }
    default:
      return state
  }
}

export default { actions, reducer, selectors }

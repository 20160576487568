import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import { getLicenses } from '../../../store/app'

import { LegalInfo as Component } from './legal-info'

function mapStateToProps(state) {
  return {
    licenses: getLicenses(state, {
      jurisdiction: jurisdiction.getLicense(state.jurisdiction),
    }),
    licenseId: jurisdiction.getLicense(state.jurisdiction),
  }
}

export const LegalInfo = connect(mapStateToProps)(Component)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { toAmount } from './to-amount'

/**
 * @name createAmountComponent
 * @desc Create `<Currency/>` component.
 * @param {Object} options
 * @param {CurrencyDefinition[]} options.currencies
 * @returns {ReactStatelessComponent} Currency React component
 * @example
 * import { createAmountComponent, EUR, USD } from '@rushplay/common'
 *
 * export const BALLS = {
 *   code: 'BALLS',
 *   ratio: 100,
 *   customCurrency: true,
 * }
 *
 * export default createAmountComponent({
 *   currencies: [EUR, USD, BALLS],
 * })
 */
export function createAmountComponent(options) {
  if (!options.currencies) {
    throw new Error('options.currencies is required')
  }

  const currencies = R.indexBy(R.prop('code'), options.currencies)

  function Amount(props) {
    if (process.env.NODE_ENV !== 'production' && props.fixed) {
      // eslint-disable-next-line no-console
      console.warn(
        'DEPRECATED',
        '`fixed` prop is deprecated. Use `decimals` instead.'
      )
    }
    const decimals = props.decimals || (props.fixed ? 'show' : 'auto')
    return React.createElement(
      props.component,
      {},
      toAmount(
        {
          currency: currencies[props.currency] || {},
          locale: props.locale,
          decimals,
        },
        props.children
      )
    )
  }

  Amount.defaultProps = {
    children: 0,
    component: 'span',
  }

  Amount.propTypes = {
    children: PropTypes.number,
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    currency: PropTypes.oneOf(options.currencies.map(({ code }) => code)),
    decimals: PropTypes.string,
    fixed: PropTypes.bool,
    locale: PropTypes.string.isRequired,
  }

  return Amount
}

/**
 * Shuffle array using Durstenfeld shuffle algorithm by a time complexity of O(n)
 * (Durstenfeld describes Fisher–Yates shuffle designed for computer use)
 * NB! Mutates given array
 * @param {*[]} array
 * @returns {*[]}
 */

export default function shuffleArray(array) {
  // While there remain elements to shuffle…
  for (let i = array.length - 1; i > 0; i--) {
    // Pick a remaining element…
    const j = Math.floor(Math.random() * (i + 1))

    // And swap it with the current element.
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import { SearchGamesSection } from './search-games-section'

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
  max-width: 1243px;
`

function SearchGamesResult(props) {
  return (
    <Wrapper>
      <SearchGamesSection
        params={{ text: props.searchQuery, limit: 24 }}
        loadMore
        tagPrefix={props.tagPrefix}
      />
    </Wrapper>
  )
}

SearchGamesResult.propTypes = {
  translate: PropTypes.func,
  searchQuery: PropTypes.string,
  tagPrefix: PropTypes.func,
}

export default withTranslate(SearchGamesResult)

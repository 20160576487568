import * as R from 'ramda'
import { CALL_HISTORY_METHOD } from 'react-router-redux'

import { getLanguage } from './store/app'

//TODO: move to @rushplay/common and add tests
function removeLanguage(pathname) {
  const MIN_PATHNAME_LENGTH = 3
  const isTooShort = pathname && pathname.length < MIN_PATHNAME_LENGTH
  const startsWithLanguage = pathname.search(/^\/\w\w\b/) > -1

  if (isTooShort || !startsWithLanguage) {
    return pathname
  }

  return pathname.slice(MIN_PATHNAME_LENGTH)
}

function prependLanguageMiddleware({ getState }) {
  const prependLanguage = pathname =>
    `/${getLanguage(getState().app) || ''}${pathname}/`
  const argsLens = R.lensPath(['payload', 'args', 0])
  const pathnameLens = R.lensProp('pathname')
  const removeAndPrepend = R.pipe(
    removeLanguage,
    prependLanguage,
    R.replace('//', '/')
  )

  return next =>
    R.pipe(
      R.when(
        R.propEq('type', CALL_HISTORY_METHOD),
        R.over(
          argsLens,
          R.cond([
            [R.is(String), removeAndPrepend],
            [
              R.both(R.is(Object), R.has('pathname')),
              R.over(pathnameLens, removeAndPrepend),
            ],
            [R.T, R.identity],
          ])
        )
      ),
      next
    )
}

export default prependLanguageMiddleware

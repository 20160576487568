import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import { selectors } from '@rushplay/legacy-forms'
import { updatePassword } from '@rushplay/api-client'

import CreateNewPassword from './create-new-password'

class CreateNewPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(token, password, passwordConfirmation) {
    this.props.onUpdatePassword(token, password, passwordConfirmation, {
      success: () => push('/create-new-password-confirmation'),
      failure: res => Notifications.add({ message: res.value, level: 'error' }),
      version: 1,
    })
  }

  render() {
    return (
      <CreateNewPassword
        isFormValid={this.props.isFormValid}
        name="createNewPassword"
        onSubmit={this.handleSubmit}
        token={this.props.token}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFormValid: selectors.isAllFieldsHaveStatus(state.forms, {
      form: 'createNewPassword',
      status: 'valid',
    }),
    token: ownProps.routeParams.token,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUpdatePassword: updatePassword }, dispatch)
}

CreateNewPasswordContainer.propTypes = {
  isFormValid: PropTypes.bool.isRequired,
  token: PropTypes.string,
  onUpdatePassword: PropTypes.func,
}

CreateNewPasswordContainer.contextTypes = { locale: PropTypes.string }

export default connect(mapStateToProps, mapDispatchToProps)(
  CreateNewPasswordContainer
)

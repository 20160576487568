import styled from '@emotion/styled'
import { Link } from '@rushplay/common'

import { baseFontSizeSmall } from '../constants/typography'

export const StyledLink = styled(Link)`
  opacity: 0.5;
  text-decoration: underline;
  font-size: ${baseFontSizeSmall};
  line-height: 16px;
  margin-left: 5px;
  margin-right: 5px;
`

import * as R from 'ramda'

// https://regexr.com/3gpo0
const getSecondLevelDomain = R.pipe(R.match(/[a-z-]+\.\w+$/i), R.head)

// Hack to get url since there is no server..
// Same solution as @link https://github.com/RushPlay/api-client/blob/master/src/get-api-url.js
export function generateGraphqlProxyUrl(hostname) {
  if (typeof process.env.GRAPHQL_URL === 'string') {
    return process.env.GRAPHQL_URL
  }

  const secondLevelDomain = getSecondLevelDomain(hostname)

  if (secondLevelDomain) {
    return `https://gql.${secondLevelDomain}`
  } else {
    /* istanbul ignore next: dev-only code */
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(
        'GRAPHQL_URL cannot be detected locally.',
        'Set GRAPHQL_URL environment variable.',
        'You can also use .env to persist configuration.',
        'More information can be found in project’s README.md'
      )
    }
    return null
  }
}

import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Row = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`
export const InlineRow = styled(Row)`
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
`
export const InlineRowItem = styled.div`
  margin-right: 7px;
  margin-left: 7px;
  flex: 1;
`
export const Description = styled.p`
  line-height: 1.6;
  margin-bottom: 30px;
`
export const Panel = styled.div`
  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  width: 100%;
  max-width: 417px;
  @media screen and (min-width: 768px) {
    margin: 40px;
    width: 417px;
    ${props => !props.showTitleBar && css`padding: 30px;`};
  }

  ${props =>
    props.showTitleBar &&
    css`
      padding-top: 70px;
      margin: 40px 10px;
    `} ${props =>
      props.center &&
      `
    align-self:center;
  `};
`
export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: center 100% / 1500px no-repeat url(${props => props.overlayImage}),
    center bottom / cover url(${props => props.image});
  min-height: 95vh;
`
export const TitleBar = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: -14px;
  left: calc(50% - 325px / 2);
  width: 325px;
  height: 68px;
  padding-top: 9px;
  font-size: 25px;
  letter-spacing: -1.1px;
  text-align: center;
  color: #fac84f;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
  background-image: url(${props => props.image});
  background-position: center;
  background-size: 325px 68px;
`

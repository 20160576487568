import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as locks from '@rushplay/compliance/locks'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import Heading from '../../common/heading'
import HtmlContent from '../../common/html-content'

import LockPage from './lock-page'

const StickyWrapper = styled.div`
  position: sticky;
  top: 60px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0)
  );
`

function TermsAndConditionsConsent(props) {
  return (
    <LockPage>
      <Heading level="2" align="center">
        {props.translate('locks.terms-and-conditions-consent.title')}
      </Heading>
      <StickyWrapper>
        <Space y={7}>
          <Flex justifyContent="center">
            <Button variant="primary" onClick={props.onAccept}>
              {props.translate('locks.terms-and-conditions-consent.accept')}
            </Button>
          </Flex>
        </Space>
      </StickyWrapper>
      <HtmlContent
        html={{
          __html: props.isExistingTranslation(
            `locks.terms-and-conditions-consent.${props.license}.content`
          )
            ? props.translate(
                `locks.terms-and-conditions-consent.${props.license}.content`
              )
            : props.translate('locks.terms-and-conditions-consent.content'),
        }}
      />
    </LockPage>
  )
}

TermsAndConditionsConsent.propTypes = {
  isExistingTranslation: PropTypes.func.isRequired,
  license: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    license: jurisdiction.getLicense(state.jurisdiction),
  }
}

const connector = R.compose(
  withTranslate,
  connect(mapStateToProps, {
    onAccept: () =>
      api.acceptTermsAndConditions({
        success: () => locks.remove('terms-and-conditions-consent'),
        version: 1,
      }),
  })
)

export default connector(TermsAndConditionsConsent)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Arrow, Manager, Popper, Target } from 'react-popper'

import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

import svgArrow from './images/triangle-arrows.svg'

const shrinkFade = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const StyledPopper = styled(props => (
  <Popper {...R.omit(['visible'], props)} />
))`
  background-color: ${props => (props.visible ? 'white' : 'transparent')};
  border-radius: 1em;
  padding: 1em 2em;
  color: black;
  max-width: 250px;
  text-align: center;
  opacity: 0;
  animation-name: ${shrinkFade};
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  pointer-events: none;

  ${props => getPopperMargins(props.placement)};

  @media screen and (max-width: 767px) {
    max-width: 100px;
  }
`
const arrowSize = '3em'

const StyledArrow = styled(props => (
  <Arrow {...R.omit(['placement'], props)} />
))`
  width: ${arrowSize};
  height: ${arrowSize};
  position: absolute;
  background-image: url(${svgArrow});
  background-size: contain;
  background-repeat: no-repeat;
  ${props => getArrowStyles(props.placement)};
`

const StyledTarget = styled(Target)`pointer-events: auto;`

const arrowAnimation = (rotation, yAxis) => keyframes`
  from { transform: translate${yAxis ? 'Y' : 'X'}(-5%) rotate(${rotation}); }
  to { transform: translate${yAxis ? 'Y' : 'X'}(5%) rotate(${rotation}); }
`

function getArrowStyles(placement) {
  switch (placement) {
    case 'left': {
      return css`
        right: calc(-${arrowSize} - 10px);
        margin-left: 10px;
        top: calc(50% - ${arrowSize});
        animation: ${arrowAnimation('90deg')} 1s ease infinite alternate;
      `
    }

    case 'right': {
      return css`
        left: calc(-${arrowSize} - 10px);
        margin-right: 10px;
        top: calc(50% - ${arrowSize});
        animation: ${arrowAnimation('-90deg')} 1s ease infinite alternate;
      `
    }

    case 'top': {
      return css`
        left: calc(50% - ${arrowSize});
        margin-top: 10px;
        margin-left: 3.5vh;
        bottom: calc(-${arrowSize} - 10px);
        animation: ${arrowAnimation('180deg', true)} 1s ease infinite alternate;
      `
    }

    case 'bottom': {
      return css`
        left: calc(50% - ${arrowSize});
        margin-bottom: 10px;
        margin-left: 1.5vh;
        top: calc(-${arrowSize} - 10px);
        animation: ${arrowAnimation('0deg', true)} 1s ease infinite alternate;
      `
    }

    default:
      return
  }
}

function getPopperMargins(placement) {
  switch (placement) {
    case 'left': {
      return css`
        margin-right: calc(${arrowSize} + 30px);
      `
    }

    case 'right': {
      return css`
        margin-left: calc(${arrowSize} + 30px);
      `
    }

    case 'top': {
      return css`
        margin-bottom: calc(${arrowSize} + 30px);
      `
    }

    case 'bottom': {
      return css`
        margin-top: calc(${arrowSize} + 30px);
      `
    }

    default:
      return
  }
}

function OnboardingPopper(props) {
  if (props.hidden) {
    return props.children
  }

  return (
    <Manager>
      <StyledTarget>{props.children}</StyledTarget>
      <StyledPopper visible={props.content} placement={props.placement}>
        {props.content}
        <StyledArrow placement={props.placement} />
      </StyledPopper>
    </Manager>
  )
}

OnboardingPopper.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  hidden: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
}

export default OnboardingPopper

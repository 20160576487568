import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { withTranslate } from '@rushplay/i18n'

import Icon from '../../common/icon/icon'

import { default as Content } from './content'
import { default as Message } from './message'
import { default as Title } from './title'

const ProTip = styled.span`font-weight: 700;`

function ForgotPasswordConfirmation(props) {
  return (
    <Content>
      <Icon name="envelope" style={{ fontSize: '80px' }} />
      <Title>{props.translate('reset-password.received-email')}</Title>
      <Message>
        <p>{props.translate('reset-password.follow-email-link')}</p>
        <p>
          <ProTip>{props.translate('reset-password.pro-tip')}</ProTip>
          &nbsp;
          {props.translate('reset-password.email-spam')}
        </p>
      </Message>
    </Content>
  )
}

ForgotPasswordConfirmation.propTypes = { translate: PropTypes.func }

export default withTranslate(ForgotPasswordConfirmation)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Casino from '@rushplay/casino'
import * as Common from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Toast from '../common/toast'
import { isEmbeddingEnabled } from '../store/app'

const gameId = R.pipe(R.path(['params', 'gameId']), parseInt)

/**
 * Optionally parse input.
 * @param {*} input
 * @returns {*} Parsed string or input if it can’t be parsed.
 */
function maybeParse(input) {
  if (typeof input !== 'string') {
    return input
  }

  try {
    return JSON.parse(input)
  } catch (error) {
    return input
  }
}

class GameIframeContainer extends React.Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage)
  }

  handleMessage(event) {
    // This event is bubbled up from inner iframes. Mainly game-integration through solid where the game is 2 levels deep.
    const parsedEventData = R.path(['event'], maybeParse(event.data))

    if (
      event.data.type === '@rushplay/game-callback/REDIRECT' ||
      R.equals('exit', parsedEventData)
    ) {
      if (event.data.payload) {
        this.props.redirectTo(decodeURIComponent(event.data.payload))
      } else {
        this.props.redirectTo(`/${this.props.referrer}`)
      }
    } else if (R.equals('reloadGame', parsedEventData)) {
      window.location.reload()
    }
  }

  render() {
    if (this.props.gameNotFetched) {
      return (
        <Common.Box margin="15px" zIndex="2">
          <Toast
            text={this.props.translate('game-iframe.reload-game')}
            variant="error"
          />
        </Common.Box>
      )
    }

    if (!this.props.src) {
      return null
    }

    if (this.props.launchMethod === 'script') {
      return (
        <React.Fragment>
          <Helmet>
            <script src={this.props.src} />
          </Helmet>
          <div id="pnggame" />
        </React.Fragment>
      )
    }

    return (
      <object
        data={this.props.src}
        style={{ overflow: 'hidden', width: '100%' }}
        type="text/html"
      />
    )
  }
}

function getUrl(script, dpr) {
  const src = new URL(script)
  src.searchParams.set('dpr', dpr)
  return src.toString()
}

function mapStateToProps(state, props) {
  const id = props.gameId || gameId(props)
  const game = Casino.selectors.getGame(state.casino, { id })
  const options = R.pathOr({}, ['options'], game)

  if (!game || R.isEmpty(options)) {
    return { gameNotFetched: true }
  }
  const embedding = isEmbeddingEnabled(state.app)

  // TODO: BE must send correct launch method
  const launchMethod = options.scriptSrc ? 'script' : options.launchMethod

  // TODO: Integrate in Game-server
  const src = getUrl(
    options.iframeSrc || options.scriptSrc,
    window.devicePixelRatio
  )

  return {
    embedding,
    launchMethod,
    referrer: R.pathOr('casino', ['location', 'query', 'referrer'], props),
    src,
  }
}

export { GameIframeContainer }

export default connect(mapStateToProps, { redirectTo })(
  withTranslate(GameIframeContainer)
)

GameIframeContainer.propTypes = {
  embedding: PropTypes.bool,
  launchMethod: PropTypes.string,
  gameNotFetched: PropTypes.bool,
  redirectTo: PropTypes.func,
  referrer: PropTypes.string,
  src: PropTypes.string,
  translate: PropTypes.func,
}

import * as R from 'ramda'

export function normalizeTransactionAmount(
  withdrawalTransaction,
  moneyBalanceCents,
  maxAmount
) {
  return value => {
    if (!value) {
      return undefined
    }

    if (withdrawalTransaction) {
      if (moneyBalanceCents) {
        const maxWithdrawValue = moneyBalanceCents / 100

        if (value > maxWithdrawValue) {
          return parseFloat(maxWithdrawValue)
        }

        return parseFloat(value)
      }

      return parseFloat(Math.min(value, maxAmount))
    }

    return parseFloat(Math.min(value, maxAmount))
  }
}

export function normalizeExpiryYear(v) {
  if (!v) {
    return v
  }

  const value = v.replace(/\s+/g, '').replace(/\D/gi, '')

  return `20${R.takeLast(2, value)}`
}

export function normalizeExpiryMonth(v) {
  if (!v) {
    return v
  }

  const value = v.replace(/\s+/g, '').replace(/\D/gi, '')

  return R.takeLast(2, value)
}

export function normalizeCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const segment = 4
  const v = value.replace(/\s+/g, '').replace(/\D/gi, '')
  const matches = v.match(/\d{4,16}/g)
  const match = (matches && matches[0]) || ''
  const parts = []
  for (let i = 0, len = match.length; i < len; i += segment) {
    parts.push(match.substring(i, i + segment))
  }
  if (parts.length) {
    return parts.join(' ')
  }

  return value
}

export const normalizers = {
  creditCard: normalizeCreditCardNumber,
  transactionAmount: normalizeTransactionAmount,
  expiryYear: normalizeExpiryYear,
  expiryMonth: normalizeExpiryMonth,
}

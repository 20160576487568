import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import parseMs from 'parse-ms'
import { locationShape } from 'react-router/lib/PropTypes'

import styled from '@emotion/styled'
import { Constraint, Flex, Space } from '@rushplay/common'

import Card from '../../../common/card'
import Heading from '../../../common/heading'
import Text from '../../../common/text'

import { Limit } from './limit'

const TextWrapper = styled(Text)`
  font-weight: 300;
  line-height: 1.4;
`

export function Limits(props) {
  return (
    <Constraint centered width="1200">
      <Flex column alignItems="center">
        <Space y={5} key={props.target}>
          <Card themeDark>
            <Space top={6}>
              <Heading align="center" level="3">
                {props.translate(`player-limits.login-time.title`)}
              </Heading>
            </Space>
            <Space all={5}>
              <TextWrapper align="center" size="14">
                {props.translate(`player-limits.login-time.info`, {
                  cooldown: parseMs(props.cooldown).days,
                })}
              </TextWrapper>
            </Space>
            {props.crossbrand && (
              <Space bottom="32px">
                <Text align="center" size="12">
                  {props.translate(
                    `${props.license}.player-limits.crossbrand-prompt`
                  )}
                </Text>
              </Space>
            )}
            <Flex wrap justifyContent="space-around">
              {R.map(id => {
                return (
                  <Limit
                    key={`${props.target}-${id}`}
                    id={id}
                    target={props.target}
                    translate={props.translate}
                    currentTime={props.currentTime}
                  />
                )
              }, props.ids)}
            </Flex>
          </Card>
        </Space>
      </Flex>
    </Constraint>
  )
}

Limits.propTypes = {
  activeFrom: PropTypes.number,
  cooldown: PropTypes.number,
  crossbrand: PropTypes.bool,
  currentTime: PropTypes.number,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  license: PropTypes.string,
  location: locationShape,
  max: PropTypes.number,
  min: PropTypes.number,
  target: PropTypes.string.isRequired,
  translate: PropTypes.func,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import { PageSpinner } from '../../common/spinner'

const Wrapper = styled.div`
  margin: 70px auto;
  max-width: 1200px;
  position: relative;

  @media screen and (max-width: 860px) {
    margin: 40px auto;
  }
`

const ModeWrapper = styled.div`
  margin: 0 auto;
  width: 80%;
  border-radius: 20px;
  border: 2px solid ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
  padding: 16px;
  display: flex;
  @media screen and (max-width: 860px) {
    padding-top: 40px;
  }
`

const Logotype = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 60%;
  min-width: 120px;
  height: 20vh;
  max-height: 240px;
  min-height: 80px;
  background-image: url('${props => props.imageUrl}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  @media screen and (max-width: 860px) {
    width: 90%;
    max-height: 120px;
  }
`

const Title = styled.h2`
  color: ${props => (props.isBlitz ? '#f4ca43' : '#FFFFFF')};
  text-align: center;
  font-size: 26px;
  padding-bottom: 16px;
`

const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 250px;
`

const Image = styled.img`
  border-radius: 10px;
  border: 2px solid ${props => props.borderColor};
  width: 260px;
  height: 150px;

  @media screen and (max-width: 860px) {
    width: 220px;
    height: 130px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 80px;
  margin-bottom: 80px;

  @media screen and (max-width: 860px) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  &:last-of-type {
    border-left: 1px solid ${props => props.borderColor};

    @media screen and (max-width: 860px) {
      display: none;
    }
  }
`

const MobileSpecialCase = styled.div`
  padding-top: 18px;
  width: 100%;
  display: none;

  @media screen and (max-width: 860px) {
    display: block;
  }
`

const Constrain = styled.div`
  width: 100%;
  max-width: 280px;
`

function ModeSelector(props) {
  if (!props.gameColors[0]) {
    return <PageSpinner />
  }

  return (
    <Wrapper>
      <ModeWrapper
        borderColor={props.gameColors[0]}
        backgroundColor={props.gameColors[1]}
      >
        <ContentWrapper>
          <Title isBlitz>
            {props.translate('game.mode-selector.blitz.title')}
          </Title>
          <Text>{props.translate('game.mode-selector.blitz.description')}</Text>
          <Space y="24px">
            <Image
              alt="Blitz"
              borderColor={props.gameColors[0]}
              src={props.translate(`game.mode-selector.blitz.${props.gameKey}`)}
            />
          </Space>
          <Constrain>
            <Link to={props.authenticated ? props.blitzLink : '/register'}>
              <Button stretch>
                {props.authenticated ? (
                  props.translate('game.mode-selector.blitz.cta')
                ) : (
                  props.translate('game.mode-selector.blitz.cta.logged-out')
                )}
              </Button>
            </Link>
            <MobileSpecialCase>
              <Link to={props.normalLink}>
                <Button stretch variant="primary">
                  {props.translate('game.mode-selector.normal.cta')}
                </Button>
              </Link>
            </MobileSpecialCase>
          </Constrain>
        </ContentWrapper>
        <ContentWrapper borderColor={props.gameColors[0]}>
          <Title>{props.translate('game.mode-selector.normal.title')}</Title>
          <Text>
            {props.translate('game.mode-selector.normal.description')}
          </Text>
          <Space y="24px">
            <Image
              alt="Normal"
              borderColor={props.gameColors[0]}
              src={props.translate(
                `game.mode-selector.normal.${props.gameKey}`
              )}
            />
          </Space>
          <Constrain>
            <Link to={props.normalLink}>
              <Button stretch variant="primary">
                {props.translate('game.mode-selector.normal.cta')}
              </Button>
            </Link>
          </Constrain>
        </ContentWrapper>
      </ModeWrapper>
      <Logotype imageUrl={props.gameLogo} />
    </Wrapper>
  )
}

export default withTranslate(ModeSelector)

ModeSelector.propTypes = {
  authenticated: PropTypes.bool,
  background: PropTypes.string.isRequired,
  blitzLink: PropTypes.string.isRequired,
  gameColors: PropTypes.array.isRequired,
  gameKey: PropTypes.string.isRequired,
  gameLogo: PropTypes.string.isRequired,
  normalLink: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

import * as R from 'ramda'

function getByEvent(event, state) {
  return state[R.findIndex(R.propEq('event', event), state)]
}

function getByPrefix(prefix, state) {
  return R.filter(n => R.startsWith(prefix, n.event), state)
}

export default {
  getByEvent,
  getByPrefix,
}

import PropTypes from 'prop-types'
import React from 'react'

import { Field } from '@rushplay/forms'

import { Input } from './input'
import { normalizers } from './normalizers'
import { parsers } from './parsers'

export function TextInput(props) {
  return (
    <Field
      initialValue={props.initialValue}
      name={props.name}
      validator={props.validators}
      normalize={props.normalize || normalizers[props.format]}
      parse={props.parse || parsers[props.format]}
      mountPoint="formsv2"
    >
      {field => (
        <Input
          {...field.getProps(props)}
          autoCapitalize={props.autoCapitalize}
          autoComplete={props.autoComplete}
          autoCorrect={props.autoCorrect}
          autoFocus={props.autoFocus}
          className={props.className}
          disabled={props.disabled}
          id={props.id}
          inputMode={props.inputMode}
          placeholder={props.placeholder}
          type="text"
        />
      )}
    </Field>
  )
}

TextInput.defaultProps = {
  initialValue: '',
}

TextInput.propTypes = {
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputMode: PropTypes.string,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  glowingBorder: PropTypes.bool,
  validators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
  ]),
  value: PropTypes.string,
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getMoneyBalanceCents } from '@rushplay/session'

import {
  acknowledgeBalanceTypeChange,
  promptBalanceTypeChange,
} from '../../store/player'
import {
  getShowBalanceTypeChangeMessage,
  getShowBalanceTypeChangeMessageOnLaunch,
} from '../../store/app'

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onAcknowledge: acknowledgeBalanceTypeChange,
      onBalanceTypeChange: promptBalanceTypeChange,
    },
    dispatch
  )
}

function mapStateToProps(state, ownProps) {
  const openOnLaunch =
    typeof ownProps.openOnLaunch !== 'undefined'
      ? ownProps.openOnLaunch
      : getShowBalanceTypeChangeMessageOnLaunch(state)

  return {
    isOpen: getShowBalanceTypeChangeMessage(state),
    openOnLaunch,
    type: getMoneyBalanceCents(state) !== 0 ? 'real' : 'bonus',
  }
}

export default connect(mapStateToProps, mapDispatchToProps)

import * as React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import Icon from '../../common/icon/icon'
import { headingFont } from '../../constants/typography'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 56px;
  transition: width 0.5s 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  @media screen and (max-width: 1024px) {
    width: ${(props) => (props.focused ? '100vw' : '56px')};
    transition: ${(props) =>
      props.focused
        ? 'width 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)'
        : 'width 0.5s 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)'};
  }

  @media screen and (min-width: 1025px) {
    width: 232px;
  }
`

const ControlsWrapper = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
`

const Input = styled.input`
  margin-left: 0.5em;
  display: block;
  width: 100%;
  background: none;
  border: 0;
  color: inherit;
  font-size: 14px;
  font-family: ${headingFont};
  outline: none;

  &::placeholder {
    color: inherit;
  }

  @media screen and (max-width: 1025px) {
    font-size: 16px;
  }
`

const SearchIcon = styled(Icon)`
  margin: -0.5em;
  padding: 0.5em;
  color: inherit;
  display: block;
  width: 1em;
  height: 1em;
  cursor: pointer;
  font-size: ${(props) => (props.name === 'search' ? '16px' : '13px')};
  line-height: 1;

  @media screen and (${(props) =>
      props.showWhen === 'big' ? 'max' : 'min'}-width: 1025px) {
    display: none;
  }
`

function SearchGamesInput(props) {
  const [focused, setFocused] = React.useState(false)
  const inputRef = React.useRef()

  function handleKeyDown(e) {
    if (e.key === 'Escape') {
      handleToggle(false)
      props.onClear()
    }
  }

  function handleToggle() {
    if (focused) {
      inputRef.current.blur()
    } else {
      inputRef.current.focus()
    }
    setFocused(!focused)
    props.onClear()
  }

  function onChange(e) {
    props.onChange(e.target.value)
  }

  return (
    <Wrapper valid={props.validSearch} focused={focused}>
      <ControlsWrapper>
        <Controls>
          <SearchIcon
            name={props.validSearch ? 'close' : 'search'}
            onClick={() => {
              handleToggle()
            }}
            showWhen="big"
          />

          <SearchIcon
            name={focused ? 'close' : 'search'}
            onClick={() => {
              handleToggle()
            }}
            showWhen="small"
          />
          <Input
            value={props.value}
            autoComplete="off"
            type="text"
            placeholder={props.translate('casino.search.placeholder-no-number')}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={onChange}
          />
        </Controls>
      </ControlsWrapper>
    </Wrapper>
  )
}

SearchGamesInput.propTypes = {
  clearSearchGames: PropTypes.func,
  searchQuery: PropTypes.string,
  validSearch: PropTypes.bool,
  translate: PropTypes.func,
  location: PropTypes.object,
  value: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withTranslate(SearchGamesInput)

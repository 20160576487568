import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import {
  getBrand,
  getEnvironment,
  getIsIpInternal,
  getLanguage,
} from '../store/app'
import { getCookieConsent } from '../store/player'

import { GoogleTagManager as Component } from './gtm'

// TODO: Get from BackEnd (configuration)
const BRANDKEY = {
  casinoheroes: 'GTM-PFCTL54',
  casitabi: 'GTM-KFD7CRV',
}

function mapStateToProps(state) {
  return {
    cookieConsent: getCookieConsent(state),
    environment: getEnvironment(state.app),
    gtmKey: BRANDKEY[getBrand(state.app)],
    isIpInternal: getIsIpInternal(state.app),
    language: getLanguage(state.app),
    version: process.version,
    websiteName: getBrand(state.app),
  }
}

const __DEV__ = process.env.NODE_ENV !== 'production'

const connector = R.compose(
  connect(mapStateToProps),
  recompose.branch(R.always(__DEV__), recompose.renderNothing)
)

export const GoogleTagManager = connector(Component)

/**
* Returns true if actual in within range of min and max (inclusive)
* @param {number} min
* @param {number} max
* @param {number} actual
*/
export default function isInRange(min, max, actual) {
  if (typeof min !== 'number') {
    throw new TypeError(
      `Expected "min" to be of type "number"; got "${typeof min}" instead`
    )
  }
  if (typeof max !== 'number') {
    throw new TypeError(
      `Expected "max" to be of type "number"; got "${typeof max}" instead`
    )
  }
  if (typeof actual !== 'number') {
    throw new TypeError(
      `Expected "actual" to be of type "number"; got "${typeof actual}" instead`
    )
  }
  return actual >= min && actual <= max
}

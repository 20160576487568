import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const CampaignButton = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  padding: 25px 10px;
  background: linear-gradient(90deg, #f76b1c 0%, #fbda61 100%);
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  cursor: pointer;
`

CampaignButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default CampaignButton

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import withoutLanguage from '../util/path-without-language'

const Language = styled.div`
  font-size: 14px;
  cursor: pointer;
  opacity: ${(props) => (props.active ? '1' : '.5')};
  padding-bottom: 16px;
  padding-right: 12px;
`

function handleChangeLanguage(language, props) {
  const supportedLanguage = props.getSupportedLanguage([language])

  if (supportedLanguage === props.language) {
    return
  }

  props.setItem('LANGUAGE', supportedLanguage)

  if (props.authenticated) {
    props.onUpdateLanguage(supportedLanguage)
  }

  props.onChangeLanguage(supportedLanguage)
  props.redirectTo(withoutLanguage(window.location.pathname))
}

export function LanguageSelector(props) {
  const i18n = useI18n()

  if (props.supportedLanguages.length < 2) {
    return null
  }

  return (
    <Box
      display="flex"
      paddingBottom={[4, null, null, null, '0px']}
      flexDirection={['row', null, null, null, 'column']}
      flexWrap="wrap"
      className={props.className}
    >
      {R.map(
        (language) => (
          <Language
            active={props.activeLanguage === language}
            onClick={() => handleChangeLanguage(language, props)}
            alignItems="flex-end"
            key={language}
          >
            {i18n.translate(`language-selector.${language}`)}
          </Language>
        ),
        props.supportedLanguages
      )}
    </Box>
  )
}

LanguageSelector.propTypes = {
  activeLanguage: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChangeLanguage: PropTypes.func.isRequired,
  supportedLanguages: PropTypes.array.isRequired,
}

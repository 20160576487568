import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'

import Button from '../../common/button'

function FingerprintContainer(props) {
  if (props.hasTouchId) {
    return (
      <Button
        stretch
        variant="primary"
        icon="fingerprint"
        type="button"
        onClick={() =>
          // eslint-disable-next-line  unicorn/require-post-message-target-origin
          window.webkit.messageHandlers.handle_fingerprint.postMessage(null)
        }
      >
        Touch ID
      </Button>
    )
  }
  return null
}

function mapStateToProps(state) {
  return {
    hasTouchId: path(['app', 'nativeApp', 'hasTouchId'], state),
  }
}

FingerprintContainer.propTypes = { hasTouchId: PropTypes.bool }

export default connect(mapStateToProps)(FingerprintContainer)

const TIMESTAMP = 'EFFECT_TIMESTAMP'

export function timestamp() {
  return { type: TIMESTAMP }
}

/**
 * Create middleware for declarative current timestamp retrieval.
 * Designed to be used with `redux-effects`.
 *
 * @param {function(): number} getTimestamp - Timestamp getter, e.g `Date.now`
 * @returns Redux middleware
 */
export function createTimestampMiddleware(getTimestamp) {
  return () => next => action =>
    action.type === TIMESTAMP ? Promise.resolve(getTimestamp()) : next(action)
}

export default createTimestampMiddleware(Date.now.bind(Date))

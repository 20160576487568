import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'

import { berry } from './../../constants/colors'
import { headingFont } from './../../constants/typography'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 3rem 2rem;
`

const Avatar = styled.img`
  border-radius: 50%;
  height: 45%;
  width: 45%;
  box-shadow: 0 11px 4px 0 rgba(0, 0, 0, 0.37);
  background-color: white;
`
const VersusIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: ${rem('-35px')};
  margin-top: ${rem('-35px')};
  border-radius: 50%;
  border: ${rem('6px')} solid white;
  color: white;
  background-color: ${berry};
  width: ${rem('70px')};
  height: ${rem('70px')};
  line-height: ${rem('70px')};
  font-family: ${headingFont};
  font-size: ${rem('20px')};
  font-weight: 700;
  box-shadow: 0 11px 4px 0 rgba(0, 0, 0, 0.37);
`

function PreBossfightImage(props) {
  return (
    <Wrapper>
      <Avatar src={props.player} />
      <Avatar src={props.boss} />
      <VersusIcon>VS</VersusIcon>
    </Wrapper>
  )
}

PreBossfightImage.propTypes = {
  boss: PropTypes.string.isRequired,
  player: PropTypes.string.isRequired,
}

export default PreBossfightImage

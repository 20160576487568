import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

import * as types from './types'
import actions from './actions'
import selectors from './selectors'

const initialState = []

function reducer(state = initialState, action) {
  switch (action.type) {
    case websockets.SESSION_EXPIRED:
    case types.CLEARED:
      return initialState

    case types.REMOVED: {
      const triggerIndex = R.findIndex(R.propEq('id', action.payload), state)
      return R.remove(triggerIndex, 1, state)
    }

    case types.UPDATED:
      return R.unionWith(R.eqBy(R.prop('id')), action.payload, state)

    default:
      return state
  }
}

export default {
  actions,
  reducer,
  types,
  selectors,
}

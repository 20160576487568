import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { timingFunctions } from 'polished'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Icon from '../icon/icon'

function inputBorderColor(props) {
  if (!props.empty) {
    if (props.valid) {
      return t.color('success')
    }
  }

  if (props.processing) {
    return t.color('night')
  }

  if (props.visited && props.invalid) {
    return t.color('errorSecondary')
  }

  if (props.glowingBorder) {
    return 'white'
  }

  if (props.active) {
    return t.color('night')
  }

  return t.color('dark60')
}

const Wrapper = styled('div', {
  shouldForwardProp: noneOf([
    'active',
    'disabled',
    'empty',
    'invalid',
    'processing',
    'valid',
    'visited',
    'floatingLabel',
    'glowingBorder',
    'centered',
    'currency',
  ]),
})`
  padding: 12px;
  text-align: left;
  display: flex;
  box-sizing: border-box;
  background-color: ${props =>
    props.disabled ? t.color('disabledGray') : t.color('white')};
  transition: border 0.3s ${timingFunctions('easeOutQuart')};
  font-size: ${t.fontSize(1)};
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${inputBorderColor};
  overflow: hidden;
  ${props => R.not(props.floatingLabel) && `padding: 11px 19px`};
  ${props => props.currency && `padding: 18px 12px`};
  ${props =>
    props.active &&
    !props.disabled &&
    `box-shadow: ${props.glowingBorder
      ? '0 0 8px 2px #209ace'
      : '0 2px 4px 0 rgba(0, 0, 0, .20)'}`};
  ${props => props.disabled && `filter: grayscale(100%)`};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
`

const StyledInput = styled.input`
  margin: initial;
  padding: initial;
  ${props =>
    props.floatingLabel ? 'padding-left: 0px;' : 'padding-top: 18px;'};
  background-color: ${props =>
    props.disabled ? t.color('disabledGray') : t.color('white')};
  box-sizing: border-box;
  width: 100%;
  font-size: ${props => (props.currency ? t.fontSize(2) : t.fontSize(1))};
  font-family: ${t.font('body')};
  text-align: ${props => (props.centered ? 'center' : 'left')};
  border: none;
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0px 9999px white;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${t.color('dark40')};
  }
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const CurrencyIcon = styled(Icon)`
  font-size: 19px;
  color: ${t.color('dark40')};
`

const PeekIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -.5em;
  font-size: 20px;
  color: ${t.color('dark40')};
  cursor: pointer;
`

const FloatingLabel = styled.label`
  color: ${t.color('dark40')};
  display: block;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${t.color('textFont')};
  transform: translateY(-50%);
  transform-origin: left top;
  transition: transform 0.3s ${timingFunctions('easeOutCubic')};
  ${props =>
    props.active &&
    `
      font-size: ${t.fontSize(1)};
      transform: translateY(-100%) scale(0.8, 0.8);
  `};
`

function Component(props) {
  const [inputType, setInputType] = useState(props.type)

  const empty = props.value === ''
  const active =
    (R.not(empty) && R.not(R.isNil(props.value))) ||
    props.focused ||
    props.disabled
  const processing = props.status === 'processing'
  const valid = props.status === 'valid'
  const invalid = props.status === 'invalid'
  const floatingLabel = props.currency || props.small

  return (
    <Wrapper
      className={props.className}
      active={props.focused}
      currency={props.currency}
      glowingBorder={props.glowingBorder}
      empty={empty}
      processing={processing}
      floatingLabel={floatingLabel}
      valid={valid}
      invalid={invalid}
      visited={props.visited}
      disabled={props.disabled}
    >
      {props.currency && <CurrencyIcon name={props.currency} />}
      <InputWrapper>
        {R.not(floatingLabel) && (
          <FloatingLabel
            active={active}
            currency={props.currency}
            empty={empty}
            htmlFor={props.id}
          >
            {props.placeholder && props.translate(props.placeholder)}
          </FloatingLabel>
        )}
        <StyledInput
          autoFocus={props.autoFocus}
          autoCapitalize={props.autoCapitalize}
          autoCorrect={props.autoCorrect}
          autoComplete={props.autoComplete}
          centered={props.centered}
          currency={props.currency}
          disabled={props.disabled}
          id={props.id}
          max={props.max}
          min={props.min}
          name={props.name}
          floatingLabel={floatingLabel}
          type={inputType}
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          step={props.step}
          placeholder={
            R.not(floatingLabel) ? (
              ''
            ) : (
              props.placeholder && props.translate(props.placeholder)
            )
          }
        />
        {props.type === 'password' && (
          <PeekIcon
            name={inputType === 'text' ? 'eye-slash' : 'eye'}
            onClick={() =>
              setInputType(inputType === 'password' ? 'text' : 'password')}
          />
        )}
      </InputWrapper>
    </Wrapper>
  )
}

Component.defaultProps = {
  min: 0,
  max: Infinity,
  value: '',
  step: 'any',
  onChange: () => {},
}

Component.propTypes = {
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  centered: PropTypes.bool,
  currency: PropTypes.string,
  glowingBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  focused: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  translate: PropTypes.func,
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']).isRequired,
  validator: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

export const Input = withTranslate(Component)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import Button from '../../common/button'
import { nightLight, oceanDark } from '../../constants/colors'

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -25%;

  width: 150%;
  height: auto;
  min-height: 150px;

  border-top-left-radius: 100%;
  border-top-right-radius: 100%;

  background-color: white;
  box-shadow: inset 0px 5px 40px -5px rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    min-height: 200px;
  }

  @media screen and (min-width: 1280px) {
    left: -5%;
    width: 110%;
  }
`

const SkipLink = styled.span`
  margin-top: 1em;
  color: ${nightLight};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${oceanDark};
  }
`

const LinkButtonWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;

  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-height: 750px) {
    bottom: 10px;
  }
`

function OnboardingSemiEclipse(props) {
  return (
    <Wrapper>
      {props.children}
      <LinkButtonWrapper>
        <Button onClick={props.onNextHandler}>{props.buttonText}</Button>
        <SkipLink onClick={props.onSkipHandler}>{props.linkText}</SkipLink>
      </LinkButtonWrapper>
    </Wrapper>
  )
}

OnboardingSemiEclipse.propTypes = {
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node,
  linkText: PropTypes.string.isRequired,
  onNextHandler: PropTypes.func.isRequired,
  onSkipHandler: PropTypes.func.isRequired,
}

export default OnboardingSemiEclipse

import React from 'react'

import styled from '@emotion/styled'

import { noneOf } from './none-of'

const OldConstraint = styled('div', {
  shouldForwardProp: noneOf(['as', 'width', 'height', 'centered']),
})`
  ${props => props.width && `max-width: ${props.width}px`};
  ${props => props.height && `max-height: ${props.height}px`};
  ${props => props.centered && `margin: auto`};
`

export function Constraint(props) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      'DEPRECATED',
      '<Constraint> component is deprecated and will be removed. Use <Box> component instead'
    )
  }
  return <OldConstraint {...props} />
}

import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { isExistingTranslation } from '@rushplay/i18n'

import { Disclaimer as Component } from './disclaimer'

function mapStateToProps(state) {
  const license = jurisdiction.getLicense(state.jurisdiction)

  const key = `deposit.${license}-disclaimer`

  return {
    text: isExistingTranslation(key) && key,
  }
}

const connector = R.compose(
  recompose.setPropTypes({
    translate: PropTypes.func,
    isExistingTranslation: PropTypes.func,
  }),
  connect(mapStateToProps),
  recompose.branch(
    props => R.not(R.is(String, props.text)),
    recompose.renderNothing
  )
)

export const Disclaimer = connector(Component)

import * as R from 'ramda'
import React from 'react'
import { ThemeProvider, withTheme } from 'emotion-theming'

import * as colors from '../constants/colors'

function WraplightBgThemeHOC(WrappedComponent) {
  function lightBgTheme(ancestorTheme) {
    const lightBgVariants = {
      variants: {
        secondary: colors.blueGrey,
        disabled: colors.ghostGrey,
        disabledText: colors.blueGrey,
      },
    }
    return R.mergeDeepRight(ancestorTheme, lightBgVariants)
  }

  return function WraplightBgTheme(props) {
    return (
      <ThemeProvider theme={lightBgTheme}>
        <WrappedComponent {...props} />
      </ThemeProvider>
    )
  }
}

export const withThemeForLightBG = R.compose(WraplightBgThemeHOC, withTheme)

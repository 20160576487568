/* eslint-disable no-console */
import * as R from 'ramda'

import * as Notifications from '@rushplay/notifications'
import * as websockets from '@rushplay/websockets'
import {
  fetchAvatars as fetchAvatarsApi,
  fetchCountries as fetchCountriesApi,
  fetchCountryCode as fetchCountryCodeApi,
  requestSmsVerification as requestSmsVerificationApi,
  updateMe as updateMeApi,
} from '@rushplay/api-client'
import {
  updateCountryCallingCode,
  updatePhoneNumber as updateMobile,
} from '@rushplay/session'

import shuffleArray from '../util/shuffle-array'

// Actions
const AVATARS_UPDATED = 'casino-heroes/signUp/AVATARS_UPDATED'
const CLEAR_SIGNUP = 'casino-heroes/signUp/CLEAR_SIGNUP'
const COUNTRIES_UPDATED = 'casino-heroes/signUp/COUNTRIES_UPDATED'
const COUNTRY_CODE_UPDATED = 'casino-heroes/signUp/COUNTRY_CODE_UPDATED'
export const REGISTRATION_SUCCESS = 'casino-heroes/signUp/REGISTRATION_SUCCESS'
export const REGISTRATION_FAIL = 'casino-heroes/signUp/REGISTRATION_FAIL'
const TERMS_CONDITIONS_TOGGLED = 'casino-heroes/signUp/TERMS_CONDITIONS_TOGGLED'
const WAIT_REGISTER = 'casino-heroes/signUp/WAIT_REGISTER'
const PRIVACY_POLICY_TOGGLED = 'casino-heroes/signUp/PRIVACY_POLICY_TOGGLED'

const initialState = {
  avatars: [],
  countries: [],
  countryCode: '',
  phoneVerificationPage: 2,
  termsConditionsVisible: false,
  privacyPolicyVisible: false,
  waitingRegister: false,
}

export function clearSignup() {
  return {
    type: CLEAR_SIGNUP,
  }
}

export function signUpReducer(state = initialState, action) {
  const { payload, type } = action

  const mergeWithState = R.mergeRight(state)

  switch (type) {
    case AVATARS_UPDATED: {
      return mergeWithState({ avatars: payload })
    }
    case COUNTRIES_UPDATED: {
      return mergeWithState({ countries: payload })
    }
    case COUNTRY_CODE_UPDATED: {
      return mergeWithState({ countryCode: payload })
    }
    case TERMS_CONDITIONS_TOGGLED: {
      return mergeWithState({
        termsConditionsVisible: !state.termsConditionsVisible,
      })
    }
    case PRIVACY_POLICY_TOGGLED: {
      return mergeWithState({
        privacyPolicyVisible: !state.privacyPolicyVisible,
      })
    }
    case WAIT_REGISTER: {
      return mergeWithState({ waitingRegister: payload })
    }
    case websockets.SESSION_EXPIRED:
    case CLEAR_SIGNUP: {
      return R.mergeRight(initialState, R.pick(['avatars'], state))
    }
    default: {
      return state
    }
  }
}

export function fetchAvatars() {
  return fetchAvatarsApi({
    success: payload => ({
      type: AVATARS_UPDATED,
      payload: shuffleArray(payload.value.result),
    }),
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function fetchCountries() {
  return fetchCountriesApi({
    success: payload => ({
      type: COUNTRIES_UPDATED,
      payload: payload.value,
    }),
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function fetchCountryCode() {
  return fetchCountryCodeApi({
    success: payload => ({
      type: COUNTRY_CODE_UPDATED,
      // Needs to be uppercase to match alpha2
      payload: payload.value.countryCode.toUpperCase(),
    }),
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function requestSmsVerification() {
  return requestSmsVerificationApi({
    success: () => {
      console.log('request sms success')
    },
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function updatePhoneNumber(userData) {
  return updateMeApi(userData, {
    success: () => {
      return [
        updateMobile(userData.address.mobile),
        updateCountryCallingCode(userData.address.countryCallingCode),
        requestSmsVerification(),
      ]
    },
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function updateLogInData(userData) {
  return updateMeApi(userData, {
    failure: res =>
      Notifications.add({
        message: res.value.message || 'error.generic',
        level: 'error',
      }),
    version: 1,
  })
}

export function registrationSuccess({
  affiliateId,
  avatar,
  countryCode,
  createdAt,
  language,
  requiresPhoneValidation,
  username,
}) {
  return {
    type: REGISTRATION_SUCCESS,
    payload: {
      affiliateId,
      avatar,
      countryCode: countryCode,
      language,
      requiresPhoneValidation,
      username,
      registrationDate: createdAt,
    },
  }
}

export function registrationFail(reason) {
  return {
    type: REGISTRATION_FAIL,
    payload: { reason },
  }
}

export function toggleTermsConditions() {
  return { type: TERMS_CONDITIONS_TOGGLED }
}

export function togglePrivacyPolicy() {
  return { type: PRIVACY_POLICY_TOGGLED }
}

export function waitRegister(payload) {
  return {
    type: WAIT_REGISTER,
    payload,
  }
}

export function getAvatars(signUp) {
  return signUp.avatars
}

export function getCountries(signUp) {
  return signUp.countries
}

export function getCountryCode(signUp) {
  return signUp.countryCode
}

export function getTermsConditionsVisible(signUp) {
  return signUp.termsConditionsVisible
}

export function getPrivacyPolicyVisible(signUp) {
  return signUp.privacyPolicyVisible
}

export function getWaitingRegister(signUp) {
  return signUp.waitingRegister
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import styled from '@emotion/styled'
import { getUserAvatar } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import { getActiveRaceCampaign } from '../../store/campaigns'

import AggregateProgress from './aggregate-progress'
import BigSpinsProgress from './big-spins-progress'
import CampaignModal from './campaign-modal'

function isAgainstMonster(campaignType) {
  return campaignType === 'aggregating_against_monster'
}

function isAgainstOthers(campaignType) {
  return campaignType === 'aggregating_against_others'
}

function getAssetImgByType(campaignAsset, type) {
  const asset = R.find(R.propEq('type', type), campaignAsset)
  return asset ? asset.image : ''
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

function CampaignOverlayContainer(props) {
  if (!props.campaign) {
    return null
  }

  return (
    <CampaignModal
      color="rgba(0, 13, 61, 0.7)"
      isOpen={props.campaignKey === props.campaign.key}
      title={props.translate(`campaigns.${props.campaign.key}.title`)}
      onRequestClose={() => props.redirectTo('/adventure')}
    >
      <Wrapper>
        {!isAgainstOthers(props.campaign.type) && (
          <AggregateProgress
            raceBarImg={getAssetImgByType(props.campaign.assets, 'raceMeter')}
            rewards={R.reverse(
              R.sortBy(R.prop('ppCheckpoint'), props.campaign.rewards)
            )}
            isAgainstMonster={isAgainstMonster(props.campaign.type)}
            monsterImg={props.campaign.monsterAvatarUrl}
            monsterProgress={props.campaign.monsterProgress}
            playerImg={props.playerImage}
            playerProgress={props.campaign.playerProgress}
          />
        )}
        {isAgainstOthers(props.campaign.type) && (
          <BigSpinsProgress
            maxPlayers={3}
            rewards={props.campaign.rewards}
            rewardImg={getAssetImgByType(props.campaign.assets, 'PVPReward')}
            avatarImg={props.playerImage}
            playerRank={props.campaign.playerRank}
            topWinners={R.slice(0, 3, props.campaign.topWinners)}
            winnerCount={props.campaign.winnersCount}
            playerCount={props.campaign.playersCount}
          />
        )}
      </Wrapper>
    </CampaignModal>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: getActiveRaceCampaign(state.campaigns),
    campaignKey: ownProps.params.key,
    playerImage: getUserAvatar(state.session),
  }
}

CampaignOverlayContainer.propTypes = {
  campaign: PropTypes.object,
  campaignKey: PropTypes.string,
  translate: PropTypes.func.isRequired,
  playerImage: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { redirectTo })(
  withTranslate(CampaignOverlayContainer)
)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { night } from '../constants/colors'

const Wrapper = styled.div`
  background-image: linear-gradient(${night} 0%, #1b3a71 84%);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`

function Row(props) {
  return <Wrapper>{props.children}</Wrapper>
}

Row.propTypes = { children: PropTypes.node }

export default Row

import * as React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { max, min, required } from '@rushplay/forms'
import { useI18n } from '@rushplay/i18n'

import * as Constants from '../constants'
import { FieldResponse, NumberInput } from '../common/forms'

const EstCurrency = styled.div`
  font-size: ${t.fontSize(0)};
  margin-top: 10px;
  margin-right: 10px;
  text-align: right;
`
export function normalizeTransactionAmount(
  withdrawalTransaction,
  moneyBalance,
  maxAmount
) {
  return (value) => {
    if (!value) {
      return null
    }

    if (withdrawalTransaction && moneyBalance) {
      return parseFloat(Math.min(value, moneyBalance))
    }

    return parseFloat(Math.min(value, maxAmount))
  }
}

export function TransactionAmountInput(props) {
  const i18n = useI18n()

  function handleAmountChange(event) {
    const value = event.target.value

    return props.onAmountChange(parseFloat(value))
  }

  return (
    <Common.Space bottom={4}>
      <NumberInput
        autoFocus={props.transactionType === Constants.TransactionType.DEPOSIT}
        currency={props.localCurrency}
        glowingBorder
        id="amount"
        initialValue={props.initialAmount}
        name="amount"
        disabled={props.disabled}
        normalize={normalizeTransactionAmount(
          props.transactionType === Constants.TransactionType.WITHDRAWAL,
          props.moneyBalanceCents / 100,
          props.maxAmount
        )}
        placeholder="wallet.amountselector.typ-in-amount"
        valid={props.valid}
        validators={[required, min(props.minAmount), max(props.maxAmount)]}
        onChange={handleAmountChange}
      />
      <FieldResponse
        for="amount"
        variables={{ maxLimit: props.maxAmount * 100 }}
      />
      {props.estimateCurrency && (
        <EstCurrency>
          {i18n.translate('welcome-offers.est-amount', {
            amount: props.convertedAmount,
          })}{' '}
          {props.conversionCurrency}
        </EstCurrency>
      )}
    </Common.Space>
  )
}

TransactionAmountInput.defaultProps = {
  onAmountChange: R.identity,
}

TransactionAmountInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  conversionCurrency: PropTypes.string,
  convertedAmount: PropTypes.number,
  estimateCurrency: PropTypes.bool,
  localCurrency: PropTypes.string,
  initialAmount: PropTypes.number,
  valid: PropTypes.bool,
  moneyBalanceCents: PropTypes.number,
  withdrawalTransaction: PropTypes.bool,
  maxAmount: PropTypes.number,
  minAmount: PropTypes.number,
  transactionType: PropTypes.string,
  tooltip: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  onAmountChange: PropTypes.func,
}

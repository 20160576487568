import * as R from 'ramda'
import { createAction } from 'redux-actions'

const initialState = {}

export const storePaytableItems = createAction(
  'casino-heroes/paytable/STORE_PAYTABLE_ITEMS'
)

export function paytableReducer(state = initialState, action) {
  const mergeWithState = R.mergeRight(state)

  switch (action.type) {
    case 'casino-heroes/paytable/STORE_PAYTABLE_ITEMS': {
      return mergeWithState({ items: action.payload.value })
    }

    default:
      return state
  }
}

import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Notifications from '@rushplay/notifications'
import * as locks from '@rushplay/compliance/locks'
import { dismissSourceOfWealth } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import { SourceOfWealth as Component } from './source-of-wealth'

function mapStateToProps(state) {
  const lock = locks.getLock(state.locks, {
    type: 'source-of-wealth-verification',
  })

  return {
    isDismissable: R.pathOr(false, ['dismissable'], lock),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDismiss: () =>
      dispatch(
        dismissSourceOfWealth({
          success: () => [
            locks.fetch(),
            locks.remove('source-of-wealth-verification'),
          ],
          failure: res =>
            Notifications.add({ message: res.value, level: 'error' }),
          version: 1,
        })
      ),
  }
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate
)

export const SourceOfWealth = connector(Component)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { withProps } from 'recompose'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import SvgAvatar from '../svg-avatar'

import SvgTreasure from './svg-treasure'
// TODO: move to CDN
import cloudsLarge from './assets/cloud-large.png'

const mapIndexed = R.addIndex(R.map)

/**
 * Amount of basis points (‱) of parent’s height which region will occupy
 * @constant {number}
 */
const REGION_HEIGHT = 8300

/**
 * Factor representing rendered region height ratio to initial size
 * @constant {number}
 */
const REGION_FACTOR = REGION_HEIGHT / 10000

/**
 * Factor representing amount of regions visible in viewport
 * @constant {number}
 */
const VIEWPORT_FACTOR = 1 + (1 - REGION_FACTOR) / REGION_FACTOR

/**
 * Amount of basis points (‱) of progress to move player avatar forward by
 * @constant {number}
 */
const PLAYER_OFFSET = (13800 - REGION_HEIGHT) / 2

const AVATAR_SIZE = 13

const BOSS_SIZE = 15

const POSITION_OFFSET = PLAYER_OFFSET * VIEWPORT_FACTOR

/**
 * Correct calculation of position in first region according to region height
 * and player offset
 * @param {number} position Position in world in basis points
 * @returns {number}
 */
function compensateFirstRegion(position) {
  if (position > 10000) {
    return position
  }

  return POSITION_OFFSET + (10000 - POSITION_OFFSET) / 10000 * position
}

/**
 * Calculate horizontal offset for given progress to make map items follow
 * the path
 * @param {number} progress World progress in basis points
 * @returns {number} Horizontal offset in basis points
 */
function horizontalOffset(progress) {
  progress = parseFloat(progress) || 0

  if (progress <= 10000) {
    const checkpoints = [
      { offset: 150, progress: 0 },
      { offset: 100, progress: 800 },
      { offset: 450, progress: 2400 },
      { offset: -450, progress: 5200 },
      { offset: -450, progress: 5600 },
      { offset: 600, progress: 8900 },
      { offset: 150, progress: 10000 },
      { offset: 100, progress: 10800 },
    ]
    const index = R.findIndex(c => c.progress > progress, checkpoints)

    if (index <= 0) {
      return 0
    }

    const a = checkpoints[index - 1]
    const b = checkpoints[index]

    const m = (b.progress - a.progress) / (b.offset - a.offset)

    return parseFloat((progress - a.progress) / m + a.offset).toFixed(3)
  }

  return horizontalOffset(progress - 10000)
}

function regionsOffset(count, progress) {
  return (
    (compensateFirstRegion(progress) - POSITION_OFFSET) / R.inc(count) / 100
  )
}

/**
 * Calculate region item position and take into account first region’s reduced
 * size.
 * @param {number} regionIndex
 * @param {number} position
 * @returns {number}
 */
function regionItemPosition(regionIndex, position) {
  position = parseFloat(position)

  if (regionIndex > 0) {
    return position
  }

  return REGION_FACTOR * position + POSITION_OFFSET / 100
}

const MapWrapper = styled.div`
  height: 100%;
  ${props => props.height && `height: ${props.height}px`};
  text-align: center;
  pointer-events: none;
  overflow: hidden;
  background-color: white;

  &::before {
    content: '';
    margin-left: -200%;
  }

  &::after {
    content: '';
    margin-right: -200%;
  }
`

const Map = styled.div`
  position: relative;
  display: inline-block;
  height: 100%;
  ${props => props.height && `height: ${props.height}px`};
  z-index: 0;
`

const Clouds = withProps(props => {
  const factor = props.align === 'right' ? 0.95 : 0.75
  const scale = props.align === 'right' ? 'scaleX(-1)' : ''
  const offset = regionsOffset(props.count, props.progress * 100)
  const transform = `${scale} translate3d(0, ${offset * factor || 0}%, 0)`
  return { style: { transform } }
})(styled.div`
  /*
    * box-sizing, padding-right and background-clip are used
    * to fix Chrome rendering artifact, when hairline border
    * is drawn for background-image with transparency
  */
  position: absolute;
  top: -${ps => ps.count * 100}%;
  bottom: 0;
  ${ps => `${ps.align}: -25%`};
  padding-right: 2px;
  box-sizing: border-box;
  width: 50%;
  background-clip: content-box;
  background-position-x: right;
  background-repeat: repeat-y;
  background-size: contain;
  z-index: 1;

  @media screen and (min-width: 481px) {
    background-image: url(${cloudsLarge});
  }
`)

Clouds.propTypes = {
  align: PropTypes.oneOf(['left', 'right']).isRequired,
}

const Regions = withProps(props => {
  const offset = regionsOffset(props.count, props.progress * 100)
  const transform = `translateY(${offset || 0}%)`
  return { style: { transform } }
})(styled.div`
  position: absolute;
  top: ${ps => ps.count * -REGION_HEIGHT / 100 + (100 - REGION_HEIGHT / 100)}%;
  bottom: 0;
  left: 0;
  right: 0;
`)

const RegionsOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  backface-visibility: hidden;
`

const RegionsTiles = styled(RegionsOverlay)`
  @media (orientation: portrait) {
    left: -200%;
    right: -200%;
  }
`

const Region = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  width: 100%;
  margin-top: -1px;
  ${ps =>
    ps.background &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-image: url(${ps.background});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (orientation: portrait) {
          background-size: contain;
        }
      }
    `};
`

const EmptyRegion = styled(Region)`background-image: ${ps => ps.background};`

const RegionItem = withProps(props => {
  const transform = `${props.hidden ? 'scale(0, 0) ' : 'scale(1, 1)'}
  translateX(${horizontalOffset(props.position * 100) / 100}%)`
  return { style: { transform } }
})(styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: stretch;
  width: 100%;
  top: ${ps => 100 - ps.position - ps.size}%;
  bottom: ${ps => ps.position}%;
  transition: transform 0.3s ease;
`)

const PlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const PlayerItem = withProps(props => {
  const transform = `
    translate3d(${horizontalOffset(props.position * 100) / 100}%, 0, 0)`
  return { style: { transform } }
})(styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  top: ${ps => 100 - ps.offset - ps.size}%;
  left: ${ps => ps.offset - ps.size}%;
  bottom: ${ps => ps.offset}%;
`)

// https://github.com/RushPlay/casino-heroes/pull/1356#issuecomment-333496451
class AspectRatioSvg extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
    this.state = { style: {} }
  }

  componentWillMount() {
    if (process.browser) {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    if (process.browser) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleResize() {
    if (this.timerId) {
      this.timerId = void clearTimeout(this.timerId)
    } else {
      this.setState({ style: { border: '1px solid transparent' } })
    }

    this.timerId = setTimeout(() => {
      this.timerId = void this.setState({ style: {} })
    }, 150)
  }

  render() {
    return <svg {...this.props} style={this.state.style} />
  }
}

function MapRegions(props) {
  const visibleRegions = R.addIndex(R.filter)((region, index) => {
    return Math.abs(Math.floor(props.progress / 100) - index) <= 1
  }, props.regions)
  const loops = Math.floor(props.progress / 100)
  const progress =
    props.progress >= 200 ? props.progress - (loops - 1) * 100 : props.progress
  const count = visibleRegions.length
  const emptyRegionBackground = R.last(props.regions).image
  return (
    <MapWrapper height={props.height}>
      <Map count={count} height={props.height} progress={progress}>
        <Clouds align="left" count={count} progress={progress} />
        <Regions count={count} progress={progress}>
          <RegionsTiles count={count} progress={progress}>
            {R.map(
              region => <Region key={region.key} background={region.image} />,
              visibleRegions
            )}
            <Region background={emptyRegionBackground} />
          </RegionsTiles>
          <RegionsOverlay count={count} progress={progress}>
            {mapIndexed(
              (region, index) => (
                <Region key={region.key}>
                  {R.map(
                    treasure => (
                      <RegionItem
                        key={treasure.id}
                        hidden={
                          !R.any(R.propEq('id', treasure.id), props.treasures)
                        }
                        position={regionItemPosition(
                          index,
                          treasure.progressPercent -
                            AVATAR_SIZE * VIEWPORT_FACTOR
                        )}
                        size={AVATAR_SIZE * VIEWPORT_FACTOR}
                      >
                        <SvgTreasure
                          src={props.treasureImage}
                          onClick={props.onTreasureClick}
                        />
                      </RegionItem>
                    ),
                    region.hiddenTreasures || []
                  )}
                  <RegionItem
                    size={BOSS_SIZE * VIEWPORT_FACTOR}
                    position="100"
                    hidden={
                      props.hideBossAvatar ||
                      progress >= (index + 1) * 100 ||
                      progress < index * 100
                    }
                  >
                    <SvgAvatar
                      src={region.bossAvatar}
                      promo={props.beatBossesCampaign}
                      onClick={props.onBossAvatarClick}
                    />
                  </RegionItem>
                </Region>
              ),
              visibleRegions
            )}
            <EmptyRegion background={emptyRegionBackground} />
          </RegionsOverlay>
        </Regions>
        <PlayerOverlay>
          <PlayerItem
            offset={PLAYER_OFFSET / 100 - AVATAR_SIZE}
            position={compensateFirstRegion(progress * 100) / 100}
            size={AVATAR_SIZE}
          >
            <SvgAvatar
              src={props.playerAvatarSrc}
              multiplier={props.boosterMultiplier}
              onClick={props.onPlayerAvatarClick}
            />
          </PlayerItem>
        </PlayerOverlay>
        <AspectRatioSvg
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          viewBox={`0 0 960 ${667 * VIEWPORT_FACTOR}`}
        />
        <Clouds align="right" count={count} progress={progress} />
      </Map>
    </MapWrapper>
  )
}

export default withTranslate(MapRegions)

MapRegions.defaultProps = {
  treasures: [],
}

MapRegions.propTypes = {
  beatBossesCampaign: PropTypes.bool,
  boosterMultiplier: PropTypes.number,
  currentWorldKey: PropTypes.string,
  height: PropTypes.number,
  hideBossAvatar: PropTypes.bool,
  playerAvatarSrc: PropTypes.string,
  progress: PropTypes.number,
  regions: PropTypes.array,
  translate: PropTypes.func.isRequired,
  treasures: PropTypes.array,
  treasureImage: PropTypes.string,
  onBossAvatarClick: PropTypes.func,
  onPlayerAvatarClick: PropTypes.func,
  onTreasureClick: PropTypes.func,
}

import * as types from './types'

export function reducer(state = [], action) {
  switch (action.type) {
    case types.STORE_OFFERS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export * from './actions'
export * from './selectors'

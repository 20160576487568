import * as R from 'ramda'
import { connect } from 'react-redux'

import GameIframeContainer from './game-iframe-container'

function mapStateToProps(state, props) {
  return {
    src: `${R.path(
      ['blitzProperties', 'launchUrl'],
      props.game
    )}&classicModeUrl=${encodeURIComponent(props.classicModeGameUrl)}`,
    redirect: typeof window.orientation !== 'undefined',
  }
}

const connector = connect(
  mapStateToProps,
  null
)

export default connector(GameIframeContainer)

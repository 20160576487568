import * as R from 'ramda'
import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@rushplay/common'

import { GameQuery } from '../../casino/game-query'

const mobileLandscape =
  'screen and (max-width: 580px) and (orientation: landscape)'

function scaledVmin(input) {
  return `calc(${input} / 4)`
}

const smallBorderSize = scaledVmin('2vmin')
const bigBorderSize = '4px'
const QuickGamesWrapper = styled.div`
  background: white;
  width: ${scaledVmin('52vmin')};
  min-width: 56px;
  max-width: 105px;
  margin-right: auto;
  margin-left: auto;
  padding: ${smallBorderSize};
  box-shadow: 0 2px 13px 4px rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 1280px) {
    width: 105px;
    padding: ${bigBorderSize};
  }
`
const QuickGameLink = styled(Link)`
  display: block;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: ${smallBorderSize};
  height: ${scaledVmin('42vmin')};
  min-height: 50px;
  max-height: 88px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media ${mobileLandscape} {
    min-height: 44px;
  }

  @media screen and (min-width: 1280px) {
    height: 88px;
    margin-bottom: ${bigBorderSize};
  }
`

export default function QuickGames() {
  return (
    <QuickGamesWrapper>
      <GameQuery name="quickGames" params={{ recent: true, limit: 4 }}>
        {data =>
          R.map(
            item => (
              <QuickGameLink
                to={{
                  pathname: `/casino/slots/${item.provider}/${item.id}/${item.key}`,
                  search: '?referrer=adventure',
                }}
                key={item.key}
                style={{ backgroundImage: `url(${item.imageUrl})` }}
              />
            ),
            data.games
          )}
      </GameQuery>
    </QuickGamesWrapper>
  )
}

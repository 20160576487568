import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import {
  getBonusBalanceCents,
  getMoneyBalanceCents,
  isSessionActive,
  updateBalance,
} from '@rushplay/session'

import { getNetEntGame } from '../store/selectors'
import { isEmbeddingEnabled } from '../store/app'

import BalanceTypeChangePopupContainer from './balance-type-change-popup'
import RealityCheckPopupContainer from './reality-check-popup-container'

let netEntExtendPlugin = undefined

class NetEntGameContainer extends Component {
  componentDidMount() {
    this.props.onGameLaunch(
      this.props.gameOptions,
      this.props.authenticated,
      this.props.bonusBalanceCents,
      this.props.moneyBalanceCents
    )
    window.netent_onLeaveTableClicked = this.props.redirectToLobby
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <div id="neGameClient" />
      </React.Fragment>
    )
  }
}

const blitzAppUrl = `${window.location.origin}${R.replace(
  '/slots',
  '/blitz',
  window.location.pathname
)}?referrer=casino&mode=blitz`

function getGameOptions(game, location) {
  return R.pathEq(['query', 'referrer'], 'blitz', location)
    ? R.assocPath(['mobileParams', 'lobbyUrl'], blitzAppUrl, game.options)
    : game.options
}

function mapStateToProps(state, ownProps) {
  const gameId = parseInt(R.path(['params', 'gameId'], ownProps))
  const game = getNetEntGame(state, { id: gameId })

  function stopAutoplay() {
    netEntExtendPlugin && netEntExtendPlugin.call('stopAutoplay', [])
  }

  const embedding = isEmbeddingEnabled(state.app)

  return {
    authenticated: isSessionActive(state.session),
    bonusBalanceCents: getBonusBalanceCents(state.session),
    // This is a weird way to keep NetEntGameContainer pure for unit tests
    // TODO: find less awkward way
    children: (
      <div>
        <RealityCheckPopupContainer onOpen={stopAutoplay} />
        <BalanceTypeChangePopupContainer onOpen={stopAutoplay} />
      </div>
    ),
    gameOptions: getGameOptions(game, ownProps.location),
    embedding,
    moneyBalanceCents: getMoneyBalanceCents(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  function parseAndUpdateBalance(
    balance,
    bonusBalanceCents,
    moneyBalanceCents
  ) {
    const balanceCents = parseInt(balance.match(/\d+/g).join(''), 10)
    if (moneyBalanceCents === 0 || balanceCents < bonusBalanceCents) {
      // is playing with bonus money
      dispatch(
        updateBalance({ moneyBalanceCents: 0, bonusBalanceCents: balanceCents })
      )
    } else {
      dispatch(
        updateBalance({
          moneyBalanceCents: balanceCents - bonusBalanceCents,
          bonusBalanceCents,
        })
      )
    }
  }

  function gameLaunchSuccess(
    netEntExtend,
    bonusBalanceCents,
    moneyBalanceCents
  ) {
    netEntExtendPlugin = netEntExtend
    netEntExtend.addEventListener('gameReady', () => {
      netEntExtend.addEventListener('gameRoundStarted', () =>
        netEntExtend.get('balanceInCurrency', balance =>
          parseAndUpdateBalance(balance, bonusBalanceCents, moneyBalanceCents)
        )
      )
      netEntExtend.addEventListener('gameRoundEnded', () =>
        netEntExtend.get('balanceInCurrency', balance =>
          parseAndUpdateBalance(balance, bonusBalanceCents, moneyBalanceCents)
        )
      )
    })
  }

  return {
    onGameLaunch: (
      options,
      authenticated,
      bonusBalanceCents,
      moneyBalanceCents
    ) => {
      const isMobile = typeof window.orientation !== 'undefined'
      window.netent.launch(
        options,
        res =>
          authenticated && !isMobile
            ? gameLaunchSuccess(res, bonusBalanceCents, moneyBalanceCents)
            : null,
        error => Notifications.add({ message: error.message, level: 'level' })
      )
    },
    redirectToLobby: () => {
      dispatch(push('/live-casino'))
    },
  }
}

export { NetEntGameContainer }

export default connect(mapStateToProps, mapDispatchToProps)(NetEntGameContainer)

NetEntGameContainer.propTypes = {
  authenticated: PropTypes.bool,
  children: PropTypes.node,
  embedding: PropTypes.bool,
  gameOptions: PropTypes.object.isRequired,
  bonusBalanceCents: PropTypes.number,
  moneyBalanceCents: PropTypes.number,
  redirectToLobby: PropTypes.func,
  onGameLaunch: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Link, Space } from '@rushplay/common'
import { css } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import Icon from '../common/icon/icon'
import Text from '../common/text'
import {
  baseFontSizeSmall,
  headingFont,
  textFont,
} from '../constants/typography'
import { nightDark, stone } from '../constants/colors'

const handleOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const GameItemWrapper = styled.div`
  position: relative;
  &:hover .login-overlay-wrapper,
  &:active .login-overlay-wrapper {
    transform: translateY(0);
    opacity: 1;
  }
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`

const GameItemContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 120px;
  background: ${nightDark};
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 768px) {
    min-height: 100px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 16px 8px;
  }
`

const LoginOverlayWrapper = styled(Link)`
  position: absolute;
  padding: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateY(-100%);
  left: 0px;
  opacity: 0;
  transition: all 0.3s 0.3s ease;
`

const LoginOverlay = styled(Flex)`
  background: rgba(0, 0, 0, 0.75);
  height: inherit;
`

const Title = styled.h6`
  font-family: ${headingFont};
  font-weight: 600;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: solid 1px ${stone};
  ${handleOverflow};
`

const Details = styled.div`
  margin-top: 10px;
  font-size: ${baseFontSizeSmall};
  ${handleOverflow};
`

const AvailableSeats = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  height: 14px;
  margin-right: 10px;
  background-color: ${stone};
  color: #fff;
  font-family: ${textFont};
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
`

const BetBehind = styled.span`
  color: green;
`

const Select = styled.div`
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${nightDark};
  color: ${stone};
`

const GameTileImage = styled.div`
  width: 110px;
  min-width: 110px;
  height: 110px;
  margin: 6px;
  border-radius: 4px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    width: 100px;
    min-width: 100px;
  }
`

const ChevronIcon = styled(Icon)`
  color: #fff;
  width: 22px;
  height: 34px;
  background-color: #2e3c74;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const BetRangeChipIcon = styled(Icon)`
  color: ${stone};
  margin-right: 11px;
`

const DealerSuitIcon = styled(Icon)`
  color: ${stone};
  margin-right: 8px;
`

function GameItem(props) {
  const gameUrl = `/casino/other/${props.gameProvider.toLowerCase()}/${
    props.gameId
  }?referrer=live-casino`
  return (
    <GameItemWrapper
      onClick={(event) => {
        event.stopPropagation()
        return props.isAuthenticated ? props.redirectTo(gameUrl) : null
      }}
    >
      {!props.isAuthenticated && (
        <LoginOverlayWrapper to="/register" className="login-overlay-wrapper">
          <LoginOverlay column alignItems="center" justifyContent="center">
            <Text align="center">
              {props.translate('live-casino.prompt-login-text')}
            </Text>
            <Space top={3}>
              <Button small variant="primary">
                {props.translate('live-casino.prompt-login-button')}
              </Button>
            </Space>
          </LoginOverlay>
        </LoginOverlayWrapper>
      )}

      <GameItemContent>
        <GameTileImage src={props.image} />
        <Content>
          <Title>{props.gameName}</Title>
          <Details>
            {props.dealer && (
              <Space bottom={3}>
                <DealerSuitIcon name={'dealer-suit'} /> {props.dealer}
              </Space>
            )}
            {props.betRange && (
              <Space bottom={3}>
                <BetRangeChipIcon name={'bet-range-chip'} />
                {props.betRange}
              </Space>
            )}

            {props.gameSeats !== null && (
              <Flex alignItems="center" justifyContent="flex-start">
                <AvailableSeats>{props.gameSeats}</AvailableSeats>
                {props.betBehind && props.gameSeats === 0 ? (
                  <BetBehind>
                    {props.translate('live-casino.bet-behind-available')}
                  </BetBehind>
                ) : (
                  props.translate('live-casino.available-seats')
                )}
              </Flex>
            )}
          </Details>
        </Content>
        <Select color={props.color}>
          <ChevronIcon name={'chevron-right'} />
        </Select>
      </GameItemContent>
    </GameItemWrapper>
  )
}

GameItem.propTypes = {
  isAuthenticated: PropTypes.bool,
  className: PropTypes.string,
  betBehind: PropTypes.bool,
  betRange: PropTypes.string,
  color: PropTypes.string,
  dealer: PropTypes.string,
  gameId: PropTypes.number,
  gameName: PropTypes.string,
  gameProvider: PropTypes.string,
  gameSeats: PropTypes.number,
  getItem: PropTypes.func,
  image: PropTypes.string,
  internalCurrency: PropTypes.string,
  loggedInOnce: PropTypes.bool,
  redirectTo: PropTypes.func,
  translate: PropTypes.func.isRequired,
}

export default withTranslate(GameItem)

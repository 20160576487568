import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import HtmlContent from '../../common/html-content'
import { night } from '../../constants/colors'

const Wrapper = styled.div`
  text-align: justify;
  background-color: ${night};
`
export function Disclaimer(props) {
  const i18n = useI18n()

  return (
    <Wrapper>
      <Space all={6}>
        <HtmlContent
          html={{
            __html: i18n.translate(props.text),
          }}
        />
      </Space>
    </Wrapper>
  )
}

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
}

import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as Session from '@rushplay/session'

import { GoogleDataLayerManager as Component } from './google-data-layer-manager'

function mapStateToProps(state) {
  return {
    affiliateId: Session.getAffiliateId(state.session),
    authenticated: Session.isSessionActive(state.session),
    userId: Session.getUsername(state.session),
  }
}

const __DEV__ = process.env.NODE_ENV !== 'production'

const connector = R.compose(
  connect(mapStateToProps),
  recompose.branch(R.always(__DEV__), recompose.renderNothing)
)

export const GoogleDataLayerManager = connector(Component)

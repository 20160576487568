import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withCountUp } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import Amount from '../../common/amount'
import HtmlContent from '../../common/html-content'
import { baseFontSizeLarge, headingFont } from '../../constants/typography'
import { nightDark, success, white } from '../../constants/colors'

const ContentArea = styled.div`
  position: relative;
  cursor: pointer;
  color: #fff;
  background-color: ${nightDark};
  border: 2px solid ${white};
  border-radius: 10px;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid ${nightDark};
    border-radius: 10px;
    pointer-events: none;
  }

  ${(props) => {
    if (!props.available) {
      return css`
        border-color: none;
        opacity: 0.7;

        &:hover {
          opacity: 0.7;
        }
      `
    } else {
      if (props.isTypeSelected) {
        if (props.selected) {
          return css`
            border-width: 2px;
            border-color: ${success};

            &::after {
              display: none;
            }
          `
        }
      }
    }
  }};
`

const Offer = styled(ContentArea)`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 36px;
  margin-top: 15px;

  @media screen and (max-width: 959px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

const NoOffer = styled(ContentArea)`
  display: flex;
  align-items: center;

  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;

  padding-top: 13px;
  padding-bottom: 11px;
  padding-left: 36px;

  font-family: ${headingFont};
  font-size: 16px;
  line-height: 22px;
`

const Description = styled.div`
  font-size: ${baseFontSizeLarge};
  line-height: 24px;
  margin-top: 5px;
`

const BonusWrapper = styled.div`
  font-size: ${baseFontSizeLarge};
  font-family: ${headingFont};
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`

const Bonus = styled.div`
  font-size: 24px;
  padding-right: 5px;
  font-weight: 500;

  @media screen and (max-width: 959px) {
    font-size: 18px;
  }
`

const BonusTitle = styled.div`
  font-size: ${baseFontSizeLarge};
  font-weight: 500;
`

const Radio = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  position: relative;
  margin-left: -26px;
  margin-right: 10px;
  margin-bottom: 2px;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: ${(props) =>
      props.selected ? 'rgba(255, 255, 255, .8)' : 'transparent'};
  }
`

const CountUpNumbers = withCountUp('span', { propName: 'children', factor: 10 })
const CountUpCurrency = withCountUp(Amount, {
  propName: 'children',
  factor: 10,
})

const mapIndexed = R.addIndex(R.map)

/**
 * Rewards needs to be sorted to determen which reward should be the Headline one.
 * It doesn't get appended to the array if the key has no value. Thus the Headline might be a lower tiered reward.
 **/
function getRewardsSorted(option) {
  const rewardOrder = [
    'bonusMoneyPercent',
    'fixedBonusMoney',
    'fixedFreespins',
    'fixedFeatureTriggers',
    'fixedFreebet',
    'fixedFreeGamesVoucher',
    'bonusRubies',
  ]

  return R.filter(
    (reward) => option[reward] !== null && option[reward] !== undefined,
    rewardOrder
  )
}

function OfferSelector(props) {
  return (
    <React.Fragment>
      {mapIndexed((option) => {
        const sortedRewards = getRewardsSorted(option)
        const firstReward = R.head(sortedRewards) || 'bonus'

        const rewardTitleTranslation = {
          bonusMoneyPercent: props.translate(
            'welcome-offer.title.percent-bonus',
            {
              percent: option.bonusMoneyPercent,
              amount: option.bonusMoneyPercentMaxCents,
              minimum_deposit: option.rangeLimits[0],
            }
          ),
          fixedBonusMoney: props.translate('welcome-offer.title.money-bonus', {
            amount: option.maxFixedBonusMoney,
          }),
          fixedFeatureTriggers: props.translate(
            'welcome-offers.title.bonus-game',
            {
              amount: option.maxFixedFeatureTriggers,
            }
          ),
          fixedFreespins: props.translate('welcome-offer.title.freespins', {
            amount: option.maxFixedFreespins,
          }),
          fixedFreeGamesVoucher: props.translate(
            'welcome-offer.title.fixed-free-games-voucher',
            {
              amount: option.fixedFreeGamesVoucher,
            }
          ),
          fixedFreebet: props.translate('welcome-offer.title.freebet', {
            amount: option.maxFixedFreebet,
          }),
          bonusRubies: props.translate('welcome-offer.title.rubies', {
            amount: option.maxFixedRubies,
          }),
        }

        const headlineReward =
          firstReward === 'bonusMoneyPercent'
            ? option.bonusMoneyPercentCalculated
            : option[firstReward]

        return (
          <React.Fragment key={option.id}>
            <Description>{rewardTitleTranslation[firstReward]}</Description>
            <Offer
              available={option.inLimits}
              selected={props.selectedType === option.id}
              isTypeSelected={Boolean(props.selectedType)}
              onClick={() => props.onChange(option.id)}
            >
              <BonusWrapper>
                <Radio
                  selected={props.selectedType === option.id && option.inLimits}
                />
                <Bonus>
                  {/money/i.test(firstReward) ||
                  /bet/i.test(firstReward) ||
                  firstReward === 'fixedFreeGamesVoucher' ? (
                    <CountUpCurrency
                      currency={props.currency}
                      fixed={!Number.isInteger(headlineReward / 100)}
                    >
                      {headlineReward}
                    </CountUpCurrency>
                  ) : (
                    <CountUpNumbers>{option[firstReward]}</CountUpNumbers>
                  )}
                </Bonus>
                <BonusTitle>
                  {props.translate(`welcome-offer.headline.${firstReward}`, {
                    gameTitle: option.gameTitle,
                  })}
                </BonusTitle>
              </BonusWrapper>
              {R.map(
                (item) => (
                  <Description key={item}>
                    <span style={{ marginRight: '.5em' }}>+</span>
                    {/money/i.test(item) || item === 'fixedFreebet' ? (
                      <CountUpCurrency
                        currency={props.currency}
                        fixed={!Number.isInteger(option[item] / 100)}
                      >
                        {option[item]}
                      </CountUpCurrency>
                    ) : (
                      <CountUpNumbers>{option[item]}</CountUpNumbers>
                    )}
                    <span style={{ marginLeft: '.5em' }}>
                      {props.translate(`welcome-offer.extras.${item}`)}
                    </span>
                  </Description>
                ),
                R.tail(sortedRewards)
              )}
            </Offer>
          </React.Fragment>
        )
      }, props.depositOffers)}

      <NoOffer
        selected={!props.selectedType}
        isTypeSelected={!props.selectedType}
        onClick={() => props.onChange(null)}
        available
      >
        <Radio selected={!props.selectedType} />
        <HtmlContent
          html={{
            __html: props.translate('wallet.deposit.do.not.want.offer'),
          }}
        />
      </NoOffer>
    </React.Fragment>
  )
}

OfferSelector.propTypes = {
  currency: PropTypes.string.isRequired,
  depositOffers: PropTypes.array,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  translate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withTranslate(OfferSelector)

import PropTypes from 'prop-types'
import React from 'react'

import { Field } from '@rushplay/forms'

import { Select } from './select'

export function SelectField(props) {
  return (
    <Field
      mountPoint="formsv2"
      name={props.name}
      value={props.value}
      initialValue={props.initialValue}
    >
      {(field) => {
        return (
          <Select
            {...field.getProps(props)}
            visited={field.visited}
            disabled={props.disabled}
            id={props.id}
            options={props.options}
          />
        )
      }}
    </Field>
  )
}

SelectField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  value: PropTypes.string,
}

/**
* TODO: refactor using something that's determenistic like:
* 'pure-random' (https://www.npmjs.com/package/pure-random)
* But not this one ^ as it's a huge package (156kb)
*/
export default function getRandomInt(min, max) {
  const _min = Math.ceil(min)
  const _max = Math.floor(max)
  return Math.floor(Math.random() * (_max - _min)) + _min
}

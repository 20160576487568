import * as types from './types'

export function updateStatistics(payload) {
  return {
    type: types.UPDATE_STATISTICS,
    payload,
  }
}

export function updateGames(payload) {
  return {
    type: types.UPDATE_GAMES,
    payload,
  }
}

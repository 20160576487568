import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Link, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import * as Lookup from '../../store/lookup'
import Button from '../../common/button'
import Icon from '../../common/icon/icon'
import Text from '../../common/text'
import background from '../../images/signup/signup-type-selection-bg.jpg'
import { Constraint } from '../../common/grid'
import { headingFont } from '../../constants/typography'
import { oceanLight } from '../../constants/colors'

const Wrapper = styled(Flex)`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 95vh;
  color: #ffffff;
  background-image: url(${background});
  background-position: top center;
  background-size: cover;
`

const infoItems = [
  {
    key: 'loyalty-program',
    icon: 'smiley',
  },
  {
    key: 'bankid',
    icon: 'bankid',
  },
  {
    key: 'simple-registration',
    icon: 'thumb-up',
  },
]

function SignupType(props) {
  const lookupCountryCode = ReactRedux.useSelector((state) =>
    Lookup.getCountryCode(state.lookup)
  )

  const isFinland = lookupCountryCode === 'FI'

  return (
    <Wrapper alignItems="center">
      <Constraint width="700">
        <Space top="60px" bottom="80px" x={4}>
          <Space bottom={8}>
            <Text bold size="3em" font={headingFont}>
              {isFinland
                ? props.translate(`deposit.sign-up-option.heading`)
                : props.translate(`bank-id.sign-up-option.heading`)}
            </Text>
          </Space>
          <Text size="2.65em" font={headingFont}>
            {isFinland
              ? props.translate(`deposit.sign-up-option.sub-heading`)
              : props.translate(`bank-id.sign-up-option.sub-heading`)}
          </Text>
        </Space>
      </Constraint>

      <span>
        <Link to="register/deposit">
          <Button variant="primary" minimalPadding>
            {isFinland
              ? props.translate(`deposit.sign-up-option.proceed.with-bankid`)
              : props.translate(`bank-id.sign-up-option.proceed.with-bankid`)}
          </Button>
        </Link>
        <Space top={6} bottom="80px">
          {isFinland && (
            <Space bottom="8px">
              <Link to="register/deposit?type=no-deposit">
                <Text size="14" underlined>
                  {props.translate(
                    `bank-id.sign-up-option.proceed.with-bankid.no-deposit`
                  )}
                </Text>
              </Link>
            </Space>
          )}
          {props.manualRegistration && (
            <Link to="register/create-account">
              <Text size="14" underlined>
                {isFinland
                  ? props.translate(
                      `deposit.sign-up-option.proceed.without-bankid`
                    )
                  : props.translate(
                      `bank-id.sign-up-option.proceed.without-bankid`
                    )}
              </Text>
            </Link>
          )}
        </Space>
      </span>

      <div>
        {R.map(
          (item) => (
            <Flex key={item.key} inline flexDirection="column">
              <Space x={6} bottom="35px">
                <Constraint width="280">
                  <Text size="48" color={oceanLight}>
                    <Icon name={item.icon} />
                  </Text>
                  <Space top="15px" bottom="15px">
                    <Text bold size="20" font={headingFont} color={oceanLight}>
                      {isFinland
                        ? props.translate(
                            `deposit.sign-up-option.info-item.${item.key}.title`
                          )
                        : props.translate(
                            `bank-id.sign-up-option.info-item.${item.key}.title`
                          )}
                    </Text>
                  </Space>
                  <Text size="14" lineHeight="19">
                    {isFinland
                      ? props.translate(
                          `deposit.sign-up-option.info-item.${item.key}.description`
                        )
                      : props.translate(
                          `bank-id.sign-up-option.info-item.${item.key}.description`
                        )}
                  </Text>
                </Constraint>
              </Space>
            </Flex>
          ),
          infoItems
        )}
      </div>
    </Wrapper>
  )
}

export default withTranslate(SignupType)

SignupType.propTypes = {
  translate: PropTypes.func.isRequired,
  manualRegistration: PropTypes.bool,
}

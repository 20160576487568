/**
 * Request configuration object
 *
 * Used to create action for `redux-effects-fetch`. `success` and `failure`
 * action creators are called with response object:
 *   - `url` - The URL of the endpoint you requested (as returned by the request)
 *   - `status` - The numerical status code of the response (e.g. `200`)
 *   - `statusText` - The text version of the status (e.g. `OK`)
 *   - `headers` - A [Headers object](https://developer.mozilla.org/en-US/docs/Web/API/Headers)
 *   - `value` - The deserialized value of the response. This may be an object or string, depending on the type of response (json or text)
 *
 * @typedef {Object} FetchConfig
 * @prop {Function} [success] - Success action creator
 * @prop {Function} [failure] - Failure action creator
 * @prop {string} [token] - Security token
 * @prop {bool} [normalize] - Specifies if response should be normalized. See https://github.com/paularmstrong/normalizr for more details
 * @prop {number} [version] - API endpoint version
 * @prop {number} [cacheFor] - Specifies for how many ms response should be cached. Can be Infinity
 * @prop {Object} [params] - Request params that goes to actual fetch
 * @prop {number} [params.timeout] -  Request timeout in ms, request promise will be rejected
 * if request does not complete in specified amount of time
 */

import { bind } from 'redux-effects'
import * as R from 'ramda'
import { v4 as generateUuid } from 'uuid'

import { EFFECT_FETCH_API } from './middleware'

const defaultParams = { headers: { 'Content-Type': 'application/json' } }

/**
 * Add authorization token as header (if present) to `fetch` params
 *
 * @private
 * @param {Object} params - `fetch` params
 * @returns `fetch` params with `Authorization` header
 */
function withAuthorizationHeader(token) {
  if (!token) {
    return defaultParams
  }

  const authorizationHeaderLens = R.lensPath(['headers', 'Authorization'])

  return R.set(authorizationHeaderLens, token, defaultParams)
}

/**
 * Add API version as header (if present) to `fetch` params
 *
 * @private
 * @param {Object} params - `fetch` params
 * @returns `fetch` params with `Accept` header
 */
function withVersionHeader(version, params) {
  if (!version) {
    return params
  }

  const versionHeaderLens = R.lensPath(['headers', 'Accept'])

  return R.set(
    versionHeaderLens,
    `application/vnd.casinosaga.v${version}`,
    params
  )
}

function request(url, config) {
  const params = Object.assign(
    {},
    withVersionHeader(config.version, withAuthorizationHeader(config.token)),
    config.params
  )

  if (params.body) {
    params.body = JSON.stringify(params.body)
  }

  const uuid = generateUuid()

  return bind(
    {
      type: EFFECT_FETCH_API,
      payload: {
        url,
        params,
      },
      meta: {
        '@rushplay/api-client': {
          cacheFor: config.cacheFor,
        },
        uuid,
      },
    },
    config.success,
    config.failure
  )
}

/**
 * Function for constructing HTTP GET action which is used in redux-effects-fetch
 *
 * @private
 * @param {String} request url
 * @param {FetchConfig} config
 * @returns {Object} action object which is used for HTTP GET request
 */
function getRequest(url, config) {
  const params = getParams(config, 'GET')

  return request(url, params)
}

/**
 * Function for constructing HTTP POST action which is used in redux-effects-fetch
 *
 * @private
 * @param {string} request url
 * @param {FetchConfig} config
 * @returns {Object} action  which is used for HTTP POST request
 */
function postRequest(url, config) {
  const params = getParams(config, 'POST')

  return request(url, params)
}

/**
 * Function for constructing HTTP PUT action which is used in redux-effects-fetch
 *
 * @private
 * @param {String} request url
 * @param {FetchConfig} config
 * @returns {Object} action  which is used for HTTP PUT request
 */
function putRequest(url, config) {
  const params = getParams(config, 'PUT')

  return request(url, params)
}

/**
 * Function for constructing DELETE action which is used in redux-effects-fetch
 *
 * @private
 * @param {string} request url
 * @param {FetchConfig} config
 * @returns {Object} action object which is used for HTTP DELETE request
 */
function deleteRequest(url, config) {
  const params = getParams(config, 'DELETE')

  return request(url, params)
}

/**
 * Get request params
 *
 * @private
 * @param {FetchConfig} config
 * @param {string} method
 */
function getParams(config, method) {
  const paramsMethodLens = R.lensPath(['params', 'method'])
  const params = R.set(paramsMethodLens, method, config)

  return params
}

export {
  deleteRequest as delete,
  getRequest as get,
  postRequest as post,
  putRequest as put,
}

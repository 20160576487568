import PropTypes from 'prop-types'
import React from 'react'

function GameIframeContainer(props) {
  if (!props.src) {
    return null
  }

  if (props.redirect) {
    window.location = props.src
    return null
  }

  //TODO: scrolling="no" is deprecated, it is recommended to use
  //style="overflow: hidden" instead, but that does not seem to work in games case.
  //The reason for having this attribute is that when the game loads, one
  //can see a blink of scrollbars for a monent, and then they dissapear.
  return (
    <iframe
      height="100%"
      scrolling="no"
      src={props.src}
      width="100%"
      onLoad={props.onLoad}
      ref={props.innerRef}
    />
  )
}

GameIframeContainer.propTypes = {
  game: PropTypes.object.isRequired,
  innerRef: PropTypes.node,
  src: PropTypes.string,
  redirect: PropTypes.bool,
  onLoad: PropTypes.func,
}

export default GameIframeContainer

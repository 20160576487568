import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Panel = styled.div`
  background-color: #062c5e;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  overflow: hidden;
`

Panel.propTypes = { children: PropTypes.element }

export default Panel

// dashToMinus :: String -> String
function dashToMinus(input) {
  // You may not notice the difference in monospaced font
  return input.replace('-', '−')
}

// group :: String -> String
function group(string) {
  let result = ''
  while (string !== '') {
    if (string.length < 3) {
      result = `${string} ${result}`
    } else {
      result = `${string.slice(-3)} ${result}`
    }

    // eslint-disable-next-line no-param-reassign
    string = string.slice(0, -3)
  }

  return dashToMinus(result.trim())
}

/**
 * Formats number by splitting in groups (e.g. 1000 → 1 000)
 * @param {(number|string)} input
 * @returns {string}
 */
export function toGrouped(input) {
  const inputString = input.toString()
  const arr = inputString.split('.')

  return arr[1] ? `${group(arr[0])}.${arr[1]}` : group(arr[0])
}

import * as R from 'ramda'

export default function formatCountryOptions(countries) {
  const result = R.map(country => {
    return {
      label: country.name,
      value: country.alpha2,
    }
  }, countries)

  return R.sort(R.ascend(R.prop('label')), result)
}

import React from 'react'
import { PropTypes } from 'prop-types'

export class LiveCasinoQuery extends React.Component {
  componentDidMount() {
    this.props.onFetch()
  }

  componentWillUnmount() {
    this.props.onClear()
  }

  render() {
    return this.props.children({
      games: this.props.games,
      ids: this.props.ids,
    })
  }
}

LiveCasinoQuery.propTypes = {
  children: PropTypes.func,
  ids: PropTypes.arrayOf(PropTypes.number),
  games: PropTypes.arrayOf(PropTypes.object),
  gameType: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
}

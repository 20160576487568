import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'

import * as App from '../../store/app'
import * as Colors from '../../constants/colors'
import { HamburgerMenu } from '../hamburger-menu'

import { Authentication } from './authentication'
import { Logo } from './logo'
import { MainMenu } from './main-menu'
import { MobileMenu } from './mobile-menu'
import { UserMenu } from './user-menu'

export function TopBar() {
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isSessionActive(state.session)
  )
  const isMenuActive = ReactRedux.useSelector((state) =>
    App.getMobileMenuVisibility(state)
  )
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    document.body.style.overflow = isMenuActive ? 'hidden' : 'auto'
    document.body.style.position = isMenuActive ? 'fixed' : 'relative'
    document.body.style.width = isMenuActive ? '100%' : 'auto'

    dispatch(App.setMobileMenuVisibility(isMenuActive))
  }, [isMenuActive])

  return (
    <Common.Box
      position="sticky"
      zIndex="100"
      top="0"
      backgroundColor={Colors.darkBlue1}
    >
      <Common.Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1440px"
        margin="auto"
      >
        <Common.Box display="flex" alignItems="center" width="100%">
          <Logo />
          <MainMenu />

          <Common.Box marginLeft="auto">
            {authenticated ? <UserMenu /> : <Authentication />}
          </Common.Box>
        </Common.Box>

        <Common.Box
          display={[null, null, null, null, 'none']}
          paddingLeft="10px"
          paddingRight="10px"
        >
          <HamburgerMenu />
        </Common.Box>
      </Common.Box>
      <Common.Box
        height={isMenuActive ? '100%' : 0}
        opacity={isMenuActive ? 1 : 0}
        display={[null, null, null, null, 'none']}
        position="fixed"
        width="100%"
        overflow="hidden"
        backgroundColor={Colors.darkBlue1}
        transition="all 0.3s ease"
      >
        <MobileMenu />
      </Common.Box>
    </Common.Box>
  )
}

import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import * as Analytics from '../../analytics'
import Button from '../../common/button'
import Section from '../section'
import { GameQuery } from '../game-query'

const SectionWrapper = styled.div`
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
`

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

//TODO: turn it into hook when GameQuery is not used anymore
function SearchGamesContainer(props) {
  const dispatch = ReactRedux.useDispatch()
  const prevHasGames = usePrevious(props.hasGames)
  React.useEffect(() => {
    if (!props.hasGames && !props.isLoading && prevHasGames) {
      dispatch(Analytics.noSearchResults({ searchedGame: props.searchedGame }))
    }
  }, [props.hasGames, props.isLoading, props.searchedGame])
  return props.children
}

SearchGamesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasGames: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchedGame: PropTypes.string,
}

export const SearchGamesSection = withTranslate((props) => {
  return (
    <SectionWrapper>
      <GameQuery key="search" name="search" params={props.params}>
        {({ games, onLoadMore, isLoading }) => {
          const heading =
            games.length > 0
              ? props.translate('casino.search.results', {
                  searchQuery: props.params.text,
                })
              : isLoading
              ? props.translate('casino.search.loading')
              : props.translate('casino.search.no-results')

          return (
            <SearchGamesContainer
              hasGames={Boolean(games.length)}
              isLoading={isLoading}
              searchedGame={props.params.text}
            >
              <Section
                games={games}
                heading={heading}
                icon="search"
                searchQuery={props.searchQuery}
                tagPrefix={props.tagPrefix}
              />

              {onLoadMore && games.length > 0 && (
                <Space top="15px">
                  <Flex justifyContent="center">
                    <Button
                      type="submit"
                      loading={isLoading}
                      onClick={onLoadMore}
                      minimalPadding
                    >
                      {props.translate('casino.category.load-more')}
                    </Button>
                  </Flex>
                </Space>
              )}
            </SearchGamesContainer>
          )
        }}
      </GameQuery>
    </SectionWrapper>
  )
})

SearchGamesSection.propTypes = {
  params: PropTypes.object,
  translate: PropTypes.function,
  searchQuery: PropTypes.object,
  tagPrefix: PropTypes.func,
}

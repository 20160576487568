import * as R from 'ramda'
import { connect } from 'react-redux'

import GameIframeContainer from './game-iframe-container'

function mapStateToProps(state, props) {
  return {
    src: R.path(['options', 'iframeSrc'], props.game),
    redirect: typeof window.orientation !== 'undefined',
  }
}

const connector = connect(
  mapStateToProps,
  null
)

export default connector(GameIframeContainer)

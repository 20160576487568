import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { h1, h2, h3, h4, h5, list, paragraph, small } from '../mixins'
import { ocean } from '../constants/colors'
import { textFont } from '../constants/typography'

const Wrapper = styled.div`
  h1 {
    ${h1};
  }

  h2 {
    ${h2};
  }

  h3 {
    ${h3};
  }

  h4 {
    ${h4};
  }

  h5 {
    ${h5};
  }

  p {
    ${paragraph};
  }

  li {
    ${list};
  }

  b,
  strong {
    font-weight: bold;
  }

  a {
    color: ${ocean};
    text-decoration: underline;
  }

  > details {
    position: relative;
    margin-bottom: 1px;
    background-color: rgba(0, 0, 0, 0.2);

    > summary {
      position: relative;
      display: block;
      cursor: pointer;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 20px 50px 20px 20px;

      font-family: ${textFont};
      font-size: 16px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
      }

      &:after {
        top: 20px;
        right: 20px;
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    details {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 5px;

      &:not(:first-of-type) {
        padding-top: 5px;
      }

      > summary {
        ${h5};
        position: relative;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        padding-right: 25px;

        &:after {
          right: 0;
          top: 0;
          height: 100%;
        }

        &::-webkit-details-marker {
          display: none;
        }
      }
    }

    main {
      padding: 20px 35px;
    }
  }

  details {
    &[open] > summary:after {
      transform: rotate(0deg);
    }

    summary {
      &:after {
        position: absolute;
        width: 20px;
        height: 17px;
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1683 1331l-166 165q-19 19-45 19t-45-19l-531-531-531 531q-19 19-45 19t-45-19l-166-165q-19-19-19-45.5t19-45.5l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z" fill="#fff" /></svg>');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        transform: rotate(-180deg);
        transition-duration: 0.5s;
        transition-property: transform;
      }
    }
  }

  small {
    ${small};
  }

  img {
    max-width: 100%;
  }

  table,
  td {
    border: 1px solid currentColor;
    border-collapse: collapse;
  }

  td {
    padding: 7px;
    max-width: 40px;
  }
`

function HtmlContent(props) {
  return (
    <Wrapper className={props.className} dangerouslySetInnerHTML={props.html} />
  )
}

export default HtmlContent

HtmlContent.propTypes = {
  className: PropTypes.string,
  html: PropTypes.shape({ __html: PropTypes.string }),
}

import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as forms from '@rushplay/forms'
import { withTranslate } from '@rushplay/i18n'

import { FieldResponse as Component } from './field-response'

function mapStateToProps(state, props) {
  const pointer = { form: props.form, field: props.for }
  return {
    errors: forms.errors(state.formsv2, pointer),
    focused: forms.isFocused(state.formsv2, pointer),
    status: forms.status(state.formsv2, pointer),
    value: forms.value(state.formsv2, pointer),
    visited: forms.isVisited(state.formsv2, pointer),
  }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return R.mergeAll([ownProps, stateProps, dispatchProps])
}

const connector = R.compose(
  recompose.fromRenderProps(forms.FormContext.Consumer, (context) => ({
    form: context?.name,
  })),
  withTranslate,
  connect(mapStateToProps, null, mergeProps)
)

export const FieldResponse = connector(Component)

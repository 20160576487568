import * as R from 'ramda'
import * as recompose from 'recompose'
import * as ReactRedux from 'react-redux'

import * as App from '../../store/app'
import HomeContainer from '../../home/home-container'

import { LandingPage as Component } from './landing-page'

export default R.compose(
  ReactRedux.connect(state => ({
    isCasitabi: App.isCasitabi(state),
  })),
  recompose.branch(
    props => !props.isCasitabi,
    recompose.renderComponent(HomeContainer)
  )
)(Component)

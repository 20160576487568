import * as R from 'ramda'

/**
 * Unique identifier for a process.
 *
 * @typedef {string} ProcessId
 */
/**
 * @constant
 */
export const UPDATED = 'processes/UPDATED'

/**
 * Updates processes.
 *
 * @param {ProcessId} id
 * @param {boolean} running
 * @returns {Object} Redux action
 * @private
 */
function update(id, running) {
  return {
    type: UPDATED,
    payload: { id, running },
  }
}

export function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATED: {
      return R.assocPath([action.payload.id], action.payload.running, state)
    }

    default: {
      return state
    }
  }
}

/**
 * Starts tracking process.
 *
 * @param {ProcessId} process
 * @returns {Object} Redux action
 */
export function start(process) {
  return update(process, true)
}

/**
 * Stops tracking process.
 *
 * @param {ProcessId} process
 * @returns {Object} Redux action
 */
export function stop(process) {
  return update(process, false)
}

/**
 * Get process status from provided processes.
 *
 * @param {Object} state
 * @param {Object} props
 * @param {Array.<ProcessId>} props.ids List of process ids
 * @returns {Boolean} Returns true if any of the provided processes
 * are running else false if not found or no process was running
 */
export function isRunning(state, props) {
  return R.any(id => R.pathOr(false, [id], state), props.ids)
}

/**
 * Get process status from all processes.
 *
 * @param {Object} state
 * @returns {Boolean} Returns true if any of the processes
 * are running else false if not found or no process was running
 */
export function isAnyRunning(state) {
  return R.any(R.equals(true), R.values(state))
}

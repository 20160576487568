import styled from '@emotion/styled'

import { gradientDeepBlueDown } from '../constants/colors'
import { headingFont } from '../constants/typography'

export const Games = styled.section`
  background-color: white;
  padding-bottom: 1em;
`

export const GamesHeading = styled.header`
  padding-top: 20px;
  background: ${gradientDeepBlueDown};
`

export const GamesTitle = styled.h3`
  font-family: ${headingFont};
  text-align: center;
  font-size: 24px;
  height: 80px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 20px;
  margin-right: 20px;
`

export const GameTitle = styled.h4`
  font-family: ${headingFont};
  font-size: 18px;
  font-weight: 200;
  color: #7f7f7f;
  margin-top: 10px;
  text-align: center;
`

export const Game = styled.li`
  padding: 20px;
  width: 130px;
  height: 160px;

  @media screen and (max-width: 320px) {
    width: 110px;
  }
`

export const GameImage = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`

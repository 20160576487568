import * as R from 'ramda'

import * as types from './types'
import * as selectors from './selectors'
import * as actions from './actions'

const initialState = {
  __items: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE: {
      const itemsLens = R.lensProp('__items')

      return R.over(itemsLens, R.mergeDeepRight(R.__, action.payload), state)
    }
    default:
      return state
  }
}

export default { actions, reducer, selectors }

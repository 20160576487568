import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Field, FormPropTypes } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import DigitsField from '../../common/digits-field'
import validators from '../../validators'

import StepTemplate from './step-template'
import { Description, InlineRow, InlineRowItem, Row } from './styles'

const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
`

function Step5(props) {
  return (
    <StepTemplate
      loading={props.loading}
      nextStepDisabled={!props.isValid}
      nextStepLabel={props.translate('sign-up.verify-account')}
      title={props.translate('sign-up.enter-code')}
      onBack={props.onBack}
      onNextStep={props.onNextStep}
    >
      <div>
        <Row>
          <Description>
            {props.translate('sign-up.digit-verification-code')}
          </Description>
        </Row>
        <InlineRow>
          <InlineRowItem>
            <Field
              component={DigitsField}
              group="step5"
              name="code"
              validator={[
                validators.required,
                validators.specialCharacters,
                validators.length(4),
              ]}
              normalize={R.replace(/([^0-9])/g, ' ')}
              count={4}
            />
          </InlineRowItem>
        </InlineRow>
        <Row>
          {props.translate('sign-up.error')}
          <Link onClick={props.onResendCode}>
            {props.translate('sign-up.resend-code')}
          </Link>
        </Row>
        <Row>
          {props.translate('signup.verification.still-having-problems')}
          <Link onClick={props.onLiveChatClick}>
            {props.translate('signup.verification.contact-support')}
          </Link>
        </Row>
      </div>
    </StepTemplate>
  )
}

export default withTranslate(Step5)

Step5.propTypes = {
  isValid: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    code: FormPropTypes.field,
  }).isRequired,
  loading: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onLiveChatClick: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
}

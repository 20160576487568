import * as R from 'ramda'
import parseMs from 'parse-ms'
import url from 'url'
import { createSelector } from 'reselect'

import * as casino from '@rushplay/casino'
import { isSessionActive } from '@rushplay/session'
import { translate } from '@rushplay/i18n'

import * as processIds from '../constants/processes'
import triggers from '../store/triggers'

import { getActiveDepositCampaign as _getActiveDepositCampaign } from './campaigns'
import { getClaimedCampaigns as _getClaimedCampaigns } from './promotions'
import {
  getClientType as _getClientType,
  getConfig as _getConfig,
  isCasitabi,
  isUk,
  isWaiting,
} from './app'
import {
  getDepositCount,
  getRealityCheckPeriod as _getRealityCheckPeriod,
  getTimeToRealityCheck as _getTimeToRealityCheck,
} from './player'

export function bindSelector(key, selector) {
  return (state, props) => selector(state[key], props)
}

const isAuthenticated = bindSelector('session', isSessionActive)
const getClaimedCampaigns = bindSelector('promotions', _getClaimedCampaigns)
const getClientType = bindSelector('app', _getClientType)
const getConfig = bindSelector('app', _getConfig)
const getRealityCheckPeriod = bindSelector('player', _getRealityCheckPeriod)
const getTimeToRealityCheck = bindSelector('player', _getTimeToRealityCheck)
const getGame = bindSelector('casino', casino.selectors.getGame)

const getCasinoModuleUrl = createSelector(
  [getConfig],
  R.path(['netent', 'staticUrl'])
)

const isDesktop = createSelector([getClientType], R.equals('browser'))

const getPluginUrl = createSelector(
  [
    isAuthenticated,
    isDesktop,
    getCasinoModuleUrl,
    getRealityCheckPeriod,
    getTimeToRealityCheck,
  ],
  (
    authenticated,
    desktop,
    casinoModuleUrl,
    realityCheckPeriod,
    timeTorealityCheck
  ) => {
    if (!desktop && authenticated && realityCheckPeriod && timeTorealityCheck) {
      return getTouchPluginUrl(
        casinoModuleUrl,
        realityCheckPeriod,
        timeTorealityCheck
      )
    }

    return ''
  }
)

export const getNetEntGame = createSelector(
  [getGame, getPluginUrl],
  (game, pluginUrl) => {
    if (pluginUrl) {
      return R.over(
        R.lensProp('options'),
        R.assoc('pluginUrl', pluginUrl),
        game
      )
    }

    return game
  }
)

// TODO: remove impure `translate`
function getPopupContents(periodMs, remainingMs) {
  const period = getTime(periodMs)
  const remaining = getTime(remainingMs)
  return {
    popupTitle: translate('reality-check.reminder'),
    popupDescriptionRemaining: translate('reality-check.playing-for', {
      time: remaining,
    }),
    popupDescriptionTotal: translate('reality-check.playing-for', {
      time: period,
    }),
    popupLeaveToHistoryButton: translate('reality-check.leave-to-history'),
    popupLeaveGameButton: translate('reality-check.leave-game'),
    popupContinueGameButton: translate('reality-check.continue'),
  }
}

function getTime(ms) {
  const time = parseMs(ms)
  const pad = nr => ('0' + nr).substr(-2)
  return `${pad(time.minutes)}:${pad(time.seconds)}`
}

function getTouchPluginUrl(
  casinoModuleUrl,
  realityCheckPeriod,
  timeToRealityCheck
) {
  const gameInclusionUrl = `${casinoModuleUrl}/gameinclusion/library/gameinclusion.js`
  const popupContents = getPopupContents(realityCheckPeriod, timeToRealityCheck)
  const gamblingHistoryUrl = `${window.location.protocol}//${window.location
    .host}/dashboard/history`

  const touchPluginUrl = {
    host: window.location.host,
    pathname: '/html/netent-touch-plugin.html',
    protocol: window.location.protocol,
    query: R.mergeRight(
      { gameInclusionUrl, realityCheckPeriod, gamblingHistoryUrl },
      popupContents
    ),
  }

  return url.format(touchPluginUrl)
}

export const getCasinoHeaderLinks = createSelector(
  [R.path(['app', 'config', 'casinoLinks']), isUk, isCasitabi],
  (allLinks, hideBlitz) =>
    R.reduce(
      (acc, link) => {
        if (link.label === 'casino.nav.blitz' && hideBlitz) {
          return acc
        } else {
          return R.append(link, acc)
        }
      },
      [],
      allLinks
    )
)

export const getActiveDepositCampaign = createSelector(
  [getClaimedCampaigns, R.prop('campaigns')],
  (claimed, campaigns) =>
    _getActiveDepositCampaign(R.omit(R.map(R.toString, claimed), campaigns))
)

export function selectCanAccessInventory(state) {
  return state.player.canAccessInventory
}

export const getInventoryLockedStatus = createSelector(
  [
    state => isWaiting(state.app, processIds.FETCH_PLAYER_INFO),
    R.compose(getDepositCount, R.prop('player')),
    state =>
      triggers.selectors.getByEvent('inventory_unlocked', state.triggers),
    state => selectCanAccessInventory(state),
  ],
  (
    isFetchingDepositCount,
    depositCount,
    unlockedNotification,
    canAccessInventory
  ) => {
    if (!isFetchingDepositCount) {
      if (unlockedNotification) {
        return 'unlocked'
      } else if (depositCount === 0 && !canAccessInventory) {
        return 'locked'
      }
    }
  }
)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import Icon from '../icon/icon'
import { blackish } from '../../constants/colors'

import getIndexByKey from './lib/get-index-by-key'
import handleChange from './lib/handle-change'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
`
const Chevron = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  color: ${blackish};
  cursor: pointer;
  user-select: none;
  font-size: 36px;
  @media screen and (min-width: 768px) {
    font-size: 50px;
  }
`
const ImageWrapper = styled.div`
  flex: 1;
  text-align: center;
`
const Image = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 191px;
  user-select: none;
  @media screen and (min-width: 768px) {
    height: 233px;
  }
`

class ImageSelect extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.images.length === 0 && nextProps.images.length > 0) {
      this.props.onChange(handleChange(this.props.current, 0, nextProps.images))
    }
  }

  render() {
    if (!this.props.images || this.props.images.length === 0) {
      return null
    }

    const currentIndex = getIndexByKey(this.props.current, this.props.images)

    return (
      <Wrapper>
        <Chevron
          onClick={() =>
            this.props.onChange(
              handleChange(this.props.current, -1, this.props.images)
            )}
        >
          <Icon name="chevron-left" />
        </Chevron>
        <ImageWrapper>
          <Image src={this.props.images[currentIndex].fullFigureImageUrl} />
        </ImageWrapper>
        <Chevron
          onClick={() =>
            this.props.onChange(
              handleChange(this.props.current, 1, this.props.images)
            )}
        >
          <Icon name="chevron-right" />
        </Chevron>
      </Wrapper>
    )
  }
}

export default ImageSelect

ImageSelect.defaultProps = { images: [] }

ImageSelect.propTypes = {
  current: PropTypes.string.isRequired,
  images: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'
import { setItem } from 'redux-effects-localstorage'

import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { Flex, Image, Space } from '@rushplay/common'
import {
  fetchSession,
  fetchWorlds,
  updateMe as updatePlayer,
} from '@rushplay/api-client'
import { actions as formActions } from '@rushplay/legacy-forms'
import { getDisplayName, getUserAvatar } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import Icon from '../../common/icon/icon'
import LogOutLink from '../log-out-link'
import NavIcon from '../../common/nav-icon/nav-icon'
import ProgressionContainer from '../../common/progression'
import worlds from '../../store/adventure/worlds'
import { CurrentSessionTime } from '../../components/current-session-time'
import { PageSpinner } from '../../common/spinner'
import { fetchAvatars, getAvatars } from '../../store/sign-up'
import { getUserNavItems, storeAvatarUrl, waitDashboard } from '../../store/app'
import { h2 } from '../../mixins'
import { headingFont } from '../../constants/typography'
import { nightLight, ocean, success } from '../../constants/colors'

import StatsRow from './stats-row'
import SvgAvatar from './../../components/svg-avatar'

const mapIndexed = R.addIndex(R.map)

const Wrapper = styled.div`
  max-width: 385px;
  margin-left: auto;
  margin-right: auto;
`

const UserAvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 107px;
  height: 107px;
  background-color: #fff;
  border-radius: 10px;
  border: 3px solid ${ocean};
  cursor: pointer;
  margin-right: 15px;
`

const UserAvatar = styled(Image)`
  height: 88px;
  width: 88px;
  border-radius: 50%;
`

const Cog = styled(Icon)`
  position: absolute;
  top: 3px;
  right: 3px;
  color: ${ocean};
`

const DisplayName = styled.h2`
  ${h2};
  font-weight: 600;
`

const StatsTitle = styled.h2`
  padding-left: 20px;
  padding-right: 20px;
  ${h2};
  font-weight: 600;
`

const Logout = styled(Space)`
  @media screen and (max-width: 1279px) {
    display: none;
  }
`

const BossRows = styled.div`
  background-image: linear-gradient(to bottom, #12284e, #295186);
`

const BossRow = styled(Flex)`
  padding-top: 20px;
  padding-bottom: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`

const BossWrapper = styled.div`
  width: 43px;
  height: 43px;

  @media screen and (min-width: 385px) {
    width: 52px;
    height: 52px;
  }
`

const BossAvatar = styled(BossWrapper)`
  position: relative;
  margin-right: 5px;
`

const Treasure = styled(BossWrapper)`
  position: relative;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 18px;

  &:before {
    content: '=';
    position: absolute;
    left: -18px;
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
  }
`

const BossCompleted = styled(Icon)`
  position: absolute;
  left: 0;
  color: ${success};
  height: 100%;
  width: 100%;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`

const UserNavWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${nightLight};
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 125px;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${ocean};
  &:not(:last-child) {
    border-right: 1px solid ${nightLight};
  }
`

const Label = styled.span`
  margin-top: 0.5rem;
  text-align: center;
  font-family: ${headingFont};
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
`

class UserDashboardContainer extends Component {
  componentWillMount() {
    if (R.isEmpty(this.props.avatars)) {
      this.props.fetchAvatars()
    }

    const config = {
      success: res => {
        const worldsEntities = R.path(
          ['value', 'result', 'entities', 'worlds'],
          res
        )
        const worldsKeys = R.path(['value', 'result', 'result'], res)

        return [
          worlds.actions.updateStats(worldsEntities),
          worlds.actions.updateDetails(worldsEntities),
          worlds.actions.storeWorlds(worldsKeys),
        ]
      },
      failure: res => Notifications.add({ message: res.value, level: 'error' }),
      version: 1,
      normalize: true,
    }

    this.props.fetchWorlds(config)
  }

  render() {
    if (R.isEmpty(this.props.worlds)) {
      return <PageSpinner />
    }

    return (
      <div>
        <ProgressionContainer />
        <Wrapper>
          <Space all={6}>
            <Flex alignItems="center" justifyContent="flex-start">
              <UserAvatarWrapper
                onClick={() => this.props.redirectTo('/dashboard/edit-avatar')}
              >
                <UserAvatar src={this.props.userAvatar} alt="avatar" />
                <Cog name="cog" size="small" />
              </UserAvatarWrapper>
              <DisplayName>{this.props.displayName}</DisplayName>
            </Flex>
          </Space>

          <UserNavWrapper>
            {R.map(
              navItem => (
                <IconWrapper key={navItem.key}>
                  <NavIcon size="45px" to={navItem.to} icon={navItem.icon} />
                  <Label>{this.props.translate(navItem.key)}</Label>
                </IconWrapper>
              ),
              this.props.userNavItems
            )}
          </UserNavWrapper>

          <StatsTitle>
            {this.props.translate('user-dashboard.global-stats')}
          </StatsTitle>

          {R.map(world => {
            const treasureIndex = R.findIndex(
              R.prop('secretPrizeUrl'),
              world.bosses || []
            )

            return (
              <div key={world.key}>
                <StatsRow
                  worldKey={world.key}
                  progressPercentage={world.worldProgressPercent}
                />

                {world.bosses &&
                treasureIndex > 0 && (
                  <BossRows>
                    {mapIndexed(
                      (chunk, index) => (
                        <BossRow key={index}>
                          {R.map(
                            boss => (
                              <BossAvatar key={boss.bossAvatar}>
                                <SvgAvatar src={boss.bossAvatar} />
                                {boss.completed && (
                                  <BossCompleted name="check-no-circle" />
                                )}
                              </BossAvatar>
                            ),
                            chunk
                          )}
                          <Treasure
                            src={R.path(['secretPrizeUrl'], R.last(chunk))}
                          />
                        </BossRow>
                      ),
                      R.splitEvery(R.add(treasureIndex, 1), world.bosses)
                    )}
                  </BossRows>
                )}
              </div>
            )
          }, this.props.worlds)}

          <Space y={6}>
            <CurrentSessionTime withTranslation />
          </Space>

          <Logout all={6}>
            <LogOutLink />
          </Logout>
        </Wrapper>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    avatars: getAvatars(state.signUp),
    userAvatar: getUserAvatar(state.session),
    displayName: getDisplayName(state.session),
    userNavItems: getUserNavItems(state),
    viewId: ownProps.params.viewId || '',
    worlds: worlds.selectors.getWorlds(state.adventure.worlds),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearForm: formActions.clearForm,
      fetchAvatars,
      fetchSession,
      fetchWorlds,
      redirectTo,
      setItem,
      storeAvatarUrl,
      updatePlayer,
      waitDashboard,
    },
    dispatch
  )
}

export default withTranslate(
  connect(mapStateToProps, mapDispatchToProps)(UserDashboardContainer)
)

UserDashboardContainer.propTypes = {
  avatars: PropTypes.array.isRequired,
  fetchAvatars: PropTypes.func,
  fetchSession: PropTypes.func.isRequired,
  fetchWorlds: PropTypes.func.isRequired,
  fields: PropTypes.object,
  redirectTo: PropTypes.func.isRequired,
  storeAvatarUrl: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  updatePlayer: PropTypes.func.isRequired,
  userAvatar: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  userNavItems: PropTypes.array,
  viewId: PropTypes.string,
  waitDashboard: PropTypes.func.isRequired,
  worlds: PropTypes.array.isRequired,
}

import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Forms from '@rushplay/forms'
import * as Limits from '@rushplay/compliance/limits'
import * as Locks from '@rushplay/compliance/locks'
import * as Session from '@rushplay/session'

import { Limit as Component } from './limit'

function mapStateToProps(state, props) {
  const limit = Jurisdiction.getMoneyLimit(state.jurisdiction, {
    type: props.target,
    period: props.period,
  })
  const valueCents =
    Forms.value(state.forms, {
      form: `${props.target}-${props.period}`,
      field: 'amount',
    }) * 100

  return {
    cooldown: R.pathOr(0, ['cooldown'], limit),
    currency: Session.getCurrency(state.session),
    depositLimitLock: Locks.getLock(state.locks, {
      type: 'unset-deposit-limits',
    }),
    excessive: Jurisdiction.getMoneyLimitExcess(state.jurisdiction, {
      type: props.target,
      period: props.period,
      valueCents,
    }),
    limitIsRemovable: !limit.required,
    max:
      R.min(
        R.pathOr(Number.MAX_SAFE_INTEGER, ['max'], limit),
        R.pathOr(
          Number.MAX_SAFE_INTEGER,
          [limit.period, 'max'],
          Limits.getValidatorsForTarget(state.limits, {
            target: limit.type,
          })
        )
      ) / 100,
    min:
      R.max(
        R.pathOr(0, ['min'], limit),
        R.pathOr(
          0,
          [limit.period, 'min'],
          Limits.getValidatorsForTarget(state.limits, {
            target: limit.type,
          })
        )
      ) / 100,
    softCap: limit.softCap,
    valueCents,
  }
}

const connector = connect(mapStateToProps)

export const Limit = R.compose(connector, Common.withCurrentTime)(Component)

import styled from '@emotion/styled'

import { headingFont } from '../constants/typography'

export const NewGameplay = styled.article`
  background-color: #000e38;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NewGameplayHeading = styled.header`
  text-align: center;
  background-color: #042751;
  width: 100%;
`

export const World = styled.li`
  padding-top: 2rem;
  width: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Worlds = styled.ul`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

export const WorldTitle = styled.h4`
  font-family: ${headingFont};
  font-size: 18px;
  color: white;
  text-align: center;
  padding-top: 2rem;
`

export const WorldImage = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 75px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`

export const WorldDescription = styled.p`
  padding: 1rem;
  font-weight: 200;
  line-height: 1.5;
  color: #999999;
  text-align: center;
`

export const NewGameplayTitle = styled.h2`
  font-family: ${headingFont};
  font-size: 24px;
  color: white;
  padding: 20px;
  text-align: center;
`

export const NewGameplaySubtitle = styled.p`
  font-family: ${headingFont};
  font-size: 18px;
  color: white;
  padding: 20px;
  text-align: center;
`

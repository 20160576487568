import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Forms from '@rushplay/forms'
import styled from '@emotion/styled'

import Checkbox from '../../../common/checkbox'
import Icon from '../../../common/icon/icon'
import InputField from '../../../common/input-field'
import { countryNames } from '../../../util/get-sorted-country-names'
import { dark40, dark60, darkBlue2 } from '../../../constants/colors'

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`

const StyledSelect = styled.button`
  font-size: 18px;
  border: 1px solid ${dark60};
  border-radius: 8px;
  background-color: ${darkBlue2};
  text-align: left;
  color: white;
  width: 100%;
  padding: 20px;
  &:focus {
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
`

const RemoveButton = styled.button`
  border: none;
  background-color: inherit;
  font-weight: 600;
  color: #838383;
  cursor: pointer;
`

const Arrow = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -0.5em;
  pointer-events: none;
  font-size: 20px;
  color: ${dark40};
  transform: ${(props) => props.show && 'rotate(180deg)'};

  @media screen and (min-width: 375px) {
    right: 20px;
  }
`

export default function MultipleSelect() {
  const i18n = I18n.useI18n()
  const [show, setShow] = React.useState(false)
  const [countryArray, setCountryArray] = React.useState([])
  const [searchArray, setSearchArray] = React.useState([])
  const dispatch = ReactRedux.useDispatch()
  const sortedCountries = {
    ABC: countryNames.filter((name) => name[0]?.match(/[A,B,C]/)),
    DEF: countryNames.filter((name) => name[0]?.match(/[D,E,F]/)),
    GHI: countryNames.filter((name) => name[0]?.match(/[G,H,I]/)),
    JKL: countryNames.filter((name) => name[0]?.match(/[J,K,L]/)),
    MNO: countryNames.filter((name) => name[0]?.match(/[M,N,O]/)),
    PQR: countryNames.filter((name) => name[0]?.match(/[P,Q,R]/)),
    STU: countryNames.filter((name) => name[0]?.match(/[S,T,U]/)),
    VWX: countryNames.filter((name) => name[0]?.match(/[V,W,X]/)),
    YZA: countryNames.filter((name) => name[0]?.match(/[Y,Z,Å]/)),
  }

  function handleOnChange(value, name) {
    if (value) {
      const newCountryArray = [...countryArray, name]
      setCountryArray(newCountryArray)
      return dispatch(
        Forms.changeValue(
          'source-of-funds',
          'fundsCountryOrigin',
          newCountryArray
        )
      )
    } else {
      const newCountryArray = countryArray.filter((country) => country !== name)
      setCountryArray(newCountryArray)
      return dispatch(
        Forms.changeValue(
          'source-of-funds',
          'fundsCountryOrigin',
          newCountryArray
        )
      )
    }
  }

  function handleOnSearch(e) {
    if (e?.target?.value) {
      return setSearchArray(
        countryNames.filter((name) =>
          name?.toLowerCase()?.startsWith(e?.target?.value?.toLowerCase())
        )
      )
    }
    setSearchArray([])
  }

  return (
    <Common.Box
      border={`1px solid ${dark60}`}
      borderRadius="9px"
      backgroundColor="white"
      color="#838383"
    >
      <Wrapper id="top-select">
        <StyledSelect
          onClick={(event) => {
            event.preventDefault()
            setShow((prev) => !prev)
          }}
        >
          <Common.Text> {i18n.translate('source-of-funds.select')}</Common.Text>
        </StyledSelect>
        <Arrow name="caret-down" show={show} />
      </Wrapper>

      <Forms.Field name="fundsCountryOrigin" mountPoint="formsv2">
        {() =>
          show ? (
            <Common.Box p="8px">
              <Common.Box ml="4px" mb="7px" fontWeight="700">
                {i18n.translate('source-of-funds.search-country')}
              </Common.Box>
              <InputField
                name="search"
                type="text"
                onChange={(e) => handleOnSearch(e)}
                label="Search"
                small
              />
              <Common.Box px="4px">
                {countryArray.map((name) => (
                  <Common.Box
                    display="inline-flex"
                    m="4px"
                    key={`countryArray-${name}`}
                    backgroundColor="#D9D9D9"
                    borderRadius="5px"
                    fontWeight="400"
                    fontSize="16px"
                    py="4px"
                    px="8px"
                  >
                    {name}
                    <RemoveButton
                      onClick={() => {
                        handleOnChange(false, name)
                      }}
                    >
                      <Icon name="close" />
                    </RemoveButton>
                  </Common.Box>
                ))}
              </Common.Box>
              {searchArray.length !== 0 ? (
                <Common.Box
                  mt="16px"
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridTemplateRows="auto"
                >
                  {searchArray.map((name) => (
                    <Common.Space key={`searchArray-${name}`} my="2px">
                      <Checkbox
                        label={name}
                        value={countryArray.includes(name)}
                        onChange={(value) => {
                          handleOnChange(value, name)
                        }}
                      />
                    </Common.Space>
                  ))}
                </Common.Box>
              ) : (
                <Common.Box px="4px">
                  {Object.keys(sortedCountries).map((group, index) => (
                    <>
                      <Common.Box
                        borderTop={index !== 0 && '1px solid #ECECEC'}
                        pt={index !== 0 && '10px'}
                        display="flex"
                        justifyContent="space-between"
                        color="#838383"
                        my="16px"
                        fontWeight="700"
                      >
                        <Common.Text letterSpacing=".3rem">{group}</Common.Text>
                        {index !== 0 ? (
                          <Common.Text
                            as="a"
                            href="#top-select"
                            textDecoration="underline"
                            cursor="pointer"
                          >
                            {i18n.translate('source-of-funds.back-to-top')}
                          </Common.Text>
                        ) : null}
                      </Common.Box>

                      <Common.Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gridTemplateRows="auto"
                      >
                        {sortedCountries[group].map((name) => (
                          <Common.Space
                            key={`fundsCountryOrigin-${name}`}
                            my="4px"
                          >
                            <Checkbox
                              label={name}
                              value={countryArray.includes(name)}
                              onChange={(value) => {
                                handleOnChange(value, name)
                              }}
                            />
                          </Common.Space>
                        ))}
                      </Common.Box>
                    </>
                  ))}
                </Common.Box>
              )}
            </Common.Box>
          ) : null
        }
      </Forms.Field>
    </Common.Box>
  )
}

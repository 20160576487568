import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

const CLEAR_POPUPS = 'casino-heroes/popups/CLEAR_POPUPS'
const DEQUEUE_POPUP = 'casino-heroes/popups/DEQUEUE_POPUP'
const QUEUE_POPUP = 'casino-heroes/popups/QUEUE_POPUP'
const REMOVE_POPUP = 'casino-heroes/popups/REMOVE_POPUP'

export function clearPopups() {
  return { type: CLEAR_POPUPS }
}

export function queuePopup(id) {
  return {
    type: QUEUE_POPUP,
    payload: id,
  }
}

export function dequeuePopup(id) {
  return {
    type: DEQUEUE_POPUP,
    payload: id,
  }
}

export function removePopup(id) {
  return {
    type: REMOVE_POPUP,
    payload: id,
  }
}

export function isCurrent(state, props) {
  return R.propEq(0, props.id, state.queue)
}

export function isRemoved(state, props) {
  return R.includes(props.id, state.removed)
}

const initialState = {
  queue: [],
  removed: [],
}

const queueLens = R.lensProp('queue')
const removedLens = R.lensProp('removed')

function reducer(state = initialState, action) {
  switch (action.type) {
    case QUEUE_POPUP: {
      const containsPopup = R.includes(action.payload)
      return R.unless(
        R.either(
          R.propSatisfies(containsPopup, 'removed'),
          R.propSatisfies(containsPopup, 'queue')
        ),
        R.over(queueLens, R.append(action.payload))
      )(state)
    }

    case DEQUEUE_POPUP: {
      return R.over(queueLens, R.reject(R.equals(action.payload)), state)
    }
    case websockets.SESSION_EXPIRED:
    case CLEAR_POPUPS: {
      return initialState
    }

    case REMOVE_POPUP: {
      return R.pipe(
        state => reducer(state, dequeuePopup(action.payload)),
        R.unless(
          R.propSatisfies(R.includes(action.payload), 'removed'),
          R.over(removedLens, R.append(action.payload))
        )
      )(state)
    }

    default:
      return state
  }
}

export default reducer

import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as websockets from '@rushplay/websockets'

import * as actions from '../../actions'
import * as selectors from '../../selectors'

const QUERY_NAME = 'live'

function fetchGames(type, liveCasinoChannel) {
  return api.fetchLiveCasinoTables({
    type,
    config: {
      success: response => {
        const ids = R.uniq(R.pluck('id', R.defaultTo([], response.value)))
        return [
          actions.storeGames(R.indexBy(R.prop('id'), response.value)),
          actions.replaceQueryResults({
            ids,
            name: QUERY_NAME,
            totalCount: R.length(ids),
          }),
          websockets.subscribe(liveCasinoChannel),
        ]
      },
      failure: () => {
        // TODO: errors when fetching should be handled by the fetching mechanism.
        // The thing here, the `failure` callback, is actually more akin to a
        // `catch`, i.e. "There was an error, what do you want to do about it?".
        return actions.storeGames(new Error('errors.http.fetch-failure'))
      },
      version: 1,
    },
  })
}

function mapStateToProps(state, ownProps) {
  const localState = R.prop(ownProps.mountPoint, state)
  const queryName = { queryName: QUERY_NAME }
  const games = ownProps.lobby
    ? selectors.getFilteredLobbyLiveGames(localState, queryName)
    : selectors.getFilteredGames(localState, queryName)

  return {
    games,
    gameType: selectors.getGameType(localState),
    ids: R.pluck('id', games),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const liveCasinoChannel = ownProps.liveCasinoChannel || 'private-live-casino'

  return bindActionCreators(
    {
      onClear: () => [
        actions.clearQueryResults(QUERY_NAME),
        websockets.unsubscribe(liveCasinoChannel),
      ],
      onFetch: () => fetchGames(ownProps.clientType, liveCasinoChannel),
    },
    dispatch
  )
}

export const connector = R.compose(
  recompose.setPropTypes({
    clientType: PropTypes.string,
    lobby: PropTypes.bool,
    mountPoint: PropTypes.string.isRequired,
    liveCasinoChannel: PropTypes.string,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

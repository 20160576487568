import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'
import { setItem } from 'redux-effects-localstorage'

import * as Notifications from '@rushplay/notifications'
import { Constraint, Flex, Space } from '@rushplay/common'
import {
  Field,
  FormPropTypes,
  actions as formActions,
  connectForm,
  selectors,
} from '@rushplay/legacy-forms'
import { fetchSession, updateMe as updatePlayer } from '@rushplay/api-client'
import { session } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import AvatarSelect from '../../common/avatar-select'
import Button from '../../common/button'
import Card from '../../common/card'
import Text from '../../common/text'
import { getAvatars } from '../../store/sign-up'
import { waitDashboard } from '../../store/app'

class EditAvatar extends Component {
  constructor() {
    super()
    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleClose() {
    this.props.redirectTo('/dashboard')
  }

  handleSubmit() {
    this.props.onSubmit({ avatar: this.props.fields.avatar.value })
  }

  render() {
    return (
      <div>
        <Constraint width="600" centered>
          <Space top={8} x="10px">
            <Card onRequestClose={this.handleClose}>
              <Space all={6}>
                <Space bottom={6}>
                  <Flex justifyContent="flex-end">
                    <Text align="center" bold size="20">
                      {this.props.translate('user-dashboard.pick-avatar')}
                    </Text>
                  </Flex>
                </Space>
                <Field
                  avatars={this.props.avatars}
                  initialValue={this.props.initialValue}
                  component={AvatarSelect}
                  name="avatar"
                  translate={this.props.translate}
                />
                <Space top="10px">
                  <Button
                    stretch
                    disabled={this.props.isFormPristine}
                    loading={this.props.isLoading}
                    onClick={this.handleSubmit}
                  >
                    {this.props.translate('user-dashboard.select')}
                  </Button>
                </Space>
              </Space>
            </Card>
          </Space>
        </Constraint>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.app.waitDashboard,
    initialValue: R.path(['player', 'avatar'], state.session),
    avatars: getAvatars(state.signUp),
    isFormPristine: selectors.isPristine(state.forms, {
      form: ownProps.form,
      field: 'avatar',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      redirectTo,

      onSubmit: userData => [
        waitDashboard(true),
        updatePlayer(userData, {
          success: () =>
            fetchSession({
              success: res => [
                setItem('AVATAR_URL', res.value.player.avatarImageUrl),
                redirectTo('/dashboard'),
                formActions.clearForm('edit-avatar'),
                waitDashboard(false),
                session.update(res.value),
              ],
              failure: error =>
                Notifications.add({ message: error.value, level: 'error' }),
              version: 1,
            }),
          failure: error => [
            waitDashboard(false),
            Notifications.add({ message: error.value, level: 'error' }),
          ],
          version: 1,
        }),
      ],
    },
    dispatch
  )
}

export default withTranslate(
  connectForm(connect(mapStateToProps, mapDispatchToProps)(EditAvatar), {
    name: 'edit-avatar',
  })
)

EditAvatar.propTypes = {
  avatars: PropTypes.array.isRequired,
  fields: PropTypes.shape({ avatar: FormPropTypes.field }),
  initialValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isFormPristine: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

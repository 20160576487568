import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

const Wrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  font-size: inherit;

  ${props => props.color && `color: ${props.color}`};
  ${props => props.size && `font-size: ${props.size}`};
  ${props => props.onClick && 'cursor: pointer'};
`

const SvgIcon = styled.svg`
  width: 1em;
  height: 1em;
`

function IconWrapper(props) {
  return (
    <Wrapper
      color={props.color}
      className={props.className}
      size={props.size}
      onClick={props.onClick}
    >
      <SvgIcon>{props.children}</SvgIcon>
    </Wrapper>
  )
}

IconWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
}

export { IconWrapper }

import styled from '@emotion/styled'

import { h2 } from '../../mixins'

export const Title = styled.h2`
  margin-bottom: 20px;
  ${h2};
`

export default Title

import * as R from 'ramda'
import { countries } from 'countries-list'

/**
 * @typedef {Object} Country
 * @prop {string} [name] - Country name
 */

/**
 * @param {Object.<Country>} - Countries
 * @returns {Array.<String>} - Array of country names
 */
export const getSortedCountryNames = R.pipe(
  R.values,
  R.pluck('name'),
  R.sort(R.comparator(R.lt))
)

export const countryNames = getSortedCountryNames(countries)

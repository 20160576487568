import React from 'react'

export class CssVariables extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.timer = null
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    this.timer = window.setInterval(() => {
      this.handleResize()
    }, 1000)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    this.timer = void window.clearInterval(this.timer)
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    // This is a fix for iOS Safari (and other mobile browsers that have
    // variable viewport height depending on browser UI visibility) as 100vh is
    // equal to maximum possible viewport height (without taking expanded
    // browser UI into account).
    //
    // It is initially introduced for mobile games rendered in iframe to avoid
    // them being covered by browser UI parts which happens if `vh` is used.
    this.setState({ '--window-inner-height': `${window.innerHeight}px` })
  }

  componentDidUpdate() {
    for (const property in this.state) {
      const value = document.documentElement.style.getPropertyValue(property)
      if (value !== this.state[property]) {
        document.documentElement.style.setProperty(
          property,
          this.state[property]
        )
      }
    }
  }

  render() {
    return null
  }
}

import styled from '@emotion/styled'

export const IconsOuterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  color: #7f7f7f;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1280px;
  justify-content: space-evenly;
  margin: auto;
  ${props =>
    props.spreadIcons &&
    `
    height: 210px;
    @media screen and (max-width: 500px) {
      font-size: 14px;
      height: 340px;
    }
    `};
`

export const IconsInnerWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  justify-content: space-between;
  height: 9em;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    height: 25em;
  }
`

export const DoubleIconsWrapper = styled.ul`
  width: ${props => (props.spreadIcons ? '40%' : '50%')};
  height: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 1279px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`

import * as R from 'ramda'

export function parseCreditCardNumber(value) {
  if (!value) {
    return value
  }

  return value.match(/(\d{1,4})/g).join(' ')
}

export function parseExpiryYear(v) {
  if (!v) {
    return v
  }

  const value = v.replace(/\s+/g, '').replace(/\D/gi, '')

  if (value.length >= 2) {
    return value.length === 3 ? R.last(value) : R.takeLast(2, value)
  } else {
    return value
  }
}

export function parseExpiryMonth(v) {
  if (!v) {
    return v
  }

  const value = v.replace(/\s+/g, '').replace(/\D/gi, '')

  if (value.length >= 2) {
    return R.takeLast(2, value)
  } else {
    return value
  }
}

export const parsers = {
  creditCard: parseCreditCardNumber,
  expiryYear: parseExpiryYear,
  expiryMonth: parseExpiryMonth,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Item = styled.li`
  box-sizing: border-box;

  /* actual styles */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: none;
  padding: 0.4vw;

  ${props =>
    ({
      grid: css`
        /* fit two whole thumbnails */
        width: 50%;

        @media screen and (min-width: 575px) {
          /* fit three whole thumbnails */
          width: 30.76923%;
        }

        @media screen and (min-width: 1025px) {
          /* fit six whole thumbnails */
          width: 16.6667%;
        }
      `,
      row: css`
        /* fit two whole thumbnails and a quarter */
        width: 44.44444%;

        @media screen and (min-width: 575px) {
          /* fit three whole thumbnails and a quarter */
          width: 30.76%;
        }

        @media screen and (min-width: 1025px) {
          /* fit six whole thumbnails */
          width: 16.6667%;
        }
      `,
    }[props.type])};
`

const List = styled.ul`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;

  /* actual styles */
  display: flex;
  width: 100%;

  ${props =>
    ({
      grid: css`flex-wrap: wrap;`,
      row: css`
        @media screen and (max-width: 1024px) {
          margin-bottom: -20px;
          padding-bottom: 20px;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        }
      `,
    }[props.type])};
`

const HideScrollbarsObscurely = styled.div`
  overflow: hidden;
  margin-top: -0.4vw;
  margin-bottom: -0.4vw;
`

const Wrapper = styled.div`
  margin-top: -0.4vw;
  margin-bottom: -0.4vw;
`

function TileList(props) {
  return (
    <Wrapper>
      <HideScrollbarsObscurely>
        <List type={props.type}>
          {React.Children.map(props.children, child => (
            <Item type={props.type}>{child}</Item>
          ))}
        </List>
      </HideScrollbarsObscurely>
    </Wrapper>
  )
}

TileList.defaultProps = {
  type: 'grid',
}

TileList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['grid', 'row']),
}

export default TileList

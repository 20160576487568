import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import parseMs from 'parse-ms'

import styled from '@emotion/styled'
import { Flex, Space } from '@rushplay/common'
import { connectForm } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import { baseFontSizeSmall, textFont } from '../constants/typography'

import LimitAmountInput from './limit-amount-input'

const Info = styled.p`
  font-family: ${textFont};
  line-height: 1.2;
  font-size: ${baseFontSizeSmall};
  font-weight: 300;
  text-align: center;
`

const Wrapper = styled(Flex)`
  flex-grow: 1;
`

class PlayerLimitForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()

    this.props.onSubmit(this.props.fields)
  }

  render() {
    const amount = this.props.fields.amount || {}
    return (
      <Wrapper column justifyContent="space-between">
        <Space top={7}>
          <LimitAmountInput
            currency={this.props.currency}
            initialValue={this.props.initialLimitAmount}
            maxAmount={this.props.maxAmount}
            minAmount={this.props.minAmount}
            name="amount"
            tooltip={amount.errors && R.head(amount.errors)}
            onAmountChange={(amount) => amount}
            required
          />
          {this.props.showInfoText ? (
            <Space top={5}>
              <Info>
                {this.props.translate('player-limits.edit-limit-info', {
                  cooldown: parseMs(this.props.cooldown).days,
                })}
              </Info>
            </Space>
          ) : null}
        </Space>
        {this.props.onSubmit && (
          <Space top={5}>
            <Button
              stretch
              onClick={this.handleSubmit}
              disabled={!this.props.valid}
            >
              {this.props.translate('player-limits.confirm')}
            </Button>
          </Space>
        )}
      </Wrapper>
    )
  }
}

PlayerLimitForm.defaultProps = {
  maxAmount: Number.MAX_SAFE_INTEGER,
  minAmount: 0,
}

PlayerLimitForm.propTypes = {
  cooldown: PropTypes.number,
  crossbrand: PropTypes.bool,
  currency: PropTypes.string,
  fields: PropTypes.object.isRequired,
  initialLimitAmount: PropTypes.number,
  isExistingTranslation: PropTypes.bool,
  maxAmount: PropTypes.number,
  minAmount: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  showInfoText: PropTypes.bool,
  softCap: PropTypes.number,
  target: PropTypes.string,
  translate: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default connectForm(withTranslate(PlayerLimitForm))

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import { Link } from '@rushplay/common'
import { fetchWorldRegions } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import * as lookup from '../../store/lookup'
import Button from '../../common/button'
import PopupContainer from '../../common/popup/popup-container'
import SizeObserver from '../../size-observer'
import adventure from '../../store/adventure'
import regions from '../../store/adventure/regions'
import worlds from '../../store/adventure/worlds'
import {
  PopupDescription,
  PopupDescriptionSmall,
  PopupImage,
  PopupImageShadow,
  PopupScrollable,
  PopupTitle,
} from '../../common/popup/popup-styles.js'
import { SeoTextArea } from '../seo-text-area'
import {
  getAssetPath,
  getClientType,
  isSportsbookEnabled,
  isUk,
} from '../../store/app'

import MapRegions from './map-regions'
import QuickGames from './quick-games'
import {
  CasinoImage,
  GamesWrapper,
  HowToPlayImage,
  HowToPlayWrapper,
  MapWrapper,
  SportsImage,
  SportsWrapper,
  WorldsImage,
  WorldsWrapper,
  Wrapper,
} from './region-container'
import { getRandomNumberFromOneTo } from './helpers'

class RegionLoggedOutContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { hiddenTreasureRandomTranslationNumber: 0, popupKey: '' }
    this.handleTreasureClick = this.handleTreasureClick.bind(this)
    this.handleTreasurePopupClose = this.handleTreasurePopupClose.bind(this)
    this.setActivePopup = this.setActivePopup.bind(this)
  }
  componentWillMount() {
    const currentWorldKey = 'treasure-island'

    this.props.fetchWorldRegions(currentWorldKey, {
      success: ({ value }) => {
        const regionsEntities = R.path(['result', 'entities', 'regions'], value)
        const regionsKeys = R.path(['result', 'result'], value)

        return [
          regions.actions.update(regionsEntities),
          worlds.actions.storeRegions(currentWorldKey, regionsKeys),
          worlds.actions.selectWorld(currentWorldKey),
        ]
      },
      failure: (res) =>
        Notifications.add({ message: res.value, level: 'error' }),
      version: 1,
      normalize: true,
      cacheFor: Infinity,
    })
  }
  componentDidMount() {
    /*
      This (alongside with <SizeObserver/>) is a poor hack to resolve Safari
      issue, when it doesn’t compute height for `inline-block` element, which
      contains map with AspectRatioSvg.

      The code below forces layout to be recomputed to trigger ResizeObserver
      update and finally set map height.

      Unfortunately, there’s no easy way to fix it properly, so it’s not a TODO,
      but an explanation of what is happening here.
    */
    window.setTimeout(() => {
      const resizeEvent = new Event('resize')
      window.dispatchEvent(resizeEvent)
    }, 1)
  }
  handleTreasureClick() {
    this.setState({
      hiddenTreasureRandomTranslationNumber: getRandomNumberFromOneTo(10),
    })
  }
  handleTreasurePopupClose() {
    this.setState({ hiddenTreasureRandomTranslationNumber: 0 })
  }
  setActivePopup(popupKey) {
    this.setState({ popupKey })
  }
  render() {
    return (
      <Wrapper>
        <SizeObserver
          getNextState={(rect) => ({ height: rect.height })}
          render={({ innerRef, state }) => (
            <MapWrapper innerRef={innerRef}>
              <MapRegions
                height={state.height}
                hideBossAvatar={this.props.hideBossAvatar}
                regions={this.props.regions}
                progress={0}
                playerAvatarSrc={`${this.props.assetPath}/avatars/images/knight-20170428123830.png`}
                treasureImage={this.props.translate(`region.treasure.default`)}
                onBossAvatarClick={() => this.setActivePopup('region-target')}
                onPlayerAvatarClick={() => this.props.redirectTo('/register')}
                onTreasureClick={this.handleTreasureClick}
              />
              <WorldsWrapper>
                <Link to="/worlds">
                  <WorldsImage
                    src={this.props.translate('region.icon.worlds')}
                  />
                </Link>
              </WorldsWrapper>
              <HowToPlayWrapper>
                <Link to="/how-to-play">
                  <HowToPlayImage
                    src={this.props.translate('region.icon.howtoplay')}
                  />
                </Link>
              </HowToPlayWrapper>
              {this.props.isSportsbookEnabled && (
                <SportsWrapper>
                  <Link to="/sports">
                    <SportsImage
                      src={this.props.translate('region.icon.sports')}
                    />
                  </Link>
                </SportsWrapper>
              )}
              <GamesWrapper>
                <Link to="/casino">
                  <CasinoImage
                    src={this.props.translate('region.icon.casino')}
                  />
                </Link>

                <QuickGames />
              </GamesWrapper>
            </MapWrapper>
          )}
        />
        <PopupContainer
          id="hidden-treasure"
          isOpen={this.state.hiddenTreasureRandomTranslationNumber > 0}
          onRequestClose={this.handleTreasurePopupClose}
        >
          <PopupImage
            src={this.props.translate('popup.hidden-treasure.image')}
          />
          <PopupTitle>
            {this.props.translate('popup.hidden-treasure.heading')}
          </PopupTitle>
          <PopupDescription
            html={{
              __html: this.props.translate(
                `popup.hidden-treasure.description-${this.state.hiddenTreasureRandomTranslationNumber}`
              ),
            }}
          />
        </PopupContainer>
        <PopupContainer
          id="region-target"
          isOpen={this.state.popupKey === 'region-target'}
          title={this.props.translate(this.props.bossKey)}
          onRequestClose={() => this.setActivePopup('')}
        >
          <PopupImageShadow src={this.props.bossAvatar} />
          <PopupDescription
            html={{
              __html: this.props.translate('popup.region-target.mission', {
                amount: 9,
              }),
            }}
          />
          <PopupDescriptionSmall>
            {this.props.translate('popup.region-target.fine-print')}
          </PopupDescriptionSmall>
          <Button stretch onClick={() => this.setActivePopup('boss-story')}>
            {this.props.translate('popup.region-target.boss-story')}
          </Button>
        </PopupContainer>
        <PopupContainer
          id="boss-story"
          isOpen={this.state.popupKey === 'boss-story'}
          title={this.props.translate(this.props.bossKey)}
          onRequestClose={() => this.setActivePopup('')}
        >
          <PopupScrollable>
            <PopupImageShadow src={this.props.bossAvatar} />
            <PopupDescription
              html={{
                __html: this.props.translate(
                  `popup.${this.props.bossKey}.story`
                ),
              }}
            />
          </PopupScrollable>
        </PopupContainer>
        <SeoTextArea translationKey="adventure-promo-page.seo-content" />
      </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  const regions = adventure.selectors.getRegions(state.adventure)
  return {
    assetPath: getAssetPath(state.app),
    bossAvatar: R.path([0, 'bossAvatar'], regions) || '',
    bossKey: R.path([0, 'bossKey'], regions) || '',
    clientType: getClientType(state.app),
    countryCode: lookup.getCountryCode(state.lookup),
    hideBossAvatar: isUk(state),
    isSportsbookEnabled: isSportsbookEnabled(state.app),
    regions,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorldRegions,
      redirectTo,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(RegionLoggedOutContainer))

RegionLoggedOutContainer.propTypes = {
  assetPath: PropTypes.string.isRequired,
  bossAvatar: PropTypes.string.isRequired,
  bossKey: PropTypes.string.isRequired,
  clientType: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  fetchWorldRegions: PropTypes.func.isRequired,
  hideBossAvatar: PropTypes.bool.isRequired,
  isSportsbookEnabled: PropTypes.bool,
  redirectTo: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'

class PlayNGoGameContainer extends Component {
  componentDidMount() {
    if (!this.props.scriptSrc) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error('PlayNGoGameContainer must have scriptSrc prop.')
      }
    }
  }

  render() {
    if (this.props.redirect) {
      window.location = this.props.scriptSrc
      return null
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Helmet>
          <script src={this.props.scriptSrc} />
        </Helmet>
        <div id="game-container" />
      </div>
    )
  }
}

PlayNGoGameContainer.propTypes = {
  redirect: PropTypes.bool,
  scriptSrc: PropTypes.string.isRequired,
}

export default PlayNGoGameContainer

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { truncate } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import InventoryTile from '../common/inventory-tile'
import PopupContainer from '../common/popup/popup-container'
import ProgressionContainer from '../common/progression'
import getDurationString from '../util/get-duration-string'
import { h4 } from '../mixins'

import Row from './row'
import StoreItem from './store-item'
import StoreItemDetails from './store-item-details'

const Content = styled.div`
  padding: 15px 20px 25px;
  max-width: 707px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  margin: 0 -10px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 45px;
    margin: 0 -10px -45px;
    overflow: auto;
  }
`

const StoreItemContainer = styled.div`padding: 10px;`

const Header = styled.header`
  margin-bottom: 20px;
  color: white;
  text-align: left;
`

const RowTitle = styled.h4`
  ${h4};
  text-transform: none;
`

const LargeTile = styled(InventoryTile)`
  width: 160px;
  height: 160px;
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
`

const Tile = styled(InventoryTile)`
  height: 100px;
  width: 100px;
`

// IMPORTANT: Controls the order of Store sections.
// Ordered from lowest value to highest
const ITEM_TYPES = [
  'feature-triggers',
  'freebet',
  'low-value',
  'medium-value',
  'high-value',
  'low-value-king-spins',
  'medium-value-king-spins',
  'king-spins',
  'pp-booster',
]

const ITEM_TYPE_TITLES = {
  'high-value': 'freespin.high-value.many',
  'low-value': 'freespin.low-value.many',
  'medium-value': 'freespin.medium-value.many',
  'low-value-king-spins': 'freespin.low-value-king-spins.many',
  'medium-value-king-spins': 'freespin.medium-value-king-spins.many',
  'king-spins': 'freespin.king-spins.many',
  'pp-booster': 'rubystore.pp-boosters',
  'feature-triggers': 'rubystore.bonus-games',
  freebet: 'rubystore.freebet',
}

const isBooster = R.propEq('type', 'PPBooster')

function getItemDescriptionKey(item) {
  const base = 'rubystore.item.description.'
  const name = R.defaultTo(R.toLower(item.name.replace(' ', '_')), item.gameKey)
  const isBonus = R.equals('featuretriggers', R.toLower(item.type))
    ? '.bonus-game'
    : ''
  const noWagerBonus =
    isBonus && R.prop('zeroWagering', item) ? '.no-wager' : ''

  return base + name + isBonus + noWagerBonus
}

function getItemAmount(item) {
  switch (item.type) {
    case 'FeatureTriggers':
      return item.amount
    case 'Freebet':
      return null
    default:
      return item.freespinsCount
  }
}

// eslint-disable-next-line react/no-multi-comp
class RubyStore extends Component {
  constructor() {
    super()
    this.handleItemBuy = this.handleItemBuy.bind(this)
  }

  handleItemBuy(blitz) {
    this.props.onItemBuy(this.props.selectedItem.id, blitz)
  }

  render() {
    return (
      <div>
        <ProgressionContainer />
        {this.props.isPopupOpen && (
          <PopupContainer
            isOpen={this.props.isPopupOpen}
            id="rubystore-item"
            onRequestClose={this.props.onPopupClose}
          >
            <StoreItemDetails
              blitzMode={this.props.selectedItem.blitz}
              description={this.props.translate(
                getItemDescriptionKey(this.props.selectedItem),
                {
                  gameName: this.props.selectedItem.name,
                  freespinsAmount: this.props.selectedItem.freespinsCount,
                  amount: this.props.selectedItem.amount,
                  betAmount: this.props.selectedItem.betAmountCents,
                }
              )}
              price={this.props.selectedItem.price}
              rubiesAmount={this.props.rubiesAmount}
              title={truncate(25, this.props.selectedItem.name)}
              onBuy={this.handleItemBuy}
              purchaseInProgress={this.props.purchaseInProgress}
            >
              <LargeTile
                amount={getItemAmount(this.props.selectedItem)}
                betAmountCents={this.props.selectedItem.betAmountCents}
                blitzMode={this.props.selectedItem.blitz}
                duration={
                  this.props.selectedItem &&
                  this.props.selectedItem.duration &&
                  getDurationString(
                    this.props.selectedItem.duration,
                    this.props.translate
                  )
                }
                enlarged
                discount={this.props.selectedItem.discount}
                image={
                  this.props.selectedItem.type === 'Freebet' ? (
                    `${this.props.assetPath}/inventory/freebet.jpg`
                  ) : (
                    this.props.selectedItem.gameImage
                  )
                }
                value={this.props.selectedItem.key}
                multiplier={this.props.selectedItem.multiplier}
                itemType={this.props.selectedItem.type}
                gradientBackground={
                  this.props.selectedItem.type === 'PPBooster'
                }
                itemLabel={
                  isBooster(this.props.selectedItem) ? (
                    this.props.translate('inventory.ppbooster.label')
                  ) : null
                }
                itemSubLabel={
                  isBooster(this.props.selectedItem) ? (
                    this.props.translate('inventory.ppbooster.sublabel')
                  ) : null
                }
              />
            </StoreItemDetails>
          </PopupContainer>
        )}
        {R.map(type => {
          return (
            <Row key={type} type={type}>
              <Content>
                <Header>
                  <RowTitle>
                    {this.props.translate(ITEM_TYPE_TITLES[type])}
                  </RowTitle>
                </Header>
                <Container>
                  {R.map(
                    item => (
                      <StoreItemContainer key={item.id}>
                        <StoreItem
                          id={item.id}
                          name={item.name}
                          price={item.price}
                          rubiesAmount={this.props.rubiesAmount}
                          onItemSelect={this.props.onSelect}
                        >
                          <Tile
                            amount={getItemAmount(item)}
                            betAmountCents={item.betAmountCents}
                            blitzMode={item.blitz}
                            discount={item.discount}
                            image={
                              item.type === 'Freebet' ? (
                                `${this.props.assetPath}/inventory/freebet.jpg`
                              ) : (
                                item.gameImage
                              )
                            }
                            value={item.key}
                            multiplier={item.multiplier}
                            duration={
                              item.duration &&
                              getDurationString(
                                item.duration,
                                this.props.translate
                              )
                            }
                            itemType={item.type}
                            gradientBackground={item.type === 'PPBooster'}
                            itemLabel={
                              isBooster(item) ? (
                                this.props.translate(
                                  'inventory.ppbooster.label'
                                )
                              ) : null
                            }
                            itemSubLabel={
                              isBooster(item) ? (
                                this.props.translate(
                                  'inventory.ppbooster.sublabel'
                                )
                              ) : null
                            }
                          />
                        </StoreItem>
                      </StoreItemContainer>
                    ),
                    this.props.rows[type]
                  )}
                </Container>
              </Content>
            </Row>
          )
        }, R.filter(type => R.has(type, this.props.rows), ITEM_TYPES))}
      </div>
    )
  }
}

RubyStore.propTypes = {
  assetPath: PropTypes.string,
  purchaseInProgress: PropTypes.bool,
  contentWrapper: PropTypes.node,
  isPopupOpen: PropTypes.bool,
  isSelected: PropTypes.func,
  rows: PropTypes.object,
  rubiesAmount: PropTypes.number,
  selectedItem: PropTypes.object,
  translate: PropTypes.func,
  onItemBuy: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default withTranslate(RubyStore)

import PropTypes from 'prop-types'
import React from 'react'

import LockPage from '../lock-page'
import { SetLimitsConfirmation } from '../../../limits/set-limits-confirmation'

export function HighDepositLimitPrompt(props) {
  return (
    <LockPage>
      <SetLimitsConfirmation onSubmit={props.onSubmit} info="excessive" />
    </LockPage>
  )
}

HighDepositLimitPrompt.propTypes = {
  onSubmit: PropTypes.func,
}

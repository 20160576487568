import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { Box, Flex, Link, Space, withCurrentTime } from '@rushplay/common'
import { fetchSessionStats } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import Button from '../common/button'
import CheckboxField from '../common/checkbox-field'
import HtmlContent from '../common/html-content'
import Line from '../common/line'
import PopupContainer from '../common/popup/popup-container'
import Text from '../common/text'
import {
  acknowledgeRealityCheck,
  clearSessionStats,
  getPendingRealityCheck,
  getRealityCheckPeriod,
  getSessionStats,
  updateSessionStats,
} from '../store/player'
import { getCurrentSessionTime } from '../store/session'

function RealityCheckPopupContainer(props) {
  const [acknowledged, setAcknowledged] = React.useState(false)

  React.useEffect(() => {
    if (props.isOpen) {
      props.onOpen()
    }
  }, [props.isOpen, props.onOpen])

  React.useEffect(() => {
    return () => {
      props.onClose()
      props.onAcknowledge()
    }
  }, [props.onClose, props.onAcknowledge])

  return (
    <PopupContainer
      id="reality-check"
      isOpen={props.isOpen}
      title={props.translate('reality-check.reminder')}
      parentNode={props.parentNode}
    >
      <Space bottom={6}>
        <HtmlContent
          html={{
            __html: props.translate('reality-check.playing-for', {
              time: props.period,
            }),
          }}
        />
      </Space>
      {props.sessionStats && (
        <React.Fragment>
          <Line />
          <Space top={6} bottom={6}>
            <Text bold align="center" size="1em">
              {props.translate('reality-check.session-stats')}
            </Text>
            <Space bottom="1em" />
            <Text lineHeight="1.4em">
              {props.translate(
                props.sessionStats.gamingBalanceCents < 0
                  ? 'reality-check.session-balance-negative'
                  : 'reality-check.session-balance-positive',
                {
                  balance: props.sessionStats.gamingBalanceCents,
                }
              )}
            </Text>
            {props.isSessionTimeVisible && (
              <Text lineHeight="1.4em">
                {props.translate('reality-check.session-logged-in', {
                  minutes: props.currentSessionTime,
                })}
              </Text>
            )}
          </Space>
        </React.Fragment>
      )}
      <Space bottom={6}>
        <CheckboxField
          label={props.translate('reality-check.acknowledge-checkbox.label')}
          value={acknowledged}
          onChange={() => setAcknowledged(!acknowledged)}
        />
      </Space>
      <Flex center column>
        <Button disabled={!acknowledged} stretch onClick={props.onAcknowledge}>
          {props.translate('reality-check.continue')}
        </Button>
        <Box paddingTop={6} width="100%">
          <Link onClick={props.onAcknowledge} to="/casino">
            <Button stretch>
              {props.translate('reality-check.leave-game')}
            </Button>
          </Link>
        </Box>
      </Flex>
      <Flex justifyContent="space-around">
        <Space top={6} bottom="10px">
          <Link onClick={props.onAcknowledge} to="/dashboard/history">
            {props.translate('reality-check.history')}
          </Link>
        </Space>
      </Flex>
    </PopupContainer>
  )
}

RealityCheckPopupContainer.defaultProps = { isOpen: false }

RealityCheckPopupContainer.propTypes = {
  currentSessionTime: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isSessionTimeVisible: PropTypes.bool,
  period: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  parentNode: PropTypes.object,
  sessionStats: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
  const isSessionTimeVisible = jurisdiction.getCurrentSessionTimeVisibility(
    state.jurisdiction
  )
  return {
    period: getRealityCheckPeriod(state.player) / (60 * 1000),
    isOpen: getPendingRealityCheck(state.player),
    sessionStats: getSessionStats(state.player),
    currentSessionTime: getCurrentSessionTime(state, ownProps),
    isSessionTimeVisible,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onAcknowledge: () => dispatch(acknowledgeRealityCheck()),
    onOpen: () =>
      dispatch(
        fetchSessionStats({
          success: ({ value }) => updateSessionStats(value),
          failure: () => {
            return
          },
          version: 2,
        })
      ),
    onClose: () => dispatch(clearSessionStats()),
  }
}

export default withCurrentTime(
  withTranslate(
    connect(mapStateToProps, mapDispatchToProps)(RealityCheckPopupContainer)
  )
)

import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ms from 'ms'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { removeItem } from 'redux-effects-localstorage'

import * as Notifications from '@rushplay/notifications'
import { getRealityCheckConfigurability } from '@rushplay/compliance/jurisdiction'
import { requestPlayerRealityCheckSettings } from '@rushplay/api-client'
import { selectors } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import NotFound from '../components/not-found'
import {
  getRealityCheckPeriod,
  setRealityCheckPeriod,
  updateTimeToRealityCheck,
} from '../store/player'

import RealityCheckForm from './reality-check-form'

function options(translate) {
  return [
    {
      value: R.toString(ms('60m')),
      label: translate('reality-check.minutes.n-minutes', { amount: 60 }),
    },
    {
      value: R.toString(ms('30m')),
      label: translate('reality-check.minutes.n-minutes', { amount: 30 }),
    },
    {
      value: R.toString(ms('15m')),
      label: translate('reality-check.minutes.n-minutes', { amount: 15 }),
    },
    {
      value: null,
      label: translate('reality-check.minutes.none'),
    },
  ]
}

class RealityCheckContainer extends Component {
  constructor(props) {
    super(props)
    this.handlePlayerSubmit = this.handlePlayerSubmit.bind(this)
  }

  handlePlayerSubmit() {
    this.props.removeItem('timeToRealityCheck')
    this.props.setRealityCheckPeriod(parseInt(this.props.period))
    this.props.requestPlayerRealityCheckSettings(this.props.period, {
      success: () =>
        Notifications.add({
          message: 'reality-check.update.success',
          level: 'success',
        }),
      failure: (error) =>
        Notifications.add({ message: error.value.errors, level: 'error' }),
      version: 2,
    })
    this.props.updateTimeToRealityCheck(this.props.period)
  }

  render() {
    return (
      <RealityCheckForm
        name="reality-check"
        options={options(this.props.translate)}
        onSubmit={this.handlePlayerSubmit}
        initialValue={
          this.props.currentValue ? this.props.currentValue.toString() : null
        }
        isDisabled={this.props.period === this.props.currentValue}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    configurable: getRealityCheckConfigurability(state.jurisdiction),
    currentValue: getRealityCheckPeriod(state.player),
    period: parseInt(
      selectors.value(state.forms, {
        form: 'reality-check',
        field: 'remind-me-in',
      })
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeItem,
      requestPlayerRealityCheckSettings,
      setRealityCheckPeriod,
      updateTimeToRealityCheck,
    },
    dispatch
  )
}

RealityCheckContainer.propTypes = {
  currentValue: PropTypes.number.isRequired,
  initialValue: PropTypes.string,
  period: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
  requestPlayerRealityCheckSettings: PropTypes.func.isRequired,
  setRealityCheckPeriod: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  updateTimeToRealityCheck: PropTypes.func.isRequired,
}

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate,
  recompose.branch(
    (props) => !props.configurable,
    recompose.renderComponent(NotFound)
  )
)(RealityCheckContainer)

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { replace as redirectTo } from 'react-router-redux'

import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

import ProgressionContainer from '../common/progression'
import logoSVG from '../images/logo-horizontal.svg'
import { Logo } from '../components/static/static'

import HelpTile from './help-tile'

const Wrapper = styled.div`
  max-width: 660px;
  margin: 20px auto 50px;
  text-align: center;
`

const Tutorials = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 20px;
  padding: 10px;

  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media screen and (min-width: 768px) {
    padding: 20px 40px 10px;
  }
`

function HowToPlayHomeContainer(props) {
  const tutorials = [
    'adventure-explained',
    'progress-in-the-adventure',
    'boss-fights',
    'collect-treasures',
    'ruby-store',
    'island-selector',
    'treasure-chest',
  ]

  return (
    <div>
      <ProgressionContainer />
      <Wrapper>
        <Logo alt={props.translate('casinoheroes')} src={logoSVG} />

        <Tutorials>
          {tutorials.map((tutorial) => {
            return (
              <HelpTile
                key={tutorial}
                title={props.translate(`how-to-play.${tutorial}`)}
                image={props.translate(`how-to-play.images.${tutorial}`)}
                onClick={() => props.redirectTo(`/how-to-play/${tutorial}`)}
              />
            )
          })}
        </Tutorials>
      </Wrapper>
    </div>
  )
}

export default connect(null, { redirectTo })(
  withTranslate(HowToPlayHomeContainer)
)

HowToPlayHomeContainer.propTypes = {
  brand: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

export const TimestampContext = React.createContext(0)

/**
 * Timestamp context provider. Must be present to make `<Timestamp>` component
 * work.
 *
 * @param {Object} props
 * @param {*} children
 * @param {number} [interval=1000] Context update interval in milliseconds
 * @return {ReactComponent}
 * @example
 * <TimestampProvider interval="5000">
 *   <App />
 * </Timestamp>
 */
export class TimestampProvider extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { timestamp: Date.now() }
  }

  componentDidMount() {
    this.timerId = window.setInterval(
      () => this.setState({ timestamp: Date.now() }),
      parseInt(this.props.interval, 10)
    )
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId)
  }

  render() {
    return (
      <TimestampContext.Provider value={this.state.timestamp}>
        {this.props.children}
      </TimestampContext.Provider>
    )
  }
}

TimestampProvider.defaultProps = {
  interval: 1000,
}

TimestampProvider.propTypes = {
  children: PropTypes.node,
  interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

/**
 * Timestamp context consumer. Requires `<TimestampProvider>` to work.
 *
 * @name Timestamp
 * @param {Object} props
 * @param {*} children
 * @param {number} [interval=1000] Children rerender interval in milliseconds
 * @return {ReactComponent}
 * @example
 * <Timestamp interval="60000">
 *   {timestamp => <span>Updates every minute: {timestamp}</span>}
 * </Timestamp>
 */
export function Timestamp(props) {
  const interval = parseInt(props.interval, 10)
  return (
    <TimestampContext.Consumer>
      {timestamp => props.children(Math.floor(timestamp / interval) * interval)}
    </TimestampContext.Consumer>
  )
}

Timestamp.defaultProps = {
  interval: 1000,
}

Timestamp.propTypes = {
  children: PropTypes.func,
  interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

/**
 * Higher-order component to inject timestamp into wrapped component. Requires `<TimestampProvider>` to work.
 * @param {object} [options]
 * @param {number|Function} [options.interval=1000] Minimum interval to wait before updating
 * @param {ReactComponent} Component
 * @returns {ReactComponent} Wrapped component with `timestamp` prop injected
 */
export function withTimestamp(options = {}, Component) {
  if (typeof Component === 'undefined') {
    return Component => withTimestamp(options, Component)
  }

  // eslint-disable-next-line react/display-name
  return props => (
    <Timestamp interval={options.interval}>
      {timestamp => <Component timestamp={timestamp} {...props} />}
    </Timestamp>
  )
}

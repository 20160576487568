import * as R from 'ramda'
/* eslint-disable react/no-set-state */
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { toAmount } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { currencies } from '../common/amount'
import { headingFont } from '../constants/typography'

const mapIndexed = R.addIndex(R.map)
const TRACK_HEIGHT = 0.75
const NODE_SIZE = 2
const ARROW_LENGTH = 0.5

const Wrapper = styled.div`
  padding-top: 14px;
  user-select: none;
  position: relative;
  width: 300px;
  @media (max-width: 1120px) {
    width: 100%;
    top: unset;
    padding-bottom: 6px;
  }
`

const BetRangeText = styled.div`
  height: 100%;
  font-size: 14px;
  font-family: ${headingFont};
  letter-spacing: 1px;
  text-align: center;
`

const TrackOuter = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: #a3a3a3;
  display: flex;
  justify-content: center;
  height: ${TRACK_HEIGHT}em;
`

const Track = styled.div`
  width: 80%;
  position: relative;
`

const RangeTrack = styled.div`
  position: absolute;
  left: ${(props) => props.start}%;
  right: ${(props) => 100 - props.end}%;
  height: ${TRACK_HEIGHT}em;
  border-radius: 5px;
  background-color: #03c7e9;
`
const NodeWrapper = styled.div`
  position: absolute;
  top: ${(TRACK_HEIGHT - NODE_SIZE) / 2}em;
  left: calc(${(props) => props.rangePositionPercentage}% - ${NODE_SIZE}em / 2);
`

const Node = styled.div`
  width: ${NODE_SIZE}em;
  height: ${NODE_SIZE}em;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`

const RangeValueWrapper = styled.span`
  position: absolute;
  left: 50%;
  padding: 4px 8px;
  min-width: 40px;
  border-radius: 11px;
  background-color: #21498e;
  text-align: center;
  transform: translateX(-50%) translateY(calc(-100% - ${ARROW_LENGTH}em));
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    bottom: calc(-${ARROW_LENGTH}em + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-width: ${ARROW_LENGTH}em 5px 0;
    border-style: solid;
    border-color: #21498e transparent;
    width: 0;
  }
`

class RangeSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: null,
      max: R.length(props.stepValues) - 1,
      min: 0,
      steps: R.length(props.stepValues),
      trackStartX: null,
      // Temporary fallback added to ensure that left start position is not undefined
      valuesIndex: R.map(
        (v) =>
          v === Infinity
            ? props.stepValues.length - 1
            : R.indexOf(v, props.stepValues),
        props.values
      ) || [0, 9],
    }
    this.handleStart = this.handleStart.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleEnd = this.handleEnd.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      valuesIndex: R.map(
        (v) =>
          v === Infinity
            ? nextProps.stepValues.length - 1
            : R.indexOf(v, nextProps.stepValues),
        nextProps.values
      ),
    })
  }

  handleStart(index) {
    this.setState({
      activeIndex: index,
      trackStartX: this.trackNode
        ? this.trackNode.getBoundingClientRect().left
        : null,
    })
    window.addEventListener('mousemove', this.handleMove)
    window.addEventListener('touchmove', this.handleMove)
    window.addEventListener('mouseup', this.handleEnd)
    window.addEventListener('touchend', this.handleEnd)
  }

  handleMove(event) {
    const pageX =
      event.pageX || (event.targetTouches && event.targetTouches[0].pageX)
    const positionDelta =
      ((pageX - this.state.trackStartX) / this.trackNode.offsetWidth) * 100
    const position =
      (positionDelta / 100) * (this.state.max - this.state.min) + this.state.min

    // round the position depending on amount of steps
    const value =
      (Math.round((position / this.state.steps) * 10) * this.state.steps) / 10

    if (
      (this.state.activeIndex === 0 &&
        value < this.state.valuesIndex[this.state.activeIndex + 1] &&
        value >= this.state.min) ||
      (this.state.activeIndex > 0 &&
        value > this.state.valuesIndex[this.state.activeIndex - 1] &&
        value <= this.state.max)
    ) {
      const updatedValue = R.update(
        this.state.activeIndex,
        value,
        this.state.valuesIndex
      )
      this.setState({ valuesIndex: updatedValue })
    }
  }

  handleEnd() {
    window.removeEventListener('mousemove', this.handleMove)
    window.removeEventListener('touchmove', this.handleMove)
    window.removeEventListener('mouseup', this.handleEnd)
    window.removeEventListener('touchend', this.handleEnd)
    const firstValue = this.props.stepValues[R.head(this.state.valuesIndex)]
    const secondValue = this.props.stepValues[R.last(this.state.valuesIndex)]
    this.props.onChange([
      firstValue,
      secondValue !== R.last(this.props.stepValues) ? secondValue : Infinity,
    ])
  }

  get positions() {
    return R.map(
      (value) =>
        ((value - this.state.min) / (this.state.max - this.state.min)) * 100,
      this.state.valuesIndex
    )
  }

  get labels() {
    return R.map((value) => {
      const stepValueCents =
        this.props.stepValues[value] > 0
          ? this.props.stepValues[value] * 100
          : 0
      const convertedValue = toAmount(
        {
          currency: currencies[this.props.currency],
          locale: this.props.locale,
        },
        stepValueCents
      )
      return `${convertedValue}${value >= this.state.max ? '+' : ''}`
    }, this.state.valuesIndex)
  }

  render() {
    return (
      <Wrapper>
        <BetRangeText>
          {this.props.translate('live-casino.bet-range')}
        </BetRangeText>
        <TrackOuter>
          <Track
            ref={(node) => {
              this.trackNode = node
            }}
          >
            <RangeTrack
              end={this.positions[R.length(this.state.valuesIndex) - 1]}
              start={this.positions[0]}
            />
            {mapIndexed((value, index) => {
              return (
                <NodeWrapper
                  key={index}
                  rangePositionPercentage={this.positions[index]}
                >
                  <RangeValueWrapper>{this.labels[index]}</RangeValueWrapper>
                  <Node
                    onMouseDown={() => this.handleStart(index)}
                    onTouchStart={() => this.handleStart(index)}
                  />
                </NodeWrapper>
              )
            }, this.state.valuesIndex)}
          </Track>
        </TrackOuter>
      </Wrapper>
    )
  }
}

export default withTranslate(RangeSlider)

RangeSlider.propTypes = {
  currency: PropTypes.string.isRequired,
  locale: PropTypes.string,
  stepValues: PropTypes.array.isRequired,
  translate: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
}
/* eslint-enable react/no-set-state */

import PropTypes from 'prop-types'
import React from 'react'

import { Space } from '@rushplay/common'

import Button from '../../../common/button'
import HtmlContent from '../../../common/html-content'
import LockPage from '../lock-page'
import Text from '../../../common/text'

export class SourceOfWealth extends React.PureComponent {
  render() {
    return (
      <LockPage>
        <HtmlContent
          html={{
            __html: this.props.translate('source-of-wealth.full-message'),
          }}
        />

        <Text align="center">
          <Space top={8}>
            {this.props.isDismissable ? (
              <Button onClick={this.props.onDismiss}>
                {this.props.translate('ok')}
              </Button>
            ) : (
              <Text bold align="center" color="red" size="18">
                {this.props.translate('source-of-wealth.undismissable-notice')}
              </Text>
            )}
          </Space>
        </Text>
      </LockPage>
    )
  }
}

SourceOfWealth.propTypes = {
  onDismiss: PropTypes.func,
  isDismissable: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { noneOf } from './none-of'

const Wrapper = styled('div', { shouldForwardProp: noneOf(['as', 'ratio']) })`
  padding-top: ${props => props.ratio * 100}%;
  position: relative;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

/**
 * Render children in a wrapper with fixed aspect ratio
 * @param {Object} props
 * @param {number} props.ratio Target ratio; may be an expression, e.g. `4/3`
 * @returns {ReactComponent}
 * @example
 * <AspectRatio ratio={16/9}>
 *   <Icon name="preview" />
 * </AspectRatio>
 */
export function AspectRatio(props) {
  return (
    <Wrapper ratio={props.ratio}>
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

AspectRatio.propTypes = {
  children: PropTypes.node,
  ratio: PropTypes.number.isRequired,
}

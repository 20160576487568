import * as R from 'ramda'
import { connect } from 'react-redux'

import { acceptPrivacyPolicy } from '@rushplay/api-client'
import { withTranslate } from '@rushplay/i18n'

import { restoreSession } from '../store/app'

function mapDispatchToProps(dispatch) {
  return {
    onAccept: () =>
      dispatch(
        acceptPrivacyPolicy({
          success: () => restoreSession(true),
          version: 1,
        })
      ),
  }
}

export const connector = R.compose(
  connect(null, mapDispatchToProps),
  withTranslate
)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { fetchWorlds, updatePlayerWorlds } from '@rushplay/api-client'
import { getUserAvatar, isSessionActive } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as processIds from '../../constants/processes'
import Button from '../../common/button'
import PopupContainer from '../../common/popup/popup-container'
import ProgressionContainer from '../../common/progression'
import worlds from '../../store/adventure/worlds/index'
import { PageSpinner } from '../../common/spinner'
import { PopupDescription, PopupImage } from '../../common/popup/popup-styles'
import { isWaiting, startWaiting, stopWaiting } from '../../store/app'

import WorldPanel from './world-panel'

const Worlds = styled.article`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  max-width: 765px;
  margin: 10px auto;

  @media screen and (max-width: 785px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

class WorldsContainer extends Component {
  constructor(props) {
    super(props)

    this.handleSelectWorld = this.handleSelectWorld.bind(this)
  }

  componentWillMount() {
    this.props.startWaiting(processIds.FETCH_WORLDS)
    const config = {
      success: res => {
        const worldsEntities = R.path(
          ['value', 'result', 'entities', 'worlds'],
          res
        )
        const worldsKeys = R.path(['value', 'result', 'result'], res)
        const currentWorldKey = R.pipe(
          R.filter(R.propEq('current', true)),
          R.keys,
          R.head
        )(worldsEntities)

        return [
          worlds.actions.selectWorld(currentWorldKey),
          worlds.actions.updateStats(worldsEntities),
          worlds.actions.updateImages(worldsEntities),
          worlds.actions.updateDetails(worldsEntities),
          worlds.actions.storeWorlds(worldsKeys),
          stopWaiting(processIds.FETCH_WORLDS),
        ]
      },
      failure: res => {
        this.props.stopWaiting(processIds.FETCH_WORLDS)
        return Notifications.add({ message: res.value, level: 'error' })
      },
      version: 1,
      normalize: true,
    }

    this.props.fetchWorlds(config)
  }

  handleSelectWorld(worldKey) {
    if (!this.props.authenticated) {
      this.props.redirectTo('/register')
      return
    }

    if (this.props.currentWorldKey === worldKey) {
      this.props.redirectTo('/adventure')
      return
    }

    if (!this.props.isSelectingWorld) {
      this.props.startWaiting(processIds.SELECT_WORLD)
      this.props.onWorldUpdate(worldKey)
    }
  }

  render() {
    if (this.props.isFetchingWorlds) {
      return <PageSpinner />
    }

    const isViewedWorldLocked = R.path(
      ['locked'],
      R.find(R.propEq('key', this.props.viewedWorld), this.props.worlds)
    )

    return (
      <div>
        <ProgressionContainer />
        <Worlds>
          {R.map(world => {
            return (
              <WorldPanel
                authenticated={this.props.authenticated}
                background={world.backgroundUrl}
                current={R.equals(world.key, this.props.currentWorldKey)}
                completed={world.completed}
                difficulty={world.difficulty}
                passedRegionsCount={world.passedRegionsCount}
                totalRegionsCount={world.totalRegionsCount}
                foundTreasuresCount={world.foundTreasuresCount}
                totalTreasuresCount={world.totalTreasuresCount}
                key={world.key}
                locked={world.locked}
                loadingWorld={this.props.isSelectingWorld}
                userAvatar={this.props.userAvatar}
                worldGoal={world.prizeImageUrl}
                worldIcon={this.props.translate(
                  `worlds.${world.key}.world-icon`
                )}
                worldKey={world.key}
                worldProgress={Math.min(world.worldProgressPercent, 100)}
                onDetailsViewing={() =>
                  this.props.redirectTo(`/worlds/${world.key}`)}
                onStartPlaying={() => this.handleSelectWorld(world.key)}
              />
            )
          }, this.props.worlds)}

          <PopupContainer
            id="world-details"
            isOpen={Boolean(this.props.viewedWorld)}
            title={this.props.translate(
              `worlds.${this.props.viewedWorld}.quote`
            )}
            onRequestClose={() => this.props.redirectTo('/worlds')}
          >
            <PopupImage
              src={this.props.translate(
                `worlds.${this.props.viewedWorld}.world-icon`
              )}
            />
            <PopupDescription
              html={{
                __html: this.props.translate(
                  `worlds.${this.props.viewedWorld}.description`
                ),
              }}
            />
            {isViewedWorldLocked && (
              <PopupDescription
                html={{
                  __html: this.props.translate(
                    `worlds.${this.props.viewedWorld}.locked`
                  ),
                }}
              />
            )}
            <Button
              disabled={isViewedWorldLocked}
              onClick={() => this.handleSelectWorld(this.props.viewedWorld)}
            >
              {this.props.translate(
                this.props.authenticated
                  ? 'worlds.start-playing'
                  : 'worlds.register'
              )}
            </Button>
          </PopupContainer>
        </Worlds>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    worlds: worlds.selectors.getWorlds(state.adventure.worlds),
    viewedWorld: props.params.worldKey,
    authenticated: isSessionActive(state.session),
    userAvatar: getUserAvatar(state.session),
    currentWorldKey: worlds.selectors.getCurrentWorld(state.adventure.worlds)
      .key,
    isSelectingWorld: isWaiting(state.app, processIds.SELECT_WORLD),
    isFetchingWorlds: isWaiting(state.app, processIds.FETCH_WORLDS),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onWorldUpdate: key =>
        updatePlayerWorlds(key, {
          success: () => [
            stopWaiting(processIds.SELECT_WORLD),
            worlds.actions.selectWorld(key),
            redirectTo('/adventure'),
          ],
          failure: res => [
            stopWaiting(processIds.SELECT_WORLD),
            Notifications.add({ message: res.value, level: 'error' }),
          ],
          version: 1,
        }),
      fetchWorlds,
      redirectTo,
      startWaiting,
      stopWaiting,
    },
    dispatch
  )
}

WorldsContainer.defaultProps = { viewedWorld: '' }

WorldsContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  worlds: PropTypes.array.isRequired,
  viewedWorld: PropTypes.string.isRequired,
  fetchWorlds: PropTypes.func.isRequired,
  userAvatar: PropTypes.string,
  onWorldUpdate: PropTypes.func.isRequired,
  currentWorldKey: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isSelectingWorld: PropTypes.bool,
  isFetchingWorlds: PropTypes.bool.isRequired,
  startWaiting: PropTypes.func.isRequired,
  stopWaiting: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslate(WorldsContainer)
)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Image } from '@rushplay/common'
import { keyframes } from '@emotion/core'
import { withTranslate } from '@rushplay/i18n'

import Avatar from '../../common/avatar'
import Icon from '../../common/icon/icon'
import Line from '../../common/line'
import { headingFont } from '../../constants/typography'

import { berry } from './../../constants/colors'

const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`

const Wrapper = styled.div`position: relative;`

const LeaderBoard = styled.ul`
  list-style: none;
  max-width: 160px;
  padding: 4px;
  border-radius: 4px;
  background-color: #004578;
  margin: 0;
`

const TopThree = styled.li`
  color: #543a17;
  text-shadow: 0 1px rgba(255, 255, 255, 0.4);
  min-width: 100px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(#eacd82, #ddb64c);
  position: relative;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`

const BoardRank = styled.div`
  text-align: center;
  line-height: 30px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: -10px;
  left: -15px;
  color: #cc2789;
`

const Certificate = styled(Icon)`
  font-size: 32px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  fill: currentColor;
  height: 28px;
`

const Number = styled.div`
  color: #fff;
  font-family: ${headingFont};
  font-size: 14px;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  width: 27px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
`

const Price = styled.div`
  font-family: ${headingFont};
  color: #543a17;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: center;
`

const Refresh = styled(Icon)`
  margin-right: -2px;
  margin-left: 6px;
  width: 14px;
`

const TopThreeLine = styled(Line)`margin: 2px 4px;`

const Username = styled.div`
  ${props => props.highlight && `color: ${berry}`};
  font-family: ${headingFont};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
`

const Meters = styled.div`
  width: 16px;
  height: 25.5vh;
  background-color: rgba(15, 55, 83, 0.9);
  padding-bottom: 4px;
  margin-left: 19px;
  @media only screen and (min-width: 321px) and (max-width: 575px) {
    height: 39vh;
  }
`

const SmallWinners = styled.div`
  height: 30%;
  border-bottom: 2px solid #ddb64c;
  background-color: rgba(221, 182, 76, 0.13);
  box-shadow: inset 0 0 1px 0 rgba(221, 182, 76, 0.4);
`

const NotWinners = styled.div`
  height: 70%;
  border-bottom: 2px solid #999;
  box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.4);
`

const DecorationLine = styled.div`
  height: 100%;
  border-left: 2px dashed ${props => (props.notWinners ? 'grey' : '#DDB64C')};
  margin-left: 7px;
`

const AvatarWrapper = styled.div`
  position: relative;
  bottom: ${props => props.progressPercentage}%;
`

const RankIndicator = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid lightgrey;
  border-bottom: 15px solid transparent;
  position: absolute;
  top: 22%;
  left: -16.6%;
  z-index: -1;
`

const Visuals = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 27px;
  top: -25px;
  z-index: 1;
`

const RewardIndicator = styled.div`
  padding: 5px;
  width: 38px;
  height: 38px;
  margin-top: -50px;
  margin-left: 49px;
`

const Rank = styled.div`
  color: white;
  font-family: ${headingFont};
  font-size: 14px;
  line-height: 24px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  min-width: 24px;
  height: 24px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 20px;
  background-color: ${berry};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: -9px;
  left: 35px;
  animation: ${pulse} 2s linear infinite;
`

const Start = styled.div`
  color: rgba(208, 209, 211, 0.9);
  font-family: ${headingFont};
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(15, 55, 83, 0.9);
  float: left;
  z-index: -2;
`

const mapIndexed = R.addIndex(R.map)

export function calculateProgress(
  currentRank,
  numberOfParticipants,
  numberOfWinners,
  numberOfTopWinners
) {
  if (!currentRank || currentRank <= numberOfTopWinners) {
    return 0
  }

  const showLowWinners =
    currentRank <= numberOfWinners && currentRank > numberOfTopWinners

  if (showLowWinners) {
    return (
      (1 -
        (currentRank - numberOfTopWinners) /
          (numberOfWinners - numberOfTopWinners)) *
      100
    )
  }
  return (
    (1 -
      (currentRank - numberOfWinners) /
        (numberOfParticipants - numberOfWinners)) *
    100
  )
}

function BigSpinsProgress(props) {
  const winnerAmounts = R.compose(
    R.pluck('amount'),
    R.take(props.maxPlayers),
    R.sort(R.descend(R.prop('amount')))
  )(props.rewards)

  return (
    <Wrapper>
      <LeaderBoard>
        {mapIndexed((player, index) => {
          return (
            <TopThree key={player}>
              <BoardRank>
                <Certificate name="certificate" size="big" />
                <Number>{index + 1}</Number>
              </BoardRank>
              <Price>
                {winnerAmounts[index]}
                <Refresh name="refresh" />
              </Price>
              <TopThreeLine color="rgba(0, 0, 0, .1)" />
              <Username highlight={props.playerRank === index + 1}>
                {player[0]}
              </Username>
            </TopThree>
          )
        }, props.topWinners)}
        {R.times(index => {
          return (
            <TopThree key={index}>
              <BoardRank>
                <Certificate name="certificate" size="big" />
                <Number>{index + props.topWinners.length + 1}</Number>
              </BoardRank>
              <Price>
                {winnerAmounts[index + props.topWinners.length]}
                <Refresh name="refresh" />
              </Price>
            </TopThree>
          )
        }, R.max(props.maxPlayers - props.topWinners.length, 0))}
      </LeaderBoard>
      <Meters>
        <SmallWinners>
          <DecorationLine />
          {props.playerRank <= props.winnerCount &&
          props.playerRank > props.maxPlayers && (
            <AvatarWrapper
              progressPercentage={calculateProgress(
                props.playerRank,
                props.playerCount,
                props.winnerCount,
                props.topWinners.length
              )}
            >
              <Visuals>
                <Avatar src={props.avatarImg} />
                {props.rewardImg &&
                props.playerRank <= props.winnerCount && (
                  <RewardIndicator>
                    <Image
                      src={props.rewardImg}
                      size="full"
                      alt="Reward Indicator"
                    />
                  </RewardIndicator>
                )}
                <RankIndicator />
                <Rank>{props.playerRank}</Rank>
              </Visuals>
            </AvatarWrapper>
          )}
        </SmallWinners>
        <NotWinners>
          <DecorationLine notWinners="true" />
          {props.playerRank > props.winnerCount && (
            <AvatarWrapper
              progressPercentage={calculateProgress(
                props.playerRank,
                props.playerCount,
                props.winnerCount,
                props.topWinners.length
              )}
            >
              <Visuals>
                <Avatar src={props.avatarImg} />
                <RankIndicator />
                <Rank>{props.playerRank}</Rank>
              </Visuals>
            </AvatarWrapper>
          )}
        </NotWinners>
      </Meters>
      <Start>{props.translate('campaigns-bigspins-start')}</Start>
    </Wrapper>
  )
}

BigSpinsProgress.propTypes = {
  maxPlayers: PropTypes.number.isRequired,
  playerRank: PropTypes.number,
  avatarImg: PropTypes.string.isRequired,
  rewardImg: PropTypes.string.isRequired,
  rewards: PropTypes.arrayOf(PropTypes.object).isRequired,
  topWinners: PropTypes.arrayOf(PropTypes.string).isRequired,
  winnerCount: PropTypes.number.isRequired,
  playerCount: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
}

export default withTranslate(BigSpinsProgress)

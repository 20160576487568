import * as R from 'ramda'
import { connect } from 'react-redux'

import * as selectors from '../selectors'

import { Notifications as Component } from './notifications'

function mapStateToProps(state, props) {
  const notificationsState = R.prop(props.mountPoint, state)

  return {
    notifications: selectors.getAll(notificationsState),
  }
}

export const Notifications = connect(mapStateToProps)(Component)

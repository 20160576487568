import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import styled from '@emotion/styled'
import { AspectRatio } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { gradientMetallic, gradientOrange } from '../constants/colors'

const AvatarBorder = styled.div`
  padding: 6%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  background-image: ${props =>
    props.promotion ? gradientOrange : gradientMetallic};
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
`

function Avatar(props) {
  return (
    <AvatarBorder promotion={props.promotion}>
      <AspectRatio ratio={1}>
        <AvatarImage src={props.src} />
      </AspectRatio>
    </AvatarBorder>
  )
}

Avatar.propTypes = {
  currentWorldKey: PropTypes.string,
  promotion: PropTypes.bool,
  src: PropTypes.string.isRequired,
  translate: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    currentWorldKey: R.path(['adventure', 'worlds', 'currentWorldKey'], state),
  }
}

export default connect(mapStateToProps)(withTranslate(Avatar))

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styled from '@emotion/styled'

const ScrollableWrapper = styled.div`
  max-height: ${props => props.maxHeight}px;
  overflow-y: auto;
`

class Scrollable extends Component {
  componentWillUpdate(nextProps) {
    if (!R.equals(this.props.children, nextProps.children)) {
      this.shouldScrollToTop = true
    }
  }

  componentDidUpdate() {
    if (this.shouldScrollToTop) {
      this.shouldScrollToTop = false
      this.node.scrollTop = 0 // this.node is a React DOM ref
    }
  }

  render() {
    return (
      <ScrollableWrapper
        maxHeight={this.props.maxHeight}
        innerRef={node => (this.node = node)}
      >
        {this.props.children}
      </ScrollableWrapper>
    )
  }
}

Scrollable.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  maxHeight: PropTypes.number.isRequired,
}

export default Scrollable

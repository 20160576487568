import styled from '@emotion/styled'

export default styled.section`
  position: relative;
  background-color: white;

  &:before {
    pointer-events: none;
    content: '';
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpath d='M0 0s25 100 50 100S100 0 100 0v100H0z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: bottom center;
    background-size: 300% 100%;
    position: absolute;
    top: -199px;
    left: 0;
    width: 100%;
    height: 200px;

    @media (min-width: 600px) {
      background-size: 160% 100%;
    }
  }
`

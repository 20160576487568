import * as ReactRedux from 'react-redux'
import * as ReactRouterRedux from 'react-router-redux'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as App from '../store/app'

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  /* Hack to prevent their light background */
  > div {
    background-color: transparent !important;
  }
`

function getBetbyBrandId(state) {
  if (App.getBrand(state) === 'casitabi') {
    return App.isProduction(state)
      ? '1965799835932168192'
      : '1955983663300743168'
  }

  return ''
}

function getBetbyScriptUrl(state) {
  if (App.getBrand(state) === 'casitabi') {
    return App.isProduction(state)
      ? 'https://casitabi.sptpub.com/bt-renderer.min.js'
      : 'https://ui.invisiblesport.com/bt-renderer.min.js'
  }

  return ''
}

function usePreviousValue(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function LandingPageSportsArea() {
  const ref = React.useRef(null)
  const [shouldInitApp, setShouldInitApp] = React.useState(false)
  const [btRendererInited, setBtRendererInited] = React.useState(false)
  const dispatch = ReactRedux.useDispatch()

  const language = ReactRedux.useSelector((state) => App.getLanguage(state.app))
  const prevLanguage = usePreviousValue(language)
  const brandId = ReactRedux.useSelector((state) => getBetbyBrandId(state.app))
  const scriptUrl = ReactRedux.useSelector((state) =>
    getBetbyScriptUrl(state.app)
  )

  // Makes sure client is available on window (script loaded properly)
  React.useEffect(() => {
    if (!btRendererInited) {
      const timerId = window.setInterval(() => {
        setBtRendererInited(typeof window.BTRenderer !== 'undefined')
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [btRendererInited])

  const client = React.useMemo(() => {
    if (process.browser && btRendererInited) {
      return new window.BTRenderer()
    }
  }, [process.browser, btRendererInited])

  // Initiates client
  React.useEffect(() => {
    if (shouldInitApp) {
      setShouldInitApp(false)

      client.initialize({
        brand_id: brandId,
        key: '',
        themeName: 'casitabi',
        lang: language,
        target: document.querySelector('#betby_widget'),
        minFrameHeight: 204,
        widgetName: 'promo',
        cssUrls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],
        widgetParams: {
          placeholder: 'operator_page',
          onBannerClick: ({ url }) =>
            dispatch(ReactRouterRedux.push(`/sports?bt-path=${url}`)),
          withSportsList: false,
        },
        onSessionRefresh: () => window.location.reload(),
        goToClick: ({ url }) =>
          dispatch(ReactRouterRedux.push(`/sports?bt-path=${url}`)),
        onLogin: () =>
          dispatch(ReactRouterRedux.push('/login?redirectTo=/sports')),
        onRecharge: () => dispatch(ReactRouterRedux.push('/wallet/deposit')),
        onRegister: () => dispatch(ReactRouterRedux.push('/register')),
        onSportClick: () => dispatch(ReactRouterRedux.push('/sports')),
      })
    }
  }, [language, shouldInitApp, brandId, client])

  // Hook for checking if app should be reinited.
  React.useEffect(() => {
    // Do nothing if there is no refrence
    if ((ref.current, btRendererInited)) {
      // Config has been updated; e.g. language was switched.
      if (prevLanguage !== language) {
        client.kill()
        setShouldInitApp(true)
      }
    }
  }, [language, prevLanguage, ref, setShouldInitApp, btRendererInited])

  // Launch client
  React.useEffect(() => {
    if (ref.current && brandId && btRendererInited) {
      setShouldInitApp(true)
    }
  }, [ref.current, brandId, btRendererInited])

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (client) {
        client.kill()
      }
    }
  }, [client])

  return (
    <React.Fragment>
      <Helmet>
        <script src={scriptUrl} id="betby-script" />
      </Helmet>
      <Common.Box bg="#06193c" py="36px">
        <Wrapper id="betby_widget" ref={ref} />
      </Common.Box>
    </React.Fragment>
  )
}

import * as recompose from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'

import { getLanguage, getSocialMediaLinks } from '../../../store/app'

import { SocialMedia as Component } from './social-media'

function mapStateToProps(state) {
  return {
    language: getLanguage(state.app),
    links: getSocialMediaLinks(state.app),
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  recompose.branch(props => !props.links, recompose.renderNothing)
)

export const SocialMedia = connector(Component)

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { h5 } from '../mixins'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100px;
  cursor: pointer;

  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (min-width: 768px) {
    width: 168px;
  }
`

const Banner = styled.div`
  width: 88px;
  height: 88px;

  background-image: ${props => `url(${props.image})`};
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 768px) {
    height: 168px;
    width: 168px;
  }
`

const Title = styled.h5`
  ${h5};
  margin-bottom: 5px;
  line-height: 1.2;
`

function HelpTile(props) {
  return (
    <Wrapper onClick={props.onClick}>
      <Banner image={props.image} />
      <Title>{props.title}</Title>
    </Wrapper>
  )
}

HelpTile.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default HelpTile

import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Field, FormPropTypes, connectForm } from '@rushplay/legacy-forms'
import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import InputField from '../../common/input-field'
import getFormTranslationKey from '../../util/get-form-translation-key'
import validators from '../../validators'

import { default as Content } from './content'
import { default as Message } from './message'
import { default as Title } from './title'

const Form = styled.form`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Input = styled.div`
  margin: 10px 0 40px;
  width: 200px;

  @media screen and (min-width: 1280px) {
    width: 400px;
  }
`

function ResetPassword(props) {
  return (
    <Content>
      <Title>{props.translate('headerLogin.forgotPassword')}</Title>
      <Message>
        {props.translate('reset-password.send-email-instructions')}
      </Message>
      <Form
        onSubmit={event => {
          event.preventDefault()
          props.onSubmit(props.fields.email.value)
        }}
      >
        <Input>
          <Field
            autoFocus
            component={InputField}
            label={props.translate('register.form.email.hint')}
            name="email"
            type="email"
            validator={validators.email}
            tooltip={props.translate(
              getFormTranslationKey('resetPassword')(
                props.fields.email,
                'email',
                'choose-valid-email'
              )
            )}
          />
        </Input>
        <Button disabled={!props.isFormValid} type="submit">
          {props.translate('password.reset.confirmButton')}
        </Button>
      </Form>
    </Content>
  )
}

ResetPassword.propTypes = {
  fields: PropTypes.shape({ email: FormPropTypes.field }).isRequired,
  isFormValid: PropTypes.bool.isRequired,
  translate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

export default connectForm(withTranslate(ResetPassword))

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getPendingRealityCheck } from '../store/player'
import { mgFlashGames } from '../constants/config'

import BalanceTypeChangePopupContainer from './balance-type-change-popup'
import GameIframeContainer from './game-iframe-container'
import RealityCheckPopupContainer from './reality-check-popup-container'

class MicrogamingGameContainer extends Component {
  constructor() {
    super()
    this.handleRealityCheck = this.handleRealityCheck.bind(this)
    this.stopAutoplay = this.stopAutoplay.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isRealityCheck !== nextProps.isRealityCheck) {
      return this.handleRealityCheck('StopGamePlay')
    } else {
      return this.handleRealityCheck('ReStartGamePlay')
    }
  }

  handleRealityCheck(action) {
    if (this.iframe && this.iframe.contentWindow === mgFlashGames) {
      this.iframe.contentWindow.postMessage(action, mgFlashGames)
    }
  }

  stopAutoplay() {
    this.handleRealityCheck('StopGamePlay')
  }

  render() {
    return (
      <React.Fragment>
        <GameIframeContainer
          {...this.props}
          innerRef={ref => (this.iframe = ref)}
        />
        <RealityCheckPopupContainer />
        <BalanceTypeChangePopupContainer
          onOpen={this.stopAutoplay}
          openOnChange
        />
      </React.Fragment>
    )
  }
}

MicrogamingGameContainer.propTypes = {
  isRealityCheck: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isRealityCheck: getPendingRealityCheck(state.player),
  }
}

export { MicrogamingGameContainer }

export default connect(mapStateToProps, null)(MicrogamingGameContainer)

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import { withTranslate } from '@rushplay/i18n'

import Button from '../../common/button'
import PopupContainer from '../../common/popup/popup-container'
import adventure from '../../store/adventure'
import worlds from '../../store/adventure/worlds'
import {
  PopupDescription,
  PopupDescriptionSmall,
  PopupImageShadow,
  PopupScrollable,
} from '../../common/popup/popup-styles.js'
import { getConfig } from '../../store/app'

function RegionPopupsContainer(props) {
  return (
    <div>
      <PopupContainer
        id="region-target"
        isOpen={props.popupKey === 'region-target'}
        title={props.translate(props.currentRegion.bossKey)}
        onRequestClose={() => props.redirectTo('/adventure')}
      >
        <PopupImageShadow src={props.currentRegion.bossAvatar} />
        <PopupDescription
          html={{
            __html: props.translate('popup.region-target.mission', {
              amount: props.playerPP,
            }),
          }}
        />
        <PopupDescriptionSmall>
          {props.translate('popup.region-target.fine-print')}
        </PopupDescriptionSmall>
        <Button
          stretch
          minimalPadding
          onClick={() => props.redirectTo('/adventure/boss-story')}
        >
          {props.translate('popup.region-target.boss-story')}
        </Button>
      </PopupContainer>
      <PopupContainer
        id="boss-story"
        isOpen={props.popupKey === 'boss-story'}
        title={props.translate(props.currentRegion.bossKey)}
        onRequestClose={() => props.redirectTo('/adventure')}
      >
        <PopupScrollable>
          <PopupImageShadow src={props.currentRegion.bossAvatar} />
          <PopupDescription
            html={{
              __html: props.translate(
                `popup.${props.currentRegion.bossKey}.story`
              ),
            }}
          />
        </PopupScrollable>
      </PopupContainer>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    assetPath: `${getConfig(state.app).cdnHost}/${getConfig(state.app)
      .cdnPrefix}`,
    currentRegion: adventure.selectors.getCurrentRegion(state.adventure),
    popupKey: ownProps.params.popupKey,
    playerPP: R.prop(
      'pp',
      worlds.selectors.getCurrentWorld(state.adventure.worlds)
    ),
  }
}

export default connect(mapStateToProps, { redirectTo })(
  withTranslate(RegionPopupsContainer)
)

RegionPopupsContainer.propTypes = {
  assetPath: PropTypes.string.isRequired,
  currentRegion: PropTypes.object,
  popupKey: PropTypes.string,
  playerPP: PropTypes.number,
  redirectTo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

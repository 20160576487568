import * as R from 'ramda'
import { combineReducers } from 'redux'

import * as websockets from '@rushplay/websockets'

import * as types from './types'

function gamesReducer(state = {}, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case types.STORE_GAME_PROPS: {
      return R.mergeDeepLeft(
        { [action.payload.id]: action.payload.props },
        state
      )
    }

    case websockets.TABLE_UPDATED: {
      return R.mergeDeepLeft(R.indexBy(R.prop('id'), action.payload), state)
    }

    case types.CLEAR_GAME_OPTIONS: {
      const stringify = R.unless(R.is(String), R.toString)
      return R.pipe(
        R.dissocPath([stringify(action.payload.id), 'options']),
        R.dissocPath([stringify(action.payload.id), 'blitzProperties'])
      )(state)
    }

    case types.STORE_GAMES: {
      return R.mergeDeepLeft(action.payload, state)
    }

    default:
      return state
  }
}

function queryResultsReducer(state = {}, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case types.APPEND_QUERY_RESULTS: {
      const { name, ids, totalCount } = action.payload

      return R.assoc(
        name,
        {
          offset: state[name].offset,
          totalCount,
          __items: R.uniq(
            R.concat(R.pathOr([], [name, '__items'], state), ids)
          ),
        },
        state
      )
    }

    case types.REPLACE_QUERY_RESULTS: {
      const { name, ids, totalCount } = action.payload

      return R.assoc(
        name,
        {
          totalCount,
          __items: ids,
        },
        state
      )
    }

    case types.STORE_QUERY_OFFSET: {
      const { name, offset } = action.payload
      return R.assocPath([name, 'offset'], offset, state)
    }

    case types.CLEAR_QUERY_RESULTS: {
      return R.dissoc(action.payload, state)
    }
    default:
      return state
  }
}

const initialFilterState = {
  gameType: 'all',
  betRange: [0, 500],
}

function filtersReducer(state = initialFilterState, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case types.UPDATE_FILTER_GAME_TYPE: {
      return R.assoc('gameType', action.payload, state)
    }
    case types.UPDATE_FILTER_BET_RANGE: {
      return R.assoc('betRange', action.payload, state)
    }
    default:
      return state
  }
}

export const reducer = combineReducers({
  games: gamesReducer,
  queryResults: queryResultsReducer,
  filters: filtersReducer,
})

import * as R from 'ramda'

const COUNTRY_LANGUAGE_MAPPINGS = {
  DE: 'de',
  FI: 'fi',
  JP: 'ja',
  NO: 'no',
  SE: 'sv',
}

/**
 * Get preferred (as per Hero Gaming standards) language by country code
 * @param {string} countryCode ISO 3166-1 alpha-2 country code
 * @return {?string} Language if present; `null` otherwise
 */
export function getLanguageByCountryCode(countryCode) {
  return R.propOr(null, R.toUpper(countryCode), COUNTRY_LANGUAGE_MAPPINGS)
}

import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { flexbox } from 'styled-system'

import propTypes from '@styled-system/prop-types'
import styled from '@emotion/styled'
import {
  createShouldForwardProp,
  props,
} from '@styled-system/should-forward-prop'

import {
  ResponsiveValueSizePropType,
  logLegacyPropsWarnings,
} from './internal-helpers/index'

const propMappers = {
  alignItems(props) {
    if (props.center) {
      return 'center'
    }

    return props.alignItems
  },
  flexDirection(props) {
    if (props.column) {
      return 'column'
    }

    return props.flexDirection
  },
  flexWrap(props) {
    if (props.wrap) {
      return 'wrap'
    }

    return props.flexWrap
  },
  justifyContent(props) {
    if (props.center) {
      return 'center'
    }

    return props.justifyContent
  },
}

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'as',
  'column',
  'grow',
  'inline',
  'shrink',
  'wrap',
  'wide',
])

/**
 * Render children in a flexbox wrapper
 * Supply responsive values (based on theme => breakpoints) in array, eg. <Flex order={[1, 2]} />
 * For more info see https://styled-system.com/api#flexbox
 * @param {Object} props
 * @param {CssStringValues} props.alignContent Control CSS `align-content` value
 * @param {CssStringValues} props.alignItems Control CSS `align-items` value
 * @param {CssStringValues} props.alignSelf Control CSS `align-self` value
 * @param {boolean} props.center Center children on both axis
 * @param {boolean} props.column Set `flex-direction` to column
 * @param {CssNumberLikeValues} props.flex Control CSS `flex` value
 * @param {CssNumberLikeValues} props.flexBasis Control CSS `flex-basis` value
 * @param {CssStringValues} props.flexDirection Control CSS `flex-direction` value
 * @param {CssNumberLikeValues} props.flexGrow Control CSS `flex-grow` value
 * @param {CssNumberLikeValues} props.flexShrink Control CSS `flex-shrink` value
 * @param {CssStringValues} props.flexWrap Control CSS `flex-wrap` value
 * @param {CssStringValues} props.justifyContent Control CSS `justify-content` value
 * @param {CssStringValues} props.justifyItems Control CSS `justify-items` value
 * @param {CssStringValues} props.justifySelf Control CSS `justify-self` value
 * @param {boolean} props.inline Render container as inline element
 * @param {CssNumberLikeValues} props.order Control CSS `order` value
 * @param {boolean} props.wrap Set `flex-wrap` to `wrap`
 * @param {boolean} props.wide Set `width` to `100%`
 * @return {ReactComponent}
 * @example
 * <Flex center wide>
 *   <Button>Register</Button>
 * </Flex>
 */

const NextFlex = styled('div', { shouldForwardProp })`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  ${props => !props.inline && props.wide && 'width: 100%;'};
  ${flexbox}
`

/**
 * Ugly backwards-compatibility hack
 *
 * We must remove properties that has undefined as value to avoid wiping out the actual value on merge
 *
 * @param {*} props
 */
function getFlexLegacyProps(props) {
  return R.reject(R.isNil, {
    alignItems: propMappers.alignItems(props),
    flexDirection: propMappers.flexDirection(props),
    flexWrap: propMappers.flexWrap(props),
    justifyContent: propMappers.justifyContent(props),
  })
}

export function Flex(props) {
  useEffect(() => logLegacyPropsWarnings(props), [])

  return <NextFlex {...getFlexLegacyProps(props)} {...props} />
}

Flex.propTypes = {
  ...propTypes.flexbox,
  grow: ResponsiveValueSizePropType,
  shrink: ResponsiveValueSizePropType,
  children: PropTypes.node,
  column: PropTypes.bool,
  inline: PropTypes.bool,
  wrap: PropTypes.bool,
  wide: PropTypes.bool,
}

const shouldForwardFlexItemProp = createShouldForwardProp([
  ...props,
  'as',
  'basis',
  'grow',
  'shrink',
])

/**
 * Control flexbox children rendering
 * Supply responsive values (based on theme => breakpoints) in array, eg. <FlexItem grow={[1, 2]} />
 * For more info see https://styled-system.com/api#flexbox
 * @name FlexItem
 * @param {Object} props
 * @param {CssNumberLikeValues} props.basis Control CSS `flex-basis` property
 * @param {CssNumberLikeValues} props.grow Control CSS `flex-grow` property
 * @param {CssNumberLikeValues} props.order Control CSS `order` value
 * @param {CssNumberLikeValues} props.shrink Control CSS `flex-shrink` property
 * @return {ReactComponent}
 * @example
 * <Flex>
 *   <FlexItem grow="1">
 *     <Icon name="checkmark" />
 *   </FlexItem>
 *   <FlexItem grow="2">
 *     <Button>Continue</Button>
 *   </FlexItem>
 * </Flex>
 */

const NextFlexItem = styled('div', { shouldForwardFlexItemProp })(flexbox)

/**
 * Ugly backwards-compatibility hack
 *
 * We must remove properties that has undefined as value to avoid wiping out the actual value on merge
 *
 * @param {*} props
 */
function getFlexItemLegacyProps(props) {
  useEffect(() => logLegacyPropsWarnings(props), [])

  return R.reject(R.isNil, {
    flexBasis: props.basis,
    flexGrow: props.grow,
    flexShrink: props.shrink,
  })
}

export function FlexItem(props) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      'DEPRECATED',
      '<FlexItem> component is deprecated and will be removed. Use <Box> component instead'
    )
  }
  return (
    <NextFlexItem
      className={props.className}
      {...getFlexItemLegacyProps(props)}
    >
      {props.children}
    </NextFlexItem>
  )
}

FlexItem.propTypes = {
  basis: ResponsiveValueSizePropType,
  grow: ResponsiveValueSizePropType,
  shrink: ResponsiveValueSizePropType,
  children: PropTypes.node,
  order: ResponsiveValueSizePropType,
}
